import { Text, Rect, setHoverStyle, subPixelOptimizeRect, formatTextStyle } from '../../util/graphic';
import { each, merge, isFunction } from '../../util';
import D3Link from '../../shape/D3Link';
import ChartView from '../../view/Chart';
import { TYPE } from './SankeyModel'; // 使用Model中定义的TYPE，与model的type值保持一致


export default class SankeyView extends ChartView {

    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    render(model, globalModel) {
        this.remove();

        let data = model.getData();
        let nodeStyle = model.get('nodeStyle');
        let linkStyle = model.get('linkStyle');
        let labelOption = model.get('label');
        let nodeColors = model.get('nodeColors') || [];
        let modelName = model.get('name');
        let nodeWidth = model.get('nodeWidth');
        let triggerOn = model.get('triggerOn');
        let { left, right } = model.position;
        let center = (left + right) / 2;

        let sankeyLinks = data.links;
        let sankeyNodes = data.nodes;
        let nodeAttrs = [];
        let linkAttrs = [];
        let labelAttrs = [];

        let labelGroup;
        let nodeGroup;

        // 计算node的attrs
        for (let i = 0; i < sankeyNodes.length; i++) {
            let { x0, y0, x1, y1, name, color } = sankeyNodes[i];
            let _color = color || nodeColors[i % nodeColors.length];

            let style = isFunction(nodeStyle.normal) ? nodeStyle.normal(sankeyNodes[i]) : nodeStyle.normal;
            let hoverStyle = isFunction(nodeStyle.emphasis) ? nodeStyle.emphasis(sankeyNodes[i]) : nodeStyle.emphasis;

            style = {
                fill: _color,
                ...style
            };
            hoverStyle = {
                fill: style.fill,
                ...hoverStyle
            };

            sankeyNodes[i].type = 'node';

            let nodeAttr = {
                shape: {
                    x: x0,
                    y: y0,
                    width: x1 - x0,
                    height: y1 - y0
                },
                style,
                z2: 10,
                key: i,
                data: sankeyNodes[i],
                _hoverStyle: hoverStyle
            };

            sankeyNodes[i].color = nodeAttr.style.fill;
            sankeyNodes[i].hoverColor = nodeAttr._hoverStyle.fill;

            nodeAttrs.push(nodeAttr);
        }

        nodeGroup = this.setShapeGroup('nodeGroup', Rect, nodeAttrs, undefined, function (shapeGroup) {
            shapeGroup.eachChild(function (shape, i) {
                setHoverStyle(shape, shape._hoverStyle, { disableMouse: true });
            });

            // 绑定node的交互事件
            shapeGroup.on(triggerOn, function ({ target }) {
                let node = target.data;

                triggerNode(node, 'emphasis');
                triggerNode(node, 'emphasis', true);
            });

            shapeGroup.on('mouseout', function ({ target }) {
                let node = target.data;

                triggerNode(node, 'normal');
                triggerNode(node, 'normal', true);
            });

            shapeGroup.on('click', function (e) {
                model.get('onNodeClick') && model.get('onNodeClick')(e.target.data, e);
            });
        });

        if (labelOption.show) {
            // 计算label的attrs
            for (let i = 0; i < sankeyNodes.length; i++) {
                let { x0, y0, x1, y1, name } = sankeyNodes[i];
                let labelStyle = labelOption.style.normal;
                let labelPadding = labelOption.padding;
                let labelOffset = labelOption.offset;
                let labelPosition = labelOption.position;
                let labelFormatter = labelOption.formatter;
                let text = labelFormatter ? labelFormatter(name, sankeyNodes[i]) : name;

                let labelAttr = globalModel.getFormattedText({
                    position: [(x0 + x1) / 2 + (x0 > center ? -nodeWidth / 2 : nodeWidth / 2), (y0 + y1) / 2],
                    style: {
                        x: labelOffset[0],
                        y: labelOffset[1],
                        text,
                        textVerticalAlign: 'center',
                        textAlign: x0 > center ? 'right' : 'left',
                        ...labelStyle
                    },
                    z2: 11,
                    key: i,
                    data: sankeyNodes[i],
                    rectHover: true
                });

                let textAlign = labelAttr.style.textAlign;
                let position;

                switch (labelPosition) {
                    case 'right':
                        position = [(x0 + x1) / 2 + nodeWidth / 2 + labelPadding, (y0 + y1) / 2];
                        break;
                    case 'left':
                        position = [(x0 + x1) / 2 - nodeWidth / 2 - labelPadding, (y0 + y1) / 2];
                        break;
                    case 'top':
                        position = [(x0 + x1) / 2, y0 - labelPadding];
                        break;
                    case 'bottom':
                        position = [(x0 + x1) / 2, y1 + labelPadding];
                        break;
                    default:
                        position = [(x0 + x1) / 2, (y0 + y1) / 2];
                        break;
                }
                labelAttr.position = position;

                labelAttrs.push(labelAttr);
            }

            labelGroup = this.setShapeGroup('labelGroup', Text, labelAttrs, undefined, function (shapeGroup) {

                // 绑定label的交互事件
                shapeGroup.on(triggerOn, function ({ target }) {
                    let node = target.data;

                    triggerNode(node, 'emphasis');
                    triggerNode(node, 'emphasis', true);
                });

                shapeGroup.on('mouseout', function ({ target }) {
                    let node = target.data;

                    triggerNode(node, 'normal');
                    triggerNode(node, 'normal', true);
                });

                shapeGroup.eachChild(function (shape, i) {
                    setHoverStyle(shape, globalModel.getFormattedText({
                        ...labelOption.style.emphasis
                    }), { disableMouse: true });
                });

                shapeGroup.on('click', function (e) {
                    model.get('onNodeClick') && model.get('onNodeClick')(e.target.data, e);
                });
            });
        }

        function horizontalSource(d) {
            return [d.source.x1, d.y0];
        }

        function horizontalTarget(d) {
            return [d.target.x0, d.y1];
        }

        // 计算link的attrs
        for (let i = 0; i < sankeyLinks.length; i++) {
            let color = {
                colorStops: [
                    {
                        offset: 0,
                        color: sankeyLinks[i].source.color
                    },
                    {
                        offset: 1,
                        color: sankeyLinks[i].target.color
                    }
                ]
            };

            sankeyLinks[i].color = sankeyLinks[i].source.color;
            sankeyLinks[i].name = modelName;
            sankeyLinks[i].type = 'link';

            let style = isFunction(linkStyle.normal) ? linkStyle.normal(sankeyLinks[i]) : linkStyle.normal;
            let hoverStyle = isFunction(linkStyle.emphasis) ? linkStyle.emphasis(sankeyLinks[i]) : linkStyle.emphasis;

            linkAttrs.push({
                shape: {
                    source: horizontalSource(sankeyLinks[i]),
                    target: horizontalTarget(sankeyLinks[i])
                },
                style: {
                    stroke: color,
                    ...style,
                    lineWidth: Math.max(1, sankeyLinks[i].width)
                },
                key: i,
                data: sankeyLinks[i],
                z2: 1,
                _hoverStyle: hoverStyle
            });
        }

        let linkGroup = this.setShapeGroup('linkGroup', D3Link, linkAttrs, undefined, function (shapeGroup) {
            shapeGroup.eachChild(function (shape, i) {
                let hoverColor = {
                    colorStops: [
                        {
                            offset: 0,
                            color: shape.data.source.hoverColor || shape.data.source.color
                        },
                        {
                            offset: 1,
                            color: shape.data.target.hoverColor || shape.data.target.color
                        }
                    ]
                };

                setHoverStyle(shape, {
                    stroke: hoverColor,
                    ...shape._hoverStyle
                }, { disableMouse: true });
            });

            // 绑定link的交互事件
            shapeGroup.on(triggerOn, function ({ target }) {
                let link = target.data;

                triggerLink(link, 'emphasis');
                triggerLink(link, 'emphasis', true);
            });

            shapeGroup.on('mouseout', function ({ target }) {
                let link = target.data;

                triggerLink(link, 'normal');
                triggerLink(link, 'normal', true);
            });
        });


        function triggerLink(link, triggerType, isTarget) {
            linkGroup.childAt(link.index).trigger(triggerType);

            triggerNode(isTarget ? link.target : link.source, triggerType, isTarget);
        }

        function triggerNode(node, triggerType, isTarget) {
            nodeGroup.childAt(node.index).trigger(triggerType);
            labelGroup && labelGroup.childAt(node.index).trigger(triggerType);

            let links = node[isTarget ? 'sourceLinks' : 'targetLinks'];

            each(links, function (link) {
                linkGroup.childAt(link.index).trigger(triggerType);

                triggerLink(link, triggerType, isTarget);
            });
        }
    }
}
