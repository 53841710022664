import ComponentView from '../../view/Component';
import { Symbol } from '../../util/symbol';
import { setHoverStyle } from '../../util/graphic';
import { TYPE } from './MarkPointModel';
import { each, merge, parseSize, isFunction, clone, isArray } from '../../util';
import { markPointActions } from '../../action/event';

export default class MarkPointView extends ComponentView {
    static type = TYPE;

    type = TYPE;

    render(model, globalModel, global) {
        if (model.get('show')) {
            let data = model.getData();
            let symbolOption = model.get('symbol');
            let labelOption = model.get('label');
            let onClick = model.get('onClick');
            let animation = model.get('animation');
            let enableSelect = model.get('enableSelect');
            let attrs = [];
            let markPointIndex = {};
            each(data, function(item, i) {
                let itemMarkData = getMarkData(
                    item,
                    model,
                    globalModel
                );
                if (!itemMarkData || markPointIndex[itemMarkData.data[0]]) return;
                markPointIndex[itemMarkData.data[0]] = true;

                let itemSymbol = merge(clone(item.symbol) || {}, symbolOption);
                let itemLabel = merge(clone(item.label) || {}, labelOption);
                let { width, height } = parseSize(itemSymbol.size);
                let symbolOffset = itemSymbol.offset;

                let { position, data: markData } = itemMarkData;
                let style = itemSymbol.style.normal,
                    hoverStyle = itemSymbol.style.emphasis;
                item.data = markData;

                if (position) {
                    if (itemLabel.normal.show) {
                        let labelFormatter = itemLabel.normal.formatter;
                        let labelPosition = itemLabel.normal.style.position;
                        isFunction(labelPosition) &&
                            (itemLabel.normal.style.position = labelPosition(
                                markData,
                                item,
                                position
                            ));
                        style.text = labelFormatter
                            ? labelFormatter(item, position)
                            : markData[1];
                        style = merge(
                            style,
                            globalModel.getFormattedText(
                                itemLabel.normal.style
                            ),
                            true
                        );
                    }

                    if (itemLabel.normal.show || itemLabel.emphasis.show) {
                        let labelFormatter =
                            itemLabel.emphasis.formatter ||
                            itemLabel.normal.formatter;
                        let labelPosition = itemLabel.emphasis.style.position;

                        isFunction(labelPosition) &&
                            (itemLabel.emphasis.style.position = labelPosition(
                                markData,
                                item,
                                position
                            ));
                        hoverStyle.text = labelFormatter
                            ? labelFormatter(item, position)
                            : markData[1];
                        hoverStyle = merge(
                            hoverStyle,
                            globalModel.getFormattedText(
                                itemLabel.emphasis.style
                            ),
                            true
                        );
                    }
                    attrs.push({
                        cursor: model.get('cursor'),
                        shape: {
                            symbolType: itemSymbol.type,
                            x: -width / 2 + symbolOffset[0],
                            y: -height / 2 + symbolOffset[1],
                            width,
                            height,
                            center: itemSymbol.center,
                            offsetPoint: itemSymbol.offsetPoint
                        },
                        position,
                        style,
                        key: item.name || i,
                        hoverStyle,
                        value: markData[1],
                        data: item,
                        index: i,
                        rectHover: true
                    });
                }
            });

            this.setShapeGroup(
                'markPoints',
                Symbol,
                attrs,
                {
                    animation,
                    animateFrom: {
                        shape: {
                            x: 0,
                            y: 0,
                            width: 0,
                            height: 0
                        }
                    },
                    animateList: {
                        position: [0, 0]
                    }
                },
                function(group) {
                    group.on('click', function(e) {
                        onClick && onClick(e.target.data, e);
                        global.dispatchAction(model, markPointActions.pointClick, { data:e.target.data, e});

                        if (enableSelect) {
                            each(data, function(d) {
                                d.selected = false;
                            });
                            e.target.data.selected = true;
                            model.dirty();
                        }
                    });

                    group.on('mousemove', function(e){
                        global.dispatchAction(model, markPointActions.pointMousemove, { data:e.target.data, e});
                    })

                    group.on('mouseout', function(e){
                        global.dispatchAction(model, markPointActions.pointMouseout, { data:e.target.data, e});
                    })

                    group.on('mouseover', function(e){
                        global.dispatchAction(model, markPointActions.pointMouseover, { data:e.target.data, e});
                    })
                },
                function(shape) {
                    setHoverStyle(shape, shape.hoverStyle);
                }
            );

            this.getShape('markPoints').eachChild(function(shape) {
                if (shape.data.selected) {
                    shape.__isHover = false;
                    shape.trigger('emphasis');
                }
            });
        } else {
            this.removeShape('markPoints');
        }
    }
}

function inAxisDomain(axisModel, value) {
    let axisType = axisModel.getAxisType();

    if (axisType === 'ordinal') {
        return value >= axisModel.startIndex && value <= axisModel.endIndex;
    } else {
        if (isArray(value)) {
            for (let i = 0; i < value.length; i++) {
                if (value[i] < axisModel.domain[0] || value[i] > axisModel.domain[1]) {
                    return false;
                }
            }
            return true;
        } else {
            return value >= axisModel.domain[0] && value <= axisModel.domain[1];
        }
    }
}

function getAxisValue(axisModel, value, valueIndex = 0) {
    let axisType = axisModel.getAxisType();

    if (axisType === 'ordinal') {
        return axisModel.scale.domain()[value - axisModel.startIndex];
    } else {
        if (isArray(value)) {
            return value[valueIndex];
        } else {
            return value;
        }
    }
}

function getMarkData(item, model, globalModel) {
    let seriesIndexes = model.get('$seriesIndex');
    !isArray(seriesIndexes) && (seriesIndexes = [seriesIndexes]);
    let seriesModels = seriesIndexes.map((index) => globalModel.getComponentByIndex('series', index));
    let seriesModel = seriesModels[0];
    let xAxisModel = (seriesModel || model).getAxisModel('x');
    let yAxisModel = (seriesModel || model).getAxisModel('y');
    
    if (item.type) {
        let re, extData;

        each(seriesModels, function(seriesModel) {
            if (seriesModel.get('selected') === false) return;

            let _extData = seriesModel.getDataExtent(
                item.type,
                item.valueDim,
                item.valueIndex
            );

            if (_extData) {
                if (
                    !extData ||
                    (item.type === 'min' && _extData[1] < extData[1]) ||
                    (item.type === 'max' && _extData[1] > extData[1])
                ) {
                    let extX = _extData[0][0];
                    let extY = _extData[0][1];

                    if (
                        inAxisDomain(xAxisModel, extX) &&
                        inAxisDomain(yAxisModel, extY)
                    ) {
                        let xValue = getAxisValue(xAxisModel, extX);
                        let yValue = getAxisValue(yAxisModel, extY, item.valueIndex);
                        let x = xAxisModel.scale(xValue);
                        let y = yAxisModel.scale(yValue);

                        if (xAxisModel.get('type') === 'band') {
                            if (seriesModel.get('type') === 'bar') {
                                x += seriesModel.centerOffset;
                            } else {
                                x += xAxisModel.scale.bandwidth() / 2;
                            }
                        }

                        re = {
                            position: [x, y],
                            data: [xValue, yValue]
                        };
                        extData = _extData;
                    }
                }
            }
        });

        return re;
    } else {
        if (seriesModel && seriesModel.get('selected') === false) return;

        if (seriesModel && !item.y && !item.yValue && !item.yIndex) {
            let seriesData = seriesModel.getRealData();

            if (item.xIndex) {
                item.yValue = seriesData[item.xIndex][1];
            } else if (item.xValue && xAxisModel.getData().indexOf(item.xValue) !== -1) {
                item.yValue =
                    seriesData[xAxisModel.getData().indexOf(item.xValue)][1];
            }
            // kline
            if(isArray(item.yValue)) item.yValue = item.yValue[item.valueIndex]
        }
        let x = getXOrY('x', item, model, seriesModel);
        let y = getXOrY('y', item, model, seriesModel);
        return isNaN(x[0]) || isNaN(y[0])
            ? false
            : { position: [x[0], y[0]], data: [x[1], y[1]] };
    }
}

function getXOrY(xOrY, item, model, seriesModel) {
    let pos = item[xOrY];
    let posValue = item[xOrY + 'Value'];
    let posIndex = item[xOrY + 'Index'];
    let _pos;
    if (typeof pos !== 'undefined') {
        _pos = pos;
        posValue = pos;
    } else if (typeof posValue !== 'undefined') {
        let axisModel = (seriesModel || model).getAxisModel(xOrY);
        let isOrdinal = axisModel.getAxisType() === 'ordinal';

        if (
            inAxisDomain(
                axisModel,
                isOrdinal
                    ? axisModel.domain.indexOf(posValue) + axisModel.startIndex
                    : posValue
            )
        ) {
            axisModel.get('type') === 'time' && (posValue = new Date(posValue));
            axisModel && (_pos = axisModel.scale(posValue));
            axisModel.get('type') === 'band' &&
                (_pos += axisModel.scale.bandwidth() / 2);
        } else {
            return false;
        }
    } else if (typeof posIndex !== 'undefined') {
        let axisModel = (seriesModel || model).getAxisModel(xOrY);

        if (
            posIndex >= axisModel.startIndex &&
            posIndex <= axisModel.endIndex
        ) {
            posValue = axisModel.scale.domain()[
                posIndex - axisModel.startIndex
            ];
            axisModel.get('type') === 'time' && (posValue = new Date(posValue));
            axisModel && (_pos = axisModel.scale(posValue));
            axisModel.get('type') === 'band' &&
                (_pos += axisModel.scale.bandwidth() / 2);
        } else {
            return false;
        }
    }

    return [_pos, posValue];
}
