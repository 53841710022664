/**
 * 散点图 by wjw
 */
import SeriesModel from '../../model/Series';
import {
    each,
    map,
    isArray,
    parsePercent,
    setObjectKV,
    getObjectKV
} from '../../util';

export const TYPE = 'scatter';

/*
一个最简单的散点图的配置中需要包括一个grid、两个axis分别为x和y，以及series中的scatter，示例如下，可在官网中直接运行

组件详细配置项如下，每个配置项都有一个默认值：


*/

export default class ScatterModel extends SeriesModel {
    static type = TYPE;

    type = TYPE;

    isScatter = true; // 区分继承自己ScatterModel的Model

    static emphasisList = ['symbol', 'label'];

    static defaultOption = {
        //data: undefined, // {Array} 系列中的数据内容数组。数组项通常为具体的数据项。数据项格式同echarts类似，http://echarts.baidu.com/option.html#series-scatter.data
        $dataIndex: undefined, // {number} 依赖的$data的index，格式同上，优先级低于data，取得的数据需符合data的格式
        dataKey: undefined, // {string|number} 搭配$dataIndex使用，方便从依赖的$data中取数据，等同于 data.map((d) => d[dataKey])，最终取得的数据需符合data的格式
        $axisIndex: [0, 1], // {Array} 所依赖axis的index，需包括一个x轴和y轴
        name: undefined, // {string} 系列名称，用于tooltip的显示，legend 的图例筛选

        // 拐点标记
        symbol: {
            // 普通状态
            normal: {
                show: true, // {boolean|string} 是否显示拐点标记， true 显示坐标轴对应点 | false 不显示 | 'all' 显示全部
                type: 'circle', // {string} symbol类型 'line' | 'rect' | 'roundRect' | 'square' | 'circle' | 'diamond' | 'pin' | 'arrow' | 'triangle'
                offset: [0, 0], // {Array} [x, y]，偏移位置
                size: 8, // {number|Function} symbol大小
                // {Object} 普通样式
                style: {
                    fill: '#058499'
                }
            },
            // 高亮状态
            emphasis: {
                show: false, // {boolean} 是否显示高亮显示
                // {Object} 高亮样式
                style: {}
            }
        },

        // 拐点上方的文本
        label: {
            // 普通状态
            normal: {
                show: false, // {boolean|string} 是否显示拐点上方值， true 显示坐标轴对应点 | false 不显示 | 'all' 显示全部
                // {Object} 普通样式
                style: {
                    position: 'top'
                },
                formatter: undefined // {Function} 文本格式化函数
            },
            emphasis: {
                show: false, // {boolean} 是否高亮显示
                // {Object} 高亮样式
                style: {}
            }
        },

        $geoIndex: undefined,
        markers: { // 配置 $geoIndex 时生效
            // show: true,
            linkLine: {
                style: {
                    lineWidth: 2,
                    stroke: '#FFBB00'
                },
                retain: 150, // {number} 优先级低于 linePoints配置, 第二个点维持的水平长度
                linePoints: undefined // {array|function} 具体line绘制的点
            },
            labelGroup: [
                // {
                //     formatter: function(d) {
                //           debugger;
                //           return '增速：' + d[5].toFixed(2) + '%';
                //     },
                //     position: [0, 45], // {array|function}
                //     style:{
                //           fontSize: 12,
                //           textAlign: 'left',
                //           textFill: '#000000'
                //     }
                // }
            ],
            data: undefined // {string|array} 'selectedList' 或者下面的样式
            // [
            //      {
            //          itemIndex: 0,
            //      }
            // ]
        },

        large: false, // {boolean} 是否开启大数据量优化，在数据图形特别多而出现卡顿时候可以开启。缺点：优化后不能自定义设置单个数据项的样式
        largeThreshold: 2000, // {number} 开启绘制优化的阈值。
        onClick: undefined, // {Function} 单击事件
        onDBLClick: undefined, // {Function} 双击事件
        selectedList: [], // {Array} 选中项，使用数据下标指定
        enableSelect: false, // {boolean|string} 是否开启选中, single(true) | multiple | false
        legendHoverLink: true, // {boolean} 是否与图例进行高亮联动
        whenEmphasisZ: 99999, // {number} 高亮时图形z值
        offset: 0, // {number} 适用于单轴散点图，距离单轴的距离

        cursor: 'default', // {string} 鼠标悬浮时在图形元素上时鼠标的样式是什么。同 CSS 的 cursor。
        zlevel: 0, // {number} 所有图形的 zlevel 值
        z: 4 // {number} 组件的所有图形的z值。控制图形的前后顺序。z值小的图形会被z值大的图形覆盖。
    };

    // 假定数据格式统一，以第一个数据的格式作为判断
    formatData(data) {
        if (data && data.length) {
            if (isArray(data[0])) {
                return data;
            } else {
                return map(data, (d, i) => {
                    return [i, d];
                });
            }
        } else {
            return data;
        }
    }

    formatOption(option) {
        super.formatOption(...arguments);

        if (
            option.color &&
            option.type === 'scatter' &&
            !getObjectKV(option, 'symbol.normal.style.fill')
        ) {
            setObjectKV(option, 'symbol.normal.style.fill', option.color);
        }

        // FIXME: 由默认的$axisIndex导致的问题
        if (option.$calendarIndex !== undefined || option.$geoIndex !== undefined) {
            option.$axisIndex = undefined;
        }
    }

    // formatData(data) {
    //     return map(data, (d, i) => {
    //         let _value = (d && d.value) || d;

    //         return isArray(_value) ? d : [i, d]
    //     })
    // }

    updateByCalendar() {
        let { globalModel } = this;
        let data = this.getData();
        let calendarModel = globalModel.getComponentByIndex('calendar', this.get('$calendarIndex'));
        let points = [];

        each(data, (d, i) => {
            if (d) {
                let date = d[0];
                let point = [];
                let _point = calendarModel.calcPoint(date);

                if (_point) {
                    point = [_point.x, _point.y, d, i];
                } else {
                    point = null;
                }

                points.push(point);
            }
        });

        this.data = data;
        this.startIndex = 0;
        this.lastPoints = this.points;
        this.points = points;
    }

    updateByGeo() {
        let { globalModel } = this;
        let data = this.getData();
        let geoModel = globalModel.getComponentByIndex('geo', this.get('$geoIndex'));
        let points = [];

        each(data, (d, i) => {
            if (d) {
                let point = [];
                let _point = geoModel.projection(d);

                if (_point) {
                    point = [_point[0], _point[1], d, i]
                } else {
                    point = null;
                }

                points.push(point);
            }
        });

        this.data = data;
        this.startIndex = 0;
        this.lastPoints = this.points;
        this.points = points;
    }

    updateByAxis() {
        let xAxis = this.getAxisModel('x'),
            yAxis = this.getAxisModel('y');
        let isStack = !!this.get('stack');
        let data = isStack ? this.stackData : this.getData();
        let points = [];
        let _offset;
        let xIsVertical =
            xAxis.get('position') === 'left' ||
            xAxis.get('position') === 'right';

        if (!yAxis) {
            let xPos = xAxis.get('position');

            _offset =
                parsePercent(
                    this.get('offset'),
                    this.global[
                        xPos === 'bottom' || xPos === 'top'
                            ? 'getHeight'
                            : 'getWidth'
                    ]()
                ) + xAxis.pos;
        }

        let xAxisIsTime = xAxis.get('type') === 'time';
        let xScale = xAxis && xAxis.scale;
        let xDomain = xScale && xScale.domain();
        let yAxisIsTime = yAxis && yAxis.get('type') === 'time';
        let yScale = yAxis && yAxis.scale;
        let yDomain = yScale && yScale.domain();
        let compare = yAxis && yAxis.get('compare');

        if (!this.get('stack') && compare && xAxis.getAxisType() === 'ordinal') {
            let startIndex = xAxis.startIndex;
            let endIndex = xAxis.endIndex;
            let startValue = data[startIndex][1];

            data = data.map((d) => {
                if (d[0] < startIndex || d[0] > endIndex) {
                    return d;
                } else {
                    let re = [...d];

                    if (compare === 'percent') {
                        re[1] = (re[1] - startValue) / startValue;
                    } else {
                        re[1] -= startValue;
                    }
                    re[2] = d;

                    return re;
                }
            });
        }

        each(data, (d, i) => {
            if (d) {
                let [dx, dy] = d.value || d;
                let point = [];

                isStack && (dy = dy[1]);

                point[xIsVertical ? 1 : 0] = getPointOnAxis(
                    dx,
                    xAxis,
                    xAxisIsTime,
                    xScale,
                    xDomain
                );

                point[xIsVertical ? 0 : 1] = yAxis
                    ? isArray(dy)
                        ? map(dy, dy_ =>
                            getPointOnAxis(
                                dy_,
                                yAxis,
                                yAxisIsTime,
                                yScale,
                                yDomain
                            )
                        )
                        : getPointOnAxis(dy, yAxis, yAxisIsTime, yScale, yDomain)
                    : _offset;

                if (isNaN(point[0]) || isNaN(point[1])) {
                    // TODO:
                    if (this.get('type') === 'area') {
                        if (!isNaN(point[0])) {
                            points.push(point);
                        } else {
                            points.push(null);
                        }
                    } else {
                        points.push(null);
                    }
                } else {
                    if (isStack) {
                        point[2] = d[2];
                        point[3] = i;
                    } else {
                        point[2] = d;
                        point[3] = i;
                    }

                    points.push(point);
                }
            } else {
                points.push(null);
            }
        });

        this.data = data;
        this.startIndex = xAxis.startIndex;
        this.lastPoints = this.points;
        this.points = points;
    }

    update() {
        if (this.get('$calendarIndex') !== undefined) {
            this.updateByCalendar();
        } else if (this.get('$geoIndex') !== undefined) {
            this.updateByGeo();
        } else {
            this.updateByAxis();
        }
    }

    getSeriesColor(name) {
        if (this.get('color')) {
            return this.get('color');
        }

        let symbolOpt = this.get('symbol').normal;

        return symbolOpt.style.fill || symbolOpt.style.stroke;
    }

    onUnSelected() {
        this.lastPoints = undefined;
        this.preLastPoints = undefined;
        this.points = undefined;
    }
}

function getPointOnAxis(value, axis, isTime, scale, domain) {
    isTime && !(value instanceof Date) && (value = new Date(value));

    if (typeof value !== 'number' && !isTime) return;

    let axisType = axis.getAxisType();

    switch (axisType) {
        case 'continuous':
            if (value <= domain[1] && value >= domain[0]) {
                return scale(value);
            }
            break;
        case 'ordinal':
            // eslint-disable-next-line no-case-declarations
            let startIndex = axis.startIndex;
            // eslint-disable-next-line no-case-declarations
            let endIndex = axis.endIndex;

            if (value <= endIndex && value >= startIndex) {
                return (
                    scale(domain[value - startIndex]) +
                    (axis.get('type') === 'band' ? scale.bandwidth() / 2 : 0)
                );
            }
            break;
        default:
            break;
    }
}
