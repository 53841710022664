/**
 * 数据
 */
import ComponentModel from '../../model/Component';
import { map, isArray } from '../../util';

const TYPE = 'data';

export default class DataModel extends ComponentModel {

    static type = TYPE;

    type = TYPE;

    static defaultOption = {
        // dataKey: {}, // {string} data键值 ， 搭配$dataIndex使用，方便从依赖的$data中取数据，等同于 data.map((d) => d[dataKey])，最终取得的数据需符合data的格式
        $dataIndex: undefined, // {number|string} data依赖 ， 依赖的$data的index，格式同上，优先级低于data，取得的数据需符合data的格式
        formatter: data => data, // {Function} 数据格式化函数
        originData: undefined // {Array | Object} 原始数据
    }

    isPending = false;

    getData(key) {
        if (typeof key === 'undefined') {
            return this._data;
        } else {
            if (isArray(key)) {
                if (key.length) {
                    return map(this._data, (value) => {
                        return map(key, (akey) => {
                            return value[akey];
                        });
                    });
                } else {
                    return [];
                }
            } else {
                return map(this._data, (value) => {
                    return value[key];
                });
            }
        }
    }

    /**
     * 
     * 
     * @memberof DataModel
     */
    update() {
        if (this.isPending) return;

        let dataIndex = this.get('$dataIndex');
        let originData = this.get('originData');
        // let originDataUrl = this.get('originDataUrl');
        let formatter = this.get('formatter');
        let globalModel = this.globalModel;
        let timelineModel = globalModel.getComponentByIndex('timeline', this.get('$timelineIndex'));

        if (timelineModel) {
            originData = originData[timelineModel.get('currentIndex')];
        }

        this.__dataDirty = false;

        if (typeof originData !== 'undefined') {
            this._data = formatter(originData);
        // todo delete 丢弃
        // } else if (typeof originDataUrl !== 'undefined') {

        //     this.__dataDirty = true;
        //     this.isPending = true;

        //     fetch(originDataUrl)
        //     .then(function(response) {
        //         return response.json()
        //       }).then((re) => {
        //         this._data = formatter(re);
        //         this.globalModel.dirty(this);
        //         this.__dataDirty = false;
        //         this.isPending = false;
        //     });
        } else if (typeof dataIndex !== 'undefined') {
            let dataModel = this.globalModel.getComponentByIndex('data', dataIndex);
            let data = dataModel.getData();

            data && (this._data = formatter(data));
        }
    }

    /**
     * 覆盖ComponentModel的updateData方法
     * 
     * @memberof DataModel
     */
    updateData() { }

    /**
     * 推送数据 TODO
     * 
     * @param {any} data 
     * @memberof DataModel
     */
    pushData(data) {
        let originData = this.get('originData');

        if (!originData) {
            console.warn('只有当data模块为数据源时才允许使用pushData方法！');
            return;
        }

        if (!isArray(this._data)) {
            console.warn('只有当data模块的数据源为数组时才允许使用pushData方法！');
            return;
        }

        originData.push(data);
        this.dirty();
    }

    setData(data) {
        this.set('originData', data);
    }

    dirty(action) {
        this.dataDirty();
        this.globalModel.dirty(this);
    }

    isDirty() {
        return this.__dataDirty;
    }
}