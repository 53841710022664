import Indicator from '../Indicator';
import { IndicatorFormula } from 'hxc3-indicator-formula';

const Formula = IndicatorFormula.getClass('kdj')
let formula = new Formula();

export default class KDJ extends Indicator{
    static type = 'kdj';

    static defaultOption = {
        K: {
            type: 'line',
            normal:{
                show: true, 
                style:{
                    stroke: '#C00C00', 
                    lineWidth: 1
                }
            },
            emphasis: undefined
        },
        D: {
            type: 'line',
            normal:{
                show: true, 
                style:{
                    stroke: '#C0BA4D', 
                    lineWidth: 1
                }
            },
            emphasis: undefined
        },
        J: {
            type: 'line',
            normal:{
                show: true, 
                style:{
                    lineWidth: 1,
                    stroke: '#FB8E04'
                }
            },
            emphasis: undefined
        }
    }

    static calculate(data){return formula.calculate(data)}

    static formatData(d, i) {
        return [i, [d.K, d.D, d.J], d];
    }

}