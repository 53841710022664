import ComponentView from '../../view/Component';
import { TYPE } from './CalendarModel';
import { Rect, subPixelOptimizeRect, Text, Polygon } from '../../util/graphic';
import { timeWeek, timeYear } from 'd3-time';

const dayLabelMap = {
    'cn': ['日', '一', '二', '三', '四', '五', '六'],
    'en': ['S', 'M', 'T', 'W', 'T', 'F', 'S']
}

const monthLabelMap = {
    'cn': [
        '一月', '二月', '三月',
        '四月', '五月', '六月',
        '七月', '八月', '九月',
        '十月', '十一月', '十二月'
    ],
    'en': [
        'Jan', 'Feb', 'Mar',
        'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ]
};

export default class CalendarView extends ComponentView {

    static type = TYPE;

    type = TYPE;

    render(model, globalModel) {
        this.renderDays(...arguments);
        this.renderSplitLine(...arguments);
        this.renderDayLabel(...arguments);
        this.renderMonthLabel(...arguments);
        this.renderYearLabel(...arguments);
    }

    renderDays(model, globalModel) {
        let cellSize = model.get('cellSize');
        let left = model.get('left');
        let top = model.get('top');
        let itemStyle = model.get('itemStyle');
        let { days } = model;
        let dayAttrs = [];

        for (let i = 0; i < days.length; i++) {
            let d = days[i];
            let attr = {
                shape: {
                    x: left,
                    y: top,
                    width: cellSize,
                    height: cellSize
                },
                position: [d.xi * cellSize, d.yi * cellSize],
                style: {
                    text: d.d.getDate(),
                    ...itemStyle
                },
                silent: true
            };

            dayAttrs.push(attr);
        }

        this.setShapeGroup('days', Rect, dayAttrs);
    }

    renderSplitLine(model, globalModel) {
        let splitLine = model.get('splitLine');

        if (!splitLine.show) return;

        let { months } = model;
        let left = model.get('left');
        let top = model.get('top');
        let cellSize = model.get('cellSize');
        let orient = model.get('orient');
        let monthAttrs = [];

        for (let n in months) {
            let m = months[n];
            let attr = {
                shape: {
                    points: pointsMath(m, cellSize, orient)
                },
                position: [left, top],
                style: splitLine.style,
                silent: true
            };

            monthAttrs.push(attr);
        }

        this.setShapeGroup('months', Polygon, monthAttrs);

    }

    renderDayLabel(model, globalModel) {
        let dayLabel = model.get('dayLabel');

        if (!dayLabel.show) return;

        let { nameMap, margin, position, style, firstDay } = dayLabel;
        let { days } = model;
        let left = model.get('left');
        let top = model.get('top');
        let cellSize = model.get('cellSize');
        let orient = model.get('orient');
        let dayLabelAttrs = [];

        if (typeof nameMap === 'string') {
            nameMap = dayLabelMap[nameMap];
        }

        if (firstDay) {
            nameMap = nameMap.slice(firstDay).concat(nameMap.slice(0, firstDay));
        }

        for (let i = 0; i < 7; i++) {
            let attr;

            if (orient === 'horizontal') {
                if (position === 'start') {
                    attr = {
                        style: {
                            text: nameMap[i],
                            x: left - margin,
                            y: top + (i + 0.5) * cellSize,
                            textAlign: 'right',
                            textVerticalAlign: 'middle',
                            ...style
                        }
                    };
                // eslint-disable-next-line no-empty
                } else {
                }
            } else {
                if (position === 'start') {
                    attr = {
                        style: {
                            text: nameMap[i],
                            x: left + (i + 0.5) * cellSize,
                            y: top - margin,
                            textAlign: 'center',
                            textVerticalAlign: 'bottom',
                            ...style
                        }
                    };
                // eslint-disable-next-line no-empty
                } else {
                }

            }

            attr.silent = true;
            dayLabelAttrs.push(globalModel.getFormattedText(attr));
        }

        this.setShapeGroup('dayLabels', Text, dayLabelAttrs);
    }

    renderMonthLabel(model, globalModel) {
        let monthLabel = model.get('monthLabel');

        if (!monthLabel.show) return;

        let { nameMap, margin, position, style } = monthLabel;
        let { months } = model;
        let left = model.get('left');
        let top = model.get('top');
        let cellSize = model.get('cellSize');
        let orient = model.get('orient');
        let monthLabelAttrs = [];

        if (typeof nameMap === 'string') {
            nameMap = monthLabelMap[nameMap];
        }

        for (let n in months) {
            let m = months[n];
            let attr;

            n = parseInt(n.split('-')[1]);

            if (orient === 'horizontal') {
                if (position === 'start') {
                    attr = {
                        style: {
                            text: nameMap[n],
                            x: (m[0].xi + m[1].xi + 1) / 2 * cellSize,
                            y: -margin,
                            textAlign: 'center',
                            textVerticalAlign: 'bottom',
                            ...style
                        },
                        position: [left, top]
                    };
                // eslint-disable-next-line no-empty
                } else {

                }
            } else {
                if (position === 'start') {
                    attr = {
                        style: {
                            text: nameMap[n],
                            x: -margin,
                            y: (m[0].yi + m[1].yi + 1) / 2 * cellSize,
                            textAlign: 'right',
                            textVerticalAlign: 'middle',
                            ...style
                        },
                        position: [left, top]
                    };
                // eslint-disable-next-line no-empty
                } else {

                }
            }

            attr.silent = true;
            monthLabelAttrs.push(globalModel.getFormattedText(attr));
        }

        this.setShapeGroup('monthLabels', Text, monthLabelAttrs);
    }

    renderYearLabel(model, globalModel) {
        let yearLabel = model.get('yearLabel');

        if (!yearLabel.show) return;

        let cellSize = model.get('cellSize');
        let left = model.get('left');
        let top = model.get('top');
        let orient = model.get('orient');
        let { days } = model;
        let { margin, style } = yearLabel;
        let day0Year = days[0].d.getFullYear(), lastDayYear = days[days.length - 1].d.getFullYear();
        let text, attr;

        if (day0Year === lastDayYear) {
            text = day0Year;
        } else {
            text = day0Year + '-' + lastDayYear;
        }

        if (orient === 'horizontal') {
            attr = {
                style: {
                    text,
                    textAlign: 'center',
                    textVerticalAlign: 'middle',
                    ...style
                },
                position: [left - margin, top + 3.5 * cellSize],
                rotation: Math.PI / 2
            }
        } else {
            attr = {
                style: {
                    text,
                    textAlign: 'center',
                    textVerticalAlign: 'middle',
                    ...style
                },
                position: [left + 3.5 * cellSize, top - margin]
            }
        }

        attr.silent = true;
        this.setShape('yearLabel', Text, globalModel.getFormattedText(attr));
    }
}

function pointsMath(m, cellSize, orient) {
    let m0 = m[0], m1 = m[1];

    if (orient === 'horizontal') {
        return [
            [m0.xi * cellSize, m0.yi * cellSize],
            [m0.xi * cellSize, 7 * cellSize],
            [m1.xi * cellSize, 7 * cellSize],
            [m1.xi * cellSize, (m1.yi + 1) * cellSize],
            [(m1.xi + 1) * cellSize, (m1.yi + 1) * cellSize],
            [(m1.xi + 1) * cellSize, 0],
            [(m0.xi + 1) * cellSize, 0],
            [(m0.xi + 1) * cellSize, m0.yi * cellSize]
        ];
    } else {
        return [
            [m0.xi * cellSize, m0.yi * cellSize],
            [7 * cellSize, m0.yi * cellSize],
            [7 * cellSize, m1.yi * cellSize],
            [(m1.xi + 1) * cellSize, m1.yi * cellSize],
            [(m1.xi + 1) * cellSize, (m1.yi + 1) * cellSize],
            [0, (m1.yi + 1) * cellSize],
            [0, (m0.yi + 1) * cellSize],
            [m0.xi * cellSize, (m0.yi + 1) * cellSize]
        ];
    }
}
