/**
 * 绘图区域
 */
import ComponentModel from '../../model/Component';
import { parsePercent, each } from '../../util';
import setModelPosition from '../helpers/setModelPosition';

export const TYPE = 'grid';

/*

划选一块矩形区域作为绘图区域


组件详细配置项如下，每个配置项都有一个默认值：

*/

export default class GridModel extends ComponentModel {
    static type = TYPE;

    type = TYPE;

    static defaultOption = {
        // 绝对定位，值范围 0 ~ 1， number || '10%' || 'left'
        left: '10%', // {string|number} grid 组件离容器左侧的距离。
        right: '10%', // {string|number} grid 组件离容器右侧的距离
        top: '10%', // {string|number} grid 组件离容器上侧的距离。
        bottom: '15%', // {string|number} grid 组件离容器下侧的距离。
        width: undefined, // {string|number} grid 组件的宽度。
        height: undefined, // {string|number} grid 组件的高度。
        reverse: false, // {boolean} 用于外部容器翻转后，翻转内部事件对象的坐标系

        // 背景配置
        background: {
            show: false, // {boolean} 是否显示背景
            borderEnable: 1, // {number|array}是否显示边框线, 1 || [1,1,1,1] || [1,1]  符合css 上右下左 规则, 1为显示, 0不显示
            style: { // {Object} 样式
                fill: undefined,
                stroke: undefined,
                lineWidth: 1
            }
        },

        // 顶部空余区域
        topPlaceHolder: {
            // {boolean} 是否显示顶部空余区域
            show: false, 
            // {number|array<number>} 顶部空余区域边框是否显示，传递数组表达[上，右，下，左]的边框开关
            borderEnable: 1,
            style: {
                stroke: '#ccc',
                fill: undefined,
                lineWidth: 1,
                // 画虚线
                lineDash: null
            },
            // {number} 高度px
            height: 20
        },

        // 底部空余区域
        bottomPlaceHolder: {
            // {boolean} 是否显示底部空余区域
            show: false,
            // {number|array<number>} 顶部空余区域边框是否显示，传递数组表达[上，右，下，左]的边框开关
            borderEnable: 1,
            style: {
                stroke: '#ccc',
                fill: undefined,
                lineWidth: 1,
                // 画虚线
                lineDash: null
            },
            // {number} 高度px
            height: 20
        },

        containLabel: false, // {boolean} 是否自动调整宽度，以包含label
        zlevel: 0, // {number} 所有图形的 zlevel 值
        z: 0 // {number} 组件的所有图形的z值。控制图形的前后顺序。z值小的图形会被z值大的图形覆盖。
    };

    position = {};

    update() {
        setModelPosition(this);

        // containLabel计算，不精确
        if (this.get('containLabel')) {
            let globalModel = this.globalModel;
            let supportAxisIndex = this.supports.axis;
            let position = this.position;

            each(supportAxisIndex, function (index) {
                let axisModel = globalModel.getComponentByIndex('axis', index);
                let labelOption = axisModel.get('label');
                let padding = labelOption.padding;
                let scale = axisModel.getScale();
                let domain = axisModel.getOriginDomain();

                if (!domain.length) return;

                scale.domain(domain);
                let longest = false;
                let axisPos = axisModel.get('position');
                let len;
                let step = Math.ceil(domain.length / 100);

                for (let i = 0; i < domain.length; i += step) {
                    // 当无series依赖axis时，domain可能是[undefined, undefined]
                    if (domain[i] !== undefined) {
                        // toFixed(10)统一小数长度
                        var cur = domain[i].toFixed ? domain[i].toFixed(10) : domain[i];

                        if (longest == false) {
                            longest = cur;
                        } else {
                            if (longest.length < cur.length) {
                                longest = cur;
                            }
                        }
                    }
                }

                switch (axisPos) {
                    case 'left':
                    case 'right':
                        if (longest !== false) {
                            len = axisModel.getLabelLen(longest, 'width'); // getLabelLen内对longest进行格式化
                            len += labelOption.padding;

                            if (axisPos === 'left') {
                                position.left += len;
                            } else {
                                position.right -= len;
                            }

                            position.width -= len;

                        }
                        break;
                    case 'top':
                    case 'bottom':
                        if (longest !== false) {
                            len = axisModel.getLabelLen(longest, 'height');
                            len += labelOption.padding;

                            if (axisPos === 'top') {
                                position.top += len;
                            } else {
                                position.bottom -= len;
                            }

                            position.height -= len;
                        }
                        break;
                    default:
                        break;
                }

            });
        }

    }

    isPointInGrid(x, y) {
        let { left, right, top, bottom } = this.position;

        if (x >= left && x <= right && y >= top && y <= bottom) {
            return true;
        } else {
            return false;
        }
    }
}
