import HqIndicatorView from './HqIndicatorView';
import HqIndicatorModel from './HqIndicatorModel';
import { registerComponent, registerIndicator } from '../../d3charts';

import MACD from './type/macd';
import KDJ from './type/kdj';
import WR from './type/wr';
import VR from './type/vr';
import RSI from './type/rsi';
import BOLL from './type/boll';
import BIAS from './type/bias';
import ASI from './type/asi';

registerIndicator(MACD, MACD.type);
registerIndicator(ASI, ASI.type);
registerIndicator(BIAS, BIAS.type);
registerIndicator(BOLL, BOLL.type);
registerIndicator(RSI, RSI.type);
registerIndicator(VR, VR.type);
registerIndicator(WR, WR.type);
registerIndicator(KDJ, KDJ.type);

registerComponent(HqIndicatorModel, HqIndicatorView);
