import Indicator from '../Indicator';

import { IndicatorFormula } from 'hxc3-indicator-formula';

const Formula = IndicatorFormula.getClass('boll')
let formula = new Formula();

export default class BOLL extends Indicator{
    static type = 'boll';

    static defaultOption = {
        MID: {
            type: 'line',
            normal:{
                show: true, 
                style:{
                    stroke: '#C00C00', 
                    lineWidth: 1
                }
            },
            emphasis: undefined
        },
        UPPER: {
            type: 'line',
            normal:{
                show: true, 
                style:{
                    stroke: '#C0BA4D', 
                    lineWidth: 1
                }
            },
            emphasis: undefined
        },
        LOWER: {
            type: 'line',
            normal:{
                show: true, 
                style:{
                    lineWidth: 1,
                    stroke: '#FB8E04'
                }
            },
            emphasis: undefined
        }
    }

    static calculate(data){return formula.calculate(data)}

    static formatData(d, i) {
        return [i, [d.MID, d.UPPER, d.LOWER], d];
    }

}