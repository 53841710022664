import Arc from '../../shape/D3Arc';
import ArcLine from '../../shape/ArcLine';
import Pie from 'zrender/src/graphic/shape/Circle';
import Text from 'zrender/src/graphic/Text';
import ChartView from '../../view/Chart';

const TYPE = 'arcline';

export default class ArcView extends ChartView {

    static type = TYPE;

    type = TYPE;

    render(model, globalModel, global) {
        this.remove();
        let PI = Math.PI;
        let PI2 = PI * 2;
        let group = this.group;
        let data = model.drawData;
        let z = model.get('z');
        let zlevel = model.get('zlevel');
        let arcStyle = model.get('itemStyle');
        let arcNormalLineWidth = arcStyle.normal.lineWidth;
        let arcEmphasisLineWidth = arcStyle.emphasis.lineWidth;
        let circleStyle = model.get('circleStyle');

        let arcCenter = model.get('center');
        let arcRadius = model.get('radius');

        let canvasW = global.getWidth();
        let canvasH = global.getHeight();


        let cx = typeof arcCenter[0] === 'string' ? parseInt(parseFloat(arcCenter[0].split('%')[0])/100*canvasW) : parseInt(arcCenter[0]);
        let cy = typeof arcCenter[1] === 'string' ? parseInt(parseFloat(arcCenter[1].split('%')[0])/100*canvasH) : parseInt(arcCenter[1]);

        let innerRadius = typeof arcRadius[0] === 'string' ? parseInt(parseFloat(arcRadius[0].split('%')[0])/100*canvasW) : parseInt(arcRadius[0]);
        let outerRadius = typeof arcRadius[1] === 'string' ? parseInt(parseFloat(arcRadius[1].split('%')[0])/100*canvasH) : parseInt(arcRadius[1]);

        let edgePadding = circleStyle.edgePadding || 2;

        let itemFormatter = arcStyle.formatter;

        let textPan;

        let arcList = [];

        let values = data.values;

        let total = 0;


        function drawBg() {

            let arcBg = new ArcLine({
                shape: {
                    cx: cx,
                    cy: cy,
                    r: outerRadius,
                    r1: innerRadius,
                    edgePadding: edgePadding,
                    endAngle: 2 * Math.PI
                },
                style: {
                    stroke: circleStyle.backgroundColor || '#223272'
                }
            })

            let circleOut = new Pie({
                shape: {
                    cx: cx,
                    cy: cy,
                    r: outerRadius + 3
                },
                style: {
                    fill: null,
                    stroke: circleStyle.outerCircleColor || '#101851'
                }
            })

            let circleIn = new Pie({
                shape: {
                    cx: cx,
                    cy: cy * .97,
                    r: innerRadius - 3
                },
                style: {
                    fill: null,
                    stroke: circleStyle.innerCircleColor || '#314b89'
                }
            })

            this.group.add(circleOut);
            this.group.add(circleIn);
            this.group.add(arcBg);
        }
        drawBg.call(this)

        function drawItem(item) {

            let {endAngle, startAngle, strokeColor, itemValue} = item;


            let arc = this.setShape(Math.random(), ArcLine,
                // shape的属性等设置
                {
                    shape: {
                        cx: cx,
                        cy: cy,
                        r: outerRadius,
                        r1: innerRadius,
                        endAngle: endAngle,
                        startAngle: startAngle,
                        edgePadding: edgePadding
                    },
                    style: {
                        fill: null,
                        stroke: strokeColor
                    }
                }
            );

            let timer;
            function onEmphasis() {
                clearTimeout(timer);
                timer = setTimeout(function() {
                    textPan.setStyle({
                        'text': itemFormatter(null, itemValue),
                        'fill': strokeColor
                    })
                    arc.stopAnimation(true);
                    arc.animateTo({
                        shape: {
                            r: outerRadius + 8
                        },
                        style: {
                            lineWidth: arcEmphasisLineWidth
                        }
                    }, 300, 'elasticOut');
                }, 50)
            }

            function onNormal() {
                clearTimeout(timer);
                arc.stopAnimation(true);
                timer = setTimeout(function() {
                    arc.animateTo({
                        shape: {
                            r: outerRadius
                        },
                        style: {
                            lineWidth: arcNormalLineWidth
                        }
                    }, 300, 'elasticOut');
                }, 50)

            }
            arc.on('mouseover', onEmphasis);
            arc.on('mouseout', onNormal)

            return arc;
        }

        function drawText(itemValue, itemColor) {

            textPan = new Text({
                style: {
                    text: itemFormatter(null, itemValue),
                    x: cx,
                    y: cy * .97,
                    font: arcStyle.textStyle.fontWeight+' '+arcStyle.textStyle.fontSize+'px '+arcStyle.textStyle.fontFamily,
                    textBaseline: 'middle',
                    textAlign: 'center',
                    textFill: itemColor
                }
            });

            this.group.add(textPan);
            return textPan;
        }

        if (values.length > 1) {

            let l = values.length;
            let startAngle = - PI / 2;
            let firstItemValue;
            let firstItemColor;
            for (let i = 0; i < l; i++) {
                total += values[i].value
            }
            for (let i = 0, item; i < l; i++) {
                item = values[i];
                let itemValue = Math.abs(item.value / total);
                let endAngle = itemValue * (2*Math.PI);
                let strokeColor = item.color;
                let itemOpt = {itemValue, startAngle, endAngle, strokeColor};
                if (i == 0) {
                    firstItemValue = itemValue
                    firstItemColor = item.color
                }

                drawItem.call(this, itemOpt);

                startAngle += endAngle;
            }
            drawText.call(this, firstItemValue, firstItemColor)
        } else {
            let itemValue = Math.abs(data.values[0].value / (data.max - data.min));
            if (itemValue >= 1) {
                itemValue = 1;
            }
            let strokeColor = data.values[0].color;
            let endAngle = itemValue * (2*Math.PI) || 0;
            drawItem.call(this, {endAngle, itemValue, strokeColor, startAngle: -PI / 2});
            drawText.call(this, itemValue, strokeColor)
        }

        for (let i = 0; i < arcList.length; i++) {


            this.group.add(arc);
        }
        /*arc.animateTo({
            shape: {
                endAngle: endAngle
            }

        }, 800, 'linear');*/


        if (data.title) {
            let titleConfigs = model.get('title');
            let titleOffsetX = typeof titleConfigs.offsetCenter[0] === 'string' ? parseFloat(titleConfigs.offsetCenter[0].split('%')[0])/100*canvasW : titleConfigs.offsetCenter[0];
            let titleOffsetY = typeof titleConfigs.offsetCenter[1] === 'string' ? parseFloat(titleConfigs.offsetCenter[1].split('%')[0])/100*canvasH : titleConfigs.offsetCenter[1];
            
            let nameText = new Text({
                style: {
                  text: data.title,
                  x: cx - titleOffsetX,
                  y: cy - titleOffsetY,
                  font: titleConfigs.textStyle.fontWeight+' '+titleConfigs.textStyle.fontSize + 'px ' + titleConfigs.textStyle.fontFamily,
                  textBaseline: 'middle',
                  textAlign: 'center',
                  textFill: titleConfigs.textStyle.color
                }
            });
            
            this.group.add(nameText);
        }
    }
}
