import { Text, Circle, Rect, formatTextStyle, Line } from '../../util/graphic';
import { each, parseSize, isFunction, getContext, isArray } from '../../util';
import { Symbol } from '../../util/symbol';
import ChartView from '../../view/Chart';
import D3Line from '../../shape/D3Line';
import { TYPE } from './ElbowModel'; // 使用Model中定义的TYPE，与model的type值保持一致
import huozi from 'huozi';

import * as d3array from 'd3-array';

export default class ElbowView extends ChartView {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    render(model, globalModel, global) {
        /*let width = global.getWidth();*/
        let height = global.getHeight();
        let that = this;

        let nodeOpt = model.get('node').normal;
        let nodeStyle = nodeOpt.style;
        let nodeSize = nodeOpt.size;
        let nodeType = nodeOpt.type;
        let source = model.getData();

        let nodeAttrs = [];
        let edgeAttrs = [];
        let textAttrs = [];
        let tipsAttrs = [];
        let arrowAttrs = [];

        let nodeWidth = 94;
        let nodeHeight = 40;
        let minWidth = 500,
            gap = nodeHeight / 2,
            itemGap = 60;

        let isCutLine = true;

        let addSuffix = function(str) {
            if (isArray(str)) {
                return str.map(d => addSuffix(d));
            } else {
                return (str >> 0) + .5;
            }
        }

        let fm = source.stockholder || [];
        let to = source.subsidiary || [];
        let center = {label: source.name};

        let gridModel = globalModel.getComponentByIndex('grid', 0);
        let {width, left, top} = gridModel.position;

        /*if (width < minWidth) {
            width = minWidth;
        }*/

        let nodes = [];
        let links = [];
        if (fm.length == 0) {
            center.tx = width / 4;
        } else if (to.length == 0) {
            center.tx = 3 * width / 4;
        } else {
            center.tx = width / 2;
        }
        center.ty = 0 * itemGap + gap || gap;
        center.sign = 'center';
        center.tx += left;
        center.ty += top;
        nodes.push(center);

        to.forEach(function (c, i) {
            c.tx = width - nodeWidth;
            c.ty = gap + i * itemGap;
            c.sign = 'to';
        });

        fm.forEach(function (c, i) {
            c.tx = nodeWidth;
            c.ty = gap + i * itemGap;
            c.sign = 'from';
        });

        to.concat(fm).forEach(function(c, i) {
            let _nodeType = isFunction(nodeType) ? nodeType(c, i) : nodeType;
            let _nodeStyle = isFunction(nodeStyle)
                ? nodeStyle(c, i)
                : nodeStyle;
            c.style = _nodeStyle;
            c.tx += left;
            c.ty += top;
            nodes.push(c);

            let a = { x: c.ty, y: c.tx };
            let b = { x: center.ty, y: center.tx };
            links.push({ source: b, target: a, label: c.label, textFill: _nodeStyle.stroke, sign: c.sign, value: c.value});
        });

        nodes.forEach(function(d, i) {

            let _nodeType = isFunction(nodeType) ? nodeType(d, i) : nodeType;
            let _nodeStyle = isFunction(nodeStyle)
                ? nodeStyle(d, i)
                : nodeStyle;
            let x;
            if (d.sign == 'center') {
                x = d.tx - nodeWidth / 2
            } else if (d.tx < width / 2) {
                x = d.tx - nodeWidth
            } else {
                x = d.tx
            }
            let y = d.ty - nodeHeight / 2;
            nodeAttrs.push({
                shape: {
                    width: nodeWidth - 1,
                    height: nodeHeight,
                    r: 2
                },
                position: addSuffix([x, y]),
                style: _nodeStyle,
                node: d,
                data: d.data
            })

            let textSequence = d.label.replace(/\r\n/g, '\n').split('').map(value => {
                return {
                    fontSize: 12,
                    character: value
                }
            });
            let layoutSequence = huozi(textSequence, {
                fontSize: 12,
                gridSize: 12,
                yInterval: 6,
                column: 7,
                row: 2
            });

            for (let char of layoutSequence) {
                textAttrs.push({
                    style: {
                        fontSize: 12,
                        text: char.character,
                        textPadding: [4, 5],
                        textFill: _nodeStyle.textFill
                    },
                    position: [x + char.x, y + char.y]
                })
            }

        });

        links.forEach(function(d, i) {

            let x1 = d.source.y,
                y1 = d.source.x,
                x2 = d.target.y,
                y2 = d.target.x,
                mx1 = (x1 + x2) / 2,
                my1 = y1,
                mx2 = mx1,
                my2 = y2,
                strokeColor = d.sign == 'from' ? '#ff5753' : '#159cbf',
                points = [];

            if (isCutLine && links[i + 1] && links[i + 1].sign == links[i].sign) {
                points = [[mx2, y2], [x2, y2]];
            } else {
                points = [[x1, y1], [mx1, my1], [mx2, my2], [x2, y2]];
            }

            edgeAttrs.push({
                style: {
                    stroke: strokeColor
                },
                shape: {
                    data: addSuffix(points)
                }
            });

            if (d.sign == 'to' || links[i - 1].sign !== 'from') {
                arrowAttrs.push({
                    shape: {
                        symbolType: 'triangle',
                        width: 8,
                        height: 8,
                        x: -4
                    },
                    style: {
                        fill: d.textFill
                    },
                    position: d.sign == 'from' ? addSuffix([x1 - nodeWidth / 2, y1]) : addSuffix([x2, y2]),
                    rotation: -Math.PI / 2
                })
            }

            tipsAttrs.push({
                style: {
                    text: d.value,
                    textAlign: 'center',
                    textVerticalAlign: 'bottom',
                    y: -5,
                    textFill: d.textFill
                },
                position: [(x2 + mx2) / 2, y2]
            })
        })

        that.setShapeGroup(
            'edges',
            D3Line,
            edgeAttrs
        );

        that.setShapeGroup(
            'nodes',
            Rect,
            nodeAttrs
        );

        that.setShapeGroup(
            'texts',
            Text,
            textAttrs
        );

        that.setShapeGroup(
            'arrow',
            Symbol,
            arrowAttrs
        )

        that.setShapeGroup(
            'tips',
            Text,
            tipsAttrs
        );
    }
}
