import { Line } from '../../util/graphic';
import Area from '../../shape/D3Area';
import D3Line from '../../shape/D3Line';
import { getCurve } from './curve';
import ScatterView from '../scatter/ScatterView';
import { each, clone, merge, findAllIndex, filter } from '../../util';
import { subPixelOptimizeLine, reverseStyleStops, setHoverStyle } from '../../util/graphic';
import extent from 'd3-array/src/extent';
import { TYPE } from './LineModel';
import { Group } from '../../util/graphic';
import { Symbol } from '../../util/symbol'

function fixRange(num, min, max) {
    return Math.min(Math.max(num, min), max);
}

export default class LineView extends ScatterView {

    static type = TYPE;

    type = TYPE;

    render(model, globalModel, global) {
        super.render(...arguments);

        let group = this.group;
        let lineOption = model.get('line').normal;
        let areaOption = model.get('area').normal;
        let areaSnapToBottom = areaOption.snapToBottom;
        let curveOption = model.get('curve');
        let linkOption = model.get('link').normal;
        let pieces = model.get('pieces');
        let needAnimation = model.get('animation');
        let animationDuration = model.get('animationDuration');
        let animationEasing = model.get('animationEasing');
        let cursor = model.get('cursor');
        let { y0, points, tickPoints } = model;
        let yExtent = extent(points, function (point) {
            return point && point[1];
        });
        let yExtent0 = yExtent[0];
        let yExtent1 = yExtent[1];
        let { stackIndex, lastStackTotal, stackTotal, lineTotal, lastLineTotal } = model;
        let stackName = model.get('stack');
        let gradient;

        let yScale = model.yAxis.scale;

        // 折线颜色分段
        if (pieces) {
            let colorStops = [];
            let globalHeight = global.getHeight();

            for (let i = 0; i < pieces.length; i++) {
                let piece = pieces[i];

                if (piece.max !== undefined) {
                    colorStops.push({
                        offset: fixRange(yScale(piece.max) / globalHeight, 0, 1),
                        color: piece.color
                    });
                } else {
                    colorStops.push({
                        offset: 0,
                        color: piece.color
                    });
                }

                if (piece.min !== undefined) {
                    colorStops.push({
                        offset: fixRange(yScale(piece.min) / globalHeight, 0, 1),
                        color: piece.color
                    });
                } else {
                    colorStops.push({
                        offset: 1,
                        color: piece.color
                    });
                }
            }

            gradient = {
                global: true,
                x: 0,
                x2: 0,
                y: 0,
                y2: globalHeight,
                colorStops
            };
        }

        if (model.get('connectNulls')) {
            points = filter(points, function (point) {
                return point;
            });
        }

        let _y0 = y0, _y0Start = y0, _y1Start = y0;

        model.lastPoints && (_y1Start = (d, index) => model.lastPoints[index] ? model.lastPoints[index][1] : d[1]);

        // 堆叠计算处理
        if (stackName) {
            let preLastPoints = model.preLastPoints;

            if (preLastPoints) {
                _y0Start = (d, index) => preLastPoints[index] ? preLastPoints[index][1] : d[1]; // y0从上次preLine的y1开始过渡
            } else { // 重新显示
                if (stackIndex) { // stackIndex非0
                    let preLineModel = getPreStackModel(model, globalModel);

                    if (preLineModel.lastPoints) {
                        _y0Start = _y1Start = (d, index) => preLineModel.lastPoints[index] ? preLineModel.lastPoints[index][1] : d[1];
                    }
                }
            }

            if (stackIndex) { // 若stackIndex非0，则_y0为前一条line
                let preLineModel = getPreStackModel(model, globalModel);

                _y0 = (d, index) => preLineModel.points[index] && preLineModel.points[index][1];
                model.preLastPoints = preLineModel.points;
            } else { // 否则_y0即为y0
                model.preLastPoints = undefined;
            }
        }

        if (areaOption.show) {
            if ((stackName && stackTotal !== lastStackTotal) || (lineTotal !== lastLineTotal)) { // stackTotal或lineTotal改变
                // 设置percent为0，添加过渡动画
                this.getShape('area') && this.setShape('area', Area, {
                    shape: {
                        percent: 0
                    }
                });
            }
            
            let maxRange = Math.max(yScale.range()[1], yScale.range()[0]);
            let shape = {
                x: d => d[0],
                y1: d => d[1],
                y0: areaSnapToBottom ? maxRange : _y0,
                y0Start: areaSnapToBottom ? maxRange : _y0Start,
                y1Start: areaSnapToBottom ? maxRange : _y1Start,
                data: points,
                curve: getCurve(curveOption),
                percent: 1,
                defined: d => d
            };
            let style = (y0 > yExtent1 || areaSnapToBottom) ? areaOption.style : reverseStyleStops(areaOption.style, (y0 < yExtent0 ? 0 : y0 - yExtent0) / (yExtent1 - yExtent0));
            
            if (gradient) {
                style = {
                    ...style,
                    fill: gradient
                }
            }

            this.setShape('area', Area,
                {
                    shape,
                    style,
                    z2: 0,
                    cursor
                },
                {
                    animation: needAnimation,
                    duration: animationDuration,
                    easing: animationEasing,
                    animateFrom: {
                        shape: {
                            percent: 0
                        }
                    }
                },
                function (shape) {
                    let areaEmphasis = model.get('area').emphasis;
                    if (areaEmphasis.style) {
                        setHoverStyle(shape, areaEmphasis.style);
                    }
                }
            );
        } else {
            this.removeShape('area');
        }

        if (lineOption.show) {
            if ((stackName && stackTotal !== lastStackTotal) || (lineTotal !== lastLineTotal)) { // stackTotal或lineTotal改变
                // 设置percent为0，添加过渡动画
                this.getShape('line') && this.setShape('line', D3Line, {
                    shape: {
                        percent: 0
                    }
                });
            }

            let shape = {
                x: function (d) { return d[0]; },
                y: function (d) { return d[1]; },
                data: points,
                yStart: _y1Start,
                percent: 1,
                curve: getCurve(curveOption),
                defined: d => d
            };
            let style = lineOption.style;

            if (gradient) {
                style = {
                    ...style,
                    stroke: gradient
                }
            }

            this.setShape('line', D3Line,
                {
                    shape,
                    style,
                    z2: 1,
                    cursor
                },
                {
                    animation: needAnimation,
                    duration: animationDuration,
                    easing: animationEasing,
                    animateFrom: {
                        shape: {
                            percent: 0
                        }
                    }
                },
                function (shape) {
                    let lineEmphasis = model.get('line').emphasis;
                    if (lineEmphasis.style) {
                        setHoverStyle(shape, lineEmphasis.style);
                    }
                }
            );

          if (model.get('showRipple')) {
            drawRipple.call(this, shape, style)
          }
        } else {
            this.removeShape('line');
        }

        if (linkOption.show) {
            let showPoints = linkOption.show === 'all' ? points : tickPoints;
            let attrs = [];
            let style = linkOption.style;
            let rstyle = reverseStyleStops(linkOption.style, 0);
            let preShowPoints;

            if (stackName && stackIndex) {
                let preLineModel = getPreStackModel(model, globalModel);
                preShowPoints = linkOption.show === 'all' ? preLineModel.points : preLineModel.tickPoints;
            }

            each(showPoints, function (point, index) {
                if (point) {
                    attrs.push(subPixelOptimizeLine({
                        shape: {
                            x1: point[0],
                            y1: preShowPoints ? preShowPoints[index][1] : y0,
                            x2: point[0],
                            y2: point[1],
                            percent: 1
                        },
                        style: y0 > point[1] ? style : rstyle,
                        key: index,
                        z2: 2,
                        cursor
                    }));
                }
            });

            this.setShapeGroup('linkGroup', Line,
                attrs,
                {
                    animation: needAnimation,
                    duration: animationDuration,
                    easing: animationEasing,
                    animateFrom: {
                        shape: {
                            percent: 0
                        }
                    }
                }
            );
        } else {
            this.removeShape('linkGroup');
        }
    }

    highlight(model, globalModel, global, payload) {
        super.highlight(...arguments);

        if (payload) {
            let { index } = payload;

            let emphasisLinkOption = model.get('link').emphasis;
            let z = model.get('z');
            let stackName = model.get('stack');
            let zlevel = model.get('zlevel');
            let data = model.getRealData();
            let points = model.points;

            let indexs = findAllIndex(data, function (d) {
                return d && d[0] === index;
            });
            let y0 = model.y0;

            if (emphasisLinkOption.show) {
                let { stackIndex } = model;
                let highlightLink = this.getShape('highlightLink');
                let linkOption = merge(clone(model.get('link').normal), emphasisLinkOption, true);
                let preShowPoints;
                let attrs = [];

                if (stackName && stackIndex) {
                    let preLineModel = getPreStackModel(model, globalModel);
                    preShowPoints = preLineModel.points;
                }

                each(indexs, function (index) {
                    let point = points[index];

                    if (!point) return;

                    let attr = {
                        shape: {
                            x1: point[0],
                            y1: preShowPoints ? preShowPoints[index][1] : y0,
                            x2: point[0],
                            y2: point[1]
                        },
                        style: reverseStyleStops(linkOption.style, y0 > point[1] ? 1 : 0),
                        z2: 10,
                        z,
                        zlevel
                    };

                    attrs.push(attr);
                });

                this.setShapeGroup('highlightLink', Line, attrs).show();
            }
        } else {
            this.getShape('line') && this.getShape('line').trigger('emphasis');
            this.getShape('area') && this.getShape('area').trigger('emphasis');
        }
    }

    downplay(model, globalModel, global, payload) {
        super.downplay(...arguments);

        if (payload) {
            if (this.getShape('highlightLink')) {
                this.getShape('highlightLink').hide();
            }
        } else {
            this.getShape('line') && this.getShape('line').trigger('normal');
            this.getShape('area') && this.getShape('area').trigger('normal');
        }
    }
}

function getPreStackModel(model, globalModel) {
    let stackName = model.get('stack');
    let { stackIndex } = model;

    return globalModel.getComponent('series', function (preModel) {
        return preModel.get('stack') === stackName && preModel.stackIndex === (stackIndex - 1);
    }, true)[0];
}

function drawRipple(shape, style) {

  let shapeData = shape.data
  let shapeLen = shapeData.length
  let lastPointX
  let lastPointY
  let i
  let existPointLen = shapeData.filter(d => d).length

  if (existPointLen <= 1) {
    this.removeShape('rippleGroup')
    return
  }
  for (i = shapeLen - 1; i >= 0; --i) {
    if (shapeData[i]) {

      lastPointX = shapeData[i][0]
      lastPointY = shapeData[i][1]
      break
    }
  }

  let rippleGroup = new Group();
  let ripplePeriod = 3000
  let delay
  let rippleLen = 2
  let ripplePath
  let sym = new Symbol({
    position: [lastPointX, lastPointY],
    z: 102,
    z2: 102,
    shape: {
      symbolType: 'circle',
      width: 4,
      height: 4,
      x: -2,
      y: -2
    },
    style: {
      fill: '#fff'
    }
  })
  for (let i = 0; i < rippleLen; ++i) {

    delay = i / rippleLen * ripplePeriod
    ripplePath = new Symbol({
      position: [lastPointX, lastPointY],
      scale: [1, 1],
      z: 101,
      z2: 101,
      shape: {
        symbolType: 'circle',
        x: -2,
        y: -2,
        width: 4,
        height: 4
      },
      style: {
        fill: style.stroke
      }
    })

    let animateScale = ripplePath.animate('', true)
      .when(ripplePeriod, {
        scale: [4, 4]
      })
      .delay(delay);
    let animateOpacity = ripplePath.animateStyle(true)
      .when(ripplePeriod, {
        opacity: 0
      })
      .delay(delay);

    animateScale.start();
    animateOpacity.start();
    rippleGroup.add(sym)
    rippleGroup.add(ripplePath);
  }

  this.set('rippleGroup', rippleGroup)
}
