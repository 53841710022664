// kline 全历史数据处理 实现类(子类)
// 请勿直接对 this._xxx 的私有成员变量 赋值或修改，
// 可调用提供的公共方法来修改
import KlineBase from './KlineBase'
import { getKLineStatus, getStockType } from '../../util/hqHelper'

export default class KlineLast extends KlineBase{

    static type = 'klineLast'

    static defaultOption = {
        urlFormatter: function({version, protocol, code, typecode, lastDays, sliceDays}) {
            var str = '';
            // 如果是数组
            if (typeof sliceDays === 'object') {
                str = sliceDays.join('_')+'last';
            }else if(typeof sliceDays === 'number'){
                str = 'last'+Math.abs(sliceDays);
            }else{
                str = `last${lastDays? lastDays:''}`;
            }

            return `${protocol}//d.10jqka.com.cn/${version}/line/${code}/${typecode}/${str}.js`;
        },

        // keepGetUrlFormatter: null,

        // 最后多少天 废弃 请用 sliceDays: -60 配置代替
        lastDays: undefined,

        // 支持两种切割获取历史k线数据方式
        // -60 --- 获取最近的60日
        // ['20170515','20180511'] --- 获取从'20170515'到'20180511'的历史k线
        sliceDays: undefined,

        // 确保是历史k线，不含今日k线
        forceHistory: true
    }
    
    constructor(){
        super(...arguments);
    }

    // last整理中，会将data中 d.today 以后的数据剔除
    arrangeHistory (d) {
        let forceHistory = this.get('forceHistory');
        // data: "20180110,50.59,51.90,50.32,50.79,5072410,263040150.00,1.924;"
        // issuePrice : "52.80"
        // name : "同花顺"
        // num : 140
        // rt : "0930-1130,1300-1500"
        // start : "20091225"
        // today : "20180807"
        // total : "2027"
        // year : {2009: 5, 2010: 239, 2011: 241, 2012: 230, 2013: 238, 2014: 242, 2015: 225, 2016: 217, 2017: 244, 2018: 146}
        let stockType = getStockType(this.get('code'));

        let result = [];
        let { 
            total: totalKlineNum,
            start: firstDate,
            issuePrice,
            name
        } = d;

        let needPopDate = d.today;

        let rr = {
            stockType,
            totalKlineNum,
            firstDate,
            // 初上市定价
            issuePrice,
            name,
            dataArray: []
        };
        let isGetTotalFirstData = false;

        if (totalKlineNum == 0 || !d.data) return rr; 

        let dataArr = d.data.split(';');

        for (let i = 0; i < dataArr.length; i++) {
            let oneDayArr = dataArr[i].split(',');
            if (forceHistory && needPopDate && needPopDate == oneDayArr[0]) {
                break;
            }
            result.push({
                t: oneDayArr[0],
                o: parseFloat(oneDayArr[1]),
                a: parseFloat(oneDayArr[2]),
                i: parseFloat(oneDayArr[3]),
                c: parseFloat(oneDayArr[4]),
                n: parseFloat(oneDayArr[5]),
                np: parseFloat(oneDayArr[6]),
                h: parseFloat(oneDayArr[7]),
                s: undefined,
                yc: undefined,
                // sj, gzqh, qh 三个市场用昨结价计算涨跌幅，涨跌额
                yj: typeof oneDayArr[8] !== 'undefined' && oneDayArr[8] !== '' ? parseFloat(oneDayArr[8]) : undefined,
                an: parseFloat(oneDayArr[9]),
                anp: parseFloat(oneDayArr[10])
            });

            result[i].s = getKLineStatus(result[i], result[i - 1]);
            if(i === 0){
                result[i].yc = result[i].o;
                isGetTotalFirstData = (firstDate == result[i].t);
            }else{
                result[i].yc = result[i-1].c;
            }
        }

        if(isGetTotalFirstData){
            result[0].yc = parseFloat(issuePrice);
        }
        rr.isGetTotalFirstData = isGetTotalFirstData
        rr.dataArray = result;
        return rr;
    }
}
