import Indicator from '../Indicator';
import { IndicatorFormula } from 'hxc3-indicator-formula';

const Formula = IndicatorFormula.getClass('rsi')
let formula = new Formula();

export default class RSI extends Indicator{
    static type = 'rsi';

    static defaultOption = {
        RSI6: {
            type: 'line',
            normal:{
                show: true, 
                style:{
                    stroke: '#C00C00', 
                    lineWidth: 1
                }
            },
            emphasis: undefined
        },
        RSI12: {
            type: 'line',
            normal:{
                show: true, 
                style:{
                    stroke: '#C0BA4D', 
                    lineWidth: 1
                }
            },
            emphasis: undefined
        },
        RSI24: {
            type: 'line',
            normal:{
                show: true, 
                style:{
                    lineWidth: 1,
                    stroke: '#FB8E04'
                }
            },
            emphasis: undefined
        },
        A: {
            type: 'line',
            normal:{
                show: true, 
                style:{
                    lineDash: [2, 4],
                    stroke: 'rgba(204,204,204,0.8)', 
                    lineWidth: 1
                }
            },
            emphasis: undefined
        },
        D: {
            type: 'line',
            normal:{
                show: true, 
                style:{
                    lineDash: [2, 4],
                    stroke: 'rgba(204,204,204,0.8)', 
                    lineWidth: 1
                }
            },
            emphasis: undefined
        }
    }

    static calculate(data){return formula.calculate(data)}

    static formatData(d, i) {
        return [i, [d.RSI6, d.RSI12, d.RSI24, d.A, d.D], d];
    }

}