/**
 * 图例
 */
import ComponentModel from '../../model/Component';
import { map, isObject, each, parsePercent, filter } from '../../util';
import { seriesActions } from '../../action/event';

export const TYPE = 'legend';

/*

图例组件展现了不同系列的标记(symbol)，颜色和名字。可以通过点击图例控制哪些系列或者系列中的哪些项不显示。

组件详细配置项如下，每个配置项都有一个默认值：

*/

export default class LegendModel extends ComponentModel {
    static type = TYPE;

    type = TYPE;

    static defaultOption = {
        data: undefined, // {Array<string>} 需与series中的name对应，否则将不显示相应图例
        show: true, // {boolean} 是否显示图例
        left: '10%', // {string|number} 图例距离左边距离，设置为'center'将水平居中
        top: '2.5%', // {string|number} 图例距离上边距离，设置为'middle'将垂直居中
        right: undefined, // {string|number} 图例距离右边距离
        bottom: undefined, // {string|number} 图例距离底部距离
        width: undefined, // {string|number} 图例整体宽度
        align: 'left', // {string} 图例中图标位于文字左边还是右边 'left' | 'right'
        type: 'plain', // {string} 图例类型：'plain' | 'scroll'
        page: { // 当type为scroll时起作用
            pageIndex: 1, // {number} 图例在第几页
            buttonItemGap: 2, // {number} 图例控制块中，按钮和页信息之间的间隔。
            buttonGap: 0, // {number} 图例控制块和图例项之间的间隔。
            iconColor: '#2f4554', // 翻页按钮的颜色。
            iconInactiveColor: '#aaa', // 翻页按钮不激活时（即翻页到头时）的颜色。
            iconSize: [14, 16], // 翻页按钮的大小。数组，如 [10, 3]，表示 [宽，高]。
            iconOffset: [0, -3], // [x, y]，x默认居右，一般不调整；主要调整y
            textStyle: {
                fill: '#333',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontFamily: 'sans-serif',
                fontSize: 16,
                textOffset: [0, -1.5] // [x, y]，x已自适应，一般不调整；主要调整y
            },
        },
        orient: 'horizontal', // {string} 图例列表的布局朝向。 'horizontal' | 'vertical'
        inactiveColor: '#ccc', // {string} 图例关闭时的颜色。
        verticalGap: 5, // {number} 每项图例之间的垂直间距
        horizontalGap: 10, // {number} 每项图例之间水平的间距
        innerGap: 5, // {number} 图例内图标与文字间距
        addLine: true, // {boolean} 对应series为line时是否添加横线
        itemWidth: undefined, // {number} 每一项宽度
        // 图标
        symbol: {
            type: undefined, // {string} 图标类型 'line' | 'rect' | 'roundRect' | 'square' | 'circle' | 'diamond' | 'pin' | 'arrow' | 'triangle'
            size: [16, 10], // {Array<number>} 图标尺寸
            color: undefined, // {string} 颜色,
            style: {} // {object}
        },
        selectedMode: 'multiple', // {string} 图例选择的模式，控制是否可以通过点击图例改变系列的显示状态。 single | multiple | true
        selected: {}, // {Object} 图例选中状态表。

        // 图例文字样式
        textStyle: {
            fontSize: 12,
            fontFamily: 'sans-serif'
        },

        padding: [10, 15, 10, 15], // {Array} 图例整体内边距
        background: { // {Object} 图例整体背景
            show: false,
            radius: 5, // {number} 背景圆角
            style: { // {Object} 样式
                lineWidth: 1,
                fill: 'rgba(128, 128, 128, 0.2)',
                stroke: '#ccc'
            }
        },

        formatter: undefined, // {Function} 图例内容格式化函数
        onClick: undefined, // {Function} 图例点击事件

        zlevel: 0, // {number} 所有图形的 zlevel 值
        z: 8 // {number} 组件的所有图形的z值。控制图形的前后顺序。z值小的图形会被z值大的图形覆盖。
    };

    update() {
        let { global, globalModel } = this;
        let canvasWidth = global.getWidth();
        let canvasHeight = global.getHeight();
        let left = this.get('left');
        let right = this.get('right');
        let width = this.get('width');
        let top = this.get('top');
        let data = this.getData();
        let selected = this.get('selected');
        let selectedMap = {};

        if (!data) {
            data = [];

            globalModel.eachComponent('series', function (model) {
                let name = model.get('name');
                if (name) {
                    data.push(name);
                }
            }, false);
        }

        selected &&
            each(selected, function(value, key) {
                selectedMap[key] = value;
            });

        // 字符串数组统一转为对象数组
        let items = map(data, item => (isObject(item) ? item : { name: item }));

        each(
            items,
            ({ name }) =>
                (selectedMap[name] =
                    !!(!selected || (selected && selected[name] !== false)))
        );

        this.selected = selectedMap;
        this.items = items;

        right = parsePercent(right, canvasWidth);
        width = parsePercent(width, canvasWidth);

        if (left === 'center' || left === 'right') {
            if (!width) width = canvasWidth;
            right = canvasWidth;
            left = canvasWidth - width;
        } else {
            left = parsePercent(left, canvasWidth);

            // 先取right
            if (right !== undefined) {
                right = canvasWidth - right;

                // 若设置了width
                if (width !== undefined) {
                    left = right - width;
                } else {
                    left = left || 0;
                    width = right - left;
                }
            } else {
                left = left || 0;

                if (width !== undefined) {
                    right = left + width;
                } else {
                    width = canvasWidth - left;
                    right = left + width;
                }
            }
        }

        if (top === 'middle') {
            top = 0;
        } else {
            top = parsePercent(top, canvasHeight);

        }

        this.position = {
            left,
            width,
            right,
            top
        };
    }

    isSelected(name) {
        return !!this.selected[name];
    }

    toggleMultiple(name, bool) {
        let { global, globalModel, selected } = this;

        // if (typeof selected[name] === 'undefined') return;

        let isSelected = bool === undefined ? selected[name] : !bool;
        let seriesModels = globalModel.getSeriesByName(name, false);

        if (seriesModels.length) {
            each(seriesModels, seriesModel => {
                if (isSelected === false) {
                    seriesModel.set('selected', true);
                    this.set({
                        selected: {
                            [name]: true
                        }
                    });
                } else {
                    let view = global.getViewOfComponentModel(seriesModel);

                    seriesModel.set('selected', false);
                    global.dispatchAction(
                        seriesModel,
                        seriesActions.unselected
                    );
                    view && view.remove();
                    this.set({
                        selected: {
                            [name]: false
                        }
                    });
                }
            });
        } else {
            globalModel.eachComponent(
                'series',
                seriesModel => {
                    let finded = seriesModel.findDataNamed(name);

                    if (finded) {
                        if (isSelected === false) {
                            seriesModel.set({
                                selected: {
                                    [name]: true
                                }
                            });
                            this.set({
                                selected: {
                                    [name]: true
                                }
                            });
                        } else {
                            seriesModel.set({
                                selected: {
                                    [name]: false
                                }
                            });
                            this.set({
                                selected: {
                                    [name]: false
                                }
                            });
                        }
                    }
                },
                false
            );
        }
    }

    toggleSingle(name) {
        let { global, globalModel, selected, items } = this;
        let isSelected = selected[name];

        // if (isSelected || typeof selected[name] === 'undefined') return;

        each(items, ({ name: _name }) => {
            let seriesModels = globalModel.getSeriesByName(_name, false);

            if (seriesModels.length) {
                each(seriesModels, seriesModel => {
                    if (_name === name) {
                        seriesModel.set('selected', true);
                        this.set({
                            selected: {
                                [_name]: true
                            }
                        });
                    } else {
                        let view = global.getViewOfComponentModel(seriesModel);

                        seriesModel.set('selected', false);
                        global.dispatchAction(
                            seriesModel,
                            seriesActions.unselected
                        );
                        view && view.remove();
                        this.set({
                            selected: {
                                [_name]: false
                            }
                        });
                    }
                });
            } else {
                globalModel.eachComponent(
                    'series',
                    seriesModel => {
                        let finded = seriesModel.findDataNamed(_name);

                        if (finded) {
                            if (_name === name) {
                                seriesModel.set({
                                    selected: {
                                        [_name]: true
                                    }
                                });
                                this.set({
                                    selected: {
                                        [_name]: true
                                    }
                                });
                            } else {
                                seriesModel.set({
                                    selected: {
                                        [_name]: false
                                    }
                                });
                                this.set({
                                    selected: {
                                        [_name]: false
                                    }
                                });
                            }
                        }
                    },
                    false
                );
            }
        });
    }
}
