/**
 * 绘图工具
 */
import SeriesModel from '../../model/Series';
import { each, map, findIndex, parsePercent } from '../../util';
import scales from '../../component/axis/scale';

export const TYPE = 'sketch';

export default class SketchModel extends SeriesModel {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量
    
    static emphasisList = ['style', 'iconStyle'];

    static defaultOption = {
        show: true, // {boolean} 是否显示icon

        left: '10%', // {string|boolean} 位置左
        top: 20, // {string|boolean} 位置上

        // {string|boolean} 绘图工具类型，false时关闭，true时只编辑
        paintTool: false, // false || delete || rect || verticalRect || horizontalRect || line || verticalLine || horizontalLine || ellipse || arrowLine
        shapeList: [],

        currentEditIndex: undefined,
        inGrid: false, // {boolean} 是否只在grid内绘制
        inAxis: true, // {boolean} 是否只在axis内绘制

        disableWhenEnd: false, // {boolean} 是否在绘制结束时关闭绘图
        confirmWhenDelete: undefined, // {Function} 是否在删除前确认

        style: {
            normal: { // {Object} 普通样式
                fill: 'rgba(49, 207, 214, 0.1)',
                stroke: 'rgb(49, 207, 214)',
                lineWidth: 1
            },
            emphasis: { // {Object} 高亮样式
                lineWidth: 2
            }
        },

        icon: ['line', 'verticalLine', 'horizontalLine', 'rect', 'verticalRect', 'horizontalRect', 'ellipse', 'arrowLine', 'back', 'delete'], // {Array} 要显示的icon
        iconType: { // {Object} 图标类型
            back: 'path://M15.0707952,6.00027263 C20.0087669,6.03831644 24,10.053057 24,15 C24,19.9705627 19.9705627,24 15,24 C10.0294373,24 6,19.9705627 6,15 L8,15 C8,18.8659932 11.1340068,22 15,22 C18.8659932,22 22,18.8659932 22,15 C22,11.1340068 18.8659932,8 15,8 L14.5857864,8 L16.6568542,10.0710678 L15.2426407,11.4852814 L12.4142136,8.65685425 L11,7.24264069 L15.2426407,3 L16.6568542,4.41421356 L15.0707952,6.00027263 Z',
            delete: 'path://M21,22 L21,24 L11,24 L9,24 L9,11 L11,11 L11,22 L19,22 L19,11 L21,11 L21,22 Z M17,8 L23,8 L23,10 L7,10 L7,8 L13,8 L13,6 L17,6 L17,8 Z M13,11 L14,11 L14,21 L13,21 L13,11 Z M16,11 L17,11 L17,21 L16,21 L16,11 Z',
            rect: 'path://M22,8 L8,8 L8,22 L22,22 L22,8 Z M24,6 L24,24 L6,24 L6,6 L24,6 Z',
            verticalRect: 'path://M6,15 L6,6 L24,6 L24,8 L8,8 L8,15 L6,15 Z M6,22 L22,22 L22,15 L24,15 L24,24 L6,24 L6,22 Z M22,10 L24,10 L24,13 L22,13 L22,10 Z M6,17 L8,17 L8,20 L6,20 L6,17 Z',
            horizontalRect: 'path://M8,22 L15,22 L15,24 L6,24 L6,22 L6,6 L8,6 L8,22 Z M24,8 L24,24 L22,24 L22,8 L15,8 L15,6 L24,6 L24,8 Z M10,6 L13,6 L13,8 L10,8 L10,6 Z M17,22 L20,22 L20,24 L17,24 L17,22 Z',
            line: 'path://M20.5563492,11 L11,20.5563492 L11,23 L7,23 L7,19 L9.72792206,19 L19,9.72792206 L19,7 L23,7 L23,11 L20.5563492,11 Z',
            verticalLine: 'path://M14,5 L14,25 L16,25 L16,5 M5,5 M25,25 Z',
            horizontalLine: 'path://M5,14 L25,14 L25,16 L5,16 M5,5 M25,25 Z',
            ellipse: 'path://M15,23 C19.418278,23 23,19.418278 23,15 C23,10.581722 19.418278,7 15,7 C10.581722,7 7,10.581722 7,15 C7,19.418278 10.581722,23 15,23 Z M15,25 C9.4771525,25 5,20.5228475 5,15 C5,9.4771525 9.4771525,5 15,5 C20.5228475,5 25,9.4771525 25,15 C25,20.5228475 20.5228475,25 15,25 Z',
            arrowLine: 'path://M19.7279221,9 L18,9 L18,7 L23,7 L23,9 L23,12 L21,12 L21,10.5563492 L9.41421356,22.1421356 L8,20.7279221 L19.7279221,9 Z'
        },
        iconStyle: { // 图标样式
            normal: { // {Object} 普通样式
                // stroke: '#666',
                fill: '#d8d8d8'
            },
            emphasis: { // {Object} 高亮样式
                fill: '#9b9b9b'
            }
        },

        zlevel: 0, // canvas层级
        z: 9 // 同一层canvas内层级
    };

    update() {
        let { globalModel, global } = this;
        let globalWidth = global.getWidth();
        let globalHeight = global.getHeight();

        global.isEnableSketch = !!this.get('paintTool');

        this.left = parsePercent(this.get('left'), globalWidth);
        this.top = parsePercent(this.get('top'), globalHeight);
    }

    popShape() {
        let { globalModel, global } = this;
        let shapeList = this.get('shapeList');

        shapeList.pop();
        this.set('shapeList', shapeList);
    }

    deleteShape(currentShape) {
        let shapeList = this.get('shapeList');
        let findedIndex = findIndex(shapeList, function (shape) {
            return currentShape.shapeIndex === shape.shapeIndex;
        });

        if (typeof findedIndex === 'number') {
            shapeList.splice(findedIndex, 1);
            this.set('shapeList', shapeList);
        }
    }
}