/**
 * 关系布局 by zp
 * 关系图
 */
import SeriesModel from '../../model/Series';
import { each, parsePercent } from '../../util';

export const TYPE = 'elbow';
export default class ElbowModel extends SeriesModel {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    static emphasisList = ['label', 'node', 'link'];

    static defaultOption = {
        zlevel: 0, // canvas层级
        z: 1, // 同一层canvas内层级

        label: {
            normal: {
                show: true,
                silent: true,
                formatter: undefined,
                style: {
                    fill: '#5097f8',
                    opacity: 0.8
                }
            },
            emphasis: {}
        },

        node: {
            normal: {
                show: true,
                type: 'circle',
                size: 10,
                style: {
                    stroke: '#5097f8',
                    fill: 'white'
                }
            }
        },

        link: {
            normal: {
                show: true,
                style: {
                    stroke: '#67cacb'
                }
            }
        }
    };

    initialized = false;

    update() {
    }
}
