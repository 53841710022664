import ChartView from '../../view/Chart';
import { TYPE } from './CustomModel';
import { each } from '../../util';


export default class CustomView extends ChartView {

    static type = TYPE;

    type = TYPE;

    constructor(model) {
        super(...arguments);

        let viewFunc = model.get('view');

        each(viewFunc, (func, name) => {
            this[name] = func;
        });
    }
  //render(model, globalModel, global) {
    //let viewFunc = model.get('view');
    //viewFunc.render.call(this, ...arguments)
  //}
}
