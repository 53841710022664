import ChartView from '../../view/Chart';
import { TYPE } from './EffectScatterModel';
import { Text, formatTextStyle } from '../../util/graphic';
import EffectSymbol from './EffectSymbol';
import { each, clone, merge, findAllIndex, isFunction, parseSize } from '../../util';
import { seriesActions } from '../../action/event';

export default class EffectScatterView extends ChartView {

    static type = TYPE;

    type = TYPE;

    renderAllSymbol(model, globalModel, global, showPoints) {
        let group = this.group;
        let symbolOption = model.get('symbol').normal;
        let { isScatter } = model;
        let cursor = model.get('cursor');
        let attrs = [];

        each(showPoints, function (point, index) {
            if (point) {
                let itemData = point[2];

                let attr = {
                    shape: getScatterShape(model, itemData, false),
                    position: [point[0], point[1]],
                    style: getScatterStyle(model, itemData, false, point[3]),
                    key: point[3],
                    seriesIndex: model.index,
                    dataIndex: point[3],
                    rectHover: true,
                    cursor,
                    model
                };

                attrs.push(attr);
            }
        });

        this.setShapeGroup('symbolGroup', EffectSymbol, attrs);
    }

    render(model, globalModel, global) {
        this.remove();

        this.renderAllSymbol(model, globalModel, global, model.points);
    }
}

function getScatterShape(model, itemData, isEmphasis) {
    let symbolOption = model.get('symbol');

    symbolOption = isEmphasis ? merge(clone(symbolOption.normal), symbolOption.emphasis, true) : symbolOption.normal;

    let symbolType = symbolOption.type;
    let symbolSize = symbolOption.size;
    let symbolOffset = symbolOption.offset;
    let _symbolSize = parseSize(typeof symbolSize === 'function' ? symbolSize(itemData) : symbolSize);

    return {
        symbolType: symbolType,
        x: -_symbolSize.width / 2 + symbolOffset[0],
        y: -_symbolSize.height / 2 + symbolOffset[1],
        width: _symbolSize.width,
        height: _symbolSize.height
    }
}

function getScatterStyle(model, itemData, isEmphasis, dataIndex) {
    let symbolOption = model.get('symbol');
    let labelOption = model.get('label');
    let normalSymbol = symbolOption.normal.style;
    let value = itemData[1];
    let item = { data: itemData, index: dataIndex - model.startIndex, dataIndex };
    let visualIndex = model.get('$visualMapIndex');
    let style, labelStyle;

    style = isFunction(normalSymbol) ? normalSymbol(value, item) : clone(normalSymbol);

    if (visualIndex !== undefined) {
        let visualMapModel = model.globalModel.getComponentByIndex('visualMap', visualIndex);

        if (model.get('$calendarIndex') !== undefined || model.get('type') === 'line') {
            style.fill = visualMapModel.getColorStop(itemData[1], style.fill);
        } else {
            style.fill = visualMapModel.getColorStop(itemData[2], style.fill);
        }
    }

    if (isEmphasis) {
        let emphasisSymbol = symbolOption.emphasis.style;

        emphasisSymbol = isFunction(emphasisSymbol) ? emphasisSymbol(value, item) : clone(emphasisSymbol);
        style = merge(style, emphasisSymbol, true);
    }

    if (isEmphasis) {
        let emphasisLabel = labelOption.emphasis;

        if (emphasisLabel.show) {
            let normalLabel = labelOption.normal;
            let labelFormatter = emphasisLabel.formatter || normalLabel.formatter;
            let _labelNormalStyle = isFunction(normalLabel.style) ? normalLabel.style(value, item) : clone(normalLabel.style);
            let _labelStyle = merge(_labelNormalStyle, emphasisLabel.style, true);

            labelStyle = {
                text: labelFormatter ? labelFormatter(value, item) : value,
                ...formatTextStyle(formatXXXStyle(_labelStyle, value, item))
            };
        }
    } else {
        let normalLabel = labelOption.normal;

        if (normalLabel.show) {
            let labelFormatter = normalLabel.formatter;
            let _labelNormalStyle = isFunction(normalLabel.style) ? normalLabel.style(value, item) : clone(normalLabel.style);

            labelStyle = {
                text: labelFormatter ? labelFormatter(value, item) : value,
                ...formatTextStyle(formatXXXStyle(_labelNormalStyle, value, item))
            };
        }
    }

    if (labelStyle) {
        style = merge(style, labelStyle);
    }

    return style;
}

function formatXXXStyle(style, value, item) {
    let _style = {};

    each(style, function (v, k) {
        if (isFunction(v)) {
            _style[k] = v(value, item);
        } else {
            _style[k] = v;
        }
    });

    return _style;
}
