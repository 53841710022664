import {
    default as DataProvider
} from '../DataProvider';
import {
    getStockType
} from '../../util/hqHelper'

function checkHqData(data) {
    return data.dataArray && data.dataArray.length && data.dataArray[0].nowp && data.preClosePrice
}
// var test = {
//     sj_mAUTD: {
//         name: "mAu(T+D)",
//         open: 1,
//         stop: 0,
//         rt: "2000-0230,0900-1130,1330-1530",
//         tradeTime: [
//             "2000-0230",
//             "0900-1130",
//             "1330-1530"
//         ],
//         pre: "273.17",
//         date: "20181127",
//         data: "",
//         dotsCount: 663,
//         dates: [
//             "20181127",
//             "20181128"
//         ]
//     }
// }

/**
 * 数据处理-历史分时
 * 包含以下行情逻辑
 * 直接请求全量数据
 * 继承 DataProvider
 * 需要把整理的数据用 this.fetchSuccess(data) 通知出去
 * !!不可以直接对this._data 进行赋值
 *
 * @extends DataProvider
 */
export default class FsHistory extends DataProvider {

    static type = 'fsHistory'

    /**
     * 默认配置
     *
     * ```
     code:'hs_300033',
     // 获取方式
     fetchType: 'jqXhrWithoutCallback',
     // 接口版本
     version: 'v6',
     // 类型再细分
     dType: undefined,
     // 是否持续更新
     isKeepingGet: true,
     urlFormatter: function({version, protocol, code}) {
         return protocol+'//d.10jqka.com.cn/'+version+'/time/'+code+'/last.js';
     },
     keepGetUrlFormatter: function({version, protocol, code, todayStart}) {
         return protocol+'//d.10jqka.com.cn/'+version+'/time/'+code+'/'+todayStart+'.js';
     },
     intervalTime: 60000
     * ```
     * @defalut
     */
    static defaultOption = {
        code: 'hs_300033',
        // 全量地址
        urlFormatter: function ({
            version,
            protocol,
            code,
            dates
        }) {
            let times
            // 目前支持某天历史查询
            times = '/' + dates[0] + '/' + dates[0]
            return protocol + '//d.10jqka.com.cn/' + version + '/time/' + code + times + '/fmt/history.js';
        }
    }

    constructor() {
        super(...arguments);

        // 当前是否实时更新
        // 根据行情数据返回的情况，程序会自行调整是否需要实时更新,
        this.keepingGetHandle;
        // 数据的值异常
        this.dataError = false
    }

    // 子类实现
    // 全量数据整理
    arrangeData(originalD) {
        let r = {};
        let code = this.get('code')
        r[code] = {}
        let d = originalD[code]
        let stockType = getStockType(code);
        let info = d.dataArray[0]
        let {
            dates: formatterDate
        } = info;
        let datesLength = formatterDate.length;
        let preClosePrice = info.pre;
        let data = info.data.split(';');
        let dataArray = [];
        let tempArr = [],
            temp, todayStart = '';
        // 0930,65.02,12619793,65.354,193100
        let formatData = function () {
            let dates;
            return function (d1, beforedate, afterdate) {
                if (!dates) {
                    dates = beforedate;
                }
                if (d1[0] == '0000') {
                    dates = afterdate;
                }

                let nowp = parseFloat(d1[1] ? d1[1] : 0);
                let av = parseFloat(d1[3] ? d1[3] : d1[1]);
                let nowpPct = (nowp - preClosePrice) / preClosePrice;
                let avPct = (av - preClosePrice) / preClosePrice;

                return {
                    s: undefined,
                    't': dates + ' ' + d1[0],
                    // 现价
                    nowp,
                    // 成交额
                    'np': parseInt(d1[2] ? d1[2] : 0),
                    // 均价
                    av,
                    // 成交量
                    'n': parseInt(d1[4] ? d1[4] : 0),
                    // 现价百分比
                    nowpPct,
                    // 均价百分比
                    avPct
                };
            }
        }();

        let nowpMax = -Infinity;
        let nowpMin = Infinity;
        let avMax = -Infinity;
        let avMin = Infinity;
        let nMax = -Infinity;
        let nMin = Infinity;
        // 如果昨收价格和 分时第一个点的均价都不存在，判断为未上市的股票
        if ((!preClosePrice && (!data[0].split(',')[2] || (data[0].split(',')[2] == 0))) || !data[0].split(',')[1] || data[0].split(',')[1] == 0) {
            this.fetchFail({
                message: '股票未上市'
            })
        } else {
            if (data.length && data[0]) {
                for (let i = 0; i < data.length; i++) {
                    tempArr = data[i].split(',');
                    temp = formatData(tempArr, formatterDate[0], formatterDate[datesLength - 1]);

                    // 计算每个点对应 成交量的颜色
                    let lastObj = dataArray[dataArray.length - 1];
                    if (i == 0) {
                        temp.s = (temp.nowp - preClosePrice) > 0 ? 'ab' : ((temp.nowp - preClosePrice) < 0 ? 'be' : 'eq');
                    } else {
                        temp.s = (temp.nowp - lastObj.nowp) > 0 ? 'ab' : ((temp.nowp - lastObj.nowp) < 0 ? 'be' : 'eq');
                    }

                    nowpMax = Math.max(temp.nowp, nowpMax);
                    nowpMin = Math.min(temp.nowp, nowpMin);

                    avMax = Math.max(temp.av, avMax);
                    avMin = Math.min(temp.av, avMin);

                    nMax = Math.max(temp.n, nMax);
                    nMin = Math.min(temp.n, nMin);

                    if (tempArr[1] && dataArray.length && dataArray[dataArray.length - 1].t && tempArr[0] == todayStart) {
                        dataArray.pop();
                    }

                    dataArray.push(temp);
                }

                formatData = null;

                todayStart = dataArray[dataArray.length - 1].t.split(' ')[1];
            }
        }
        r[code] = {
            stockType,
            afterTradeTime: d.afterTradeTime,
            // 交易时间 : ["0930-1130", "1300-1500"]
            tradeTime: d.tradeTime,
            isTrading: d.isTrading || 0,
            // 股票代码
            code,
            // 各地时间换算成北京时间，跨天展示问题
            formatterDate,
            // 昨收价格
            preClosePrice,
            // 分时一天交易结束总共点数
            totalTimeNum: d.dotsCount,
            // 股票名称
            name: d.name,
            // 通过最大值最小值的分析，由此可得出 数据状态
            max: {
                'nowp': nowpMax,
                'av': avMax,
                'n': nMax
            },
            min: {
                'nowp': nowpMin,
                'av': avMin,
                'n': nMin
            },
            // 是否停盘
            stop: d.stop || 0,
            // 当天是否开市日(双休日不开市)
            open: d.open || 0,
            // 更新到的最后时刻
            todayStart,
            // 整理后的数据
            dataArray: dataArray.length ? dataArray : []
        };
        if (!checkHqData(r[code])) {
            this.dataError = true;
        }

        return r;
    }

    /**
     * 初始化
     */
    init() {
        let that = this;
        // let fetchType = this.get('fetchType');
        let req1Url = this.get('urlFormatter')({
            ...this.option
        });
        // let date = this.get('date')
        let {
            url,
            callbackName
        } = this.getHqCallbackNameAndUrl(req1Url);

        this.fetchRemote(url, {
                jsonpCallbackFunction: callbackName
            })
            .then(function (d) {
                // d = test;
                let r = that.arrangeData(d);
                if (that.dataError) {
                    that.fetchFail({
                        message: '数据正常返回，内容异常'
                    }, r)
                    // 不再执行下面的更新操作
                    return false;
                } else {
                    that.fetchSuccess(r);
                }
            }).catch(function (ex) {
                that.fetchFail(ex);
            })
    }
}
