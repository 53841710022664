import {
    Text,
    Rect,
    setHoverStyle,
    subPixelOptimizeRect,
    formatTextStyle,
    colorTool
} from '../../util/graphic';
import { each, merge, isFunction } from '../../util';
import ChartView from '../../view/Chart';
import { seriesActions } from '../../action/event';
import { TYPE } from './TreemapModel'; // 使用Model中定义的TYPE，与model的type值保持一致

export default class TreemapView extends ChartView {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    constructor(model, globalModel, global) {
        super(...arguments);

        let roam = this.roam,
            gridModel;

        if (roam.inGrid) {
            let gridIndex = model.get('$gridIndex');

            gridModel = globalModel.getComponentByIndex('grid', gridIndex);
        }

        global.on('mousewheel', ({ offsetX, offsetY, wheelDelta }) => {
            if (
                (!gridModel || gridModel.isPointInGrid(offsetX, offsetY)) &&
                roam.enable
            ) {
                model.dirty();
            }
        });
    }

    render(model, globalModel, global) {
        let { targetRoot, position: { left, top } } = model;
        let leafDepth = model.get('leafDepth');
        let drillDownIcon = model.get('drillDownIcon');
        let value = model.get('value');
        let labelOpt = model.get('label');
        let upperLabelOpt = model.get('upperLabel');
        let itemStyle = model.get('itemStyle');
        let nodeClick = model.get('nodeClick');
        let attrs = [];
        let startDep = targetRoot.depth;
        let endDep = startDep + leafDepth;
        let groupScale = this.group.scale || [1, 1];
        let labelNormal = labelOpt.normal;
        let upperLabelNormal = upperLabelOpt.normal;
        let itemNormal = itemStyle.normal;
        let labelFormatter =
            labelNormal.formatter ||
            function(data, node) {
                return (
                    (node.children && drillDownIcon
                        ? drillDownIcon + ' '
                        : '') + data.name
                );
            };
        let upperLabelFormatter =
            labelNormal.formatter ||
            function(data, node) {
                return data.name;
            };
        let animateFrom = [];
        let treemapGroup = this.getShape('treemap');

        function findParentShape(path) {
            let parentShape;

            path = path.split('/');
            path.pop();

            while (path.length) {
                parentShape = treemapGroup.childOfName(path.join('/'));

                if (parentShape) {
                    break;
                } else {
                    path.pop();
                }
            }

            return parentShape;
        }

        function addNode(node) {
            let { x0, x1, y0, y1, parent, data, depth } = node;
            let path = data._path;

            if (treemapGroup) {
                let parentShape = findParentShape(path);

                if (parentShape) {
                    let { x, y } = parentShape.shape;

                    animateFrom.push({
                        shape: {
                            x,
                            y,
                            width: 0,
                            height: 0
                        }
                    });
                } else {
                    animateFrom.push({});
                }
            } else {
                animateFrom.push({});
            }

            let textStyle = {
                text: ''
            };

            if (
                labelNormal.show &&
                (!data.children || (leafDepth && node.depth === leafDepth))
            ) {
                let labelStyle = labelNormal.style;

                isFunction(labelStyle) && (labelStyle = labelStyle(data, node));

                textStyle = globalModel.getFormattedText({
                    text: labelFormatter(data, node),
                    truncate: {
                        outerWidth: (x1 - x0) * groupScale[0],
                        outerHeight: (y1 - y0) * groupScale[1]
                    },
                    position: 'inside',
                    ...labelStyle
                });
            } else if (
                upperLabelNormal.show &&
                data.children &&
                (!leafDepth || (leafDepth && node.depth < leafDepth))
            ) {
                textStyle = globalModel.getFormattedText({
                    text: upperLabelFormatter(data, node),
                    truncate: {
                        outerWidth: (x1 - x0) * groupScale[0],
                        outerHeight: (y1 - y0) * groupScale[1]
                    },
                    position: 'insideTopLeft',
                    ...upperLabelNormal.style
                });
            }

            let _itemStyle = itemNormal;

            isFunction(_itemStyle) && (_itemStyle = _itemStyle(data, node));

            attrs.push(
                subPixelOptimizeRect({
                    shape: {
                        x: x0,
                        y: y0,
                        width: x1 - x0,
                        height: y1 - y0
                    },
                    position: [left, top],
                    style: {
                        fill: data.color,
                        ...textStyle,
                        ..._itemStyle
                    },
                    data: {
                        value: node.value,
                        ...data,
                        parent
                    },
                    key: path,
                    z2: depth
                })
            );
        }

        function travelChildren(node) {
            if (leafDepth) {
                if (node.depth < endDep) {
                    addNode(node);
                    if (node.children) {
                        each(node.children, function(child) {
                            travelChildren(child);
                        });
                    }
                } else if (node.depth === endDep) {
                    addNode(node);
                }
            } else {
                addNode(node);
                if (node.children) {
                    each(node.children, travelChildren);
                }
            }
        }

        travelChildren(targetRoot);

        this.setShapeGroup(
            'treemap',
            Rect,
            attrs,
            {
                animation: model.get('animation'),
                duration: model.get('animationDuration'),
                easing: model.get('animationEasing'),
                animateFrom,
                animateList: {
                    shape: {
                        x: 0,
                        y: 0,
                        width: 0,
                        height: 0
                    }
                },
                animateLeave: {
                    style: {
                        opacity: 0
                    }
                }
            },
            function(group) {
                group.on('click', function(e) {
                    let nodeClick = model.get('nodeClick');

                    switch (nodeClick) {
                        case 'link':
                            // eslint-disable-next-line no-case-declarations
                            let link = e.target.data.link;

                            if (link) {
                                window.open(link, '_blank');
                            }
                            break;
                        case 'zoomToNode':
                            if (e.target.data.children) {
                                model.set('targetPath', e.target.data._path);
                            }
                            break;
                        case false:
                            break;
                    }

                    global.dispatchAction(model, seriesActions.itemClick, e);
                });
            }
        );

        renderBreadcrumb.call(this, model, globalModel);
    }
}

function renderBreadcrumb(model, globalModel) {
    let breadcrumb = model.get('breadcrumb');

    if (!breadcrumb.show) return;

    let { targetPath, position } = model;
    let {
        textStyle,
        style,
        minWidth,
        maxWidth,
        height,
        align,
        left,
        top
    } = breadcrumb;
    let attrs = [];

    if (left === undefined) {
        left = position.left + position.width / 2;
    }
    if (top === undefined) {
        top = position.bottom + 20;
    }

    targetPath = targetPath.split('/');

    let curPath = '';
    let curX = left;

    each(targetPath, function(path) {
        let text = new Text(
            globalModel.getFormattedText({
                style: {
                    text: path,
                    ...textStyle.normal
                }
            })
        );
        let width = text.getBoundingRect().width;

        width = Math.max(Math.min(maxWidth, width), minWidth);

        curPath = curPath ? curPath + '/' + path : path;

        attrs.push({
            shape: {
                x: curX,
                y: top,
                width,
                height
            },
            style: {
                ...globalModel.getFormattedText({
                    text: path,
                    truncate: {
                        outerWidth: width,
                        outerHeight: height
                    },
                    ...textStyle.normal
                }),
                ...style.normal
            },
            data: {
                path: curPath
            }
        });

        curX += width;
    });

    let breadGroup = this.setShapeGroup(
        'breadcrumb',
        Rect,
        attrs,
        undefined,
        function(shapeGroup) {
            shapeGroup.on('click', function(e) {
                let { target } = e;

                model.set('targetPath', target.data.path);
            });
        }
    );

    if (align === 'center') {
        let breadGroupWidth = breadGroup.getBoundingRect().width;

        breadGroup.attr('position', [-breadGroupWidth / 2, 0]);
    }
}
