import { Text, Circle, formatTextStyle } from '../../util/graphic';
import { each, parseSize, isFunction } from '../../util';
import { Symbol } from '../../util/symbol';
import ChartView from '../../view/Chart';
import D3Link from '../../shape/D3Link';
import { TYPE } from './TreeModel'; // 使用Model中定义的TYPE，与model的type值保持一致

export default class TreeView extends ChartView {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    render(model, globalModel, global) {
        let { root, position } = model;
        let orient = model.get('orient');
        let layout = model.get('layout');
        let labelOpt = model.get('label').normal;
        let labelStyle = labelOpt.style;
        let labelSilent = labelOpt.silent;
        let labelFormatter = labelOpt.formatter;
        let nodeOpt = model.get('node').normal;
        let nodeStyle = nodeOpt.style;
        let nodeSize = nodeOpt.size;
        let nodeType = nodeOpt.type;
        let linkOpt = model.get('link').normal;
        let animation = model.get('animation');
        let { left, top } = position;
        let nodes = root.descendants();
        let links = root.links();
        let nodeAttrs = [],
            linkAttrs = [],
            textAttrs = [],
            nodeAnimateFrom = [],
            linkAnimateFrom = [],
            textAnimateFrom = [];

        each(nodes, function(node) {
            let {
                _x,
                _y,
                _id,
                children,
                _children,
                parent,
                _gapX,
                _gapY
            } = node;

            let { width: nodeWidth, height: nodeHeight } = parseSize(
                isFunction(nodeSize) ? nodeSize(node) : nodeSize
            );
            let _nodeType = isFunction(nodeType) ? nodeType(node) : nodeType;
            let _nodeStyle = isFunction(nodeStyle)
                ? nodeStyle(node)
                : nodeStyle;

            if (nodeOpt.show) {
                nodeAttrs.push({
                    shape: {
                        symbolType: _nodeType,
                        x: -nodeWidth / 2,
                        y: -nodeHeight / 2,
                        width: nodeWidth,
                        height: nodeHeight
                    },
                    position: [_x, _y],
                    style: _nodeStyle,
                    z2: 1,
                    key: _id,
                    node,
                    data: node.data
                });

                nodeAnimateFrom.push(
                    parent
                        ? {
                              position: [
                                  (parent._x + _x) / 2,
                                  (parent._y + _y) / 2
                              ]
                          }
                        : {
                              position: [_x, _y]
                          }
                );
            }

            if (labelOpt.show) {
                let textStyle, rotation;
                let _labelStyle = isFunction(labelStyle)
                    ? labelStyle(node)
                    : labelStyle;

                if (layout === 'radial') {
                    let ang = (node.x + 360) % 360;
                    textStyle = {
                        textAlign: ang < 180 && ang > 0 ? 'left' : 'right',
                        textVerticalAlign: 'center'
                    };
                    rotation =
                        (node.x / 180 + (ang < 180 && ang > 0 ? -0.5 : 0.5)) *
                        Math.PI;
                } else {
                    if (orient === 'horizontal') {
                        textStyle = {
                            textAlign: 'right',
                            textVerticalAlign: 'center'
                        };
                        rotation = 0;
                    } else {
                        textStyle = {
                            textAlign: 'left',
                            textVerticalAlign: 'center'
                        };
                        rotation = -Math.PI / 2 * 3;
                    }
                }

                labelOpt.rotate !== undefined && (rotation = labelOpt.rotate);
                
                textAttrs.push(
                    globalModel.getFormattedText({
                        style: {
                            text: labelFormatter ? labelFormatter(node.data.name, node.data) : node.data.name,
                            ...textStyle,
                            ..._labelStyle
                        },
                        silent: labelSilent,
                        position: [_gapX, _gapY],
                        // origin: [_gapX, _gapY],
                        rotation,
                        z2: 2,
                        key: _id,
                        data: node.data
                    })
                );

                textAnimateFrom.push(
                    parent
                        ? {
                              position: [
                                  (parent._gapX + _gapX) / 2,
                                  (parent._gapY + _gapY) / 2
                              ]
                          }
                        : {
                              position: [_gapX, _gapY]
                          }
                );
            }
        });

        this.setShapeGroup(
            'nodes',
            Symbol,
            nodeAttrs,
            {
                animation,
                animateFrom: nodeAnimateFrom
            },
            function(nodeGroup) {
                nodeGroup.on('click', function(e) {
                    let expandAndCollapse = model.get('expandAndCollapse');
                    let { target: { node } } = e;

                    global.dispatchAction(model, 'NODE_CLICK', e);

                    if (expandAndCollapse) {
                        if (node.children) {
                            node._children = node.children;
                            node.children = null;
                            model.dirty();
                        } else if (node._children) {
                            node.children = node._children;
                            model.dirty();
                        }
                    }
                });
            }
        );
        this.setShapeGroup('texts', Text, textAttrs, {
            animation,
            animateFrom: textAnimateFrom
        }, function(labelGroup) {
          labelGroup.on('click', function(e) {
            global.dispatchAction(model, 'LABEL_CLICK', e);
          });
        });

        if (linkOpt.show) {
            each(links, function(link) {
                let { source, target } = link;

                linkAttrs.push({
                    shape: {
                        source: [source._x, source._y],
                        target: [target._x, target._y],
                        orient
                    },
                    style: linkOpt.style,
                    z2: 0,
                    key: source._id + '-' + target._id
                });

                linkAnimateFrom.push({
                    shape: {
                        source: [source._x, source._y],
                        target: [source._x, source._y]
                    }
                });
            });
        }

        this.setShapeGroup('links', D3Link, linkAttrs, {
            animation,
            animateFrom: linkAnimateFrom
        });
    }
}
