/**
 * 矩形条旋转 by zp
 */
import SeriesModel from '../../model/Series';

const TYPE = 'arcbar';

export default class ArcModel extends SeriesModel {
    static type = TYPE;

    type = TYPE;
}

ArcModel.defaultOption = {
    zlevel: 0,
    z: 1,

    // 默认全局居中
    center: ['50%', '50%'], 
    // 半径
    radius: [10, 20],

    // 标题
    title : {
        // x, y，单位px
        offsetCenter: [0, 0],
        textStyle: { // 其余属性默认使用全局文本样式，详见TEXTSTYLE
            color: '#333',
            fontStyle: 'normal',
            fontFamily: 'sans-serif',
            fontWeight: 'normal',
            fontSize: 14
        }
    },

    circleStyle: {
        maxAngle: 270
    },
    hoverStyle: {
        formatter: function (d) {
            return d;
        },

        textStyle: {
            // 'center',
            position: null,
            color: '#333',
            fontFamily: 'sans-serif',
            fontWeight: 'normal',
            fontSize: 12
        }
    },

    itemStyle: {
        normal: {
            stroke: '#333',
            fill: '#333',
            cornerRadius: 0
        }
    }
};
