/** @module D3Charts */

import * as zrender from 'zrender/src/zrender';
import View from './view/View';
import ChartView from './view/Chart';
import ComponentView from './view/Component';
import ComponentModel from './model/Component';
import SeriesModel from './model/Series';
import * as util from './util';
import * as graphic from './util/graphic';
import * as symbol from './util/symbol';
import GlobalModel from './model/Global';
import Eventful from 'zrender/src/mixin/Eventful';
import * as colorTool from 'zrender/src/tool/color';
import env from 'zrender/src/core/env';
// import * as d3Time from 'd3-time';
import * as action from './action/event';
import Indicator from './chart/hqIndicator/Indicator'
import { VERSION } from './configs'
import { track, doStat, tongjiDebounce } from './util/tracking'


let { isString, each, mixin, findIndex, isFunction, isArray } = util;
const PREFIX = '__';

let instances = {}; // init生成的实例
let idBase = new Date() - 0;

let event = new Eventful();
let instanceActions = action.instanceActions;

const MOUSE_EVENT_NAMES = [
    'click',
    'dblclick',
    'mouseover',
    'mouseout',
    'mousemove',
    'mousedown',
    'mouseup',
    'globalout',
    'mousewheel',
    'pinch',
    'contextmenu'
];

/**
 * Charts类
 *
 * @class Charts
 * @extends {Eventful}
 */
class Charts extends Eventful {
    id = PREFIX + idBase++;

    // view视图存储对象
    _viewMap = {};

    // 全局global model
    _model = undefined;

    // 是否开启编辑
    isEnableEdit = false;

    // 是否开启绘图
    isEnableSketch = false;

    /**
     *
     * @param {string|Dom} dom 容器的id或者dom元素
     * @param {string} theme 主题
     * @param {Object} opts 其他选项, opts.datavTrackId 表示统计id前缀
     */
    constructor(dom, theme, opts = {}) {

        window._thsclog && window._thsclog({name: 'datav-d3charts',ver: VERSION});

        tongjiDebounce(opts);

        super(...arguments);

        this._dom = isString(dom) ? document.getElementById(dom) : dom; // 容器

        let zr = (this._zr = zrender.init(this._dom, opts)); // 初始化zr实例

        opts.backgroundColor && zr.setBackgroundColor(opts.backgroundColor); // 设置画布背景颜色

        this._model = new GlobalModel(null, null, null, this); // 全局管理model

        this._actionQueue = []; // 事件队列

        this.initEvents();

        // 每一帧
        zr.animation.on(
            'frame',
            function () {
                if (this.isError()) return; // 全局有错误，不再进行更新，防止死循环

                try {
                    let actionQueue = this._actionQueue;

                    // 事件在每帧进行调用
                    while (actionQueue.length) {
                        let action = actionQueue.shift();
                        this.trigger(action[0], action[1]);
                    }

                    if (this._model.isDirty()) {
                        // 根据是否为脏来进行更新
                        this.update();
                        // this.update.call(this);
                    }
                } catch (e) {
                    this.error();
                    throw e;
                }
            },
            this
        );

    }

    // 代理zrender事件
    initEvents () {
        each(MOUSE_EVENT_NAMES, eveName => {
            this._zr.on(eveName, e => {
                this.trigger(eveName, e);
            });
        });
    }

    /**
     * 设置参数，每次调用会进行清除再重新生成
     *
     * @param {Object} option 配置参数
     * @param {Object} [lazyUpdate=true] 配置参数
     * ```
     * lazyUpdate设置为false时,可用于直接导出图片
     * 对zrender来说 group内的shape添加完成后，即可导出图片
     * ```
     * @param {Object} callbacks 立即更新的回调
     * @returns this
     */
    setOption(option, lazyUpdate = true, callbacks = {}) {
        // 统计
        doStat(option, SeriesModel, ComponentModel);

        this.clear();

        this._model.setOption(option);
        // @TODO delete 已废弃
        this.toggleEdit(this.isEnableEdit);

        prepareView.call(this);

        this.__AFTER_SET_OPTION = true;

        // lazyUpdate设置为false时,可用于直接导出图片
        // 对zrender来说 group内的shape添加完成后，即可导出图片
        if (!lazyUpdate) {
            this.update(false, callbacks);
        }

        return this;
    }

    /**
     * 调用globalModel的更新对标记为脏的model进行更新，并对有更新的model对应的view重新进行渲染
     *
     * @param {boolean} [force] 是否强制更新
     * force 如果为真, 则所有的model和view都全部更新(不管是否为脏，更新后重置为非脏)
     * @param {boolean} callbacks 回调函数
     */
    update(force, callbacks = {}) {
        this._model.__update(force);

        // view 创建与更新
        doRender.call(this, callbacks);

        // 整个canvas的背景色
        let backgroundColor = this._model.get('backgroundColor');
        backgroundColor && this._zr.setBackgroundColor(backgroundColor);
    }

    /**
     * 清除所有view、model以及事件
     *
     * @returns this
     */
    clear() {
        let zr = this._zr;

        // 清除所有model
        this._model.clear();

        // 删除所有视图
        each(this._viewMap, function (view) {
            view.remove();
            zr.remove(view.group);
        });
        this._viewMap = {};

        // 解绑全部事件
        this.off();

        this.error(false);

        return this;
    }

    /**
     * 标记错误状态
     *
     * @param {bool} [bool] true或false
     * @private
     */
    error(bool) {
        this._isError = typeof bool !== 'undefined' ? bool : true;
    }

    /**
     * 是否错误状态
     *
     * @returns {boolean} 是否是错误状态
     */
    isError() {
        return this._isError;
    }

    /**
     * Resize the canvas.
     * Should be invoked when container size is changed
     *
     * @param {Object} [opts]
     * @param {number|string} [opts.width] Can be 'auto' (the same as null/undefined)
     * @param {number|string} [opts.height] Can be 'auto' (the same as null/undefined)
     */
    resize(opts) {
        this._zr.resize(opts);
        this.update(true);
    }

    /**
     * 触发事件
     *
     * @param {Object} model 组件model
     * @param {string} type 事件名称
     * @param {any} payload
     * @param {boolean} [throttle=true] true的话不会马上触发
     */
    dispatchAction(model, type, payload, throttle = true) {
        if (throttle) {
            let index = findIndex(this._actionQueue, function (ele) {
                return ele[0] === model.id && ele[1] === type;
            });

            index > -1 && this._actionQueue.splice(index, 1); // 过滤了之前的相同模块的相同事件，仅触发最新的事件
            this._actionQueue.push([`${model.id}_${type}`, payload]);
        } else {
            this.trigger(`${model.id}_${type}`, payload);
        }
    }

    /**
     * 注册事件
     *
     * @param {Object} model 组件model
     * @param {string} type 事件名称
     * @param {function} callback 回调函数
     * @param {any} context
     */
    registerAction(model, type, callback, context) {
        this.on(`${model.id}_${type}`, callback, context || model);
    }

    /**
     * Get container width
     *
     * @returns {number} width of container
     */
    getWidth() {
        return this._zr.getWidth();
    }

    /**
     * Get container height
     *
     * @returns {number} height of container
     */
    getHeight() {
        return this._zr.getHeight();
    }

    /**
     * 获取指定的model，或者globalModel(无参数时)
     *
     * @param {string} [type] 组件名称
     * @param {number|string|Function} [index] 对应组件的index、name或方法选取
     * @returns {Object} 返回指定的model
     */
    getModel(type, index) {
        if (arguments.length === 2) {
            if (typeof index === 'number') {
                return this._model.getComponentByIndex(type, index);
            } else if (isString(index)) {
                return this._model.getComponentById(type, index);
            } else if (isFunction(index)) {
                return this._model.getComponent(type, index, false);
            }
        } else if (arguments.length === 1) {
            return this._model._componentsMap[type];
        } else {
            return this._model;
        }
    }

    /**
     * 获取指定的dataModel
     *
     * @deprecated
     * @param {number} index dataModel的序数
     * @returns data model
     */
    getDataModel(index) {
        return this._model.getComponentByIndex('data', index);
    }

    /**
     * 获取zr实例
     *
     * @returns instance of zr
     */
    getZr() {
        return this._zr;
    }

    /**
     * 获取dom
     *
     * @returns dom
     */
    getDom() {
        return this._dom;
    }

    /**
     * 获取model对应的view
     *
     * @param {Object} componentModel 组件model
     * @returns {Object} 返回model对应的view
     */
    getViewOfComponentModel(componentModel) {
        return this._viewMap[componentModel.id];
    }

    //????? 这里的dirty还需要手动设置？？
    // hideSeries(index) {
    //     let seriesModel = this._model.getComponentByIndex('series', index);

    //     if (seriesModel) {
    //         seriesModel.set('selected', false);
    //         seriesModel.__dirty = false;
    //         this._viewMap[seriesModel.id].remove();
    //     }
    // }

    // showSeries(index) {
    //     let seriesModel = this._model.getComponentByIndex('series', index);

    //     if (seriesModel) {
    //         seriesModel.set('selected', true);
    //     }
    // }

    /**
     * 把多层的canvas内容放到一个canvas中
     * 常用于生成图片
     * @param  {object} opts
     * @return {object}
     */
    getRenderedCanvas(opts) {
        if (!env.canvasSupported) {
            return;
        }
        opts = opts || {};
        opts.pixelRatio = opts.pixelRatio || 1;
        // opts.backgroundColor = opts.backgroundColor;
        this.stopAnimation();

        return this._zr.painter.getRenderedCanvas(opts);
    }

    /**
     * 停止所有动画
     * zr.storage 下所有子shape 放在 _displayList
     * 添加的group 放在 _roots
     * 常用于 输出图片前调用
     */
    stopAnimation() {
        let zr = this._zr;
        let list = zr.storage.getDisplayList(true);
        // Stop each shape animations
        each(list, function (el) {
            el.stopAnimation(true);
        });
    }

    /**
     * 生成dataUrl
     *
     * @param {Object} [opts]
     * @param {number|string} [opts.width] Can be 'auto' (the same as null/undefined)
     * @param {number|string} [opts.height] Can be 'auto' (the same as null/undefined)
     * @param {string} [opts.type] jpg | png
     * @param {string} [opts.backgroundColor]
     * @returns data url
     */
    getDataUrl(opts) {
        let lastSize = { width: this.getWidth(), height: this.getHeight() };

        opts = opts || {};

        if (opts.size) {
            this.resize(opts.size);
            this.stopAnimation();
        }

        let url = this.getRenderedCanvas(opts).toDataURL(
            'image/' + ((opts && opts.type) || 'png')
        );

        if (opts.size) {
            this.resize(lastSize);
            this.stopAnimation();
        }

        return url;
    }

    /**
     * 切换编辑状态
     * @todo delete 已废弃
     * @param {boolean} [bool] true或false
     */
    toggleEdit(bool) {
        bool === undefined && (bool = !this.isEnableEdit);

        this._model.eachComponent(function (name, model) {
            model.set('enableEdit', bool);
        });

        this.isEnableEdit = bool;
    }
}

// 初始化每个model对应的view
function prepareOneView(model) {
    let { _viewMap: viewMap, _model: globalModel, _zr: zr } = this;
    let _View, view;

    _View = View.getClass(model.type);
    if (_View) {
        view = new _View(model, globalModel, this);
        view.type = model.type;
        viewMap[model.id] = view;
        // 此处 view.group 里面的shape为空
        zr.add(view.group);
    }

    return view;
}

// 准备视图
// @todo delete
function prepareView() {
    this._model.eachComponent((componentType, model) => {
        prepareOneView.call(this, model);
    });
}

// 进行渲染
function doRender(callbacks = {}) {
    let { _viewMap: viewMap, _model: globalModel } = this;

    globalModel.eachComponent((componentType, model) => {
        let view = viewMap[model.id];

        //不是所有model类型都有对应的view. data这类的model没有对应的View
        let _View = View.getClass(model.type);
        if (_View) {
            // 如果view不存在
            if (!view) {
                view = prepareOneView.call(this, model);
            }

            // if (view) {
                view.__render(model, globalModel, this);
            // }
        }

    });

    // 每次setOption view.render完 后触发
    if (this.__AFTER_SET_OPTION) {
        this.__AFTER_SET_OPTION = false;

        callbacks[instanceActions.viewDidRenderAfterSetOption] && callbacks[instanceActions.viewDidRenderAfterSetOption]();

        this.trigger(instanceActions.viewDidRenderAfterSetOption);
    }

    this.trigger(instanceActions.viewDidRender);
}

/**
 * 初始化Charts
 *
 * @param {string|dom} dom 接收dom的id或者dom
 * @param {object} opts.datavTrackId 表示统计id前缀
 * @returns {Object} 返回{@link module:D3Charts~Charts}实例
 */
function init(dom, opts) {
    let chart = new Charts(dom, undefined, opts);

    instances[chart.id] = chart;

    return chart;
}

/**
 * 注册Model
 *
 * @param {Object} Model 要注册的Model
 */
function registerModel(Model_) {
    ComponentModel.registerClass(Model_, Model_.type);
}

/**
 * 注册view
 *
 * @param {Object} View Model对应的View
 */
function registerView(View_) {
    View.registerClass(View_, View_.type);
}

/**
 * 注册 行情指标
 * @param  {Object} Indicator
 */
function registerIndicator(Indicator_) {
    Indicator.registerClass(Indicator_, Indicator_.type);
}


/**
 * 注册Model及View
 *
 * @param {Object} Model 要注册的Model
 * @param {Object} View Model对应的View
 */
function registerComponent(Model_, View_) {
    Model_ && registerModel(Model_);
    View_ && registerView(View_);

    if (!Model_ && View_) {
        registerModel(
            class extends ComponentModel {
                static type = View_.type;
            }
        );
    }
}

// 地图
function initMap(dom) {
    var map = document.createElement('div');
    map.id = dom;
    map.width = '100%';
    map.height = '600px';
    return map;
}

// 微信app等非常规canvas环境 调用
export function setCanvasCreator(creator) {
    util.$override('createCanvas', creator);
}

export {
    VERSION,
    init,
    track,
    event,
    initMap,
    registerModel,
    registerView,
    registerComponent,
    registerIndicator,
    /**
     * 绘图引擎zrender
     *
     * @see https://ecomfe.github.io/zrender-doc/public/
     */
    zrender,
    /**
     * 工具函数
     *
     * @see module:util/index
     */
    util,
    /**
     * 图形相关函数
     *
     * @see module:util/graphic
     */
    graphic,
    colorTool,
    symbol,
    /**
     * @see ChartView
     */
    ChartView,
    /**
     * @see ComponentView
     */
    ComponentView,
    /**
     * @see Indicator
     */
    Indicator,
    // d3Time,
    /**
     * @see SeriesModel
     */
    SeriesModel as ChartModel,
    /**
     * @see ComponentModel
     */
    ComponentModel,
    action,
    instances
};
