import ChartView from '../../view/Chart';
import { Rect, subPixelOptimizeRect } from '../../util/graphic';
import { each, isFunction, merge, clone, parsePercent } from '../../util';
import { setHoverStyle } from '../../util/graphic';
import { TYPE } from './HistogramModel';
import { setLabel } from '../bar/helper';

export default class HistogramView extends ChartView {

    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    render(model, globalModel) {
        let style = model.get('itemStyle');
        let label = model.get('label');
        let rectWidth = model.get('rectWidth');
        let normalStyle = style.normal;
        let emphasisStyle = style.emphasis;
        let labelNormal = label.normal;
        let labelEmphasis = label.emphasis;
        let { points, y0 } = model;
        let attrs = [];

        each(points, function (point) {
            let x = point[0];
            let y = point[1];
            let xCenter = (x[0] + x[1]) / 2;
            let width = x[1] - x[0];
            let _style = isFunction(normalStyle) ? normalStyle(point[2]) : clone(normalStyle);
            let _emphasisStyle = isFunction(emphasisStyle) ? emphasisStyle(point[2]) : clone(emphasisStyle);
            let _rectWidth = parsePercent(rectWidth, width);

            _style = setLabel(_style, labelNormal, { data: point[2], dataIndex: point[3] }, globalModel, model);
            _emphasisStyle = setLabel(_emphasisStyle, merge(labelEmphasis, labelNormal), { data: point[2], dataIndex: point[3] }, globalModel, model);

            attrs.push(subPixelOptimizeRect({
                shape: {
                    x: xCenter - _rectWidth / 2,
                    y: y,
                    width: _rectWidth,
                    height: y0 - y
                },
                style: _style,
                emphasisStyle: _emphasisStyle,
                seriesIndex: model.index,
                dataIndex: point[3],
                key: point[3]
            }));
        });

        this.setShapeGroup('rectGroup', Rect, attrs,
            {
                animation: model.get('animation'),
                animateFrom: {
                    shape: {
                        y: y0,
                        height: 0
                    }
                }
            },
            undefined,
            function (shape) {
                setHoverStyle(shape, shape.emphasisStyle);
            }
        );
    }
}
