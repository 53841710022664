import { Text, Circle, formatTextStyle, Polygon, setHoverStyle, Line, subPixelOptimizeLine } from '../../util/graphic';
import { each, parsePercent, merge, clone } from '../../util';
import ChartView from '../../view/Chart';
import { legendActions } from '../../action/event';
import { TYPE } from './FunnelModel'; // 使用Model中定义的TYPE，与model的type值保持一致

export default class FunnelView extends ChartView {

    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    constructor(model, globalModel, global) {
        super(...arguments);

        if (model.get('legendHoverLink')) {
            let legendModels = globalModel.getComponent('legend');

            each(legendModels, (legendModel) => {
                global.registerAction(legendModel, legendActions.mouseover, ({ name }) => {
                    if (legendModel.isSelected(name)) {
                        let target = this.getShape('funnel', name);
                        
                        this.getShape('funnel', name).trigger('mouseover', { target });
                    }
                });
                global.registerAction(legendModel, legendActions.mouseout, ({ name }) => {
                    let target = this.getShape('funnel', name);

                    target && target.trigger('mouseout', { target });
                });
            })
        }
    }

    render(model, globalModel) {
        let { data, position: { width, height, left, top, right }, maxItem } = model;
        if (!data.length) {
            this.removeShape('funnel');
            return;
        }
        let gap = model.get('gap');
        let animation = model.get('animation');
        let funnelAlign = model.get('funnelAlign');
        let labelNormal = model.get('label').normal;
        let labelEmphasis = model.get('label').emphasis;
        let itemNormal = model.get('itemStyle').normal;
        let itemEmphasis = model.get('itemStyle').emphasis;
        let labelLineNormal = model.get('labelLine').normal;
        let labelLineEmphasis = model.get('labelLine').emphasis;
        let maxSize = parsePercent(model.get('maxSize'), width);
        let minSize = parsePercent(model.get('minSize'), width);
        let dataLen = data.length;
        let itemLen = (height - (dataLen - 1) * gap) / dataLen;
        let maxValue = maxItem.value;
        let scale = (maxSize - minSize) / maxValue;
        let center = left + width / 2;
        let attrs = [];
        let animateFrom = [];
        let labelAttrs = [], labelLineAttrs = [];
        let curPos = top;

        left = center - maxSize / 2;
        right = center + maxSize / 2;

        for (let i = 0; i < dataLen; i++) {
            let cur = data[i];
            let curValue = cur.value;
            let curHalf = (curValue * scale + minSize) / 2;
            let curLeft, curRight;
            let nextLeft, nextRight;
            let nextHalf = 0;

            if (i < dataLen - 1) {
                let next = data[i + 1];
                let nextValue = next.value;

                nextHalf = (nextValue * scale + minSize) / 2;
            } else {
                nextHalf = (curValue * scale + minSize) / 2;
            }

            switch (funnelAlign) {
                case 'left':
                    curLeft = left;
                    curRight = left + 2 * curHalf;
                    nextLeft = left;
                    nextRight = left + 2 * nextHalf;
                    break;
                case 'right':
                    curLeft = right;
                    curRight = right - 2 * curHalf;
                    nextLeft = right;
                    nextRight = right - 2 * nextHalf;
                    break;
                case 'center':
                default:
                    curLeft = center - curHalf;
                    curRight = center + curHalf;
                    nextLeft = center - nextHalf;
                    nextRight = center + nextHalf;
                    break;
            }

            let style = {
                fill: cur.color,
                ...itemNormal
            };

            if (labelNormal.show) {
                let labelX, textAlign;
                let lineX1, lineX2;
                let labelY = curPos + itemLen / 2;
                let text = labelNormal.formatter ? labelNormal.formatter(cur.value, cur) : cur.value;
                let inCenter = false;

                switch (labelNormal.position) {
                    case 'left':
                        lineX1 = (curLeft + nextLeft) / 2;
                        lineX2 = lineX1 - labelNormal.gap;
                        labelX = lineX2 - 5;
                        textAlign = 'right';
                        break;
                    case 'right':
                        lineX1 = (curRight + nextRight) / 2;
                        lineX2 = lineX1 + labelNormal.gap;
                        labelX = lineX2 + 5;
                        textAlign = 'left';
                        break;
                    default:
                        labelX = (curLeft + curRight) / 2;
                        textAlign = 'center';
                        inCenter = true;
                        break;
                }

                labelAttrs.push({
                    style: {
                        opacity: 1,
                        x: labelX,
                        y: labelY,
                        text,
                        textAlign,
                        ...formatTextStyle(clone(labelNormal.style))
                    },
                    key: cur.name,
                    _hoverStyle: {
                        text: labelEmphasis.formatter ? labelNormal.formatter(cur.value, cur) : text,
                        ...formatTextStyle(clone(labelEmphasis.style))
                    },
                    silent: inCenter,
                    z2: 1
                });

                if (labelLineNormal.show && !inCenter) {
                    labelLineAttrs.push(subPixelOptimizeLine({
                        shape: {
                            x1: lineX1,
                            y1: labelY,
                            x2: lineX2,
                            y2: labelY
                        },
                        key: cur.name,
                        style: labelLineNormal.style,
                        _hoverStyle: clone(labelLineEmphasis.style)
                    }));
                }
            }

            attrs.push({
                shape: {
                    points: [[curLeft, curPos], [curRight, curPos], [nextRight, curPos + itemLen], [nextLeft, curPos + itemLen]]
                },
                style: {
                    opacity: 1,
                    fill: cur.color,
                    ...itemNormal
                },
                _hoverStyle: clone(itemEmphasis),
                key: cur.name,
                data: cur,
                z2: 0
            });
            animateFrom.push({
                shape: {
                    points: [[curLeft, curPos], [curRight, curPos], [curRight, curPos], [curLeft, curPos]]
                }
            });

            curPos += itemLen + gap;
        }

        this.setShapeGroup('funnel', Polygon, attrs, {
            animation,
            animateFrom,
            animateLeave: {
                style: {
                    opacity: 0
                }
            }
        }, undefined, (shape) => {
            shape._hoverStyle && setHoverStyle(shape, shape._hoverStyle);

            shape.on('mouseover', ({ target }) => {
                if (labelEmphasis.show) {
                    let label = this.getShape('label', target.name);

                    if (label) {
                        label.trigger('emphasis');
                    } else {
                        let points = target.shape.points;
                        let labelX, textAlign;
                        let labelY = (points[1][1] + points[2][1]) / 2;
                        let formatter = labelEmphasis.formatter || labelNormal.formatter;
                        let text = formatter ? formatter(target.data.value, target.data) : target.data.value;

                        switch (labelEmphasis.position || labelNormal.position) {
                            case 'left':
                                labelX = (points[0][0] + points[3][0]) / 2 - labelNormal.gap - 5;
                                textAlign = 'right';
                                break;
                            case 'right':
                                labelX = (points[1][0] + points[2][0]) / 2 + labelNormal.gap + 5;
                                textAlign = 'left';
                                break;
                            default:
                                labelX = (points[0][0] + points[1][0]) / 2;
                                textAlign = 'center';
                                break;
                        }

                        this.setShape('tempLabel', Text, {
                            style: {
                                x: labelX,
                                y: labelY,
                                text,
                                textAlign,
                                ...formatTextStyle(merge(clone(labelNormal.style), labelEmphasis.style, true))
                            },
                            z: model.get('z'),
                            z2: 999,
                            silent: true
                        });
                    }

                    if (labelLineEmphasis.show) {
                        let labelLine = this.getShape('labelLine', target.name);

                        if (labelLine) {
                            labelLine.trigger('emphasis');
                        } else {
                            let points = target.shape.points;
                            let lineX1, lineX2;
                            let labelY = (points[1][1] + points[2][1]) / 2;
                            let inCenter = false;

                            switch (labelNormal.position) {
                                case 'left':
                                    lineX1 = (points[0][0] + points[3][0]) / 2;
                                    lineX2 = lineX1 - labelNormal.gap;
                                    break;
                                case 'right':
                                    lineX1 = (points[1][0] + points[2][0]) / 2;
                                    lineX2 = lineX1 + labelNormal.gap;
                                    break;
                                default:
                                    inCenter = true;
                                    break;
                            }

                            if (labelLineNormal.show && !inCenter) {
                                this.setShape('tempLabelLine', Line, subPixelOptimizeLine({
                                    shape: {
                                        x1: lineX1,
                                        y1: labelY,
                                        x2: lineX2,
                                        y2: labelY
                                    },
                                    style: merge(clone(labelLineNormal.style), labelLineEmphasis.style, true)
                                }));
                            }
                        }
                    }
                }
            });

            shape.on('mouseout', ({ target }) => {
                if (labelEmphasis.show) {
                    let label = this.getShape('label', target.name);
                    let labelLine = this.getShape('labelLine', target.name);

                    if (label) {
                        label.trigger('normal');
                    } else {
                        this.removeShape('tempLabel');
                    }

                    if (labelLine) {
                        labelLine.trigger('normal');
                    } else {
                        this.removeShape('tempLabelLine');
                    }
                }
            });
        });

        this.setShapeGroup('label', Text, labelAttrs, undefined, undefined, function (shape) {
            shape._hoverStyle && setHoverStyle(shape, shape._hoverStyle);
        });

        this.setShapeGroup('labelLine', Line, labelLineAttrs, undefined, undefined, function (shape) {
            shape._hoverStyle && setHoverStyle(shape, shape._hoverStyle);
        });
    }
}
