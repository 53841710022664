/**
 * 漏斗图 by wjw
 */
import SeriesModel from '../../model/Series';
import { each, parsePercent, filter, isFunction } from '../../util';

export const TYPE = 'funnel';

export default class FunnelModel extends SeriesModel {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    static emphasisList = ['itemStyle', 'label', 'labelLine'];

    static defaultOption = {
        //data: undefined, // {Array} 系列中的数据内容数组。数组项通常为具体的数据项。每一项的格式如： { value: 160, name: '展现', color: '#42A2FF' }
        $dataIndex: undefined, // {number} 依赖的$data的index，格式同上，优先级低于data，取得的数据需符合data的格式
        dataKey: undefined, // {string|number} 搭配$dataIndex使用，方便从依赖的$data中取数据，等同于 data.map((d) => d[dataKey])，最终取得的数据需符合data的格式
        $gridIndex: 0, // {number} 依赖的grid

        sort: 'descending', // {string|Function} 数据排序， 可以取 'ascending'，'descending'，'none'（表示按 data 顺序），或者一个函数
        gap: 0, // {number} 数据图形间距。
        legendHoverLink: true, // {boolean} 是否启用图例 hover 时的联动高亮。
        funnelAlign: 'center', // {string} 水平方向对齐布局类型，默认居中对齐，可用选项还有：'left' | 'right' | 'center'
        minSize: '0%', // {string|number} 数据最小值 min 映射的宽度。可以是绝对的像素大小，也可以是相对布局宽度的百分比。
        maxSize: '100%', // {string|number} 数据最大值 max 映射的宽度。可以是绝对的像素大小，也可以是相对布局宽度的百分比。

        // 图形样式。
        itemStyle: {
            normal: { // {Object} 普通样式

            },
            emphasis: { // {Object} 高亮样式

            }
        },

        // 漏斗图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等
        label: {
            normal: { // 普通配置
                show: true, // {boolean} 是否显示
                position: 'center', // {string} 标签的位置, left | center | right
                gap: 50, // {number} 标签和图形的距离
                style: { // {Object} 普通样式
                    fill: '#acacac',
                    textVerticalAlign: 'center'
                }
            },

            emphasis: { // 高亮配置
                show: true, // {boolean} 是否显示
                style: { // {Object} 高亮样式
                }
            }
        },

        // 标签的视觉引导线样式，在 label 位置 设置为'left'或者'right'的时候会显示视觉引导线。
        labelLine: {
            normal: { // 普通配置
                show: true, // {boolean} 是否显示
                style: { // {Object} 普通样式
                    stroke: '#acacac',
                    lineWidth: 1
                }
            },
            emphasis: { // 高亮配置
                show: true, // {boolean} 是否显示
                style: { // {Object} 高亮样式

                }
            }
        },

        zlevel: 0, // canvas层级
        z: 1 // 同一层canvas内层级
    };

    update() {
        let data = this.getData();
        let gridModel = this.globalModel.getComponentByIndex('grid', this.get('$gridIndex'));
        let sort = this.get('sort');
        let { position } = gridModel;
        let selected = this.get('selected');
        let maxItem;

        if (selected) {
            data = filter(data, function (d) {
                if (selected[d.name] === false) {
                    return false
                } else {
                    return true;
                }
            })
        }

        if (sort) {
            if (sort === 'descending') {
                data = data.sort(function (a, b) {
                    return b.value - a.value
                });
            } else if (sort === 'ascending') {
                data = data.sort(function (a, b) {
                    return a.value - b.value
                });
            } else if (isFunction(sort)) {
                data = data.sort(sort);
            }
        }

        each(data, function (item) {
            if (!maxItem || maxItem.value < item.value) {
                maxItem = item;
            }
        });

        this.maxItem = maxItem;
        this.data = data;
        this.position = position;
    }
}
