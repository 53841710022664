/**
 * 老版本的地理组件 by zgx 可以用 component/GeoModel.js 完全替换
 * 之前有项目用的是这个组件
 */

import SeriesModel from '../../model/Series';
import { each, map } from '../../util';
import { geoTransverseMercator } from 'd3-geo';

export const TYPE = 'd3-geo';

export default class GeoModel extends SeriesModel {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    static defaultOption = {
        zlevel: 0, // canvas层级
        z: 1, // 同一层canvas内层级
        $gridIndex: 0,
        map: {
            outerPath: {
                show: true, // {boolean} 是否显示外边框
                style: {
                    stroke: '#00beff', // {string} 外边框颜色
                    lineWidth: 10 // {number} 外边框宽度
                }
            },
            innerPath: {
                show: true, // {boolean} 是否显示内边框
                style: {
                    stroke: '#027da7', // {string} 内边框颜色
                    fill: '#04526d', // {string} 内边框填充
                    randomFill: {
                        randomFill: false, // {boolean} 是否随机填充
                        colors: ['rgb(17, 16, 50)', 'rgb(25, 24, 62)', 'rgb(15, 16, 55)', 'rgb(26, 25, 66)', 'rgb(15, 51, 51)', 'rgb(11, 10, 36)'] // {array} 随机填充颜色数组
                    },
                    lineWidth: 2, // {number} 内边框宽度
                    hover: {
                        hover: true, // {boolean} 是否鼠标悬浮交互
                        hoverFill: 'rgba(40,128,255, 1.0)' // {string} 悬浮交互后颜色
                    },
                    lineDash: [0, 0] // {array}虚线
                },
                events: {
                    registion: 'REGISTER', // {string} 交互事件注册
                    dispatch: {
                        mouseover: 'MOUSEOVER', // {string} 鼠标悬浮交互事件注册
                        mouseout: 'MOUSEOUT' // {string} 鼠标移出交互事件注册
                    }
                }
            }
        },
        paddingArea: {
            show: false, // {boolean} 是否显示地图矩形边界
            padding: {
                left: 50, // {number} 离左侧容器边界距离
                right: 50, // {number} 离右侧容器边界距离
                top: 50, // {number} 离上侧容器边界距离
                bottom: 50 // {number} 离下侧容器边界距离
            },
            style: {
                fill: 'rgba(255, 0, 0, 0.2)', // {string} 矩形边界填充
                stroke: 'rgba(255, 0, 0, 1)', // {string} 矩形边界边框
                lineWidth: 1 // {number} 边框宽度
            }
        },
        markers: {
            group: [ // {array} 标记以数组形式存在
            ],
            animation: {
                animation: false, // {boolean} 是否动画
                speed: 1000, // {number} 动画时间
                animateSize: 0.5 // {number} 动画后大小
            },
            overLabel: {
                show: false, // {boolean} 是否显示悬乎标签窗口
                speed: 1000, // {number} 动画事件
                style: {
                    lineWidth: 2, // {number} 悬窗边框宽度
                    fill: '#00fffd'// {string} 悬窗填充
                },
                focusStyle: {
                    fill: 'rgba(248, 195, 28, 1.6)',// {string} 悬窗飞线填充
                    stroke: 'rgba(248, 195, 28, 0.6)',// {string} 悬窗飞线颜色
                    lineWidth: 10, // {number} 悬窗飞线宽度
                    shadowBlur: 0, // {number} 悬窗飞线阴影宽度
                    shadowColor: 'rgba(255, 255, 255, 0)'// {string} 悬窗飞线阴影颜色 
                },
                labelLine: {
                    show: false, // {boolean} 是否显示悬窗飞线
                    position:
                        {
                            startX: 20, // {number} 悬窗飞线初始X位置
                            startY: 20, // {number} 悬窗飞线初始Y位置
                            endX: 150 // {number} 悬窗飞线结束X位置
                        }
                },
                labelGroup: [
                ] // {array} 悬窗文字分组
            },
            clickLabel: {
                show: false, // {boolean} 是否显示交互标签
                color: '#57FFD9',// {string} 交互标签颜色
                top: 5, // {number} 交互标签置顶位置
                style: {
                    textFill: '#FF5654',// {string} 交互标签文字颜色
                    fontSize: 24, // {number} 交互标签文字大小
                    textAlign: 'center'// {string} 交互标签文字位置
                }
            },
            hover: {
                hover: false, // {boolean} 是否鼠标悬浮交互
                pannal: {
                    show: false, // {boolean} 交互有是否有悬窗
                    width: 200, // {number} 悬浮交互悬窗宽度
                    height: 150, // {number} 悬浮交互悬窗高度
                    style: {
                        fill: 'rgb(3, 14, 27)',// {string} 悬浮交互悬窗填充
                        stroke: 'rgb(6, 254, 220)',// {string} 悬浮交互悬窗边框颜色
                        lineWidth: 2 // {number} 悬浮交互悬窗边框宽度
                    }
                },
                labelGroup: [
                ] // {array} 悬浮交互标签分组
            }
        },
        flylines: {
            show: false, // {boolean} 是否显示飞线
            colors: ['rgba(135,231,60, 1)', 'rgba(40,128,255, 0.1)'], // {array} 飞线渐变颜色分组
            style: {
                lineWidth: 2, // {number} 飞线宽度
                opacity: 1, // {number} 飞线透明度
                shadowBlur: 10, // {number} 飞线阴影宽度
                shadowColor: 'black'// {string} 飞线阴影颜色
            },
            background: {
                show: true, // {boolean} 是否显示飞线底部轨迹
                style: {
                    stroke: 'rgb(0, 255, 0)'// {string} 轨迹颜色
                }
            },
            animation: true // {boolean} 是否飞线动画
        },
        routes: {
            show: false, // 是否显示阈值轨迹
            colors: ['rgba(135,231,60, 1)', 'rgba(40,128,255, 0.1)'], // {array} 默认轨迹渐变颜色分组
            threshold: {
                threshold: true, // {boolean} 是否有阈值
                value: 5, // {number} 阈值
                colors: ['rgba(255,0,0, 1)', 'rgba(255,0,0, 0.1)'] // {array} 根据阈值改变渐变颜色
            },
            animation: {
                animation: true, // {boolean} 是否动画
                style: {
                    lineWidth: 4, // {number} 轨迹宽度
                    fill: '#fff',// {string} 轨迹填充
                    thresholdFill: ['green', 'rgba(255,15,15, 0)'], // {array} 根据阈值改变轨迹填充
                    lengthPercent: 1, // {number} 总的轨迹长度
                    speed: 3000, // {number} 动画事件
                    delay: 3000 // {number} 动画延迟
                }
            },
            style: {
                lineWidth: 4, // {number} 默认轨迹宽度
                opacity: 1, // {number} 轨迹透明度
                shadowBlur: 10, // {number} 轨迹阴影宽度
                shadowColor: 'black',// {string} 轨迹阴影颜色
                text: {
                    formatter: function (d) { return d.value + '%' } // {function} 自定义文字
                },
                fontSize: 16, // {number} 文字大小
                textFill: '#fff' // {string} 文字填充
            }
        },
        polygons: {
            show: false, // {boolean} 是否显示多边形
            style: {
                fill: 'rgba(0, 255, 255, 0.5)',// {string} 多边形填充
                stroke: 'rgba(0, 255, 255, 0)',// {string} 多边形边框颜色
                lineWidth: 1 // {number} 多边形边框宽度
            }
        },
        bars: {
            show: false, // {boolean} 是否显示柱状图
            colors: ['rgb(255, 251, 242)', 'rgb(66, 77, 155)', 'rgb(0, 255, 255)'], // 柱状图颜色构成数组，分别表示头，左边，右边
            size: 2, // {number} 柱状图大小
            style: {
                textFill: 'white',// {string} 文字颜色填充
                textPosition: 'bottom',// {string} 文字位置
                fontSize: 12 // {number} 文字大小
            }
        },
        southSea: {
            show: true, // {boolean} 是否显示南海部分
            offset: [0, 0], // {array} 南海部分位置偏移
            scale: [0.7, 0.7], // {array} 南海部分缩放
            lineWidth: 2, // {number} 南海部分线宽
            style: {
                textFill: 'white',// {string} 文字填充
                textPosition: 'bottom',// {string} 文字位置
                fontSize: 14, // {number} 文字大小
                textOffset: [0, 0] // {array} 文字偏移
            }
        }
    }

    update () {
        let gridModel = this.globalModel.getComponentByIndex('grid', 0);
        let { left, right, top, bottom } = gridModel.position;
        this.projection = geoTransverseMercator()
            .rotate([-110, 0, 0])
            .fitExtent([[left, top], [right, bottom]], window.chinaBorderData);
    }
}