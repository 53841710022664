/*
* 单圆弧 by zzy
 */
import SeriesModel from '../../model/Series';

const TYPE = 'arc';

export default class ArcModel extends SeriesModel {
    static type = TYPE;

    type = TYPE;
}

ArcModel.defaultOption = {
    zlevel: 0,
    z: 1,
    data: undefined,
    $dataIndex: undefined,

    // 默认全局居中
    center: ['50%', '50%'],
    // 半径
    radius: [10, 20],

    // 标题
    title : {
        // x, y，单位px
        offsetCenter: [0, 0],
        textStyle: { // 其余属性默认使用全局文本样式，详见TEXTSTYLE
            color: '#333',
            fontStyle: 'normal',
            fontFamily: 'sans-serif',
            fontWeight: 'normal',
            fontSize: 14
        }
    },

    circleStyle: {
        show: false,
        innerRadius: 55,
        outerRadius: 70,
        //  'line' or 'dotted', 'square' 三种
        innerType:'line',
        outerType:'line',
        symbolStyle: {
            // 是否和item计算出的弧度一致
            // 默认绘制整个圆
            angleWithItem: false,
            edgePadding: 1,
            // 小圆点半径
            radius: 3,
            stroke: '#000',
            fill: '#000'
        },
        innerStrokeColor: '#333',
        innerFillColor: '#F04927',
        outerFillColor: '#F04927',
        outerStrokeColor: '#F04927'
    },
    animation: true,
    itemStyle: {
        offsetCenter: [0, 0],
        normal: {
            stroke: '#333',
            fill: '#333',
            cornerRadius: 0
        },
        formatter: function (originD, d) {
            return d;
        },

        textStyle: {
            position: 'center',
            textBaseline: 'middle',
            textAlign: 'center',
            color: '#333',
            fontFamily: 'sans-serif',
            fontWeight: 'normal',
            fontSize: 12,
            // fill: '#333', // fill和color都可以，推荐使用fill
            // rich: {
            //     a: {
            //         fill: 'rgb(161, 161, 161)'
            //     },
            //     b: {
            //         fill: 'white'
            //     }
            // }
        }
    },
    startArc: {
        show: false,
        angleCross: 1, // {number} 度 360度为分母
        normal: {
            fill: '#576AD0',
            stroke: '#576AD0',
            cornerRadius: 0
        }
    },
    endArc: {
        show: false,
        angleCross: 1, // {number} 度 360度为分母
        normal: {
            fill: '#576AD0',
            stroke: '#576AD0',
            cornerRadius: 0
        }
    }
};
