import { Rect, Line, Text, Polygon, Polyline, Circle } from '../../util/graphic';
import D3Line from '../../shape/D3Line';
import basis from 'd3-shape/src/curve/basis';
import ChartView from '../../view/Chart';
import { each, getContext } from '../../util';
import { TYPE } from './DagrexModel'; // 使用Model中定义的TYPE，与model的type值保持一致
import dagre from '../../util/dagre';
import { max, min } from 'd3-array';
import { point } from 'd3-scale/src/band';
import { dagrexActions } from '../../action/event';

export default class DagrexView extends ChartView {

  static type = TYPE; // 静态变量

  type = TYPE; // 实例变量

  render(model, globalModel, global) {
    let w, h;
    let splitNum = 7;
    let data = model.getData();
    let realEdges = data.edges;
    let realNodes = data.nodes;
    let maxLink = max(realEdges, (e) => e.data.weight);
    let nodeOpt = model.get('node');
    let linkOpt = model.get('link');
    let g = this.g || new dagre.graphlib.Graph({ multigraph: true, directed: true, compound: true });
    const EVENTS = ['click', 'mouseover', 'mousemove', 'mouseout']
    const ctx = getContext()
    ctx.font = linkOpt.label.fontSize + 'px sans-serif'

    function _length(str){
      var len=0
      for(var i=0; i<str.length;i++){
        if(str.charAt(i)>'~'){len+=2;}else{len++;}
      }
      return len
    }

    let maxNodeLen = 0
    realNodes.forEach(d => {
      let length = _length(d.data.name)
      if (maxNodeLen < length) {
        maxNodeLen = length
      }
    })

    if (!this.g) {
      g.setGraph({
        // rankdir: "LR",
        nodesep: isNaN(nodeOpt.nodesep) ? maxNodeLen * 4 : nodeOpt.nodesep,
        ranksep: nodeOpt.rankStep
      });
      g.setDefaultEdgeLabel(function () { return {}; });

      each(realNodes, (node) => {
        g.setNode(node.data.id, {
          label: node.data.category,
          width: nodeOpt.shape.width + nodeOpt.style.normal.lineWidth,
          height: nodeOpt.shape.height + nodeOpt.style.normal.lineWidth,
          newName: node.data.name,
          group: node.data.group,
          isRoot: node.data.isRoot,
          isClick: true
        });
      });
      each(realEdges, (edge) => {
        g.setEdge(edge.data.source, edge.data.target, {
          label: edge.data.weight,
          group: edge.data.group
        });
      });
      dagre.layout(g);
      this.g = g
    }

    w = global.getWidth();
    h = global.getHeight();

    this.maxCategory = max(realNodes, (n) => n.data.category);
    this.minCategory = min(realNodes, (n) => n.data.category);
    this.categoryArr = realNodes.map((n) => n.data.category);

    this.offsetWidth = w - g.graph().width;
    this.offsetHeight = h - g.graph().height;

    this.onlyShowNodes = this.onlyShowNodes || g.nodes()
    this.onlyShowEdges = this.onlyShowEdges || g.edges()

    let attrs = [];
    let textAttrs = [];
    let fillColor = (node) => {
      if (node.label === this.maxCategory) {
        return nodeOpt.style.normal.colors.end;
      } else if (node.label === this.minCategory) {
        return nodeOpt.style.normal.colors.origin;
      } else {
        return nodeOpt.style.normal.colors.process;
      }
    }

    let uniqueArray = (arr) => {
      let res = [];
      let json = {};
      each(arr, (a) => {
        if (!json[a]) {
          res.push(a);
          json[a] = 1;
        }
      })
      return res;
    }

    const getNodeWidth = (w, n) => {
      return w;
    }

    const getNodeHeight = (h, n) => {
      return h;
    }

    const getNodeR = (r, n) => {
      return r;
    }


    each(g.nodes(), (v, i) => {
      let node = g.node(v);
      let currentX = node.x + this.offsetWidth / 2;
      let currentY = node.y + this.offsetHeight / 2;
      let style
      let fillColor
      let strokeColor

      if (node.isRoot) {
        if (node.isFillHl) {
          fillColor = nodeOpt.style.highlight.solidFill
          style = nodeOpt.style.highlight
          strokeColor = nodeOpt.style.highlight.stroke
        } else if (node.isFill) {
          fillColor = nodeOpt.style.normal.solidFill
          style = nodeOpt.style.normal
          strokeColor = nodeOpt.style.normal.stroke
        } else {
          fillColor = nodeOpt.style.highlight.solidFill
          style = nodeOpt.style.highlight
          strokeColor = nodeOpt.style.highlight.stroke
        }
      } else {
        if (node.isFillHl) {
          fillColor = nodeOpt.style.highlight.solidFill
          style = nodeOpt.style.highlight
        } else if (node.isFill) {
          fillColor = nodeOpt.style.normal.solidFill
          style = nodeOpt.style.normal
        } else {
          fillColor = nodeOpt.style.normal.fill
          style = nodeOpt.style.normal
        }
        if (node.isHl) {
          strokeColor = nodeOpt.style.highlight.stroke
        } else {
          strokeColor = nodeOpt.style.normal.stroke
        }
      }

      /*if (node.isFill) {
        if (node.isRoot) {
          style = nodeOpt.style.normal
        } else {
          style = nodeOpt.style.highlight
        }
      } else {
        style = (node.isHl || node.isRoot) ? nodeOpt.style.highlight : nodeOpt.style.normal
      }*/
      let opacity = 1
      if (this.onlyShowNodes.indexOf(v) == -1 && !node.isRoot) {
        opacity = nodeOpt.blurOpacity
      }
      attrs.push({
        shape: {
          x: currentX,
          y: currentY,
          width: getNodeWidth(nodeOpt.shape.width),
          height: getNodeHeight(nodeOpt.shape.height),
          r: node.isRoot ? 200 : getNodeR(nodeOpt.shape.r)
        },
        style: {
          ...style,
          opacity: opacity,
          fill: fillColor,
          stroke: strokeColor
        },
        position: [-getNodeWidth(nodeOpt.shape.width) / 2, -nodeOpt.shape.height / 2],
        z: model.get('z'),
        z2: 900,
        data: realNodes.filter(d => d.data.id == v),
        nodeKey: v,
        nodeLabel: node.newName,
        isClick: node.isClick
      });
      textAttrs.push({
        style: {
          // text: getText(node.newName, realNodes[i]),
          opacity: opacity,
          text: node.newName,
          fontSize: nodeOpt.style.normal.fontSize,
          textFill: nodeOpt.style.normal.textFill,
          textAlign: 'center'
        },
        position: [currentX, currentY - nodeOpt.style.normal.fontSize / 2 + 30],
        z: model.get('z'),
        z2: 901,
        silent: true
      })
    })
    let limit = model.get('limited');
    //initScale.call(this, model, limit);

    // must be here before rendering nodes
    this.edgeOffsetX = -(w - g.graph().width) / 2;
    this.edgeOffsetY = -(h - g.graph().height) / 2;

    model.getNodes = this.setShapeGroup('nodes', Rect, attrs, undefined, undefined, node => {

      EVENTS.forEach(d => {
        node.on(d, function(e) {
          let data = e.target.data
          // @todo 重复名称 待合并
          global.dispatchAction(model, 'node_' + d, e)
          global.dispatchAction(model, dagrexActions['node'+d.slice(0,1).toUpperCase()+d.slice(1)], e)
        })
      })

      /*let draggable = false
      node.on('mousedown', d => {
        draggable = true
      })

      global.on('mousemove', ({offsetX, offsetY}) => {
        if (draggable) {
          let nodeData = node.data[0].data
          let nodeId = nodeData.id
          let nodeEl = g.node(nodeId)

          const newPos = this.group.transformCoordToLocal(offsetX, offsetY); // Important!
          node.setShape('x', newPos[0]);
          node.setShape('y', newPos[1]);
          nodeEl.x = newPos[0] - this.offsetWidth / 2
          nodeEl.y = newPos[1] - this.offsetHeight / 2
          const connectEdges = g.nodeEdges(nodeId)
          connectEdges.forEach(d => {
            let edge = g.edge(d)
            console.log(edge)
          })
          [>model.dirty()<]
        }
      })

      global.on('mouseup', d => {
        draggable = false
      })*/

    });

    this.setShapeGroup('texts', Text, textAttrs);

    renderLinks.call(this, model, global, g, this.offsetWidth, this.offsetHeight, data.edges);


    function contain(child, parent) {
      for (let i = 0; i < child.length; i++) {
        if (parent.indexOf(child[i]) > -1) {
          return true
        }
      }

      return false
    }

    function getRenderNodesByGroup(group) {
      return g.nodes().filter(d => {
        return contain(group, g.node(d).group)
      })
    }

    function getRenderEdgesByGroup(group) {
      return g.edges().filter(d => {
        return contain(group, g.edge(d).group)
      })
    }

    model.highlightNode = id => {
      let node = g.node(id)
      node.isHl = true
      //g.removeNode(nodeName)
      model.dirty()
    }

    model.unHighlightNode = id => {
      let node = g.node(id)
      node.isHl = false
      model.dirty()
    }

    model.fillNode = id => {
      let node = g.node(id)
      node.isFill = true
      //g.removeNode(nodeName)
      model.dirty()
    }

    model.unFillNode = id => {
      let node = g.node(id)
      node.isFill = false
      model.dirty()
    }

    model.highlightEdge = id => {
      let edge = g.edge(id)
      let nodev = g.node(id.v)
      let nodew = g.node(id.w)
      edge.isHl = true
      nodev.isHl = true
      nodew.isHl = true
      model.dirty()
    }

    model.unHighlightEdge = id => {
      let edge = g.edge(id)
      let nodev = g.node(id.v)
      let nodew = g.node(id.w)
      edge.isHl = false
      nodev.isHl = false
      nodew.isHl = false
      model.dirty()
    }

    model.highlightGroup = (group, byGroup) => {
      let shouldShowNodes = getRenderNodesByGroup(group)

      let shouldShowEdges

      if (byGroup) {
        shouldShowEdges = getRenderEdgesByGroup(group)
      } else {
        shouldShowEdges = g.edges().filter(d => shouldShowNodes.indexOf(d.v) >= 0 && shouldShowNodes.indexOf(d.w) >= 0)
      }
      each(shouldShowNodes, d => g.node(d).isHl = true)
      each(shouldShowEdges, d => g.edge(d).isHl = true)
      model.dirty()
    }

    model.unHighlightGroup = (group, byGroup) => {
      let shouldShowNodes = getRenderNodesByGroup(group)
      each(shouldShowNodes, d => g.node(d).isHl = false)

      let shouldShowEdges
      if (byGroup) {
        shouldShowEdges = getRenderEdgesByGroup(group)
      } else {
        shouldShowEdges = g.edges().filter(d => shouldShowNodes.indexOf(d.v) >= 0 && shouldShowNodes.indexOf(d.w) >= 0)
      }
      each(shouldShowEdges, d => g.edge(d).isHl = false)
      model.dirty()
    }

    model.showGroup = (group, byGroup) => {
      let shouldShowNodes = getRenderNodesByGroup(group)
      let shouldShowEdges
      this.onlyShowNodes = shouldShowNodes

      if (byGroup) {
        shouldShowEdges = getRenderEdgesByGroup(group)
      } else {
        shouldShowEdges = g.edges().filter(d => shouldShowNodes.indexOf(d.v) >= 0 && shouldShowNodes.indexOf(d.w) >= 0)
      }
      this.onlyShowEdges = shouldShowEdges
      g.nodes().forEach(d => {
        let node = g.node(d)
        if (shouldShowNodes.indexOf(d) >= 0) {
          node.isHl = true
          node.isFillHl = true
        } else {
          node.isHl = false
          node.isFillHl = false
        }
      })
      g.edges().forEach(d => {
        let edge = g.edge(d)
        if (shouldShowEdges.indexOf(d) >= 0) {
          edge.isHl = true
        } else {
          edge.isHl = false
        }
      })
      model.dirty()
    }

    model.hideGroup = group => {
      let shouldShowNodes = g.nodes().filter(d => group.indexOf(g.node(d).group[0]) == -1)
      this.onlyShowNodes = shouldShowNodes
      let shouldShowEdges = g.edges().filter(d => shouldShowNodes.indexOf(d.v) >= 0 && shouldShowNodes.indexOf(d.w) >= 0)
      this.onlyShowEdges = shouldShowEdges
      model.dirty()
    }

    model.reset = () => {
      each(g.nodes(), (node) => {
        let cnode = g.node(node)
        cnode.isHl = false
        cnode.isFillHl = false
        cnode.isFill = false
      })
      each(g.edges(), (edge) => {
        let cedge = g.edge(edge)
        cedge.isHl = false
      })
      this.onlyShowNodes = null
      this.onlyShowEdges = null
      model.dirty()
    }

    function initScale(model, limit) {
      this.group.scale = [1, 1];
      this.group.position = [0, 0];
      const rect = this.group.getBoundingRect();
      let scaleW = rect.width / w;
      let scaleH = rect.height / h;
      let finalScale = Math.max(scaleW, scaleH) * 1.1;
      let originParam = (1 / finalScale - 1) / 0.1;
      let scaleParam;
      if (limit) {
        scaleParam = Math.min(originParam, -1);
      } else {
        scaleParam = originParam;
      }
      let offsetX = w / 2;
      let offsetY = h / 2;
      if (model.option.isTop) {
        offsetY = 0;
        this.group.position[1] = -rect.y + 10;
      }
      this.scale(offsetX, offsetY, scaleParam);
    }

    function renderLinks(model, global, g, offsetWidth, offsetHeight, data) {
      let linkOpt = model.get('link');
      let roamOpt = model.get('roam');
      let attrs = [];
      let attrs2 = [];
      let attrs3 = [];

      function calcuTextPos(pointArray) {
        return [pointArray[1][0], pointArray[1][1]]
        // eslint-disable-next-line no-unreachable
        if (pointArray.length > 2) {
          let midX, midY;
          for (let i = 0; i < pointArray.length; i += 1) {
            midX = (pointArray[pointArray.length - 3][0] + pointArray[pointArray.length - 2][0]) / 2;
            midY = (pointArray[pointArray.length - 3][1] + pointArray[pointArray.length - 2][1]) / 2;
          }
          return [midX, midY - 10];
        } else {
          let midX = (pointArray[0][0] + pointArray[1][0]) / 2;
          let midY = (pointArray[0][1] + pointArray[1][1]) / 2;
          return [midX, midY - 10];
        }
      }

      function getEdageText(data, label) {
        if (linkOpt.label.type === 'text') {
          return label;
        } else {
          if (data.weight) {
            return data.weight + '%';
          } else if (data.label) {
            return data.label;
          } else {
            return '';
          }
        }
      }

      function getEdgeDash(label) {
        if (label) {
          return [5, 5];
        } else {
          return [0, 0];
        }
      }

      function fillArrow(label) {
        if (label) {
          return null;
        } else {
          return linkOpt.style.normal.arrowStroke;
        }
      }

      function getLabelBg(weight) {
        if (weight) {
          return linkOpt.label.fontSize;
        } else {
          return 0;
        }
      }

      function getLineColor(data) {
        if (data.label) {
          return linkOpt.style.normal.dashFill;
        } else {
          return linkOpt.style.normal.stroke;
        }
      }

      // 判断两个矩形是否相交, 坐标 (A, B), C 为 width, D 为 height
      function checkCollision(A, B, C, D, E, F, G, H) {
        // 转为对角线坐标
        C += A, D += B, G += E, H += F;
        var rect;
        // 没有相交
        if (C <= E || G <= A || D <= F || H <= B){
          rect = [0, 0, 0, 0];
        }else{
          var tmpX, tmpY;

          if (E > A) {
            tmpX = G < C ? [E, G] : [E, C];
          } else {
            tmpX = C < G ? [A, C] : [A, G];
          }

          if (F > B) {
            tmpY = H < D ? [F, H] : [F, D];
          } else {
            tmpY = D < H ? [B, D] : [B, H];
          }

          rect = [tmpX[0], tmpY[0], tmpX[1], tmpY[1]];
        }
        // 相交面积大于 0 即为碰撞
        return (rect[2] - rect[0]) * (rect[3] - rect[1]) > 0;
      }

      function getRectInfo(rect) {
        let width = rect.width
        let height = 12
        let x = rect.position[0]
        let y = rect.position[1]
        return {width, height, x, y}
      }

      let isDetected = []

      each(g.edges(), (e, i) => {
        const lineWidth = linkOpt.lineWidth;
        const edge = g.edge(e)
        const isHl = edge.isHl;
        let points = edge.points;
        let point1 = [points[points.length - 2].x + this.offsetWidth / 2, points[points.length - 2].y + this.offsetHeight / 2];
        let point2 = [points[points.length - 1].x + this.offsetWidth / 2, points[points.length - 1].y + this.offsetHeight / 2];
        const arrowSize = Math.max(linkOpt.arrowSize[0], linkOpt.arrowSize[1]);
        const lineLength = Math.sqrt(Math.pow((point2[0] - point1[0]), 2) + Math.pow((point2[1] - point1[1]), 2))
        const percent = (lineLength - arrowSize) / lineLength;
        let opacity

        if (this.onlyShowEdges.indexOf(e) == -1) {
          opacity = linkOpt.blurOpacity
        }

        let style = isHl ? linkOpt.style.highlight : linkOpt.style.normal
        let arrowStyle = isHl ? linkOpt.style.highlight.arrowStroke : linkOpt.style.normal.arrowStroke

        let text = getEdageText(data[i].data, g.edge(e).label)

        let p = {
          ...points[1],
          width: ctx.measureText(text).width,
          height: 12
        }
        isDetected.forEach(e => {
          let dInfo = [p.x, p.y, p.width, p.height]
          let eInfo = [e.x, e.y, e.width, e.height]

          let isCover = checkCollision(...dInfo, ...eInfo)
          if (isCover) {
            if (e.x <= p.x) {
              points[1].x += e.width
            } else {
              points[1].x -= e.width
            }
          }
        })
        isDetected.push(p)

        let pointArr = points.map((p, i) => {
          if (i < points.length - 1) {
            return [p.x + this.offsetWidth / 2, p.y + this.offsetHeight / 2];
          } else {
            return [point1[0] + (point2[0] - point1[0]) * percent, point1[1] + (point2[1] - point1[1]) * percent];
          }
        });

        attrs3.push({
          width: ctx.measureText(text).width,
          style: {
            opacity: opacity,
            text: text,
            fontSize: linkOpt.label.fontSize,
            textFill: isHl ? linkOpt.label.hlTextFill : linkOpt.label.textFill,
            textAlign: 'center'
          },
          position: calcuTextPos(pointArr),
          z: model.get('z'),
          z2: 882,
          silent: true
        });

        attrs.push({
          shape: {
            x: function (d) { return d[0]; },
            y: function (d) { return d[1]; },
            data: pointArr,
            percent: 1,
            curve: linkOpt.smooth ? basis : undefined,
            defined: d => d
          },
          style: {
            ...style,
            opacity: opacity,
            lineDash: [0, 0]
          },
          data: realEdges.filter(d => d.data.source == e.v && d.data.target == e.w),
          z: model.get('z'),
          z2: 880
        })
        let arrowAngle;
        if ((point2[1] - point1[1]) >= 0) {
          arrowAngle = Math.asin((point2[0] - point1[0]) / lineLength);
        } else if ((point2[1] - point1[1]) < 0 && (point2[0] - point1[0]) < 0) {
          arrowAngle = - Math.PI / 2 + Math.asin((point2[1] - point1[1]) / lineLength);
        } else if ((point2[1] - point1[1]) < 0 && (point2[0] - point1[0]) >= 0) {
          arrowAngle = Math.PI / 2 + Math.asin((point1[1] - point2[1]) / lineLength);
        }
        attrs2.push({
          shape: {
            points: [point2, [point2[0] + arrowSize, point2[1] - arrowSize], [point2[0] - arrowSize, point2[1] - arrowSize]]
          },
          style: {
            fill: arrowStyle,
            opacity: opacity
          },
          z: model.get('z'),
          z2: 880,
          rotation: arrowAngle,
          origin: point2
        });
      });

      this.setShapeGroup('edges', D3Line, attrs, undefined, function(edge) {
        EVENTS.forEach(d => {
          edge.on(d, function(e) {
            let data = e.target.data
            // @todo 重复名称 待合并
            global.dispatchAction(model, 'edge_' + d, e)
            global.dispatchAction(model, dagrexActions['edge'+d.slice(0,1).toUpperCase()+d.slice(1)], e)
          })
        })
      });
      this.setShapeGroup('arrows', Polygon, attrs2);
      this.setShapeGroup('edgeLabel', Text, attrs3);
    }

    /*this.onlyShowNodes = null
      this.onlyShowEdges = null*/
  }
}
