/**
 * 蜡烛图 || 多个k线
 */
import Path from 'zrender/src/graphic/Path';
import { map } from '../util';
// import { subPixelOptimize } from '../util/graphic'

function add_5(n, fixed) {
    if (arguments.length < 2) fixed = 0.5;
    return (n >> 0) + fixed;
}

function klineBamboo(data, bandWidth, fixed, ctx) {
    if (bandWidth) {
        map(data, (d, i) => {
            let x = add_5(d[1].lineX, fixed);
            // 主线
            ctx.moveTo(x, add_5(d[1].lineYTop, fixed));
            ctx.lineTo(x, add_5(d[1].lineYBottom, fixed));

            if (d[2].c >= d[2].o) {
                // 盈
                ctx.moveTo(x, add_5(d[1].y, fixed));
                ctx.lineTo(x + bandWidth / 2, add_5(d[1].y, fixed));

                ctx.moveTo(x, add_5(d[1].y + d[1].h, fixed));
                ctx.lineTo(x - bandWidth / 2, add_5(d[1].y + d[1].h, fixed));
            } else {
                // 亏
                ctx.moveTo(x, add_5(d[1].y, fixed));
                ctx.lineTo(x - bandWidth / 2, add_5(d[1].y, fixed));

                ctx.moveTo(x, add_5(d[1].y + d[1].h, fixed));
                ctx.lineTo(x + bandWidth / 2, add_5(d[1].y + d[1].h, fixed));
            }
        })
    }
}

function klinePrice(data, bandWidth, fixed, ctx) {
    var x, adjust, temp1, temp2;
    if (bandWidth <= 3) {
        map(data, (d, i) => {
            x = add_5(d[1].lineX, fixed);
            temp1 = add_5(d[1].lineYTop, fixed);
            temp2 = add_5(d[1].lineYBottom, fixed);
            // 微调 如果线的距离不到2，用2补全
            // @todo 用1补还是看不到点，所以改用2
            adjust = Math.abs(temp1 - temp2) < 2 ? 2 : 0
            ctx.moveTo(x, temp1 - adjust);
            ctx.lineTo(x, temp2);
        });
    } else {
        map(data, (d, i) => {
            x = add_5(d[1].lineX, fixed);
            // 微调 矩形高度不足1时，画一条横线替代
            if (d[1].h < 1) {
                ctx.moveTo(add_5(d[0], fixed), add_5(d[1].y, fixed));
                ctx.lineTo(add_5(d[0] + bandWidth, fixed), add_5(d[1].y, fixed));
            } else {
                ctx.rect(add_5(d[0], fixed), add_5(d[1].y, fixed), bandWidth, d[1].h);
            }

            ctx.moveTo(x, add_5(d[1].lineYTop, fixed));
            ctx.lineTo(x, add_5(d[1].y, fixed));
            ctx.moveTo(x, add_5(d[1].y + d[1].h, fixed));
            ctx.lineTo(x, add_5(d[1].lineYBottom, fixed));

            // ctx.moveTo(x, add_5(d[1].lineYTop, fixed));
            // ctx.lineTo(x, add_5(d[1].lineYBottom, fixed));

        })
    }
}

var canvasBarInterpolates = {
    'vol': klinePrice,
    'kline': klinePrice,
    'bamboo': klineBamboo
}

// data 举例: [32, {lineX,y,lineYTop,lineYBottom,h} ]
function drawBar() {
    var lineWidth = 1,
        bandWidth = 1,
        drawTemplate = klinePrice,
        ctx = undefined;

    var bar = function (data) {
        // var fixed = (bandWidth <= 3 || lineWidth%2 === 0 ) ? 0 : 0.5;
        var fixed = 0.5;

        if (data.length) {
            drawTemplate(data, bandWidth, fixed, ctx);
        }
        return bar;
    }

    bar.ctx = function (_) {
        ctx = _;
        return bar;
    };

    bar.bandWidth = function (_) {
        bandWidth = _;
        return bar;
    }

    bar.lineWidth = function (_) {
        lineWidth = _;
        return bar;
    };

    bar.drawTemplate = function (_) {
        if (typeof _ === 'function') {
            drawTemplate = _;
        } else {
            drawTemplate = canvasBarInterpolates[_];
        }

        return bar;
    };

    return bar;
}


export default Path.extend({

    type: 'candlesticks',

    shape: {
        data: []
    },

    style: {
        lineWidth: 1
    },

    updateKLine: function () {
        !this.kline && (this.kline = drawBar());
        let {
            kline,
            shape
        } = this;

        for (let p in shape) {
            if (!~['data', 'percent', 'yStart'].indexOf(p) && kline[p] && typeof shape[p] !== 'undefined') {
            // if (p === 'y') {
            //     let y = shape[p];
            //     let yIsFunc = isFunction(y);
            //     let percent = shape.percent;
            //     let yStart = shape.yStart;
            //     let yStartIsFunc = isFunction(yStart);

            //     kline[p]((v, i, data) => {
            //         let _y = yIsFunc ? y(v, i, data) : y;

            //         if (typeof yStart === 'undefined') {
            //             return _y;
            //         } else {
            //             return _y * percent + (yStartIsFunc ? yStart(v, i, data) : yStart) * (1 - percent)
            //         }
            //     });
            // } else {
                kline[p](shape[p]);
            // }
            }
        }
    },

    buildPath: function (ctx, shape) {
        this.updateKLine();
        this.kline.ctx(ctx)(shape.data);
    }
});