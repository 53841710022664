import { Text, Line, Circle } from '../../util/graphic';
import ChartView from '../../view/Chart';
import D3Arc from '../../shape/D3Arc';
import { parsePercent, isArray } from '../../util';
import { TYPE } from './GaugeModel';
import linear from 'd3-scale/src/linear';
import { interpolateRgb } from 'd3-interpolate';
import { scaleLinear } from 'd3-scale';

export default class GaugeView extends ChartView {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    render(model, globalModel, global) {

        let {
            min,
            max,
            value,
            radius,
            center,
            startAngle,
            endAngle,
            percent,
            title,
            color
        } = model;
        let arcNumOpt = model.get('arcNum');
        let splitNumber = model.get('splitNumber');
        let axisLineOpt = model.get('axisLine');
        let detailOpt = model.get('detail');
        let titleOpt = model.get('title');
        let splitLineOpt = model.get('splitLine');
        let axisLabelOpt = model.get('axisLabel');
        let axisTickOpt = model.get('axisTick');
        let tickOffset = model.get('tickOffset');
        let gradient = model.get('gradient');
        let animation = model.get('animation');
        let arcOutline = model.get('arcOutline');
        let endArc = model.get('endArc');

        let scaleAngle = scaleLinear().domain([0, 2 * Math.PI]).range([0, 360])
        let endArcAngle = scaleAngle.invert(endArc.angleCross)

        tickOffset = parsePercent(tickOffset, radius);

        let { style } = axisLineOpt;
        let _startAngle = startAngle + Math.PI / 2;
        let _endAngle = endAngle + Math.PI / 2;
        let outerRadius = radius;
        let innerRadius = radius - style.lineWidth;

        // 外框圆弧
        if (arcOutline.show) {
            this.setShape('arcOutline', D3Arc, {
                shape: {
                    outerRadius: outerRadius,
                    innerRadius: innerRadius,
                    startAngle: _startAngle,
                    endAngle: _endAngle,
                    cornerRadius: 0,
                },
                position: center,
                style: arcOutline.style,
                cursor: 'default'
            })
        }

        // arc
        if (axisLineOpt.show) {
            if (gradient || !color) {
                let colorObj = handleList(color)
                // 循环次数，比如3种颜色，是2段渐变
                let segment = color.length - 1;
                // arc的间隙：每2种渐变色之间画 arcNumOpt 段弧，比如3种渐变色要画 arcNumOpt*2 段
                let gap = (_endAngle - _startAngle) * percent / (arcNumOpt * segment);
                let attrs = [];
                for (let i = 0; i < arcNumOpt * segment; i++) {
                    let curStart = _startAngle + (gap * i)
                    let curEnd = _startAngle + (gap * i) + gap
                    let curColor = scaleColor(colorObj.color, colorObj.domain, curStart, _startAngle, _endAngle)
                    attrs.push({
                        key: i,
                        shape: {
                            outerRadius: outerRadius,
                            innerRadius: innerRadius,
                            startAngle: curStart,
                            endAngle: curEnd,
                            cornerRadius: 0
                        },
                        position: center,
                        style: {
                            fill: curColor,
                            stroke: curColor
                        },
                        cursor: 'default'
                    });
                }
                this.setShapeGroup('axisLine', D3Arc, attrs, {
                    animation,
                    animateFrom: {
                        shape: {
                            startAngle: _startAngle,
                            endAngle: _startAngle
                        }
                    }
                });

            } else {
                let attrs = [];

                for (let i = 0; i < color.length; i++) {
                    let s, e;

                    if (i === 0) {
                        s = _startAngle;

                        if (percent < color[i][0]) {
                            e =
                                _startAngle +
                                (_endAngle - _startAngle) * percent;
                        } else {
                            e =
                                _startAngle +
                                (_endAngle - _startAngle) * color[i][0];
                        }
                    } else {
                        if (percent < color[i - 1][0]) {
                            s = e =
                                _startAngle +
                                (_endAngle - _startAngle) * percent;
                        } else if (percent < color[i][0]) {
                            s =
                                _startAngle +
                                (_endAngle - _startAngle) * color[i - 1][0];
                            e =
                                _startAngle +
                                (_endAngle - _startAngle) * percent;
                        } else {
                            s =
                                _startAngle +
                                (_endAngle - _startAngle) * color[i - 1][0];
                            e =
                                _startAngle +
                                (_endAngle - _startAngle) * color[i][0];
                        }
                    }

                    attrs.push({
                        shape: {
                            outerRadius: outerRadius,
                            innerRadius: innerRadius,
                            startAngle: s,
                            endAngle: e
                        },
                        position: center,
                        style: {
                            fill: color[i][1],
                            ...style
                        },
                        key: color[i][0],
                        z2: 0,
                        silent: true
                    });
                }

                this.setShapeGroup('axisLine', D3Arc, attrs, {
                    animation,
                    animateFrom: {
                        shape: {
                            startAngle: _startAngle,
                            endAngle: _startAngle
                        }
                    }
                });
            }
        }

        // 结尾高亮圆弧
        if (endArc.show && endArc.angleCross !== 0) {
            this.setShape('endArc', D3Arc, {
                shape: {
                    outerRadius: outerRadius,
                    innerRadius: innerRadius,
                    startAngle: _startAngle + (_endAngle - _startAngle) * percent,
                    endAngle: _startAngle + (_endAngle - _startAngle) * percent + endArcAngle,
                    cornerRadius: 0
                },
                position: center,
                style: endArc.style,
                cursor: 'default'
            }, {
                animation,
                animateFrom: {
                    shape: {
                        startAngle: _startAngle,
                        endAngle: _startAngle
                    }
                }
            })
        }

        // 文字
        if (detailOpt.show) {
            let { offsetCenter, formatter, style } = detailOpt;

            offsetCenter = parseOffset(offsetCenter, radius);

            this.setShape('detail', Text, {
                style: globalModel.getFormattedText({
                    text: formatter ? formatter(value) : value,
                    ...style
                }),
                position: [
                    center[0] + offsetCenter[0],
                    center[1] + offsetCenter[1]
                ]
            });
        }

        // 标题
        if (titleOpt.show && title) {
            let { offsetCenter, style } = titleOpt;

            offsetCenter = parseOffset(offsetCenter, radius);

            this.setShape('title', Text, {
                style: globalModel.getFormattedText({
                    text: title,
                    ...style
                }),
                position: [
                    center[0] + offsetCenter[0],
                    center[1] + offsetCenter[1]
                ]
            });
        }

        // 分隔线
        if (splitLineOpt.show) {
            let { length, style, color } = splitLineOpt;
            let attrs = [];
            let stepAngle = (endAngle - startAngle) / splitNumber;
            let curAngle = startAngle;
            let colorObj = {}
            if (splitLineOpt.gradient) {
                colorObj = handleList(color)
            }
            length = parsePercent(length, radius);

            for (let i = 0; i <= splitNumber; i++) {
                let curStyle = {}
                if (axisTickOpt.gradient) {
                    let curColor = scaleColor(colorObj.color, colorObj.domain, curAngle, startAngle, endAngle)
                    curStyle = {
                        fill: curColor,
                        stroke: curColor
                    }
                } else {
                    curStyle = style
                }
                if (splitLineOpt.type === 'tick') {
                    attrs.push({
                        shape: {
                            x1:
                                center[0] +
                                Math.cos(curAngle) * (radius - tickOffset),
                            x2:
                                center[0] +
                                Math.cos(curAngle) * (radius - tickOffset - length),
                            y1:
                                center[1] +
                                Math.sin(curAngle) * (radius - tickOffset),
                            y2:
                                center[1] +
                                Math.sin(curAngle) * (radius - tickOffset - length)
                        },
                        style: curStyle,
                        z2: 5,
                        silent: true
                    });
                } else if (splitLineOpt.type === 'circle') {
                    attrs.push({
                        shape: {
                            cx: center[0] + Math.cos(curAngle) * (radius - tickOffset),
                            cy: center[1] + Math.sin(curAngle) * (radius - tickOffset),
                            r: length
                        },
                        style: curStyle,
                        silent: true
                    });
                }
                curAngle += stepAngle;
            }
            if (splitLineOpt.type === 'tick') {
                this.setShapeGroup('splitLine', Line, attrs);
            } else if (splitLineOpt.type === 'circle') {
                this.setShapeGroup('splitLine', Circle, attrs);
            }
        }

        // 刻度
        if (axisTickOpt.show) {

            let { length, splitNumber: tickNumber, style, color } = axisTickOpt;
            let attrs = [];
            let number = splitNumber * tickNumber;
            let stepAngle = (endAngle - startAngle) / number;
            let curAngle = startAngle;
            let colorObj = {}
            if (axisTickOpt.gradient) {
                colorObj = handleList(color)
            }
            length = parsePercent(length, radius);
            for (let i = 0; i <= number; i++) {
                let curStyle = {}
                if (axisTickOpt.gradient) {
                    let curColor = scaleColor(colorObj.color, colorObj.domain, curAngle, startAngle, endAngle)
                    curStyle = {
                        fill: curColor,
                        stroke: curColor
                    }
                } else {
                    curStyle = style
                }
                if (axisTickOpt.type === 'tick') {
                    attrs.push({
                        shape: {
                            x1:
                                center[0] +
                                Math.cos(curAngle) * (radius - tickOffset),
                            x2:
                                center[0] +
                                Math.cos(curAngle) * (radius - tickOffset - length),
                            y1:
                                center[1] +
                                Math.sin(curAngle) * (radius - tickOffset),
                            y2:
                                center[1] +
                                Math.sin(curAngle) * (radius - tickOffset - length)
                        },
                        style: curStyle,
                        z2: 4,
                        silent: true
                    });
                } else if (axisTickOpt.type === 'circle') {
                    attrs.push({
                        shape: {
                            cx: center[0] + Math.cos(curAngle) * (radius - tickOffset),
                            cy: center[1] + Math.sin(curAngle) * (radius - tickOffset),
                            r: length
                        },
                        style: curStyle,
                        silent: true
                    });
                }
                curAngle += stepAngle;
            }
            if (axisTickOpt.type === 'tick') {
                this.setShapeGroup('axisTick', Line, attrs);
            } else if (axisTickOpt.type === 'circle') {
                this.setShapeGroup('axisTick', Circle, attrs);
            }
        }

        // 刻度值
        if (axisLabelOpt.show) {
            let { formatter, distance, style } = axisLabelOpt;
            let attrs = [];
            let stepAngle = (endAngle - startAngle) / splitNumber;
            let curAngle = startAngle;
            let step = (max - min) / splitNumber;
            let cur = min;
            let splitLineLen = parsePercent(splitLineOpt.length, radius);

            distance = parsePercent(distance, radius);

            for (let i = 0; i <= splitNumber; i++) {
                attrs.push({
                    style: globalModel.getFormattedText({
                        x:
                            center[0] +
                            Math.cos(curAngle) *
                                (radius - tickOffset - splitLineLen - distance),
                        y:
                            center[1] +
                            Math.sin(curAngle) *
                                (radius - tickOffset - splitLineLen - distance),
                        text: formatter ? formatter(cur) : cur,
                        ...style
                    }),
                    z2: 7,
                    silent: true
                });

                curAngle += stepAngle;
                cur += step;
            }

            this.setShapeGroup('axisLabel', Text, attrs);
        }
    }
}

function parseOffset(offset, base) {
    let re;

    if (isArray(offset)) {
        re = [parsePercent(offset[0], base), parsePercent(offset[1], base)];
    } else {
        re = [parsePercent(offset, base), parsePercent(offset, base)];
    }

    return re;
}

function handleList (colorList) {
    let result = {
        domain: [],
        color: []
    }
    for (let i = 0; i < colorList.length; i++) {
        result.domain.push(colorList[i][0])
        result.color.push(colorList[i][1])
    }
    return result
}

function scaleColor (colorList, domainList, curAngle, startAngle, endAngle) {
    let angleToPer = scaleLinear().domain([startAngle, endAngle]).range([0, 1]);
    let perToColor = scaleLinear().domain(domainList).range(colorList).interpolate(interpolateRgb);
    return perToColor(angleToPer(curAngle))
}
