/**
 * Radial network （edge bundling图）
 * https://bl.ocks.org/rpgove/d40e8b02a5f9edb50d31c37c4c03a79a
 *
 * by zp
 * 
 * 关系图
 */
import SeriesModel from '../../model/Series';
import { each, parsePercent } from '../../util';

export const TYPE = 'radial';

export default class RadialModel extends SeriesModel {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    static emphasisList = ['label', 'node', 'link'];

    static defaultOption = {
        zlevel: 0, // canvas层级
        z: 1, // 同一层canvas内层级

        startAngle: 0, // {Number} 径向布局起始角度
        endAngle: 360, // {Number}径向布局结束角度
        gap: 10, // {Number} 节点与标签间隔
        initialTreeDepth: 2, // {Number} 树图初始展开的层级（深度）。
        expandAndCollapse: true, // {Boolean} 子树折叠和展开的交互，默认打开 。
        separation: undefined, // {Function }设置间隔访问器,

        label: {
            normal: {
                show: true,
                silent: true,
                formatter: undefined,
                style: {
                    fill: '#5097f8',
                    opacity: 0.8
                }
            },
            emphasis: {}
        },

        node: {
            normal: {
                show: true,
                type: 'circle',
                size: 10,
                style: {
                    stroke: '#5097f8',
                    fill: 'white'
                }
            }
        },

        link: {
            normal: {
                show: true,
                style: {
                    stroke: '#67cacb'
                }
            }
        }
    };

    initialized = false;

    update() {
    }
}
