import { Path } from '../util/graphic';

export default Path.extend({

    type: 'ellipse',

    shape: {
        x: 0,
        y: 0,
        width: 0,
        height: 0
    },
    style: {
        stroke: '#000',

        fill: null
    },

    buildPath: function (ctx, shape) {

        var { x, y, width: w, height: h } = shape;

        var kappa = .5522848,
            ox = (w / 2) * kappa, // control point offset horizontal
            oy = (h / 2) * kappa, // control point offset vertical
            xe = x + w, // x-end
            ye = y + h, // y-end
            xm = x + w / 2, // x-middle
            ym = y + h / 2; // y-middle

        ctx.moveTo(x, ym);
        ctx.bezierCurveTo(x, ym - oy, xm - ox, y, xm, y);
        ctx.bezierCurveTo(xm + ox, y, xe, ym - oy, xe, ym);
        ctx.bezierCurveTo(xe, ym + oy, xm + ox, ye, xm, ye);
        ctx.bezierCurveTo(xm - ox, ye, x, ym + oy, x, ym);
    }
});
