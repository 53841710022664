import { filterStatus, decimalRound } from '../util/hqHelper';

/**
 * 冷静期
 * @param  {object} d
 * @return {object}
 */
function arrangeCoolingPeriod(d) {
    var starttime = d['276'].substring(0, 2)+':'+d['276'].substring(2, 4)+':'+d['276'].substring(4, 6);
    var endtime = d['277'].substring(0, 2)+':'+d['277'].substring(2, 4)+':'+d['277'].substring(4, 6);

    // 冷静期 返回的object值
    var dataobj = {
        //参考价
        considerPrice: d['51'],
        //价格上限
        priceCeil : d['69'],

        //价格下限
        priceFloor: d['70'],

        //开始时间
        starttime: d['276'],
        starttimeStr: starttime,

        //结束时间
        endtime: d['277'],
        endtimeStr: endtime
    }

    return dataobj;
}

/**
 * 集合竞价阶段
 * @param  {object} d 需要含有的字段 d.hkStatus, d['278'], d['278'], d['70'], d['69'], d['10']
 *
 * @return {object}
 */
function arrangeBidPeriod(d) {
    // 竞价集合阶段 返回的object值
    var dataobj = {
        // 交易时段
        bidPeriod: undefined,
        // 最低价限制
        minPrice: undefined,
        // 最高价限制
        maxPrice: undefined,
        // 参考价
        considerPrice: undefined,
        // 买卖盘不平衡方向
        direct: undefined,
        // 买卖盘不平衡数量
        directNum: undefined
    }

    // 收市竞价
    var bidPeriodMap = {
        '3':'开盘',
        '4':'买卖盘配对阶段',
        '5':'输入买卖盘阶段',
        '105':'参考定价阶段',
        '106':'参考定价阶段',
        '107':'随机收盘',
        '0': '收盘'
    };

    dataobj.bidPeriod = bidPeriodMap[d.hkStatus];
    dataobj.direct = d['278'] >= 0 ? '买' : '卖';
    dataobj.directNum = Math.abs(d['278']);

    dataobj.minPrice = d['70'];
    dataobj.maxPrice = d['69'];

    if (/^(105|106|5)$/.test(d.hkStatus)) {
        dataobj.considerPrice = d['10'];
    }else{
        dataobj.considerPrice = '';
    }

    return dataobj;
}

/**
 * 港股特殊状态 整理
 * @param  {object} d
 * @return {object}
 *
 * 港股状态分为
 * 1. 正常交易时间
 * 2. 不开市
 * 3. 冷静期(市场波动调节阶段) coolingPeriod
 * 4. 集合竞价阶段 bidPeriod
 * 本函数处理的是，当进入 3或4 状态时的数据整理
 */
function hKStatus(d){
    var statusMap = {
        'coolingPeriod':'冷静期 市场波动调节阶段',
        'bidPeriod':'集合竞价阶段'
    }

    var hqStatus, hqStatusDetail = {};
    if(d['276'] > 0 || d['277'] > 0){
        hqStatus = 'coolingPeriod';
        hqStatusDetail = arrangeCoolingPeriod(d);

    }else if(/^(4|5|105|106|107)$/.test(d.hkStatus)){
        hqStatus = 'bidPeriod';
        hqStatusDetail = arrangeBidPeriod(d);
    }
    hqStatusDetail.msg = statusMap[hqStatus];
    return {
        hqStatus: hqStatus,
        hqStatusDetail: hqStatusDetail
    }

}


// 两个价格比较, 计算  差价, 百分比, 涨跌状态
function arrangeUsa(curPrice, oldPrice) {
    var curPriceShow = parseFloat(curPrice);
    var oldPriceShow = parseFloat(oldPrice);
    var rateP = curPriceShow - oldPriceShow;
    var rate = rateP / oldPriceShow * 100;
    var color = filterStatus(curPriceShow, oldPriceShow);
    return {
        rateP: decimalRound(rateP, 3),
        status:color,
        curPrice: curPrice,
        curPriceShow: decimalRound(curPrice, 3),
        oldPrice:oldPrice,
        oldPriceShow: decimalRound(oldPrice, 3),
        rate: decimalRound(rate, 2)
    }
}

/*
* 美股状态控制
* 254 盘前交易价格
* 278 盘后交易价格
* 有字段 就显示, 盘前  价格存在, 减去 今收
* 有字段 就显示, 盘后  价格存在, 减去 今收
*/
function usaStatus(d){
    var statusMap = {
        'panqian':'盘前',
        'panhou':'盘后'
    }

    var hqStatus, hqStatusDetail = {};

     if (d['254'] > 0){
        hqStatus = 'panqian';
        hqStatusDetail = arrangeUsa(d['254'], d['10']);

    }else if(d['278'] > 0) {
        hqStatus = 'panhou';
        hqStatusDetail = arrangeUsa(d['278'], d['10']);
    }
    hqStatusDetail.msg = statusMap[hqStatus];
    hqStatusDetail.time = d.time;

    return {
        hqStatus: hqStatus,
        hqStatusDetail: hqStatusDetail
    }
}


/**
 * 获取股票相关 行情状态
 * @param  {string} market e.g: stockType.msg or 'HK_stock'
 * @param  {object} d
 * @return {object}
 */
export function getStockstatus(market, d){
    market = market || 'HS_stock';
    if (/^(HK_stock)/.test(market)) {
        return hKStatus(d);
    }else if (/^(USA_stock)/.test(market)) {
        return usaStatus(d);
    }
}
