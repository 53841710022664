// 名称中勿使用 . 字符
// chart实例上的 事件 
// 
const instanceActions = {
    // 每次setOption  view render完成后触发 (不是实际绘图完后)
    // 曾用名 RENDER_END_AFTER_SETOPTION
    viewDidRenderAfterSetOption: 'VIEW_DID_RENDER_AFTER_SET_OPTION',

    // view render完成后触发 (不是实际绘图完后)
    // 曾用名 RENDER_END
    viewDidRender: 'VIEW_DID_RENDER'
}

const titleActions = {
    titleClick: 'TITLE_CLICK' // title被点击
};

const axisActions = {
    move: 'AXIS_MOVE', // 在axis所在的grid中移动
    out: 'AXIS_OUT', // 移出axis所在的grid
    zoomChange: 'AXIS_ZOOM_CHANGED', // axis的zoom改变
    domainChange: 'AXIS_DOMAIN_CHANGED' // axis的domain改变
};

const radarAxisActions = {
    move: 'RADAR_AXIS_MOVE', // 在axis所在的grid中移动
    out: 'RADAR_AXIS_OUT' // 移出axis所在的grid
}

const geoAxisActions = {
    over: 'GEO_AXIS_OVER',
    out: 'GEO_AXIS_OUT',
    itemClick: 'GEO_AXIS_ITEM_CLICK'
}

const axisPointerActions = {
    move: 'AXISPOINTER_MOVE',
  out: 'AXISPOINTER_OUT',
  before: 'BEFORE_AXISPOINTER_MOVE',
  after: 'AFTER_AXISPOINTER_MOVE'
};

const dataZoomActions = {
    start: 'ZOOM_MOVE_START', // dataZoom开始移动
    moving: 'ZOOM_MOVING', // dataZoom正在移动
    end: 'ZOOM_MOVE_END', // dataZoom移动结束
    wheel: 'ZOOM_WHEEL', // dataZoom的滚轮缩放
    change: 'ZOOM_CHANGE' // dataZoom的zoom发生改变
};

const seriesActions = {
    itemClick: 'ITEM_CLICK', // series中的某一项被点击
    dblClick: 'DBL_CLICK', // series 双击
    selectedListChange: 'SELECTEDLIST_CHANGE', // series的selectedList发生改变
    mouseover: 'ITEM_MOUSEOVER', // 鼠标mouseover series某一项
    mouseout: 'ITEM_MOUSEOUT', // 鼠标mouseout series某一项
    mousemove: 'ITEM_MOUSEMOVE' // 鼠标mousemove series某一项
};

const legendActions = {
    click: 'LEGEND_CLICK', // 图例某一项被点击
    mouseover: 'LEGEND_MOUSEOVER', // 鼠标mouseover图例某一项
    mouseout: 'LEGEND_MOUSEOUT', // 鼠标mouseout图例某一项
    mousemove: 'LEGEND_MOUSEMOVE' // 鼠标mousemove图例某一项
};

// d3-geo 待废弃
const geoActions = {
    showLabel: 'LABEL', // 显示geo的某一个marker的label
    markerClick: 'LABEL_NAME', // geo的某一个marker被点击
    itemClick: 'ITEM_CLICK', // 某个省份被点击
    mouseout: 'ITEM_MOUSEOUT', // 某个省份 悬停out
    mouseover: 'ITEM_MOUSEMOVE' // 某个省份被悬停
};

const markPointActions = {
    pointMousemove: 'MARKPOINT_MOUSEMOVE',
    pointMouseout: 'MARKPOINT_MOUSEOUT',
    pointMouseover: 'MARKPOINT_MOUSEOVER',
    pointClick: 'MARKPOINT_CLICK'
};

const markLineActions = {
    lineMousemove: 'MARKLINE_MOUSEMOVE', //鼠标mousemove标记线
    lineMouseout: 'MARKLINE_MOUSEOUT', //鼠标mouseout标记线
    lineMouseover: 'MARKLINE_MOUSEOVER', // 鼠标mouseover标记线
    lineClick: 'MARKLINE_CLICK' // markLine某处被点击
};

const dagrexActions = {
    nodeClick: 'NODE_CLICK',
    nodeMouseover: 'NODE_MOUSEOVER',
    nodeMousemove: 'NODE_MOUSEMOVE',
    nodeMouseout: 'NODE_MOUSEOUT',

    edgeClick: 'EDGE_CLICK',
    edgeMouseover: 'EDGE_MOUSEOVER',
    edgeMousemove: 'EDGE_MOUSEMOVE',
    edgeMouseout: 'EDGE_MOUSEOUT'
}

const sketchActions = {
    itemClick: 'ITEM_CLICK' // sketch所画上去的某一项被点击
};

const forceActions = {
    nodeClick: 'FOCUS_NODE', // force的node节点被点击
    linkClick: 'FOCUS_EDGE' // force的link连线被点击
};

const modelActions = {
    willUpdate: 'MODEL_WILL_UPDATE', // model将更新
    didUpdate: 'MODEL_DID_UPDATE' // model已更新
};

const viewActions = {
    didRender: 'VIEW_DID_RENDER' // view已渲染
};

// 行情数据处理
const dataProviderActions = {
    update: 'PROVIDER_UPDATE' // provider更新
};

// 行情数据池
const dataPoolActions = {
    update: 'POOL_UPDATE' // pool更新, 每个provider fetch状态更新 都会触发数据池的更新
};

export {
    markPointActions,
    markLineActions,
    dataProviderActions,
    dataPoolActions,
    titleActions,
    axisActions,
    radarAxisActions,
    axisPointerActions,
    dataZoomActions,
    seriesActions,
    legendActions,
    geoActions,
    geoAxisActions,
    dagrexActions,
    sketchActions,
    forceActions,
    viewActions,
    modelActions,
    instanceActions
}
