/**
 * 标题
 */
import ComponentModel from '../../model/Component';
import { parsePercent } from '../../util';

export const TYPE = 'title';

export default class TitleModel extends ComponentModel {

    static type = TYPE;

    type = TYPE;
    
    static emphasisList = ['style'];

    static defaultOption = {

        show: true, // {Boolean} 是否显示
        text: undefined, //{string} title文本内容
        // 显示位置
        left: '50%', // {String | Number} 左
        top: '5%', // {String | Number} 上 
        
        link: '', // {String} 标题文本超链接。
        target: 'blank', // {String} 指定窗口打开主标题超链接。

        style: { // 文本样式
            normal: {
                textAlign: 'center',
                textVerticalAlign: 'center',
                fill: '#333',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontFamily: 'sans-serif',
                fontSize: 18
            },
            emphasis: {

            }
        },
        
        zlevel: 0, // {number} 所有图形的 zlevel 值
        z: 10 // {number} 组件的所有图形的z值。控制图形的前后顺序。z值小的图形会被z值大的图形覆盖。
    };

    update() {
        let { _option: { left, top, show }, global } = this;
        let globalWidth = global.getWidth();
        let globalHeight = global.getHeight();

        this.show = show;

        if (show) {
            this.left = parsePercent(left, globalWidth);
            this.top = parsePercent(top, globalHeight);
        }
    }
}