import { Rect, subPixelOptimizeRect } from '../../../util/graphic';
import { map } from '../../../util';


function getLeftRight(xAxis, points, inCenter) {
    let xScale = xAxis.scale;
    let left, right;
    let start = points[0][0];
    let end = points[1][0];

    if (xAxis.getAxisType() === 'ordinal') {
        let xDomain = xScale.domain();
        let paddingInner = xScale.paddingInner ? xScale.paddingInner() * xScale.step() / 2 : 0;

        start = Math.max(start, xAxis.startIndex) - xAxis.startIndex;
        end = Math.min(end, xAxis.endIndex) - xAxis.startIndex;

        if (inCenter) {
            left = xScale(xDomain[start]) + xScale.bandwidth() / 2;
            right = xScale(xDomain[end]) + xScale.bandwidth() / 2;
        } else {
            if (end >= start) {
                left = xScale(xDomain[start]) - paddingInner;
                right = xScale(xDomain[end]) - paddingInner + (xAxis.get('type') === 'band' ? xScale.step() : 0);
            } else {
                left = xScale(xDomain[start]) - paddingInner + (xAxis.get('type') === 'band' ? xScale.step() : 0);
                right = xScale(xDomain[end]) - paddingInner;
            }
        }
    } else {
        left = xScale(start);
        right = xScale(end);
    }

    return {
        left,
        right,
        start,
        end
    }
}

function verticalRect({ shape, style }, model) {
    let points = shape.points;
    // let { left, right, start, end } = getLeftRight(xAxis, points);

    let attr = subPixelOptimizeRect({
        shape: {
            x: points[0][0],
            y: points[0][1],
            width: points[1][0] - points[0][0],
            height: points[1][1] - points[0][1]
        },
        style
    });

    return {
        attr,
        Shape: Rect
        // start,
        // end
    }
}

verticalRect.onlyInAxis = true;
verticalRect.pointNum = 2;


export default {
    verticalRect
   
};