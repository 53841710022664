import Arc from '../../shape/D3Arc'
import D3Line from '../../shape/D3Line'
import { Symbol } from '../../util/symbol'
import Text from 'zrender/src/graphic/Text'
import ChartView from '../../view/Chart'
import {merge, isArray, map, each, clone, getContext} from '../../util'
import extent from 'd3-array/src/extent'
import linear from 'd3-scale/src/linear'
import { Group } from '../../util/graphic';
import { seriesActions } from '../../action/event';
import { interpolateRgb } from 'd3-interpolate';
import { scaleLinear } from 'd3-scale';


const TYPE = 'pie'

export default class ArcView extends ChartView {


  static type = TYPE

  type = TYPE

  render(model, globalModel, global) {

    //this.remove()
    let isFirst = model._updateTime <= 1
    let data = model.drawData
    let idx = 0

    if(!data) return

    let ctx = getContext()
    let PI = Math.PI
    let PI2 = PI * 2
    let group = this.group
    let z = model.get('z')
    let zlevel = model.get('zlevel')
    let labelStyle = model.get('label')
    let hideList = model.get('label').hideList
    let labelSymbol = model.get('label').symbol
    let circleStyle = model.get('circleStyle')
    let itemStyle = model.get('itemStyle')
    let itemNorStyle = model.get('item').normal.style
    let itemEmpStyle = model.get('item').emphasis.style
    let that = this

    let arcCenter = model.get('center')
    let arcRadius = model.get('radius')
    let likeAlipay = model.get('likeAlipay')

    let gridModel = globalModel.getComponentByIndex('grid', 0) || {position: {width: global.getWidth(), height: global.getHeight(), left: 0, top: 0}}
    let {width, height, left, top} = gridModel.position

    let canvasW = width || global.getWidth()
    let canvasH = height || global.getHeight()


    let cx = typeof arcCenter[0] === 'string' ? parseInt(parseFloat(arcCenter[0].split('%')[0])/100*canvasW) : parseInt(arcCenter[0])
    let cy = typeof arcCenter[1] === 'string' ? parseInt(parseFloat(arcCenter[1].split('%')[0])/100*canvasH) : parseInt(arcCenter[1])

    let theme = model.get('theme')

    cx += left
    cy += top

    let innerRadius, outerRadius, hoverRadius, actualRadius = Math.min(canvasW, canvasH)
    if (arcRadius == undefined) {
      outerRadius = actualRadius / 2.5
      innerRadius = actualRadius / 4
      hoverRadius = outerRadius + 10
    } else {
      innerRadius = typeof arcRadius[0] === 'string' ? parseInt(parseFloat(arcRadius[0].split('%')[0])/100*actualRadius) : parseInt(arcRadius[0])
      outerRadius = typeof arcRadius[1] === 'string' ? parseInt(parseFloat(arcRadius[1].split('%')[0])/100*actualRadius) : parseInt(arcRadius[1])
      hoverRadius = typeof arcRadius[2] === 'string' ? parseInt(parseFloat(arcRadius[2].split('%')[0])/100*actualRadius) : (arcRadius[2] ? parseInt(arcRadius[2]): outerRadius + 8)
    }

    let labelPosition = labelStyle.position
    let isLabelCenter = !! (labelPosition == 'center')
    let isLabelInside = !! (labelPosition == 'inner')
    let isLabelBoth = !! (labelPosition == 'both')


    let labelLineLen = labelStyle.lineLen || [15, 15]
    let lineLen1 = isArray(labelLineLen) ? labelLineLen[0] : labelLineLen
    let lineLen2 = isArray(labelLineLen) ? labelLineLen[1] : labelLineLen

    let itemFormatter = labelStyle.formatter
    let nameFormatter = labelStyle.nameFormatter

    let arcList = []
    let arcAniList = []
    let textList = []
    // let textAniList = [];s
    let lineList = []
    // let lineAniList = []
    let symbolList = []
    let needAni = model.get('animation')

    let values = data.values

    let total = 0

    let scale

    let isDrew

    let innerCircle

    let hasShadow = !! itemStyle.shadowRadius

    let shadowCircle

    let renderData = []

    if (hasShadow) {
      shadowCircle = this.setShapeGroup('gray', Arc, [{
        key: 'gray',
        shape: {
          outerRadius: outerRadius,
          innerRadius: innerRadius
        },
        style: {
          fill: itemStyle.shadowColor
        },
        position: [cx, cy]
      }], {
        animation: needAni,
        animateFrom: [{
          position: [cx, cy]
        }]
      })
    }


      let avoidLabelGap = model.get('avoidLabelGap')
    function adjustSingleSide(list, cx, cy, r, dir, viewWidth, viewHeight) {
      list.sort(function (a, b) {
        return a.y - b.y
      })


      // 压
      function shiftDown(start, end, delta, dir) {
        for (var j = start; j < end; j++) {
          list[j].y += delta
          if (j > start
            && j + 1 < end
            && list[j + 1].y > list[j].y + list[j].height
          ) {
            shiftUp(j, delta / 2)
            return
          }
        }

        shiftUp(end - 1, delta / 2)
      }

      // 弹
      function shiftUp(end, delta) {
        for (var j = end; j >= 0; j--) {
          list[j].y -= delta
          if (j > 0
            && list[j].y > list[j - 1].y + list[j - 1].height
          ) {
            break
          }
        }
      }

      function changeX(list, isDownList, cx, cy, r, dir) {
        var lastDeltaX = dir > 0
          ? isDownList // 右侧
          ? Number.MAX_VALUE // 下
          : 0 // 上
          : isDownList // 左侧
          ? Number.MAX_VALUE // 下
          : 0; // 上

        for (var i = 0, l = list.length; i < l; i++) {
          // Not change x for center label
          if (list[i].position === 'center') {
            continue
          }
          var deltaY = Math.abs(list[i].y - cy)
          var length = list[i].len
          var length2 = list[i].len2
          var deltaX = (deltaY < r + length)
            ? Math.sqrt(
              (r + length + length2) * (r + length + length2)
              - deltaY * deltaY
            )
            : Math.abs(list[i].x - cx)
          if (isDownList && deltaX >= lastDeltaX) {
            // 右下，左下
            deltaX = lastDeltaX - 10
          }
          if (!isDownList && deltaX <= lastDeltaX) {
            // 右上，左上
            deltaX = lastDeltaX + 10
          }

          list[i].x = cx + deltaX * dir
          lastDeltaX = deltaX
        }
      }

      var lastY = 0
      var delta
      var len = list.length
      var upList = []
      var downList = []
      for (var i = 0; i < len; i++) {
        delta = list[i].y - lastY
        if (delta < 0) {
          shiftDown(i, len, -delta, dir)
        }
        lastY = list[i].y + list[i].height + avoidLabelGap
      }
      if (viewHeight - lastY < 0) {
        shiftUp(len - 1, lastY - viewHeight)
      }
      for (var i = 0; i < len; i++) {
        if (list[i].y >= cy) {
          downList.push(list[i])
        }
        else {
          upList.push(list[i])
        }
      }
      changeX(upList, false, cx, cy, r, dir)
      changeX(downList, true, cx, cy, r, dir)
    }

    function avoidOverlap(labelLayoutList, cx, cy, r, viewWidth, viewHeight) {
      var leftList = []
      var rightList = []
      for (var i = 0; i < labelLayoutList.length; i++) {
        if (labelLayoutList[i].x < cx) {
          leftList.push(labelLayoutList[i])
        }
        else {
          rightList.push(labelLayoutList[i])
        }
      }

      adjustSingleSide(rightList, cx, cy, r, 1, viewWidth, viewHeight)
      adjustSingleSide(leftList, cx, cy, r, -1, viewWidth, viewHeight)

      for (var i = 0; i < lineList.length; i++) {
        var linePoints = lineList[i].shape.data
        if (linePoints) {
          var dist = linePoints[1][0] - linePoints[2][0]
          var isLeft = labelLayoutList[i].x < cx


          linePoints[2][0] = labelLayoutList[i].x
          linePoints[1][1] = linePoints[2][1] = labelLayoutList[i].y
          linePoints[1][0] = linePoints[2][0] + dist

          if (labelStyle.outerOffset) {
            if (isLeft) {
              linePoints[2][0] = labelLayoutList[i].x = labelStyle.outerOffset
            } else {
              linePoints[2][0] = labelLayoutList[i].x = width - labelStyle.outerOffset
            }
          }

          if (labelLayoutList[i].x < cx) {
            linePoints[2][0] += 5
          } else {
            linePoints[2][0] -= 5
          }

          if (symbolList.length) {
            symbolList[i].position = [...linePoints[0]]
          }
        }
      }
    }


    function drawInnerCircle(item) {
      let r = circleStyle && circleStyle.innerCircleRadius || item.inner - 8
      let fc = circleStyle.fillColor || item.strokeColor
      let circle = this.setShapeGroup('innerCircle', Arc,
        [{
          key: 'innerCircle',
          shape: {
            outerRadius: r,
            innerRadius: 0,
            endAngle: PI2,
            startAngle: 0
          },
          position: [cx, cy],
          style: {
            fill: fc,
            stroke: null
          }
        }], {
          animation: needAni,
          animateFrom: [
            {
              position: [cx, cy]
            }
          ]
        }
      )
      //this.group.add(circle)
      return circle
    }

    function drawItem(item, i) {

      let {endAngle, inner, outer, hover, startAngle, strokeColor, itemValue, itemName, value} = item

      let textPan

      arcList.push({
        shape: {
          outerRadius: outer,
          innerRadius: inner,
          endAngle: endAngle,
          startAngle: startAngle
        },
        key: itemName,
        position: [cx, cy],
        style: {
          fill: strokeColor,
          ...itemNorStyle
        },
        seriesIndex: model.index,
        data: {
          name: itemName,
          value: `${(itemValue * 100).toFixed(0)}%`,
          item
        },
        z: 10
      })

      arcAniList.push({
        position: [cx, cy]
      })

      /*let arc = this.setShapeGroup('shape_' + i, Arc,
      // shape的属性等设置
                [{
                    shape: {
                        outerRadius: outerRadius,
                        innerRadius: innerRadius,
                        endAngle: endAngle,
                        startAngle: startAngle
                    },
                    key: i,
                    position: [cx, cy],
                    style: {
                        fill: strokeColor,
                        stroke: null
                    },
                    seriesIndex: model.index,
                    data: {
                        name: itemName,
                        value: `${(itemValue * 100).toFixed(0)}%`,
                        item
                    },
                    z: 10
                }], {
                    animation: true,
                    animateFrom: [{
                        position: [cx, cy]
                    }]
                }, undefined, function(arc) {
                    arc.on('mouseover', onEmphasis)
                    arc.on('mouseout', onNormal)
                }
            );*/
      // let tooltipFormatter = globalModel.get('tooltip') && globalModel.get('tooltip').formatter
      // if (!tooltipFormatter) {
      //     tooltipFormatter = function(text, value) {
      //         return `${text}: ${(value * 100).toFixed(0)}%`
      //     }
      // }
      // arc.data = {value: tooltipFormatter(itemName, itemValue)}

      //arc.on('mouseover', onEmphasis, item)
      //arc.on('mouseout', onNormal)

      item.loopKey = item.dataIndex

      if (typeof labelStyle.show === 'undefined') {
        if (isLabelCenter) {
          if (!isDrew) {
            innerCircle = drawInnerCircle.call(this, item)
            textPan = drawText.call(this, item, true)
            isDrew = textPan
          } else {
            textPan = isDrew
          }
        } else{
          item.itemName = ''
          textPan = drawText.call(this, item)
          //textPan.on('mouseover', onEmphasis)
        }
        //textPan.on('mouseout', onNormal)
      }


    }

    function onShape(name) {
      if (textShape.childOfName(name) && lineShape.childOfName(name)) {
        let target = {
            opacity: 1
        }

        textShape.childOfName(name).setStyle(target)
        lineShape.childOfName(name).setStyle(target)
        symbolShape.childOfName(name).setStyle(target)
      }
    }

    function offShape(name) {
      let target = {
        opacity: 0
      }
      textShape.eachChild(d => d.setStyle(target))
      lineShape.eachChild(d => d.setStyle(target))
      symbolShape.eachChild(d => d.setStyle(target))
    }

    function onEmphasis() {

      onNormal.call(that)
      let item = this.data.item
      let name = this.name
      if (innerCircle) {
        if (!circleStyle.fillColor) {
          innerCircle.childAt(0).setStyle({
            fill: item.strokeColor
          })
        }
        textShape && textShape.childAt(0).setStyle({
          'text': itemFormatter(item, item.itemValue) + (item.itemName ? '\n{a|' + item.itemName + '}' : ''),
          'fill': labelStyle.emphasis && labelStyle.emphasis.color || labelStyle.color || item.strokeColor
        })
      }
      //textPan.setStyle({
      //'text': itemFormatter(null, itemValue) + (item.itemName ? '\n{a|' + item.itemName + '}' : ''),
      //'fill': labelStyle.emphasis && labelStyle.emphasis.color || labelStyle.color || strokeColor,
      //'x': textPan.emphasisOffsetX,
      //'y': textPan.emphasisOffsetY
      //})

      this.stopAnimation(true)

      let style = merge(clone(itemNorStyle), itemEmpStyle, true)

      this.animateTo({
        shape: {
          innerRadius: hasShadow ? (item.inner + itemStyle.shadowRadius) : item.inner,
          outerRadius: item.hover
        },
        style: {
          ...style
        },
        z2: ++idx
      }, 300, 'elasticOut')

      likeAlipay && (offShape(), onShape(name))

    }

    function onNormal() {
      this._shape.eachChild(d => {

        let item = d.data.item
        d.animateTo({
          shape: {
            outerRadius: item.outer,
            innerRadius: item.inner
          },
          style: {
            ...itemNorStyle
          }
        }, 50, 'elasticOut')
      })

      //textPan.setStyle({
      //'x': textPan.textX,
      //'y': textPan.textY
      //}, 50, 'elasticOut')

      likeAlipay && offShape()
    }


    this.onShape = onShape
    this.offShape = offShape
    this.onEmphasis = onEmphasis
    this.onNormal = function() {
      onNormal.call(that)
    }


    function measureText(text) {
      ctx.save()
      ctx.font = `${labelStyle.textStyle.fontSize || 12}px sans-serif`
      let width = Math.max.apply(Math, text.split('\n').map(d => ctx.measureText(d).width))
      ctx.restore()
      return width
    }

    let measureMap = {}

    function getColorFromGradient(gradientColor) {
      if (typeof gradientColor !== 'object') {
        return gradientColor
      } else {
        let colorstops = gradientColor.colorStops
        let lastIdx = colorstops.length - 1
        return colorstops[lastIdx].color
      }
    }

    function drawText(item, isCenter) {

      let {endAngle, startAngle, strokeColor, itemValue, value} = item

      let a = (endAngle + startAngle) / 2 - PI / 2

      let r = (outerRadius + innerRadius) / 2

      let dx = Math.cos(a)
      let dy = Math.sin(a)

      let offsetX = isCenter ? 0 : dx * r
      let offsetY = isCenter ? 0 : dy * r

      let outerL = likeAlipay ? (hoverRadius - outerRadius) * 2 : 0

      let x1 = (isLabelInside ? (outerRadius + innerRadius) / 2 * dx : (outerRadius + outerL) * dx) + cx
      let y1 = (isLabelInside ? (outerRadius + innerRadius) / 2 * dy : (outerRadius + outerL) * dy) + cy
      let x2 = x1 + dx * (lineLen1)
      let y2 = y1 + dy * (lineLen1)

      if (likeAlipay) {
        let validWidth = x2
        if (dx > 0) {
          validWidth = global.getWidth() - x2
        }
        item.validWidth = validWidth
        item.labelFontSize = labelStyle.textStyle.fontSize
      } else {

        let x3 = x2 + ((dx < 0 ? -1 : 1) * lineLen2)
        let validWidth = x3
        if (dx > 0) {
          validWidth = global.getWidth() - x3
        }
        item.validWidth = validWidth
        item.labelFontSize = labelStyle.textStyle.fontSize

      }

      let textX
      let textY
      let linePoints = []
      let textAlign = 'center'
      let textColor = labelStyle.textStyle.color;
      let insideColor = labelStyle.textStyle.insideColor || textColor
      let text = itemFormatter(item, itemValue);
      let invisible = likeAlipay ? 0 : 1

      if (text === false) return false;

      if (!isLabelCenter) {
        if (!isLabelInside) {
          // For outer
          let textWidth = measureMap[text] || measureText(text)
          measureMap[text] = textWidth
          if (likeAlipay) {
            lineLen2 = textWidth
          }
          var x3 = x2 + ((dx < 0 ? -1 : 1) * lineLen2)
          var y3 = y2

          textAlign = isLabelInside ? 'center' : (dx > 0 ? 'left' : 'right')
          textColor = (!textColor || textColor == 'auto') ? strokeColor : textColor
          if (likeAlipay) {
            textX = x2 + ((dx < 0 ? -1 : 0) * textWidth)
            textY = y2 - 2
            textAlign = labelStyle.textStyle.textAlign
          } else {
            textX = x3 + (dx < 0 ? -5 : 5)
            textY = y3
          }

          if (isLabelBoth) {


            textList.push({
              key: 'both' + item.name,
              style: merge({
                text,
                x: cx + offsetX,
                y: cy + offsetY,
                fontSize: labelStyle.textStyle.fontSize,
                fontFamily: labelStyle.textStyle.fontFamily,
                textBaseline: 'middle',
                textAlign: 'center',
                textFill: getColorFromGradient(insideColor)
              }, labelStyle.textStyle, true),
              silent: true,
              z: 11
            })

            text = nameFormatter(item)
          }

          if (itemStyle.type == 'rose') {
            x1 = scale(value) * dx + cx
            y1 = scale(value) * dy + cy
          }
          linePoints = [[x1, y1], [x2, y2], [x3, y3]]

          //avoidOverlap(linePoints, cx, cy, outerRadius, canvasW, canvasH)

          lineList.push({
            shape: {
              data: linePoints
            },
            key: item.name,
            style: {
              stroke: getColorFromGradient(labelStyle.lineColor || textColor),
              opacity: invisible
            },
          })
          // lineAniList.push({
          //     shape: {
          //         data: linePoints
          //     }
          // })

          if (labelSymbol.show) {
            symbolList.push({
              key: item.name,
              shape: {
                symbolType: labelSymbol.type,
                x: -labelSymbol.size / 2,
                y: -labelSymbol.size / 2,
                width: labelSymbol.size,
                height: labelSymbol.size
              },
              style: {
                fill: textColor,
                opacity: invisible,
                ...labelSymbol.style
              },
              position: [x1, y1]
            })
          }
          /*this.setShapeGroup('line_' + item.loopKey, D3Line, [{
                        shape: {
                            data: linePoints
                        },
                        key: item.loopKey,
                        style: {
                            stroke: labelStyle.lineColor || textColor
                        }
                    }], {
                        animation: true,
                        animateFrom: [{
                            shape: {
                                data: linePoints
                            }
                        }]
                    });*/


        } else {
          textX = cx + offsetX
          textY = cy + offsetY
        }
      } else {
        textX = cx
        textY = cy
      }
      textList.push({
        key: item.name,
        style: merge({
          opacity: invisible,
          text: text + (item.itemName ? '\n{a|' + item.itemName + '}' : ''),
          x: textX,
          y: textY,
          fontSize: labelStyle.textStyle.fontSize,
          fontFamily: labelStyle.textStyle.fontFamily,
          textBaseline: likeAlipay ? 'bottom' : 'middle',
          textAlign: textAlign,
          textFill: getColorFromGradient(textColor),
          rich: {
            a: {
              fontSize: labelStyle.subTextStyle.fontSize,
              textLineHeight: labelStyle.subTextStyle.textLineHeight
            }
          }
        }, labelStyle.textStyle, true),
        silent: true,
        z: 11
      })

      // textAniList.push({
      //     style: {
      //         x: textX,
      //         y: textY
      //     }
      // })

      /*let textPan = this.setShapeGroup('text_' + item.loopKey, Text, [{
                key: item.loopKey,
                style: {
                    text: itemFormatter(null, itemValue) + (item.itemName ? '\n{a|' + item.itemName + '}' : ''),
                    x: textX,
                    y: textY,
                    textFont: labelStyle.textStyle.fontSize+'px '+labelStyle.textStyle.fontFamily,
                    textBaseline: 'middle',
                    textAlign: textAlign,
                    textFill: textColor,
                    rich: {
                        a: {
                            font: '14px arial',
                            textLineHeight: 30
                        }
                    }
                },
                z: 11
            }], {
                animation: true,
                animateFrom: [{
                    style: {
                        x: textX,
                        y: textY
                    }
                }]
            })*/

      /*let emphasisOffsetX = isCenter ? cx : ((isLabelInside ? (cx + dx * (r + itemStyle.shadowRadius)) : textX))
            let emphasisOffsetY = isCenter ? cy : ((isLabelInside ? (cy + dy * (r + itemStyle.shadowRadius)) : textY))

            merge(textPan, {emphasisOffsetX, emphasisOffsetY, textX, textY}, true)

      //this.group.add(textPan)
            return textPan;*/
      return true
    }

    if (!(data.max && data.min)) {

      let l = values.length
      let startAngle = 0
      let firstItemValue
      let firstItemColor
      for (let i = 0; i < l; i++) {
        total += values[i].value - 0; // 强制转换为nuber
      }
      scale = linear()
      scale.domain(extent(values, function(d) {
        return d.value
      }))
      scale.range([innerRadius * 1.2, outerRadius])
      isNaN(total) && (total = 0); // total非数字置为0
      for (let i = 0, item; i < l; i++) {
        item = values[i]
        let itemValue = total ? Math.abs(item.value / total) : 1 / l; // total为0均分
        let endAngle = startAngle + itemValue * PI2
        let strokeColor = item.color || theme[i % theme.length]
        let diff = hoverRadius - outerRadius
        let hover = hoverRadius
        let outer = outerRadius
        let inner = innerRadius
        if (itemStyle.type == 'rose') {
          outer = scale(item.value)
          hover = outer + diff
        }
        let itemOpt = {itemValue, inner, outer, hover, startAngle, endAngle, strokeColor, itemName: item.name, value: item.value, dataIndex: item.dataIndex, name: item.name}

        renderData.push(itemOpt)


        drawItem.call(this, itemOpt, i)

        startAngle = endAngle
      }
    } else {
      let itemValue = Math.abs(data.values[0].value / (data.max - data.min))
      if (itemValue >= 1 || !itemValue) {
        itemValue = 1
      }
      let strokeColor = data.values[0].color
      let endAngle = itemValue * PI2
      drawItem.call(this, {endAngle, itemValue, strokeColor, startAngle: 0}, i)
    }

    hideLabel()
    function hideLabel() {
      if (isArray(hideList)) {
        hideList.sort()
        if (!isLabelCenter) {
          if (isLabelBoth) {
            for (let k = hideList.length - 1; k >= 0; k--) {
              let hi = hideList[k]
              textList.splice(hi * 2 + 1, 1)
              textList.splice(hi * 2, 1)
              lineList.splice(hi, 1)
            }
          } else {
            for (let k = hideList.length - 1; k >= 0; k--) {
              let hi = hideList[k]
              textList.splice(hi, 1)
              lineList.splice(hi, 1)
            }
          }
        }
      }
    }

    if (labelPosition === 'outer' && model.get('avoidLabelOverlap')) {
      let _textList = map(textList, function (item) {
        return {
          x: item.style.x,
          y: item.style.y,
          position: 'outer',
          height: (new Text(item)).getBoundingRect().height,
          len: lineLen1,
          len2: lineLen2
        }
      })

      avoidOverlap(_textList, cx, cy, outerRadius, canvasW, canvasH)

      each(textList ,function(item, index) {
        item.style.x = _textList[index].x
        item.style.y = _textList[index].y

      })
    }

    this._shape = this.setShapeGroup('shape', Arc, arcList, {
      animation: needAni,
      // animateFrom: arcAniList,
      animateList: {
        shape: {
          endAngle: 0,
          startAngle: 0
        }
      }
    }, function(arc) {
      arc.off('mouseover')
      arc.off('mouseout')

      arc.on('click', function(e) {
        let { target: { node } } = e;
        global.dispatchAction(model, seriesActions.itemClick, e);
      })

      arc.on('dblclick', function(e) {
        let { target: { node } } = e;
        global.dispatchAction(model, seriesActions.dblClick, e);
      })

      if (model.get('hoverAnimation')) {
        arc.on('mouseover', function(e) {
          let target = e.target
          onEmphasis.call(target)
          global.dispatchAction(model, seriesActions.mouseover, e);
        })
        arc.on('mouseout', function(e) {
          let target = e.target
          onNormal.call(that)
          global.dispatchAction(model, seriesActions.mouseout, e);
        })
        arc.on('mousemove', function(e) {
          global.dispatchAction(model, seriesActions.mousemove, e);
        })

      }
    })

    let lineShape = this.setShapeGroup('line', D3Line, lineList, {
      animation: needAni,
      // animateFrom: lineAniList
      animateList: {
        shape: {
          data: [[0, 0], [0, 0], [0, 0]]
        }
      }
    })

    let textShape = this.setShapeGroup('text', Text, textList, {
      animation: needAni,
      // animateFrom: textAniList
      animateList: {
        style: {
          x: 0,
          y: 0
        }
      }
    })

    let symbolShape = this.setShapeGroup('labelSymbol', Symbol, symbolList, {
      animation: needAni,
      animateList: {
        position: [0, 0]
      }
    })

    function clipPath() {

      let arc = this.setShape('shape_clip', Arc,
        // shape的属性等设置
        {
          shape: {
            outerRadius: Math.max(canvasW, canvasH) / 2,
            innerRadius: 0,
            startAngle: 0,
            endAngle: 0
          },
          position: [cx, cy]
        }
      )
      arc.animateTo({
        shape: {
          endAngle: PI2
        }
      }, 300, 'linear', function() {
        group.removeClipPath()
      })
      return arc
    }
    if (isFirst && needAni) {
      group.setClipPath(clipPath.call(this))
    }


    if (data.title) {
      let titleConfigs = model.get('title')
      let titleOffsetX = typeof titleConfigs.offsetCenter[0] === 'string' ? parseFloat(titleConfigs.offsetCenter[0].split('%')[0])/100*canvasW : titleConfigs.offsetCenter[0]
      let titleOffsetY = typeof titleConfigs.offsetCenter[1] === 'string' ? parseFloat(titleConfigs.offsetCenter[1].split('%')[0])/100*canvasH : titleConfigs.offsetCenter[1]

      this.setShapeGroup('title_text', Text, [{
        style: {
          text: data.title,
          x: cx - titleOffsetX,
          y: cy - titleOffsetY,
          font: titleConfigs.textStyle.fontWeight+' '+titleConfigs.textStyle.fontSize + 'px ' + titleConfigs.textStyle.fontFamily,
          textBaseline: 'middle',
          textAlign: 'center',
          textFill: titleConfigs.textStyle.color,
          ...titleConfigs.textStyle
        }
      }])
    }
  }

}

function scaleColor (colorList, domainList, curAngle, startAngle, endAngle) {
  let angleToPer = scaleLinear().domain([startAngle, endAngle]).range([0, 1]);
  let perToColor = scaleLinear().domain(domainList).range(colorList).interpolate(interpolateRgb);
  return perToColor(angleToPer(curAngle))
}
