/**
 * 矩形树图 by wjw
 */
import { colorTool } from '../../util/graphic';
import SeriesModel from '../../model/Series';
import { each, isString, isArray } from '../../util';
import {
    treemap,
    hierarchy,
    treemapBinary,
    treemapDice,
    treemapSlice,
    treemapSliceDice,
    treemapSquarify,
    treemapResquarify
} from 'd3-hierarchy';
import { scaleOrdinal } from 'd3-scale';
import { color20 } from '../../model/globalDefault'

export const TYPE = 'treemap';

const tiling = {
    treemapBinary,
    treemapDice,
    treemapSlice,
    treemapSliceDice,
    treemapSquarify,
    treemapResquarify
};

// https://github.com/d3/d3-hierarchy#treemap

export default class TreemapModel extends SeriesModel {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量
    
    static emphasisList = ['itemStyle', 'label', 'upperLabel'];

    static defaultOption = {
        //data: undefined, // {Array} 系列中的数据内容数组。数组项通常为具体的数据项。数据项格式 https://github.com/d3/d3-hierarchy#hierarchy
        $dataIndex: undefined, // {number} 依赖的$data的index，格式同上，优先级低于data，取得的数据需符合data的格式
        dataKey: undefined, // {string|number} 搭配$dataIndex使用，方便从依赖的$data中取数据，等同于 data.map((d) => d[dataKey])，最终取得的数据需符合data的格式
        $gridIndex: 0, // {Array} 所依赖grid的index
        name: undefined, // {string} 系列名称，用于tooltip的显示，legend 的图例筛选

        targetPath: undefined, // {string} 目标层级
        value: d => d.value, // {Function} 值获取函数
        leafDepth: 1, // {number} 设置了 leafDepth 后，下钻（drill down）功能开启。drill down 功能即点击后才展示子层级。leafDepth 表示『展示几层』，层次更深的节点则被隐藏起来。点击则可下钻看到层次更深的节点。
        tile: 'treemapSquarify', // {string} 平铺方式 https://github.com/d3/d3-hierarchy#treemap-tiling
        round: false, // {boolean} 是否舍入

        paddingTop: 0, // {number} 顶间距
        paddingBottom: 0, // {number} 底间距
        paddingLeft: 0, // {number} 左间距
        paddingRight: 0, // {number} 右间距
        paddingInner: 1, // {number} 内间距

        nodeClick: 'zoomToNode', // {string} 点击节点后的行为。可取值为 false：节点点击无反应 | 'zoomToNode'：点击节点后缩放到节点 | 'link'：如果节点数据中有 link 点击节点后会进行超链接跳转。
        drillDownIcon: '▶', // {string} 当节点可以下钻时的提示符。只能是字符。

        color: [
            '#525552',
            '#c23531',
            '#2f4554',
            '#61a0a8',
            '#d48265',
            '#91c7ae',
            '#749f83',
            '#ca8622',
            '#bda29a',
            '#6e7074',
            '#546570',
            '#c4ccd3'
        ], // 颜色组

        // 样式配置
        itemStyle: {
            normal: {}, // {Object} 普通样式
            emphasis: {} // {Object} 高亮样式
        },

        // label 描述了每个矩形中，文本标签的样式。
        label: {
            normal: {
                show: true, // {boolean} 是否显示
                style: {}, // {Object} 样式
                formatter: undefined // {Function} 格式化函数
            },
            emphasis: {
                show: false, // {boolean} 是否显示
                style: {} // {Object} 样式
            }
        },

        // upperLabel 用于显示矩形的父节点的标签
        upperLabel: {
            normal: {
                show: false, // {boolean} 是否显示
                style: {}, // {Object} 样式
                formatter: undefined // {Function} 格式化函数
            },
            emphasis: {
                show: false, // {boolean} 是否显示
                style: {} // {Object} 样式
            }
        },

        // 面包屑，能够显示当前节点的路径。
        breadcrumb: {
            show: true, // {boolean} 是否显示

            align: 'center', // {string} 为center时居中
            top: undefined, // {number} 位置上
            left: undefined, // {number} 位置右
            height: 22, // {number} 高度
            minWidth: 30, // {number} 最小宽度
            maxWidth: 60, // {number} 最大宽度

            style: { // 每个节点样式
                normal: { // {Object} 普通样式
                    stroke: 'rgba(255,255,255,0.7)',
                    fill: 'rgba(0,0,0,0.7)',
                    lineWidth: 1,
                    shadowBlur: 3,
                    shadowColor: ' rgba(150,150,150,1)'
                },
                emphasis: {} // {Object} 高亮样式
            },
            textStyle: { // 文本样式
                normal: { // {Object} 普通样式
                    fill: '#fff'
                },
                emphasis: {} // {Object} 高亮样式
            }
        },

        zlevel: 0, // canvas层级
        z: 1 // 同一层canvas内层级
    };

    update() {
        let {
            globalModel,
            _option: {
                $gridIndex: gridIndex,
                targetPath,
                value,
                paddingInner,
                paddingTop,
                paddingBottom,
                paddingLeft,
                paddingRight,
                tile,
                round,
                color
            }
        } = this;
        let data = this.getData();
        let gridModel = globalModel.getComponentByIndex('grid', gridIndex);
        let { left, top, right, bottom, width, height } = gridModel.position;
        let _treemap = treemap()
            .size([width, height])
            .round(round)
            .tile(isString(tile) ? tiling[tile] : tile)
            .paddingInner(paddingInner)
            .paddingTop(paddingTop)
            .paddingBottom(paddingBottom)
            .paddingLeft(paddingLeft)
            .paddingRight(paddingRight);

        setDataPath(data);
        targetPath = targetPath || data._path;

        if (isArray(color)) {
            color = (function() {
                let colorScale = scaleOrdinal(color);

                return function(d, i, p) {
                    let path = d._path;

                    if (i < 2) {
                        return colorScale(path);
                    } else {
                        return colorTool.lift(p.color, -(i - 1) * 0.1);
                    }
                };
            })();
        }

        travel(data, function(d, i, p) {
            d.color = d.color || color(d, i, p);
        });

        let targetData = findTargetData(data, targetPath);
        let targetRoot = hierarchy(targetData)
            .sum(value)
            .sort(function(a, b) {
                return b.height - a.height || b.value - a.value;
            });

        _treemap(targetRoot);

        this.targetPath = targetPath;
        this.targetRoot = targetRoot;
        this.position = gridModel.position;
    }
}

function setDataPath(data, parent) {
    travel(data, function(d, i, p) {
        d._path = p ? p._path + '/' + d.name : d.name;
        d._parent = p;
    });
}

function travel(data, func, i = 0, parent) {
    func(data, i, parent);
    i++;

    each(data.children, function(childData) {
        travel(childData, func, i, data);
    });
}

function findTargetData(data, targetPath) {
    if (!targetPath || data._path === targetPath) return data;

    if (data.children) {
        for (let i = 0; i < data.children.length; i++) {
            if (targetPath.indexOf(data.name) > -1) {
                let targetData = findTargetData(data.children[i], targetPath);

                if (targetData) return targetData;
            }
        }
    }
}
