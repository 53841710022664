export const provinceCenter = {           
    '台湾': [
        121,
        24
    ],
    '河北': [
        115.5,
        38.5
    ],
    '山西': [
        112.3,
        37.5
    ],
    '内蒙古': [
        110,
        41
    ],
    '辽宁': [
        123.2,
        41.3
    ],
    '吉林': [
        126,
        43.7
    ],
    '黑龙江': [
        127.8,
        46.8
    ],
    '江苏': [
        120,
        32.4
    ],
    '浙江': [
        120,
        29.2
    ],
    '安徽': [
        117.2,
        31.5
    ],
    '福建': [
        118.2,
        26
    ],
    '江西': [
        115.7,
        28
    ],
    '山东': [
        117.5,
        36.3
    ],
    '河南': [
        113.6,
        34
    ],
    '湖北': [
        113,
        31
    ],
    '湖南': [
        112,
        28
    ],
    '广东': [
        113.5,
        23
    ],
    '广西': [
        108.5,
        23.5
    ],
    '海南': [
        110,
        19.5
    ],
    '四川': [
        103.5,
        30.6
    ],
    '贵州': [
        106.7,
        26.6
    ],
    '云南': [
        102,
        24.7
    ],
    '西藏': [
        91,
        30.5
    ],
    '陕西': [
        108.9,
        34.26
    ],
    '甘肃': [
        104,
        35.5
    ],
    '青海': [
        97,
        36
    ],
    '宁夏': [
        106.1,
        37.5
    ],
    '新疆': [
        87,
        41
    ],
    '北京': [
        116.4,
        40.24
    ],
    '天津': [
        116.87,
        39.5
    ],
    '上海': [
        121.4,
        31.1
    ],
    '重庆': [
        107.5,
        29.7
    ],
    '香港': [
        113.2,
        22.75
    ],
    '澳门': [
        114.52,
        22.86
    ]
}