import ComponentModel from '../../model/Component';
import scales from '../axis/scale';
import { parsePercent } from '../../util';
import { clone } from 'zrender/src/core/util'

export const TYPE = 'radarAxis';

/**
 * 极坐标 http://echarts.baidu.com/option.html#radar
 */
export default class RadarAxisModel extends ComponentModel {

    static type = TYPE;

    type = TYPE;


    static defaultOption = {
        startAngle: 90, // {number} 起始角度
        center: ['50%', '50%'], // {array<string>|array<number>} 极坐标中心位置
        radius: 170, // {number} 半径
        splitNumber: 5, // { number } 分隔段
        name: {
            // 文字位置相对于最大半径
            positionOffset: 1.1,
            formatter: undefined,
            textStyle:{
                textBaseline: 'middle',
                textFill: '#ccc'
            }
        },
        // axisLabel: {
        //     // 文字位置相对于最大半径
        //     positionOffset: 1.1,
        //     formatter: undefined,
        //     textStyle:{
        //         textBaseline: 'middle',
        //         textFill: '#ccc',
        //     }
        // },
        axisLine: {
            lineStyle: {
                color: '#ccc'
            }
        },
        // 分隔线样式
        splitLine: {
            lineStyle: {
                // 使用深浅的间隔色
                color: ['#f5f5f5', '#e5e5e5'],
                // 分隔线 宽度
                width: 1
            }
        },
        // 分隔线背景
        splitArea: {
            areaStyle: {
                // 使用深浅的间隔色
                color: ['#f5f5f5', '#e5e5e5']
            }
        },

        indicatorName: {
            textStyle: {
                color: '#fff',
                backgroundColor: '#999',
                borderRadius: 3,
                padding: [3, 5]
           }
        },

        // radar.indicator[i].max number
        // 指示器的最大值，可选，默认为 100
        // radar.indicator[i].min number
        // 指示器的最小值，可选，默认为 0。
        indicator: [
           // { name: '销售（sales）', max: 6500},
           // { name: '管理（Administration）', max: 16000},
           // { name: '信息技术（Information Techology）', max: 30000},
           // { name: '客服（Customer Support）', max: 38000},
           // { name: '研发（Development）', max: 52000},
           // { name: '市场（Marketing）', max: 25000}
        ],
        shape: 'polygon', // {string} 雷达图绘制类型，'polygon' | 'circle'
        zlevel: 0,
        z: 1
    };

    // 极坐标
    getAxisType(){
        return 'polar'
    }

    /**
    *  判断一个点是否在极坐标范围内
    *  @param point  测试点坐标
    *  @param circle 圆心坐标
    *  @param r 圆半径
    *  返回true为真，false为假
    *  */
    isPointIn(offsetX, offsetY){
        let circle = this.center;
        if (!circle) {return false}
        let r = this.radius;
        if (r === 0) return false
        var dx = circle[0] - offsetX
        var dy = circle[1] - offsetY
        return dx * dx + dy * dy <= r * r
    }

    angleScaleInvert(offsetX, offsetY){
        let circle = this.center;
        let r = this.radius;
        let x0 = circle[0];
        let y0 = circle[1];
        return Math.atan2(offsetX-x0, offsetY-y0)+Math.PI/2;
    }

    /**
     * 通过index获取data
     */
    getDataByIndex(dataIndex, filterHide = true) {
        let { globalModel, index } = this;

        let seriesModels = globalModel.getSeriesByAxis(0, filterHide);

        var arr = []
        for (var i = 0; i < seriesModels.length; i++) {
            let seriesModel = seriesModels[i];
            let s = clone(seriesModel.getData());
            arr.push({
                originValue: s,
                name: seriesModel.get('name'),
                color: seriesModel.getSeriesColor(),
                seriesIndex: seriesModel.index,
                type: seriesModel.type
                // dataIndex: index
            })
        }
        return arr;
    }

    update() {
        let {
            _option: {
                indicator, radius, center
            },
            globalModel, global } = this;

        let scale = scales['linear'];

        let indicatorNum = indicator.length;
        var angleScale = scale()
               .domain([0, indicatorNum])
               .range([-Math.PI/2, 2 * Math.PI-Math.PI/2]);
        let canvasW = global.getWidth();
        let canvasH = global.getHeight();

        radius = parsePercent(radius, Math.min(canvasW, canvasH));

        var radiusScaleArr = [];
        var max = 100, min = 0;
        indicator.map(function(d, j){
            let radiusScale = scale()
                .domain([d.min ? d.min : min, d.max ? d.max: max])
                .range([0, radius]);

            radiusScaleArr.push(radiusScale);
        });

        let cx = typeof center[0] === 'string' ? parseInt(parseFloat(center[0].split('%')[0])/100*canvasW) : parseInt(center[0]);
        let cy = typeof center[1] === 'string' ? parseInt(parseFloat(center[1].split('%')[0])/100*canvasH) : parseInt(center[1]);
        // 角度映射indicator序号
        this.angleScale = angleScale;
        this.radiusScaleArr = radiusScaleArr;
        this.radius = radius;
        this.center = [cx, cy];
        // this.scale = scale;
    }
}
