/**
 * 热力图 by wjw
 */
import SeriesModel from '../../model/Series';
import { each, filter } from '../../util';

export const TYPE = 'heatmap';

export default class HeatmapModel extends SeriesModel {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    static defaultOption = {
        zlevel: 0, // canvas层级
        z: 1, // 同一层canvas内层级

        pointSize: 20, // {number} 每个点的大小，在geo上有效。
        blurSize: 30, // {number} 每个点模糊的大小，在geo上有效。
        minOpacity: 0, // {number} 最小的透明度，在geo上有效。
        maxOpacity: 1, // {number} 最大的透明度，在geo上有效。

        // 样式
        itemStyle: {
            normal: { // {Object} 普通样式
                fill: 'rgba(102, 132, 221, 0.5)'
            }
        }
    };

    updateByAxis() {
        let data = this.data;
        let xAxisModel = this.getAxisModel('x');
        let yAxisModel = this.getAxisModel('y');
        let xScale = xAxisModel.scale;
        let yScale = yAxisModel.scale;
        let xDomain = xAxisModel.domain;
        let yDomain = yAxisModel.domain;
        let xStartIndex = xAxisModel.startIndex;
        let yStartIndex = yAxisModel.startIndex;
        let xStep = xScale.step();
        let yStep = yScale.step();
        let xPaddingOuter = xScale.paddingOuter() * xStep;
        let yPaddingOuter = xScale.paddingOuter() * yStep;
        let points = [];

        for (let i = 0; i < data.length; i++) {
            let d = data[i];

            points.push([
                xScale(xDomain[d[0] - xStartIndex]) - xPaddingOuter,
                yScale(yDomain[d[1] - yStartIndex]) - yPaddingOuter,
                d[2]
            ]);
        }

        this.points = points;
        this.width = xStep;
        this.height = yStep;
    }

    updateByCalendar() {
        let { globalModel } = this;
        let data = this.data;
        let calendarModel = globalModel.getComponentByIndex('calendar', this.get('$calendarIndex'));
        let cellSize = calendarModel.get('cellSize');
        let points = [];

        each(data, (d, i) => {
            let date = d[0];
            let point = [];
            let _point = calendarModel.calcPoint(date);

            if (_point) {
                point = [_point.x - cellSize / 2, _point.y - cellSize / 2, d[1]];
                points.push(point);
            }
        });

        this.points = points;
        this.width = cellSize;
        this.height = cellSize;
    }

    updateByGeo() {
        let { globalModel } = this;
        let data = this.data;
        let geoModel = globalModel.getComponentByIndex('geo', this.get('$geoIndex'));
        let points = [];

        each(data, (d, i) => {
            let point = [];
            let _point = geoModel.projection(d);

            if (_point) {
                points.push([_point[0], _point[1], d[2]]);
            }
        });

        this.points = points;
    }

    update() {
        let { global } = this;
        let axisIndex = this.get('$axisIndex');
        let calendarIndex = this.get('$calendarIndex');
        let geoIndex = this.get('$geoIndex');
        let data = this.getData();

        data = filter(data, function (d) {
            return d;
        });

        this.data = data;

        if (axisIndex !== undefined) {
            this.updateByAxis();
        } else if (calendarIndex !== undefined) {
            this.updateByCalendar();
        } else if (geoIndex !== undefined) {
            this.updateByGeo();
        }
    }
}