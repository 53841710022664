import ComponentView from '../../view/Component';
import { Rect, Text } from '../../util/graphic';
import { each, isArray, isFunction } from '../../util';
import { axisActions, dataZoomActions } from '../../action/event';
import { subPixelOptimizeLine, Line } from '../../util/graphic';
import { bisector } from 'd3-array';

let bisectX = function (arr, value) {
    let i;

    for (i = 0; i < arr.length; i++) {
        if (arr[i] === null) {
            continue;
        } else {
            if (value < arr[i][0]) {
                break;
            }
        }
    }

    return i;
};
let bisectY = bisector(function (d) {
    return d && -d[1];
}).right;

const TYPE = 'axis';

let isFirstMove = false
let timeOutEvent

export default class AxisView extends ComponentView {
    static type = TYPE;

    type = TYPE;

    constructor(model, globalModel, global) {
        super(...arguments);

        this._initEvent(model, globalModel, global);
    }

    _initEvent(model, globalModel, global) {
        let gridModels = model.getGridModels();
        let axisType = model.getAxisType();
        let xOrY = model.get('xOrY');
        let position = model.get('position');
        let lastIndex,
            lastSeries = [],
            isMouseout = true;
        let dataZoomModel = globalModel.getComponentByIndex(
            'dataZoom',
            model.get('$dataZoomIndex')
        );

        function onOut() {
            if (isMouseout) return;
            clearTimeout(timeOutEvent)
            isFirstMove = false

            if (axisType === 'continuous') {
                global.dispatchAction(model, axisActions.out, {});
            } else {
                let lastData = model.getLastData();

                global.dispatchAction(model, axisActions.out, {
                    lastIndex,
                    lastDataIndex: model.endIndex,
                    lastData
                });
                lastIndex = undefined;
            }

            isMouseout = true;
        }
      const gridModel = gridModels[0]

        global.on('mousemove', function (e) {
            if (global.isEnableEdit || global.isEnableSketch) return;

            let { offsetX, offsetY } = e;
          if ('ontouchstart' in window && gridModel.get('reverse')) {
            let temp = offsetX
            offsetX = offsetY
            offsetY = temp
          }
            let inGrid = false;
            let ifTriggerMove = model.get('ifTriggerMove');

            each(gridModels, function (gridModel) {
                if (gridModel.isPointInGrid(offsetX, offsetY)) {
                    inGrid = true;
                }
            });

            if (inGrid) {
                if (axisType === 'continuous') {
                    let axisData = model.scale.invert(
                        position === 'bottom' || position === 'top'
                            ? offsetX
                            : offsetY
                    );

                    global.dispatchAction(model, axisActions.move, {
                        e,
                        axisData
                    });


                    global.dispatchAction(model, '', {
                        e,
                        axisData
                    });
                } else {
                    let index = model.getIndexByOffset(offsetX, offsetY);

                    if (typeof index === 'number') {

                        let event = model.getMoveDataByIndex(index, lastIndex);
                        event.e = e;
                        event.lastIndex = lastIndex;

                        if (!isNaN(event.pos) && (!ifTriggerMove || ifTriggerMove(event))) {
                            global.dispatchAction(model, axisActions.move, event);
                        }

                        if (isFirstMove == false) {
                            timeOutEvent = setTimeout(() => {
                                global.dispatchAction(model, 'AXIS_LONG_CLICK', event);
                            }, 300);
                            isFirstMove = true
                        }
                    }
                    // lastIndex = index;
                }

                isMouseout = false;
            } else if (!isMouseout) {
                onOut();
            }
        });

        global.on('globalout', onOut);
        global.on('mouseup', onOut);
        // global.on('mouseout', onOut);

        if (xOrY === 'x') {
            let ifTriggerHighlight = model.get('ifTriggerHighlight');

            if (ifTriggerHighlight) {
                global.registerAction(
                    model,
                    axisActions.move,
                    ({ e, index, axisData }) => {
                        let seriesModels = globalModel.getSeriesByAxis(
                            model.index
                        );
                        let position = model.get('position');
                        let isVertical =
                            position === 'left' || position === 'right';

                        if (axisType === 'continuous') {
                            let { offsetX, offsetY } = e;

                            each(seriesModels, function (seriesModel) {
                                if (seriesModel.get('type') === 'line') {
                                    let view = global.getViewOfComponentModel(
                                        seriesModel
                                    );
                                    let points = seriesModel.points;
                                    let index = isVertical
                                        ? bisectY(points, -offsetY)
                                        : bisectX(points, offsetX);

                                    index--;

                                    if (points[index]) {
                                        view.highlight(
                                            seriesModel,
                                            globalModel,
                                            global,
                                            {
                                                index: index,
                                                startIndex: 0
                                            }
                                        );
                                    }
                                }
                            });
                        } else {
                            if (lastIndex !== index) {
                                each(seriesModels, function (seriesModel) {
                                    let view = global.getViewOfComponentModel(
                                        seriesModel
                                    );

                                    if (typeof lastIndex !== 'undefined') {
                                        view.downplay(
                                            seriesModel,
                                            globalModel,
                                            global,
                                            {
                                                index:
                                                    lastIndex +
                                                    model.startIndex,
                                                startIndex: model.startIndex
                                            }
                                        );
                                    }

                                    if (typeof index !== 'undefined') {
                                        view.highlight(
                                            seriesModel,
                                            globalModel,
                                            global,
                                            {
                                                index: model.startIndex + index,
                                                startIndex: model.startIndex
                                            }
                                        );
                                    }
                                });
                            }

                            lastIndex = index;
                        }

                        lastSeries = seriesModels;
                    }
                );

                global.registerAction(model, axisActions.out, function ({
                    lastIndex
                }) {
                    each(lastSeries, function (seriesModel) {
                        let view = global.getViewOfComponentModel(seriesModel);

                        view.downplay(seriesModel, globalModel, global, {
                            index: model.startIndex + lastIndex,
                            startIndex: model.startIndex
                        });
                    });
                    lastSeries = [];
                });
            }

            if (dataZoomModel) {
                global.registerAction(
                    dataZoomModel,
                    dataZoomActions.change,
                    function () {
                        global.dispatchAction(model, axisActions.out, {
                            lastIndex
                        });
                        lastIndex = undefined;
                    }
                );
            }
        }
    }

    render(model, globalModel, global) {
        let splitArea = model.get('splitArea');
        let splitLine = model.get('splitLine');
        let name = model.get('axisName');
        let axis = model.axis;
        let scale = model.scale;
        let position = model.get('position');
        let needAnimation = model.get('animation');
        let animationDuration = model.get('animationDuration');
        let animationEasing = model.get('animationEasing');
        let cursor = model.get('cursor');

        model.axis(this, model, globalModel, global);

        if (splitArea && splitArea.show) {
            let domain = scale.domain();
            let range = scale.range();
            let values, offset;
            let areaColor = splitArea.style.color;
            let onRect = splitArea.onRect;
            let gridIndex = model.get('$gridIndex');
            let gridModels = globalModel.getComponent(
                'grid',
                function (gModel) {
                    let gridIndex = model.get('$gridIndex');

                    return isArray(gridIndex) ? gridIndex.indexOf(gModel.index) > -1 : gridIndex === gModel.index
                }
            );
            let isBand = model.get('type') === 'band';
            let paddingInner, paddingOuter, bandwidth;
            let attrs = [];

            if (isBand) {
                let scaleStep = scale.step();

                values = domain;
                paddingInner = scaleStep * scale.paddingInner();
                paddingOuter = scaleStep * scale.paddingOuter();
                bandwidth = scale.bandwidth();
            } else {
                values = axis.values();
            }

            let valuesLen = values.length;

            for (let j = 0; j < gridModels.length; j++) {
                let gridModel = gridModels[j];
                let { left, top, width, height } = gridModel.position;

                for (let i = 0, len = valuesLen; i < len; i++) {
                    let x, y, xy1;

                    if (isBand) {
                        xy1 = scale(values[i]) + bandwidth;

                        if (!onRect) {
                            if (i === valuesLen - 1) {
                                xy1 += Math.min(paddingInner / 2, paddingOuter);
                            } else {
                                xy1 += paddingInner / 2;
                            }
                        }

                        if (position === 'left' || position === 'right') {
                            x = left;
                            y = scale(values[i]);

                            if (!onRect) {
                                if (i === 0) {
                                    y -= Math.min(paddingInner / 2, paddingOuter);
                                } else {
                                    y -= paddingInner / 2;
                                }
                            }

                            height = xy1 - y;
                        } else {
                            x = scale(values[i]);
                            y = top;

                            if (!onRect) {
                                if (i === 0) {
                                    x -= Math.min(paddingInner / 2, paddingOuter);
                                } else {
                                    x -= paddingInner / 2;
                                }
                            }

                            width = xy1 - x;
                        }
                    } else {
                        xy1 = i === valuesLen - 1 ? range[1] : scale(values[i + 1]);

                        if (position === 'left' || position === 'right') {
                            x = left;
                            y = xy1;
                            height = scale(values[i]) - y;
                        } else {
                            x = scale(values[i]);
                            y = top;
                            width = xy1 - x;
                        }
                    }

                    attrs.push({
                        shape: {
                            x,
                            y,
                            width,
                            height
                        },
                        style: {
                            fill: isArray(areaColor)
                                ? areaColor[i % areaColor.length]
                                : areaColor
                        },
                        silent: true,
                        key: values[i] + '_' + j,
                        z2: -1
                    });
                }
            }

            this.setShapeGroup('areaGroup', Rect, attrs, {
                animation: needAnimation,
                duration: animationDuration,
                easing: animationEasing,
                animateList: {
                    shape: {
                        x: 0,
                        y: 0,
                        width: 0,
                        height: 0
                    }
                }
            });
        }

        if (splitLine && splitLine.show) {
            let values = axis.values();
            let splitLineStyle = splitLine.style;
            let gridModels = globalModel.getComponent(
                'grid',
                function (gModel) {
                    let gridIndex = model.get('$gridIndex');

                    return isArray(gridIndex) ? gridIndex.indexOf(gModel.index) > -1 : gridIndex === gModel.index
                }
            );
            let attrs = [];
            let bandwidthHalf = scale.bandwidth ? scale.bandwidth() / 2 : 0;

            for (let j = 0; j < gridModels.length; j++) {
                let gridModel = gridModels[j];
                let { left, top, right, bottom } = gridModel.position;

                for (let i = 0, len = values.length; i < len; i++) {
                    let shape = {};
                    let xy = scale(values[i]) + bandwidthHalf;
                    let lineStyle = {};
                    let lineColor = splitLineStyle.color;

                    if (position === 'left' || position === 'right') {
                        shape = {
                            x1: left,
                            y1: xy,
                            x2: right,
                            y2: xy
                        };
                    } else {
                        shape = {
                            x1: xy,
                            y1: top,
                            x2: xy,
                            y2: bottom
                        };
                    }

                    if (isFunction(splitLineStyle)) {
                        lineStyle = splitLineStyle(values[i], i);
                    } else {
                        let lineColor = splitLineStyle.color;

                        lineStyle = {
                            ...splitLineStyle,
                            stroke: isArray(lineColor)
                                ? lineColor[i % lineColor.length]
                                : lineColor
                        }
                    }

                    attrs.push(
                        subPixelOptimizeLine({
                            shape,
                            style: lineStyle,
                            silent: true,
                            key: values[i] + '_' + j,
                            z2: -1
                        })
                    );
                }
            }

            this.setShapeGroup('splitLine', Line, attrs, {
                animation: needAnimation,
                duration: animationDuration,
                easing: animationEasing,
                animateList: {
                    shape: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 0
                    }
                }
            });
        }

        if (name.show) {
            let nameOffset = name.offset;
            let nameLocation = name.location;
            let nameVertical = name.vertical;
            let namePosition, nameAlign, nameVerticalAlign;
            let nameText = name.text;
            let scaleRange = scale.range();
            let location;
            let linePos = axis.linePos();

            switch (nameLocation) {
                case 'start':
                    location = scaleRange[0];
                    break;
                case 'middle':
                case 'center':
                    location = (scaleRange[0] + scaleRange[1]) / 2;
                    break;
                case 'end':
                    location = scaleRange[1];
                    break;
            }

            if (position === 'left' || position === 'right') {
                namePosition = [
                    linePos + nameOffset[0],
                    location + nameOffset[1]
                ];
                nameAlign = 'center';
                nameVerticalAlign =
                    nameLocation === 'start'
                        ? 'top'
                        : nameLocation === 'end'
                            ? 'bottom'
                            : 'center';
            } else {
                namePosition = [
                    location + nameOffset[0],
                    linePos + nameOffset[1]
                ];
                nameAlign =
                    nameLocation === 'start'
                        ? 'right'
                        : nameLocation === 'end'
                            ? 'left'
                            : 'center';
                nameVerticalAlign = 'center';
            }

            if (nameVertical) {
                nameText = nameText.split('').join('\n');
            }

            this.setShape(
                'name',
                Text,
                globalModel.getFormattedText({
                    style: {
                        text: nameText,
                        textAlign: nameAlign,
                        textVerticalAlign: nameVerticalAlign,
                        ...name.style
                    },
                    scale: name.scale,
                    rotation: name.rotate / 180 * Math.PI,
                    position: namePosition,
                    silent: true
                })
            );
        }
    }
}
