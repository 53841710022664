import { Text, Rect, Circle, setHoverStyle, subPixelOptimizeRect, Image } from '../../util/graphic';
import { Symbol } from '../../util/symbol';
import * as colorTool from 'zrender/src/tool/color';
import ChartView from '../../view/Chart';
import SimpleHeat from './SimpleHeat';
import { TYPE } from './HeatmapModel'; // 使用Model中定义的TYPE，与model的type值保持一致
import { each } from '../../util';

export default class HeatmapView extends ChartView {

    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    renderOnGeo(model, globalModel, global) {
        let { points } = model;
        let blurSize = model.get('blurSize');
        let pointSize = model.get('pointSize');
        let minOpacity = model.get('minOpacity');
        let maxOpacity = model.get('maxOpacity');
        let itemStyle = model.get('itemStyle').normal;
        let visualMapIndex = model.get('$visualMapIndex');
        let visualMapModel = global.getModel('visualMap', visualMapIndex);
        let globalWidth = global.getWidth();
        let globalHeight = global.getHeight();

        let heat = this._heat || new SimpleHeat();

        let color = visualMapModel.get('inRange').color;
        let gradient = {};

        for (let i = 0; i < color.length; i++) {
            gradient[color[i][0]] = color[i][1];
        }

        heat.data(points);
        heat.max(visualMapModel.get('max'));
        heat.min(visualMapModel.get('min'));
        heat.radius(pointSize, blurSize);
        heat.gradient(gradient);
        heat.draw(minOpacity, maxOpacity, globalWidth, globalWidth);

        this.setShape('heatmap', Image, {
            style: {
                width: globalWidth,
                height: globalWidth,
                x: 0,
                y: 0,
                image: heat._canvas
            },
            silent: true
        });
    }

    renderOnOther(model, globalModel, global) {
        let { points, width, height } = model;
        let itemStyle = model.get('itemStyle').normal;
        let visualMapModel = global.getModel('visualMap', 0);
        let attrs = [];

        for (let i = 0; i < points.length; i++) {
            let point = points[i];
            let color = visualMapModel && visualMapModel.getColorStop(point[2]);
            let style = { ...itemStyle };

            if (color) {
                style.fill = color;
            }

            attrs.push({
                shape: {
                    x: 0,
                    y: 0,
                    width,
                    height
                },
                position: [point[0], point[1]],
                style: style,
                data: {
                    value: point[2]
                }
            })
        }

        this.setShapeGroup('heatmap', Rect, attrs);
    }

    render(model) {
        let geoIndex = model.get('$geoIndex');

        if (geoIndex !== undefined) {
            this.renderOnGeo(...arguments);
        } else {
            this.renderOnOther(...arguments);
        }
    }
}
