import { Path } from '../util/graphic';
import d3line from 'd3-shape/src/line';
import { linkHorizontal, linkVertical } from 'd3-shape/src/link/index';

export default Path.extend({
    type: 'd3-link',

    shape: {

        source: undefined,

        target: undefined,

        x: undefined,

        y: undefined,

        orient: 'horizontal'
    },

    style: {
        stroke: null,
        fill: null
    },

    updateLink() {
        let { orient } = this.shape;
        
        // !this.link && (this.link = orient === 'horizontal' ? linkHorizontal() : linkVertical());
        if(!this.link){
            if (orient === 'straightLine') {
                this.link = d3line();
                this.link.x(function(d){
                    return d[0]
                }).y(function(d){
                    return d[1]
                })
            }else if(orient === 'horizontal'){
                this.link = linkHorizontal();
            }else{
                this.link = linkVertical();
            }
        }
    },

    buildPath: function (context, shape) {
        let { source, target, orient } = this.shape;

        this.updateLink();
        if (orient === 'straightLine') {
            this.link.context(context)([
                source,
                target
            ]);
        }else{
            this.link.context(context)({
                source,
                target
            });
        }
    }

})
