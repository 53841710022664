/**
 * 标域
 */
import ComponentModel from '../../model/Component';
import { parsePercent } from '../../util';

export const TYPE = 'markArea';

export default class MarkAreaModel extends ComponentModel {

    static type = TYPE;

    type = TYPE;
    
    static emphasisList = ['areaStyle'];

    static defaultOption = {

        show: true, // 是否显示

        $axisIndex: undefined, // 依赖的axis
        $seriesIndex: undefined, // 若配置$seriesIndex则使用该series的axis

        // 标记区域样式
        areaStyle: {
            normal: {
                fill: 'rgba(128, 128, 128, 0.5)'
            },
            emphasis: {

            }
        },

        // 标签样式
        label: {
            normal: {
                show: false,
                style: {
                    position: 'top'
                }
            },
            emphasis: {
                show: false,
                style: {
                    
                }
            }
        },
        
        cursor: 'pointer',
        zlevel: 0,
        z: 10
    };
}