import { Rect, Line, Text, Polygon, Polyline } from '../../util/graphic';
import ChartView from '../../view/Chart';
import { each } from '../../util';
import { TYPE } from './OrgchartModel'; // 使用Model中定义的TYPE，与model的type值保持一致
import dagre from '../../util/dagre';
import { Symbol } from '../../util/symbol';
import { max } from 'd3-array';
import { point } from 'd3-scale/src/band';
import { forceActions } from '../../action/event';

const isOffset = (p) => {
  if (p) {
    return p;
  }
  return 0;
};

let offsetWidth, offsetHeight;

export default class DagreView extends ChartView {

  static type = TYPE; // 静态变量

  type = TYPE; // 实例变量

  render(model, globalModel, global) {
    let data = model.getData();
    let nodeOpt = model.get('node');

    this.w = global.getWidth();
    this.h = global.getHeight();

    let isScale = model.get('scale');
    let roam = model.get('roam').enable;

    if (isScale) {
      offsetWidth = this.w - model.g.graph().width;
      offsetHeight = this.h - model.g.graph().height;
    } else {
      offsetWidth = 0;
      offsetHeight = 0;
    }

    let attrs = [];
    let attrs2 = [];
    const isShadow = (bool) => {
      return bool === 'true' ? nodeOpt.style.normal.shadowOffsetX : 0;
    };
    const isSilent = (c, bool) => {
      if (c) {
        return false;
      }
      if (!roam) {
        return true;
      }
      return bool !== 'true';
    };
    const isStroke = (s, n) => {
      if (s) {
        return s;
      }
      return n;
    };
    const isFill = (c, n) => {
      if (c) {
        return c;
      }
      return n;
    };
    const isTextFill = (t, c) => {
      if (t) {
        return t;
      }
      return c;
    };
    const isWidth = (s, n, f, name) => {
      if (s) {
        let isEnglish = escape(name).indexOf('%u');
        let length;
        if (isEnglish === -1) {
          length = name.length / 2 * f + f * 1.5;
        } else {
          length = name.length * f + f;
        }
        return length;
      }
      return n;
    };
    let isTextLength = model.get('node').isTextLength;

    each(model.g.nodes(), (v, i) => {
      let node = model.g.node(v);
      let currentX = node.x + offsetWidth / 2;
      let currentY = node.y + offsetHeight / 2 - isOffset(node.paddingY);
      let text = model.nameMap[v]

      attrs.push({
        shape: {
          symbolType: nodeOpt.shape.symbolType,
          x: currentX,
          y: currentY,
          width: isWidth(isTextLength, nodeOpt.shape.width, nodeOpt.style.normal.fontSize, text),
          height: nodeOpt.shape.height,
          r: nodeOpt.shape.r
        },
        style: {
          ...nodeOpt.style.normal,
          fill: isFill(node.color, nodeOpt.colors[node.label.split('-')[0]]),
          shadowOffsetX: isShadow(node.label.split('-')[1]),
          stroke: isStroke(node.stroke, nodeOpt.style.normal.stroke)
        },
        position: [-nodeOpt.shape.width / 2, -nodeOpt.shape.height / 2],
        z: model.get('z'),
        z2: 998,
        silent: isSilent(node.clickable, node.label.split('-')[1]),
        nodeKey: v,
        group: model.g.node(v).label,
        key: v,
        nodeData: node.nodeData
      });
      attrs2.push({
        style: {
          text: text,
          fontSize: nodeOpt.style.normal.fontSize,
          textFill: isTextFill(node.textFill, nodeOpt.colors[node.label.split('-')[0]]),
          textAlign: 'left'
        },
        position: [currentX - nodeOpt.shape.width / 2 + nodeOpt.style.normal.fontSize / 2, currentY - nodeOpt.style.normal.fontSize / 2],
        z: model.get('z'),
        z2: 999,
        silent: true,
        nodeName: v,
        key: v
      });
    });

    this.setShapeGroup('nodes', Symbol, attrs, {
      animation: true,
      animateList: {
        shape: {
          x: 0,
          y: 0
        }
      }
    }, undefined, (n, i) => {
      let circleSize = n.shape.width / 2;
      let labelShape;
      let successors = model.g.successors(n.nodeKey);
      n.open = successors.length > 0;
      n.on('mousedown', () => {
        let eventId = model.get('eventId');
        global.dispatchAction(model, eventId, n.nodeData);

        const hide = () => {
          const nodeNeighbors = new dagre.graphlib.alg.dijkstra(model.g, n.nodeKey);
          for (let v in nodeNeighbors) {
            if (nodeNeighbors[v].predecessor) {
              each(model.splitNameArr, (string, k) => {
                if (string === v) {
                  model.splitNameArr.splice(k, 1);
                }
              });
              model.g.removeNode(v);
            }
          }
        };
        const show = () => {
          const showNeighbors = model.bg.successors(n.nodeKey);
          each(showNeighbors, (neibor) => {
            model.splitNameArr.push(neibor);
            model.g.setNode(neibor, model.bg.node(neibor));
            model.g.setEdge(n.nodeKey, neibor, { label: 'edges' });
          });
        };

        if (model.get('expandAndCollaspe')) {

          if (!n.open) {
            show()
          } else {
            hide()
          }
          n.open = !n.open;

          dagre.layout(model.g);
          model.dirty();
        }
      });
    });

    this.setShapeGroup('nodeLabels', Text, attrs2, {
      animation: true,
      animateList: {
        position: [0, 0]
      }
    });

    renderLinks.call(this, model, global, offsetWidth, offsetHeight, data.edges);

    let offset = model.get('offset');
    if (isScale) {
      initScale.call(this, model);
    } else {
      this.group.scale = [1, 1];
      const rect = this.group.getBoundingRect();
      const dx = offset[0];
      const dy = offset[1];
      this.group.position = [-rect.x + dx, -rect.y + dy];
      let finalScale = rect.width / this.w * 1.1;
      let originParam = (1 / finalScale - 1) / 0.1;
      this.scale(0, 0, originParam);
    }

    if (!roam) {
      global.on('mousedown', ({ offsetX, offsetY, target }) => {
        let group = this.group;
        if (!group || target) return;
        this._isMove = true;
        this.startX = offsetX;
        this.startY = offsetY;
        this.startPosition = [group.position[0], group.position[1]];
        this.initY;
        if (!this.initY) {
          this.initY = this.group.position[1];
        }
      });
      global.on('mousemove', ({ offsetX, offsetY }) => {
        if (this._isMove) {
          let group = this.group;
          // this.group.position = [-rect.x + dx, -rect.y + dy];
          let rect = this.group.getBoundingRect();
          group.attr({
            position: [this.startPosition[0], this.startPosition[1] + offsetY - this.startY]
          });
          if (group.position[1] > this.initY) {
            group.attr({
              position: [this.startPosition[0], this.initY]
            });
          }
          if (group.position[1] < this.initY + this.h - rect.height * this.group.scale[1]) {
            group.attr({
              position: [this.startPosition[0], this.initY + this.h - rect.height * this.group.scale[1] - offset[1] * this.group.scale[1]]
            });
          }
        }
      });
      global.on('mouseup', () => {
        this._isMove = false;
      });
    }
  }
}

function initScale(model) {
  let isScale = model.get('scale');
  this.group.scale = [1, 1];
  this.group.position = [0, 0];
  const rect = this.group.getBoundingRect();
  let scaleW = rect.width / this.w;
  let scaleH = rect.height / this.h;
  let finalScale = Math.max(scaleW, scaleH) * isScale;
  let originParam = (1 / finalScale - 1) / 0.1;
  let scaleParam = originParam;
  let offsetX = this.w * 0.5;
  let offsetY = this.h * 0.5;
  if (model.option.isTop) {
    offsetY = 0;
    this.group.position[1] = -rect.y + 10;
  }
  this.scale(offsetX, offsetY, scaleParam);
}

function renderLinks(model, global, offsetWidth, offsetHeight) {
  let linkOpt = model.get('link');
  let attrs = [];
  let attrs2 = [];
  let paddingArr = [];

  each(model.g.edges(), (e, i) => {
    let points = model.g.edge(e).points;
    if (model.g.edge(e).paddingY) {
      let endPoints = model.g.edge(e).points;
      paddingArr.push(endPoints);
      if (!this[i]) {
        endPoints[endPoints.length - 1].y -= model.g.edge(e).paddingY;
        endPoints[endPoints.length - 2].y -= model.g.edge(e).paddingY;
      }
      this[i] = true;
    }
    let point1 = [points[points.length - 2].x + offsetWidth / 2, points[points.length - 2].y + offsetHeight / 2];
    let point2 = [points[points.length - 1].x + offsetWidth / 2, points[points.length - 1].y + offsetHeight / 2];
    const arrowSize = linkOpt.arrowSize;
    const lineLength = Math.sqrt(Math.pow((point2[0] - point1[0]), 2) + Math.pow((point2[1] - point1[1]), 2))

    let pointArr = points.map((p) => {
      return [p.x + offsetWidth / 2, p.y + offsetHeight / 2]
    });

    attrs.push({
      shape: {
        points: pointArr
      },
      style: {
        ...linkOpt.style.normal
      },
      z: model.get('z'),
      z2: 997,
      key: e.v + e.w,
      silent: true
    });

    let arrowAngle;
    if ((point2[1] - point1[1]) >= 0) {
      arrowAngle = Math.asin((point2[0] - point1[0]) / lineLength);
    } else if ((point2[1] - point1[1]) < 0 && (point2[0] - point1[0]) < 0) {
      arrowAngle = - Math.PI / 2 + Math.asin((point2[1] - point1[1]) / lineLength);
    } else if ((point2[1] - point1[1]) < 0 && (point2[0] - point1[0]) >= 0) {
      arrowAngle = Math.PI / 2 + Math.asin((point1[1] - point2[1]) / lineLength);
    }

    attrs2.push({
      shape: {
        points: [point2, [point2[0] + arrowSize, point2[1] - arrowSize], [point2[0] - arrowSize, point2[1] - arrowSize]]
      },
      style: {
        fill: linkOpt.style.normal.stroke
      },
      z: model.get('z'),
      z2: 997,
      rotation: arrowAngle,
      origin: point2,
      key: e.w + e.v,
      silent: true
    });
  })
  this.setShapeGroup('edges', Polyline, attrs, {
    animation: true,
    animateList: {
      shape: {
        points: [[0, 0], [0, 0], [0, 0]]
      }
    }
  });
  this.setShapeGroup('arrows', Polygon, attrs2, {
    animation: true,
    animateList: {
      shape: {
        points: [[0, 0], [0, 0], [0, 0]]
      },
      origin: [0, 0]
    }
  });
}

