import { Polygon, Circle, enableDrag, setHoverStyle } from '../../util/graphic';
import { Symbol } from '../../util/symbol';
import { each, merge, isFunction, map, find } from '../../util';
import ChartView from '../../view/Chart';
import { TYPE } from './SketchModel'; // 使用Model中定义的TYPE，与model的type值保持一致
import shapes from './shape/index';
import { transformToAxis, transformToPX, transformToAxis1, transformToPX1 } from './helper';
import { sketchActions } from '../../action/event';

export default class SketchView extends ChartView {

    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    constructor(model, globalModel, global) {
        super(...arguments);

        this.initEvent(model, globalModel, global);
    }

    initEvent(model, globalModel, global) {
        let axisIndex = model.get('$axisIndex');
        let painting = false, startInAxis = false;
        let gridModel, points = [];
        let shapeIndex = 0;
        let startTime;
        let xAxis, yAxis;

        each(model.get('$axisIndex'), (index) => {
            let axis = globalModel.getComponentByIndex('axis', index);

            if (['bottom', 'top'].indexOf(axis.get('position')) > -1) {
                xAxis = axis;
            } else {
                yAxis = axis;
            }
        });

        if (yAxis) {
            gridModel = yAxis.getGridModels()[0];
        }

        global.on('mousedown', ({ offsetX, offsetY, target }) => {
            let paintTool = model.get('paintTool');

            if (!paintTool || !shapes[paintTool] || painting || global.isEnableEdit || (target && target.isSketchShape)) return;

            if (model.get('inGrid') && (!gridModel || !gridModel.isPointInGrid(offsetX, offsetY))) return;

            startInAxis = model.get('inAxis') && gridModel && gridModel.isPointInGrid(offsetX, offsetY);

            if (!startInAxis && shapes[paintTool].onlyInAxis) return;

            points[0] = [offsetX, offsetY];
            startTime = new Date();
            painting = true;
        });

        global.on('mousemove', ({ offsetX, offsetY }) => {
            if (!painting) return;

            let shapeAttr = {
                style: model.get('style').normal,
                shapeType: model.get('paintTool')
            };

            switch (shapes[shapeAttr.shapeType].pointNum) {
                case 1:
                    points[0] = [offsetX, offsetY];
                    break;
                case 2:
                default:
                    points[1] = [offsetX, offsetY];
                    break;
            }

            if (startInAxis) {
                shapeAttr.shape = {
                    points: transformToAxis(points, xAxis, yAxis),
                    axisIndex
                }
            } else {
                shapeAttr.shape = {
                    points
                }
            }

            let { attr, Shape } = shapes[shapeAttr.shapeType](shapeAttr, model, xAxis, yAxis);

            attr.z = model.get('z');

            this.setShape('tempShape', Shape, attr);
        });

        global.on('mouseup', ({ offsetX, offsetY }) => {
            if (!painting) return;

            if (new Date() - startTime > 200) {
                let attr = {
                    style: model.get('style').normal,
                    z: model.get('z'),
                    hoverStyle: model.get('style').emphasis
                };

                if (startInAxis) {
                    attr.shape = {
                        points: transformToAxis(points, xAxis, yAxis),
                        axisIndex
                    };
                } else {
                    attr.shape = {
                        points
                    }
                }

                attr.shapeIndex = +new Date();
                attr.shapeType = model.get('paintTool');

                model.get('shapeList').push(attr);
                model.set('shapeList', model.get('shapeList'));
            }

            this.removeShape('tempShape');
            painting = false;
            points = [];

            model.get('disableWhenEnd') && model.set('paintTool', false);
        });

        global.on('click', function ({ target }) {
            if ((!target || !target.isSketchShape) && model.get('paintTool') && typeof model.get('currentEditIndex') === 'number') {
                model.set('currentEditIndex', undefined);
            }
        });
    }

    render(model, globalModel, global) {
        let shapeList = model.get('shapeList');
        let paintTool = model.get('paintTool');
        let icons = model.get('icon');
        let iconType = model.get('iconType');
        let iconNormalStyle = model.get('iconStyle').normal;
        let iconHoverStyle = model.get('iconStyle').emphasis;
        let { top, left } = model;
        let xAxis, yAxis;

        each(model.get('$axisIndex'), (index) => {
            let axis = globalModel.getComponentByIndex('axis', index);

            if (['bottom', 'top'].indexOf(axis.get('position')) > -1) {
                xAxis = axis;
            } else {
                yAxis = axis;
            }
        });

        if (model.get('show')) {
            each(icons, (iconName, index) => {
                let style;

                style = paintTool === iconName ? iconHoverStyle : iconNormalStyle;

                this.setShape('icon_' + iconName, Symbol, {
                    shape: {
                        symbolType: iconType[iconName],
                        x: index * 35,
                        y: 0,
                        width: 20,
                        height: 20
                    },
                    position: [left, top],
                    style,
                    rectHover: true,
                    iconName,
                    z2: 999
                }, undefined, function (shape) {
                    setHoverStyle(shape, iconHoverStyle, { disableMouse: true });

                    switch (shape.iconName) {
                        case 'back':
                            shape.on('click', function () {
                                model.popShape();
                            });
                            shape.on('mousedown', function ({ target }) {
                                target.trigger('emphasis');
                            });
                            shape.on('mouseup', function ({ target }) {
                                target.trigger('normal');
                            });
                            break;
                        default:
                            shape.on('click', function () {
                                let curTool = model.get('paintTool');

                                if (curTool === this.iconName) {
                                    model.set('paintTool', false);
                                } else {
                                    model.set('paintTool', this.iconName);
                                }
                            });
                            break;
                    }
                });
            });
        }

        this.eachShape((shape, name) => {
            name.match('shapeList_') && this.removeShape(name);
        });

        each(shapeList, (shapeAttr) => {
            let { attr, Shape } = shapes[shapeAttr.shapeType](shapeAttr, model, xAxis, yAxis);

            attr.shapeIndex = shapeAttr.shapeIndex;
            attr.isSketchShape = true;

            this.setShape('shapeList_' + shapeAttr.shapeIndex, Shape, attr, undefined, (shape) => {
                setHoverStyle(shape, shapeAttr.hoverStyle);
                shape.on('click', (e) => {
                    let { target } = e;
                    let paintTool = model.get('paintTool');
                    if (paintTool) {
                        if (paintTool === 'delete') {
                            let confirmWhenDelete = model.get('confirmWhenDelete');

                            if (!confirmWhenDelete || confirmWhenDelete()) {
                                model.deleteShape(target);
                            }
                        } else {
                            model.set('currentEditIndex', shape.shapeIndex);
                        }
                    }
                    global.dispatchAction(model, sketchActions.itemClick, e);
                })
            });
        });

        let currentEditIndex = model.get('currentEditIndex');
        let finded;

        if (paintTool && typeof currentEditIndex === 'number') {
            finded = find(shapeList, function (_shapeAttr) {
                return _shapeAttr.shapeIndex === currentEditIndex;
            });
        }

        if (finded) {
            let editAttrs = [];
            let editPoints = finded.shape.points;

            if (finded.shape.axisIndex) {
                editPoints = transformToPX(editPoints, xAxis, yAxis, true);
            }

            for (var i = 0; i < editPoints.length; i++) {
                editAttrs.push({
                    shape: {
                        type: 'rect',
                        x: editPoints[i][0] - 5,
                        y: editPoints[i][1] - 5,
                        width: 10,
                        height: 10
                    },
                    style: {
                        fill: 'white',
                        stroke: 'rgb(49, 207, 214)'
                    },
                    z2: 999,
                    z: model.get('z'),
                    pointIndex: i,
                    isSketchShape: true,
                    rectHover: true
                })
            }

            this.setShapeGroup('editPoints', Symbol, editAttrs, undefined, function (editGroup) {
                enableDrag(editGroup, global, {
                    onDragging: function ({ target, offsetX, offsetY }) {
                        let _shapeList = model.get('shapeList');
                        let currentEditIndex = model.get('currentEditIndex');
                        let finded = find(_shapeList, function (_shapeAttr) {
                            return _shapeAttr.shapeIndex === currentEditIndex;
                        });

                        if (finded) {
                            finded.shape.points[target.pointIndex] = finded.shape.axisIndex ? transformToAxis1([offsetX, offsetY], xAxis, yAxis) : [offsetX, offsetY];
                            model.dirty();
                        }
                    }
                });
            });
        } else {
            this.removeShape('editPoints');
        }
    }
}