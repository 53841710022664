/**
 * 刷选
 */
import ComponentModel from '../../model/Component';
import { parsePercent, each, parseSize, isString, isObject } from '../../util';
import setModelPosition from '../helpers/setModelPosition';
import scales from '../axis/scale';

export const TYPE = 'zoomBrush';

export default class ZoomBrushModel extends ComponentModel {

    static type = TYPE;

    type = TYPE;
    
    static emphasisList = ['iconStyle'];

    static defaultOption = {
        axisIndex: [0], // {Array} 控制的axisIndex

        enable: false, // {boolean} 是否开启zoomBrush
        show: true, // {boolean} 是否显示icon

        left: '90%', // {string|number} icon显示位置
        top: '5%', // {string|number} icon显示位置

        // brush样式
        style: {
            fill: 'rgba(0, 0, 0, 0.3)'
        },
        title: { // icon对应文字
            zoom: '区域缩放',
            back: '区域缩放还原'
        },
        icon: { // icon对应类型
            zoom: 'path://M0,13.5h26.9 M13.5,26.9V0 M32.1,13.5H58V58H13.5 V32.1',
            back: 'path://M22,1.4L9.9,13.5l12.3,12.3 M10.3,13.5H54.9v44.6 H10.3v-26'
        },
        iconStyle: { // icon样式
            normal: {
                stroke: '#666',
                fill: undefined
            },
            emphasis: {
                stroke: '#6cc'
            }
        },

        history: [],
        zlevel: 0,
        z: 8
    };

    update() {
        let global = this.global;
        this.left = parsePercent(this.get('left'), global.getWidth());
        this.top = parsePercent(this.get('top'), global.getHeight());
    }

    toggleEnable(bool) {
        if (bool === undefined) {
            this.set({
                enable: !this.get('enable')
            });
        } else {
            this.set({
                enable: bool
            });
        }
    }

    pushHistory(status) {
        if (status) {
            let history = this.get('history');

            history.push(status);
            this.set('history', history);
        }
    }

    popHistory() {
        let { globalModel, global } = this;
        let history = this.get('history');
        let status = history.pop();

        if (status) {
            each(status, function (item) {
                let axisModel = globalModel.getComponentByIndex('axis', item[0]);

                axisModel.setZoom(item[1]);
            });

            this.set('history', history);
        }
    }
}