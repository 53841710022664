import { each, trim, merge } from 'zrender/src/core/util'

    // 港股指数：176
    // 港股个股：177、178、179、180、182 181
    // 美股指数：200
    // 全球指数（除港股和沪深）：88
    // 美股个股：185、186、169、170
    // 沪深板块行业: 48
    // 初始化:
    // var stockTypeObj = new hxc3.StockType({
    //    code:'hs_300033'
    // });
    function StockType(options){
        this.configs = merge(options, this.constructor.defaultConfigs);
    }

    StockType.defaultConfigs = {
        code:'33_300033',
        hs:{
            fsXaxisShow:{
                '0930': 0,
                '1030': 60,
                '1130': 120,
                '1400': 180,
                '1500': 241
            }, 
            // 中间午休的, 总数为单数才是合理的, 主要是午休结束后的1300 本身应该还是上一个时刻的交易值,而不应该是新的值
            // fsTotalTimeNum: 242
        },
        hskcb:{
            fsXaxisShow:{
                '0930': 0,
                '1130': 120,
                '1500': 241
            } 
        },
        hk: {
            fsXaxisShow:{
                '0930': 0,
                '1030': 60,
                '1200': 150,
                '1400': 211,
                '1610': 341
            },
            // fsTotalTimeNum: 342
        }
    }

    StockType.prototype.get = function(d){
        return this.configs[d];
    };

    StockType.prototype.render = function(d){
        this.init();
    };

    /**
    * stockCode 包含 market
    * stockCode e.g: '33_300033','hk_HK0001','hk_HSI'
    * return 如下:
    * shortStockCode: "300033",
    * marketCode: "hs",
    * msg: "HS_stock",
    *
    * 保留小数位数
    * keepLen: 2,
    * 
    * 成交量是否要转换为'手'
    * needConvertoShou: true
    *
    * 成交量单位
    * volUnit : '手'
    * 
    * fsXaxisShow: x轴指定显示的坐标内容
    *
     */
    StockType.prototype.init = function(){   
        var that = this;
        var stockCode = trim(that.get('code'));
        var tempArr = stockCode.split('_');
        var shortStockCode = tempArr[1];
        var marketCode = tempArr[0];
        var result = {
            'shortStockCode':shortStockCode,
            'marketCode':marketCode,
            // 'fsXaxisShow':'',
            'volUnit':'',
            // 'fsTotalTimeNum':''
        };

        //////////////////////////////////////////////////////
        // isXXTpl包含指数和个股                              //
        // 必须是isXXIndexTpl,isXXStockTpl,isXXBlockTpl的合集 //
        //////////////////////////////////////////////////////
        var isHKIndexTpl = /^(176)$/,
            // isHKStockTpl = /^(177|178|179|180|182|181)$/,
            isHKTpl = /^(hk|176|177|178|179|180|182|181|183)$/,

            // 沪深 包含 沪深ａ股ｂ股，指数，板块, hs.
            // 沪深 板块:48(包括行业和概念), 再细分: 对shortcode 881 是行业，剩余 是概念
            isHSIndexTpl = /^(zs|16|32|24)$/,
            isHSBlockTpl = /^(48|bk)$/,
            isHSBlockHangyeTpl = /^(881)/,
            // isHSStockTpl = /^(17|18|22|33|34)$/,
            
            //科创板
            isHSKcbTpl = /^(688)/,

            // 新三板
            isSBStockTpl = /^(sb|144|145)$/,
            isHSTpl = /^(hs|sz|sh|zs|16|32|24|48|bk|17|18|22|33|34|sb|144|145|21|37)$/,

            // OthersTpl是 贵金属 外汇 股指期货 基金 国外期货 国内期货 上海债券 深圳债券 国外指数 的合集
            isOthersTpl = /^(pmetal|41|wh|92|97|98|99|gzqh|129|fd|20|36|gqh|217|219|qh|65|66|67|68|69|70|shzq|szzq|19|35|gzs|sj|81|ggqq|233|104|120)$/,
            // 贵金属
            isPMetal = /^(pmetal|41)$/,
            // 外汇
            isForeignExchange = /^(wh|97|98|99)$/,
            // 股指期货
            isIndexFuture = /^(gzqh|129)$/,
            // 基金
            isFund = /^(fd|20|36)$/,
            // 国外期货
            isOverseasFuture = /^(gqh|217|219)$/,
            // 国内期货
            isInternalFuture = /^(qh|65|66|67|68|69|70)$/,
            // 债券
            isBond = /^(shzq|szzq|19|35)$/,
            // 国外指数, 包含个别美股指数, 是88的子集
            isOverseasIndex = /^(gzs|92)$/,
            // 上金所
            isSJS = /^(sj|81)$/,
            // 个股期权
            isStockOption = /^(ggqq|233|104|120)$/,

            isUSAIndexTpl = /^(200|88)$/,
            // isUSAStockTpl = /^(169|168|170|185|184|186|189|201|202)$/,
            isUSATpl = /^(usa|169|168|170|185|184|186|189|200|88|201|202)$/,

            isUKTpl = /^(eu|161)$/;


        if (isHSTpl.test(marketCode)) {
            result.keepLen = 2;
            result.needConvertoShou = true;
            result.volUnit = '手';

            result.fsXaxisShow = this.get('hs').fsXaxisShow;
            // result.fsTotalTimeNum = this.get('hs').fsTotalTimeNum;
            
            if(isHSKcbTpl.test(shortStockCode)){
                // 科创板
                result.msg = 'HS_stock_kcb';
                result.fsXaxisShow = this.get('hskcb').fsXaxisShow;
            }else if (isHSIndexTpl.test(marketCode)) {
                // 沪深指数
                result.msg = 'HS_index';
              
            }else if (isHSBlockTpl.test(marketCode)) {
                if (isHSBlockHangyeTpl.test(shortStockCode)) {
                    // 沪深行业
                    result.msg = 'HS_block_hangye';
                }else{
                    // 沪深概念
                    result.msg = 'HS_block_gainian';
                }
              
            }else if (isSBStockTpl.test(marketCode)) {
                // 新三板
                result.msg = 'HS_stock_sb';
            }else{
                // 沪深个股
                result.msg = 'HS_stock';
            }
            
        }else if (isHKTpl.test(marketCode)) {
            result.keepLen = 3;
            result.needConvertoShou = false;
            result.volUnit = '';

            result.fsXaxisShow = this.get('hk').fsXaxisShow;
            // result.fsTotalTimeNum = this.get('hk').fsTotalTimeNum;
             
            if (isHKIndexTpl.test(marketCode)) {
                // 港股指数
                result.msg = 'HK_index';
            }else{
                // 港股个股
                result.msg = 'HK_stock';
            }
        }else if(isUSATpl.test(marketCode)){
            result.needConvertoShou = false;
            result.volUnit = '';

            if (isUSAIndexTpl.test(marketCode)) {
                // 美股指数
                result.msg = 'USA_index';
                result.keepLen = 2;
            }else{
                // 美股个股
                result.msg = 'USA_stock';
                result.keepLen = 3;
            }

        }else if(isOthersTpl.test(marketCode)){
            result.keepLen = 2;
            result.needConvertoShou = true;
            result.volUnit = '手';

            if(isPMetal.test(marketCode)){
                result.msg = 'Others_pmetal';
            }else if(isForeignExchange.test(marketCode)){
                result.msg = 'Others_wh';
                result.keepLen = 5;
                // result.fsTotalTimeNum = 1440;
            }else if(isIndexFuture.test(marketCode)){
                result.msg = 'Others_gzqh';
                result.needConvertoShou = false;
            }else if(isFund.test(marketCode)){
                result.msg = 'Others_fd';
                result.keepLen = 3;
            }else if(isOverseasFuture.test(marketCode)){
                result.msg = 'Others_gqh';
                result.needConvertoShou = false;
                result.volUnit = '';
                result.keepLen = 3;
            }else if(isInternalFuture.test(marketCode)){
                result.msg = 'Others_qh';
                result.needConvertoShou = false;
                result.volUnit = '';
                result.keepLen = 2;
            }else if(isBond.test(marketCode)){
                result.msg = 'Others_zq';
            }else if(isOverseasIndex.test(marketCode)){
                result.msg = 'Others_gzs';
                result.keepLen = 2;
            }else if(isSJS.test(marketCode)){
                result.msg = 'Others_sj';
                result.needConvertoShou = false;
                result.volUnit = '';
                result.keepLen = 2;
                // result.fsTotalTimeNum = 663;
            }else if(isStockOption.test(marketCode)){
                result.msg = 'Others_ggqq';
                result.keepLen = 2;
            }else{
                result.msg = 'Others_'+marketCode;
            }

        }else if(isUKTpl.test(marketCode)){
            // 英股指数 
            result.msg = 'UK_stock';
            result.keepLen = 2;
          
            result.needConvertoShou = false;

            result.volUnit = '';
            // result.fsTotalTimeNum = 511;
                         
        }else{
            result.msg = 'HS_stock';
            result.keepLen = 2;
            result.needConvertoShou = true;
            result.volUnit = '手';
        }
        return result;

    };

    export default StockType;
