/**
 * 蜡烛图 || k线
 */

import Path from 'zrender/src/graphic/Path';

export default Path.extend({

	type: 'candlestick',

	shape: {
		x: 0,
		open: 0,
		close: 0,
		low: 0,
		hight: 0,
		width: 0
	},

	style: {
		lineWidth: 1
	},

	buildPath: function (ctx, shape) {
		let { x, open, close, low, high, width } = shape;
		let center = (x + width / 2 >> 0) + 0.5;

		if (width > 0.5) {
			let max = Math.max(open, close);
			let min = Math.min(open, close);
	
			ctx.rect(x, min, width, max - min);
			ctx.moveTo(center, low);
			ctx.lineTo(center, max);
			ctx.moveTo(center, min);
			ctx.lineTo(center, high);
		} else {
			ctx.rect(x, high, width, high - low);
		}
	}
});