/**
 * 词云 by wjw
 */
import SeriesModel from '../../model/Series';
import { each } from '../../util';
import d3Cloud from './d3-cloud';

export const TYPE = 'wordcloud';

export default class WordCloudModel extends SeriesModel {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    static defaultOption = {
        zlevel: 0, // canvas层级
        z: 1, // 同一层canvas内层级

        // https://github.com/jasondavies/d3-cloud

        fontFamily: 'Impact', // {string} fontFamily
        fontStyle: 'normal', // {string} fontStyle
        fontWeight: 'normal', // {string|number} fontWeight
        fontSize: function(d) {
            return d.value;
        }, // {Function} 字体大小
        color: undefined, // {string|Function} 颜色

        padding: 1, // {number} 字间隔
        rotate: function() {
            return (~~(Math.random() * 6) - 3) * 30;
        }, // {number|Function} 旋转方法
        text: function(d) {
            return d.name;
        }, // {string} 字内容获取方法
        random: Math.random, // {Function} 随机方法
        spiral: 'archimedean' // {string|Function} 螺旋 'rectangular' || 'archimedean'
    };

    constructor() {
        super(...arguments);

        this.layout = d3Cloud();
    }

    update() {
        let {
            globalModel,
            _option: {
                fontFamily,
                fontStyle,
                fontWeight,
                fontSize,
                padding,
                rotate,
                text,
                random,
                spiral
            }
        } = this;
        let data = this.getData();
        let gridIndex = this.get('$gridIndex');
        let gridModel = globalModel.getComponentByIndex('grid', gridIndex);
        let position = gridModel.position;

        this.layout
            .size([position.width, position.height])
            .words(data)
            .font(fontFamily)
            .fontStyle(fontStyle)
            .fontWeight(fontWeight)
            .fontSize(fontSize)
            .padding(padding)
            .rotate(rotate)
            .text(text)
            .random(random)
            .spiral(spiral)
            .stop()
            .start();

        this.data = data;
        this.position = position;
    }
}
