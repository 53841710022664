let platform = '';
// Navigator not exists in node
if (typeof navigator !== 'undefined') {
    platform = navigator.platform || '';
}

export default {
    textStyle: {
        fontFamily: 'sans-serif',
        // fontFamily: platform.match(/^Win/) ? 'Microsoft YaHei' : 'sans-serif',  雅黑字体涉及侵权，需要去除。
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 'normal'
    },
    roam: {
        enable: false,
        inGrid: false,
        horiDrag: true,
        vertiDrag: true
    },
    backgroundColor: undefined,
    preventDefaultMouseWheel: true, // {boolean} 是否阻止 mousewheel 事件的默认行为。
    animation: true, // {boolean} 是否开启动画。
    animationDuration: 500, // {number} 初始动画的时长
    animationEasing: 'cubicOut' // {string} 初始动画的缓动效果。不同的缓动效果可以参考 http://www.echartsjs.com/gallery/editor.html?c=line-easing。
}


export const color20 = ['#1f77b4', '#aec7e8', '#ff7f0e', '#ffbb78', '#2ca02c', '#98df8a', '#d62728', '#ff9896', '#9467bd', '#c5b0d5', '#8c564b', '#c49c94', '#e377c2', '#f7b6d2', '#7f7f7f', '#c7c7c7', '#bcbd22', '#dbdb8d', '#17becf', '#9edae5'];
