/**
 * k线指标 by zzy
 */
import SeriesModel from '../../model/Series';
import { each, map, merge, clone, isArray } from '../../util';
import Indicator from './Indicator';

export const TYPE = 'hqIndicator';

/*
行情指标

*/

export default class HqIndicatorModel extends SeriesModel {
    static type = TYPE;

    type = TYPE;

    static defaultOption = {
        //data: undefined, // {Array} 系列中的数据内容数组。数组项通常为具体的数据项。数据项格式同echarts类似，http://echarts.baidu.com/option.html#series-line.data
        $dataIndex: undefined, // {number} 依赖的$data的index，格式同上，优先级低于data，取得的数据需符合data的格式
        dataKey: undefined, // {string|number} 搭配$dataIndex使用，方便从依赖的$data中取数据，等同于 data.map((d) => d[dataKey])，最终取得的数据需符合data的格式
        $axisIndex: [0, 1], // {Array} 所依赖axis的index，需包括一个x轴和y轴
        name: undefined, // {string} 系列名称，用于tooltip的显示，legend 的图例筛选

        // {string} indicator类型 'macd', 'asi', 'bias', 'boll', 'kdj', 'rsi', 'vr', 'wr' 
        indicatorType: 'macd',

        getXKey: function (d) {
            return d.xIndex;
        },

        // 指标配置
        indicator: undefined,
       
        cursor: 'default', // {string} 鼠标悬浮时在图形元素上时鼠标的样式是什么。同 CSS 的 cursor。
        zlevel: 0, // {number} 所有图形的 zlevel 值
        z: 3 // {number} 组件的所有图形的z值。控制图形的前后顺序。z值小的图形会被z值大的图形覆盖。
    };

    // 返回的数组，第一位用于x轴计算
    // 第二位用于 y轴计算，其他信息可以放在第三位
    formatData(data) {
        let indicatorType = this.get('indicatorType');
        let Ind = Indicator.getClass(indicatorType);
        return map(data, Ind.formatData);
    }

    update() {
        let xAxis = this.getAxisModel('x'),
            yAxis = this.getAxisModel('y');
        let { startIndex, endIndex, scale:xScale } = xAxis;
        let yScale = yAxis.scale;
        let yDomain = yScale.domain();
        let xDomain = xScale.domain();
        let bandwidth = xScale.bandwidth();
        let getXKey = this.get('getXKey');
        let indicatorType = this.get('indicatorType');
        let offset = bandwidth / 2;
        let points = [];
        let indicatorOption;
        
        if(Indicator.getClass(indicatorType)){
            let Ind = Indicator.getClass(indicatorType);
            let indicatorObj = new Ind();
            indicatorOption = this.get('indicator') ? merge(this.get('indicator'), clone(Ind.defaultOption)) : clone(Ind.defaultOption);
            
            let y0 = yScale(
                yDomain[1] < 0 ? yDomain[1] : yDomain[0] > 0 ? yDomain[0] : 0
            );

            let data = this.getData().slice(
                startIndex,
                endIndex + 1
            );

            let shapes = [];
            each(indicatorOption, (item, name)=>{
                let shapeAttr;
                if (item.type !== 'line') {
                    shapeAttr = indicatorObj.getDrawData({name, xScale, yScale, data, item, model:this});
                }else{
                    let points = [];
                    for (let i = 0; i < data.length; i++) {
                        points.push([
                            xScale(getXKey(data[i][2]))+offset, 
                            yScale(data[i][2][name]),
                            data[i],
                            i + startIndex,
                            i
                        ])
                    }
                    shapeAttr = {
                        shape:{
                            x: function (d) { return d[0]; },
                            y: function (d) { return d[1]; },
                            data: points,
                            yStart: y0,
                            percent: 1,
                            defined: v => v
                        },
                        style: item.normal.style
                    }
                }

                if(isArray(shapeAttr)){
                    for (let i = 0; i < shapeAttr.length; i++) {
                        shapeAttr[i].z2 = this.get('z');
                        shapeAttr[i].cursor = this.get('cursor');
                    }
                }else{
                    shapeAttr.z2 = this.get('z');
                    shapeAttr.cursor = this.get('cursor');
                }

                shapes.push({
                    name: name,
                    shapeType: item.type,
                    normal: item.normal,
                    emphasis: item.emphasis,
                    attr: shapeAttr
                });
            })

            // 每个柱子的实际宽度
            this.bandwidth = bandwidth;
            // 中心线偏移
            this.centerOffset = offset;
            // this.pointsByColor = pointsByColor;
            this.indicatorOption = indicatorOption;
            this.shapes = shapes;
            this.y0 = y0;
            this.startIndex = startIndex;
            this.endIndex = endIndex;
            this.indicator = indicatorObj;

        }else{
            console.warn(`行情指标类型${indicatorType}未注册！`);
        }

    }

    getSeriesColor(name) {
        // if (this.get('color')) {
        //     return this.get('color');
        // }
        var r = {};
        each(this.indicatorOption, (item, name)=>{
            r[name] = item.normal.style.stroke;
        });
        return r;
    }
}
