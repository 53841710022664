import Indicator from '../Indicator';
import CandleSticks from '../../../shape/CandleSticks';

import { IndicatorFormula } from 'hxc3-indicator-formula';

const Formula = IndicatorFormula.getClass('macd')
let formula = new Formula();

export default class MACD extends Indicator{
    static type = 'macd';

    static defaultOption = {
        MACD: {
            type: 'hqbar',
            normal:{
                show: true, 
                hq:{
                    up: 'rgba(247,48,75,1)', 
                    down: 'rgba(48,172,99,1)',
                    eq: 'rgba(68,68,68,1)'
                },
                style:{
                    lineWidth: 1,
                    stroke: '#555' 
                }
            },
            emphasis: undefined
        },
        DEA: {
            type: 'line',
            normal:{
                show: true, 
                style:{
                    stroke: '#C00C00', 
                    lineWidth: 1
                }
            },
            emphasis: undefined
        },
        DIFF: {
            type: 'line',
            normal:{
                show: true, 
                style:{
                    stroke: '#C0BA4D', 
                    lineWidth: 1
                }
            },
            emphasis: undefined
        }
    }

    static calculate(data){return formula.calculate(data)}

    static formatData(d, i) {
        return [i, [d.MACD, d.DIFF, d.DEA], d];
    }

    getShape(name){
        return CandleSticks;
    }

    getDrawData({name, xScale, yScale, data, item, model}){
        let bandwidth = xScale.bandwidth();
        let offset = bandwidth / 2;
        let getXKey = model.get('getXKey');
        let shapeAttr = [];

        let colorGroup = {
            up: [],
            down: [],
            eq: []
        };
        for (let i = 0; i < data.length; i++) {
            let info = data[i][2];
            let xPos = xScale(getXKey(info));
            let detar = yScale(info.MACD) - yScale(0) > 0 ? 1:(yScale(info.MACD) - yScale(0) < 0?-1:0)
            let point = [
                xPos,
                {   
                    lineX: xPos + offset,
                    lineYTop: detar>0 ? yScale(info.MACD) : yScale(0),
                    lineYBottom: detar>0 ? yScale(0) : yScale(info.MACD) 
                },
                info
            ]

            if (detar<0) {
                colorGroup.up.push(point);
            }else if(detar>0){
                colorGroup.down.push(point);
            }else{
                colorGroup.eq.push(point);
            }
        }

        for (let i in colorGroup) {
            shapeAttr.push({
                shape:{
                    bandwidth: 1,
                    data: colorGroup[i]
                },
                style: {
                    stroke: item.normal.hq[i],
                    lineWidth: 1
                }
            });
        }
        
        return shapeAttr;
    }

}