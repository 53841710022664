import { each, clone, isArray } from '../../../util';
import { Matrix4, Vector3 } from '../../../util/cuon-matrix';
import MultPolygon from '../../../shape/MultPolygon';

export default function getCuboidLayout({
    rectWidth, position, y0, points, model
}) {
    let itemStyle = model.get('itemStyle').normal;
    let xLookAngle = model.get('xLookAngle') / 180 * Math.PI;
    let yLookAngle = model.get('yLookAngle') / 180 * Math.PI;
    let isStack = !!model.get('stack');
    let attrs = [];
    let animateFroms = [];

    if (position === 'top' || position === 'bottom') {
        let rectStyle = itemStyle.rect;
        let shadowStyle = itemStyle.shadow;

        let viewMatrix = new Matrix4();
        viewMatrix.setLookAt(Math.tan(xLookAngle), Math.tan(yLookAngle), 1, 0, 0, 0, 0, 1, 0);

        let x = [0, rectWidth];
        let z = [rectWidth * 2, 0]

        let topFace = [
            (new Matrix4(viewMatrix)).multiplyVector3(new Vector3([x[0], 0, z[1]])).elements.slice(0, 2),
            (new Matrix4(viewMatrix)).multiplyVector3(new Vector3([x[1], 0, z[1]])).elements.slice(0, 2),
            (new Matrix4(viewMatrix)).multiplyVector3(new Vector3([x[0], 0, z[0]])).elements.slice(0, 2),
            (new Matrix4(viewMatrix)).multiplyVector3(new Vector3([x[1], 0, z[0]])).elements.slice(0, 2)
        ];

        each(points, ([xi, yi], index) => {
            let attr = {};
            let animateFrom = {};
            let points = [];
            let startPoints = [];
            let _y0 = isStack ? yi[0] : y0;
            let _yi = isStack ? yi[1] : yi;

            for (let i = 0; i < topFace.length; i++) {
                let x = topFace[i][0] + xi;

                points.push([x, topFace[i][1] + _y0]);
                points.push([x, topFace[i][1] + _yi]);
                startPoints.push([x, topFace[i][1] + _y0]);
            }

            // 正面矩形
            attr.rect = {
                shape: {
                    points: clone([
                        points[0],
                        points[2],
                        points[3],
                        points[1]
                    ])
                },
                style: rectStyle,
                z2: 2
            };

            animateFrom.rect = {
                shape: {
                    points: clone([
                        startPoints[0],
                        startPoints[1],
                        startPoints[1],
                        startPoints[0]
                    ])
                }
            };

            if (!isStack || model.stackIndex === 0) {
                // 上下矩形
                let points3 = [];

                points3 = [
                    points[0],
                    points[2],
                    points[6],
                    points[4]
                ];

                attr.shadow = {
                    shape: {
                        points: clone(points3)
                    },
                    style: shadowStyle,
                    z2: 0
                };

                animateFrom.shadow = {
                    shape: {
                        points: clone([
                            startPoints[0],
                            startPoints[1],
                            startPoints[3],
                            startPoints[2]
                        ])
                    }
                };
            }

            attrs.push(attr);
            animateFroms.push(animateFrom);
        });
    // eslint-disable-next-line no-empty
    } else {

    }

    return {
        attrs,
        animateFroms,
        Shape: MultPolygon
    }
}

function getItemStyle(itemStyle, index) {
    return itemStyle[index];
}
