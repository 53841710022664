/**
 * 新版本的地理组件 by wjw
 */

import ComponentModel from '../../model/Component';
import { each, map } from '../../util';
import { geoTransverseMercator } from 'd3-geo';

export const TYPE = 'geo';

export default class GeoModel extends ComponentModel {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    static defaultOption = {
        $gridIndex: 0,
        $visualMapIndex: undefined,
        mapName: 'china', // {string}  china | provinceCenter, 默认 中国地图 | 中国地图省份居中
        map: {
            outerPath: {
                show: true, // {boolean} 是否显示外边框
                style: {
                    stroke: '#00beff', // {string} 外边框颜色
                    lineWidth: 10 // {number} 外边框宽度
                }
            },

            label:{
                show: false
            },

            innerPath: {

                normal: {
                    show: true, // {boolean} 是否显示内边框
                    style: { // { object|function }可以传递 函数
                        stroke: 'rgba(83, 167, 227, 0.5)', // {string} 内边框颜色
                        fill: 'rgba(255, 255, 255, 1)', // {string} 内边框填充
                        lineWidth: 1, // {number} 内边框宽度,
                        lineDash: undefined // {array}虚线
                    }
                },

                emphasis: {
                    show: true, 
                    style:{
                        fill: 'rgba(40, 128, 255, 0.8)' // {string} 悬浮交互后颜色
                    }
                }

                // //////////////// 0.3.19 之后废弃
                // show: true, // {boolean} 是否显示内边框 @todo 废弃
                // style: {
                //     stroke: '#027da7', // {string} 内边框颜色
                //     fill: '#04526d', // {string} 内边框填充
                //     randomFill: {
                //         randomFill: false, // {boolean} 是否随机填充
                //         colors: ['rgb(17, 16, 50)', 'rgb(25, 24, 62)', 'rgb(15, 16, 55)', 'rgb(26, 25, 66)', 'rgb(15, 51, 51)', 'rgb(11, 10, 36)'] // {array} 随机填充颜色数组
                //     },
                //     lineWidth: 2, // {number} 内边框宽度
                //     hover: {
                //         hover: true, // {boolean} 是否鼠标悬浮交互
                //         hoverFill: 'rgba(40,128,255, 1.0)', // {string} 悬浮交互后颜色
                //     },
                //     lineDash: [0, 0] // {array}虚线
                // },

                // events: {
                //     registion: 'REGISTER', // {string} 交互事件注册
                //     dispatch: {
                //         mouseover: 'MOUSEOVER', // {string} 鼠标悬浮交互事件注册
                //         mouseout: 'MOUSEOUT' // {string} 鼠标移出交互事件注册
                //     }
                // }
            }
        },
        southSea: {
            show: true, // {boolean} 是否显示南海部分
            offset: [0, 0], // {array} 南海部分位置偏移
            scale: [0.7, 0.7], // {array} 南海部分缩放
            lineWidth: 2, // {number} 南海部分线宽
            style: {
                textFill: 'white', // {string} 文字填充
                textPosition: 'bottom', // {string} 文字位置
                fontSize: 14, // {number} 文字大小
                textOffset: [0, 0] // {array} 文字偏移
            }
        },

        zlevel: 0, // canvas层级
        z: 0 // 同一层canvas内层级
    }

    update () {
        let gridModel = this.globalModel.getComponentByIndex('grid', 0);
        let { left, right, top, bottom } = gridModel.position;

        this.projection = geoTransverseMercator()
            .rotate([-110, 0, 0])
            .fitExtent([[left, top], [right, bottom]], window.chinaBorderData);

        this.position = gridModel.position;
    }
}
