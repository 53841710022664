import GroupShape from '../../shape/GroupShape';
import { Symbol } from '../../util/symbol';
import { Text, Line, subPixelOptimizeLine } from '../../util/graphic';
import { merge, isFunction } from '../../util';

export default class LegendItem extends GroupShape {

    constructor(attrMap, globalModel, global) {
        super(...arguments);

        this.globalModel = globalModel;
        this.attr(arguments);
    }

    attr([attrMap, globalModel, global]) {
        let { x, y, textStyle, symbolType, symbolWidth, symbolHeight, symbolColor, align, innerGap,
            symbolStyle, series, item, isSelected, inactiveColor, position, left, width, right,
            lastHeight, verticalGap, fromRight, addLine, itemWidth } = attrMap;
        let color = isSelected ? symbolColor : inactiveColor;
        let seriesSymbol = series.get('symbol') && series.get('symbol').normal;
        let seriesLine = series.get('line') && series.get('line').normal;
        let text = new Text(globalModel.getFormattedText({
            style: {
                textVerticalAlign: 'center',
                truncate: itemWidth ? { outerWidth: itemWidth - innerGap - symbolWidth } : null,
                ...textStyle
            }
        }));
        let symbol, line;

        let _symbolType = symbolType;
        if (!_symbolType) {
            if (series.type === 'line' && seriesLine.show) {
                _symbolType = (seriesSymbol && seriesSymbol.show && seriesSymbol.type) || 'none';
            } else {
                _symbolType = 'roundRect';
            }
        }

        // 若是line类型，且未自定义icon，在中间加一条线
        if (addLine && series.type === 'line' && seriesLine.show) {
            let lineStyle = seriesLine.style;
            line = new Line(subPixelOptimizeLine({
                shape: {
                    x1: 0,
                    y1: 0,
                    x2: symbolWidth,
                    y2: 0
                },
                style: isSelected ? lineStyle : merge({ stroke: inactiveColor }, lineStyle, false),
                z2: 0
            }));
        }

        if (addLine && series.type === 'line' && seriesLine.show) {
            symbol = new Symbol({
                shape: {
                    symbolType: _symbolType,
                    x: (symbolWidth - symbolHeight) / 2,
                    y: 0,
                    width: symbolHeight,
                    height: symbolHeight
                },
                z2: 1
            });
        } else {
            symbol = new Symbol({
                shape: {
                    symbolType: _symbolType,
                    x: 0,
                    y: 0,
                    width: symbolWidth,
                    height: symbolHeight
                },
                z2: 1
            });
        }

        if (!symbolType && series.type === 'line' && seriesLine.show && seriesSymbol && seriesSymbol.show && !isFunction(seriesSymbol.style)) {
            let seriesSymbolStyle = seriesSymbol.style;
            let style = isSelected
                ? symbolStyle
                : { fill: seriesSymbolStyle.fill && inactiveColor, stroke: seriesSymbolStyle.stroke && inactiveColor };

            symbol.setStyle(isSelected ? seriesSymbolStyle : merge(style, seriesSymbol.style, false));
        } else {
            symbol.setColor(color);
            isSelected && symbol.setStyle(symbolStyle);
        }

        let textX, symbolX;
        let { height: textHeight, width: textWidth } = text.getBoundingRect();
        let centerY = y + Math.max(symbolHeight, textHeight) / 2;
        let globalWidth = global.getWidth();
        let realItemWidth;
        let _itemWidth;

        function autoFit() {
            let textStr = textStyle.text;
            let str = '';

            for (var i = 0; i < textStr.length; i++) {
                str += textStr[i];

                text.attr({
                    style: {
                        text: str
                    }
                });

                textWidth = text.getBoundingRect().width;
                realItemWidth = symbolWidth + textWidth + innerGap;
                _itemWidth = itemWidth || realItemWidth;

                if (fromRight) {
                    if (position[0] - _itemWidth < left) {
                        str = str.replace(/.$/, '\n' + str.match(/.$/));
                        position[1] += textHeight / 2;
                    }
                } else {
                    if (position[0] + _itemWidth > right) {
                        str = str.replace(/.$/, '\n' + str.match(/.$/));
                        position[1] += textHeight / 2;
                    }
                }
            }
        }

        realItemWidth = symbolWidth + textWidth + innerGap;
        _itemWidth = itemWidth || realItemWidth;

        if (fromRight) {
            if (position[0] - _itemWidth < left) { // 超过画布宽
                if (right === position[0]) { // 从起始位置
                    if (!itemWidth) {
                        autoFit();
                    }
                } else {
                    position = [right, position[1] + lastHeight + verticalGap];

                    if (!itemWidth) {
                        autoFit();
                    }
                }
            }
        } else {
            if (position[0] + _itemWidth > right) { // 超过画布宽
                if (left === position[0]) { // 从起始位置
                    if (!itemWidth) {
                        autoFit();
                    }
                } else {
                    position = [left, position[1] + lastHeight + verticalGap];

                    if (!itemWidth) {
                        autoFit();
                    }
                }
            }
        }

        if (align === 'right') {
            textX = x;
            symbolX = x + textWidth + innerGap;
        } else {
            symbolX = x;
            textX = x + symbolWidth + innerGap;
        }

        text.attr({
            position: [textX, centerY - 1]
        });
        symbol.attr({
            position: [symbolX, centerY - symbolHeight / 2]
        });

        this.set('text', text);
        this.set('symbol', symbol);

        if (line) {
            line.attr({
                position: [symbolX, centerY]
            });
            line && this.set('line', line);
        }

        if (fromRight) {
            position = [position[0] - itemWidth, position[1]];
        }

        this.group.attr('position', position);
    }

    remove() {
        super.remove(...arguments);
        this.globalModel = null;
    }
}
