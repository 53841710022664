/**
 * 矩形 可配置圆角 和 borderEnable
 * BorderRoundRect
 */
import { Path } from '../util/graphic';


function convertToArr(r){
    var r1, r2, r3, r4;

    if (typeof r === 'number') {
        r1 = r2 = r3 = r4 = r;
    } else if (r instanceof Array) {
        if (r.length === 1) {
            r1 = r2 = r3 = r4 = r[0];
        } else if (r.length === 2) {
            r1 = r3 = r[0];
            r2 = r4 = r[1];
        } else if (r.length === 3) {
            r1 = r[0];
            r2 = r4 = r[1];
            r3 = r[2];
        } else {
            r1 = r[0];
            r2 = r[1];
            r3 = r[2];
            r4 = r[3];
        }
    } else {
        r1 = r2 = r3 = r4 = 0;
    }

    return { r1, r2, r3, r4 };
}

function buildPath(ctx, shape) {
    var x = shape.x;
    var y = shape.y;
    var width = shape.width;
    var height = shape.height;
    var r = shape.r;

    if (width < 0) {
        x = x + width;
        width = -width;
    }

    if (height < 0) {
        y = y + height;
        height = -height;
    }

    let {r1, r2, r3, r4} = convertToArr(r);
    let {r1:borderTop, r2:borderRight, r3:borderBottom, r4:borderLeft} = convertToArr(shape.borderEnable);

    var total;

    if (r1 + r2 > width) {
        total = r1 + r2;
        r1 *= width / total;
        r2 *= width / total;
    }

    if (r3 + r4 > width) {
        total = r3 + r4;
        r3 *= width / total;
        r4 *= width / total;
    }

    if (r2 + r3 > height) {
        total = r2 + r3;
        r2 *= height / total;
        r3 *= height / total;
    }

    if (r1 + r4 > height) {
        total = r1 + r4;
        r1 *= height / total;
        r4 *= height / total;
    }

    if (borderTop) {
        ctx.moveTo(x + r1, y);
        ctx.lineTo(x + width - r2, y);
        r2 !== 0 && ctx.arc(x + width - r2, y + r2, r2, -Math.PI / 2, 0);
        // r2 !== 0 && ctx.quadraticCurveTo(x + width, y, x + width, y + r2);

    }

    if (borderRight) {
        ctx.moveTo(x + width - r2, y);
        ctx.lineTo(x + width, y + height - r3);
        r3 !== 0 && ctx.arc(x + width - r3, y + height - r3, r3, 0, Math.PI / 2);
        // r3 !== 0 && ctx.quadraticCurveTo(x + width, y + height, x + width - r3, y + height );
    }

    if (borderBottom) {
        ctx.moveTo(x + width, y + height - r3);
        ctx.lineTo(x + r4, y + height);
        r4 !== 0 && ctx.arc(x + r4, y + height - r4, r4, Math.PI / 2, Math.PI);
        // r4 !== 0 && ctx.quadraticCurveTo( x, y + height, x, y + height - r4 );
    }

    if(borderLeft){
        ctx.moveTo(x + r4, y + height);
        ctx.lineTo(x, y + r1);
        r1 !== 0 && ctx.arc(x + r1, y + r1, r1, Math.PI, Math.PI * 1.5);
        // r1 !== 0 && ctx.quadraticCurveTo(x, y, x + r1, y);
    }
    
}

export default Path.extend({

    type: 'borderRect',

    shape: {
        // 左上、右上、右下、左下角的半径依次为r1、r2、r3、r4
        // r缩写为1         相当于 [1, 1, 1, 1]
        // r缩写为[1]       相当于 [1, 1, 1, 1]
        // r缩写为[1, 2]    相当于 [1, 2, 1, 2]
        // r缩写为[1, 2, 3] 相当于 [1, 2, 3, 2]
        r: 0,

        // 同上规则, 上右下左
        borderEnable: 1,

        x: 0,
        y: 0,
        width: 0,
        height: 0
    },

    buildPath: function (ctx, shape) {
        var x = shape.x;
        var y = shape.y;
        var width = shape.width;
        var height = shape.height;
        if (!shape.r && (shape.borderEnable === 1 || shape.borderEnable === 0)){
            ctx.rect(x, y, width, height);
        } else {
            buildPath(ctx, shape);
        }
        ctx.closePath();
        return;
    }
});