/**
 * 柱状图 by wjw
 */
import SeriesModel from '../../model/Series';
import { each, isArray, map, isObject, filter, getObjectKV, setObjectKV } from '../../util';

export const TYPE = 'bar';

/*
一个最简单的折线图的配置中需要包括一个grid、两个axis分别为x和y，以及series中的line，示例如下，可在官网中直接运行

组件详细配置项如下，每个配置项都有一个默认值：


*/

export default class BarModel extends SeriesModel {
    static type = TYPE;

    type = TYPE;

    static emphasisList = ['itemStyle', 'label'];

    static defaultOption = {
        //data: undefined, // {Array} 系列中的数据内容数组。数组项通常为具体的数据项。数据项格式同echarts类似，http://echarts.baidu.com/option.html#series-bar.data
        $dataIndex: undefined, // {number} 依赖的$data的index，格式同上，优先级低于data，取得的数据需符合data的格式
        dataKey: undefined, // {string|number} 搭配$dataIndex使用，方便从依赖的$data中取数据，等同于 data.map((d) => d[dataKey])，最终取得的数据需符合data的格式
        $axisIndex: [0, 1], // {Array} 所依赖axis的index，需包括一个x轴和y轴
        name: undefined, // {string} 系列名称，用于tooltip的显示，legend 的图例筛选

        rectRadius: undefined, // {Number | Array |Function} 矩形圆角大小
        subPixelOptimize: true, // {boolean} 是否对矩形进行高清处理，位置可能会偏移
        // bar样式
        itemStyle: {
            // {Object|Function} 普通样式
            normal: {},
            // {Object|Function} 高亮样式
            emphasis: {}
        },

        // 标签
        label: {
            normal: {
                show: false, // {boolean} 普通状态是否显示
                formatter: undefined, // {Function} 文本格式化函数
                style: { // {Object|Function} 样式
                    position: 'inside'
                }
            },
            emphasis: {
                show: false, // {boolean} 高亮状态是否显示
                style: {} // {Object|Function} 样式
            }
        },

        // 柱间连接区域配置
        joinArea: {
            show: false, // 是否显示柱间连接区域
            style: { // {Object} 连接区域样式
                fill: 'rgba(12, 55, 256, 0.3)'
            },
            showLabel: true, // {boolean} 是否显示label
            labelStyle: {},// {Object} label样式
            formatter: undefined // {Function} label格式化函数
        },


        large: false, // {boolean} 是否开启大数据量优化，在数据图形特别多而出现卡顿时候可以开启。缺点：优化后不能自定义设置单个数据项的样式
        largeThreshold: 2000, // {number} 开启绘制优化的阈值。

        barType: 'rect', // {string} bar的展示类型 rect | cuboid | candlestick | shadowRect | waterfall | boxplot
        selectedList: [], // {Array} 选中项，使用数据下标指定
        enableSelect: false, // {boolean|string} 是否开启选中, single(true) | multiple | false
        onZero: true, // {boolean} 起始点是否从0开始
        xLookAngle: 30, // {number} barType为cuboid或shadowRect时有效，x方向观察角度
        yLookAngle: 30, // {number} barType为cuboid或shadowRect时有效，y方向观察角度
        rectMin: 0, // {number} rect的最小高度，仅适用于rect类型barType，堆叠类型不适用
        rectWidth: 10, // {number} rect的宽度，仅适用于x轴为连续类型坐标轴（linear、time等）
        legendHoverLink: true, // {boolean} 是否与图例进行高亮联动
        stack: undefined, // {string} 堆叠名，设置后堆叠名相同将以堆叠形式展示
        overlap: undefined, // {string} 重叠名，设置后重叠名相同将以重叠形式展示
        hideZero: false, // {boolean} 是否隐藏0值的柱形

        cursor: 'default', // {string} 鼠标悬浮时在图形元素上时鼠标的样式是什么。同 CSS 的 cursor。
        zlevel: 0, // {number} 所有图形的 zlevel 值
        z: 2 // {number} 组件的所有图形的z值。控制图形的前后顺序。z值小的图形会被z值大的图形覆盖。
    };

    formatData(data) {
        let xAxis = this.getAxisModel('x');
        let isBand = xAxis.get('type') === 'band';

        return map(data, (d, i) => (isBand ? [i, d] : d));
    }

    formatOption(option) {
        super.formatOption(...arguments);

        if (option.color && !getObjectKV(option, 'itemStyle.normal.fill')) {
            setObjectKV(option, 'itemStyle.normal.fill', option.color);
        }
    }

    update() {
        let { globalModel, barTotal } = this;
        let xAxis = this.getAxisModel('x'),
            yAxis = this.getAxisModel('y');
        let compare = yAxis && yAxis.get('compare');
        let { startIndex, endIndex, scale: xScale } = xAxis;
        let yScale = yAxis.scale;
        let xDomain = xScale.domain();
        let yDomain = yScale.domain();
        let y0 = yScale(
            yDomain[1] < 0 ? yDomain[1] : yDomain[0] > 0 ? yDomain[0] : 0
        );
        let isBand = xAxis.get('type') === 'band';
        let data;
        let barOffset;
        let rectWidth;
        let points = [];

        // x轴是band类型
        if (isBand) {
            let bandwidth = xScale.bandwidth();
            let barGap = xAxis.barGap;
            let barWidth = (1 - barGap * (barTotal - 1)) / barTotal;

            data = (this.get('stack') ? this.stackData : this.getData()).slice(
                startIndex,
                endIndex + 1
            );
            rectWidth = bandwidth * barWidth;
            barOffset = bandwidth * (this.barIndex * (barGap + barWidth));

            if (!this.get('stack') && compare) {
                let startValue = data[0][1];

                data = data.map((d) => {
                    let re = [...d];

                    if (compare === 'percent') {
                        re[1] = (re[1] - startValue) / startValue;
                    } else {
                        re[1] -= startValue;
                    }
                    re[2] = d;

                    return re;
                });

                this.data = data;
            }

            each(data, (d, i) => {
                if (d) {
                    let [dx, dy] = d;
                    let ys = isArray(dy) ? map(dy, di => yScale(di)) : yScale(dy);

                    points.push([
                        xScale(xDomain[i]) + barOffset,
                        ys,
                        d,
                        i + startIndex,
                        i
                    ]);
                }
            });

            this.bandwidth = xScale.bandwidth();
            this.centerOffset = barOffset + rectWidth / 2;
        } else {
            let data = this.getData();

            rectWidth = this.get('rectWidth');
            barOffset = -rectWidth / 2;

            each(data, (d, i) => {
                if (d) {
                    let [dx, dy] = d;
                    let ys = isArray(dy)
                        ? map(dy, di => getPointOnAxis(di, yAxis))
                        : getPointOnAxis(dy, yAxis);
                    let point = [];

                    point[0] = getPointOnAxis(dx, xAxis) + barOffset;
                    point[1] = ys;

                    if (!isNaN(point[0]) && !isNaN(point[0])) {
                        point[2] = d;
                        point[3] = i;

                        points.push(point);
                    }
                }
            });
        }

        this.ticks = map(
            xAxis.axis.values(),
            v => xDomain.indexOf(v) + startIndex
        );
        this.points = points;
        this.y0 = y0;
        this.position = xAxis.get('position');
        this.rectWidth = Math.max(rectWidth, 0.5);
    }

    getSeriesColor(name) {
        if (this.get('color')) {
            return this.get('color');
        }

        let itemStyle = this.get('itemStyle').normal;
        let barType = this.get('barType');

        switch (barType) {
            case 'cuboid':
                itemStyle = itemStyle.front;
                break;
            case 'shadowRect':
                itemStyle = itemStyle.rect;
                break;
            default:
                break;
        }

        let color = itemStyle.fill || itemStyle.stroke;

        if (color) {
            return isObject(color) ? color.colorStops[0].color : itemStyle.fill;
        }
    }
}

/**
 * 获取值对应到轴上的位置
 */
function getPointOnAxis(value, axis) {
    let isTime = axis.get('type') === 'time';

    isTime && !(value instanceof Date) && (value = new Date(value));

    if (typeof value !== 'number' && !isTime) return;

    let axisType = axis.getAxisType();
    let scale = axis.scale;
    let domain = scale.domain();

    switch (axisType) {
        case 'continuous':
            if (value <= domain[1] && value >= domain[0]) {
                return scale(value);
            }
            break;
        case 'ordinal':
            // eslint-disable-next-line no-case-declarations
            let startIndex = axis.startIndex;
            // eslint-disable-next-line no-case-declarations
            let endIndex = axis.endIndex;

            if (value <= endIndex && value >= startIndex) {
                return (
                    scale(domain[value - startIndex]) +
                    (axis.get('type') === 'band' ? scale.bandwidth() / 2 : 0)
                );
            }
            break;
        default:
            break;
    }
}
