/**
 * 桑基图 by wjw
 */
import SeriesModel from '../../model/Series';
import { each } from '../../util';
import * as d3Sankey_ from 'd3-sankey';
const d3Sankey = d3Sankey_;

export const TYPE = 'sankey';

export default class SankeyModel extends SeriesModel {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    static defaultOption = {
        data: undefined, // {Object} 
        $gridIndex: 0, // {number} 所依赖grid的index

        // https://github.com/d3/d3-sankey
        nodeWidth: 15, // {number} 节点宽度
        nodePadding: 15, // {number} 节点间隔
        nodeAlign: 'sankeyJustify', // {string} 节点对齐方式
        iterations: 32, // {number} 布局的迭代次数，用来不断优化图中节点的位置，以减少节点和边之间的相互遮盖。设置为2时左右两侧的node倾向于居中对齐

        linkStyle: { // 连线样式
            normal: {

            },
            emphasis: {

            }
        },
        onNodeClick: undefined, // {Function} 节点点击事件
        nodeStyle: { // 节点样式
            normal: { // {Object} 普通样式

            },
            emphasis: { // {Object} 高亮样式

            }
        },
        label: { // 标签配置
            show: true, // {boolean} 是否显示
            padding: 5, // {number} 标签与节点间隔
            position: 'right', // {string} 标签位置
            offset: [0, 0], // {Array} 标签偏移
            style: { // 标签样式
                normal: { // {Object} 普通样式
                },
                emphasis: { // {Object} 高亮样式
                }
            },
            formatter: undefined // {Function} 格式化标签文本
        },
        triggerOn: 'mousemove', // {string} 触发方式

        zlevel: 0, // canvas层级
        z: 1 // 同一层canvas内层级
    };

    update() {
        let { globalModel } = this;
        let data = this.getData();
        let gridIndex = this.get('$gridIndex');
        let gridModel = globalModel.getComponentByIndex('grid', gridIndex);
        let { left, top, right, bottom } = gridModel.position;
        let nodeWidth = this.get('nodeWidth');
        let nodePadding = this.get('nodePadding');
        let nodeAlign = this.get('nodeAlign');
        let iterations = this.get('iterations');

        if (data && data.links) {
            each(data.links, function (link) {
                link.value = link.value - 0;
            })
        }
        
        let sankey = this.sankey = d3Sankey.sankey()
            .nodeWidth(nodeWidth)
            .nodePadding(nodePadding)
            .nodeAlign(d3Sankey[nodeAlign])
            .iterations(iterations)
            .extent([[left, top], [right, bottom]]);

        sankey(data);

        this.position = gridModel.position;
    }
}