import { Path } from '../util/graphic';
import d3area from 'd3-shape/src/area';
import { isFunction } from '../util';

export default Path.extend({
    type: 'd3-area',

    shape: {

        data: [],

        x: undefined,

        x0: undefined,

        x1: undefined,

        y: undefined,

        y0: undefined,

        y1: undefined,

        defined: undefined,

        curve: undefined,

        yStart: 0,

        percent: 1
    },

    style: {
        stroke: null,

        fill: '#000'
    },

    updateArea: function () {
        !this.area && (this.area = d3area());

        let { area, shape } = this;

        for (let p in shape) {
            if (!~['data', 'percent', 'yStart'].indexOf(p) && area[p] && typeof shape[p] !== 'undefined') {
                if (p === 'y1' || p === 'y0') {
                    let y = shape[p];
                    let yIsFunc = isFunction(y);
                    let percent = shape.percent;
                    let yStart = shape[p === 'y1' ? 'y1Start' : 'y0Start'];
                    let yStartIsFunc = isFunction(yStart);

                    area[p]((v, i, data) => (yIsFunc ? y(v, i, data) : y) * percent + (yStart ? (yStartIsFunc ? yStart(v, i, data) : yStart) * (1 - percent) : 0));
                } else {
                    area[p](shape[p]);
                }
            }
        }
    },

    buildPath: function (context, shape) {
        this.updateArea();
        this.area.context(context)(shape.data);
    }
})