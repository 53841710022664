/* eslint-disable no-case-declarations */
/**
 * 坐标轴
 */
import ComponentModel from '../../model/Component';
import scales from './scale';
import { axisBottom, axisRight, axisLeft, axisTop } from './axis';
import { dataZoomActions, axisActions } from '../../action/event';
import {
    each,
    map,
    isArray,
    isObject,
    parsePercent,
    findAllIndex,
    filter,
    isFunction,
    setObjectKV
} from '../../util';
import { Text } from '../../util/graphic';
import extent from 'd3-array/src/extent';
import arrayMax from 'd3-array/src/max';
import arrayMin from 'd3-array/src/min';
import NP from '../../util/number-precision';

const TYPE = 'axis';

/*

坐标轴依赖于grid，通常使用与折线图、柱状图等基于直角坐标系的图表。


组件详细配置项如下，每个配置项都有一个默认值：

*/

export default class AxisModel extends ComponentModel {
    static type = TYPE;

    type = TYPE;

    static defaultOption = {
        //data: undefined, // {Array} 系列中的数据内容数组。数组项通常为具体的数据项。数据项格式同echarts类似，http://echarts.baidu.com/option.html#xAxis.data
        $dataIndex: undefined, // {number} 依赖的$data的index，格式同上，优先级低于data，取得的数据需符合data的格式
        dataKey: undefined, // {string|number} 搭配$dataIndex使用，方便从依赖的$data中取数据，等同于 data.map((d) => d[dataKey])，最终取得的数据需符合data的格式

        // {number|Array<number>} 依赖的grid的index, 若为数组，将在多个grid触发axis事件，但仅在数组第一项grid位置进行axis的绘制; 如果有上下两个grid，此时x轴的配置就该为$gridIndex: [0, 1]
        $gridIndex: 0,

        $dataZoomIndex: undefined, // {number} 依赖的dataZoom的index

        relyOtherAxis: false, // {boolean} 是否依赖其他轴，在依赖其他轴是必须配置
        xOrY: 'x', // {string} 作为x轴还是y轴， 'x' | 'y'
        position: 'bottom', // {string} 坐标轴位于grid的位置， 'bottom' | 'top' | 'left' | 'right'
        // https://github.com/xswei/d3js_doc/tree/master/d3js_doc_old/API/d3-scale-master
        type: 'band', // {string} scale类型，同d3-scale，linear | band | point | log | time， https://github.com/d3/d3-scale/

        start: '0%', // {string} 数据起始位置百分比，设置dataZoomIndex后则使用dataZoom的start
        end: '100%', // {string} 数据结束位置百分比，设置dataZoomIndex后则使用dataZoom的end

        barGap: '20%', // {string} 适用于柱状图，柱间距离, 适用于多组数据
        paddingInner: '60%', // {string} 仅对band类型有效，band与band之间的内部间隔，以百分比形式定义.
        paddingOuter: '30%', // {string} 仅对band类型有效，band两边相对于range两端的间隔，同样以百分比形式定义.
        padding: '0%', // {string} 仅对point类型有效，band两边相对于range两端的间隔，同样以百分比形式定义.
        offset: 0, // {number} 相对于默认位置的偏移，在相同的 position 上有多个 Y 轴的时候有用
        ifTriggerHighlight: true, // {boolean} 是否触发series的highlight和downplay
        domainScale: 1.2, // {number} 对domain进行缩放，仅适用于linear型轴，当设置space时无效
        nice: false, // {boolean} 对domain 进行适当的扩展，以使其起始值是一个“整数”，会与domainScale叠加。仅连续型轴（linear、log等）有效
        domainFormatter: undefined, // {Function} 重新设置domain，仅连续型轴有效
        max: undefined, // {number} 设置domain的最大值，仅连续型轴有效
        min: undefined, // {number} 设置domain的最小值，仅连续型轴有效
        fitMax: undefined, // {number} 设置domain允许的最大值，仅连续型轴有效
        fitMin: undefined, // {number} 设置domain允许的最小值，仅连续型轴有效
        splitNumber: undefined, // {number} 坐标轴的分割段数，需要注意的是这个分割段数只是个预估值，最后实际显示的段数会在这个基础上根据分割后坐标轴刻度显示的易读程度作调整。仅作用于连续类型axis
        intervalLength: undefined, // {number} 坐标轴间隔宽度，仅作用于band或point类型axis，当设置interval后无效，若不设置将根据第一个标签宽度进行自适应间隔
        interval: undefined, // {number} 坐标轴标签间隔个数
        tickValues: undefined, // {Function|Array} 自定义要显示的标签
        domainEqualZero: [-1, 1], // {Array} 当domain为[0, 0]时设置的doamin
        space: 0, // {number|string|Array} // 根据space对doamin进行放大，仅适用于linear轴，优先级高于domainScale，可以使用数字对应或者百分比字符串，可以使用数组，space[0]对应doamin[0]，space[1]对应domain[1]; 注意如果行情分时图中设置了space，需要开启symmetry
        addValues: undefined, // {Array} 额外补充进domain计算的值

        compare: false, // {string|boolean} 是否以第一个值为基准，以比较的方式进行展示，设置为percent则以百分比形式展示，stack不支持compare

        alwaysShowLast: false, // {boolean} 总是显示最后一个标签
        alwaysShowFirst: false, // {boolean} 总是显示第一个标签

        findLastIndex: undefined, // {Function} 获取最后一条数据时，自定义找到最后一个index的方法

        symmetry: false, // {boolean} 是否以0轴对称
        sliceData: true, // {boolean} 适用于y轴，当x轴为连续型轴是否对数据进行裁剪，再进行值域计算，主要用于散点图中
        filterHide: true, // {boolean} 是否将隐藏的series过滤后再进行值域计算，主要用于散点图中

        ifTriggerMove: undefined, // {function} 是否触发悬停, 常用于行情配置

        logBase: 10, // {number} 对数轴(log)的底数

        // 坐标轴线
        line: {
            show: true, // {boolean} 是否显示坐标轴线， true | false
            // 坐标轴线样式
            style: {
                stroke: 'rgba(51, 51, 51, 0.1)',
                lineWidth: 2
            },
            onZero: false, // {boolean} X 轴或者 Y 轴的轴线是否在另一个轴的 0 刻度上
            onZeroAxisIndex: 1 // {number} 在哪个轴的 0 刻度上
        },

        // 坐标轴刻度相关设置
        tick: {
            show: true, // {boolean} 是否显示坐标轴刻度， true | false
            outerSize: 6, // {number} 刻度朝外长度
            innerSize: 0, // {number} 刻度朝内长度
            onCenter: true, // {boolean} 刻度位于每一段的中间
            // 刻度样式
            style: {
                lineWidth: 1,
                stroke: 'rgba(51, 51, 51, 0.1)'
            }
        },

        // 坐标轴标签的相关设置
        label: {
            show: true, // {boolean} 是否显示坐标轴标签， true| false
            inRange: false, // {boolean} 是否在range的范围内显示
            padding: 10, // {number} 标签离轴线的距离
            // 标签样式
            style: {
                fill: 'rgba(51, 51, 51, 0.4)',
                rich: {
                    Y: {},
                    m: {},
                    b: {},
                    d: {},
                    a: {},
                    I: {},
                    p: {}
                }
            },
            inside: false, // {boolean} 标签是否位于grid内
            truncate: false, // {boolean} 标签文字自动截取省略
            followLine: false, // {boolean} 标签位置是否跟随轴线
            rotate: 0, // {number} 标签旋转的角度，在类目轴的类目标签显示不下的时候可以通过旋转防止标签之间重叠。 旋转的角度从 -90 度到 90 度
            formatter: undefined // {Function} 标签的内容格式化函数, value, i, totalNum
        },

        // 坐标轴在 grid 区域中的分隔线。
        splitLine: {
            show: false, // {boolean} 是否显示分隔线。
            // {object|function}分隔线样式
            style: {
                color: 'rgba(51, 51, 51, 0.1)',
                lineWidth: 1
            }
        },

        // 坐标轴在 grid 区域中的分隔区域。
        splitArea: {
            show: false, // {boolean} 是否显示分隔区域。
            onRect: false, // {boolean} 是否只显示bar范围区域，仅适用于bar类型图
            // 分隔区域的样式设置
            style: {
                color: ['rgba(51, 51, 51, 0.05)', 'rgba(0, 0, 0, 0)']
            }
        },

        // 坐标轴名称。
        axisName: {
            show: false, // {boolean} 是否显示坐标轴名称
            text: '', // {string} 名称
            offset: [0, 0], // {Array} 名称偏移
            location: 'end', // {string} 显示在坐标轴得位置 'start' | 'center' | 'end'
            style: {}
        },

        cursor: 'default', // {string} 鼠标悬浮时在图形元素上时鼠标的样式是什么。同 CSS 的 cursor。
        zlevel: 0, // {number} 所有图形的 zlevel 值
        z: 0 // {number} 组件的所有图形的z值。控制图形的前后顺序。z值小的图形会被z值大的图形覆盖。
    };

    formatOption(option) {
        super.formatOption(...arguments);

        if (option.color) {
            setObjectKV(option, 'axisName.style.fill', option.color);
            setObjectKV(option, 'label.style.fill', option.color);
            setObjectKV(option, 'tick.style.stroke', option.color);
            setObjectKV(option, 'line.style.stroke', option.color);
        }
    }

    // shouldModelDirty(action, model, globalModel) {
    //     let { type: actionType, payload } = action;
    //     let { _option: { type }, index } = this;

    //     switch (actionType) {
    //         case 'DEPENDENT_WILL_UPDATE':
    //             let dependent = payload.dependent;

    //             switch (dependent.type) {
    //                 case 'dataZoom':
    //                     switch (type) {
    //                         case 'point':
    //                         case 'band':
    //                             let data = this.getData();
    //                             let dataLen = data.length;
    //                             let startIndex = Math.round(dataLen * dependent.start);
    //                             let endIndex = Math.round(dataLen * dependent.end);

    //                             if (startIndex === this.startIndex && endIndex === this.endIndex) {
    //                                 return false;
    //                             }

    //                             break;
    //                     }
    //                 default:
    //                     break;
    //             }

    //             break;
    //         default:
    //             break;
    //     }

    //     return true;
    // }

    getScale() {
        let type = this.get('type');
        let scale = this.scale;

        if (!scale) {
            this.scale = scale = scales[type]();
        }

        return scale;
    }

    /**
     *
     */
    updateBySelf() {
        let {
            _option: {
                type,
                position,
                splitNumber,
                $dataZoomIndex,
                xOrY,
                domainFormatter,
                start,
                end,
                nice,
                min,
                max,
                fitMin,
                fitMax,
                alwaysShowFirst,
                alwaysShowLast,
                intervalLength,
                interval,
                tickValues,
                filterHide,
                domainScale,
                offset,
                domainEqualZero,
                space
            },
            globalModel,
            global
        } = this;
        let seriesModels = globalModel.getSeriesByAxis(this.index, filterHide);
        let axis = this.axis,
            scale = this.getScale(),
            seriesExtents = [],
            range,
            domain = [];
        let len;
        let dataZoomModel = globalModel.getComponentByIndex(
            'dataZoom',
            $dataZoomIndex
        ),
            gridModel = this.getGridModels()[0]; // 若是数组取第一个$gridIndex作为画的位置
        let { left, top, bottom, right, width, height } = gridModel.position;
        let labelOpt = this.get('label');
        let labelStyle = labelOpt.style;
        let labelScale = labelOpt.scale;
        let globalWidth = global.getWidth();
        let globalHeight = global.getHeight();
        let pos = gridModel.position[position];

        // 根据position计算range,pos，以及初始化axis
        switch (position) {
            case 'top':
                axis = axis || axisTop();
                range = [left, right];
                offset = parsePercent(offset, globalHeight);
                pos -= offset;
                break;
            case 'bottom':
                axis = axis || axisBottom();
                range = [left, right];
                offset = parsePercent(offset, globalHeight);
                pos += offset;
                break;
            case 'left':
                axis = axis || axisLeft();
                range = [bottom, top];
                offset = parsePercent(offset, globalWidth);
                pos -= offset;
                break;
            case 'right':
                axis = axis || axisRight();
                range = [bottom, top];
                offset = parsePercent(offset, globalWidth);
                pos += offset;
                break;
        }

        // 若有dataZoom，则取dataZoom的start和end，否则取自己的start和end
        if (dataZoomModel) {
            start = dataZoomModel.start;
            end = dataZoomModel.end;

            let minSpan = dataZoomModel.minSpan;

            if (this.getAxisType() === 'ordinal' && minSpan > 1) {
                let minValueSpan = dataZoomModel.get('minValueSpan');
                let data = this.getData();

                if (minValueSpan && data) {
                    let dataLen = data.length;

                    range = [range[0], (range[1] - range[0]) / minSpan + range[0]];
                }
            }
        } else {
            start = parsePercent(start);
            end = parsePercent(end);
        }

        // 设置range
        scale.range(range);

        // 获取domain
        domain = this.getOriginDomain();

        switch (this.getAxisType()) {
            // 当series只有一个轴为continuous类型时，则该轴必须是y轴
            case 'continuous':
                if (type === 'linear') {
                    // 当domain为[0, 0]时，处理domain
                    if (domainEqualZero && domain[0] === 0 && domain[1] === 0) {
                        domain = domainEqualZero;
                        domainScale = 0;
                        space = 0;
                    }

                    // 取min和max设置domain
                    domain = [
                        isNaN(min) ? domain[0] : min,
                        isNaN(max) ? domain[1] : max
                    ];

                    // 对domain作正负对称处理
                    if (this.get('symmetry')) {
                        let _min = Math.abs(Math.min.apply(null, domain));
                        let _max = Math.abs(Math.max.apply(null, domain));
                        let delta = Math.max(_min, _max);

                        domain = [-delta, delta];
                    }

                    // 根据space重新设置domain，放大至space空间对应的doamin
                    if (space) {
                        let space0, space1;

                        if (isArray(space)) {
                            if (space.length > 1) {
                                space0 = space[0];
                                space1 = space[1];
                            } else {
                                space0 = space1 = space[0];
                            }
                        } else {
                            space0 = space1 = space;
                        }

                        space0 = parsePercent(space0, position === 'top' || position === 'bottom' ? width : height);
                        space1 = parsePercent(space1, position === 'top' || position === 'bottom' ? width : height);

                        let deltaRange = Math.abs(range[0] - range[1]);
                        let deltaDomain = deltaRange / (deltaRange - space0 - space1) * (domain[1] - domain[0]);

                        domain[0] = domain[0] - (space0 / deltaRange * deltaDomain);
                        domain[1] = domain[1] + (space1 / deltaRange * deltaDomain);
                    } else if (domainScale) { // 根据domainScale对domain进行放大
                        let deltaDomain = (domain[1] - domain[0]) * (domainScale - 1) / 2;

                        if(!deltaDomain) { deltaDomain = domain[0] * (domainScale - 1) }

                        if (isNaN(min)) {
                            if (domain[0] >= 0) {
                                if (domain[0] - deltaDomain > 0) {
                                    domain[0] -= deltaDomain;
                                } else {
                                    domain[0] = 0;
                                }
                            } else {
                                domain[0] -= deltaDomain;
                            }
                        }

                        if (isNaN(max)) {
                            if (domain[1] <= 0) {
                                if (domain[1] + deltaDomain < 0) {
                                    domain[1] += deltaDomain;
                                } else {
                                    domain[1] = 0;
                                }
                            } else {
                                domain[1] += deltaDomain;
                            }
                        }
                    }

                    if (domain[0]>domain[1]) {domain.reverse()}

                }

                // 使用domainFormatter处理domain
                domainFormatter && (domain = domainFormatter(domain));

                // 使用fitMax和fitMin处理domain
                domain = [
                    isNaN(fitMin) ? domain[0] : (domain[0] < fitMin ? fitMin : domain[0]),
                    isNaN(fitMax) ? domain[1] : (domain[1] > fitMax ? fitMax : domain[1])
                ];

                // 根据start和end设置domain
                let delta = domain[1] - domain[0];

                if (type !== 'time') {
                    end < 1 && (domain[1] = domain[0] + delta * end);
                    start > 0 && (domain[0] = domain[0] + delta * start);
                } else {
                    domain[1] = new Date(+domain[0] + delta * end);
                    domain[0] = new Date(+domain[0] + delta * start);
                }

                break;
            case 'ordinal':
                // 设置scale的padding
                if (type === 'band') {
                    scale
                        .paddingInner(parsePercent(this.get('paddingInner')))
                        .paddingOuter(parsePercent(this.get('paddingOuter')));
                } else if (type === 'point') {
                    scale
                        .padding(parsePercent(this.get('padding')))
                }

                // 根据start和end设置domain
                let dataLen = domain.length;
                let startIndex = Math.round((dataLen - 1) * start);
                let endIndex = Math.round((dataLen - 1) * end);

                this.startIndex = startIndex;
                this.endIndex = endIndex;
                domain = domain.slice(startIndex, endIndex + 1);

                break;
            default:
                break;
        }

        // 设置domain
        scale.domain(domain);

        // 设置axis的其他参数
        axis.axisLine(this.get('line'));
        axis.axisTick(this.get('tick'));
        axis.axisLabel(this.get('label'));
        axis.scale(scale)
            .pos(pos)
            .alwaysShowFirst(alwaysShowFirst)
            .alwaysShowLast(alwaysShowLast);

        // 自定义tickValues
        if (tickValues) {
            let _tickValues;

            if (isFunction(tickValues)) {
                _tickValues = tickValues(domain);
            } else {
                _tickValues = tickValues;
            }

            // 根据domain进行过滤
            if (this.getAxisType() === 'ordinal') {
                _tickValues = filter(_tickValues, function (v) {
                    return domain.indexOf(v) > -1;
                });
            } else {
                _tickValues = filter(_tickValues, function (v) {
                    return v >= domain[0] && v <= domain[1];
                });
            }

            axis.tickValues(_tickValues);
        }

        // 对不同类型axis、scale的特殊处理
        switch (type) {
            case 'point':
            case 'band':
                // 若未自定义tickValues
                if (!tickValues) {
                    let values = [];

                    len = domain.length;

                    // 未定义interval
                    if (!interval) {
                        // 未定义intervalLength，则自适应计算
                        if (!intervalLength || intervalLength <= 0) {
                            let longest = domain[0];

                            for (let i = 1; i < domain.length; i++) {
                                if (longest.length < domain[i].length) {
                                    longest = domain[i];
                                }
                            }

                            intervalLength = this.getLabelLen(longest);

                            intervalLength *= 1.2;
                        }

                        interval = Math.ceil(
                            len / (Math.abs(range[1] - range[0]) / intervalLength)
                        );
                    }

                    // 生成tickValues
                    for (var i = 0; i < len; i += interval) {
                        values.push(domain[i]);
                    }

                    // 总是显示最后一个tick
                    if (alwaysShowLast) {
                        let last = domain[len - 1];
                        let lastValue = values[values.length - 1];
                        let gap =
                            (this.getLabelLen(last) + this.getLabelLen(lastValue)) / 2;

                        gap *= 1.5;

                        if (scale(last) - scale(lastValue) < gap) {
                            values.pop();
                        }

                        values.push(last);
                    }

                    axis.tickValues(values);
                    this.interval = interval;
                }
                break;
            case 'linear':
                if (!tickValues) {
                    // nice && scale.nice();
                    // domainFormatter && scale.domain(domainFormatter(scale.domain()));
                    splitNumber && axis.ticks(splitNumber);

                    // nice处理，对tick的第一个值和最后一个值做处理
                    if (nice) {
                        let ticks = scale.ticks(splitNumber);

                        if (ticks.length < 2) {
                            if(ticks.length !== 1){
                                domain = [];
                                ticks = [];
                            }
                        } else {
                            if (ticks[0] > domain[0]) {
                                domain[0] = NP.minus(
                                    ticks[0],
                                    NP.minus(ticks[1], ticks[0])
                                );
                                ticks.unshift(domain[0]);
                            }

                            let tickLen = ticks.length;

                            if (ticks[tickLen - 1] < domain[1]) {
                                domain[1] = NP.plus(
                                    ticks[tickLen - 1],
                                    NP.minus(ticks[tickLen - 1], ticks[tickLen - 2])
                                );
                                ticks.push(domain[1]);
                            }
                        }

                        scale.domain(domain);
                        axis.tickValues(ticks);
                    }
                }
                break;
            case 'time':
            case 'log':
                splitNumber && axis.ticks(splitNumber);
                nice && scale.nice();
                break;
            default:
                break;
        }

        if (type === 'log') {
            scale.base(this.get('logBase'));
        }

        this.lastDomain = this.domain;
        this.domain = domain;
        this.range = range;
        this.pos = pos;
        this.lastStart = this.start;
        this.lastEnd = this.end;
        this.start = start;
        this.end = end;
        this.axis = axis;
        this.scale = scale;
        this.dataZoomIndex = $dataZoomIndex;
        this.barGap = parsePercent(this.get('barGap'));

        // 事件触发
        if (this.lastStart !== undefined && this.lastEnd !== undefined && (start !== this.lastStart || end !== this.lastEnd)) {
            global.dispatchAction(this, axisActions.zoomChange, {
                zoom: { start, end },
                model: this
            });
        }

        if (!this.lastDomain || this.lastDomain.length !== domain.length) {
            global.dispatchAction(this, axisActions.domainChange, {
                domain,
                model: this
            });
        } else {
            for (let i = 0; i < this.lastDomain.length; i++) {
                if (domain[i] !== this.lastDomain[i]) {
                    global.dispatchAction(this, axisActions.zoomChange, {
                        domain,
                        model: this
                    });
                    break;
                }
            }
        }
    }

    /**
     * 获取domain
     */
    getOriginDomain() {
        // if (this.originDomain) {
        //     return this.originDomain;
        // }
        let axisModel = this;
        let { globalModel } = this;

        if (this.get('relyOtherAxis')) {
            let axisIndex = this.get('$axisIndex');

            axisModel = globalModel.getComponentByIndex('axis', axisIndex) || axisModel;
        }
        let {
            _option: {
                filterHide,
                addValues
            }
        } = axisModel;
        let seriesModels = globalModel.getSeriesByAxis(axisModel.index, filterHide);
        let seriesExtents = [],
            domain = [];

        // 计算domain，初始化scale
        switch (axisModel.getAxisType()) {
            // 当series只有一个轴为continuous类型时，则该轴必须是y轴
            case 'continuous':
                each(seriesModels, model => {
                    let ext = getSeriesExtent(model, axisModel, globalModel);

                    ext && seriesExtents.push(ext);
                });

                domain[0] = arrayMin(seriesExtents, function (seriesExtent) {
                    return seriesExtent[0];
                });
                domain[1] = arrayMax(seriesExtents, function (seriesExtent) {
                    return seriesExtent[1];
                });

                if (isArray(addValues)) {
                    domain[0] = Math.min(domain[0], ...addValues);
                    domain[1] = Math.max(domain[1], ...addValues);
                }

                break;
            case 'ordinal':
                domain = axisModel.getData() || [];

                break;
            default:
                break;
        }

        axisModel.originDomain = domain;

        return domain;
    }

    /**
     * 根据其他axisModel进行更新
     * @param {Object} axisModel 依赖的axisModel
     */
    updateByOther(axisModel) {
        let {
            _option: {
                position,
                splitNumber,
                $dataZoomIndex,
                start,
                end,
                nice,
                min,
                max,
                fitMin,
                fitMax,
                alwaysShowFirst,
                alwaysShowLast,
                tickValues,
                offset
            },
            global
        } = this;
        let axis = this.axis,
            scale = this.getScale(),
            domain = axisModel.domain;
        let gridModel = this.getGridModels()[0]; // 若是数组取第一个$gridIndex作为画的位置
        let { left, top, bottom, right, width, height } = gridModel.position;
        let labelOpt = this.get('label');
        let globalWidth = global.getWidth();
        let globalHeight = global.getHeight();
        let type = axisModel.get('type');

        this.startIndex = axisModel.startIndex;
        this.endIndex = axisModel.endIndex;

        let pos = gridModel.position[position];

        scale.domain(domain);

        // 根据position计算range,pos，以及初始化axis
        switch (position) {
            case 'top':
                axis = axis || axisTop();
                scale.range([left, right]);
                offset = parsePercent(offset, globalHeight);
                pos -= offset;
                break;
            case 'bottom':
                axis = axis || axisBottom();
                scale.range([left, right]);
                offset = parsePercent(offset, globalHeight);
                pos += offset;
                break;
            case 'left':
                axis = axis || axisLeft();
                scale.range([bottom, top]);
                offset = parsePercent(offset, globalWidth);
                pos -= offset;
                break;
            case 'right':
                axis = axis || axisRight();
                scale.range([bottom, top]);
                offset = parsePercent(offset, globalWidth);
                pos += offset;
                break;
        }

        // 设置axis的其他参数
        axis.axisLine(this.get('line'));
        axis.axisTick(this.get('tick'));
        axis.axisLabel(this.get('label'));
        axis
            .scale(scale)
            .pos(pos)
            .alwaysShowFirst(alwaysShowFirst)
            .alwaysShowLast(alwaysShowLast);

        tickValues = axisModel.axis.tickValues();

        // 对不同类型axis、scale的特殊处理
        switch (type) {
            case 'point':
            case 'band':
                axis.tickValues(tickValues);
                this.interval = axisModel.interval;
                break;
            case 'linear':
                if (tickValues) {
                    axis.tickValues(tickValues);
                } else {
                    splitNumber && axis.ticks(splitNumber);
                }
                break;
            case 'time':
            case 'log':
                splitNumber && axis.ticks(splitNumber);
                nice && scale.nice();
                break;
            default:
                break;
        }

        if (type === 'log') {
            scale.base(this.get('logBase'));
        }

        this.lastDomain = this.domain;
        this.domain = domain;
        this.range = scale.range();
        this.pos = pos;
        this.lastStart = this.start;
        this.lastEnd = this.end;
        this.start = start;
        this.end = end;
        this.axis = axis;
        this.scale = scale;
        this.dataZoomIndex = $dataZoomIndex;
        this.barGap = parsePercent(this.get('barGap'));
    }

    update() {
        if (this.get('relyOtherAxis')) {
            let axisIndex = this.get('$axisIndex');
            let { globalModel } = this;
            let axisModel = globalModel.getComponentByIndex('axis', axisIndex);

            // 有依赖其他坐标轴 更新
            axisModel && this.updateByOther(axisModel);
        } else {

            // 没有依赖其他坐标轴 更新
            this.updateBySelf();
        }
    }

    updateData() {
        super.updateData(...arguments);

        this.originDomain = undefined;
    }

    /**
     * 仅band或point
     *
     * @param {number} offsetX
     * @param {number} offsetY
     * @returns {array} (x, y)点对应的数据
     *
     * @memberof AxisModel
     */
    getDataByOffset(offsetX, offsetY) {
        let { startIndex } = this;
        let dataIndex = this.getIndexByOffset(offsetX, offsetY) + startIndex;

        return this.getDataByIndex(dataIndex);
    }

    // 通过index，获取触发axis_move 所需要的data
    getMoveDataByIndex(index, lastIndex){
        let axisData = this.getData(index + this.startIndex);
        let data = {
            axis: {
                dataIndex: index + this.startIndex,
                data: axisData,
                startIndex: this.startIndex,
                axisIndex: index
            },
            series: this.getDataByIndex(
                index + this.startIndex
            ),
            allSeries: this.getDataByIndex(
                index + this.startIndex,
                false
            )
        };

        let event = {
            e: {offsetX: this.scale(axisData), offsetY: 1},
            lastIndex: lastIndex,
            index,
            dataIndex: index + this.startIndex,
            axisData,
            data,
            pos:
                this.scale(axisData) +
                (this.scale.bandwidth
                    ? this.scale.bandwidth() / 2
                    : 0)
        };

        return event;
    }

    /**
     * 通过index获取data
     */
    getDataByIndex(dataIndex, filterHide = true) {
        let { globalModel, index, scale, startIndex } = this;
        let seriesModels = globalModel.getSeriesByAxis(index, filterHide);
        let xScale = this.scale;
        let xDomain = xScale.domain();
        let result = [];

        if (
            seriesModels &&
            seriesModels.length &&
            this.get('xOrY') === 'x' &&
            this.getAxisType() === 'ordinal'
        ) {
            each(seriesModels, seriesModel => {
                let yAxisModel = seriesModel.getAxisModel('y');
                let yScale = yAxisModel && yAxisModel.scale;
                let yDomain = yScale && yScale.domain();
                let findedIndex = findAllIndex(seriesModel._stackByPercent ? seriesModel.stackData : seriesModel.getRealData(), function (
                    d,
                    i
                ) {
                    if (!d) return false;

                    if (!yAxisModel) { // 只有x轴
                        return d[0] === dataIndex;
                    } else if (yAxisModel.getAxisType() === 'ordinal') { // y轴为ordinal类型
                        return (
                            d[0] === dataIndex &&
                            d[1] >= yAxisModel.startIndex &&
                            d[1] <= yAxisModel.endIndex
                        );
                    } else { // y轴为其他类型
                        if (seriesModel._stackByPercent) {
                            return d[0] === dataIndex && isInDomain(d[1][0], yDomain) && isInDomain(d[1][1], yDomain);
                        } else {
                            return d[0] === dataIndex && isInDomain(d[1], yDomain);
                        }
                    }
                });

                each(findedIndex, function (index) {
                    let value = seriesModel._stackByPercent ? seriesModel.stackData[index] : seriesModel.getRealData(index);
                    let _value = seriesModel._stackByPercent ? [value[0], value[3]] : value;
                    let y = yScale && (seriesModel._stackByPercent ? yScale(value[1][1]) : yScale(value[1]));

                    result.push({
                        name: seriesModel.get('name'),
                        value: _value,
                        color: seriesModel.getSeriesColor(),
                        seriesIndex: seriesModel.index,
                        type: seriesModel.type,
                        dataIndex: index,
                        x: xScale(xDomain[value[0] - startIndex]),
                        y,
                        originValue: value
                    });
                });
            });
        }

        return result;
    }

    /**
     * 仅band或point
     *
     * @param {any} offsetX
     * @param {any} offsetY
     * @returns {number} (x, y)对应的index
     *
     * @memberof AxisModel
     */
    getIndexByOffset(offsetX, offsetY) {
        let { scale } = this;
        let position = this.get('position');
        let offset =
            position === 'top' || position === 'bottom' ? offsetX : offsetY;

        if (position === 'top' || position === 'bottom') {
            offset = offsetX - scale.range()[0];
        } else {
            offset = scale.range()[0] - offsetY;
        }

        let index = Math.min(
            Math[this.get('type') === 'band' ? 'floor' : 'round'](
                (offset -
                    (scale.paddingOuter
                        ? (scale.paddingOuter() - scale.paddingInner() / 2) *
                        scale.step()
                        : 0)) /
                scale.step()
            ),
            scale.domain().length - 1
        );

        return index > -1 ? index : 0;
    }

    /**
     * 获取依赖的grid
     */
    getGridModels() {
        let gridIndex = this.get('$gridIndex');
        let gridModels = [];

        if (!isArray(gridIndex)) {
            gridIndex = [gridIndex];
        }

        each(gridIndex, index => {
            gridModels.push(
                this.globalModel.getComponentByIndex('grid', index)
            );
        });

        return gridModels;
    }

    /**
     * 获取scale的类型
     *
     * @returns {string} 返回scale的类型
     * @memberof AxisModel
     */
    getAxisType() {
        let type = this.get('type');

        switch (type) {
            case 'linear':
            case 'time':
            case 'log':
                return 'continuous';
            case 'band':
            case 'point':
                return 'ordinal';
        }
    }

    setZoom(zoom) {
        let dataZoomModel = this.globalModel.getComponentByIndex(
            'dataZoom',
            this.get('$dataZoomIndex')
        );

        if (dataZoomModel) {
            dataZoomModel.set(zoom);
            this.global.dispatchAction(dataZoomModel, dataZoomActions.change, {
                zoom
            });
        } else {
            this.set(zoom);
        }
    }

    /**
     * 计算文本长度
     */
    getLabelLen(text, wOrH) {
        let position = this.get('position');
        let labelOpt = this.get('label');
        let labelStyle = labelOpt.style;
        let labelScale = labelOpt.scale;
        let scale = this.getScale();
        let labelFormat = labelOpt.formatter || (scale.tickFormat && scale.tickFormat.apply(scale));
        let labelText = new Text({
            style: {
                text: labelFormat ? labelFormat(text) : text,
                ...labelStyle
            }
        });
        let rect = labelText.getBoundingRect();
        let textWidth;

        if (!wOrH) {
            wOrH = position === 'top' || position === 'bottom'
                ? 'width'
                : 'height';

        }

        if (labelOpt.rotate) {
            let ang = labelOpt.rotate / 180 * Math.PI;

            if (wOrH === 'width') {
                // textWidth = Math.abs(rect.width * Math.cos(ang)) + Math.abs(rect.height * Math.sin(ang));
                textWidth = Math.min(Math.abs(rect.height / Math.sin(ang)), rect.width);
            } else {
                // textWidth = Math.abs(rect.height * Math.cos(ang)) + Math.abs(rect.width * Math.sin(ang));
                textWidth = Math.min(Math.abs(rect.height / Math.cos(ang)), rect.width);
            }
        } else {
            textWidth =
                rect[wOrH] *
                (labelScale ? labelScale[wOrH === 'width' ? 0 : 1] : 1);
        }

        return textWidth || 0;
    }

    /**
     * 获取最后一条数据
     *
     * @returns {Object}
     * @memberof AxisModel
     */
    getLastData() {
        let lastIndex = this.endIndex;
        let findLastIndex = this.get('findLastIndex');

        if (findLastIndex) {
            lastIndex = findLastIndex(this.getData(), this.startIndex, this.endIndex);
        }

        let axisData = this.getData(lastIndex);

        return {
            axis: {
                dataIndex: this.endIndex,
                data: axisData,
                startIndex: this.startIndex,
                axisIndex: this.endIndex - this.startIndex
            },
            series: this.getDataByIndex(
                lastIndex
            ),
            allSeries: this.getDataByIndex(
                lastIndex,
                false
            )
        };
    }
}

/**
 * 根据x轴的domain，截取数据
 *
 * @param {any} data
 * @param {any} seriesModel
 * @returns {array}
 */
function getSlicedData(data, seriesModel) {
    let xAxisModel = seriesModel.getAxisModel('x');
    let { start, end } = xAxisModel;
    let xAxisType = xAxisModel.getAxisType();

    if (start === undefined || end === undefined) return data;

    if (xAxisType === 'ordinal') {
        let dataLen = xAxisModel.getData().length;
        let startIndex = Math.round((dataLen - 1) * start);
        let endIndex = Math.round((dataLen - 1) * end);

        return filter(data, function (d) {
            if (d) {
                return d[0] >= startIndex && d[0] <= endIndex;
            }
        });
    } else {
        let xDomain = xAxisModel.scale.domain();
        let isTime = xAxisModel.get('type') === 'time';

        return filter(data, function (d) {
            if (d) {
                let dx = d[0];

                if (isArray(dx)) {
                    if (dx[0] > xDomain[1] || dx[1] < xDomain[0]) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    isTime && (dx = new Date(dx));
                    return dx >= xDomain[0] && dx <= xDomain[1];
                }
            }
        });
    }
}

/**
 * 获取series的极值
 *
 * @param {any} seriesModel
 * @param {any} axisModel
 * @param {any} globalModel
 * @returns {array|boolean}
 */
function getSeriesExtent(seriesModel, axisModel, globalModel) {
    if (seriesModel.getData()) {
        let min = getSeriesMaxOrMin(
            seriesModel,
            axisModel,
            arrayMin,
            globalModel
        );
        let max = getSeriesMaxOrMin(
            seriesModel,
            axisModel,
            arrayMax,
            globalModel
        );
        let onZero = seriesModel.get('onZero');

        if (!~['time', 'log'].indexOf(axisModel.get('type')) && onZero) {
            if (max < 0) {
                max = 0;
            } else if (min > 0) {
                min = 0;
            }
        }

        return [min, max];
    } else {
        return false;
    }
}

/**
 * 根据所给的func获取series的最大值或最小值
 *
 * @param {any} seriesModel
 * @param {any} axisModel
 * @param {any} func 获取最大值或最小值的方法
 * @param {any} globalModel
 * @returns {function}
 */
function getSeriesMaxOrMin(seriesModel, axisModel, func, globalModel) {
    if (axisModel.get('xOrY') === 'x') {
        let axisIsTime = axisModel.get('type') === 'time';
        let data = seriesModel.getData();

        if (!data || !data.length) return;

        // 假设数据格式统一，以第一个数据判断格式
        let _func,
            d0x;

        for (let i = 0; i < data.length; i++) {
            if (data[i]) {
                d0x = data[i][0];
                break;
            }
        }

        if (isArray(d0x)) {
            _func = func;
        } else if (axisIsTime) {
            _func = dx => new Date(dx);
        } else {
            _func = dx => dx;
        }

        return func(data, (d) => {
            if (d) {
                return _func(d[0]);
            }
        });
    } else {
        let data = seriesModel.get('stack')
            ? seriesModel.stackData
            : seriesModel.getData();

        data = axisModel.get('sliceData')
            ? getSlicedData(data, seriesModel)
            : data;

        if (!data || !data.length) return;

        // 假设数据格式统一，以第一个数据判断格式
        let _func,
            d0y;

        for (let i = 0; i < data.length; i++) {
            if (data[i]) {
                d0y = data[i][1];
                break;
            }
        }

        if (isArray(d0y)) {
            _func = func;
        } else if (isObject(d0y)) {
            _func = function (dy) {
                let mm; // max or min

                if (func === arrayMin) {
                    mm = Infinity;
                    each(dy, value => mm > value && (mm = value));
                } else {
                    mm = -Infinity;
                    each(dy, value => mm < value && (mm = value));
                }

                return mm;
            };
        } else {
            _func = dy => dy;
        }

        // stack不支持compare
        let compare = !seriesModel.get('stack') && axisModel.get('compare');

        return func(data, function (d) {
            if (d) {
                let dy = d[1];

                if (typeof dy === 'number' || isArray(dy)) {
                    if (compare) {
                        if (compare === 'percent') {
                            dy = (dy - d0y) / d0y;
                        } else {
                            dy -= d0y
                        }
                    }
                    return _func(dy);
                } else {
                    return null;
                }
            }
        });
    }
}

/**
 * 判断值是否在domain内
 *
 * @param {any} d 值
 * @param {any} domain
 * @returns {boolean}
 */
function isInDomain(d, domain) {
    if (isArray(d)) {
        for (let i = 0; i < d.length; i++) {
            if (d[i] < domain[0] || d[i] > domain[1]) {
                return false;
            }
        }
    } else {
        if (d < domain[0] || d > domain[1]) {
            return false;
        }
    }

    return true;
}
