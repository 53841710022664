import Arc from '../../shape/D3Arc';
import Pie from 'zrender/src/graphic/shape/Circle';
import Text from 'zrender/src/graphic/Text';
import { Symbol } from '../../util/symbol';
import ChartView from '../../view/Chart';
import { scaleLinear } from 'd3-scale';

const TYPE = 'arc';

export default class ArcView extends ChartView {

    static type = TYPE;

    type = TYPE;

    getPoint(c1, c2, radius, angle){
        let cos = Math.cos;
        let sin = Math.sin;
        return [c1 + cos(angle) * radius, c2 + sin(angle) * radius]
    }

    getAttrs(cx, cy, circleStyle, endAngle, symbolType){
        let cx0 = cx
        let cy0 = cy
        let earc = !circleStyle.symbolStyle.angleWithItem ? 2*Math.PI : endAngle;
        let sarc = 0
        let edgePadding = circleStyle.symbolStyle.edgePadding
        let r0 = circleStyle.outerRadius
        let n = earc / 4 / Math.PI * 360 / edgePadding
        let attrs = [];

        for (var i = 0; i < n; i++) {
            var ii0 = this.getPoint(cx0, cy0, r0, earc / n * i + sarc - (Math.PI/2))

            attrs.push({
                shape: {
                    symbolType: symbolType,
                    x: ii0[0]-circleStyle.symbolStyle.radius,
                    y: ii0[1]-circleStyle.symbolStyle.radius,
                    width:circleStyle.symbolStyle.radius*2,
                    height:circleStyle.symbolStyle.radius*2
                },
                cursor: 'default',
                style:{
                    stroke: circleStyle.symbolStyle.stroke,
                    fill: circleStyle.symbolStyle.fill
                }
            });
        }

        return attrs;
    }

    render(model, globalModel, global) {
        this.remove();

        let group = this.group;
        let data = model.getData();
        if (!data) { return; }
        let z = model.get('z');
        let zlevel = model.get('zlevel');
        let arcStyle = model.get('itemStyle');
        let circleStyle = model.get('circleStyle');
        let startArcStyle = model.get('startArc');
        let endArcStyle = model.get('endArc');

        let arcCenter = model.get('center');
        let arcRadius = model.get('radius');

        let canvasW = global.getWidth();
        let canvasH = global.getHeight();

        let cx = typeof arcCenter[0] === 'string' ? parseInt(parseFloat(arcCenter[0].split('%')[0])/100*canvasW) : parseInt(arcCenter[0]);
        let cy = typeof arcCenter[1] === 'string' ? parseInt(parseFloat(arcCenter[1].split('%')[0])/100*canvasH) : parseInt(arcCenter[1]);

        let innerRadius = typeof arcRadius[0] === 'string' ? parseInt(parseFloat(arcRadius[0].split('%')[0])/100*canvasW) : parseInt(arcRadius[0]);
        let outerRadius = typeof arcRadius[1] === 'string' ? parseInt(parseFloat(arcRadius[1].split('%')[0])/100*canvasH) : parseInt(arcRadius[1]);

        let scaleAngle = scaleLinear().domain([0, 2 * Math.PI]).range([0, 360])
        let itemValue = data.values[0].value / (data.max - data.min);
        let startArcValue = startArcStyle.angleCross;
        let endArcValue = endArcStyle.angleCross;
        let startArcAngle = 0;
        let endArcAngle = 0;
        if (itemValue !== 0) {
            startArcAngle = scaleAngle.invert(startArcValue);
            endArcAngle = scaleAngle.invert(endArcValue);
        }
        let endAngle = (itemValue || itemValue == 0) ? itemValue * (2*Math.PI) : Math.PI;
        let animationStartEndAngle = model.get('animation') ? 0 : endAngle

        let arc = new Arc({
            shape: {
                innerRadius: innerRadius,
                outerRadius: outerRadius,
                startAngle: 0,
                endAngle: animationStartEndAngle,
                cornerRadius: arcStyle.normal.cornerRadius,
                data: data
            },
            position: [cx, cy],
            style: arcStyle.normal,
            cursor: 'default'
        });
        // 起始高亮弧度
        let arcStart
        if (startArcStyle.show && startArcStyle.angleCross !== 0) {
            arcStart = new Arc({
                shape: {
                    innerRadius: innerRadius,
                    outerRadius: outerRadius,
                    startAngle: 0,
                    endAngle: startArcAngle,
                    cornerRadius: startArcStyle.normal.cornerRadius,
                },
                position: [cx, cy],
                style: startArcStyle.normal,
                cursor: 'default'
            });
        }
        // 结束高亮弧度
        let arcEnd
        if (endArcStyle.show && endArcStyle.angleCross !== 0) {
            arcEnd = new Arc({
                shape: {
                    innerRadius: innerRadius,
                    outerRadius: outerRadius,
                    startAngle:  model.get('animation') ? 0 : endAngle,
                    endAngle: model.get('animation') ? 0 : endAngle + endArcAngle,
                    cornerRadius: endArcStyle.normal.cornerRadius,
                },
                position: [cx, cy],
                style: endArcStyle.normal,
                cursor: 'default'
            });

            if (model.get('animation')) {
                arcEnd.animateTo({
                    shape: {
                        startAngle: endAngle,
                        endAngle: endAngle + endArcAngle
                    }
                }, 800, 'cubicOut');
            }
        }

        if (model.get('animation')) {
            arc.animateTo({
                shape: {
                    endAngle: endAngle
                }
            }, 800, 'cubicOut');
        }


        if (circleStyle.show) {
            // 绘制外边
            // 线条
            if(circleStyle.outerType == 'line'){
                this.setShape('outline', Symbol, {
                    shape: {
                        symbolType: 'circle',
                        x: cx-circleStyle.outerRadius,
                        y: cy-circleStyle.outerRadius,
                        width: circleStyle.outerRadius*2,
                        height: circleStyle.outerRadius*2
                    },
                    cursor: 'default',
                    style:{
                        stroke: circleStyle.outerStrokeColor,
                        fill: circleStyle.outerFillColor
                    }
                })
            // 小圆点
            }else if(circleStyle.outerType == 'dotted'){
                let attrs = this.getAttrs(cx, cy, circleStyle, endAngle, 'circle');
                this.setShapeGroup('outline', Symbol, attrs);
            // 小方块
            }else{
                let attrs = this.getAttrs(cx, cy, circleStyle, endAngle, 'square');
                this.setShapeGroup('outline', Symbol, attrs);
            }

            // 绘制内边
            this.setShape('innerPie', Symbol, {
                shape: {
                    symbolType: 'circle',
                    x: cx-circleStyle.innerRadius,
                    y: cy-circleStyle.innerRadius,
                    width:circleStyle.innerRadius*2,
                    height:circleStyle.innerRadius*2
                },
                cursor: 'default',
                style:{
                    stroke: circleStyle.innerStrokeColor,
                    fill: circleStyle.innerFillColor
                }
            });
        }

        this.group.add(arc);
        this.group.add(arcStart);
        this.group.add(arcEnd);

        let itemFormatter = arcStyle.formatter;
        let textOffset = arcStyle.offsetCenter;
        let itemOffsetX = typeof textOffset[0] === 'string' ? parseFloat(textOffset[0].split('%')[0])/100*canvasW : textOffset[0];
        let itemOffsetY = typeof textOffset[1] === 'string' ? parseFloat(textOffset[1].split('%')[0])/100*canvasH : textOffset[1];

        let itemText = new Text({
            cursor: 'default',
            style: globalModel.getFormattedText({
                text: itemFormatter(data.values[0], itemValue),
                x: cx - itemOffsetX,
                y: cy - itemOffsetY,
                ...arcStyle.textStyle
            })
        });

        this.group.add(itemText);

        if (data.title) {
            let titleConfigs = model.get('title');
            let titleOffsetX = typeof titleConfigs.offsetCenter[0] === 'string' ? parseFloat(titleConfigs.offsetCenter[0].split('%')[0])/100*canvasW : titleConfigs.offsetCenter[0];
            let titleOffsetY = typeof titleConfigs.offsetCenter[1] === 'string' ? parseFloat(titleConfigs.offsetCenter[1].split('%')[0])/100*canvasH : titleConfigs.offsetCenter[1];

            let nameText = new Text({
                cursor: 'default',
                style: {
                    text: data.title,
                    x: cx - titleOffsetX,
                    y: cy - titleOffsetY,
                    font: titleConfigs.textStyle.fontWeight+' '+titleConfigs.textStyle.fontSize + 'px ' + titleConfigs.textStyle.fontFamily,
                    textBaseline: 'middle',
                    textAlign: 'center',
                    textFill: titleConfigs.textStyle.color
                }
            });

            this.group.add(nameText);
        }
    }
}
