/**
 * 标点
 */
import ComponentModel from '../../model/Component';
import { parsePercent } from '../../util';

export const TYPE = 'markPoint';

export default class MarkPointModel extends ComponentModel {

    static type = TYPE;

    type = TYPE;

    static defaultOption = {
        $axisIndex: undefined, // {number|Array<number>}依赖的axis
        $seriesIndex: undefined, // {Number} 若配置$seriesIndex则使用该series的axis

        show: true, // {Boolean} 是否显示
        onClick: undefined, // {Function} 点击事件
        enableSelect: false, // {Boolean} 是否开启选中

        symbol: { // 标记配置
            type: 'pin', // {String} 标记显示类型 
            size: [40, 45], // {Number Array} 标记大小
            offset: [0, 0], // {Number Array} 标记偏移
            style: { // 标记样式
                normal: {

                },
                emphasis: {

                }
            }
        },

        label: { // 标签配置
            normal: {
                show: false, // {Boolean} normal状态是否显示标签 
                style: {
                    position: 'top' // {String|Function} 标签显示位置
                }
            },
            emphasis: {
                show: false, // {Boolean} emphasis状态是否显示标签
                style: {
                    
                }
            }
        },

        cursor: 'pointer',
        zlevel: 0,
        z: 10
    };
}