/**
 * 折线图 by wjw
 */
import ScatterModel from '../scatter/ScatterModel';
import { each, map, isArray, setObjectKV, getObjectKV, parseSize } from '../../util';

export const TYPE = 'line';

/*
一个最简单的折线图的配置中需要包括一个grid、两个axis分别为x和y，以及series中的line，示例如下，可在官网中直接运行
组件详细配置项如下，每个配置项都有一个默认值：


*/

export default class LineModel extends ScatterModel {
    static type = TYPE;

    type = TYPE;

    isScatter = false;

    static emphasisList = ['symbol', 'link', 'label', 'line', 'area'];

    static defaultOption = {
        //data: undefined, // {Array} 系列中的数据内容数组。数组项通常为具体的数据项。数据项格式同echarts类似，http://echarts.baidu.com/option.html#series-line.data
        $dataIndex: undefined, // {number} 依赖的$data的index，格式同上，优先级低于data，取得的数据需符合data的格式
        dataKey: undefined, // {string|number} 搭配$dataIndex使用，方便从依赖的$data中取数据，等同于 data.map((d) => d[dataKey])，最终取得的数据需符合data的格式
        $axisIndex: [0, 1], // {Array} 所依赖axis的index，需包括一个x轴和y轴
        name: undefined, // {string} 系列名称，用于tooltip的显示，legend 的图例筛选

        pieces: undefined, // {Array} 折线颜色分段 [{ min: 0, color: 'red' }, { max: 0, color: 'green' }], http://echarts.baidu.com/option.html#visualMap-piecewise.pieces

        aliasType: undefined, // {boolean} 用于统计区分 hqline 与 line

        // 折线配置
        line: {
            normal: {
                show: true, // {boolean} 是否显示折线
                // {Object} 普通样式
                style: {
                    fill: undefined,
                    stroke: '#058499',
                    lineWidth: 2
                }
            },
            emphasis: {}
        },

        // 区域填充配置
        area: {
            // 区域从底部开始画
            normal: {
                show: false, // {boolean} 是否显示面积
                snapToBottom: false, // {boolean} 吸附到底部
                // {Object} 面积样式
                style: {
                    fill: '#058499'
                }
            },
            emphasis: {}
        },

        // 拐点标记
        symbol: {
            normal: { // 普通状态
                show: false, // {boolean|string} 是否显示拐点标记， true 显示坐标轴对应点 | false 不显示 | 'all' 显示全部
                type: 'circle', // {string} symbol类型 'line' | 'rect' | 'roundRect' | 'square' | 'circle' | 'diamond' | 'pin' | 'arrow' | 'triangle' 或svg的path
                offset: [0, 0], // {Array} [x, y]，偏移位置
                size: 8, // {number} symbol大小
                // 普通样式
                style: {}
            },
            emphasis: { // 高亮状态
                show: false, // {boolean} 是否高亮显示
                // 高亮样式
                style: {}
            }
        },

        // 拐点与坐标轴连线
        link: {
            normal: { // 普通状态
                show: false, // {boolean|string} 是否显示点到0轴连线， true 显示坐标轴对应点 | false 不显示 | 'all' 显示全部
                // 普通样式
                style: {}
            },
            emphasis: { // 高亮状态
                show: false, // {boolean} 是否高亮显示
                // 高亮样式
                style: {}
            }
        },

        // 拐点上方的文本
        label: {
            normal: {
                // 普通状态
                show: false, // {boolean|string} 是否显示拐点上方值， true 显示坐标轴对应点 | false 不显示 | 'all' 显示全部
                // 普通样式
                style: {
                    position: 'top'
                },
                formatter: undefined // {Function} 文本格式化函数
            },
            emphasis: {
                show: false, // {boolean} 是否高亮显示
                // 高亮样式
                style: {}
            }
        },

        curve: {
            type: 'linear', // {string} curve类型，参考d3文档 https://github.com/xswei/d3-shape/blob/master/README.md#curves
            param: undefined // 根据type不同，param代表的参数也不同，参考d3文档
        },
      showRipple: false, // 开启最后一个标记点的提醒效果
        connectNulls: false, // {boolean} 是否连接空数据。
        legendHoverLink: true, // {boolean} 是否与图例进行高亮联动
        selectedList: [], // {Array} 选中项，使用数据下标指定
        enableSelect: false, // {boolean|string} 是否开启选中, single(true) | multiple | false

        cursor: 'default', // {string} 鼠标悬浮时在图形元素上时鼠标的样式是什么。同 CSS 的 cursor。
        zlevel: 0, // {number} 所有图形的 zlevel 值
        z: 3 // {number} 组件的所有图形的z值。控制图形的前后顺序。z值小的图形会被z值大的图形覆盖。
    };

    formatOption(option) {
        super.formatOption(...arguments);

        if (option.color) {
            if (!getObjectKV(option, 'line.normal.style.stroke')) {
                setObjectKV(option, 'line.normal.style.stroke', option.color);
            }
            if (!getObjectKV(option, 'area.normal.style.fill')) {
                setObjectKV(option, 'area.normal.style.fill', option.color);
            }
            if (!getObjectKV(option, 'symbol.normal.style.fill') && !getObjectKV(option, 'symbol.normal.style.stroke')) {
                setObjectKV(option, 'symbol.normal.style.fill', 'white');
                setObjectKV(option, 'symbol.normal.style.stroke', option.color);
            }
        }
    }

    update() {
        super.update();

        let xAxis = this.getAxisModel('x'),
            yAxis = this.getAxisModel('y');
        let xScale = xAxis.scale;
        let yScale = yAxis.scale;
        let yDomain = yScale.domain();
        let xDomain = xScale.domain();
        let isStack = this.get('stack');
        let data = this.data;
        let offset = xAxis.get('type') === 'band' ? xScale.bandwidth() / 2 : 0;
        let points = this.points;
        let xIsVertical =
            xAxis.get('position') === 'left' ||
            xAxis.get('position') === 'right';

        if (xAxis.getAxisType() === 'ordinal') {
            let symbolOpt = this.get('symbol').normal;

            if (symbolOpt.show === 'auto') {
                let symbolSize = parseSize(symbolOpt.size);
                let isHorizontal = ['bottom', 'top'].indexOf(xAxis.get('position')) > -1;
                let tickPoints = [];

                symbolSize = isHorizontal ? symbolSize.width : symbolSize.height;

                for (let i = 0; i < points.length; i++) {
                    if (!points[i]) continue;

                    if (tickPoints.length) {
                        if (points[i][isHorizontal ? 0 : 1] - tickPoints[tickPoints.length - 1][isHorizontal ? 0 : 1] > symbolSize) {
                            tickPoints.push(points[i]);
                        }
                    } else {
                        tickPoints.push(points[i]);
                    }
                }
                
                this.tickPoints = tickPoints;
            } else {
                this.tickPoints = map(xAxis.axis.values(), (d, i) => {
                    let index = xDomain.indexOf(d) + xAxis.startIndex;

                    if (data[index]) {
                        let value = data[index][1];

                        if (typeof value !== 'number') return;

                        let x = xScale(d) + offset;
                        let y = isStack ? yScale(value[1]) : yScale(value);

                        if (typeof x === 'number' && typeof y === 'number') {
                            return [x, y, data[index], index];
                        }
                    }
                });
            }
        } else {
            this.tickPoints = points;
        }

        // 若为time类型轴，则在前后进行补点
        if (xAxis.get('type') === 'time') {
            let firstPoint, lastPoint, i, j;

            for (i = points.length; i--;) {
                if (points[i]) {
                    if (i < points.length - 1) {
                        new Date(data[i + 1][0]) > xScale.domain()[1] &&
                            (lastPoint = points[i]);
                    }
                    break;
                }
            }

            for (j = 0; j < points.length; j++) {
                if (points[j]) {
                    if (j > 0) {
                        new Date(data[j - 1][0]) < xScale.domain()[0] &&
                            (firstPoint = points[j]);
                    }
                    break;
                }
            }

            if (lastPoint || firstPoint) {
                this.points = [...points];
                if (lastPoint) {
                    this.points.splice(
                        i + 1,
                        0,
                        xIsVertical
                            ? [
                                lastPoint[0],
                                xScale.range()[1],
                                lastPoint[2],
                                lastPoint[3]
                            ]
                            : [
                                xScale.range()[1],
                                lastPoint[1],
                                lastPoint[2],
                                lastPoint[3]
                            ]
                    );
                }
                // if (firstPoint) {
                //     this.points.splice(j, 0, xIsVertical ?
                //         [firstPoint[0], xScale.range()[0], firstPoint[2], firstPoint[3]] :
                //         [xScale.range()[0], firstPoint[1], firstPoint[2], firstPoint[3]]
                //     );
                // }
            } else {
                this.points = points;
            }
        }

        this.y0 = yScale(
            yDomain[1] < 0 ? yDomain[1] : yDomain[0] > 0 ? yDomain[0] : 0
        );
        this.startIndex = xAxis.startIndex;
        this.endIndex = xAxis.endIndex;
        this.yAxis = yAxis;
    }

    getSeriesColor(name) {
        if (this.get('color')) {
            return this.get('color');
        }

        let lineOpt = this.get('line').normal;
        let areaOpt = this.get('area').normal;
        let symbolOpt = this.get('symbol').normal;

        return lineOpt.show
            ? lineOpt.style.stroke
            : areaOpt.show
                ? areaOpt.style.fill
                : symbolOpt.style.fill || symbolOpt.style.stroke;
    }
}
