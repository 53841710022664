import ChartView from '../../view/Chart';
import { TYPE } from './ScatterModel';
import { Text, formatTextStyle, D3Line} from '../../util/graphic';
import { Symbol, LargeSymbol} from '../../util/symbol';
import { each, clone, merge, findAllIndex, isFunction, parseSize, isArray } from '../../util';
import { seriesActions } from '../../action/event';

export default class ScatterView extends ChartView {

    static type = TYPE;

    type = TYPE;

    renderAllSymbol(model, globalModel, global, showPoints) {
        let group = this.group;
        let symbolOption = model.get('symbol').normal;
        let { isScatter } = model;
        let selectedList = model.get('selectedList');
        let whenEmphasisZ = model.get('whenEmphasisZ');
        let needAnimation = model.get('animation');
        let animationDuration = model.get('animationDuration');
        let animationEasing = model.get('animationEasing');
        let cursor = model.get('cursor');
        let attrs = [], animateFroms = [], animateList;

        each(showPoints, function (point, index) {
            if (point) {
                let itemData = point[2];
                let isSelected = selectedList ? selectedList.indexOf(point[3]) > -1 : false;

                let attr = {
                    shape: getScatterShape(model, itemData, isSelected),
                    position: [point[0], point[1]],
                    // style: getScatterStyle(model, itemData, isSelected, point[3]),
                    key: point[3],
                    z2: (isSelected && whenEmphasisZ) ? whenEmphasisZ : point[3] + 99,
                    seriesIndex: model.index,
                    dataIndex: point[3],
                    rectHover: true,
                    cursor
                };

                attrs.push(attr);

                animateFroms.push({
                    shape: {
                        x: 0,
                        y: 0,
                        width: 0,
                        height: 0
                    },
                    position: [point[0], point[1]]
                });
            }
        });

        if (isScatter || model.get('stack')) {
            animateList = {
                shape: {
                    x: 0,
                    y: 0,
                    width: 0,
                    height: 0
                },
                position: [0, 0]
            };
        } else {
            animateList = null;
        }

        this.setShapeGroup('symbolGroup', Symbol,
            attrs,
            {
                animation: needAnimation,
                duration: animationDuration,
                easing: animationEasing,
                animateFrom: animateFroms,
                animateList
            },
            function (shape) {
                shape.on('mouseover', function ({ target }) {
                    setStyle(target, model, true);
                    whenEmphasisZ && (target.z2 = whenEmphasisZ + 1);

                    global.dispatchAction(model, seriesActions.mouseover, { 
                        data: model.points[target.dataIndex][2],
                        dataIndex: target.dataIndex,
                        name: model.get('name'),
                        seriesIndex: model.index,
                        position:{
                            x: model.points[target.dataIndex][0],
                            y: model.points[target.dataIndex][1]
                        }
                    });
                });
                shape.on('mouseout', function ({ target }) {
                    let selectedList = model.get('selectedList');

                    if (selectedList.indexOf(target.dataIndex) === -1) {
                        setStyle(target, model, false);
                    }

                    global.dispatchAction(model, seriesActions.mouseout, { 
                        data: model.points[target.dataIndex][2],
                        dataIndex: target.dataIndex,
                        name: model.get('name'),
                        seriesIndex: model.index,
                        position:{
                            x: model.points[target.dataIndex][0],
                            y: model.points[target.dataIndex][1]
                        }
                    });
                });

                shape.on('click', function (e) {
                    let enableSelect = model.get('enableSelect');
                    let selectedList = model.get('selectedList');
                    let { target } = e;

                    if (enableSelect === 'multiple') {
                        let index = selectedList.indexOf(target.dataIndex);

                        if (index > -1) {
                            selectedList.splice(index, 1);
                            model.set('selectedList', selectedList);
                            setStyle(target, model, false);
                        } else {
                            selectedList.push(target.dataIndex);
                            model.set('selectedList', selectedList);
                        }

                    } else if (enableSelect) {
                        each(selectedList, function (index) {
                            shape.childOfName(index) && setStyle(shape.childOfName(index), model, false);
                        });
                        model.set('selectedList', [target.dataIndex]);

                        global.dispatchAction(model, seriesActions.selectedListChange, [target.dataIndex]);
                    }
                    
                    global.dispatchAction(model, seriesActions.itemClick, { 
                        data: model.points[target.dataIndex][2],
                        dataIndex: target.dataIndex,
                        name: model.get('name'),
                        seriesIndex: model.index,
                        position:{
                            x: model.points[target.dataIndex][0],
                            y: model.points[target.dataIndex][1]
                        }
                    });
                    model.get('onClick') && model.get('onClick')(model.points[target.dataIndex][2], e);
                });

                shape.on('dblclick', function (e) {
                    let { target } = e;

                    model.get('onDBLClick') && model.get('onDBLClick')(model.points[target.dataIndex][2], e);
                });
            }
        );


        let symbolGroup = this.getShape('symbolGroup');

        symbolGroup.eachChild(symbol => {
            if (~selectedList.indexOf(symbol.dataIndex)) {
                setStyle(symbol, model, true, true);
            } else {
                setStyle(symbol, model, false, true);
            }
        });
    }

    renderLargeSymbol(model, globalModel, global, showPoints) {
        let symbolOption = model.get('symbol').normal;
        let points = [];

        each(showPoints, function (point) {
            if (point) {
                let itemData = point[2];
                let p = getScatterShape(model, itemData, false);

                p.x += point[0];
                p.y += point[1];
                points.push(p);
            }
        });

        this.setShape('symbolGroup', LargeSymbol, {
            shape: {
                points
            },
            style: symbolOption.style,
            silent: true
        });
    }

    getMarkerInfoByItemIndex(itemIndex, showPoints){
        let r; 
        showPoints.map(function(item, elem) {
            if(item[3] == itemIndex){
                r = item;
                return;
            }
        });
        return r;
    }

    renderMarkers(model, globalModel, global, showPoints) {
        let markers = model.get('markers')
        let { data, linkLine:linkLineStyle, labelGroup:labelGroupStyle } = markers;
        let geoIndex = model.get('$geoIndex');
        let geoModel = global.getModel('geo', geoIndex)
        
        let markerData;
        if (data === 'selectedList') {
            markerData = model.get('selectedList').map(function(item, elem) {
                return {itemIndex: item}
            })
        }else{
            markerData = data;
        }

        let lineAttr = [], labelAttr = [];
        markerData.map((item, elem) => {
            let itemLinkLineStyle = merge(clone(item.linkLine) || {}, linkLineStyle)
            let itemLabelGroupStyle = merge(clone(item.labelGroup) || [], labelGroupStyle);
            let itemIndex = item.itemIndex;
            let { 
                linePoints: linePointsExpr, 
                retain
            } = itemLinkLineStyle
            let lineFinalPoints;


            // 0: 579.9208888936214
            // 1: 524.928230433814
            // 2: (8) [114.07, 22.62, 81.68054012413938, 91.28454595786198, 6, 1.34, 41, "深圳"]
            // 3: 2
            let markerInfo = this.getMarkerInfoByItemIndex(itemIndex, showPoints)

            if (typeof linePointsExpr === 'function') {
                lineFinalPoints = linePointsExpr({
                    position: geoModel.position,
                    startPos: [markerInfo[0], markerInfo[1]],
                    markerInfo: markerInfo[2],
                    itemIndex: markerInfo[3],
                    retain
                })
            }else if(isArray(linePointsExpr)){
                lineFinalPoints = linePointsExpr;
            }else{
                lineFinalPoints = [
                    [markerInfo[0], markerInfo[1]],
                    [markerInfo[0]+20, markerInfo[1]-40],
                    [markerInfo[0]+20+retain, markerInfo[1]-40]
                ]
            }

            if (!isArray(lineFinalPoints)) {
                console.warn('marker linkLine linePoints 需要是数组格式: [[0, 0], [100, 100], [200, 100]]')
            }

            lineAttr.push({
                shape: {
                    x: function (d) { return d[0]; },
                    y: function (d) { return d[1]; },
                    data: lineFinalPoints,
                    percent: 1,
                    defined: d => d
                },
                style: itemLinkLineStyle.style,
                markerLine: {
                    itemIndex: itemIndex,
                    markerInfo: markerInfo
                }
            });

            for (let i = 0; i < itemLabelGroupStyle.length; i++) {
                let s = itemLabelGroupStyle[i];
                let offsetFinal = s.offset || [0, 0];
                let labelContent;

                if (typeof s.formatter === 'function') {
                    labelContent = s.formatter({
                        position: geoModel.position,
                        startPos: [markerInfo[0], markerInfo[1]],
                        markerInfo: markerInfo[2],
                        itemIndex: markerInfo[3],
                        retain
                    })
                }
                if (typeof s.offset === 'function') {
                    offsetFinal = s.offset({
                        position: geoModel.position,
                        startPos: [markerInfo[0], markerInfo[1]],
                        markerInfo: markerInfo[2],
                        itemIndex: markerInfo[3],
                        retain
                    })
                }

                if (labelContent) {
                    labelAttr.push({
                        style: {
                            ...s.style,
                            text: labelContent
                        },
                        position: [lineFinalPoints[1][0]+offsetFinal[0],lineFinalPoints[1][1]+offsetFinal[1]] ||lineFinalPoints[1]
                    })
                }

            }

        })


        this.setShapeGroup('markerLine', D3Line, lineAttr)

        this.setShapeGroup('markerText', Text, labelAttr);

    }

    render(model, globalModel, global) {
        let { points, tickPoints, isScatter } = model;
        let symbolOption = model.get('symbol').normal;
        let markers = model.get('markers');
        let showPoints = symbolOption.show === 'all' || isScatter ? points : tickPoints;

        if (symbolOption.show) {
            if (model.get('large') && showPoints.length > model.get('largeThreshold')) {
                this.renderLargeSymbol(model, globalModel, global, showPoints);
            } else {
                this.renderAllSymbol(model, globalModel, global, showPoints);
            }
        } else {
            this.removeShape('symbolGroup');
        }

        if (markers && markers.show && (markers.data === 'selectedList' || markers.data[0] )) {
            this.renderMarkers(model, globalModel, global, showPoints)
        }
    }

    highlight(model, globalModel, global, payload) {
        if (payload) {
            let { index } = payload;
            let emphasisSymbolOption = model.get('symbol').emphasis;
            let emphasisLabelOption = model.get('label').emphasis;
            let z = model.get('z');
            let type = model.get('type');
            let zlevel = model.get('zlevel');
            let data = model.getRealData();
            let points = model.points;

            let indexs = type === 'scatter' ? findAllIndex(data, function (d) {
                return d && d[0] === index;
            }) : [index];

            if (emphasisSymbolOption.show || emphasisLabelOption.show) {
                let highlightSymbol = this.getShape('highlightSymbol');
                let symbolGroup = this.getShape('symbolGroup');
                let attrs = [];

                each(indexs, (index) => {
                    let targetSymbol = symbolGroup && symbolGroup.childOfName(index);

                    if (targetSymbol) {
                        setStyle(targetSymbol, model, true);
                    } else {
                        let point = points[index];

                        if (!point) return;

                        let itemData = point[2];

                        let attr = {
                            shape: getScatterShape(model, itemData, true),
                            position: [point[0], point[1]],
                            dataIndex: point[3],
                            style: getScatterStyle(model, itemData, true, point[3]),
                            z2: point[3] + 99,
                            z,
                            zlevel,
                            seriesIndex: model.index
                        };

                        attrs.push(attr);
                    }
                });

                this.setShapeGroup('highlightSymbol', Symbol, attrs).show();
            }
        } else {
            let symbolGroup = this.getShape('symbolGroup');

            if (symbolGroup) {
                symbolGroup.eachChild(function (child) {
                    setStyle(child, model, true);
                });
            }
        }
    }

    downplay(model, globalModel, global, payload) {
        let selectedList = model.get('selectedList');

        if (payload) {
            let { index, startIndex } = payload;
            let data = model.getRealData();
            let type = model.get('type');

            let indexs = type === 'scatter' ? findAllIndex(data, function (d) {
                return d && d[0] === index;
            }) : [index];

            if (this.getShape('symbolGroup')) {
                each(indexs, (index) => {
                    let targetSymbol = this.getShape('symbolGroup').childOfName(index);

                    if (targetSymbol) {
                        selectedList.indexOf(index) === -1 && setStyle(targetSymbol, model, false);
                    }
                });
            }

            if (this.getShape('highlightSymbol')) {
                this.getShape('highlightSymbol').hide();
            }
        } else {
            let symbolGroup = this.getShape('symbolGroup');

            if (symbolGroup) {
                symbolGroup.eachChild(function (child) {
                    selectedList.indexOf(child.dataIndex) === -1 && setStyle(child, model, false);
                });
            }
        }
    }
}

function setStyle(target, model, isEmphasis, onlyStyle) {
    let symbolOpt = model.get('symbol')[isEmphasis ? 'emphasis' : 'normal'];

    if (symbolOpt.show) {
        let data = model.get('stack') ? model.stackData[target.dataIndex][2] : model.getRealData(target.dataIndex);

        if (!onlyStyle) {
            target.stopAnimation(true);
            target.animate('shape', false)
                .when(100, getScatterShape(model, data, isEmphasis))
                .start();
        }

        target.useStyle(getScatterStyle(model, data, isEmphasis, target.dataIndex));

        !isEmphasis && (target.z2 = target.dataIndex + 99);
    }
}

function getScatterShape(model, itemData, isEmphasis) {
    let symbolOption = model.get('symbol');

    symbolOption = isEmphasis ? merge(clone(symbolOption.normal), symbolOption.emphasis, true) : symbolOption.normal;

    let symbolType = symbolOption.type;
    let symbolSize = symbolOption.size;
    let symbolOffset = symbolOption.offset;
    let _symbolSize = parseSize(typeof symbolSize === 'function' ? symbolSize(itemData) : symbolSize);

    return {
        symbolType: symbolType,
        x: -_symbolSize.width / 2 + symbolOffset[0],
        y: -_symbolSize.height / 2 + symbolOffset[1],
        width: _symbolSize.width,
        height: _symbolSize.height
    }
}

function getScatterStyle(model, itemData, isEmphasis, dataIndex) {
    let symbolOption = model.get('symbol');
    let labelOption = model.get('label');
    let normalSymbol = symbolOption.normal.style;
    let value = itemData[1];
    let item = { data: itemData, index: dataIndex - model.startIndex, dataIndex };
    let visualIndex = model.get('$visualMapIndex');
    let style, labelStyle;

    style = isFunction(normalSymbol) ? normalSymbol(value, item) : clone(normalSymbol);

    if (visualIndex !== undefined) {
        let visualMapModel = model.globalModel.getComponentByIndex('visualMap', visualIndex);

        if (model.get('$calendarIndex') !== undefined || model.get('type') === 'line') {
            style.fill = visualMapModel.getColorStop(itemData[1], style.fill);
        } else {
            style.fill = visualMapModel.getColorStop(itemData[2], style.fill);
        }
    }

    if (isEmphasis) {
        let emphasisSymbol = symbolOption.emphasis.style;

        emphasisSymbol = isFunction(emphasisSymbol) ? emphasisSymbol(value, item) : clone(emphasisSymbol);
        style = merge(style, emphasisSymbol, true);
    }

    if (isEmphasis) {
        let emphasisLabel = labelOption.emphasis;

        if (emphasisLabel.show) {
            let normalLabel = labelOption.normal;
            let labelFormatter = emphasisLabel.formatter || normalLabel.formatter;
            let _labelNormalStyle = isFunction(normalLabel.style) ? normalLabel.style(value, item) : clone(normalLabel.style);
            let _labelStyle = merge(_labelNormalStyle, emphasisLabel.style, true);

            labelStyle = {
                text: labelFormatter ? labelFormatter(value, item) : value,
                ...formatTextStyle(formatXXXStyle(_labelStyle, value, item))
            };
        }
    } else {
        let normalLabel = labelOption.normal;

        if (normalLabel.show) {
            let labelFormatter = normalLabel.formatter;
            let _labelNormalStyle = isFunction(normalLabel.style) ? normalLabel.style(value, item) : clone(normalLabel.style);

            labelStyle = {
                text: labelFormatter ? labelFormatter(value, item) : value,
                ...formatTextStyle(formatXXXStyle(_labelNormalStyle, value, item))
            };
        }
    }

    if (labelStyle) {
        style = merge(style, labelStyle);
    }

    return style;
}

function formatXXXStyle(style, value, item) {
    let _style = {};

    each(style, function (v, k) {
        if (isFunction(v)) {
            _style[k] = v(value, item);
        } else {
            _style[k] = v;
        }
    });

    return _style;
}
