import { each, isFunction } from '../../../util';
import CandleStick from '../../../shape/CandleStick';

export default function getCandleStickLayout({
    rectWidth, position, y0, points, data, model
}) {
    let itemStyle = model.get('itemStyle').normal;
    let attrs = [];
    let animateFroms = [];
    let attrsDroplet = [];

    let animateList = {
        shape: {
            // x: 0,
            open: 0,
            close: 0,
            low: 0,
            high: 0,
            width: 0
        }
    }

    if (position === 'top' || position === 'bottom') {
        each(points, ([x, [y1, y2, y3, y4], d, i]) => {
            attrs.push({
                shape: {
                    x: x,
                    open: y1,
                    close: y2,
                    low: y3,
                    high: y4,
                    width: rectWidth
                },
                style: isFunction(itemStyle) ? itemStyle(data[i]) : itemStyle
            });

            animateFroms.push({
                shape: {
                    open: y1,
                    close: y1,
                    low: y1,
                    high: y1
                }
            });
        });
    // eslint-disable-next-line no-empty
    } else {

    }

    return {
        attrs,
        animateFroms,
        animateList,
        Shape: CandleStick
    }
}
