/**
 * 箱型图
 */

import Path from 'zrender/src/graphic/Path';

export default Path.extend({

	type: 'candlestick',

	shape: {
		x: 0,
		low: 0,
		q1: 0,
		median: 0,
		q3: 0,
		high: 0
	},

	style: {
		fill: 'white',
		stroke: '#e93030'
	},

	buildPath: function (ctx, shape) {
		let { x, low, q1, median, q3, high, width } = shape;
		let left = x;
		let center = left + width / 2;
		let right = x + width;

		ctx.moveTo(left, low);
		ctx.lineTo(right, low);

		ctx.moveTo(center, low);
		ctx.lineTo(center, q1);

		ctx.rect(left, q1, width, q3 - q1);

		ctx.moveTo(left, median);
		ctx.lineTo(right, median);

		ctx.moveTo(center, q3);
		ctx.lineTo(center, high);

		ctx.moveTo(left, high);
		ctx.lineTo(right, high);
	}
});