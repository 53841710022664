/**
 * 时间轴
 */
import ComponentModel from '../../model/Component';
import { parsePercent } from '../../util'
import scales from '../axis/scale';

export const TYPE = 'timeline';

export default class TimelineModel extends ComponentModel {

    static type = TYPE;

    type = TYPE;

    static defaultOption = {
        show: true, // {Boolean} 是否显示
        currentIndex: 0, // {Number} 当前选中项
        autoPlay: true, // {Boolean} 表示是否自动播放。
        rewind: false, // {Boolean} 表示是否反向播放。
        loop: true, // {Boolean} 表示是否循环播放。
        playInterval: 2000, // {Number} 表示播放的速度（跳动的间隔），单位毫秒（ms）。
        realtime: true, // {Boolean} 拖动圆点的时候，是否实时更新视图。
        inverse: false, // {Boolean} 是否反向放置 timeline，反向则首位颠倒过来。

        orient: 'horizontal', // {String} vertical || horizontal
        left: '10%', // {String | Number} 左
        top: '95%', // {String | Number} 上
        length: '80%', // {String | Number} 长度

        interval: undefined, // {Number} label显示间隔
        intervalLength: 50, // {Number} label显示间隔宽度
        alwaysShowLast: false, // {Boolean} 总是显示最后一个

        line: { // 时间轴线配置
            show: true, // {Boolean}是否显轴线
            style: {

            }
        },

        label: { // 时间轴标签配置
            show: true, // {Boolean} 是否显示
            position: undefined, // {Strnig} 显示位置
            formatter: undefined, // {Function} 格式化函数
            padding: 15, // {Number} 间隔
            style: { // 样式
                normal: {

                },
                emphasis: {

                }
            }
        },

        symbol: { // 时间轴标记图形配置
            show: 'all', // {Boolean | String} 是否显示 all | true | false
            type: 'circle', // {String} 标记类型
            size: 12, // {Number} 标记大小
            style: { // 标记样式
                normal: {

                },
                emphasis: {

                }
            }
        },

        currentSymbol: { // 当前时间点标记图形配置
            show: true, // {Boolean} 是否显示
            type: 'circle', // {String} 标记类型
            size: 15, // {Number} 标记大小
            style: { // 标记样式 

            }
        },

        controlBtn: { // 控制按钮
            show: true, // {Boolean} 是否显示
            showPlayBtn: 'end', // {String} play按钮显示位置
            showPrevBtn: 'start', // {String} prev按钮显示位置
            showNextBtn: 'end', // {String} end按钮显示位置
            size: 20, // {Number} 按钮大小
            gap: 12, // {Number} 按钮之间间隔
            playIcon: 'path://M31.6,53C17.5,53,6,41.5,6,27.4S17.5,1.8,31.6,1.8C45.7,1.8,57.2,13.3,57.2,27.4S45.7,53,31.6,53z M31.6,3.3 C18.4,3.3,7.5,14.1,7.5,27.4c0,13.3,10.8,24.1,24.1,24.1C44.9,51.5,55.7,40.7,55.7,27.4C55.7,14.1,44.9,3.3,31.6,3.3z M24.9,21.3 c0-2.2,1.6-3.1,3.5-2l10.5,6.1c1.899,1.1,1.899,2.9,0,4l-10.5,6.1c-1.9,1.1-3.5,0.2-3.5-2V21.3z', // jshint ignore:line
            stopIcon: 'path://M30.9,53.2C16.8,53.2,5.3,41.7,5.3,27.6S16.8,2,30.9,2C45,2,56.4,13.5,56.4,27.6S45,53.2,30.9,53.2z M30.9,3.5C17.6,3.5,6.8,14.4,6.8,27.6c0,13.3,10.8,24.1,24.101,24.1C44.2,51.7,55,40.9,55,27.6C54.9,14.4,44.1,3.5,30.9,3.5z M36.9,35.8c0,0.601-0.4,1-0.9,1h-1.3c-0.5,0-0.9-0.399-0.9-1V19.5c0-0.6,0.4-1,0.9-1H36c0.5,0,0.9,0.4,0.9,1V35.8z M27.8,35.8 c0,0.601-0.4,1-0.9,1h-1.3c-0.5,0-0.9-0.399-0.9-1V19.5c0-0.6,0.4-1,0.9-1H27c0.5,0,0.9,0.4,0.9,1L27.8,35.8L27.8,35.8z', // jshint ignore:line
            nextIcon: 'path://M18.6,50.8l22.5-22.5c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7L18.7,4.4c-0.1-0.1-0.2-0.3-0.2-0.5 c0-0.4,0.3-0.8,0.8-0.8c0.2,0,0.5,0.1,0.6,0.3l23.5,23.5l0,0c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7l-0.1,0.1L19.7,52 c-0.1,0.1-0.3,0.2-0.5,0.2c-0.4,0-0.8-0.3-0.8-0.8C18.4,51.2,18.5,51,18.6,50.8z', // jshint ignore:line
            prevIcon: 'path://M43,52.8L20.4,30.3c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7L42.9,6.4c0.1-0.1,0.2-0.3,0.2-0.5 c0-0.4-0.3-0.8-0.8-0.8c-0.2,0-0.5,0.1-0.6,0.3L18.3,28.8l0,0c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7l0.1,0.1L41.9,54 c0.1,0.1,0.3,0.2,0.5,0.2c0.4,0,0.8-0.3,0.8-0.8C43.2,53.2,43.1,53,43,52.8z', // jshint ignore:line
            style: {
                normal: {

                },
                emphasis: {

                }
            }
        },

        zlevel: 0, // {number} 所有图形的 zlevel 值
        z: 0 // {number} 组件的所有图形的z值。控制图形的前后顺序。z值小的图形会被z值大的图形覆盖。
    };

    update() {
        if (!this.get('show')) return;

        let { _option: { orient, left, top, length, inverse }, global } = this;
        let data = this.getData();
        let viewWidth = global.getWidth();
        let viewHeight = global.getHeight();
        let scale = this.scale || scales.point();
        let _length = parsePercent(length, orient === 'vertical' ? viewHeight : viewWidth);
        let _left = parsePercent(left, viewWidth);
        let _top = parsePercent(top, viewHeight);
        let range;

        if (data) {
            scale.domain(data);
        }

        if (orient === 'vertical') {
            range = [_top, _top + _length];
        } else {
            range = [_left, _left + _length];
        }

        inverse && (range.reverse());
        scale.range(range);

        this.left = _left;
        this.top = _top;
        this.length = _length;
        this.scale = scale;
    }

}