import ChartView from '../../view/Chart';
import Area from '../../shape/D3Area';
import { getCurve } from '../line/curve';
import { each, clone, merge, findAllIndex, filter } from '../../util';
import { TYPE } from './AreaModel';

export default class AreaView extends ChartView {
    static type = TYPE;

    render(model, globalModel, global) {
        let cursor = model.get('cursor');
        let curveOption = model.get('curve');
        let needAnimation = model.get('animation');
        let animationDuration = model.get('animationDuration');
        let animationEasing = model.get('animationEasing');
        let areaOption = model.get('area').normal;
        let areaStyle = areaOption.style;
        let { points, y0 } = model;

        if (areaOption.show) {
            let shape = {
                x: d => d[0],
                y0: d => d[1][0],
                y1: d => d[1][1],
                data: points,
                curve: getCurve(curveOption),
                y0Start: y0,
                y1Start: y0,
                percent: 1,
                defined: d => d
            };
    
            this.setShape(
                'area',
                Area,
                {
                    shape,
                    style: areaStyle,
                    cursor
                },
                {
                    animation: needAnimation,
                    duration: animationDuration,
                    easing: animationEasing,
                    animateFrom: {
                        shape: {
                            percent: 0
                        }
                    }
                }
            );
        }
    }
}
