/**
 * 树图 by wjw  
 * 关系图
 * 不可以调样式，如果要调整样式，可以选择 组织结构图 orgchartModel
 */
import SeriesModel from '../../model/Series';
import { each, parsePercent } from '../../util';
import * as d3Hierarchy_ from 'd3-hierarchy';

const d3Hierarchy = d3Hierarchy_;

export const TYPE = 'tree';

export default class TreeModel extends SeriesModel {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    static emphasisList = ['label', 'node', 'link'];

    static defaultOption = {
        //data: undefined, // {Array} 系列中的数据内容数组。数组项通常为具体的数据项。数据项格式 https://github.com/d3/d3-hierarchy#hierarchy
        $dataIndex: undefined, // {number} 依赖的$data的index，格式同上，优先级低于data，取得的数据需符合data的格式
        dataKey: undefined, // {string|number} 搭配$dataIndex使用，方便从依赖的$data中取数据，等同于 data.map((d) => d[dataKey])，最终取得的数据需符合data的格式
        $gridIndex: 0, // {Array} 所依赖grid的index
        name: undefined, // {string} 系列名称，用于tooltip的显示，legend 的图例筛选

        orient: 'horizontal', // {String} 树图中 正交布局 的方向 horizontal || vertical
        treeType: 'tree', // {String} 树状图与集群图 tree || cluster
        layout: 'orthogonal', // {String} 树图的布局，有 正交 和 径向 两种 orthogonal || radial
        radial: '50%', // {String|Number} 径向布局半径
        startAngle: 0, // {Number} 径向布局起始角度
        endAngle: 360, // {Number} 径向布局结束角度
        gap: 10, // {Number} 节点与标签间隔
        initialTreeDepth: 2, // {Number} 树图初始展开的层级（深度）。
        expandAndCollapse: true, // {Boolean} 子树折叠和展开的交互，默认打开 。
        separation: undefined, // {Function} 设置间隔访问器

        // 标签
        label: {
            normal: { // 普通配置
                show: true, // {boolean} 是否显示
                silent: true, // {boolean} 允许交互
                formatter: undefined, // {Function} 格式化函数
                style: { // {Object} 样式
                    fill: '#5097f8',
                    opacity: 0.8
                }
            },
            emphasis: {}
        },

        // 节点
        node: {
            normal: {
                show: true, // {boolean} 是否显示
                type: 'circle', // {string} symbol类型
                size: 10, // {number} symbol大小
                style: { // {Object} 样式
                    stroke: '#5097f8',
                    fill: 'white'
                }
            }
        },

        // 连线
        link: {
            normal: {
                show: true, // {boolean} 是否显示
                style: { // {Object} 样式
                    stroke: '#67cacb'
                }
            }
        },

        zlevel: 0, // canvas层级
        z: 1 // 同一层canvas内层级
    };

    initialized = false;

    update() {
        let { globalModel } = this;
        let data = this.getData();
        let gridIndex = this.get('$gridIndex');
        let orient = this.get('orient');
        let treeType = this.get('treeType');
        let layout = this.get('layout');
        let gap = this.get('gap');
        let startAngle = this.get('startAngle');
        let endAngle = this.get('endAngle');
        let separation = this.get('separation');
        let initialTreeDepth = this.get('initialTreeDepth');
        let gridModel = globalModel.getComponentByIndex('grid', gridIndex);
        let { left, top, right, bottom, width, height } = gridModel.position;
        let tree = d3Hierarchy[treeType]();
        let root = this.root || d3Hierarchy.hierarchy(data);
        let radial;

        separation && tree.separation(separation);

        if (layout === 'orthogonal') {
            tree.size(
                orient === 'horizontal' ? [height, width] : [width, height]
            );
        } else {
            radial = parsePercent(this.get('radial'), Math.min(width, height));
            tree.size([endAngle - startAngle, radial]);
        }

        if (
            !this.initialized &&
            typeof initialTreeDepth === 'number' &&
            initialTreeDepth > -1
        ) {
            let id = 0;

            root.eachAfter(function(node) {
                node._id = Math.random();

                if (node.depth >= initialTreeDepth) {
                    node._children = node.children;
                    node.children = null;
                }
            });

            this.initialized = true;
        }

        tree(root);

        if (layout === 'orthogonal') {
            root.each(function(node) {
                if (orient === 'horizontal') {
                    node._x = node.y + left;
                    node._y = node.x + top;
                    node._gapX = node._x - gap;
                    node._gapY = node._y;
                } else {
                    node._x = node.x + left;
                    node._y = node.y + top;
                    node._gapX = node._x;
                    node._gapY = node._y - gap;
                }
            });
        } else {
            left += radial + (width - radial * 2) / 2;
            top += radial + (height - radial * 2) / 2;

            root.each(function(node) {
                node.x += startAngle;
                node._x = Math.sin(node.x / 180 * Math.PI) * node.y + left;
                node._y = Math.cos(node.x / 180 * Math.PI) * node.y + top;
                node._gapX =
                    Math.sin(node.x / 180 * Math.PI) * (node.y + gap) + left;
                node._gapY =
                    Math.cos(node.x / 180 * Math.PI) * (node.y + gap) + top;
            });
        }

        this.root = root;
        this.position = gridModel.position;
    }
}
