/**
 * 节点用饼图表现 by zgx
 * 关系图
 */
import SeriesModel from '../../model/Series';
import { each, map } from '../../util';
import dagre from '../../util/dagre';
import { max } from 'd3-array';

export const TYPE = 'pieRelation';

export default class PieRelationModel extends SeriesModel {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    static defaultOption = {
        zlevel: 0, // canvas层级
        z: 1, // 同一层canvas内层级
        node: { // 人物事件节点
            colors: ['#3F48FF', 'rgb(150, 150, 150)', '#3F96FF', '#996699', 'rgb(102, 51, 153)'], // 分组颜色，分组数量可以自己定义，默认5组
            paddingX: 5,
            paddingY: 30,
            rankPadding: 200,
            shape: {
                r: 30, // 节点形状弧度
                piePadding: 5
            },
            style: {
                normal: {
                    fontSize: 10 // label字体颜色与节点颜色一致
                },
                emphasis: {

                }
            }
        },
        link: { // 人物事件节点
            arrowSize: 5, // 箭头大小范围，数组元素一表示箭头最小值，数组元素二表示箭头最大值
            lineWidth: 5, // 连线宽度范围，数组元素一表示最小宽度，数组元素二表示最大宽度
            label: {
                fontSize: 12,
                textFill: 'rgb(102, 102, 102)'
            },
            style: {
                normal: {
                    stroke: '#000',
                    opacity: 0.7
                },
                emphasis: {

                }
            }
        }
    };

    update() {
        let data = this.getData();
        let clusterOpt = this.get('cluster');
        let g;
        if (this.g) {
            g = this.g;
        } else {
            g = new dagre.graphlib.Graph({ multigraph: true, directed: true, compound: true })
            g.setGraph({
                rankdir: 'LR',
                nodesep: this.get('node').paddingX,
                ranksep: this.get('node').rankPadding
            });
            g.setDefaultEdgeLabel(function () { return {}; });
            this.splitNameArr = [];
            each(data.nodes, (node) => {
                g.setNode(node.data.id, { label: node.data.id, group: node.data.category, width: this.get('node').paddingY, height: this.get('node').paddingX, proportion: node.data.proportion });
                this.splitNameArr.push(node.data.name);
            })
            each(data.edges, (edge) => {
                g.setEdge(edge.data.source, edge.data.target, { label: 'edges' });
            })
            dagre.layout(g);

            let getScale = (g) => {
                let fixedParam = 1.2;
                let scaleW = g.graph().width / this.global.getWidth();
                let scaleH = g.graph().height / this.global.getWidth();
                let finalScale = Math.max(scaleW, scaleH) * fixedParam;
                return finalScale;
            }
            this.finalScale = getScale(g);

            this.g = g;
        }
    }
}
