// echarts/component/tooltip/TooltipContent

import * as zrUtil from 'zrender/src/core/util';
import * as eventUtil from 'zrender/src/core/event';

var each = zrUtil.each;

var vendors = ['', 'Webkit', 'Moz', 'O'];

function TooltipContent(container, api, style) {
    var el = document.createElement('div');
    var zr = this._zr = api.getZr();

    each(style, function (value, key) {
        if (key === 'transition') {
            each(vendors, function (vendor) {
                el.style[vendor + 'Transition'] = value
            });
        } else {
            el.style[key] = value;
        }
    });

    this.el = el;

    el.className = 'd3charts-tooltip';

    this._x = api.getWidth() / 2;
    this._y = api.getHeight() / 2;

    container.appendChild(el);

    this._container = container;

    this._show = false;

    /**
     * @private
     */
    this._hideTimeout;

    var self = this;
    el.onmouseenter = function () {
        // clear the timeout in hideLater and keep showing tooltip
        if (self._enterable) {
            clearTimeout(self._hideTimeout);
            self._show = true;
        }
        self._inContent = true;
    };
    el.onmousemove = function (e) {
        e = e || window.event;
        if (!self._enterable) {
            // Try trigger zrender event to avoid mouse
            // in and out shape too frequently
            var handler = zr.handler;
            // 如果存在第三方嵌入的一些dom触发的事件，或touch事件，需要转换一下事件坐标
            eventUtil.normalizeEvent(container, e, true);
            handler.dispatch('mousemove', e);
        }
    };
    el.onmouseleave = function () {
        self._inContent = false;
        if (self._enterable) {
            if (self._show) {
                self.hideLater(self._hideDelay);
            }
        }
    };
    el.onclick = function (e) {
        if (!self._enterable) {
            // Try trigger zrender event to avoid mouse
            // in and out shape too frequently
            var handler = zr.handler;
            eventUtil.normalizeEvent(container, e, true);
            handler.dispatch('click', e);
        }
    }
}

TooltipContent.prototype = {

    constructor: TooltipContent,

    /**
     * @private
     * @type {boolean}
     */
    _enterable: true,

    /**
     * Update when tooltip is rendered
     */
    update: function () {
        // FIXME
        // Move this logic to ec main?
        var container = this._container;
        var stl = container.currentStyle
            || document.defaultView.getComputedStyle(container);
        var domStyle = container.style;
        if (domStyle.position !== 'absolute' && stl.position !== 'absolute') {
            domStyle.position = 'relative';
        }
        // Hide the tooltip
        // PENDING
        // this.hide();
    },

    show: function (tooltipModel) {
        clearTimeout(this._hideTimeout);
        var el = this.el;

        el.style.left = this._x + 'px';
        el.style.top = this._y + 'px';
        el.style.display = el.innerHTML ? 'block' : 'none';

        this._show = true;
    },

    setContent: function (content) {
        this.el.innerHTML = content == null ? '' : content;
    },

    setEnterable: function (enterable) {
        this._enterable = enterable;
    },

    getSize: function () {
        var el = this.el;
        return [el.clientWidth, el.clientHeight];
    },

    moveTo: function (x, y) {
        // xy should be based on canvas root. But tooltipContent is
        // the sibling of canvas root. So padding of ec container
        // should be considered here.
        var zr = this._zr;
        var viewportRoot;
        // zr.painter.getViewportRoot() canvas外的一层，zrender.init id的里面一层
        if (zr && zr.painter && (viewportRoot = zr.painter.getViewportRoot())) {
            x += viewportRoot.offsetLeft || 0;
            y += viewportRoot.offsetTop || 0;
        }

        var style = this.el.style;
        style.left = x + 'px';
        style.top = y + 'px';

        this._x = x;
        this._y = y;
    },

    hide: function () {
        this.el.style.display = 'none';
        this._show = false;
    },

    hideLater: function (time) {
        if (this._show && !(this._inContent && this._enterable)) {
            if (time) {
                this._hideDelay = time;
                // Set show false to avoid invoke hideLater mutiple times
                this._show = false;
                this._hideTimeout = setTimeout(zrUtil.bind(this.hide, this), time);
            }
            else {
                this.hide();
            }
        }
    },

    isShow: function () {
        return this._show;
    },

    remove: function () {
        this._container.removeChild(this.el);
    }
};

export default TooltipContent;
