import { Path } from '../util/graphic';

export default Path.extend({

    type: 'arrowLine',

    shape: {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0,
        arrowAngle: 60,
        arrowSize: 20
    },
    style: {
        stroke: '#000',

        fill: null
    },

    buildPath: function (ctx, shape) {

        var { x1, y1, x2, y2, arrowAngle, arrowSize } = shape;
        var angle = Math.atan2(y2 - y1, x2 - x1);
        var halfArrowAngle = arrowAngle / 2 / 180 * Math.PI;

        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.moveTo(x2, y2);
        ctx.lineTo(x2 - Math.cos(halfArrowAngle - angle) * arrowSize, y2 + Math.sin(halfArrowAngle - angle) * arrowSize);
        ctx.moveTo(x2, y2);
        ctx.lineTo(x2 - Math.cos(-angle - halfArrowAngle) * arrowSize, y2 + Math.sin(-angle - halfArrowAngle) * arrowSize);
    }
});
