/**
 * 直方图 by wjw
 */
import SeriesModel from '../../model/Series';
import { each } from '../../util';

export const TYPE = 'histogram';

export default class HistogramModel extends SeriesModel {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    static emphasisList = ['itemStyle', 'label'];

    static defaultOption = {
        //data: undefined, // {Array} 系列中的数据内容数组。数组项通常为具体的数据项。每一项的数据项格式为 [[0, 1], 0]
        $dataIndex: undefined, // {number} 依赖的$data的index，格式同上，优先级低于data，取得的数据需符合data的格式
        dataKey: undefined, // {string|number} 搭配$dataIndex使用，方便从依赖的$data中取数据，等同于 data.map((d) => d[dataKey])，最终取得的数据需符合data的格式
        $axisIndex: [0, 1], // {Array} 所依赖axis的index，需包括一个x轴和y轴
        name: undefined, // {string} 系列名称，用于tooltip的显示，legend 的图例筛选

        onZero: true, // {boolean} 起始点是否从0开始
        rectWidth: '100%', // {string} 宽度占区间百分比

        // 样式
        itemStyle: {
            normal: { // {Object} 普通样式
            },
            emphasis: { // {Object} 高亮样式
            }
        },

        label: { // 标签
            normal: {
                show: false, // {Boolean} 普通状态是否显示
                style: { // {Object} 普通样式
                    position: 'inside',
                    fill: 'black'
                }
            },
            emphasis: {
                show: false, // {Boolean} 高亮状态是否显示
                style: { // {Object} 高亮样式
                }
            }
        },
        
        zlevel: 0, // canvas层级
        z: 1 // 同一层canvas内层级
    };

    update() {
        let xAxis = this.getAxisModel('x'),
            yAxis = this.getAxisModel('y');
        let xScale = xAxis.scale,
            yScale = yAxis.scale;
        let xDomain = xScale.domain(),
            yDomain = yScale.domain();
        let y0 = yScale(yDomain[1] < 0 ? yDomain[1] : (yDomain[0] > 0 ? yDomain[0] : 0));
        let data = this.getData();
        let points = [];

        each(data, (d, i) => {
            let [dx, dy] = d;
            let point = [];

            dx = getDx(dx, xDomain);

            if (dx) {
                point[0] = [xScale(dx[0]), xScale(dx[1])];
                point[1] = yScale(dy);
                point[2] = d;
                point[3] = i;
    
                points.push(point);
            }
        });

        this.points = points;
        this.y0 = y0;
    }
}

function getDx(dx, domain) {
    if (dx[0] >= domain[0] && dx[1] <= domain[1]) {
        return dx;
    } else if (dx[1] < domain[0] || dx[0] > domain[1]) {
        return false;
    } else if (dx[0] < domain[0] && dx[1] > domain[1]) {
        return [domain[0], domain[1]];
    } else if (dx[0] < domain[0]) {
        return [domain[0], dx[1]];
    } else if (dx[1] > domain[1]) {
        return [dx[0], domain[1]];
    }
}