import ComponentView from '../../view/Component';
import { each, isArray } from '../../util';
import { TYPE } from './DataZoomModel';
import { dataZoomActions } from '../../action/event';
import { Rect, subPixelOptimizeRect } from '../../util/graphic';
import { Symbol } from '../../util/symbol';
import D3Line from '../../shape/D3Line';
import Area from '../../shape/D3Area';

export default class DataZoomView extends ComponentView {

    static type = TYPE;

    type = TYPE;

    constructor(model, globalModel, global) {
        super(...arguments);

        // TODO 禁用事件，只有wheel事件，只有mousemove事件..
        this._initEvent(model, globalModel, global);
    }

    _initEvent(model, globalModel, global) {

        if (model.get('type') !== 'slider') {
            let gridIndex = model.get('$gridIndex');

            if (isArray(gridIndex)) {
                each(gridIndex, index => {
                    this._bindGridEvent(model, globalModel, global, globalModel.getComponentByIndex('grid', index));
                })
            } else {
                this._bindGridEvent(model, globalModel, global, globalModel.getComponentByIndex('grid', gridIndex));
            }
        }
    }

    _bindGridEvent(model, globalModel, global, gridModel) {
        let dragging = false;
        let zoomOnMouseWheel = model.get('zoomOnMouseWheel');
        let moveOnMouseMove = model.get('moveOnMouseMove');
        let preventDefaultMouseMove = model.get('preventDefaultMouseMove');
        let preventDefaultMouseWheel = model.get('preventDefaultMouseWheel');

        if (moveOnMouseMove) {
            let downTime, moved = false;

            global.on('mousedown', function (e) {
                if (global.isEnableEdit || global.isEnableSketch) return;

                let { offsetX, offsetY } = e;
                let zoomBrushModels = globalModel.getComponent('zoomBrush');

                // 若存在zoomBrush且处于enable状态，不触发事件
                if (zoomBrushModels) {
                    for (let i = zoomBrushModels.length; i--;) {
                        if (zoomBrushModels[i].get('enable')) return;
                    }
                }

                if (gridModel.isPointInGrid(offsetX, offsetY)) {
                    downTime = new Date();
                    dragging = true;
                    moved = false;
                    global.dispatchAction(model, dataZoomActions.start, { e });
                }
            });

            global.on('mousemove', function (e) {
                let pressStop = model.get('pressStop');
                let { offsetX, offsetY } = e;
                let time = new Date();

                if (pressStop && dragging && !moved && time - downTime > 300) {
                    dragging = false;
                }

                if (dragging && gridModel.isPointInGrid(offsetX, offsetY)) {
                    global.dispatchAction(model, dataZoomActions.moving, { e });
                    preventDefaultMouseMove && preventDefault(e);
                    moved = true;
                }
            });

            global.on('mouseup', function (e) {

                dragging && global.dispatchAction(model, dataZoomActions.end);
                dragging = false;
            });

            global.on('globalout', function (e) {

                dragging && global.dispatchAction(model, dataZoomActions.end);
                dragging = false;
            });
        }

        if (zoomOnMouseWheel) {
            global.on('mousewheel', function (e) {
                if (global.isEnableEdit || global.isEnableSketch) return;

                let { offsetX, offsetY, wheelDelta } = e;

                if (gridModel.isPointInGrid(offsetX, offsetY)) {
                    global.dispatchAction(model, dataZoomActions.wheel, { e, scale: wheelDelta > 0 ? 0.1 : -0.1, zoomX: offsetX, zoomY: offsetY });
                    preventDefaultMouseWheel && preventDefault(e);
                }
            });

            global.on('pinch', function (e) {
                if (global.isEnableEdit || global.isEnableSketch) return;

                let { offsetX, offsetY, pinchX, pinchY } = e;

                if (gridModel.isPointInGrid(pinchX, pinchY)) {
                    global.dispatchAction(model, dataZoomActions.wheel, { e, scale: e.pinchScale - 1, zoomX: pinchX, zoomY: pinchY });
                    preventDefault(e.event);
                }
            });
        }

        let zoomMoving = false,
            lastOffset,
            moved,
            movedZoom;

        global.registerAction(model, dataZoomActions.start, ({ e: { offsetX, offsetY } }) => {
            let orient = model.get('orient');

            lastOffset = orient === 'horizontal' ? offsetX : -offsetY;
            zoomMoving = true;
        });

        global.registerAction(model, dataZoomActions.moving, ({ e: { offsetX, offsetY } }) => {
            if (zoomMoving) {
                let orient = model.get('orient');
                let { width, height } = gridModel.position;
                let start = model.start;
                let end = model.end;
                let offset = orient === 'horizontal' ? offsetX : -offsetY;
                let len = orient === 'horizontal' ? width : height;

                let stepWidth = model.axisModel && model.axisModel.scale.step && model.axisModel.scale.step();

                moved = offset - lastOffset;
                movedZoom = moved / len * (end - start);
                
                if (stepWidth && Math.abs(moved) < stepWidth) { return; }
                if (start - movedZoom >= 0 && end - movedZoom <= 1) {
                    let zoom = {
                        start: start - movedZoom,
                        end: end - movedZoom
                    };

                    if (zoomIsDifferent(model, zoom)) {
                        fullZoom(zoom, model);
                        model.set(zoom);
                        global.dispatchAction(model, dataZoomActions.change, { zoom: fixZoom(zoom) });
                    }
                } else {
                    if (start !== 0 && start - movedZoom < 0) {
                        let zoom = {
                            start: 0,
                            end: end - movedZoom + (movedZoom - start)
                        };

                        if (zoomIsDifferent(model, zoom)) {
                            fullZoom(zoom, model);
                            model.set(zoom);
                            global.dispatchAction(model, dataZoomActions.change, { zoom: fixZoom(zoom) });
                        }
                    } else if (end !== 1 && end - movedZoom > 1) {
                        let zoom = {
                            start: start - movedZoom - (end - movedZoom - 1),
                            end: 1
                        };

                        if (zoomIsDifferent(model, zoom)) {
                            fullZoom(zoom, model);
                            model.set(zoom);
                            global.dispatchAction(model, dataZoomActions.change, { zoom: fixZoom(zoom) });
                        }
                    }
                }

                lastOffset = offset;
            }
        });

        global.registerAction(model, dataZoomActions.end, () => {
            zoomMoving = false;
        });

        global.registerAction(model, dataZoomActions.wheel, ({ zoomX, zoomY, scale }) => {
            let orient = model.get('orient');
            let { minSpan, maxSpan } = model;
            let { left, bottom, width, height } = gridModel.position;
            let pos = orient === 'horizontal' ? (zoomX - left) / width : (bottom - zoomY) / height;
            let start = model.start;
            let end = model.end;
            let zoomDelta = end - start;

            zoomDelta *= scale;
            start += zoomDelta * pos;
            end -= zoomDelta * (1 - pos);

            start = Math.max(0, start);
            end = Math.min(1, end);

            let zoom = {
                start: Math.max(0, start),
                end: Math.min(1, end)
            };

            if (minSpan && (Math.abs(zoom.start - zoom.end) < minSpan || minSpan > 1)) return;
            if (maxSpan && Math.abs(zoom.start - zoom.end) > maxSpan) return;

            if (zoomIsDifferent(model, zoom)) {
                fullZoom(zoom, model);
                model.set(zoom);
                global.dispatchAction(model, dataZoomActions.change, { zoom: fixZoom(zoom) });
            }
        });
    }

    render(model, globalModel, global) {
        if (model.get('type') === 'inside') return;

        let { position, handlerStart, handlerEnd, handlerSize, points } = model;
        let { left, right, top, bottom, width, height } = position;
        let fillerOption = model.get('filler');
        let backgroundOption = model.get('background');
        let dataBackgroundOption = model.get('dataBackground');
        let isHorizontal = model.get('orient') === 'horizontal';

        if (backgroundOption.show) {
            this.setShape('background', Rect, subPixelOptimizeRect({
                shape: {
                    x: left,
                    y: top,
                    width,
                    height
                },
                style: backgroundOption.style
            }));
        }

        if (dataBackgroundOption.show) {
            if (dataBackgroundOption.line.show) {
                let shape = {
                    x: function (d) { return d[0]; },
                    y: function (d) { return d[1]; },
                    data: points,
                    percent: 1,
                    defined: d => d
                };
                let style = dataBackgroundOption.line.style;

                this.setShape('dataBackground.line', D3Line,
                    {
                        shape,
                        style
                    }
                );
            }

            if (dataBackgroundOption.area.show) {
                let shape = {
                    x: d => d[0],
                    y1: d => d[1],
                    y0: bottom,
                    data: points,
                    percent: 1,
                    defined: d => d
                };
                let style = dataBackgroundOption.area.style;

                this.setShape('dataBackground.area', Area,
                    {
                        shape,
                        style
                    }
                );
            }
        }

        if (fillerOption.show) {
            let fillerShape;

            if (isHorizontal) {
                fillerShape = {
                    x: width * handlerStart,
                    y: 0,
                    width: width * (handlerEnd - handlerStart),
                    height
                };
            } else {
                fillerShape = {
                    x: 0,
                    y: height * handlerStart,
                    width: width,
                    height: height * (handlerEnd - handlerStart)
                };
            }

            this.setShape('filler', Symbol, {
                shape: {
                    symbolType: fillerOption.type,
                    ...fillerShape
                },
                position: [left, top],
                style: fillerOption.style
            }, undefined, shape => {
                shape.on('mousedown', (e) => {
                    this.draggingFiller = true;
                    updateFiller.call(this, e, isHorizontal, model, global);
                });
                global.on('mousemove', (e) => {
                    updateFiller.call(this, e, isHorizontal, model, global);
                });
                global.on('mouseup', (e) => {
                    updateFiller.call(this, e, isHorizontal, model, global);
                    this.draggingFiller = false;
                    this.lastFillerOffset = undefined;
                });
                global.on('globalout', (e) => {
                    updateFiller.call(this, e.event, isHorizontal, model, global);
                    this.draggingFiller = false;
                    this.lastFillerOffset = undefined;
                });
            });
        }

        if (model.get('handler').show) {
            renderHandler.call(this, 'start', model, globalModel, global);
            renderHandler.call(this, 'end', model, globalModel, global);
        }
    }
}

function zoomIsDifferent(model, zoom) {
    if (model.start !== zoom.start || model.end !== zoom.end) {
        return true;
    } else {
        return false;
    }
}

function preventDefault(e) {
    var rawE = e.event;
    rawE.preventDefault && rawE.preventDefault();
}

function renderHandler(type, model, globalModel, global) {
    let { position, handlerStart, handlerEnd, handlerSize, xstart, xend } = model;
    let labelOption = model.get('label');
    let { left, right, top, bottom, width, height } = position;
    let isHorizontal = model.get('orient') === 'horizontal';
    let handlerOption = model.get('handler');
    let handlerAttr;
    let style = handlerOption.style;

    if (labelOption.show) {
        style = {
            text: labelOption.formatter(model['x' + type]),
            textPosition: ((type === 'start' && handlerStart < handlerEnd) || (type === 'end' && handlerStart > handlerEnd)) ? 'left' : 'right',
            ...globalModel.getFormattedText(labelOption.style),
            ...handlerOption.style
        }
    }

    handlerAttr = {
        shape: {
            symbolType: handlerOption.type,
            x: -handlerSize.width / 2,
            y: 0,
            ...handlerSize
        },
        position: [left + width * model[type === 'start' ? 'handlerStart' : 'handlerEnd'], top],
        style,
        rectHover: true,
        cursor: 'ew-resize'
    };

    this.setShape('handler' + type, Symbol, handlerAttr, undefined, shape => {
        shape.handlerType = type;
        shape.on('mousedown', () => {
            this['draggingHandler' + type] = true;
        });
        global.on('mousemove', (e) => {
            updateHandler(this['draggingHandler' + type], e, isHorizontal, model, shape.handlerType, global);
        });
        global.on('mouseup', (e) => {
            updateHandler(this['draggingHandler' + type], e, isHorizontal, model, shape.handlerType, global);
            this['draggingHandler' + type] = false;
        });
        global.on('globalout', (e) => {
            updateHandler(this['draggingHandler' + type], e.event, isHorizontal, model, shape.handlerType, global);
            this['draggingHandler' + type] = false;
        });
    });
}

function updateHandler(dragging, { offsetX, offsetY }, isHorizontal, model, handlerType, global) {
    if (!dragging) return;
    let { left, right, top, bottom, width, height } = model.position;
    let { minSpan, maxSpan } = model;
    let offset;

    if (isHorizontal) {
       
        offset = offsetX;
        offset = getFixedOffset(offset, left, right);

        let zoom = {
            start: model.start,
            end: model.end,
            [handlerType]: (offset - left) / width
        }
        /** zoom记录了用户拖拽以后的新状态 */
        let delta = zoom.end - zoom.start 
        
        /** 拖拽后可能导致datazoom的起始结束点位置交错, 需要保证起始点小于结束点*/
        if(delta<0){
            delta=zoom.start
            zoom.start= zoom.end
            zoom.end=delta
            delta=zoom.end - zoom.start 
        }
        if (minSpan && (delta < minSpan || minSpan > 1)) return;
        if (maxSpan && delta > maxSpan) return;
        fullZoom(zoom, model);
        model.set(zoom);
        global.dispatchAction(model, dataZoomActions.change, {
            zoom: fixZoom(zoom)
        });
    }
}

function updateFiller({ offsetX, offsetY }, isHorizontal, model, global) {
    if (!this.draggingFiller) return;

    let { left, right, top, bottom, width, height } = model.position;
    let offset = isHorizontal ? offsetX : offsetY, lastFillerOffset = this.lastFillerOffset;

    this.lastFillerOffset = offset;

    if (typeof lastFillerOffset === 'undefined') return;

    let { start, end } = model;
    let delta = offset - lastFillerOffset;
    let _start, _end;

    if (isHorizontal) {
        _start = delta / width + start;
        _end = delta / width + end;

        if (_start < 0) {
            _end = _end + (0 - _start);
            _start = 0;
        }
        if (_end > 1) {
            _start = _start - (_end - 1);
            _end = 1;
        }

        let zoom = {
            start: _start,
            end: _end
        };

        fullZoom(zoom, model);
        model.set(zoom);
        global.dispatchAction(model, dataZoomActions.change, { zoom: fixZoom(zoom) });
    }

}

function fullZoom(zoom, model) {
    let { axisData } = model;

    if (axisData) {
        zoom.startValue = Math.round(zoom.start * (axisData.length - 1));
        zoom.endValue = Math.round(zoom.end * (axisData.length - 1));
    }
}

function fixZoom(zoom) {
    return {
        start: Math.min(zoom.start, zoom.end),
        end: Math.max(zoom.start, zoom.end)
    }
}

function getFixedOffset(offset, min, max) {

    offset < min && (offset = min);
    offset > max && (offset = max);

    return offset
}
