/**
 * 节点水平排列，圆弧线连接的关系图 by zp
 *
 * 关系图
 */
import SeriesModel from '../../model/Series';
import { filter } from '../../util';

const TYPE = 'arcshape';

export default class ArcModel extends SeriesModel {
    static type = TYPE;

    type = TYPE;

    static defaultOption = {
    }


    update() {
    }
}
