import ComponentView from '../../view/Component';
import { TYPE } from './LegendModel';
import { each, isObject, parseSize, clone, merge, parsePercent, getContext } from '../../util';
import LegendItem from './LegendItem';
import { legendActions } from '../../action/event';
import { Circle, enableDrag, Rect, Text, formatTextStyle } from '../../util/graphic'
import { Symbol } from '../../util/symbol'

export default class LegendView extends ComponentView {

    static type = TYPE;

    type = TYPE;

    constructor(model, globalModel, global) {
        super(...arguments);

        global.registerAction(model, legendActions.click, function ({ name }) {
            if (model.get('selectedMode') === 'single') {
                model.toggleSingle(name);
            } else {
                model.toggleMultiple(name);
            }
        });

        global.registerAction(model, legendActions.mouseover, function ({ name }) {
            if (model.isSelected(name)) {
                let targetModels = globalModel.getComponentByName('series', name, true);

                each(targetModels, function (targetModel) {
                    if (targetModel.get('legendHoverLink')) {
                        global.getViewOfComponentModel(targetModel).highlight(targetModel, globalModel, global);
                    }
                });
            }
        });

        global.registerAction(model, legendActions.mouseout, function ({ name }) {
            if (model.isSelected(name)) {
                let targetModels = globalModel.getComponentByName('series', name, true);

                each(targetModels, function (targetModel) {
                    if (targetModel.get('legendHoverLink')) {
                        global.getViewOfComponentModel(targetModel).downplay(targetModel, globalModel, global);
                    }
                });
            }
        });
    }

    render(model, globalModel, global) {
        this.remove()
        if (!model.get('show')) return;

        let { position: { left, right, top, width }, items, selected } = model;
        let globalWidth = global.getWidth();
        let globalHeight = global.getHeight();
        let orient = model.get('orient');
        let align = model.get('align');
        let inactiveColor = model.get('inactiveColor');
        let verticalGap = parsePercent(model.get('verticalGap'), globalHeight);
        let horizontalGap = parsePercent(model.get('horizontalGap'), globalWidth);
        let innerGap = model.get('innerGap');
        let addLine = model.get('addLine');
        let formatter = model.get('formatter');
        let selectedMode = model.get('selectedMode');
        let { size: symbolSize, type: symbolType, color: symbolColor, style: symbolStyle } = model.get('symbol');
        symbolSize = parseSize(symbolSize);
        let textStyle = model.get('textStyle');
        let itemWidth = model.get('itemWidth');
        let onClick = model.get('onClick');
        let fromRight = false;
        let curPos = fromRight ? right : left,
            curTop = top;
        let lastHeight;
        let type = model.get('type')
        let pageAllConfig = model.get('page')
        let pageButtonItemGap = model.get('page').buttonItemGap
        let pageButtonGap = model.get('page').buttonGap
        let pageIconColor = model.get('page').iconColor
        let pageIconInactiveColor = model.get('page').iconInactiveColor
        let pageIconSize = model.get('page').iconSize
        let pageIconOffset = model.get('page').iconOffset
        let pageTextStyle = model.get('page').textStyle
        const ctx = getContext()
        ctx.save()

        let drawList = []

        let draw = (drawList, isScroll) => {
            if (isScroll) {
                curPos = fromRight ? right : left
            }
            // 遍历drawList
            each(drawList, (item) => {
                let { name, icon, style, size, showName } = item;
                let { width: symbolWidth, height: symbolHeight } = size !== undefined ? parseSize(size) : symbolSize;
                let seriesModels = globalModel.getSeriesByName(name, false); // 找到item对应series项
                let findedSeries;

                if (seriesModels.length) { // 存在对应series项
                    findedSeries = seriesModels[0];
                } else { // 不存在，则再遍历series找data内是否有有对应的item的name
                    globalModel.eachComponent('series', function (seriesModel) {
                        if (seriesModel.findDataNamed(name)) {
                            findedSeries = seriesModel;
                        }
                    }, false);
                }

                if (findedSeries) { // 找到对应的series
                    let isSelected = !!selected[name];

                    let legendItem = this.setShape(name, LegendItem, [
                        {
                            x: 0,
                            y: 0,
                            textStyle: {
                                ...textStyle,
                                fill: selected[name] ? textStyle.fill : inactiveColor,
                                text: showName ? showName : (formatter ? formatter(name, item) : name)
                            },
                            align,
                            symbolType: icon || symbolType,
                            symbolWidth,
                            symbolHeight,
                            symbolColor: symbolColor || findedSeries.getSeriesColor(name),
                            innerGap,
                            symbolStyle: merge(style || {}, symbolStyle, false),
                            series: findedSeries,
                            item,
                            isSelected,
                            inactiveColor,
                            position: [curPos, curTop],
                            fromRight,
                            left,
                            right,
                            width,
                            lastHeight,
                            verticalGap,
                            addLine,
                            itemWidth,
                            type: type
                        },
                        globalModel,
                        global
                    ], {
                        animation: true,
                    }, function (shape) {
                        if (!selectedMode) return;

                        shape.group && (shape = shape.group);
                        shape._name = name;

                        shape.on('click', function (e) {
                            let { _name: name } = this;

                            global.dispatchAction(model, legendActions.click, { e, name, item, model });
                            onClick && (onClick(name, item, model)); // 兼容旧版用法
                        });

                        shape.on('mouseover', function (e) {
                            let { _name: name } = this;

                            global.dispatchAction(model, legendActions.mouseover, { e, name, item, model });
                        });

                        shape.on('mouseout', function (e) {
                            let { _name: name } = this;

                            global.dispatchAction(model, legendActions.mouseout, { e, name, item, model });
                        });

                        shape.on('mousemove', function (e) {
                            let { _name: name } = this;

                            global.dispatchAction(model, legendActions.mousemove, { e, name, item, model });
                        });
                    });

                    let itemRect = legendItem.group.getBoundingRect();

                    if (orient === 'vertical') {
                        curTop = legendItem.group.position[1] + itemRect.height + verticalGap;
                    } else {
                        if (type === 'scroll' && orient === 'horizontal') {
                            lastHeight = itemRect.height;
                            curPos = legendItem.group.position[0] + (fromRight ? -horizontalGap : (itemWidth || itemRect.width) + horizontalGap);
                            curTop = top;
                        } else {
                            lastHeight = itemRect.height;
                            curPos = legendItem.group.position[0] + (fromRight ? -horizontalGap : (itemWidth || itemRect.width) + horizontalGap);
                            curTop = legendItem.group.position[1];
                        }
                    }
                }
            });
        }
        // 滚动图例
        if (type === 'scroll' && orient === 'horizontal') {
            ctx.font = pageTextStyle.fontSize + 'px ' + pageTextStyle.fontFamily
            let pageTextWidth = ctx.measureText('99/99').width
            let otherWidth = model.position.width - pageTextWidth - pageIconSize[0] * 3 - pageButtonGap - pageButtonItemGap * 2
            let gapFlag = [0]
            let scrollBoxWidth = 0
            for(let i = 0; i < items.length; i++) {
                let li = items[i]
                ctx.font = textStyle.fontSize + 'px ' + textStyle.fontFamily
                let tWidth = ctx.measureText(li.name).width
                let itemBoxWidth = symbolSize.width + tWidth + innerGap + horizontalGap
                scrollBoxWidth += itemBoxWidth
                if (scrollBoxWidth > otherWidth) {
                    gapFlag.push(i)
                    scrollBoxWidth = itemBoxWidth
                }
            }
            gapFlag.push(items.length)
            let allPages = gapFlag.length - 1
            let page = model.get('page').pageIndex
            ctx.font = pageTextStyle.fontSize + 'px ' + pageTextStyle.fontFamily
            pageTextWidth = ctx.measureText(page + '/' + allPages).width
            drawList = items.slice(gapFlag[page - 1], gapFlag[page])
            draw(drawList, true)
            this.setShape('pre', Symbol, {
                shape: {
                    symbolType: 'triangle',
                    width: pageIconSize[1],
                    height: pageIconSize[0],
                    x: -pageIconSize[1],
                    y: -pageIconSize[0]
                },
                style: {
                    fill: (page === 1) ? pageIconInactiveColor : pageIconColor
                },
                position: [model.position.right - (pageIconSize[0] * 2) - pageTextWidth - pageButtonItemGap * 2 + pageIconOffset[0], model.position.top + pageIconOffset[1]],
                rotation: Math.PI / 2
            }, undefined, function(shape) {
                shape.on('click', function (e) {
                    if (page === 1) {
                        return
                    } else {
                        // 改变页码
                        page--
                        let cur = pageAllConfig
                        cur.pageIndex = page
                        model.set('page', cur) // 会触发render
                    }
                });
            })
            this.setShape('next', Symbol, {
                shape: {
                    symbolType: 'triangle',
                    width: pageIconSize[1],
                    height: pageIconSize[0]
                },
                style: {
                    fill: (page === allPages) ? pageIconInactiveColor : pageIconColor
                },
                position: [model.position.right - pageIconSize[0] + pageIconOffset[0], model.position.top + pageIconOffset[1]],
                rotation: -Math.PI / 2
            }, undefined, function(shape) {
                shape.on('click', function (e) {
                    if (page === allPages) {
                        return
                    } else {
                        // 改变页码
                        page++
                        let cur = pageAllConfig
                        cur.pageIndex = page
                        model.set('page', cur) // 会触发render
                    }
                });
            })
            this.setShape('pageText', Text,
                // shape的属性等设置
                formatTextStyle({
                    style: {
                        text: page + '/' + allPages,
                        x: model.position.right - pageIconSize[0] * 2 - pageTextWidth - pageButtonItemGap + pageTextStyle.textOffset[0],
                        y: model.position.top + pageTextStyle.textOffset[1],
                        ...pageTextStyle
                    }
                }),
            );
        } else { // 正常图例
            drawList = items
            draw(drawList, false)
        }
        this.removeShape('background');


        let rect = this.group.getBoundingRect();
        let bottom = parsePercent(model.get('bottom'), globalHeight);
        let offset0, offset1;

        switch (model.get('left')) {
            case 'center':
                offset0 = globalWidth / 2 - rect.width / 2 - rect.x;
                model.position.left = offset0; // FIXME:
                break;
            case 'right':
                offset0 = globalWidth - 5 - rect.x - rect.width;
                break;
            case 'left':
                offset0 = 5;
                break;
            default:
                if (model.get('right') !== undefined) {
                    offset0 = right - rect.x - rect.width;
                }
                break;
        }

        if (model.get('top') === 'middle') {
            offset1 = globalHeight / 2 - rect.height / 2 - rect.y;
        }

        if (bottom !== undefined) {
            offset1 = globalHeight - bottom - rect.height - rect.y;
        }

        offset0 && each(this.group.children(), child => {
            child.position[0] += offset0;
        });
        offset1 && each(this.group.children(), child => {
            child.position[1] += offset1;
        });

        let background = model.get('background');

        if (background.show) {
            let padding = model.get('padding');
            let rect = this.group.getBoundingRect();

            this.setShape('background', Rect, {
                shape: {
                    x: rect.x - padding[3],
                    y: rect.y - padding[0],
                    width: rect.width + padding[1] + padding[3],
                    height: rect.height + padding[0] + padding[2],
                    r: background.radius
                },
                style: background.style,
                z2: -1,
                silent: true
            });
        }
        ctx.restore()
    }

    renderEdit(model, globalModel, global) {
        let { position: { left, top } } = model;
        let realLeft = model.get('left');

        if (realLeft === 'center' || realLeft === 'right') {
            let rect = this.group.getBoundingRect();
            left = rect.x;
        }

        this.setShape('_edit_point', Circle, {
            shape: {
                cx: left,
                cy: top,
                r: 7
            },
            style: {
                fill: 'rgba(0, 0, 0, 0.8)'
            },
            cursor: 'move',
            z2: 9999
        }, undefined, function (shape) {
            function resetPos({ offsetX, offsetY }) {
                model.set({
                    left: offsetX,
                    top: offsetY
                });
            }

            enableDrag(shape, global, {
                onDragging: resetPos,
                onDragEnd: resetPos
            });
        });
    }

    removeEdit() {
        this.eachShape((shape, name) => {
            if (name.indexOf('_edit_') === 0) {
                shape.disableDrag && shape.disableDrag();
                this.removeShape(name);
            }
        });
    }
}
