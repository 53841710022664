import { Path } from '../util/graphic';
import * as pathTool from 'zrender/src/tool/path';

export default Path.extend({
    type: 'd3-geomap',

    shape: {
        path: ''
    },

    style: {
        stroke: 'red',
        fill: '#000',
        lineWidth: null
    },

    buildPath: function (context, shape) {
        this.proxyPath = pathTool.createFromString(shape.path, {});
        this.proxyPath.buildPath(context);
    }
})