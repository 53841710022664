import Arc from 'zrender/src/graphic/shape/Arc';
import Line from 'zrender/src/graphic/shape/Line';
import D3Line from '../../shape/D3Line';
import { Text, Circle, formatTextStyle } from '../../util/graphic';
import ChartView from '../../view/Chart';
import * as d3 from 'd3-collection';
import * as d3Array from 'd3-array';
import linear from 'd3-scale/src/linear';
import BezierCurve from 'zrender/src/graphic/shape/BezierCurve';

const TYPE = 'arcshape';

export default class ArcView extends ChartView {

    static type = TYPE;

    type = TYPE;

    render(model, globalModel, global) {
        this.remove();
        this.group.position = [25, 25];
        let width = global.getWidth() - 50;
        let height = global.getHeight();
        let source = model.getData();
        let nodesData = source.nodes;
        let edgesData = source.edges;

        function person(d) {
            return d.type == 'ggfr' || d.type == 'rzgd'
        }

        function company(d) {
            return !(d.type == 'ggfr' || d.type == 'rzgd')
        }

        function setColor(data) {
            data.forEach(function(d) {

                let type = d.type;
                let color = '#4b667b';
                if (type == 'tzgs' || type == 'jccg') {
                    color = '#414DF5';
                } else if (type == 'btzgs') {
                    color = '#B5DD8F';
                } else if (type == 'rzgd') {
                    color = '#F3BA45';
                } else if (type == 'ggfr') {
                    color = '#EB7B4F';
                }
                d.color = color;
            })
        }

        let nodeSet = d3.nest().key(function(d) {
            return d.id
        }).map(nodesData);

        let parentNodeSet = d3.nest().key(function(d) {
            return d.to
        }).map(edgesData);

        let childSet = d3.nest().key(function(d) {
            return d.from
        }).map(edgesData);

        let childrenSize = d3Array.sum(nodesData, person);

        let sortKey = {
            'btzgs': -1,
            'center': 0,
            'tzgs': 1
        }

        let children = nodesData.filter(person);
        let parentNode = nodesData.filter(company).sort(function(a, b) {
            return sortKey[b.type] - sortKey[a.type]
        });


        children.forEach(function(d) {
            let id = d.id;
            let node = childSet.get(id);
            let parentNodeId = node[0].to;
            let parentNodeNode = nodeSet.get(parentNodeId)[0];
            parentNodeNode.children = parentNodeNode.children || [];
            if (parentNodeNode.children.indexOf(d) == -1) {
                parentNodeNode.children.push(d);
            }
            d.parentNode = parentNodeNode;
        })

        let childrenNice = [];

        parentNode.forEach(function(d) {
            let item = d.children;
            if (item) {
                item.forEach(function(data) {
                    childrenNice.push(data);
                })
            }
        })
        children = childrenNice;

        let hasChildSize = parentNode.filter(function(d) {
            return d.children && d.children.length > 0
        }).length;

        function compute() {

            for (let i = 0; i < parentNode.length; i++) {
                let node = parentNode[i];
                let step = width / parentNode.length;
                node.x = step * i;
                node.y = height * .6;
            }

            for (let i = 0; i < children.length; i++) {
                let node = children[i];
                let step = width / children.length;
                node.x = step * i;
                node.y = height * .9
            }


        }

        compute();
        setColor(nodesData);

        edgesData.forEach(function (e) {
            e.source = nodeSet.get(e.from)[0];
            e.target = nodeSet.get(e.to)[0];
        });

        let nodeAttrs = [], arcAttrs = [], linksAttrs = [], linksStyle = {
            stroke: '#505050',
            opacity: .05,
            lineWidth: 2
        };

        nodesData.forEach(function(d) {

            nodeAttrs.push({
                shape: {
                    cx: d.x,
                    cy: d.y,
                    r: 5
                },
                style: {
                    fill: d.color
                },
                data: d
            })
        })

        edgesData.forEach(function(d) {

            if (person(d.source) || person(d.target)) {

                linksAttrs.push({
                    shape: {
                        x1: d.source.x,
                        y1: d.source.y,
                        x2: d.target.x,
                        y2: d.target.y
                    },
                    style: linksStyle,
                    data: d

                })
            } else {
                arcAttrs.push({
                    shape: {
                        cx: (d.source.x + d.target.x) / 2,
                        cy: (d.source.y + d.target.y) / 2,
                        r:  Math.abs(d.source.x - d.target.x) / 2,
                        startAngle: Math.PI,
                        endAngle: 0,
                        clockwise: true
                    },
                    style: linksStyle,
                    data: d
                })
            }
        })


        let arcGroup = this.setShapeGroup('arclinks', Arc, arcAttrs)
        let linksGroup = this.setShapeGroup('links', Line, linksAttrs)
        let nodesGroup = this.setShapeGroup('nodes', Circle, nodeAttrs, {}, function(nodeGroup) {
            nodeGroup.on('mouseover', function (e) {
                let node = e.target;
                let nodeData = node.data;
                linksGroup.children().forEach(function(d) {
                    if (d.data.source == nodeData || d.data.target == nodeData) {
                        d.setStyle( {
                            stroke: d.data.source.color,
                            opacity: .5
                        })
                    }
                })

                arcGroup.children().forEach(function(d) {
                    if (d.data.source == nodeData || d.data.target == nodeData) {
                        d.setStyle( {
                            stroke: d.data.source.color,
                            opacity: .5
                        })
                    }
                })
            });

            nodeGroup.on('mouseout', function(e) {
                linksGroup.children().forEach(function(d) {
                    d.setStyle(linksStyle)
                })
                arcGroup.children().forEach(function(d) {
                    d.setStyle(linksStyle)
                })
            })

        })

        this.group.add(arcGroup);
        this.group.add(linksGroup);
        this.group.add(nodesGroup);


    }
}
