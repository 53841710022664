/**
 * 组织结构图 by zgx
 * 关系图
 */
import SeriesModel from '../../model/Series';
import { each, map } from '../../util';
import dagre from '../../util/dagre';
import { max } from 'd3-array';

export const TYPE = 'orgchart';

export default class OrgchartModel extends SeriesModel {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    static defaultOption = {
        zlevel: 0, // canvas层级
        z: 1, // 同一层canvas内层级
        offset: [0, 0], // {array} 偏移量
        scale: 1.2, // {number} 缩放比例
        rankStep: 200,// {number} 分组间距
        nodeStep: 15, // {number} 节点间距
        eventId: 'EVENT', // {string} 交互事件
        node: { // 人物事件节点
            colors: ['#3F48FF', 'rgb(150, 150, 150)', '#3F96FF', '#996699', 'rgb(102, 51, 153)'], // {array} 分组颜色，分组数量可以自己定义，默认5组
            hierarchy: 3, // {number} 分组层级
            isTextLength: false, // {boolean} 是否限制标签文字长度
            shape: {
                symbolType: 'circle', // {string} 节点形状，可以选圆形、正方形、三角形
                r: 0, // {number} 节点形状弧度
                width: 20, // {number} 节点宽度
                height: 10 // {number} 节点高度
            },
            style: {
                normal: {
                    shadowBlur: 0, // {number} 节点阴影宽度
                    shadowColor: 'rgb(255, 0, 0)', // {string} 节点阴影颜色
                    fontSize: 10, // {number} 标签自己大小，字体颜色与节点颜色一致
                    shadowOffsetX: 3, // {string} 节点阴影偏移
                    stroke: '#000', // {string} 节点边框颜色
                    lineWidth: 1 // {string} 节点边框线宽
                },
                emphasis: {

                }
            }
        },
        link: { // 人物事件节点
            arrowSize: [1, 5], // {array} 箭头大小范围，数组元素一表示箭头最小值，数组元素二表示箭头最大值
            lineWidth: [1, 5], // {array} 连线宽度范围，数组元素一表示最小宽度，数组元素二表示最大宽度
            label: {
                fontSize: 12, // {number} 连线上文字大小
                textFill: 'rgb(102, 102, 102)' // {string} 连线上文字颜色
            },
            style: {
                normal: {
                    stroke: '#cccccc', // {string} 连线颜色
                    opacity: 0.7 // {number} 连线透明度
                },
                emphasis: {

                }
            }
        },
        roam: {
            enable: true // {boolean} 是否支持缩放
        }
    };

    update() {
        let data = this.getData();
        let g;

        const isHeight = (n, h) => {
            if (n) {
                return n;
            }
            return h;
        };

        const isOffset = (n, h) => {
            if (n) {
                return (n - h) / 2;
            }
        }

        if (this.g) {
            g = this.g;
        } else {
            g = new dagre.graphlib.Graph({ multigraph: true, directed: true, compound: true });
            g.setGraph({
                rankdir: 'LR',
                nodesep: this.get('nodeStep'),
                ranksep: this.get('rankStep')
            });
            g.setDefaultEdgeLabel(function () { return {}; });
            const setNode = (node, g) => {
                let isParent = false;
                each(data.edges, (edge) => {
                    if (node.data.name === edge.data.source) {
                        isParent = true;
                    }
                });
                let color = node.data.color;
                let stroke = node.data.stroke;
                let textFill = node.data.textFill;
                let clickable = node.data.clickable;
                let nodeData = node.data.nodeData;
                g.setNode(node.data.id, { label: node.data.category + '-' + isParent, width: this.get('node').shape.width, height: isHeight(node.data.height, this.get('node').shape.height), color, stroke, textFill, clickable, nodeData, paddingY: isOffset(node.data.height, this.get('node').shape.height) });
            };
            this.splitNameArr = [];
          this.nameMap = {}
            each(data.nodes, (node) => {
                if (node.data.category < this.get('node').hierarchy) {
                    setNode(node, g);
                    this.splitNameArr.push(node.data.name);
                  this.nameMap[node.data.id] = node.data.name
                }
            });
            each(data.edges, (edge) => {
                if (edge.data.category < this.get('node').hierarchy) {
                    let end = g.node(edge.data.target);
                    g.setEdge(edge.data.source, edge.data.target, { label: 'edges', paddingY: end.paddingY });
                }
            });
            dagre.layout(g);

            let getScale = (g) => {
                let fixedParam = 1.2;
                let scaleW = g.graph().width / this.global.getWidth();
                let scaleH = g.graph().height / this.global.getWidth();
                let finalScale = Math.max(scaleW, scaleH) * fixedParam;
                return finalScale;
            };
            this.finalScale = getScale(g);

            this.g = g;

            this.bg = new dagre.graphlib.Graph({ multigraph: true, directed: true, compound: true })
            this.bg.setGraph({
                rankdir: 'LR',
                nodesep: 5,
                ranksep: 200
            });
            this.bg.setDefaultEdgeLabel(function () { return {}; });
            each(data.nodes, (node) => {
                setNode(node, this.bg);
            });
            each(data.edges, (edge) => {
                this.bg.setEdge(edge.data.source, edge.data.target, { label: 'edges' });
            });
            dagre.layout(this.bg);
        }
    }
}
