export * from './d3charts';
//import 'babel-polyfill'

/** 图表类型 **/
import './chart/arc';
import './chart/pie';
import './chart/arcline';
import './chart/arcbar';
import './chart/radar';

import './chart/line';
import './chart/bar';
import './chart/scatter';
import './chart/sankey';
import './chart/histogram';
import './chart/wordcloud';
import './chart/funnel';
import './chart/gauge';
import './chart/area';
import './chart/lines';
import './chart/effectScatter';
import './chart/heatmap';
import './chart/treemap';
import './chart/tree';

// import './chart/circlePacking' @todo 待优化

import './chart/custom';
// import './chart/chord';  @todo 待优化

// import './chart/relationship'; 关系图整理 @todo delete
// import './chart/force'; 关系图整理 @todo delete
import './chart/radial';
import './chart/elbow'; // huozi不兼容小程序
import './chart/arcshape';

import './chart/dagre'; // dagre不兼容小程序
import './chart/dagrex'; // dagrex不兼容小程序
import './chart/orgchart'; // dagre不兼容小程序
// import './chart/cluster'; // dagre不兼容小程序 @todo delete
import './chart/pieRelation'; // dagre不兼容小程序

// import './chart/arcturbo'; // @todo delete
// import './chart/rectarc'; // @todo delete

// import './chart/example'; // 引入模块
// import './chart/candlestick';

/** 组件类型 **/
import './component/axis';
import './component/grid';
import './component/tooltip';
import './component/data';
import './component/dataZoom';
import './component/legend';
import './component/axisPointer';
import './component/timeline';
import './component/markPoint';
import './component/title';
import './component/markLine';
import './component/zoomBrush';
import './component/sketch';
import './component/calendar';
import './component/markArea';
import './component/radarAxis';
import './component/visualMap';

import './component/region';
// import './component/map';
import './component/d3-geo';
import './component/geo';

import * as d3Time from 'd3-time';

import scales from './component/axis/scale';
export { scales, d3Time };

// 行情绘图相关
import './chart/hqbar';
import './chart/hqIndicator';
import * as hqHelper from './util/hqHelper';
export { hqHelper };

// 行情数据接口
import 'es6-promise/auto';
import './datapool/common'
import './datapool/fs'
import './datapool/kline'
export { default as BaseDataProvider, registerDataProvider } from './datapool/DataProvider'
import DataPool from './datapool/DataPool'

export var getDataPool = (function(){
    var dataPool;
    return function(opts){
        if (!dataPool) {
            if (window && window.hxc3 && typeof window.hxc3.getDataPool === 'function') {
                dataPool = hxc3.getDataPool();
            }else{
                dataPool = new DataPool(opts);
            }
        }
        return dataPool;
    }
})()
