import { Text, setHoverStyle, formatTextStyle } from '../../util/graphic';
import { each, merge, isFunction } from '../../util';
import ChartView from '../../view/Chart';
import { color20 } from '../../model/globalDefault'
import { scaleOrdinal } from 'd3-scale';
import { interpolateRgb } from 'd3-interpolate';
import { seriesActions } from '../../action/event';
import { TYPE } from './WordCloudModel'; // 使用Model中定义的TYPE，与model的type值保持一致

export default class WordCloudView extends ChartView {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    render(model, globalModel, global) {
        let { data, position: { width, height, left, top } } = model;
        let fontFamily = model.get('fontFamily');
        let fontStyle = model.get('fontStyle');
        let fontWeight = model.get('fontWeight');
        let attrs = [];
        let _color = scaleOrdinal(color20);
        let color = model.get('color');

      console.log(data)

        each(data, function(d, i) {
            let attr = {
                style: {
                    text: d.text,
                    // x: d.x,
                    // y: d.y,
                    fill: color
                        ? isFunction(color) ? color(d, i) : color
                        : _color(i),
                    fontSize: d.size,
                    textAlign: 'center',
                    textBaseline: 'middle',
                    fontFamily,
                    fontWeight,
                    fontStyle
                },
                rotation: -d.rotate / 180 * Math.PI,
                position: [d.x, d.y],
                // origin: [d.x, d.y],
                key: d.text,
                data: d
            };

          if (!((d.x + left + width / 2) > width || (d.x + left + width / 2) < 0 || (d.y + top + height / 2) < 0 || (d.y + top + height / 2) > height)) {
            attrs.push(formatTextStyle(attr));
          } else {
            console.log(d)
          }
        });

        let shapeGroup = this.setShapeGroup(
            'wordcould',
            Text,
            attrs,
            {
                animation: model.get('animation'),
                animateList: {
                    style: {
                        fontSize: 0
                    },
                    rotation: 0,
                    position: [0, 0]
                },
                animateFrom: {
                    style: {
                        fontSize: 0
                    }
                }
            },
            function(shapeGroup) {
                shapeGroup.on('click', function(e) {
                    global.dispatchAction(model, seriesActions.itemClick, {
                        data: e.target.data,
                        e
                    });
                });
            }
        );

        let rect = shapeGroup.getBoundingRect();

        shapeGroup.attr('position', [left + width / 2, top + height / 2]);
    }
}
