/**
 * 关系布局图 by zgx
 * 关系图
 */

import SeriesModel from '../../model/Series';
import { each, map } from '../../util';

export const TYPE = 'dagre';

export default class DagreModel extends SeriesModel {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    static defaultOption = {
        zlevel: 0, // canvas层级
        z: 1, // 同一层canvas内层级
        isTop: false,
        limited: true,
        node: { // 人物事件节点 
            rankStep: 80, // {number} 节点间距
            entireLabel: false, // {boolean} 是否有标签
            shape: {
                width: 80, // {number} 节点宽度
                height: 30, // {number} 节点高度
                r: 15 // {number} 节点弧度
            },
            style: {
                normal: {
                    colors: {origin: 'rgb(47, 151, 255)', process: 'rgba(169, 210, 251)', end: 'rgba(43, 87, 194, 1)'}, // {object} 节点分组颜色，目前分为3种颜色
                    fontSize: 14, // {number} 标签字体大小
                    textFill: 'rgb(255, 255 ,255)', // {string} 标签字体颜色
                    stroke: 'rgb(47, 151, 255)', // {string} 节点边框颜色
                    lineWidth: 1 // {number} 节点边框宽度
                },
                emphasis: {

                }
            }
        },
        link: { // 人物事件节点
            arrowSize: [3, 6], // {array} 箭头大小范围，数组元素一表示箭头最小值，数组元素二表示箭头最大值
            lineWidth: [1, 3], // {array} 连线宽度范围，数组元素一表示最小宽度，数组元素二表示最大宽度
            label: {
                type: 'percent', //  {string} 连线上文字表现形式
                fontSize: 10, // {number} 连线上文字大小
                textFill: 'rgb(47, 151, 255)' // {string} 连线上文字颜色
            },
            style: {
                normal: {
                    stroke: 'rgb(47, 151, 255)', // {string} 连线颜色
                    opacity: 0.4, // {number} 连线透明度
                    dashFill: 'rgb(47, 151, 255)', // {string} 连线为虚线时颜色
                    lineDash: [5, 5] // {array} 如果没有关系数值，则以虚线显示
                },
                emphasis: {

                }
            }
        }
    };
}