// @charset "utf-8";
/********************************************************************
ths analytics(TA script)
author: away@myhexin.com
history:
2011-05-27	创建
2011-06-15
	增加了自动判断是否是client的功能。对于客户端和网站，可以不使用ld
	增加了_top选项，如果是false,则取当前页面url以及referrer，否则取父frame的url和referrer.默认为true
2011-08-22
    增加了cs字段，表示clientSize，也就是客户view的大小。
usage:
    兼容原stat方法:
    TA.log(10347, 'client');
    TA.log({id: 10347, ld: 'client'});
    TA.log(10348); // 默认ld='browser'
    对于需要传递的参数可以任意增加，例如
    TA.log({cost: 1024, type: 'school', author: '10jqka'});
    那么以上所有参数都会被带到url中进行传递。

    另外ta对象提供了对点击事件的记录，以下代码在id为btn1的对象点击时，会向日志服务器发送一个点击记录,该记录带有鼠标点击位置信息。
    TA.logElementClick(document.getElementById('btn1'), {eid: 'btn1'});

    对于在屏幕上的任意点击也可以记录。
    TA.logClick({eid: 'window', _sid: '__ta_wclick__', _top: false});

    对于document所有的锚点点击可以进行自动加载处理。日志中会带有该锚点的href信息
    TA.logArchorClick({type: 'archor'});

    两个内部属性：
    _base:	基本的点击路径的信息。默认是http://stat.10jqka.com.cn/q?。可以在传递参数中修改。
    _sid:    点击统计是通过在文档中插入图片实现的。这个图片id默认为__ths_stat__，如果存在同时激发多个记录，那么需要对id进行区分。这个时候需要重定义_sid

*********************************************************************/
export const isInWXApp = (function () {
	let bool = false;

	if (typeof wx !== 'undefined' && wx.request && wx.getSystemInfoSync) {
		let info = wx.getSystemInfoSync();

		bool = info && info.version;
	}

	return bool;
})();

// 日志记录对象
// 提供 add和log两个方法进行日志记录
var ths_stat = function () {
	this.id = 0;
	this.ld = this.getLd();
	try {
		if (typeof external !== 'undefined' && typeof external.createObject !== 'undefined') {
			this.ld = 'client';
		}
	// eslint-disable-next-line no-empty
	} catch (e) { }
	var proto = (typeof document !== 'undefined' && 'https:' == document.location.protocol) ? 'https:' : 'http:';
	this._top = true;
	if (typeof screen !== 'undefined') {
		this.size = screen.width + 'x' + screen.height;
	}
	this._base = proto + '//stat.10jqka.com.cn/q?';
	this._sid = '__ths_stat__';
	this._imgs = {};
};
//获取终端类别，browser 还是android 还是 iphone
ths_stat.prototype.getLd = function () {
	if (isInWXApp) return 'wx';
	var ld = 'browser';
	var ag = navigator.userAgent.toLowerCase();
	ld = ag.indexOf('android') != -1 ? 'mobile' : (ag.indexOf('iphone') != -1 ? 'mobile' : (ag.indexOf('ipad') != -1 ? 'mobile' : 'browser'));
	return ld;
};
// 具体访问进行日志记录
// @param url string 记录的日志
ths_stat.prototype._navigate = function (url) {
	if (isInWXApp) {
		wx.request({ url });
		return;
	}
	// var el = document.getElementById(this._sid);
	var el = this._imgs[this._sid];
	if (!el) {
		el = new Image(0, 0);
		this._imgs[this._sid] = el;
		// el = document.createElement('img');
		// el.id = this._sid;
		// el.height = 0;
		// el.width = 0;
		// el.setAttribute("style", "display:none");
		// (document.body || document.head).appendChild(el);
	}
	el.src = url;
};

// 增加提交的参数信息
// @param p1 Object|number  对于Object方式，将所有属性加入到this.否则认为是id
// @param p2 string ld
ths_stat.prototype.add = function (p1, p2) {
	if (p1 && p2) {
		this.id = p1;
		this.ld = p2;
	} else if (p1 instanceof Object) {
		for (var k in p1) {
			this[k] = p1[k];
		}
	} else if (p1) {
		this.id = p1;
	}
}

// 设置访问的location地址
ths_stat.prototype._setLocation = function () {
	if (isInWXApp) return;

	if (this._top && top) {
		try {
			if (!this.ref) {
				this.ref = top.document.referrer;
			}
			if (!this.url) {
				this.url = top.location.href;
			}
		// eslint-disable-next-line no-empty
		} catch (e) { }
	}
	if (!this.ref) {
		this.ref = (document.referrer) ? document.referrer : null;
	}
	if (!this.url) {
		this.url = window.location.href;
	}

	// 客户区大小在这个时候再进行设置。
	document.body && (this.cs = document.body.clientWidth + 'x' + document.body.clientHeight);
	this.ts = (new Date()).getTime();
}

// 返回访问的url
ths_stat.prototype._getUrl = function () {
	this._setLocation();
	var url = [];
	for (var k in this) {
		if (k && k.charAt(0) != '_' && this[k] && typeof (this[k]) !== 'function') {
			if (url.length) {
				url.push('&');
			}
			url = url.concat([encodeURIComponent(k), '=', encodeURIComponent(this[k])]);
		}
	}
	return this._base + url.join('');
}

// 对本对象进行日志记录
ths_stat.prototype.log = function () {
	this._navigate(this._getUrl());
};

// 全局namespace
export default function () {
	var stat = new ths_stat();
	stat.add.apply(stat, arguments);
	stat.log();
}
