import { each, isFunction } from '../../../util';
import Boxplot from '../../../shape/Boxplot';

export default function getBoxplotLayout({
    rectWidth, position, y0, points, data, model
}) {
    let itemStyle = model.get('itemStyle').normal;
    let attrs = [];
    let animateFroms = [];
    let attrsDroplet = [];

    let animateList = {
        shape: {
            x: 0,
            low: 0,
            q1: 0,
            median: 0,
            q3: 0,
            high: 0,
            width: 0
        }
    }

    if (position === 'top' || position === 'bottom') {
        each(points, ([x, [y1, y2, y3, y4, y5], d, i]) => {
            attrs.push({
                shape: {
                    x: x,
                    low: y1,
                    q1: y2,
                    median: y3,
                    q3: y4,
                    high: y5,
                    width: rectWidth
                },
                style: isFunction(itemStyle) ? itemStyle(data[i]) : itemStyle
            });

            animateFroms.push({
                shape: {
                    low: y3,
                    q1: y3,
                    median: y3,
                    q3: y3,
                    high: y3
                }
            });
        });
    // eslint-disable-next-line no-empty
    } else {

    }

    return {
        attrs,
        animateFroms,
        animateList,
        Shape: Boxplot
    }
}
