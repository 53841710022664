import ComponentView from '../../view/Component';
import { each, curry, isArray, isObject } from '../../util';
import { TYPE } from './TooltipModel';
import TooltipContent from './TooltipContent';
import { axisActions, radarAxisActions } from '../../action/event';

export default class TooltipView extends ComponentView {
    static type = TYPE;

    type = TYPE;

    constructor(model, globalModel, global) {
        super(...arguments);

        this._tooltipContent = new TooltipContent(
            global.getDom(),
            global,
            model.get('style')
        );
        this._tooltipContent.setEnterable(model.get('enterable'));
        this._tooltipContent.update()
        this._initEvent(model, globalModel, global);
    }

    showTooltip(model, data, offsetX, offsetY, dataIndex) {

      const gridModel = model.getAxisModel('x').getGridModels()[0]
      const gridPosition = gridModel.position

      if ('ontouchstart' in window && gridModel.get('reverse')) {
        let temp = offsetX
        offsetX = offsetY
        offsetY = gridPosition.bottom + gridPosition.top - temp
      }

      model.global.dispatchAction(model, 'BEFORE_SHOWTOOLTIP', data)
        let tooltipContent = this._tooltipContent;
        let toFixedNumber = model.get('toFixedNumber');
        let dataFormatter = model.get('dataFormatter');
        let axisFormatter = model.get('axisFormatter');
        let formatter =
            model.get('formatter') ||
            curry(
                defaultFormatter,
                toFixedNumber,
                dataFormatter,
                axisFormatter
            );
        let content = formatter(data);

        if (content) {
            tooltipContent.setContent(content);
            tooltipContent.show(model);
            this._updatePosition(
                offsetX,
                offsetY,
                model,
                model.global,
                'axis',
                content,
                dataIndex
            );
        } else {
            this.hideTooltip(model);
        }
    }

    hideTooltip(model) {
      model.global.dispatchAction(model, 'AFTER_SHOWTOOLTIP')
        let tooltipContent = this._tooltipContent;
        tooltipContent.isShow() &&
            !model.get('alwaysShowContent') &&
            tooltipContent.hideLater(model.get('hideDelay'));
    }

    /**
     * trigger 类型为axis时
     * @param  {global} model
     * @param  {global} globalModel
     * @param  {global} global
     */
    _initEvent(model, globalModel, global) {
        let trigger = model.get('trigger');
        let triggerOn = model.get('triggerOn');
        let axisIndex = model.get('$axisIndex');
        let showDelay = model.get('showDelay');
        let tooltipContent = this._tooltipContent;
        let axisModel = globalModel.getComponentByIndex('axis', axisIndex);

        let radarAxisIndex = model.get('$radarAxisIndex');
        let radarAxisModel = globalModel.getComponentByIndex('radarAxis', radarAxisIndex);

        if (
            trigger === 'axis' &&
            radarAxisModel &&
            radarAxisModel.getAxisType() === 'polar'
        ) {
            global.registerAction(
                radarAxisModel,
                radarAxisActions.move,
                (data) => {
                    if (data && data.allSeries.length) {
                        this.showTooltip(model, data, data.e.offsetX, data.e.offsetY, 0);
                    } else {
                        this.hideTooltip(model);
                    }
                }
            );
            global.registerAction(
                radarAxisModel,
                radarAxisActions.out,
                (data) => {
                    this.hideTooltip(model);
                }
            );
        }else if (
            trigger === 'axis' &&
            axisModel &&
            axisModel.getAxisType() === 'ordinal'
        ) {
            // trigger为axis时，只能触发ordinal类型的axis

            global.registerAction(
                axisModel,
                axisActions.move,
                ({
                    e,
                    index,
                    lastIndex,
                    axisData,
                    data,
                    dataIndex
                }) => {
                    if (data && data.allSeries.length) {
                        this.showTooltip(model, data, e.offsetX, e.offsetY, dataIndex);
                    } else {
                        this.hideTooltip(model);
                    }
                }
            );

            global.registerAction(axisModel, axisActions.out, ({
                lastSeries,
                lastIndex
            }) => {
                this.hideTooltip(model);
            });
        } else if (trigger === 'item') {
            let zr = global.getZr();
            let delayTimeout;
            global.on(triggerOn, ({ offsetX, offsetY }) => {
                // target shape 类型, 拿target.data显示
                let target = zr.findHover(offsetX, offsetY).target;
                if (!target) return;

                if (target.parent && target.parent.groupShape) {
                    target = target.parent;
                }

                let { dataIndex, name, seriesIndex } = target;
                let seriesModel = globalModel.getComponentByIndex(
                    'series',
                    seriesIndex
                );
                let content = {};
                let data = target.data;

                if (typeof seriesIndex === 'undefined') {
                    if (typeof data !== 'undefined') {
                        content.series = [data];
                    } else {
                        content = false;
                    }
                } else {
                    // let axisModel = seriesModel.getAxisModel('x');

                    if (!seriesModel) return;

                    // if (axisModel) {
                    //     content.axis = {
                    //         dataIndex,
                    //         data: axisModel.getData(dataIndex),
                    //         startIndex: axisModel.startIndex,
                    //         axisIndex: axisModel.startIndex ? dataIndex - axisModel.startIndex : undefined
                    //     };
                    // }
                    if (!data) {
                        if (typeof dataIndex !== 'undefined') {
                            data = seriesModel.get('stack')
                                ? seriesModel.points[dataIndex][2]
                                : seriesModel.getRealData(dataIndex);
                        } else if (typeof name !== 'undefined') {
                            data = seriesModel.findDataNamed(name);
                        }
                    }

                    if (typeof data !== 'undefined') {
                        content.series = [
                            {
                                ...data,
                                name: data.name || seriesModel.get('name'),
                                value: data.value || data,
                                color:
                                    data.color || seriesModel.getSeriesColor(),
                                seriesIndex: seriesModel.index,
                                type: seriesModel.type,
                                dataIndex: dataIndex
                            }
                        ];
                    }
                }

                if (content) {
                    let toFixedNumber = model.get('toFixedNumber');
                    let dataFormatter = model.get('dataFormatter');
                    let seriesFormatter =
                        seriesModel &&
                        seriesModel.get('tooltip') &&
                        seriesModel.get('tooltip').formatter;
                    let formatter =
                        seriesFormatter ||
                        model.get('formatter') ||
                        curry(
                            defaultFormatter,
                            toFixedNumber,
                            dataFormatter,
                            undefined
                        );

                    content = formatter(content);

                    if (content) {
                        tooltipContent.setContent(content);
                        if (showDelay) {
                            delayTimeout = setTimeout(() => {
                                tooltipContent.show(model);
                                this._updatePosition(
                                    offsetX,
                                    offsetY,
                                    model,
                                    global,
                                    'item',
                                    target
                                );
                            }, showDelay);
                        } else {
                            tooltipContent.show(model);
                            this._updatePosition(
                                offsetX,
                                offsetY,
                                model,
                                global,
                                'item',
                                target
                            );
                        }
                    }
                } else {
                    this.hideTooltip(model);
                }
            });

            global.on('mouseout', (e) => {
                delayTimeout && clearTimeout(delayTimeout);
                this.hideTooltip(model);
            });

            global.on('mouseup', (e) => {
                delayTimeout && clearTimeout(delayTimeout);
                this.hideTooltip(model);
            });
        }

        // global.on('mouseover', function (e) {
        //     console.log(e)
        // })
    }

    _updatePosition(x, y, model, global, type, el, dataIndex) {
        let viewWidth = global.getWidth();
        let viewHeight = global.getHeight();
        let positionExpr = model.get('position');
        let offset = model.get('offset');
        let confine = model.get('confine');
        let content = this._tooltipContent;

        let contentSize = content.getSize();

        if (typeof positionExpr === 'function') {
            ({ x, y } = positionExpr({ x, y, model, dataIndex }));
        } else if (isArray(positionExpr)) {
            x = positionExpr[0];
            y = positionExpr[1];
        // eslint-disable-next-line no-empty
        } else if (isObject(positionExpr)) {

        } else if (typeof positionExpr === 'string' && el) {
            let rect = { x, y, width: 0, height: 0 };

            if (type === 'axis' && el.series && el.series[0]) {
                rect.x = el.series[0].x;
                rect.y = el.series[0].y;
            } else if (type === 'item' && el) {
                rect = el.getBoundingRect().clone();
                rect.applyTransform(el.transform);
            }

            let pos = calcTooltipPosition(positionExpr, rect, contentSize);

            x = pos[0];
            y = pos[1];
        } else {
            let pos = refixTooltipPosition(
                x,
                y,
                content.el,
                viewWidth,
                viewHeight,
                offset
            );
            x = pos[0];
            y = pos[1];
        }

        if (confine) {
            let pos = confineTooltipPosition(
                x,
                y,
                content.el,
                viewWidth,
                viewHeight
            );
            x = pos[0];
            y = pos[1];
        }

        content.moveTo(x, y);
    }

    remove() {
        super.remove();

        this._tooltipContent.remove();
    }
}

function defaultFormatter(
    toFixedNumber,
    dataFormatter,
    axisFormatter = d => d,
    content
) {
    if (!content) return;
    let { axis, series } = content;
    let axisData = axis && axis.data;
    let str =
        typeof axisData !== 'undefined' ? axisFormatter(axisData, axis) : '';
    let isFirst = true;
    let hasData = false;

    each(series, function (item) {
        let value = isArray(item.value) ? item.value[1] : item.value;

        if (typeof value === 'undefined') return;

        let data = dataFormatter
            ? dataFormatter(value, item)
            : typeof value === 'number' ? value.toFixed(toFixedNumber) : value;

        if (data || data === 0) {
            str += `
                    ${typeof axisData === 'undefined' && isFirst ? '' : '</br>'}
                    ${
                item.color
                    ? `<span style="display: inline-block; margin-right: 5px; border-radius: 10px; width: 9px; height: 9px; background-color: ${
                    item.color
                    }"></span>`
                    : ''
                }
                    ${item.name ? item.name + ': ' : ''}${data}
            `;
            isFirst = false;
            hasData = true;
        }
    });

    return hasData ? str : false;
}

function refixTooltipPosition(
    x,
    y,
    el,
    viewWidth,
    viewHeight,
    offset = [0, 0]
) {
    var size = getOuterSize(el);
    var width = size.width;
    var height = size.height;

    if (x + width + offset[0] > viewWidth) {
        x -= width + offset[0];
    } else {
        x += offset[0];
    }

    if (y + height + offset[1] > viewHeight) {
        y -= height + offset[1];
    } else {
        y += offset[1];
    }
    return [x, y];
}

function confineTooltipPosition(x, y, el, viewWidth, viewHeight) {
    var size = getOuterSize(el);
    var width = size.width;
    var height = size.height;

    x = Math.min(x + width, viewWidth) - width;
    y = Math.min(y + height, viewHeight) - height;
    x = Math.max(x, 0);
    y = Math.max(y, 0);

    return [x, y];
}

function getOuterSize(el) {
    var width = el.clientWidth;
    var height = el.clientHeight;

    // Consider browser compatibility.
    // IE8 does not support getComputedStyle.
    if (document.defaultView && document.defaultView.getComputedStyle) {
        var stl = document.defaultView.getComputedStyle(el);
        if (stl) {
            width +=
                parseInt(stl.paddingLeft, 10) +
                parseInt(stl.paddingRight, 10) +
                parseInt(stl.borderLeftWidth, 10) +
                parseInt(stl.borderRightWidth, 10);
            height +=
                parseInt(stl.paddingTop, 10) +
                parseInt(stl.paddingBottom, 10) +
                parseInt(stl.borderTopWidth, 10) +
                parseInt(stl.borderBottomWidth, 10);
        }
    }

    return { width: width, height: height };
}

function calcTooltipPosition(position, rect, contentSize) {
    var domWidth = contentSize[0];
    var domHeight = contentSize[1];
    var gap = 5;
    var x = 0;
    var y = 0;
    var rectWidth = rect.width;
    var rectHeight = rect.height;
    switch (position) {
        case 'inside':
            x = rect.x + rectWidth / 2 - domWidth / 2;
            y = rect.y + rectHeight / 2 - domHeight / 2;
            break;
        case 'top':
            x = rect.x + rectWidth / 2 - domWidth / 2;
            y = rect.y - domHeight - gap;
            break;
        case 'bottom':
            x = rect.x + rectWidth / 2 - domWidth / 2;
            y = rect.y + rectHeight + gap;
            break;
        case 'left':
            x = rect.x - domWidth - gap;
            y = rect.y + rectHeight / 2 - domHeight / 2;
            break;
        case 'right':
            x = rect.x + rectWidth + gap;
            y = rect.y + rectHeight / 2 - domHeight / 2;
    }
    return [x, y];
}
