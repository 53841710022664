import { each, isArray, find } from 'zrender/src/core/util'

import log from './track';
import { VERSION } from '../configs'

export var tracking = true;

/**
 * 是否开启统计
 *
 * @param {boolean} bool true或false
 */
export function track(bool) {
    tracking = bool;
}

export function debounce(fn,wait){
    var timer = null;
    return function(opts){
        clearTimeout(timer)
        timer = setTimeout(()=>{
            fn(opts)
        },wait)
    }
}

let stat = {};
let hqdataStat = [];

export function doStat(option, SeriesModel, ComponentModel, hqStatus = false) {
    if (hqStatus) {
        hqdataStat.push(option);
        return;
    }

    each(option, function (item, name) {
        if (name === 'series') {
            if (SeriesModel.hasClass && SeriesModel.hasClass(name)) {
                if (isArray(item)) {
                    let typestore = [];
                    each(item, function (seriesItem) {
                        let type = seriesItem.aliasType || seriesItem.type
                        if (!stat[type]) {
                            stat[type] = 0;
                        }
                        let findAlready = false;
                        find(typestore, function(i){
                            if (i === type) {
                                findAlready = true;
                            }
                        })
                        if (!findAlready) {
                            typestore.push(type);
                            stat[type]++;
                        }
                    });
                } else {
                    let type = item.aliasType || item.type
                    if (!stat[type]) {
                        stat[type] = 0;
                    }

                    stat[type]++;
                }
            }
        } else {
            if (ComponentModel.hasClass(name)) {
                if (!stat[name]) {
                    stat[name] = 0;
                }

                // if (isArray(item)) {
                //     stat[name] += item.length;
                // } else {
                //     stat[name]++;
                // }
                stat[name]++;
            }
        }
    });
}

export function statToString() {
    let str = '';

    if (hqdataStat.length) {
        each(hqdataStat, function (seriesItem) {
            let type = 'hqd'+seriesItem.type + (seriesItem.dType||'')
            if (!stat[type]) {
                stat[type] = 0;
            }

            stat[type]++;
        });
    }

    each(stat, function (value, name) {
        str += `${name}-${value}|`;
    });

    str = str.slice(0, -1);

    return str;
}

function tongji(opts) {
    if (tracking) {
        var datavTrackId = '';
        if (opts && opts.datavTrackId) {
            datavTrackId = opts.datavTrackId+'.';
        }
        log({
            _sid: datavTrackId+'iwencai_datav_d3charts.load',
            id: datavTrackId+'iwencai_datav_d3charts.load',
            datavIwencai: 'iwencai_datav_d3charts.load',
            version: VERSION,
            widgetN: statToString(),
            fid: 'iwencai_datav'
        });
    }
}

export var tongjiDebounce = debounce(tongji, 3000);