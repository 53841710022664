import { Path } from '../util/graphic';
import d3arc from 'd3-shape/src/arc';

export default Path.extend({
    type: 'd3-arc',

    shape: {

        // centroid: null,

        // 内环半径
        innerRadius: 0,

        // 外环半径
        outerRadius: 0,

        // 圆弧边角弧度
        cornerRadius: 0,

        padRadius: 0,

        // 0: y正方向, 顺时针开始
        startAngle: 0,

        endAngle: Math.PI * 2,

        padAngle: 0

    },

    style: {
        stroke: null,
        fill: null
    },

    updateArc() {
        !this.arc && (this.arc = d3arc());
        let { arc, shape } = this;

        for (let p in shape) {
            !isNaN(shape[p]) && arc[p] && arc[p](shape[p]);
        }
    },

    buildPath: function (context, shape) {

        this.updateArc();
        this.arc.context(context)();
        
    }

})
