/* eslint-disable no-unreachable */
/**
 * k线图 by zzy
 */
import SeriesModel from '../../model/Series';
import {
    each,
    map,
    isArray,
    setObjectKV,
    getObjectKV
} from '../../util';

export const TYPE = 'hqbar';

/*
 k线图，配合dataPool行情接口使用更方便。
*/

export default class HqbarModel extends SeriesModel {
    static type = TYPE;

    type = TYPE;

    isScatter = false;

    static emphasisList = ['symbol', 'link', 'label', 'line', 'area'];

    static defaultOption = {
        $dataIndex: undefined, // {number} 依赖的$data的index，格式同上，优先级低于data，取得的数据需符合data的格式
        dataKey: undefined, // {string|number} 搭配$dataIndex使用，方便从依赖的$data中取数据，等同于 data.map((d) => d[dataKey])，最终取得的数据需符合data的格式
        $axisIndex: [0, 1], // {Array} 所依赖axis的index，需包括一个x轴和y轴
        name: undefined, // {string} 系列名称，用于tooltip的显示，legend 的图例筛选

        // {string} kline类型 'kline' | 'vol' | 'bamboo'
        // 如果是vol类型，字段对象需要有 {t: '', n: ''}  两个属性
        // 如果是kline类型，字段对象需要有 {t: '', o: '', a: '', i: '', c:''}  5个属性
        hqbarType: 'kline' || 'bamboo',

        getColorKey: function (d) { // {function} 获取颜色函数
            return d.s
        },

        getXKey: function (d) { // {function} 获取x的值
            return d.t;
        },

        hq: { // 行情相关配置
            // 因为缩放后使用的是 stroke的颜色，
            // 默认如果 up和down配置相同的stroke颜色，缩放后使用各自的fill颜色
            up: {
                // k线 边框线色
                stroke: 'rgba(247,48,75,1)',
                // k线填充色
                fill: 'rgba(247,48,75,1)'
            },
            down: {
                stroke: 'rgba(48,172,99,1)',
                fill: 'rgba(48,172,99,1)'
            },
            eq: {
                stroke: 'rgba(68,68,68,1)',
                fill: 'rgba(68,68,68,1)'
            }
        },

        // 折线配置
        line: {
            normal: {
                show: true, // {boolean} 是否显示折线
                // 普通样式
                style: {
                    fill: undefined,
                    stroke: '#058499',
                    lineWidth: 2
                }
            },
            emphasis: {}
        },

        // 拐点标记
        symbol: {
            normal: {
                // 普通状态
                show: false, // {boolean|string} 是否显示拐点标记， true 显示坐标轴对应点 | false 不显示 | 'all' 显示全部
                type: 'circle', // {string} symbol类型 'line' | 'rect' | 'roundRect' | 'square' | 'circle' | 'diamond' | 'pin' | 'arrow' | 'triangle' 或svg的path
                offset: [0, 0], // {Array} [x, y]，偏移位置
                size: 8, // {number} symbol大小
                // 普通样式
                style: {}
            },
            emphasis: {
                // 高亮状态
                show: false, // {boolean} 是否高亮显示
                // 高亮样式
                style: {}
            }
        },

        // 拐点上方的文本
        label: {
            normal: {
                // 普通状态
                show: false, // {boolean|string} 是否显示拐点上方值， true 显示坐标轴对应点 | false 不显示 | 'all' 显示全部
                // 普通样式
                style: {
                    position: 'top'
                },
                formatter: undefined // {Function} 文本格式化函数
            },
            emphasis: {
                show: false, // {boolean} 是否高亮显示
                // 高亮样式
                style: {}
            }
        },

        // legendHoverLink: false, // {Boolean} 是否与图例进行高亮联动
        // selectedList: [], // {Array} 选中项，使用数据下标指定
        // enableSelect: false, // {boolean|string} 是否开启选中, single(true) | multiple | false

        cursor: 'default', // {string} 鼠标悬浮时在图形元素上时鼠标的样式是什么。同 CSS 的 cursor。
        zlevel: 0, // {number} 所有图形的 zlevel 值
        z: 3 // {number} 组件的所有图形的z值。控制图形的前后顺序。z值小的图形会被z值大的图形覆盖。
    };

    formatOption(option) {
        super.formatOption(...arguments);

        if (option.color) {
            if (!getObjectKV(option, 'line.normal.style.stroke')) {
                setObjectKV(option, 'line.normal.style.stroke', option.color);
            }
            if (!getObjectKV(option, 'symbol.normal.style.fill') && !getObjectKV(option, 'symbol.normal.style.stroke')) {
                setObjectKV(option, 'symbol.normal.style.fill', 'white');
                setObjectKV(option, 'symbol.normal.style.stroke', option.color);
            }
        }
    }

    // 返回的数组，第一位用于x轴计算
    // 第二位用于 y轴计算，其他信息可以放在第三位
    formatData(data) {
        switch (this.get('hqbarType')) {
            case 'kline':
            case 'bamboo':
                return map(data, (d, i) => [i, [d.o, d.a, d.i, d.c], d]);
                break;
            case 'vol':
                return map(data, (d, i) => [i, [d.n], d]);
                break;
            default:
                break;
        }
    }

  /*
   * lineX, lineYTop, lineYBottom k线中标识最高价和最低价
   * 在缩放到宽度小于3时，使用lineX, lineYTop, lineYBottom画直线，省略画矩形
   */
   getDrawData(info) {
        let xAxis = this.getAxisModel('x');
        let yAxis = this.getAxisModel('y');
        let xScale = xAxis.scale;
        let yScale = yAxis.scale;
        let bandwidth = xScale.bandwidth();
        let offset = bandwidth / 2;
        let getXKey = this.get('getXKey');

        let xPos = xScale(getXKey(info));

        let type = this.get('hqbarType');
        if (type === 'kline' || type === 'bamboo') {
            return {
                x: xPos,
                y: yScale(Math.max(info.o, info.c)),
                h: Math.abs(yScale(info.o) - yScale(info.c)),
                lineX: xPos + offset,
                lineYTop: yScale(info.a),
                lineYBottom: yScale(info.i)
            }
        } else if (type === 'vol') {
            // 为了让最小的柱子也有一个高度，所以留了1.5
            // 通常有低线，所以减了1
            let adjustH = yScale.range()[0] - Math.floor(yScale(info.n)) - 1;
            let lowestBar = adjustH < 1 ? 1 : 0
            return {
                x: xPos,
                y: yScale(info.n) - lowestBar,
                h: adjustH + lowestBar,
                lineX: xPos + offset,
                lineYTop: yScale(info.n),
                lineYBottom: yScale.range()[0]
            }
        }
    }

    update() {
        let xAxis = this.getAxisModel('x');
        let yAxis = this.getAxisModel('y');
        let xScale = xAxis.scale;
        let yScale = yAxis.scale;
        let yDomain = yScale.domain();
        let xDomain = xScale.domain();
        let bandwidth = xScale.bandwidth();
        let getColorKey = this.get('getColorKey');
        let getXKey = this.get('getXKey');
        let offset = bandwidth / 2;
        let points = [];
        let pointsByColor = {
            up: [],
            eq: [],
            down: []
        }

        let {
            startIndex,
            endIndex
        } = xAxis;

        let y0 = yScale(
            yDomain[1] < 0 ? yDomain[1] : yDomain[0] > 0 ? yDomain[0] : 0
            );

        let data = this.getData().slice(
            startIndex,
            endIndex + 1
            );

        each(data, ([x, d, info], drawIndex) => {
            // let xvalue = xDomain.find((x,i)=> i == drawIndex);
            let xPos = xScale(getXKey(info));
            let ys = this.getDrawData(info);
            let item = [
            xPos,
            ys,
            info,
            drawIndex + startIndex,
            drawIndex
            ];

            points.push(item);

            if (getColorKey(info) === 'be') {
                pointsByColor.down.push(item)
            } else if (getColorKey(info) === 'ab') {
                pointsByColor.up.push(item)
            } else {
                pointsByColor.eq.push(item)
            }

        });

        // 每个柱子的实际宽度
        this.bandwidth = bandwidth;
        // 中心线偏移
        this.centerOffset = offset;
        this.pointsByColor = pointsByColor;

        this.points = points;
        this.y0 = y0;
        this.startIndex = xAxis.startIndex;
        this.endIndex = xAxis.endIndex;
        this.yAxis = yAxis;
    }

    getSeriesColor(name) {
        if (this.get('color')) {
            return this.get('color');
        }

        let lineOpt = this.get('line').normal;
        let symbolOpt = this.get('symbol').normal;

        return lineOpt.show ?
            lineOpt.style.stroke : (symbolOpt.style.fill || symbolOpt.style.stroke);
    }
}
