// 其他行情数据处理
import {
    default as DataProvider
} from '../DataProvider';
import { each, isArray } from 'zrender/src/core/util'
import { filterStatus, decimalRound, filterNum, convertToShowData, getStockType, getStockstatus } from '../../util/hqHelper'

/* 请勿修改已有函数，可添加
 * 1 日期  有些接口指的是日期，有些是时间戳
 * 7 开盘 (常用于k线数据)
 * 8 最高 (常用于k线数据)
 * 9 最低 (常用于k线数据)
 * 10 最新价 (常用于五档买卖中的现价)
 * 11 收盘 (常用于k线数据)
 * 12 成交量分类 (常用于五档买卖，返回值 $12>4(买入) $12<4(卖出) $12==4(平盘))
 * 13 成交量
 * 19 成交额
 * 49 现手 (常用于五档买卖,返回值是股，需要/100才是手)
 * 1968584 换手率 (常用于k线数据)
 * 625295 成交笔数 (常用于五档买卖)
*/

/*+----------------+-----------------------------------------------+
| curP           | 最新价 (常用于五档买卖中的现价)               |
+----------------+-----------------------------------------------+
| curPStatus     | 最新价状态                                    |
+----------------+-----------------------------------------------+
| dynamicSYL     | 动态市盈率                                    |
+----------------+-----------------------------------------------+
| hqStatus       |                                               |
+----------------+-----------------------------------------------+
| hqStatusDetail |                                             |
+----------------+-----------------------------------------------+
| isStop         | 是否停牌                                      |
+----------------+-----------------------------------------------+
| keepLen        | 推荐的价格精度                                |
+----------------+-----------------------------------------------+
| maxP           | 最大值                                        |
+----------------+-----------------------------------------------+
| maxPStatus     | 最大值状态                                    |
+----------------+-----------------------------------------------+
| minP           | 最小值                                        |
+----------------+-----------------------------------------------+
| minPStatus     | 最小值状态                                    |
+----------------+-----------------------------------------------+
| n              | 成交量                                        |
+----------------+-----------------------------------------------+
| name           | 股票名称                                      |
+----------------+-----------------------------------------------+
| np             | 成交额                                        |
+----------------+-----------------------------------------------+
| openP          | 开盘价                                        |
+----------------+-----------------------------------------------+
| openPStatus    | 开盘价状态                                    |
+----------------+-----------------------------------------------+
| rate           | 涨跌幅 展示时后面需要带％                     |
+----------------+-----------------------------------------------+
| rateP          | 涨跌额                                        |
+----------------+-----------------------------------------------+
| shortcode      | 股票代码                                      |
+----------------+-----------------------------------------------+
| sjl            | 市净率 展示时后面需要带％                     |
+----------------+-----------------------------------------------+
| staticSYL      | 静态市盈率                                    |
+----------------+-----------------------------------------------+
| stockM         | 流通市值                                      |
+----------------+-----------------------------------------------+
| totalM         | 总市值                                        |
+----------------+-----------------------------------------------+
| turnRate       | 换手率 (常用于k线数据)                        |
+----------------+-----------------------------------------------+
| yesterdayP     | 昨收 (期货等市场用 昨结价格                   |
+----------------+-----------------------------------------------+
| zf             | 振幅                                          |
+----------------+-----------------------------------------------+*/


var getter = {
    time:function(d){return d[1]; },
    openP:function(d){return d[7]; },
    maxP:function(d){return d[8]; },
    minP:function(d){return d[9]; },
    curP:function(d){return d[10]; },
    closeP:function(d){return d[11]; },
    dealType:function(d){return d[12]; },
    vol:function(d){return d[13]; },
    volMoney:function(d){return d[19]; },
    curVol:function(d){return d[49]; },
    turnRate:function(d){return d[1968584]; },
    dealNum:function(d){return d[625295]; }
};

// 五档数据处理
// 增加了
// 成交量,成交金额的过滤,
// 价格过滤
// 颜色判断
function fiverangeArrange({remoteData, code, stockType}){
    var data = remoteData.items;
    var r = {};
    for(var i in data){
        if (/^(25|27|29|151|155|31|33|35|153|157)$/.test(i)) {
            r[i] = filterNum(data[i], stockType.needConvertoShou);
        }else{
            r[i] = data[i] == '' ? '': decimalRound(data[i], stockType.keepLen);
            r['color-'+i] = filterStatus(data[i], data['6']);
        }
    }
    return r;
}


// 实时行情数据
function realheadArrange({remoteData, code, stockType}){
    var r = remoteData.items;
    var hqstatus = getStockstatus(stockType.msg, r);
    var shortcode = code.split('_')[1];
    var keepLen = stockType.keepLen;
    var needConvertoShou = stockType.needConvertoShou;

    // 昨收 (期货等市场用 昨结价格)
    var yesterdayP = r['66'] ? r['66'] : (r['6'] === '' ? 0 : r['6']);
    var preRateP, preRate;
    if(r['66']){
        preRateP = r['10'] ? r['10'] - r['66'] : undefined;
        preRate = r['10'] ? preRateP/r['66']*100 : undefined;
    }else{
        preRateP = r['264648'];
        preRate = r['199112'];
    }

    var showData = convertToShowData({
        o : r['7'],
        a: r['8'],
        i : r['9'],
        c : r['10'],
        h : r['1968584'],
        n : r['13'],
        np : r['19'],
        yc : yesterdayP,
        preRateP: preRateP,
        preRate: preRate
    }, keepLen, needConvertoShou);

    var resultAfterArrage = {
        hqStatus: hqstatus && hqstatus.hqStatus,
        hqStatusDetail: hqstatus && hqstatus.hqStatusDetail,

        name:r.name,
        shortcode:shortcode,
        // 推荐的价格 精度
        keepLen:keepLen,
        // 是否停牌
        isStop: r['stop'],

        openP: showData.openP,
        openPStatus: showData.openPStatus,
        maxP: showData.maxP,
        maxPStatus: showData.maxPStatus,
        minP: showData.minP,
        minPStatus: showData.minPStatus,
        curP: showData.curP,
        curPStatus: showData.curPStatus,
        yesterdayP: showData.yesterdayP,
        // 成交量
        n: showData.n,
        // 成交额
        np: showData.np,
        // 换手滤 展示时后面需要带％
        turnRate: showData.turnRate,
        // 涨跌额
        rateP: showData.rateP,
        // 涨跌幅 展示时后面需要带％
        rate: showData.rate,

        // 静态市盈率
        staticSYL: r['134152'],
        // 动态市盈率
        dynamicSYL: r['2034120'],

        // 市净率 展示时后面需要带％
        sjl: decimalRound(r['592920'], 2),

        // 总市值
        totalM: filterNum(r['3541450']),

        // 流通市值
        stockM: filterNum(r['3475914']),

        // 振幅
        zf: decimalRound(r['526792'], 2)
    }
    return resultAfterArrage;

}


function dealDetailHtml(d, yesterdayPrice, needConvertoShou){
    var timeArr=[],timeStr = '--';
    if (d.His) {
        timeArr = d.His.split(':');
        timeStr = timeArr[0]+':'+timeArr[1];
    }
    var curPrise = getter.curP(d);
    var dealType = getter.dealType(d);
    var dealTypeStr = '';
    if (dealType>4) {
        dealTypeStr = 'buy';
    } else{
         dealTypeStr = dealType == 4 ? 'eq':'sell';
    }
    var priceColor = filterStatus(curPrise, yesterdayPrice);
    var curVol = filterNum(getter.curVol(d), needConvertoShou);

    return {
        time: timeStr,
        color: priceColor,
        price: curPrise,
        // 买入还是卖出分类
        dealTypeStr,
        curVol
    };
}

// 成交明细数据处理
function exchangedetailArrange({remoteData, code, stockType}){
    var r = {};
    r.items = [];
    if (remoteData.items.length) {
        for (var i = 0; i < remoteData.items.length; i++) {
            r.items.push(dealDetailHtml(remoteData.items[i], remoteData['6'], stockType.needConvertoShou));
        }
    }else{
        return '';
    }

    return r;
}

var arrangeFunc = {
    'fiverangeArrange': fiverangeArrange,
    'exchangedetailArrange': exchangedetailArrange,
    'realheadArrange': realheadArrange
};

/**
* 数据处理-行情其他数据接口处理
* 包含以下行情逻辑
* 1. 可开启或关闭实时更新
* 继承 DataProvider
* 需要把整理的数据用 this.fetchSuccess(data) 通知出去
* !!不可以直接对this._data 进行赋值
*
* @extends DataProvider
*/
export default class OthersHq extends DataProvider{
    static type = 'othersHq'

    static defaultOption = {
        code: 'hs_300033',
        dType: 'realhead',
        urlFormatter: function({version, protocol, dType, code}) {
            return `${protocol}//d.10jqka.com.cn/${version}/${dType}/${code}/last.js`;
        },
        // 指定callbackname
        callbackName: null
    }

    constructor(){
        super(...arguments);
        // 定时更新控制
        this.keepingGetHandle;
    }

    /**
     * 初始化
     * @override
     */
    init () {
        let that = this;
        let code = that.get('code');
        let stockType = getStockType(code);
        this.stockType = stockType;

        that.getFromWeb(function(remoteData){
            let dType = that.get('dType');

            let afterArrage;
            if(typeof arrangeFunc[dType+'Arrange'] === 'function'){
                afterArrage = arrangeFunc[dType+'Arrange']({
                    remoteData,
                    code,
                    stockType: that.stockType
                });
            }

            that.fetchSuccess({
                remoteData,
                afterArrage,
                stockType: that.stockType
            });

            if(that.get('isKeepingGet')){
                that.keepingGetData();
            }
        });

    }

    getFromWeb (callback) {
        let that = this;
        let urlFormatter = this.get('urlFormatter');
        let { callbackName: definedCallback } = this.option;
        let reqUrl = urlFormatter({
            ...this.option
        });
        let { url, callbackName } = this.getHqCallbackNameAndUrl(reqUrl, definedCallback);

        this.fetchRemote(url, {
                jsonpCallbackFunction: callbackName
            })
            .then(function(d) {
                callback(d);
            }).catch(function(ex) {
                that.fetchFail(ex);
            });
    }

    keepingGetData () {
        var that = this;

        this.keepingGetHandle = setTimeout(function othersHqRepeat() {
            that.getFromWeb(function(remoteData){
                var dType = that.get('dType');
                var afterArrage;
                if(typeof arrangeFunc[dType+'Arrange'] === 'function'){
                    afterArrage = arrangeFunc[dType+'Arrange']({
                        remoteData,
                        code: that.get('code'),
                        stockType: that.stockType
                    });
                }

                that.fetchSuccess({
                    remoteData,
                    afterArrage,
                    stockType: that.stockType
                });
            });
            that.keepingGetHandle = setTimeout(othersHqRepeat, that.get('intervalTime'));
        }, that.get('intervalTime'));

    }
}


