import { slice } from './array';
import identity from './identity';
import { Group, Line, Text } from '../../util/graphic';
import { subPixelOptimizeLine } from '../../util/graphic';
import { isFunction, isArray, clone } from '../../util';

var top = 1,
  right = 2,
  bottom = 3,
  left = 4,
  epsilon = 1e-6;

function translateX(x, pos) {
  return [x, pos];
}

function translateY(y, pos) {
  return [pos, y];
}

function center(scale) {
  return function (d, onCenter) {
    var range = scale.range();
    var offset;
    if (onCenter === false) {
      offset = Math.max(0, scale.paddingInner ? scale.step() * (1 - scale.paddingInner() / 2) : scale.step() / 2);
    } else {
      offset = Math.max(0, scale.bandwidth ? scale.bandwidth() : scale.step()) / 2; // Adjust for 0.5px offset.
    }
    if (scale.round()) offset = Math.round(offset);

    if (range[0] < range[1]) {
      return Math.min(Math.max(scale(d) + offset, range[0]), range[1]);
    } else {
      return Math.max(Math.min(scale(d) + offset, range[0]), range[1]);
    }
  };
}

function entering() {
  return !this.__axis;
}

function axis(orient, scale) {
  var tickArguments = [],
    tickValues = null,
    tickFormat = null,
    alwaysShowFirst = false,
    alwaysShowLast = false,
    k = orient === top || orient === left ? -1 : 1,
    x = orient === left || orient === right ? 'x' : 'y',
    transform = orient === top || orient === bottom ? translateX : translateY,
    textAlign = orient === right ? 'left' : orient === left ? 'right' : 'center',
    textBaseline = orient === top ? 'bottom' : orient === bottom ? 'top' : 'middle',
    axisLine = {
      show: true
    },
    axisTick = {
      show: true,
      outerSize: 6,
      innerSize: 0
    },
    axisLabel = {
      show: true,
      padding: 3,
      rotation: Math.PI / 6
    },
    linePos;

  var pos = 200;

  linePos = pos;

  function axis(view, model, globalModel, global) {
    var values = axis.values(),
      format = axis.format(),
      range = scale.range(),
      range0 = Math.min(range[0], range[range.length - 1]),
      range1 = Math.max(range[0], range[range.length - 1]),
      position = (scale.step ? center : identity)(scale.copy()),
      tickSizeOuter = axisTick.outerSize,
      tickSizeInner = axisTick.innerSize,
      labelInside = axisLabel.inside,
      spacing = axisLabel.padding,
      vertical = axisLabel.vertical,
      labelPos = pos,
      axisType = model.getAxisType(),
      domain = scale.domain();

    let needAnimation = model.get('animation');
    let animationDuration = model.get('animationDuration');
    let animationEasing = model.get('animationEasing');
    let cursor = model.get('cursor');

    linePos = pos;

    var tickNum = values.length;

    if (axisLine.show) {
      var lineShape;

      if (axisLine.onZero) {
        let zeroAxis = globalModel.getComponentByIndex('axis', axisLine.onZeroAxisIndex);

        if (zeroAxis) {
          let zeroScale = zeroAxis.scale;
          let zeroDomain = zeroScale.domain();

          if (zeroDomain[0] < 0 || zeroDomain[1] < 0) {
            let gridIndex = model.get('$gridIndex');
            isArray(gridIndex) && (gridIndex = gridIndex[0]);
            let axisPosition = model.get('position');
            let zeroRange = zeroAxis.scale.range();
            linePos = zeroScale(0);

            switch (axisPosition) {
              case 'left':
              case 'right':
                linePos = Math.max(Math.min(zeroRange[1], linePos), zeroRange[0]);
                break;
              case 'top':
              case 'bottom':
                linePos = Math.max(Math.min(zeroRange[0], linePos), zeroRange[1]);
                break;
            }
          }
        }
      }

      // 是否根据lineWidth调整位置???
      // var halfLineWidth = (axisLine.style.lineWidth || 1) / 2;

      // switch (orient) {
      //   case right:
      //   case bottom:
      //     linePos += halfLineWidth;
      //     break;
      //   case left:
      //   case top:
      //     linePos -= halfLineWidth;
      //     break;
      // }

      if (orient === left || orient == right) {
        lineShape = {
          x1: linePos,
          y1: range0,
          x2: linePos,
          y2: range1
        };
      } else {
        lineShape = {
          x1: range0,
          y1: linePos,
          x2: range1,
          y2: linePos
        };
      }

      view.setShape('line', Line, subPixelOptimizeLine({
        shape: lineShape,
        style: axisLine.style,
        z2: 1,
        silent: true
      }), {
          animation: needAnimation,
          duration: animationDuration,
          easing: animationEasing,
          animateList: {
            shape: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 0
            }
          }
        });
    }

    if (axisTick.show) {
      let attrs = [];
      let tickAttr = {
        shape: {
          ...{ x1: 0, y1: 0, x2: 0, y2: 0 },
          [x + '1']: k * tickSizeOuter,
          [x + '2']: -k * tickSizeInner
        },
        style: axisTick.style
      };

      if (!axisTick.onCenter && axisType === 'ordinal') {
        attrs.push(subPixelOptimizeLine({
          ...clone(tickAttr),
          position: transform(range0, linePos),
          key: -1,
          silent: true
        }));
        attrs.push(subPixelOptimizeLine({
          ...clone(tickAttr),
          position: transform(range1, linePos),
          key: tickNum,
          silent: true
        }));
      }

      for (let i = 0; i < tickNum; i++) {
        if (!axisTick.onCenter && i === tickNum - 1 && axisType === 'ordinal' && domain[domain.length - 1] === values[i]) break;

        attrs.push(subPixelOptimizeLine({
          ...clone(tickAttr),
          position: transform(position(values[i], axisTick.onCenter), linePos),
          key: i,
          silent: true
        }));
      }

      view.setShapeGroup('tickGroup', Line, attrs, {
        animation: needAnimation,
        duration: animationDuration,
        easing: animationEasing,
        animateList: {
          position: [0, 0]
        }
      });
    }

    if (axisLabel.show) {
      let attrs = [];
      let truncate = null;

      axisLabel.followLine && (labelPos = linePos);

      let axisPosition = model.get('position');
      let interval = model.interval;

      if (axisLabel.truncate && scale.step && interval) {
        let step = scale.step() * interval;
        
        if (axisPosition === 'top' || axisPosition === 'bottom') {
          truncate = {
            outerWidth: step
          };
        } else {
          truncate = {
            outerHeight: step
          };
        }
      }

      for (let i = 0; i < tickNum; i++) {
        let text = format(values[i], i, tickNum);
        let _position = transform(position(values[i]), labelPos);
        let _textAlign = textAlign;
        let _textBaseline = textBaseline;

        if (vertical) {
          text = text.split('').join('\n')
        }

        if (axisLabel.inRange) {
          if (orient === left || orient === right) {
            if (i === 0) {
              values[i] === domain[0] && (_textBaseline = 'bottom') && (_position[1] = range1);
            } else if (i === tickNum - 1) {
              values[i] === domain[domain.length - 1] && (_textBaseline = 'top') && (_position[1] = range0);
            }
          } else {
            if (i === 0) {
              values[i] === domain[0] && (_textAlign = 'left') && (_position[0] = range0);
            } else if (i === tickNum - 1) {
              values[i] === domain[domain.length - 1] && (_textAlign = 'right') && (_position[0] = range1);
            }
          }
        }

        if (axisLabel.inside) {
          if (orient === left || orient === right) {
            _textAlign = 'left';
          } else {
            _textBaseline = 'bottom';
          }
        }

        _position[x === 'x' ? 0 : 1] += k * spacing * (axisLabel.inside ? -1 : 1);

        let labelStyle = axisLabel.style;
        labelStyle = isFunction(labelStyle) ? labelStyle(values[i], i, _position, values) : labelStyle

        attrs.push(globalModel.getFormattedText({
          style: {
            text,
            textVerticalAlign: _textBaseline,
            textAlign: _textAlign,
            ...labelStyle,
            textOrign: 'center',
            truncate
          },
          scale: axisLabel.scale || [1, 1],
          position: _position,
          rotation: axisLabel.rotate / 180 * Math.PI,
          key: values[i],
          cursor,
          z2: 10,
          silent: true
        }));
      }

      view.setShapeGroup('labelGroup', Text, attrs, {
        animation: needAnimation,
        duration: animationDuration,
        easing: animationEasing,
        animateList: {
          position: [0, 0]
        }
      });
    }
  }

  axis.values = function () {
    if (tickValues) return tickValues;

    if (scale.ticks) {
      let ticks = scale.ticks.apply(scale, tickArguments);
      let domain = scale.domain();
      let tickNum = ticks.length;
      let delta = Math.abs(domain[1] - domain[0]);
      let gap = delta / tickNum;

      if (alwaysShowLast) {
        if (Math.abs(ticks[ticks.length - 1] - domain[1]) < gap / 3) {
          ticks.pop();
        }
        ticks.indexOf(domain[1]) === -1 && ticks.push(domain[1]);
      }

      if (alwaysShowFirst) {
        if (Math.abs(ticks[0] - domain[0]) < gap / 3) {
          ticks.shift();
        }
        ticks.indexOf(domain[0]) === -1 && ticks.unshift(domain[0]);
      }

      return ticks;
    } else {
      return scale.domain();
    }
  };

  axis.axisLine = function (_) {
    return arguments.length ? (axisLine = _, axis) : pos;
  };

  axis.axisTick = function (_) {
    return arguments.length ? (axisTick = _, axis) : pos;
  };

  axis.axisLabel = function (_) {
    return arguments.length ? (axisLabel = _, axis) : pos;
  };

  axis.pos = function (_) {
    return arguments.length ? (pos = _, axis) : pos;
  };

  axis.scale = function (_) {
    return arguments.length ? (scale = _, axis) : scale;
  };

  axis.ticks = function () {
    return tickArguments = slice.call(arguments), axis;
  };

  axis.tickArguments = function (_) {
    return arguments.length ? (tickArguments = _ == null ? [] : slice.call(_), axis) : tickArguments.slice();
  };

  axis.tickValues = function (_) {
    return arguments.length ? (tickValues = _ == null ? null : slice.call(_), axis) : tickValues && tickValues.slice();
  };

  axis.tickFormat = function (_) {
    return arguments.length ? (tickFormat = _, axis) : tickFormat;
  };

  axis.alwaysShowFirst = function (_) {
    return arguments.length ? (alwaysShowFirst = _, axis) : alwaysShowFirst;
  };

  axis.alwaysShowLast = function (_) {
    return arguments.length ? (alwaysShowLast = _, axis) : alwaysShowLast;
  };

  axis.linePos = function () {
    return linePos;
  }

  axis.format = function (_, i, totalNum) {
    let format = axisLabel.formatter ? axisLabel.formatter : (scale.tickFormat ? scale.tickFormat.apply(scale, tickArguments) : identity)
    return arguments.length ? format(_, i, totalNum) : format;
  }

  return axis;
}

export function axisTop(scale) {
  return axis(top, scale);
}

export function axisRight(scale) {
  return axis(right, scale);
}

export function axisBottom(scale) {
  return axis(bottom, scale);
}

export function axisLeft(scale) {
  return axis(left, scale);
}
