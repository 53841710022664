import ComponentView from '../../view/Component';
import { each, map, parseSize } from '../../util';
import { Line, subPixelOptimizeLine, Text, setHoverStyle } from '../../util/graphic';
import { Symbol } from '../../util/symbol';
import { TYPE } from './TimelineModel';

export default class TimelineView extends ComponentView {

    static type = TYPE;

    type = TYPE;

    constructor(model) {
        super(...arguments);

        this.dragging = false;

        this.interval = setInterval(() => {
            if (model.get('autoPlay') && !this.dragging) {
                let currentIndex = model.get('currentIndex');
                let data = model.getData();
                if (!data) return;
                let dataLen = data.length;
                let loop = model.get('loop');

                if (model.get('rewind')) {
                    currentIndex--;
                } else {
                    currentIndex++;
                }

                if (currentIndex < 0) {
                    if (!loop) return;

                    currentIndex += dataLen;
                } else if (currentIndex >= dataLen) {
                    if (!loop) return;

                    currentIndex = 0;
                }

                model.set('currentIndex', currentIndex);
            }
        }, model.get('playInterval'))
    }

    render(model, globalModel, global) {
        if (!model.get('show')) return;

        let lineOption = model.get('line');
        let labelOption = model.get('label');
        let symbolOption = model.get('symbol');
        let currentSymbolOption = model.get('currentSymbol');
        let controlBtnOption = model.get('controlBtn');
        let interval = model.get('interval');
        let intervalLength = model.get('intervalLength');
        let alwaysShowLast = model.get('alwaysShowLast');
        let currentIndex = model.get('currentIndex');
        let { left, top, length, scale } = model;
        let orient = model.get('orient');
        let isVertical = orient === 'vertical';
        let data = model.getData();

        if (lineOption.show) {
            let lineShape = {
                x1: left,
                y1: top
            };

            if (isVertical) {
                lineShape.x2 = left;
                lineShape.y2 = top + length;
            } else {
                lineShape.x2 = left + length;
                lineShape.y2 = top;
            }

            this.setShape('line', Line, subPixelOptimizeLine({
                shape: lineShape,
                style: lineOption.style,
                z2: 69
            }), undefined, function (shape) {
                shape.on('click', function ({ offsetX, offsetY }) {
                    let index = getIndexByOffset(offsetX, offsetY, scale, isVertical, model.get('inverse'));
                    let currentIndex = model.get('currentIndex');

                    if (index !== currentIndex) {
                        model.set('currentIndex', index);
                    }
                })
            });
        }

        if (!data) return;

        let values = [];
        let len = data.length;

        if (interval) {
            for (let i = 0; i < len; i += interval) {
                values.push([data[i], i]);
            }
        } else if (intervalLength) {
            let step = Math.ceil(len / (length / intervalLength));

            for (let i = 0; i < len; i += step) {
                values.push([data[i], i]);
            }
        }

        if (alwaysShowLast) {
            let last = data[len - 1];

            last !== values[values.length - 1][0] && values.push([last, len - 1]);
        }

        if (labelOption.show) {
            let formatter = labelOption.formatter;
            let padding = labelOption.padding;
            let labelAttrs = [];

            each(values, function ([value, index]) {
                labelAttrs.push(globalModel.getFormattedText({
                    style: {
                        text: formatter ? formatter(value) : value,
                        textAlign: isVertical ? 'left' : 'center',
                        textVerticalAlign: isVertical ? 'center' : 'top',
                        ...labelOption.style.normal
                    },
                    position: isVertical ? [left + padding, scale(value)] : [scale(value), top + padding],
                    index,
                    z2: 89
                }));
            });

            this.setShapeGroup('labelGroup', Text, labelAttrs, undefined, function (group) {
                group.on('click', function ({ target }) {
                    model.set('currentIndex', target.index);
                });

                group.eachChild(function (child) {
                    setHoverStyle(child, globalModel.getFormattedText({ ...labelOption.style.emphasis }));
                })
            });
        }

        if (symbolOption.show) {
            let symbolAttrs = [];
            let symbolType = symbolOption.type;
            let { width, height } = parseSize(symbolOption.size);
            let ticks = symbolOption.show === 'all' ? map(data, (d, i) => [d, i]) : values;

            each(ticks, function ([value, index]) {
                symbolAttrs.push({
                    shape: {
                        symbolType,
                        x: -width / 2,
                        y: -height / 2,
                        width,
                        height
                    },
                    style: {
                        ...symbolOption.style.normal
                    },
                    position: isVertical ? [left, scale(value)] : [scale(value), top],
                    index,
                    z2: 79
                });
            });

            this.setShapeGroup('symbolGroup', Symbol, symbolAttrs, undefined, function (group) {
                group.on('click', function ({ target }) {
                    model.set('currentIndex', target.index);
                });

                group.eachChild(function (child) {
                    setHoverStyle(child, { ...symbolOption.style.emphasis });
                })
            });

        }

        if (currentSymbolOption.show) {
            let symbolType = currentSymbolOption.type;
            let { width, height } = parseSize(currentSymbolOption.size);
            let value = data[currentIndex];

            this.setShape('currentSymbol', Symbol,
                {
                    shape: {
                        symbolType,
                        x: -width / 2,
                        y: -height / 2,
                        width,
                        height
                    },
                    style: currentSymbolOption.style,
                    position: isVertical ? [left, scale(value)] : [scale(value), top],
                    index: currentIndex,
                    z2: 99
                }, {
                    animation: model.get('animation'),
                    animateList: {
                        position: [0, 0]
                    }
                }, (shape) => {

                    shape.on('mousedown', () => {
                        this.dragging = true;
                    });
                    global.on('mousemove', ({ offsetX, offsetY }) => {
                        updatePoint(model, shape, offsetX, offsetY, scale, isVertical, this.dragging, model.get('realtime'));
                    });
                    global.on('mouseup', ({ offsetX, offsetY }) => {
                        updatePoint(model, shape, offsetX, offsetY, scale, isVertical, this.dragging, true);
                        this.dragging = false;
                    });
                    global.on('globalout', (e) => {
                        let { offsetX, offsetY } = e.event;
                        updatePoint(model, shape, offsetX, offsetY, scale, isVertical, this.dragging, true);
                        this.dragging = false;
                    });
                }
            );
        }

        if (controlBtnOption.show) {
            let { showPlayBtn, showPrevBtn, showNextBtn } = controlBtnOption;
            let starts = [], ends = [];

            showPrevBtn && (showPrevBtn !== 'end' ? starts.push('prev') : ends.push('prev'));
            showNextBtn && (showNextBtn !== 'start' ? ends.push('next') : starts.push('next'));
            showPlayBtn && (showPlayBtn !== 'end' ? starts.push('play') : ends.push('play'));

            renderControlBtn.call(this, 'start', starts, model, controlBtnOption);
            renderControlBtn.call(this, 'end', ends, model, controlBtnOption);
        }
    }

    remove() {
        super.remove();

        clearInterval(this.interval);
    }

}

function getIndexByOffset(offsetX, offsetY, scale, isVertical, inverse) {
    let offset = isVertical ? offsetY : offsetX;
    let range0 = scale.range()[0];

    offset = (isVertical ? offsetY : offsetX) - range0;
    inverse && (offset = -offset);

    let index = Math.max(0, Math.min(Math.round(offset / scale.step()), scale.domain().length - 1));

    return index;
}

function updatePoint(model, shape, offsetX, offsetY, scale, isVertical, dragging, realtime) {
    if (!dragging) return;

    let inverse = model.get('inverse');
    let offset = isVertical ? offsetY : offsetX;
    let range = scale.range();

    inverse && (range = [...range].reverse());

    offset < range[0] && (offset = range[0]);
    offset > range[1] && (offset = range[1]);

    let originalPos = shape.position;

    shape.attr('position', isVertical ? [originalPos[0], offset] : [offset, originalPos[1]]);

    if (realtime) {
        let index = getIndexByOffset(offsetX, offsetY, scale, isVertical, inverse);
        let currentIndex = model.get('currentIndex');

        if (index !== currentIndex) {
            model.set('currentIndex', index);
        }
    }

}

function renderControlBtn(type, arr, model, controlBtnOption) {
    let { gap, size, style } = controlBtnOption;
    let { width, height } = parseSize(size);
    let symbolOption = model.get('symbol');
    let autoPlay = model.get('autoPlay');
    let orient = model.get('orient');
    let { width: symbolHeight, height: symbolWidth } = parseSize(symbolOption.show ? symbolOption.size : 0);
    let { left, top, length } = model;

    each(arr, (item, index) => {
        let symbolType, position, rotation = 0;

        if (item === 'play') {
            symbolType = autoPlay ? controlBtnOption.stopIcon : controlBtnOption.playIcon;
        } else {
            symbolType = controlBtnOption[item + 'Icon'];
        }

        if (orient === 'vertical') {
            if (item === 'prev' || item === 'next') {
                rotation = -Math.PI / 2;
            }
        }

        if (type === 'start') {
            if (orient === 'horizontal') {
                position = [left - symbolWidth / 2 - (index + 1) * gap - (index + 0.5) * width, top];
            } else {
                position = [left, top - symbolHeight / 2 - (index + 1) * gap - (index + 0.5) * height];
            }
        } else {
            if (orient === 'horizontal') {
                position = [left + length + symbolWidth / 2 + (index + 0.5) * width + gap * (index + 1), top];
            } else {
                position = [left, top + length + symbolHeight / 2 + (index + 0.5) * height + gap * (index + 1)];
            }
        }

        this.setShape(item, Symbol, {
            shape: {
                symbolType,
                x: -width / 2,
                y: -height / 2,
                width,
                height
            },
            rotation,
            rectHover: true,
            name: item,
            style: style.normal,
            position
        }, undefined, shape => {
            setHoverStyle(shape, style.emphasis);

            shape.on('click', function ({ target }) {
                let currentIndex = model.get('currentIndex');
                let dataLen = model.getData().length;

                switch (shape.name) {
                    case 'prev':
                        currentIndex--;
                        currentIndex < 0 && (currentIndex = dataLen - 1);
                        model.set('currentIndex', currentIndex);
                        break;
                    case 'next':
                        currentIndex++;
                        currentIndex >= dataLen && (currentIndex = 0);
                        model.set('currentIndex', currentIndex);
                        break;
                    case 'play':
                        // eslint-disable-next-line no-case-declarations
                        let autoPlay = model.get('autoPlay');

                        autoPlay = !autoPlay;
                        target.setShape('symbolType', autoPlay ? controlBtnOption.stopIcon : controlBtnOption.playIcon);
                        model.set('autoPlay', autoPlay);

                        break;
                    default:
                        break;
                }
            })
        });
    })
}
