import FsToday from './FsToday';
import FsHistory from './FsHistory';
import FsMoneyFlow from './FsMoneyFlow';
import { registerDataProvider } from '../DataProvider';

// 分时行情数据 注册
registerDataProvider(FsToday);

// 分时行情历史数据（仅支持前30天数据） 注册
registerDataProvider(FsHistory);

// 资金流向数据
registerDataProvider(FsMoneyFlow);
