import ComponentModel from '../../model/Component';
import { each, map, findIndex, parsePercent, isArray } from '../../util';
import { getPointPos } from './helper';

export const TYPE = 'region';

export default class RegionModel extends ComponentModel {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量
    
    static emphasisList = ['style', 'iconStyle'];

    static defaultOption = {
        show: true, 

        inGrid: false,
        inAxis: true, // {Boolean} 是否只在axis内绘制
        regionType: 'verticalRect',
        // 一直显示 拖拽点
        alwaysShowHandlePoint: true,
        style: {
            textFormatter: undefined,
            normal: { // 普通样式
                fill: 'rgba(49, 207, 214, 0.1)',
                stroke: 'rgba(49, 207, 214, 1)'
                // lineWidth: 1
            },
            emphasis: undefined
        },

        // 可传数组
        handlePoints: {
            type: 'circle',
            // {string|function}
            // 拖拽点的位置 coordMiddle表示居中 
            // function需要 return [x, y]
            position: 'coordMiddle',
            size: [20, 20], // {Number Array} 标记大小
            offset: [0, 0], // {Number Array} 标记偏移
            style:{
                normal: {
                    fill: 'white',
                    stroke: 'rgb(49, 207, 214)',
                    lineWidth: 2
                },
                emphasis: {

                }
            }
        },

        region:{
            // 最小框选区间
            min: 4,
            // 热区
            hotspot: {
                //{Number} 中心线两边扩展px
                expand: 10,
                style:{ 
                    normal: {
                        // fill: 'rgba(25, 55, 15, 0.2)'
                        fill: 'rgba(255, 255, 255, 0)'
                    },
                    emphasis: {
                        fill: 'rgba(230, 122, 122, 0.5)'
                    }
                }
            },
            start: undefined,
            // start: {
            //     x: 0,
            //     xType: 'number',
            //     y: 'coordStart',
            //     yType: 'coordStart'
            // },
            end: undefined
            // end: { 
            //     x: 20,
            //     xType: 'number',
            //     xFormatter:function(x, info){
            //         return x + info.bandwidth + 1
            //     },
            //     y: 'coordEnd',
            //     yType: 'coordEnd',
            // }
        },


        zlevel: 0, // canvas层级
        z: 9, // 同一层canvas内层级
        cursor: 'default'
    };

    checkRegionLimit(direct, value){
        let obj = this.get('region');
        let minRegion = obj.min;
        let startIndex = this.startIndex;
        let endIndex = this.endIndex;
        let minIndex = 0;
        let maxIndex = this.totalNumber-1;

        // 从右边拖拽
        if (direct === 'end') {
            if (value<=startIndex+minRegion) {
                if(value-minRegion>=minIndex){
                    obj.start.x = value - minRegion;
                    obj.start.xType = 'number';
                    obj.end.x = value;
                    obj.end.xType = 'number';
                }
            }else{
                obj.start.x = startIndex;
                obj.start.xType = 'number';
                obj.end.x = value;
                obj.end.xType = 'number';
            }
        }else{
            if(value >= endIndex-minRegion){
                if (value+minRegion<=maxIndex) {
                    obj.start.x = value;
                    obj.start.xType = 'number';
                    obj.end.x = value + minRegion;
                    obj.end.xType = 'number';
                }
            }else{
                obj.start.x = value;
                obj.start.xType = 'number';
                obj.end.x = endIndex;
                obj.end.xType = 'number';
            }
        }

        return obj;
    }

    update() {
        let { globalModel, global } = this;
        let axisIndex = this.get('$axisIndex');
        let region = this.get('region');
        let minRegion = region.min;
        let handlePointsStyle = this.get('handlePoints');
        let expand = region.hotspot.expand;
        let xAxis, yAxis;

        handlePointsStyle = !isArray(handlePointsStyle) ? [handlePointsStyle, handlePointsStyle] : handlePointsStyle

        each(axisIndex, (index) => {
            let axis = globalModel.getComponentByIndex('axis', index);

            if (['bottom', 'top'].indexOf(axis.get('position')) > -1) {
                xAxis = axis;
            } else {
                yAxis = axis;
            }
        });
        let xAxisDomain = xAxis.scale.domain();

        let startIndex, endIndex;

        if (region.start.xType === 'number' && region.end.xType === 'number') {
            startIndex = region.start.x;
            endIndex = region.end.x;
        }else{
            map(xAxisDomain, (d, i)=>{
                if (d === region.start.x) {
                    startIndex = i;
                }
                if(d === region.end.x){
                    endIndex = i;
                }
            })
        }
        let maxIndex = xAxisDomain.length - 1;
        let minIndex = 0;

        if (startIndex === undefined) {
            if (region.start.x < xAxisDomain[0]) {
                startIndex = 0;

                if (endIndex === undefined) {
                    if (region.end.x < xAxisDomain[0]) {
                        endIndex = startIndex + minRegion;
                    }
                } else {
                    if (endIndex - startIndex < minRegion) {
                        endIndex = startIndex + minRegion;
                    }
                }
            }
        }
        if (endIndex === undefined || xAxisDomain[endIndex] === undefined) {
            if (region.end.x > xAxisDomain[xAxisDomain.length - 1] || endIndex > xAxisDomain.length - 1 ) {
                endIndex = xAxisDomain.length - 1;

                if (startIndex === undefined) {
                    if (region.start.x > xAxisDomain[xAxisDomain.length - 1]) {
                        startIndex = endIndex - minRegion;
                    }
                } else {
                    if (endIndex - startIndex < minRegion) {
                        startIndex = endIndex - minRegion;
                    }
                }
            }
        }

        let startDate, endDate;
        startDate = xAxisDomain[startIndex];
        endDate = xAxisDomain[endIndex];

        let startPoint = [
                    getPointPos({
                        x:startIndex, 
                        xType: 'number',
                        xFormatter: region.start.xFormatter
                    }, 'x', xAxis.scale),
                    getPointPos(region.start, 'y', yAxis.scale)
                ]
        let endPoint = [
                    getPointPos({
                        x:endIndex, 
                        xType: 'number',
                        xFormatter: region.end.xFormatter
                    }, 'x', xAxis.scale),
                    getPointPos(region.end, 'y', yAxis.scale)
                ]
        
        // 热区绘图数据
        let hotspotPoints = [
            {
                start: [startPoint[0] - expand, startPoint[1]],
                end: [startPoint[0] + expand, endPoint[1]]
            },
            {
                start: [endPoint[0] - expand, startPoint[1]],
                end: [endPoint[0] + expand, endPoint[1]]
            }
        ];

        let points = [
            startPoint,
            endPoint
        ];

        var handlePointsPos = [];
        for (var i = 0; i < points.length; i++) {
            if(handlePointsStyle[i].position === 'coordMiddle'){
                let yPos = getPointPos({
                    y: 'coordMiddle',
                    yType: 'coordMiddle'
                }, 'y', yAxis.scale);

                handlePointsPos.push([points[i][0], yPos])

            }
        }
        

        this.points = points;
        this.startDate = startDate;
        this.endDate = endDate;
        this.startIndex = startIndex; 
        this.endIndex = endIndex;
        this.handlePointsPos = handlePointsPos;
        this.totalNumber = xAxisDomain.length;
        this.handlePointsStyle = handlePointsStyle;
        this.hotspotPoints = hotspotPoints;
    }
    
}