import GroupShape from '../../shape/GroupShape';
import { each, isFunction } from '../../util';
import { Polygon, BezierCurve } from '../../util/graphic';
import { Symbol } from '../../util/symbol';

export default class EffectLine extends GroupShape {

    constructor(attrMap) {
        super(...arguments);

        let model = attrMap.model;
        let lineOpt = attrMap.line;
        let coords = attrMap.coords;
        let coord0 = coords[0];
        let coord1 = coords[1];
        let lineAttr = {
            shape: {
                x1: coord0[0],
                y1: coord0[1],
                x2: coord1[0],
                y2: coord1[1],
                cpx1: (coord0[0] + coord1[0]) / 2 - (coord0[1] - coord1[1]) * lineOpt.curveness,
                cpy1: (coord0[1] + coord1[1]) / 2 - (coord1[0] - coord0[0]) * lineOpt.curveness,
                percent: 1
            },
            style: lineOpt.style
        };
        let line = new BezierCurve(lineAttr);
        let effectOpt = attrMap.effectSymbol;
        let endOpt = attrMap.endSymbol;
        let effectSize = effectOpt.size;
        let point0 = line.pointAt(0);
        let point1 = line.pointAt(1);
        let tangent0 = line.tangentAt(0);
        let tangent1 = line.tangentAt(1);
        let rotation0 = -Math.PI / 2 - Math.atan2(tangent0[1], tangent0[0]);
        let rotation1 = -Math.PI / 2 - Math.atan2(tangent1[1], tangent1[0]);

        if (lineOpt.show) {
            this.setShape('line', BezierCurve, lineAttr, {
                animation: model.get('animation'),
                animateFrom: {
                    shape: {
                        percent: 0
                    }
                }
            });
        }

        if (endOpt.show) {
            let endSymbol = new Symbol({
                shape: {
                    symbolType: 'arrow',
                    x: -endOpt.size / 2,
                    y: -endOpt.size / 2,
                    width: endOpt.size,
                    height: endOpt.size
                },
                style: endOpt.style,
                position: point1,
                rotation: rotation1
            });

            this.set('endSymbol', endSymbol);

            if (model.get('animation')) {
                endSymbol.__t = 0;
                let animator = endSymbol.animate('', false)
                    .when(model.get('animationDuration'), {
                        __t: 1
                    })
                    .during(function () {
                        let tangent = line.tangentAt(endSymbol.__t);
                        let rotation = -Math.PI / 2 - Math.atan2(tangent[1], tangent[0]);

                        endSymbol.attr('position', line.pointAt(endSymbol.__t));
                        endSymbol.attr('rotation', rotation);
                    });

                animator.start(model.get('animationEasing'));
            }
        }

        if (effectOpt.show) {
            let symbol = new Symbol({
                shape: {
                    symbolType: effectOpt.type,
                    x: -effectSize / 2,
                    y: -effectSize / 2,
                    width: effectSize,
                    height: effectSize
                },
                style: effectOpt.style,
                position: point0,
                rotation: rotation0
            });

            this.set('symbol', symbol);

            symbol.__t = 0;
            let animator = symbol.animate('', effectOpt.loop)
                .when(effectOpt.period, {
                    __t: 1
                })
                .delay(isFunction(effectOpt.delay) ? effectOpt.delay(attrMap.data, attrMap.index, attrMap) : effectOpt.delay)
                .during(function () {
                    let tangent = line.tangentAt(symbol.__t);
                    let rotation = -Math.PI / 2 - Math.atan2(tangent[1], tangent[0]);

                    symbol.attr('position', line.pointAt(symbol.__t));
                    symbol.attr('rotation', rotation);
                });

            animator.start();
        }
    }
}