import { merge, each } from 'zrender/src/core/util'
import { default as DataProvider, getDataId } from './DataProvider'
import EventTarget from 'hxc3-subscriber'
import { dataPoolActions } from '../action/event'
import { doStat, tongjiDebounce } from '../util/tracking'

/**
 * 数据池  dataProvider实例们的管理者
 * 单例
 * 所有从数据池获取的数据，可以添加，!!请勿修改覆盖已有字段!!(因为实时更新等，还会操作到这些数据)
 *
 * @class DataPool
 * @extends {EventTarget}
 */
 class DataPool extends EventTarget {
    static msg = 'D3Charts'

    constructor(opts={}){
        tongjiDebounce(opts);

        super();

        // 所有数据缓存;
        this._maps = {};

        this.setStatus();
    }

    // 注册数据源
    // dataId :
    // fsMoneyFlow__common__hs_300390
    // fsToday__common__hs_300033_hs_600000_bk_881140
    // klineLast__hfqDay__hk_HK0004
    register (componentOption) {
        doStat(componentOption, undefined, undefined, true);
        let {type, ...opt} = componentOption;
        var checkDataId = this.convertOptionToDataId(componentOption);
        // 数据池中未注册
        if(!this._maps[checkDataId]){
            var Clazz = DataProvider.getClass(type);
            var component = new Clazz(opt, this);
            this._maps[component.dataId] = component;
            component.update();
            return component;
        }else{
            return this._maps[checkDataId];
        }
    }

    // usa_RDS.A 会转换成 usa_RDS__A
    // 避免 this.on事件 错误理解为命名空间
    convertOptionToDataId (componentOption) {
        let {type, ...opt} = componentOption;
        let option, Clazz;
        if (DataProvider.hasClass(type)) {
            Clazz = DataProvider.getClass(type);
            option = merge(opt, Clazz.defaultOption);
            return getDataId(option, type);
        }else{
            console.warn(`${type} provider并未声明, 请先定义 class ${type}, 并添加到DataProvider: DataProvider.registerClass(${type}, ${type}.type);`);
        }
    }

    /**
     * 获取 数据池中的dataProvider
     * @param  {string|object} componentOption 配置
     * @return {objeck}        dataProvider 实例
     */
    getDataProvider (componentOption) {
        if (typeof componentOption === 'string') {
            return this._maps[componentOption];
        }else{
            var checkDataId = this.convertOptionToDataId(componentOption);
            return this._maps[checkDataId];
        }
    }

    /**
     * 通过dataId获取存储的事件名
     * @param  {object}  registerBy provider对象
     * @param  {string}  type       事件名称
     * @param  {string} listener    命名空间
     * @return {string}  事件名
     */
    fomatTopicName(registerBy, type, listener = false){
        var namespace = listener ? '.'+listener : '';
        var dataId = registerBy.dataId;
        return dataId + '__hxc3__' + type + namespace;
    }

    getDataInMap(){
        let r = [];
        each(this._maps, (d, i)=>{
            let {fetchStatus, _data: data} = d;
            r.push({fetchStatus, data});
        });

        return r;
    }

    /**
     * 触发事件
     */
    triggerAction(dispatchBy, type, data) {
        this.trigger(dispatchBy.dataId+'__hxc3__'+type, data);
        this.trigger(dataPoolActions.update, this.getDataInMap());
    }

    /**
     * 注册事件
     * @param {any} registerBy
     * @param {string} ttype 推荐添加命名空间，在取消绑定事件时，更灵活 'PROVIDER_UPDATE.mylistener'
     * @param {requestCallback} callback
     * @return {number} 监听唯一数字标号
     *
     * e.g:
     * onAction(dataProvider, 'PROVIDER_UPDATE', function(){...})
     * onAction(dataProvider, 'PROVIDER_UPDATE.mylistener', function(){...})
     */
    onAction(registerBy, ttype, callback) {
        let dataId = registerBy.dataId;
        let type = ttype.split('.')[0];
        let listener = ttype.split('.')[1];
        let topicName = this.fomatTopicName(registerBy, type, listener);
        if (this._maps[dataId]) {

            let {_data: data, fetchStatus} = this._maps[dataId];

            callback({
                data,
                fetchStatus
            });
            return this.on(topicName, callback);
        }else{
            console.warn(`${dataId} 并未在当前数据池中, 请先注册: dataProvider.register(dataConfigs);`);
        }
    }

    /**
     * 取消 某个 监听(某个listener命名空间下的监听)
     * 不是删除数据处理类，
     * 原数据处理仍然缓存，如果配置了实时更新，仍会实时取数据
     * @param  {string} listener 监听id
     *
     * e.g
     * 根据命名空间 取消监听(最消耗性能)
     * dataPool.offAction('.listener')
     *
     * 取消某个 数据处理类的监听
     * dataPool.offAction(privoder, 'PROVIDER_UPDATE')
     *
     * 取消某个 数据处理类下 某个某个命名空间的监听
     * dataPool.offAction(privoder, 'PROVIDER_UPDATE.listener');
     */
    offAction(registerBy, ttype) {
        let topicName;
        if (typeof registerBy === 'string') {
            topicName = registerBy;
            return this.off(topicName);
        }else{
            let type = ttype.split('.')[0];
            let listener = ttype.split('.')[1];
            topicName = this.fomatTopicName(registerBy, type, listener);

            let dataId = registerBy.dataId;
            if (this._maps[dataId]) {
                return this.off(topicName);
            }
        }

        return false;
    }

    // 删除数据处理类，同时删除所有对应的事件！
    // 一般只有在大量接口切换时，防止缓存数据过大，而使用
    // 目前只支持通过命名空间remove
    // remove('.myproject')
    removeProvider(registerBy){
        let topicName;
        if (typeof registerBy === 'string') {
            topicName = registerBy;
            let topics = this.getTopicsByNamespace(topicName.replace('.', ''));
            this.offAction(topicName);
            each(topics, function(item, i){
                // 必须把数据整理实例中的定时器先清除
                this._maps[item.split('__hxc3__')[0]].stopGetData();
                delete this._maps[item.split('__hxc3__')[0]];
            }, this);
        }
    }

    //港交所要求，非登录的情况, 需要替换url 获取延迟15分钟数据
    getFilterUrl(url){
        if (this._status.enableFilterUrl) {
            return this._status.filterUrl(url)
        }else{
            return url;
        }
    }

    // 默认是登录的
    setStatus(opt){
        opt = opt || {};
        var defaultOpt = {
            // 是否懒更新 @todo 没有控制 一个页面中部分要替换，部分不要替换的情况
            lazyInit: true,
            // 是否要替换url
            enableFilterUrl: false,
            filterUrl: function(d){
                var url = d;
                // 港股延时行情涉及接口
                // 1. 今日k线接口
                // http://d.10jqka.com.cn/v6/line/hk_HK0700/01/defer/today.js
                // 2. real 实时行情
                // http://d.10jqka.com.cn/v6/real/hk/defer/HK0700,HK0003
                // http://d.10jqka.com.cn/v6/multimarketreal/33,177/300033_300032_300031_300030,HK0700_HK0003/defer/1968584_10_199112_19
                // 3. 分时数据
                // http://d.10jqka.com.cn/v6/time/hs_300033/last.js
                // http://d.10jqka.com.cn/v6/time/hs_300033/1400.js
                // 4. realhead 实时头部行情
                // http://d.10jqka.com.cn/v2/realhead/33_300033/last.js
                // 5. rank 市场行情排名
                // http://d.10jqka.com.cn/v6/rank/177/199112/defer/d10.js
                // http://d.10jqka.com.cn/v2/rank/33/8/a5.js
                // http://d.10jqka.com.cn/v2/rank/33,17/8/d10.js
                d.replace(/\/(line|real|multimarketreal|time|realhead|rank)\//, function(s0, s1){
                    switch(s1){
                        case 'line':
                            // k线接口中只有today.js 接口需要延迟替换；
                            if (/today/.test(d)) {
                                url = d.replace(/(.+)(\/)/,function(s0, s1){
                                    return s1 + '/defer/';
                                })
                                // url = d.split('.js')[0]+ '_defer' + '.js'
                            }
                            break;
                        case 'time':
                        case 'realhead':
                        case 'rank':
                            // url = d.split('.js')[0]+ '_defer' + '.js'
                            url = d.replace(/(.+)(\/)/,function(s0, s1){
                                return s1 + '/defer/';
                            })
                            break;
                        case 'real':
                        case 'multimarketreal':
                            // url = d + '_defer'
                            url = d.replace(/(.+)(\/)/,function(s0, s1){
                                return s1 + '/defer/';
                            })
                            break;
                    }
                    return s1;
                })
                return url;
            }
        };
        this._status = merge(opt, this._status || defaultOpt);
    }

    getStatus(){
        return this._status;
    }

}

// 单例处理
let DataPoolProxy = (function(){
    var instance;
    return function(opt){
        if(!instance){
            instance = new DataPool(opt);
        }
        return instance
    }
})();

export default DataPoolProxy;
