import { reverseStyleStops, Rect, Polygon } from '../../util/graphic';
import LargeRects from '../../shape/LargeRects';
import ChartView from '../../view/Chart';
import { TYPE } from './BarModel';
import layoutFunc from './layout/index';
import { getSingleRect } from './layout/rect';
import { isFunction, map, merge, find, each, clone } from '../../util';
import { setLabel } from './helper';
import { seriesActions } from '../../action/event';

export default class BarView extends ChartView {

    static type = TYPE;

    type = TYPE;

    renderAll(model, globalModel, global) {
        let barType = model.get('barType');
        let itemStyle = model.get('itemStyle').normal;
        let needAnimation = model.get('animation');
        let animationDuration = model.get('animationDuration');
        let animationEasing = model.get('animationEasing');
        let cursor = model.get('cursor');
        let joinAreaOpt = model.get('joinArea');
        let data = model.getData();
        let { points, y0, rectWidth, position, ticks } = model;

        if (layoutFunc[barType]) {

            let { attrs, animateFroms, animateList, animateLeave, Shape } = layoutFunc[barType]({
                rectWidth, position, y0, points, data, model, globalModel, ticks
            });

            each(attrs, function (attr, index) {
                attr.dataIndex = attr.key = points[index][3];
                attr.seriesIndex = model.index;
                attr.cursor = cursor;
            });

            this.setShapeGroup('rectGroup', Shape,
                attrs,
                {
                    animation: needAnimation,
                    duration: animationDuration,
                    easing: animationEasing,
                    animateFrom: animateFroms,
                    animateList,
                    animateLeave
                }, undefined,
                function (shape) {
                    shape.on('click', function (e) {
                        let enableSelect = model.get('enableSelect');

                        if (enableSelect === 'multiple') {
                            let selectedList = model.get('selectedList');
                            let index = selectedList.indexOf(this.dataIndex);

                            if (index > -1) {
                                selectedList.splice(index, 1);
                                model.set('selectedList', selectedList);
                            } else {
                                selectedList.push(this.dataIndex);
                                model.set('selectedList', selectedList);
                            }
                            global.dispatchAction(model, seriesActions.selectedListChange, selectedList);
                        } else if (enableSelect) {
                            model.set('selectedList', [this.dataIndex]);
                            global.dispatchAction(model, seriesActions.selectedListChange, [this.dataIndex]);
                        }
                    })

                  shape.on('dblclick', function(e) {
                    let selectedList = model.get('selectedList');
                    global.dispatchAction(model, seriesActions.dblClick, this.dataIndex);
                  })
                }
            );

            if (joinAreaOpt.show) {
                let joinAreaAttrs = [];
                let joinAreaAnimateFrom = [];
                let bandwidth = model.bandwidth;

                for (let i = 1; i < points.length; i++) {
                    let cur = points[i];
                    let last = points[i - 1];
                    let style = joinAreaOpt.style;
                    let x0 = last[0] + bandwidth;

                    if (joinAreaOpt.showLabel) {
                        let value = cur[2][1] - last[2][1];
                        let labelStyle = isFunction(joinAreaOpt.labelStyle) ? joinAreaOpt.labelStyle(value, cur, last) : clone(joinAreaOpt.labelStyle);

                        style = {
                            ...style,
                            text: joinAreaOpt.formatter ? joinAreaOpt.formatter(value, cur, last) : value,
                            ...globalModel.getFormattedText(labelStyle)
                        };
                    }

                    joinAreaAttrs.push({
                        shape: {
                            points: [[x0, last[1]], [cur[0], cur[1]], [cur[0], y0], [x0, y0]]
                        },
                        style,
                        key: cur[3],
                        cursor
                    });
                    joinAreaAnimateFrom.push({
                        shape: {
                            points: [[x0, y0], [x0, y0], [x0, y0], [x0, y0]]
                        }
                    });
                }

                this.setShapeGroup('joinAreaGroup', Polygon, joinAreaAttrs, {
                    animation: needAnimation,
                    duration: animationDuration,
                    easing: animationEasing,
                    animateFrom: joinAreaAnimateFrom
                });
            }

            let labelOption = model.get('label').normal;

            if (labelOption.show) {
                let { attrs, animateFroms, animateList, Shape } = layoutFunc.rect({
                    rectWidth, position, y0, points, data, model, globalModel, ticks
                }, true);

                each(attrs, function (attr, index) {
                    attr.silent = true;
                });

                this.setShapeGroup('textRectGroup', Shape,
                    attrs,
                    {
                        animation: needAnimation,
                        duration: animationDuration,
                        easing: animationEasing,
                        animateFrom: animateFroms,
                        animateList,
                        animateLeave
                    }
                );
            }

            let rectGroup = this.getShape('rectGroup');
            let selectedList = model.get('selectedList');

            rectGroup.eachChild(rect => {
                if (~selectedList.indexOf(rect.dataIndex)) {
                    this.highlight(model, globalModel, global, { index: rect.dataIndex });
                } else {
                    // this.downplay(model, globalModel, global, { index: rect.dataIndex });
                }
            });
        } else {
            console.warn('barType类型不支持！');
        }
    }

    renderLarge(model, globalModel, global) {
        let { points, y0, rectWidth } = model;
        let itemStyle = model.get('itemStyle').normal;

        this.setShape('largeRects', LargeRects, {
            shape: {
                points,
                y0,
                rectWidth
            },
            style: itemStyle,
            silent: true
        });
    }

    render(model, globalModel, global) {
        let { points } = model;

        if (model.get('large') && points.length > model.get('largeThreshold')) {
            this.removeShape('rectGroup');
            this.renderLarge(model, globalModel, global);
        } else {
            this.removeShape('largeRects');
            this.renderAll(model, globalModel, global);
        }
    }

    highlight(model, globalModel, global, payload) {
        let xAxis = model.getAxisModel('x');
        let itemStyle = model.get('itemStyle').emphasis;
        let labelEmphasis = model.get('label').emphasis;

        if (payload) {
            let { index } = payload;
            let rect = this.getShape('rectGroup', index);

            if (rect) {
                highlightItem(this, model, globalModel, rect, itemStyle, labelEmphasis);
            }
        } else {
            let rectGroup = this.getShape('rectGroup');

            rectGroup.eachChild(rect => {
                highlightItem(this, model, globalModel, rect, itemStyle, labelEmphasis);
            });
        }
    }

    downplay(model, globalModel, global, payload) {
        let xAxis = model.getAxisModel('x');
        let itemStyle = model.get('itemStyle').normal;
        let labelNormal = model.get('label').normal;
        let selectedList = model.get('selectedList');

        if (payload) {
            let { index } = payload;
            let rect = this.getShape('rectGroup', index);

            if (rect) {
                !~selectedList.indexOf(rect.dataIndex) && downplayItem(this, model, globalModel, rect, itemStyle, labelNormal);
            }
        } else {
            let rectGroup = this.getShape('rectGroup');

            rectGroup.eachChild(rect => {
                !~selectedList.indexOf(rect.dataIndex) && downplayItem(this, model, globalModel, rect, itemStyle, labelNormal);
            });
        }
    }
}

function highlightItem(view, model, globalModel, rect, itemStyle, labelEmphasis) {
    let dataIndex = rect.dataIndex;
    let data = model.getData(dataIndex);
    let _itemStyle = isFunction(itemStyle) ? itemStyle(data) : itemStyle;

    rect.groupShape && (rect = rect.groupShape);
    rect.setStyle(data[1] < 0 ? reverseStyleStops(_itemStyle) : _itemStyle);

    if (labelEmphasis.show) {
        let labelNormal = model.get('label').normal;
        let textRectGroup = view.getShape('textRectGroup');

        if (!textRectGroup) {
            textRectGroup = view.setShapeGroup('textRectGroup', Rect, []);
        }

        let { points, y0, rectWidth, position, ticks } = model;
        let finded = find(points, function (point) {
            return point[3] === dataIndex;
        });

        if (finded) {
            let textRect = view.getShape('textRectGroup', rect.dataIndex);
            let { attr, animateFrom } = getSingleRect({
                rectWidth, position, y0, points, data: model.getData(), model, globalModel, ticks
            }, true, finded, true);

            if (!textRect) {
                attr.silent = true;
                attr.z = model.get('z');
                attr.name = dataIndex;
                textRect = new Rect(attr);
                textRectGroup.add(textRect);
            } else {
                textRect.attr(attr);
            }
        }
        // rect.setStyle(setLabel({}, merge(labelEmphasis, labelNormal), { data, dataIndex }, globalModel, model));
    }
}

function downplayItem(view, model, globalModel, rect, itemStyle, labelNormal) {
    let dataIndex = rect.dataIndex;
    let data = model.getData(dataIndex);
    let _itemStyle = isFunction(itemStyle) ? itemStyle(data) : {...itemStyle};
    let textRect = view.getShape('textRectGroup', dataIndex);
    let visualIndex = model.get('$visualMapIndex');

    if (visualIndex !== undefined) {
        let visualMapModel = model.globalModel.getComponentByIndex('visualMap', visualIndex);

        _itemStyle.fill = visualMapModel.getColorStop(data[1], _itemStyle.fill);
    }

    rect.groupShape && (rect = rect.groupShape);
    rect.useStyle(data[1] < 0 ? reverseStyleStops(_itemStyle) : _itemStyle);

    if ((!labelNormal.show || (labelNormal.show !== 'all' && model.ticks.indexOf(data[0]) === -1))) {

        if (textRect) {
            view.getShape('textRectGroup').remove(textRect);
        }
        // rect.setStyle(setLabel({}, labelNormal, { data, dataIndex }, globalModel, model));
    } else {
        let { points, y0, rectWidth, position, ticks } = model;
        let finded = find(points, function (point) {
            return point[3] === dataIndex;
        });

        if (finded && textRect) {
            let { attr, animateFrom } = getSingleRect({
                rectWidth, position, y0, points, data: model.getData(), model, globalModel, ticks
            }, true, finded, false);

            textRect.attr(attr);
        }
    }
}
