// k线图
import CandleSticks from '../../shape/CandleSticks';
import ChartView from '../../view/Chart';
import { map } from '../../util';
import { TYPE } from './HqbarModel';

export default class HqbarView extends ChartView {

    static type = TYPE;

    type = TYPE;

    render(model, globalModel, global) {
        super.render(...arguments);
        let group = this.group;
        let lineOption = model.get('line').normal;
        let needAnimation = model.get('animation');
        let cursor = model.get('cursor');
        let hqbarType = model.get('hqbarType');
        let hqStyle = model.get('hq');

        let {
            y0,
            points
        } = model;
        let data = model.getData();

        let bandwidth = model.bandwidth;
        // 中心线偏移
        let centerOffset = model.centerOffset;
        let pointsByColor = model.pointsByColor;

        let _y0 = y0,
            _y0Start = y0,
            _y1Start = y0;

        model.lastPoints && (_y1Start = (d, index) => model.lastPoints[index] ? model.lastPoints[index][1] : d[1]);

        if (lineOption.show) {

            // let lineStyle = lineOption.style;

            let status = ['down', 'up', 'eq'];

            let sameStrokeColor = (hqStyle['down'].stroke === hqStyle['up'].stroke);
            let attrs = map(status, (d) => {
                // 当宽度小于1 k线只画一条直线，
                // 如果up和down的stroke颜色相同，使用各自fill颜色 作为stroke
                let style = bandwidth <= 3 ? {
                    stroke: sameStrokeColor ? hqStyle[d].fill : hqStyle[d].stroke,
                    fill: hqStyle[d].fill
                } : hqStyle[d]

                let shape = {
                    data: pointsByColor[d],
                    bandWidth: bandwidth,
                    yStart: _y1Start,
                    percent: 1,
                    drawTemplate: hqbarType
                };

                return {
                    shape,
                    style,
                    z2: 1,
                    cursor
                }
            });

            this.setShapeGroup('hqbarGroup', CandleSticks,
                attrs,
                undefined,
                function (shape) {
                    // 绑定事件
                }
            );
        }
    }
}