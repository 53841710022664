import { map } from '../../util';

export function transformToAxis(points, xAxis, yAxis) {
    return map(points, point => transformToAxis1(point, xAxis, yAxis))
}

export function transformToPX(points, xAxis, yAxis, inCenter) {
    return map(points, point => transformToPX1(point, xAxis, yAxis, inCenter))
}

export function transformToAxis1(point, xAxis, yAxis) {
    return [xxx(xAxis, point), xxx(yAxis, point)];
}

export function transformToPX1(point, xAxis, yAxis, inCenter) {
    return [yyy(xAxis, point, inCenter), yyy(yAxis, point, inCenter)];
}

function xxx(axis, point) {
    if (axis.getAxisType() === 'ordinal') {
        return axis.getIndexByOffset(point[0], point[1]) + axis.startIndex;
    } else {
        return axis.scale.invert(point[['bottom', 'top'].indexOf(axis.get('position')) > -1 ? 0 : 1]);
    }
}

function yyy(axis, point, inCenter) {
    let bottomOrTop = ['bottom', 'top'].indexOf(axis.get('position')) > -1;

    if (axis.getAxisType() === 'ordinal') {
        let p = axis.scale(axis.scale.domain()[point[bottomOrTop ? 0 : 1] - axis.startIndex]);

        if (inCenter && axis.get('type') === 'band') {
            p += axis.scale.bandwidth() / 2;
        }

        return p;
    } else {
        return axis.scale(point[bottomOrTop ? 0 : 1]);
    }
}