/**
 * 标线
 */
import ComponentModel from '../../model/Component';
import { parsePercent } from '../../util';

export const TYPE = 'markLine';

export default class MarkLineModel extends ComponentModel {

    static type = TYPE;

    type = TYPE;
    
    static emphasisList = ['lineStyle'];

    static defaultOption = {
        zlevel: 0,
        z: 10,

        show: true, // 是否显示

        $axisIndex: undefined, // 依赖的axis
        $seriesIndex: undefined, // 若配置$seriesIndex则使用该series的axis

        // 标线样式
        lineStyle: {
            normal: {
                lineWidth: 1
            },
            emphasis: {

            }
        },

        // 标签样式
        label: {
            normal: {
                show: false,
                style: {
                    position: 'top' // 'insideLeft', 'inside', 'left', 'right', 'top', 'bottom'
                }
            },
            emphasis: {
                show: false,
                style: {
                    
                }
            }
        }
    };
}