import { Rect, Polygon, Circle, enableDrag, setHoverStyle } from '../../util/graphic';
import { Symbol } from '../../util/symbol';
import { each, merge, isFunction, map, find, parseSize } from '../../util';
import ChartView from '../../view/Chart';
import { TYPE } from './RegionModel'; // 使用Model中定义的TYPE，与model的type值保持一致
import shapes from './shape/index';
// import { transformToAxis, transformToPX, transformToAxis1, transformToPX1, getPointPos } from './helper';
import { dataZoomActions } from '../../action/event';


export default class RegionView extends ChartView {

    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    constructor(model, globalModel, global) {
        super(...arguments);

        this.initEvent(model, globalModel, global);
    }

    initEvent(model, globalModel, global) {
        let dataZoomModel = globalModel.getComponentByIndex('dataZoom', 0);
        let storePoints = [], storeIndex = [];

        this.isMousedown = {
            0: false,
            1: false
        };

        // 滚轮时候，用date定位region框
        global.registerAction(dataZoomModel, dataZoomActions.wheel, ({ e, scale, zoomX, zoomY }) => {
            let startDate = model.startDate
            let endDate = model.endDate;
            // 放大
            let fangda = scale > 0;
            let totalNumber = model.totalNumber;
            let maxIndex = totalNumber - 1;
            let minIndex = 0;
            let startIndex = model.startIndex;
            let endIndex = model.endIndex;
          
            let obj = model.get('region');
            obj.start.x = startDate;
            obj.start.xType = 'value';

            obj.end.x = endDate;
            obj.end.xType = 'value';
            
            model.set({
                'region': obj
            });

        });

        // 左右移动时，用number定位region框
        global.registerAction(dataZoomModel, dataZoomActions.start, () => {
            storePoints = model.get('region');
            storeIndex = [model.startIndex, model.endIndex];

            storePoints.start.x = storeIndex[0];
            storePoints.start.xType = 'number';
            storePoints.end.x = storeIndex[1];
            storePoints.end.xType = 'number';
            model.set({
                'region': storePoints
            });
        });

        global.on('mouseup', ({ offsetX, offsetY }) => {
            if (this.isMousedown[0] || this.isMousedown[1]) {
                this.isMousedown = {
                    0: false,
                    1: false
                };
                model.dirty();
            }
        });

        global.on('globalout', ({ offsetX, offsetY }) => {
            if (this.isMousedown[0] || this.isMousedown[1]) {
                this.isMousedown = {
                    0: false,
                    1: false
                };
                model.dirty();
            }
        });
    }

    render(model, globalModel, global) {
        let enableRefresh = model.get('enableRefresh');
        let regionType = model.get('regionType');
        let axisIndex = model.get('$axisIndex');
        let hotspotNormalOption = model.get('region').hotspot.style.normal;
        let hotspotHoverOption = model.get('region').hotspot.style.emphasis;
        let handlePointsStyle = model.handlePointsStyle;
        let points = model.points;
        let handlePointsPos = model.handlePointsPos;
        let hotspotPoints = model.hotspotPoints;
        let cursor = model.get('cursor');
        let xAxis, yAxis;

        each(axisIndex, (index) => {
            let axis = globalModel.getComponentByIndex('axis', index);

            if (['bottom', 'top'].indexOf(axis.get('position')) > -1) {
                xAxis = axis;
            } else {
                yAxis = axis;
            }
        });
        
        let regionHoverStyle = model.get('style').emphasis;
        let text = typeof model.get('style').textFormatter === 'function' ? model.get('style').textFormatter({
            during: model.endIndex - model.startIndex,
            endIndex: model.endIndex,
            startIndex: model.startIndex
        }) : model.endIndex - model.startIndex;
        let shapeAttr = {
            shape: {
               points
            },
            style: {
                text: text,
                ...model.get('style').normal
            }
        };

        let { attr, Shape } = shapes[regionType](shapeAttr, model);
        attr.z = model.get('z');
        attr.cursor = cursor;

        this.setShape('region', Shape, attr, undefined, function (shape) {
            regionHoverStyle && setHoverStyle(shape, regionHoverStyle);
        });


        // 绘制热区
        let hotspotAttrs = [];
        for (let i = 0; i < hotspotPoints.length; i++) {
            let itemHotspot = hotspotPoints[i];
            hotspotAttrs.push({
                z: model.get('z'),
                shape: {
                   x: itemHotspot.start[0],
                   y: itemHotspot.start[1],
                   width: itemHotspot.end[0] - itemHotspot.start[0],
                   height: itemHotspot.end[1] - itemHotspot.start[1]
                },
                style: this.isMousedown[i]? hotspotHoverOption : hotspotNormalOption,
                pointIndex: i
            });
        }

        let that = this;
        this.setShapeGroup('hotspot', Rect, hotspotAttrs, undefined, function (hotspotGroup) {

            hotspotGroup.on('mousedown', ({ target, offsetX, offsetY })=> {
                that.isMousedown[target.pointIndex] = true;
                target.setStyle('fill', hotspotHoverOption.fill);
            })

            hotspotGroup.on('mouseup', ({ target, offsetX, offsetY })=> {
                that.isMousedown[target.pointIndex] = false;
                target.setStyle('fill', hotspotNormalOption.fill);
            })

            enableDrag(hotspotGroup, global, {
                onDragStart: function(){
                    global.isEnableSketch = true;
                },
                onDragging: function ({ target, offsetX, offsetY }) {
                    
                    // hotspotHoverOption && setHoverStyle(hotspotGroup, hotspotHoverOption);
                    let temp = xAxis.getIndexByOffset(offsetX, offsetY);
              
                    let obj = model.checkRegionLimit(target.pointIndex ? 'end': 'start', temp);

                    model.set({'region': obj});
                   
                },
                onDragEnd: function(){
                    global.isEnableSketch = false;
                }
            });
        });


        let symbolAttrs = [];
        for (let i = 0; i < points.length; i++) {
            let itemSymbol = handlePointsStyle[i];
            let { width, height } = parseSize(itemSymbol.size);
            let symbolOffset = itemSymbol.offset;
            let normalStyle = itemSymbol.style.normal;
        
            symbolAttrs.push({
                position: handlePointsPos[i],
                shape: {
                    symbolType: itemSymbol.type,
                    x: -width / 2 + symbolOffset[0],
                    y: -height / 2 + symbolOffset[1],
                    width,
                    height
                },
                style: normalStyle,
                z2: 999,
                z: model.get('z'),
                pointIndex: i,
                silent: true
                // rectHover: true
            })
        }

        this.setShapeGroup('handlePoints', Symbol, symbolAttrs);

    }
}