import CandleSticks from '../../shape/CandleSticks';
import D3Line from '../../shape/D3Line';
import ChartView from '../../view/Chart';
import { each, clone, merge, findAllIndex, filter, map, isArray } from '../../util';
import { subPixelOptimizeLine } from '../../util/graphic';
import { TYPE } from './HqIndicatorModel';

// function fixRange(num, min, max) {
//     return Math.min(Math.max(num, min), max);
// }

export default class HqbarView extends ChartView {

    static type = TYPE;

    type = TYPE;

    render(model, globalModel, global) {
        super.render(...arguments);
        this.remove();
        let cursor = model.get('cursor');
        let { shapes, y0, indicator } = model;

        map(shapes, (d)=>{
            let ShapePath;
            
            switch(d.shapeType){
                case 'line':
                    ShapePath = D3Line;
                    break;
                default:
                    ShapePath = indicator.getShape(d.shapeType);
                    break;
            }
            
            if (isArray(d.attr)) {
                this.setShapeGroup(d.name, ShapePath,
                    d.attr,
                    undefined,
                    function(shapes){

                    }
                );
            }else{
                this.setShape(d.name, ShapePath,
                    subPixelOptimizeLine(d.attr),
                    undefined,
                    function (shape) {
                        // let lineEmphasis = model.get('line').emphasis;
                        // if (lineEmphasis.style) {
                        //     setHoverStyle(shape, lineEmphasis.style);
                        // }
                    }
                );
            }
        });

    }
}
