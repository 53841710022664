import ComponentView from '../../view/Component';
import { TYPE } from './GridModel';
import { BorderRoundRect, Line, enableDrag, subPixelOptimizeRect, Rect } from '../../util/graphic'

export default class GridView extends ComponentView {

    static type = TYPE;

    type = TYPE;

    render(model, globalModel, global) {
        let { left, top, width, height } = model.position;
        let background = model.get('background');
        let borderEnable = background.borderEnable;
        let topPlaceHolder = model.get('topPlaceHolder');
        let bottomPlaceHolder = model.get('bottomPlaceHolder');

        if (background.show) {
            this.setShape('background', BorderRoundRect, subPixelOptimizeRect({
                shape: {
                    x: left,
                    y: top,
                    width,
                    height, 
                    borderEnable: borderEnable
                },
                style: background.style,
                silent: true
            }))
        }

        if (topPlaceHolder.show) {
            this.setShape('topPlaceHolder', BorderRoundRect, subPixelOptimizeRect({
                shape: {
                    x: left,
                    y: top - topPlaceHolder.height ,
                    width,
                    height: topPlaceHolder.height, 
                    borderEnable: topPlaceHolder.borderEnable
                },
                style: topPlaceHolder.style,
                silent: true
            }))
        }

        if(bottomPlaceHolder.show){
            this.setShape('bottomPlaceHolder', BorderRoundRect, subPixelOptimizeRect({
                shape: {
                    x: left,
                    y: top + height ,
                    width,
                    height: bottomPlaceHolder.height, 
                    borderEnable: bottomPlaceHolder.borderEnable
                },
                style: bottomPlaceHolder.style,
                silent: true
            }))
        }
    }

    renderEdit(model, globalModel, global) {
        let { position: { left, top, right, bottom } } = model;
        let chartWidth = global.getWidth();
        let chartHeight = global.getHeight();

        this.setShape('_edit_left', Line, {
            shape: {
                x1: left,
                y1: top,
                x2: left,
                y2: bottom
            },
            style: {
                stroke: 'rgba(0, 0, 0, 0.8)',
                lineWidth: 2
            },
            cursor: 'e-resize',
            z2: 9999
        }, undefined, function (shape) {
            function resetPos({ offsetX, offsetY }) {
                model.set({
                    left: offsetX
                });
            }

            enableDrag(shape, global, {
                onDragging: resetPos,
                onDragEnd: resetPos
            });
        });

        this.setShape('_edit_top', Line, {
            shape: {
                x1: left,
                y1: top,
                x2: right,
                y2: top
            },
            style: {
                stroke: 'rgba(0, 0, 0, 0.8)',
                lineWidth: 2
            },
            cursor: 'n-resize',
            z2: 9999
        }, undefined, function (shape) {
            function resetPos({ offsetX, offsetY }) {
                model.set({
                    top: offsetY
                });
            }

            enableDrag(shape, global, {
                onDragging: resetPos,
                onDragEnd: resetPos
            });
        });

        this.setShape('_edit_right', Line, {
            shape: {
                x1: right,
                y1: top,
                x2: right,
                y2: bottom
            },
            style: {
                stroke: 'rgba(0, 0, 0, 0.8)',
                lineWidth: 2
            },
            cursor: 'e-resize',
            z2: 9999
        }, undefined, function (shape) {
            function resetPos({ offsetX, offsetY }) {
                model.set({
                    right: chartWidth - offsetX
                });
            }

            enableDrag(shape, global, {
                onDragging: resetPos,
                onDragEnd: resetPos
            });
        });

        this.setShape('_edit_bottom', Line, {
            shape: {
                x1: left,
                y1: bottom,
                x2: right,
                y2: bottom
            },
            style: {
                stroke: 'rgba(0, 0, 0, 0.8)',
                lineWidth: 2
            },
            cursor: 'n-resize',
            z2: 9999
        }, undefined, function (shape) {
            function resetPos({ offsetX, offsetY }) {
                model.set({
                    bottom: chartHeight - offsetY
                });
            }

            enableDrag(shape, global, {
                onDragging: resetPos,
                onDragEnd: resetPos
            });
        });
    }

    removeEdit() {
        this.eachShape((shape, name) => {
            if (name.indexOf('_edit_') === 0) {
                shape.disableDrag && shape.disableDrag();
                this.removeShape(name);
            }
        });
    }

}