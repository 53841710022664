import { Path } from '../util/graphic';
import d3line from 'd3-shape/src/line';
import { isFunction } from '../util';

export default Path.extend({
    type: 'd3-line',

    shape: {

        data: [],

        x: undefined,

        y: undefined,

        defined: undefined,

        curve: undefined,

        yStart: 0,

        percent: 1
    },

    style: {
        stroke: '#000',

        fill: null
    },

    updateLine: function () {
        !this.line && (this.line = d3line());

        let { line, shape } = this;

        for (let p in shape) {

            if (!~['data', 'percent', 'yStart'].indexOf(p) && line[p] && typeof shape[p] !== 'undefined') {
                if (p === 'y') {
                    let y = shape[p];
                    let yIsFunc = isFunction(y);
                    let percent = shape.percent;
                    let yStart = shape.yStart;
                    let yStartIsFunc = isFunction(yStart);

                    line[p]((v, i, data) => {
                        let _y = yIsFunc ? y(v, i, data) : y;

                        if (typeof yStart === 'undefined') {
                            return _y;
                        } else {
                            return _y * percent + (yStartIsFunc ? yStart(v, i, data) : yStart) * (1 - percent)
                        }
                    });
                } else {
                    line[p](shape[p]);
                }
            }
        }
    },

    buildPath: function (context, shape) {
        this.updateLine();
        this.line.context(context)(shape.data);
    }
})