import ComponentView from '../../view/Component';
import { setHoverStyle, Line, subPixelOptimizeLine } from '../../util/graphic';
import { TYPE } from './MarkLineModel';
import { each, merge, parseSize, isArray } from '../../util';
import { markLineActions } from '../../action/event';

export default class MarkLineModel extends ComponentView {
    static type = TYPE;

    type = TYPE;

    render(model, globalModel, global) {
        if (model.get('show')) {
            let data = model.getData();
            let lineStyle = model.get('lineStyle');
            let labelOpt = model.get('label').normal;
            let animation = model.get('animation');
            let attrs = [];

            each(data, function (item, i) {
                let _lineStyle = merge(
                    (item.lineStyle || {}).normal || {},
                    lineStyle.normal
                );
                let _labelOpt = merge(
                    (item.label && item.label.normal) || {},
                    labelOpt
                );
                let _labelStyle = {};

                if (_labelOpt.show) {
                    let text = _labelOpt.formatter ? _labelOpt.formatter(item) : item.value;

                    _labelStyle = globalModel.getFormattedText({
                        text,
                        ..._labelOpt.style
                    });
                }

                if (item.points) {
                    let xAxisModel = model.getAxisModel('x');
                    let points = [];

                    each(item.points, function (point) {
                        let { position, data: markData } = getMarkData(
                            point,
                            model,
                            globalModel
                        ) || {};

                        if (position) {
                            if (['left', 'right'].indexOf(xAxisModel.get('position')) > -1) {
                                position = [position[1], position[0]];
                            }

                            points.push(position);
                        }
                    });

                    if (points.length >= 2) {
                        attrs.push(
                            subPixelOptimizeLine({
                                shape: {
                                    x1: points[0][0],
                                    y1: points[0][1],
                                    x2: points[1][0],
                                    y2: points[1][1],
                                    percent: 1
                                },
                                data: item,
                                style: {
                                    ..._lineStyle,
                                    ..._labelStyle
                                },
                                key: i
                            })
                        );
                    }
                } else if (item.type) {
                    let seriesModel = globalModel.getComponentByIndex(
                        'series',
                        model.get('$seriesIndex')
                    );

                    if (seriesModel && seriesModel.get('selected') !== false) {
                        let xAxisModel = (seriesModel || model).getAxisModel(
                            'x'
                        );
                        let yAxisModel = model.getAxisModel('y');
                        let extData = seriesModel.getDataExtent(item.type);
                        let shape;

                        if (item.type === 'average') {
                            shape = {
                                x1: xAxisModel.range[0],
                                y1: yAxisModel.scale(extData),
                                x2: xAxisModel.range[1],
                                y2: yAxisModel.scale(extData),
                                percent: 1
                            };
                        } else {
                            shape = {
                                x1: xAxisModel.range[0],
                                y1: yAxisModel.scale(extData[0][1]),
                                x2: xAxisModel.range[1],
                                y2: yAxisModel.scale(extData[0][1]),
                                percent: 1
                            };
                        }

                        attrs.push(
                            subPixelOptimizeLine({
                                shape: shape,
                                data: item,
                                style: {
                                    ..._lineStyle,
                                    ..._labelStyle
                                },
                                key: i
                            })
                        );
                    }
                }
            });

            this.setShapeGroup('markLines', Line, attrs,
                {
                    animation,
                    animateFrom: {
                        shape: {
                            percent: 0.01
                        }
                    },
                    animateList: {
                        shape: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 0
                        }
                    }
                },
                function (group) {
                    group.on('click', function(e) {
                        global.dispatchAction(model, markLineActions.lineClick, { 
                            data: e.target.data,
                            e
                        });
                    });

                    group.on('mouseover', function(e) {
                        global.dispatchAction(model, markLineActions.lineMouseover, {
                            data: e.target.data,
                            e
                        });
                    })

                    group.on('mouseout', function(e) {
                        global.dispatchAction(model, markLineActions.lineMouseout, {
                            data: e.target.data,
                            e
                        });
                    })

                    group.on('mousemove', function(e) {
                        global.dispatchAction(model, markLineActions.lineMousemove, {
                            data: e.target.data,
                            e
                        });
                    })
                }
            );
        } else {
            this.removeShape('markLines');
        }
    }
}

function inAxisDomain(axisModel, value) {
    let axisType = axisModel.getAxisType();

    if (axisType === 'ordinal') {
        return value >= axisModel.startIndex && value <= axisModel.endIndex;
    } else {
        return value >= axisModel.domain[0] && value <= axisModel.domain[1];
    }
}

function getAxisValue(axisModel, value) {
    let axisType = axisModel.getAxisType();

    if (axisType === 'ordinal') {
        return axisModel.scale.domain()[value - axisModel.startIndex];
    } else {
        return value;
    }
}

export function getMarkData(item, model, globalModel) {
    let seriesIndexes = model.get('$seriesIndex');
    !isArray(seriesIndexes) && (seriesIndexes = [seriesIndexes]);
    let seriesModels = seriesIndexes.map((index) => globalModel.getComponentByIndex('series', index));
    let seriesModel = seriesModels[0];
    let xAxisModel = (seriesModel || model).getAxisModel('x');
    let yAxisModel = (seriesModel || model).getAxisModel('y');

    if (item.type) {
        let re, extData;

        each(seriesModels, function (seriesModel) {
            let _extData = seriesModel.getDataExtent(
                item.type,
                item.valueDim,
                item.valueIndex
            );

            if (_extData) {
                if (
                    !extData ||
                    (item.type === 'min' && _extData[1] < extData[1]) ||
                    (item.type === 'max' && _extData[1] > extData[1])
                ) {
                    let extX = _extData[0][0];
                    let extY = _extData[0][1];

                    if (
                        inAxisDomain(xAxisModel, extX) &&
                        inAxisDomain(yAxisModel, extY)
                    ) {
                        let xValue = getAxisValue(xAxisModel, extX);
                        let yValue = getAxisValue(yAxisModel, extY);
                        let x = xAxisModel.scale(xValue);
                        let y = yAxisModel.scale(yValue);

                        if (xAxisModel.get('type') === 'band') {
                            if (seriesModel.get('type') === 'bar') {
                                x += seriesModel.centerOffset;
                            } else {
                                x += xAxisModel.scale.bandwidth() / 2;
                            }
                        }

                        re = {
                            position: [x, y],
                            data: [xValue, yValue]
                        };
                        extData = _extData;
                    }
                }
            }
        });

        return re;
    } else {
        if (seriesModel && !item.y && !item.yValue && !item.yIndex) {
            let seriesData = seriesModel.getRealData();

            if (item.xIndex) {
                item.yValue = seriesData[item.xIndex][1];
            } else if (item.xValue) {
                item.yValue =
                    seriesData[xAxisModel.getData().indexOf(item.xValue)][1];
            }
        }
        let x = getXOrY('x', item, model, seriesModel);
        let y = getXOrY('y', item, model, seriesModel);
        if(!isNaN(x[0]) && !isNaN(y[0])){
            if(item.xFormatter && typeof(item.xFormatter) == 'function'){
                x[0] = item.xFormatter(x[0], xAxisModel.scale.bandwidth());
            }
            if(item.yFormatter && typeof(item.yFormatter) == 'function'){
                y[0] = item.xFormatter(y[0]);
            }
        }
        return isNaN(x[0]) || isNaN(y[0])
            ? false
            : { position: [x[0], y[0]], data: [x[1], y[1]] };
    }
}

function getXOrY(xOrY, item, model, seriesModel) {
    let pos = item[xOrY];
    let posValue = item[xOrY + 'Value'];
    let posIndex = item[xOrY + 'Index'];
    let _pos;

    if (typeof pos !== 'undefined') {
        let axisModel = (seriesModel || model).getAxisModel(xOrY);

        if (['left', 'top'].indexOf(pos) > -1) {
            pos = axisModel.range[0];
        } else if (['right', 'bottom'].indexOf(pos) > -1) {
            pos = axisModel.range[1];
        }

        _pos = pos;
        posValue = pos;
    } else if (typeof posValue !== 'undefined') {
        let axisModel = (seriesModel || model).getAxisModel(xOrY);
        let isOrdinal = axisModel.getAxisType() === 'ordinal';

        if (
            model.type === 'markArea' ||
            inAxisDomain(
                axisModel,
                isOrdinal
                    ? axisModel.domain.indexOf(posValue) + axisModel.startIndex
                    : posValue
            )
        ) {
            axisModel.get('type') === 'time' && (posValue = new Date(posValue));
            //axisModel && (_pos = axisModel.scale(posValue));
          // 超出仍显示未超出部分
          if (axisModel) {
            let domain = axisModel.domain
            let domainMin = domain[0]
            let domainMax = domain[domain.length - 1]
            let _posValue = posValue
            if (posValue < domainMin) {
              _posValue = domainMin
            } else if (posValue > domainMax) {
              _posValue = domainMax
            }
              _pos = axisModel.scale(_posValue)

          }
            axisModel.get('type') === 'band' &&
                (_pos += axisModel.scale.bandwidth() / 2);
        } else {
            return false;
        }
    } else if (typeof posIndex !== 'undefined') {
        let axisModel = (seriesModel || model).getAxisModel(xOrY);

        if (
            posIndex >= axisModel.startIndex &&
            posIndex <= axisModel.endIndex
        ) {
            posValue = axisModel.scale.domain()[
                posIndex - axisModel.startIndex
            ];
            axisModel.get('type') === 'time' && (posValue = new Date(posValue));
            axisModel && (_pos = axisModel.scale(posValue));
            axisModel.get('type') === 'band' &&
                (_pos += axisModel.scale.bandwidth() / 2);
        } else {
            if (model.type === 'markArea') {
                if (posIndex < axisModel.startIndex) {
                    posValue = axisModel.scale.domain()[
                        0
                    ];
                } else {
                    posValue = axisModel.scale.domain()[
                        axisModel.endIndex - axisModel.startIndex
                    ];
                }

                axisModel.get('type') === 'time' && (posValue = new Date(posValue));
                axisModel && (_pos = axisModel.scale(posValue));
                axisModel.get('type') === 'band' &&
                    (_pos += axisModel.scale.bandwidth() / 2);
            } else {
                return false;
            }
        }
    }

    return [_pos, posValue];
}
