/**
 * 坐标轴线
 */
import ComponentModel from '../../model/Component';
import { each } from '../../util';

export const TYPE = 'axisPointer';

/*

坐标轴指示器是指示坐标轴当前刻度的工具。鼠标悬浮到图上，可以出现标线和刻度文本。依赖于axis。


组件详细配置项如下，每个配置项都有一个默认值：

*/

export default class AxisPointerModel extends ComponentModel {
    static type = TYPE;

    type = TYPE;

    static defaultOption = {
        $axisIndex: [0, 1], // {number|Array<number>} 依赖的axis的index，可以为数组

        enable: true, // {boolean} 是否显示

        // 指示线
        line: {
            show: true, // {boolean} 是否显示指示线
            // 顶部延长
            topExtend: 0,
            // line样式
            style: {
                stroke: 'rgba(51, 51, 51, 0.5)',
                lineWidth: 1
            }
        },

        // 指示区域
        shadow: {
            show: false, // {boolean} 是否显示指示区域
            style: { // {Object} 样式
                fill: 'rgba(150,150,150,0.3)'
            }
        },

        // 坐标轴指示器的文本标签。
        label: {
            show: true, // {boolean} 是否显示标签
            formatter: undefined, // {Function} 标签内容格式化
            gap: undefined, // {number} 标签与坐标轴距离
            inRange: undefined, // {boolean} 显示在axis的range范围内
            inside: false, // {boolean} 标签是否位于grid内
            // 标签文本样式
            style: {
                fill: 'white',
                textPadding: 5,
                textBorderRadius: 2,
                textBackgroundColor: '#6A7985'
            },
            rotate: undefined // {number} 标签旋转的角度，在类目轴的类目标签显示不下的时候可以通过旋转防止标签之间重叠。 旋转的角度从 -90 度到 90 度
        },

        // $seriesIndex: undefined,
        valueIndex: undefined, // {number} 当series的单个数据项为数组时，取数组的第几个值

        // series标签
        seriesLabel: {
            show: false, // {boolean} 是否显示
            gap: 5, // {number} 间隔

            // {Function} 标签内容格式化
            formatter: function (d) {
                return d;
            },

            seriesIndex: [], // {Array<number>} 要显示的label的seriesIndex
            style: {
                textVerticalAlign: 'bottom',
                textAlign: 'right'
            }
        },

        zlevel: 0, // {number} 所有图形的 zlevel 值
        z: 9 // {number} 组件的所有图形的z值。控制图形的前后顺序。z值小的图形会被z值大的图形覆盖。
    };

    update() {
        let seriesLabel = this.get('seriesLabel');
        let globalModel = this.globalModel;
        let seriesIndex = [];

        each(seriesLabel.seriesIndex, function (index) {
            if (typeof index === 'string') {
                let seriesModel = globalModel.getComponentById('series', index);

                if (seriesModel) {
                    seriesIndex.indexOf(seriesModel.index) === -1 &&
                        seriesIndex.push(seriesModel.index);
                } else {
                    console.warn(
                        'axisPointer -> seriesLabel -> sereisIndex: 未找到对应的model'
                    );
                }
            } else {
                seriesIndex.indexOf(index) === -1 && seriesIndex.push(index);
            }
        });

        this.seriesIndex = seriesIndex;
    }

    /**
     * 根据坐标更新位置
     * @param {number} x 坐标x
     * @param {number} y 坐标y
     */
    updateByXY(x, y) {
        let { global, globalModel } = this;
        let view = global.getViewOfComponentModel(this);
        let xAxisModel = this.getAxisModel('x');
        let yAxisModel = this.getAxisModel('y');
        let index = xAxisModel.getIndexByOffset(x, y); // 计算对应到x轴的index
        let dx = xAxisModel.getData()[index]; // 获取对应到x轴的值
        let dy = yAxisModel.scale.invert(y); // 计算对应到y轴的值
        let pos = xAxisModel.scale(dx) + (xAxisModel.scale.bandwidth ? xAxisModel.scale.bandwidth() / 2 : 0); // 修正位置线位置到中间

        view.movePointer(this, xAxisModel, globalModel, { offsetX: x, offsetY: y, axisData: dx, pos });
        view.movePointer(this, yAxisModel, globalModel, { offsetX: x, offsetY: y, axisData: dy });
    }

    /**
     * 根据数据更新位置
     * @param {number} index 数据的index
     * @param {number} value 数据的值
     */
    updateByIndex(index, value) {
        let { global, globalModel } = this;
        let view = global.getViewOfComponentModel(this);
        let xAxisModel = this.getAxisModel('x');
        let yAxisModel = this.getAxisModel('y');

        if (xAxisModel) {
            let dx = xAxisModel.getData()[index]; // 获取对应到x轴的值
            let pos = xAxisModel.scale(dx) + (xAxisModel.scale.bandwidth ? xAxisModel.scale.bandwidth() / 2 : 0); // 修正位置线位置到中间

            view.movePointer(this, xAxisModel, globalModel, { axisData: dx, pos });
        }

        if (yAxisModel) {
            let y = yAxisModel.scale(value);

            view.movePointer(this, yAxisModel, globalModel, { offsetY: y, axisData: value });
        }
    }
}
