import { Rect, Line, subPixelOptimizeRect, subPixelOptimizeLine } from '../../../util/graphic';
import { map } from '../../../util';
import Ellipse from '../../../shape/Ellipse';
import ArrowLine from '../../../shape/ArrowLine';
import { transformToAxis, transformToPX } from '../helper';


function getLeftRight(xAxis, points, inCenter) {
    let xScale = xAxis.scale;
    let left, right;
    let start = points[0][0];
    let end = points[1][0];

    if (xAxis.getAxisType() === 'ordinal') {
        let xDomain = xScale.domain();
        let paddingInner = xScale.paddingInner ? xScale.paddingInner() * xScale.step() / 2 : 0;

        start = Math.max(start, xAxis.startIndex) - xAxis.startIndex;
        end = Math.min(end, xAxis.endIndex) - xAxis.startIndex;

        if (inCenter) {
            left = xScale(xDomain[start]) + xScale.bandwidth() / 2;
            right = xScale(xDomain[end]) + xScale.bandwidth() / 2;
        } else {
            if (end >= start) {
                left = xScale(xDomain[start]) - paddingInner;
                right = xScale(xDomain[end]) - paddingInner + (xAxis.get('type') === 'band' ? xScale.step() : 0);
            } else {
                left = xScale(xDomain[start]) - paddingInner + (xAxis.get('type') === 'band' ? xScale.step() : 0);
                right = xScale(xDomain[end]) - paddingInner;
            }
        }
    } else {
        left = xScale(start);
        right = xScale(end);
    }

    return {
        left,
        right
    }
}

function getTopBottom(yAxis, points, inCenter) {
    let yScale = yAxis.scale;
    let bottom, top;
    let start = points[1][1];
    let end = points[0][1];

    if (yAxis.getAxisType() === 'ordinal') {
        let yDomain = yScale.domain();
        let paddingInner = yScale.paddingInner ? yScale.paddingInner() * yScale.step() / 2 : 0;

        start = Math.max(start, yAxis.startIndex) - yAxis.startIndex;
        end = Math.min(end, yAxis.endIndex) - yAxis.startIndex;

        if (inCenter) {
            bottom = yScale(yDomain[start]) + yScale.bandwidth() / 2;
            top = yScale(yDomain[end]) + yScale.bandwidth() / 2;
        } else {
            if (end >= start) {
                top = yScale(yDomain[end]) - paddingInner;
                bottom = yScale(yDomain[start]) - paddingInner + (yAxis.get('type') === 'band' ? yScale.step() : 0);
            } else {
                top = yScale(yDomain[end]) - paddingInner + (yAxis.get('type') === 'band' ? yScale.step() : 0);
                bottom = yScale(yDomain[start]) - paddingInner;
            }
        }
    } else {
        bottom = yScale(start);
        top = yScale(end);
    }

    return {
        top,
        bottom
    }
}

function getLRTB(points, axisIndex, xAxis, yAxis, inCenter) {
    let left, right, top, bottom;

    if (axisIndex) {
        let lr = getLeftRight(xAxis, points, inCenter);
        let tb = getTopBottom(yAxis, points, inCenter);

        left = lr.left;
        top = tb.top;
        right = lr.right;
        bottom = tb.bottom;
    } else {
        left = points[0][0];
        top = points[0][1];
        right = points[1][0];
        bottom = points[1][1];
    }

    return { left, right, top, bottom };
}

function rect({ shape, style }, model, xAxis, yAxis) {
    let points = shape.points;
    let { left, right, top, bottom } = getLRTB(points, shape.axisIndex, xAxis, yAxis);

    let attr = subPixelOptimizeRect({
        shape: {
            x: left,
            y: top,
            width: right - left,
            height: bottom - top
        },
        style
    });

    return {
        attr,
        Shape: Rect
    }
}

rect.pointNum = 2;


function verticalRect({ shape, style }, model, xAxis, yAxis) {
    let points = shape.points;
    let top = yAxis.range[0];
    let bottom = yAxis.range[1];
    let { left, right } = getLeftRight(xAxis, points);

    let attr = subPixelOptimizeRect({
        shape: {
            x: left,
            y: top,
            width: right - left,
            height: bottom - top
        },
        style
    });

    return {
        attr,
        Shape: Rect
    }
}

verticalRect.onlyInAxis = true;
verticalRect.pointNum = 2;


function horizontalRect({ shape, style }, model, xAxis, yAxis) {
    let points = shape.points;
    let left = xAxis.range[0];
    let right = xAxis.range[1];
    let { top, bottom } = getTopBottom(yAxis, points);

    let attr = subPixelOptimizeRect({
        shape: {
            x: left,
            y: top,
            width: right - left,
            height: bottom - top
        },
        style
    });

    return {
        attr,
        Shape: Rect
    }
}

horizontalRect.onlyInAxis = true;
horizontalRect.pointNum = 2;


function line({ shape, style }, model, xAxis, yAxis) {
    let points = shape.points;
    let { left, right, top, bottom } = getLRTB(points, shape.axisIndex, xAxis, yAxis, true);

    let attr = subPixelOptimizeLine({
        shape: {
            x1: left,
            y1: top,
            x2: right,
            y2: bottom
        },
        style
    });

    return {
        attr,
        Shape: Line
    }
}

line.pointNum = 2;


function getCenter(axis, center) {
    let scale = axis.scale;

    if (axis.getAxisType() === 'ordinal') {
        let domain = scale.domain();
        let paddingInner = scale.paddingInner ? scale.paddingInner() * scale.step() / 2 : 0;

        center = Math.max(center, axis.startIndex);
        center = scale(domain[center]) + scale.bandwidth() / 2;
    } else {
        center = scale(center);
    }

    return center;
}

function verticalLine({ shape, style }, model, xAxis, yAxis) {
    let points = shape.points;
    let top = yAxis.range[0];
    let bottom = yAxis.range[1];
    let center = getCenter(xAxis, points[0][0]);

    let attr = subPixelOptimizeLine({
        shape: {
            x1: center,
            y1: top,
            x2: center,
            y2: bottom
        },
        style
    });

    return {
        attr,
        Shape: Line
    }
}

verticalLine.onlyInAxis = true;
verticalLine.pointNum = 1;


function horizontalLine({ shape, style }, model, xAxis, yAxis) {
    let points = shape.points;
    let left = xAxis.range[0];
    let right = xAxis.range[1];
    let center = getCenter(yAxis, points[0][1]);

    let attr = subPixelOptimizeLine({
        shape: {
            x1: left,
            y1: center,
            x2: right,
            y2: center
        },
        style
    });

    return {
        attr,
        Shape: Line
    }
}

horizontalLine.onlyInAxis = true;
horizontalLine.pointNum = 1;


function ellipse({ shape, style }, model, xAxis, yAxis) {
    let points = shape.points;
    let { left, right, top, bottom } = getLRTB(points, shape.axisIndex, xAxis, yAxis);

    let attr = {
        shape: {
            x: left,
            y: top,
            width: right - left,
            height: bottom - top
        },
        style
    };

    return {
        attr,
        Shape: Ellipse
    }
}

ellipse.pointNum = 2;


function arrowLine({ shape, style }, model, xAxis, yAxis) {
    let points = shape.points;
    let { left, right, top, bottom } = getLRTB(points, shape.axisIndex, xAxis, yAxis, true);

    let attr = {
        shape: {
            x1: left,
            y1: top,
            x2: right,
            y2: bottom
        },
        style
    };

    return {
        attr,
        Shape: ArrowLine
    }
}

ellipse.pointNum = 2;

function getPoints({ shape, style }, model, xAxis, yAxis) {
    let points = shape.points;

    if (shape.axisIndex) {
        let _xAxis = xAxis || model.getAxisModel('x');
        let _yAxis = yAxis || model.getAxisModel('y');

        points = transformToPX(points, _xAxis, _yAxis);
    }

    return points;
}

export default {
    rect,
    verticalRect,
    horizontalRect,
    line,
    verticalLine,
    horizontalLine,
    ellipse,
    arrowLine
};