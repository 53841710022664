import { each, isFunction, isArray, merge } from '../../../util';
import {
    subPixelOptimizeRect,
    reverseStyleStops,
    Rect
} from '../../../util/graphic';
import { setLabel } from '../helper';

export function getSingleRect(
    { rectWidth, position, y0, points, data, model, globalModel, ticks },
    renderLabel,
    [xi, yi, d, i, index],
    isEmphasis
) {
    let itemStyle = renderLabel
        ? { fill: 'rgba(0, 0, 0, 0)' }
        : isEmphasis
            ? merge(
                  model.get('itemStyle').emphasis,
                  model.get('itemStyle').normal
              )
            : model.get('itemStyle').normal;
    let rectMin = model.get('rectMin');
    let rectRadius = model.get('rectRadius');
    let labelOption = isEmphasis
        ? merge(model.get('label').emphasis, model.get('label').normal)
        : model.get('label').normal;
    let _style = isFunction(itemStyle) ? itemStyle(data[i]) : {...itemStyle};
    let subPixelOptimize = model.get('subPixelOptimize');
    let visualIndex = model.get('$visualMapIndex');
    let _rectRadius = isFunction(rectRadius) ? rectRadius(data[i]) : rectRadius 
    // 从visualMap获取颜色
    if (visualIndex !== undefined) {
        let visualMapModel = model.globalModel.getComponentByIndex('visualMap', visualIndex);

        _style.fill = visualMapModel.getColorStop(d[1], _style.fill);
    }

    if (position === 'top' || position === 'bottom') {
        let height, _yi;

        if (isArray(yi)) {
            let _min = Math.min.apply(null, yi);
            let _max = Math.max.apply(null, yi);

            height = _max - _min;
            _yi = _min;
        } else {
            height = y0 - yi;
            _yi = yi;
        }

        if (renderLabel) {
            _style = setLabel(
                _style,
                labelOption,
                { data: data[i], dataIndex: i, index },
                globalModel,
                model
            );
        }

        if (Math.abs(height) < rectMin) {
            if (height < 0) {
                _yi += rectMin + height;
                height = -rectMin;
            } else {
                _yi -= rectMin - height;
                height = rectMin;
            }
        }

        data[i][1] < 0 && (_style = reverseStyleStops(_style));

        if (height < 0) {
            _yi += height;
            height = -height;
        }

        let attr = {
            shape: {
                x: xi,
                y: _yi,
                width: rectWidth,
                height: height,
                r: _rectRadius
            },
            key: i,
            style: _style,
            z2: renderLabel ? 9 : 1
        };

        subPixelOptimize && (attr = subPixelOptimizeRect(attr));

        return {
            attr,
            animateFrom: {
                shape: {
                    y: isArray(yi) ? yi[0] : y0,
                    height: 0
                }
            }
        };
    } else {
        let width, _yi;

        if (isArray(yi)) {
            let _min = Math.min.apply(null, yi);
            let _max = Math.max.apply(null, yi);

            width = _max - _min;
            _yi = _min;
        } else {
            width = y0 - yi;
            _yi = yi;
        }

        if (renderLabel) {
            _style = setLabel(
                _style,
                labelOption,
                { data: data[i], dataIndex: i },
                globalModel,
                model
            );
        }

        if (Math.abs(width) < rectMin) {
            if (width < 0) {
                _yi += rectMin + width;
                width = -rectMin;
            } else {
                _yi -= rectMin - width;
                width = rectMin;
            }
        }

        data[i][1] < 0 && (_style = reverseStyleStops(_style));

        if (width < 0) {
            _yi += width;
            width = -width;
        }

        let attr = {
            shape: {
                x: _yi,
                y: xi,
                width: width,
                height: rectWidth,
                r: _rectRadius
            },
            key: i,
            style: _style,
            z2: renderLabel ? 9 : 1
        };

        subPixelOptimize && (attr = subPixelOptimizeRect(attr));

        return {
            attr,
            animateFrom: {
                shape: {
                    x: isArray(yi) ? yi[0] : y0,
                    width: 0
                }
            }
        };
    }
}

export default function getRectLayout(param, renderLabel) {
    let {
        rectWidth,
        position,
        y0,
        points,
        data,
        model,
        globalModel,
        ticks
    } = param;
    let itemStyle = renderLabel
        ? { fill: 'rgba(0, 0, 0, 0)' }
        : model.get('itemStyle').normal;
    let rectMin = model.get('rectMin');
    let rectRadius = model.get('rectRadius');
    let hideZero = model.get('hideZero');
    let labelOption = model.get('label').normal;
    let attrs = [];
    let animateFroms = [];

    typeof itemStyle.lineWidth === 'undefined' && (itemStyle.lineWidth = 1);

    let animateList = {
        shape: {
            x: 0,
            y: 0,
            width: 0,
            height: 0
        }
    };

    each(points, point => {
        let [xi, yi, d, i] = point;

        if (
            !renderLabel ||
            ((labelOption.show === 'all' ||
                (labelOption.show && ticks.indexOf(d[0]) > -1)) &&
                (!hideZero || (hideZero && data[i][1] !== 0)))
        ) {
            let rect = getSingleRect(param, renderLabel, point);

            attrs.push(rect.attr);
            animateFroms.push(rect.animateFrom);
        }
    });

    return {
        attrs,
        animateFroms,
        animateList,
        Shape: Rect
    };
}
