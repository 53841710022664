import View from './View';
import { each } from '../util';

const TYPE = 'chart';

/**
 * 图表View类
 * 
 * @extends {View}
 */
class ChartView extends View {

    static type = TYPE;

    type = TYPE;

    /**
     * 高亮
     * 
     * @param {object} seriesModel 
     * @param {object} globalModel 
     * @param {object} global 
     * @param {object} payload 
     */
    highlight(seriesModel, globalModel, global, payload) {
    }

    /**
     * 取消高亮
     * 
     * @param {object} seriesModel 
     * @param {object} globalModel 
     * @param {object} global 
     * @param {object} payload 
     */
    downplay(seriesModel, globalModel, global, payload) {
    }
}

export default ChartView;