import { 
    default as DataProvider,
    getLastestTime
} from '../DataProvider';
import { each, isArray } from 'zrender/src/core/util'

var formatData = function(tempArr, date){
    return {
        't': date+' '+tempArr[0],
        //主动买入特大单金额
        'mfsb': parseFloat(tempArr[1]?tempArr[1]:0),
        //主动卖出特大单金额
        'mfss': parseFloat(tempArr[2]?tempArr[2]:0),
        // 大单
        'mfbb': parseFloat(tempArr[3]?tempArr[3]:0),
        'mfbs': parseFloat(tempArr[4]?tempArr[4]:0),
        // 小单
        'mflb': parseFloat(tempArr[5]?tempArr[5]:0),
        'mfls': parseFloat(tempArr[6]?tempArr[6]:0),
        // 中单
        'mfmb': parseFloat(tempArr[7]?tempArr[7]:0),
        'mfms': parseFloat(tempArr[8]?tempArr[8]:0)
    };
}

/** 
 * 数据处理-分时资金流向
 * 包含以下行情逻辑 
 * 1. !!只有沪深个股 有资金流向的数据
 * 2. 先请求全量数据，再请求增量数据
 * 3. 可开启或关闭实时更新
 * 继承 DataProvider
 * 需要把整理的数据用 this.fetchSuccess(data) 通知出去
 * !!不可以直接对this._data 进行赋值
 * 
 * @extends DataProvider
 */
export default class FsMoneyFlow extends DataProvider{

    static type = 'fsMoneyFlow'

    /**
     * 默认配置
     *
     * ```
        code:'hs_300033',
        // 接口版本
        version: dataVersion,
        // 是否持续更新
        isKeepingGet: false,
        // 全量地址
        urlFormatter: function({version, protocol, code}) {
            return protocol+'//d.10jqka.com.cn/'+version+'/moneyflow/'+code+'/last.js';
        },
        // 增量地址
        keepGetUrlFormatter: function({version, protocol, code, todayStart}) {
            return protocol+'//d.10jqka.com.cn/'+version+'/moneyflow/' + code + '/' + todayStart +'.js';
        },
        // 更新频率
        intervalTime: 60000
     * ```
     * @defalut
     */
    static defaultOption = {
        code:'hs_300033',
        // 全量地址
        urlFormatter: function({version, protocol, code}) {
            return protocol+'//d.10jqka.com.cn/'+version+'/moneyflow/'+code+'/last.js';
        },
        // 增量地址
        keepGetUrlFormatter: function({version, protocol, code, todayStart}) {
            return protocol+'//d.10jqka.com.cn/'+version+'/moneyflow/' + code + '/' + todayStart +'.js';
        }
    }

    constructor(){
        super(...arguments);

        // 当前是否实时更新
        // 根据行情数据返回的情况，程序会自行调整是否需要实时更新,
        this.keepingGetHandle;
    }

    // 子类实现
    // 获得全量数据
    getToday (callback) {
        let that = this;
        let reqUrl = this.get('urlFormatter')({
            ...this.option
        });
        let { url, callbackName } = this.getHqCallbackNameAndUrl(reqUrl);

        this.fetchRemote(url, {
                jsonpCallbackFunction: callbackName
            })
            .then(function(d) {
                callback(d);
            }).catch(function(ex) {
                that.fetchFail(ex);
            });
    }

    // 子类实现
    // 更新增量数据
    keepGetting (callback) {
        var that = this;

        let code = this.get('code').split(',');
        let d = this.getData();
        // 如果是多个code, 只取第一个code 作是否需要更新的计算
        if(isArray(code)) code = code[0];
        let data = d[code];

        // todayStart 用于拼接增量数据的url
        let reqUrl = this.get('keepGetUrlFormatter')({
            todayStart: data.todayStart,
            ...this.option
        });
        let { url, callbackName } = this.getHqCallbackNameAndUrl(reqUrl);

        this.fetchRemote(url, {
                jsonpCallbackFunction: callbackName
            })
            .then(function(d) {
                callback(d);
            }).catch(function(ex) {
                that.fetchFail(ex);
            });
    }

    arrangeData (originalD, historyD) {
        var r = {};
        var codeArr = this.get('code').split(',');
        each(codeArr, (code, i)=>{
            r[code] = {};
            let hasHistoryD = !!(historyD && historyD[code]);
            let historyArr = hasHistoryD ? historyD[code].dataArray : '';
            let historySingleD = hasHistoryD && historyD[code];
            let d = originalD[code];

            let data = d.data.split(';');
            let dataArray = historyArr ? historyArr.slice(0):[];
            let tempArr = [], todayStart = hasHistoryD ? historySingleD.todayStart : '';
            
            for (let i = 0; i < data.length; i++) {
                tempArr = data[i].split(',');
                if (tempArr[1] && dataArray.length && tempArr[0] == todayStart) {
                    dataArray.pop();
                    dataArray.push(formatData(tempArr, d.date));
                }else {
                    dataArray.push(formatData(tempArr, d.date));
                }
            }

            todayStart = dataArray[dataArray.length-1].t.split(' ')[1];

            r[code] = {
                // 交易时间 : ['0930-1130', '1300-1500']
                tradeTime: d.rt.split(','),
                // 股票代码
                code,
                // 分时一天交易结束总共点数
                totalTimeNum: d.dotsCount || 241,
                // 股票名称
                name: d.name,

                stop: 0,
                // 是否开市
                open: d.open,

                // 更新到的最后时刻
                todayStart,

                // 整理后的数据
                dataArray: dataArray.length ? dataArray : []
            };
        });

        return r;
    }

    // 子类实现
    // 校验是否实时更新
    checkNeedGetting () {
        let code = this.get('code').split(',');
        let d = this.getData();
        // 如果是多个code, 只取第一个code 作是否需要更新的计算
        if(isArray(code)) code = code[0];
        let data = d[code];

        let isKeepingGet = this.get('isKeepingGet'); 
        
        let lastestTime = getLastestTime(data);
        
        let start, end, r = false;

        for (let i = 0; i < data.tradeTime.length; i++) {
            start = data.tradeTime[i].split('-')[0];
            end = data.tradeTime[i].split('-')[1];
            if (lastestTime >= start && lastestTime < end) {
                r = isKeepingGet;
            }
        }

        return r;
    }
    
    /**
     * 初始化
     */
    init () {
        var that = this;

        this.getToday(function (d) {
            that.fetchSuccess(that.arrangeData(d));
            
            // 是否需要实时更新
            if(!that.checkNeedGetting()){
                that.keepingGetHandle && clearTimeout(that.keepingGetHandle);
                return false;
            }

            // 持续获取
            that.keepingGetHandle = setTimeout(function repeatMoneyFn() {
                that.keepGetting(function(currentD){
                    that.fetchSuccess(that.arrangeData(currentD, that.getData()));
                });

                that.keepingGetHandle = setTimeout(repeatMoneyFn, that.get('intervalTime'));
             
            }, that.get('intervalTime'));
        });
    }

}