import GroupShape from './GroupShape';
import { each } from '../util';
import { Polygon } from '../util/graphic';

export default class MultPolygon extends GroupShape {

    constructor(attrMap) {
        super(...arguments);

        each(attrMap, (attr, key) => {
            this.setShape(key, Polygon, attr);
        });
    }
}