import ChartView from '../../view/Chart';
import { TYPE } from './GeoModel'; // 使用Model中定义的TYPE，与model的type值保持一致
import { geoTransverseMercator, geoPath } from 'd3-geo';
import { Rect, Polygon, Line, BezierCurve, Text } from '../../util/graphic';
import { each, clone } from '../../util';
import SVGPath from '../../shape/SVGPath';
import { provinceCenter } from './provinceCenter';
import { geoAxisActions } from '../../action/event';

export default class GeoView extends ChartView {

    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    constructor(model, globalModel, global) {
        super(...arguments);

        this._initEvent(model, globalModel, global);
    }

    _initEvent(model, globalModel, global){
        // global.registerAction(model, geoAxisActions.over, function ({ data, style }) {
        //     if (shape.data.name === data.name && innerPath.emphasis.show) {
        //         let s = merge(clone(innerPath.emphasis.style), style);
        //         shape.setStyle(s);
        //     }
        // });

        // global.registerAction(model, geoAxisActions.out, function ({ data }) {
        //     if (shape.data.name === data.name) {
        //         shape.setStyle('fill', shape.fill);
        //     }
        // });
    }

    render(model, globalModel, global) {
        this.renderCanvas(model, globalModel, global);
        drawSouthSea.call(this, model, globalModel, global);
    }

    renderCanvas(model, globalModel, global) {

        let { outerPath, innerPath } = model.get('map');
        let mapName = model.get('mapName');
        let visualMapIndex = model.get('$visualMapIndex');
        let visualMapModel = globalModel.getComponentByIndex('visualMap', visualMapIndex);

        let { left, right, top, bottom } = model.position;
        let data = model.getData();

        let mapData, mapBorderData;

        if (mapName == 'china') {
            mapData = window.chinaData;
            mapBorderData = window.chinaBorderData
        // 省份名称居中
        }else if(mapName == 'provinceCenter'){
            mapData = clone(window.chinaData);
            window.chinaData.features.map(function(v) {
                // 地区名称
                var name = v.properties.name;

                mapData.features.map(function(m){
                  if (name === m.properties.name) {
                    m.properties.cp = provinceCenter[name];
                  }
                })
            });

            mapBorderData = window.chinaBorderData
        }

        const getPath = () => {
            const path = geoPath()
                .projection(model.projection);
            return path;
        }

        // 地图外边框
        if (outerPath.show) {
            let path = getPath();

            this.setShape('border', SVGPath, {
                shape: {
                    path: path(mapBorderData)
                },
                style: {
                    ...outerPath.style,
                    fill: null
                }
            });
        }
        
        // 地图内块
        if (innerPath.normal.show) {
            let path = getPath();
            let attrs = [];

            // 一块块画
            each(mapData.features, (d, i) => {
                let fillType;
                let innerPathNormalStyle;

                // 从visualMap取颜色
                if (data && visualMapModel) {
                    for (let i = 0; i < data.length; i++) {
                        if (visualMapModel.isInRange(data[i].value) && data[i].name === d.properties.name) {
                            fillType = visualMapModel.getColorStop(data[i].value);
                            break;
                        }
                    }
                }
                
                if (!fillType) {
                    if (typeof innerPath.normal.style === 'function') {
                        innerPathNormalStyle = innerPath.normal.style({
                            axisIndex: d.properties.name, 
                            geoPos: d.properties.cp
                        });
                        fillType = innerPathNormalStyle.fill || '#04526d';
                    } else {
                        innerPathNormalStyle = innerPath.normal.style;
                        fillType = innerPathNormalStyle.fill;
                    }
                }

                attrs.push({
                    shape: {
                        path: path(d),
                        fillType: fillType
                    },
                    style: {
                        ...innerPathNormalStyle,
                        fill: fillType
                    },
                    data: {
                        name: d.properties.name
                    },
                    fill: fillType
                });
            });

            this.setShapeGroup('geomap', SVGPath, attrs, undefined, undefined, shape => {
                /** 记录一般状态下的样式 */
                const style=JSON.parse(JSON.stringify(shape.style))
                shape.on('mouseover', (e) => {
                    global.dispatchAction(model, geoAxisActions.over, {data: shape.data});

                    if (innerPath.emphasis.show) {
                        shape.setStyle(innerPath.emphasis.style);
                    }
                });


                shape.on('mouseout', (e) => {
                    global.dispatchAction(model, geoAxisActions.out, {data: shape.data});

                    if (innerPath.normal.show) {
                         /** 取消高亮恢复一般样式 */
                         shape.setStyle(style);
                    }
                });


                shape.on('click', function (e) {
                    global.dispatchAction(model, geoAxisActions.itemClick, {data: shape.data});
                });

            });
        }
    }
}


// SouthSea Path
const paths = ['m6,31.5l9,7.5l15,9l15,4l18,0l17,-14l21,-31L20,7L6,24z', 'm113,7l10,25l11,-25z', 'm46.5,66.5l14.5,-6.5l-1,13l-7,7l-15,4l8.5,-17.5z']
const lines = [
    { y2: 7, x2: 145, y1: 7, x1: 20 },
    { y2: 24, x2: 6, y1: 7, x1: 20 },
    { y2: 195, x2: 145, y1: 7, x1: 145 },
    { y2: 195, x2: 6, y1: 24, x1: 6 },
    { y2: 195, x2: 145, y1: 195, x1: 6 },
    { y2: 46.5, x2: 132.5, y1: 31.5, x1: 141.5 },
    { y2: 76.5, x2: 115.5, y1: 61.5, x1: 121.5 },
    { y2: 111.5, x2: 110.5, y1: 92.5, x1: 110.5 },
    { y2: 147.5, x2: 101.5, y1: 127.5, x1: 108.5 },
    { y2: 177.5, x2: 78.5, y1: 163.5, x1: 91.5 },
    { y2: 188.5, x2: 39.5, y1: 184.5, x1: 54.5 },
    { y2: 158.5, x2: 11.5, y1: 172.5, x1: 17.5 },
    { y2: 132.5, x2: 39.5, y1: 142.5, x1: 24.5 },
    { y2: 98.5, x2: 37.5, y1: 113.5, x1: 40.5 }
];

function drawSouthSea(model, globalModel, global) {
    const attrs = [];
    const attrs2 = [];
    let southSeaOpt = model.get('southSea');
    let markerOpt = model.get('markers');
    let gridModel = model.globalModel.getComponentByIndex('grid', 0);
    let { right, bottom } = gridModel.position;
    let { outerPath } = model.get('map');
    function toPoint(percent) {
        let str = percent.replace('%', '');
        str = str / 100;
        return str;
    }

    if (southSeaOpt.show) {
        each(paths, function (p) {
            attrs.push({
                shape: {
                    path: p
                },
                style: {
                    stroke: outerPath.style.stroke,
                    fill: outerPath.style.fill,
                    lineWidth: 1
                },
                scale: southSeaOpt.scale,
                position: [right * toPoint(southSeaOpt.offset[0]), bottom * toPoint(southSeaOpt.offset[1])]
            });
        });
        each(lines, function (l) {
            attrs2.push({
                shape: {
                    x1: l.x1,
                    y1: l.y1,
                    x2: l.x2,
                    y2: l.y2
                },
                style: {
                    stroke: outerPath.style.stroke,
                    fill: outerPath.style.fill,
                    lineWidth: southSeaOpt.lineWidth
                },
                scale: southSeaOpt.scale,
                position: [right * toPoint(southSeaOpt.offset[0]), bottom * toPoint(southSeaOpt.offset[1])]
            })
        })

        this.setShapeGroup('southSea', SVGPath, attrs);
        this.setShapeGroup('southSeaLine', Line, attrs2);
        this.setShape('southSeaLabel', Text, {
            style: {
                text: '中国南海诸岛',
                textFill: southSeaOpt.style.textFill,
                textAlign: 'middle',
                fontSize: southSeaOpt.style.fontSize
            },
            position: [right * toPoint(southSeaOpt.offset[0]) + (75 + southSeaOpt.style.textOffset[0]) * southSeaOpt.scale[0], bottom * toPoint(southSeaOpt.offset[1]) + (190 - southSeaOpt.style.fontSize * 2 + southSeaOpt.style.textOffset[1]) * southSeaOpt.scale[1]],
            z: model.get('z'),
            scale: southSeaOpt.scale,
            z2: 999
        });
    }
}