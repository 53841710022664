/**
 * 发散线柱子的圆环图 by zp
 */
import SeriesModel from '../../model/Series';
import { filter } from '../../util';

const TYPE = 'arcline';

export default class ArcModel extends SeriesModel {
    static type = TYPE;

    type = TYPE;

    static defaultOption = {
        zlevel: 0,
        z: 1,

        // 默认全局居中
        center: ['50%', '50%'], 
        // 半径
        radius: [10, 20],

        // 标题
        title : {
            // x, y，单位px
            offsetCenter: [0, 0],
            textStyle: { // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                color: '#333',
                fontStyle: 'normal',
                fontFamily: 'sans-serif',
                fontWeight: 'normal',
                fontSize: 14
            }
        },

        circleStyle: {
            show: false,
            innerRadius: 55,
            outerRadius: 70,
            innerStrokeColor: '#333',
            innerFillColor: '#F04927',
            outerFillColor: '#F04927'
        },

        itemStyle: {
            normal: {
                stroke: '#333',
                fill: '#333',
                cornerRadius: 0,
                lineWidth: 1
            },
            formatter: function (originD, d) {
                return d;
            },

            textStyle: {
                // 'center',
                position: null,
                color: '#333',
                fontFamily: 'sans-serif',
                fontWeight: 'normal',
                fontSize: 12
            },
            emphasis: {
                lineWidth: 2
            }
        }
    }

    update() {
        let {globalModel} = this;
        let originData = this.getData();
        let selected = this.get('selected');
        let drawData = filter(originData.values, (v, k) => {
            return !(selected && selected[v.name] === false)
        });
        if (drawData.length > 0) {
            drawData.sort(function(a, b) {
                return b.value - a.value
            })
            let {max, min} = originData;
            this.drawData = {max, min, values: drawData};
        }
    }
}
