/**
 * 数据缩放
 */
import ComponentModel from '../../model/Component';
import { parsePercent, each, parseSize, isString, isObject, isArray } from '../../util';
import setModelPosition from '../helpers/setModelPosition';
import scales from '../axis/scale';

export const TYPE = 'dataZoom';

/*

dataZoom 组件 用于区域缩放，从而能自由关注细节的数据信息，或者概览数据整体，或者去除离群点的影响。type为inside时依赖于grid。


组件详细配置项如下，每个配置项都有一个默认值：

*/

export default class DataZoomModel extends ComponentModel {
    static type = TYPE;

    type = TYPE;

    // http://echarts.baidu.com/option.html#dataZoom-inside
    static defaultOption = {
        $gridIndex: 0, // {number|Array<number>} 依赖的gridIndex，滚轮等交互事件将加在该grid内，可以为数组，在多个grid中添加事件
        type: 'inside', // {string} 缩放类型 inside | slider

        // slider类型的dataZoom位置
        left: '10%', // {string|number} 左
        right: '10%', // {string|number} 右
        top: undefined, // {string|number} 顶
        bottom: '3%', // {string|number} 底
        width: undefined, // {string|number} 宽
        height: 25, // {string|number} 高

        // 背景设置
        background: {
            show: true, // {boolean} 是否显示背景
            style: { // {Object} 样式
                fill: 'rgba(47,69,84,0)',
                stroke: '#ddd',
                lineWidth: 1
            }
        },

        seriesIndex: 0, // {number} 只取得series的数据用，并非依赖，关联的series的index（需注意无$符），用于在dataBackGround中绘制背景 
        valueIndex: 0, // {number} 当series的单个数据项为数组时，取数组的第几个值画线

        // 以seriesIndex关联的series绘制数据背景，仅支持以折线绘制
        dataBackground: {
            show: false, // {boolean} 是否显示数据背景
            // 折线配置
            line: {
                show: true, // {boolean} 是否以折线显示
                style: { // {Object} 样式
                    stroke: 'rgba(47,69,84,0.3)',
                    lineWidth: 0.5
                }
            },
            // 区域配置
            area: {
                show: true, // {boolean} 是否以区域显示
                style: { // {Object} 样式
                    fill: 'rgba(47,69,84,0.1)'
                }
            }
        },

        // handler两侧label
        label: {
            show: false, // {boolean} 在拖拽条两边显示推拽位置信息
            formatter: d => d, // {Function} 格式整理
            // 标签样式
            style: {} // {Object} 样式
        },

        // 填充块配置
        filler: {
            show: true, // {boolean} 是否显示填充块
            type: 'rect', // {string} 显示样式
            style: { // {Object} 样式
                fill: 'rgba(167,183,204,0.4)',
                stroke: '#ddd'
            }
        },

        // 手柄配置
        handler: {
            show: true, // 是否显示手柄
            type:
                'path://M8.2,13.6V3.9H6.3v9.7H3.1v14.9h3.3v9.7h1.8v-9.7h3.3V13.6H8.2z M9.7,24.4H4.8v-1.4h4.9V24.4z M9.7,19.1H4.8v-1.4h4.9V19.1z', // {String} 显示类型，支持svg的path
            size: [10, '100%'], // {Array} 控制手柄的尺寸，可以是像素大小，也可以是相对于 dataZoom 组件宽度的百分比，默认跟 dataZoom 宽度相同。
            style: { // {Object} 样式
                fill: '#a7b7cc',
                stroke: '#000',
                lineWidth: 0
            }
        },

        start: undefined, // {string} 数据窗口范围的起始百分比。
        end: undefined, // {string} 数据窗口范围的结束百分比。
        startValue: undefined, // {number}, 数据窗口范围的起始值，startValue: -30 表示从数据最后30个开始画
        endValue: undefined, // {number}, 数据窗口范围的结束值

        minSpan: undefined, // {number} 用于限制窗口大小的最小值 0 ~ 1
        maxSpan: undefined, // {number} 用于限制窗口大小的最大值 0 ~ 1
        minValueSpan: undefined, // {number} 用于限制窗口大小的最小值显示个数
        maxValueSpan: undefined, // {number} 用于限制窗口大小的最大值显示个数

        pressStop: false, // {boolean} 长按停止缩放
        zoomOnMouseWheel: true, // {boolean} 滚轮是否触发数据缩放
        moveOnMouseMove: true, // {boolean} 鼠标拖拽移动是否触发数据平移
        preventDefaultMouseMove: false, // {boolean} 是否阻止 mousemove 事件的默认行为。
        preventDefaultMouseWheel: true, // {boolean} 是否阻止 mousewheel 事件的默认行为。

        orient: 'horizontal', // {String} 触发数据平移的拖拽移动事件方向 horizontal | vertical

        zlevel: 0, // {number} 所有图形的 zlevel 值
        z: 8 // {number} 组件的所有图形的z值。控制图形的前后顺序。z值小的图形会被z值大的图形覆盖。
    };

    update() {
        let { globalModel } = this;
        let start = parsePercent(this.get('start'));
        let end = parsePercent(this.get('end'));
        let axisModel = globalModel.getComponent('axis', (model) => {
            return model.get('$dataZoomIndex') === this.index && model.getAxisType() === 'ordinal';
        })[0];
        let {
            _option: {
                handler,
                orient,
                width,
                height,
                dataBackground,
                seriesIndex,
                label,
                valueIndex
            }
        } = this;
        let minSpan = this.get('minSpan');
        let maxSpan = this.get('maxSpan');

        // 将minValueSpan和maxValueSpan转为minSpan和maxSpan
        if (axisModel) {
            let startValue = this.get('startValue');
            let endValue = this.get('endValue');
            let axisData = axisModel.getData();

            if (axisData) {
                let dataLen = axisData.length;
                let minValueSpan = this.get('minValueSpan');
                let maxValueSpan = this.get('maxValueSpan');

                if (startValue !== undefined && start === undefined) {
                    typeof startValue !== 'number' && (startValue = axisData.indexOf(startValue));
                    startValue < 0 && (startValue += dataLen);
                    start = startValue / (dataLen - 1);
                }

                if (endValue !== undefined && end === undefined) {
                    typeof endValue !== 'number' && (endValue = axisData.indexOf(endValue));
                    endValue < 0 && (endValue += dataLen);
                    end = endValue / (dataLen - 1);
                }

                this.axisData = axisData;

                if (minValueSpan !== undefined) {
                    minSpan = minValueSpan / dataLen;
                }

                if (maxValueSpan !== undefined) {
                    maxSpan = maxValueSpan / dataLen;
                }
            }

            this.axisModel = axisModel;
        }

        start = start || 0;
        end = end || 1;
        this.handlerStart = Math.min(Math.max(start, 0), 1);
        this.handlerEnd = Math.min(Math.max(0, end), 1);
        this.start = Math.max(Math.min(start, end), 0);
        this.end = Math.min(Math.max(start, end), 1);
        this.minSpan = minSpan;
        this.maxSpan = maxSpan;

        setModelPosition(this);

        if (handler.show) {
            if (orient === 'horizontal') {
                this.handlerSize = parseSize(handler.size, height);
            } else {
                this.handlerSize = parseSize(handler.size, width);
            }
        }

        if (dataBackground.show || label.show) {
            let seriesModel = globalModel.getComponentByIndex(
                'series',
                seriesIndex
            );
            let xAxisModel = seriesModel.getAxisModel('x');
            let xAxisData = xAxisModel.getData();

            if (dataBackground.show) {
                let xAxisType = xAxisModel.get('type');
                let yAxisModel = seriesModel.getAxisModel('y');
                let yAxisType = yAxisModel.get('type');
                let seriesData = seriesModel.getRealData();
                let xScale = (this.xScale = this.xScale || scales[xAxisType]());
                let yScale = (this.yScale = this.yScale || scales[yAxisType]());
                let { left, right, top, bottom } = this.position;
                let points = (this.points = []);

                xScale.domain(xAxisData).range([left, right]);

                yScale
                    .domain([
                        seriesModel.getDataExtent(
                            'min',
                            undefined,
                            valueIndex,
                            false
                        )[1],
                        seriesModel.getDataExtent(
                            'max',
                            undefined,
                            valueIndex,
                            false
                        )[1]
                    ])
                    .range([bottom, top]);

                let offset = xScale.bandwidth ? xScale.bandwidth() / 2 : 0;

                each(xAxisData, function (d, index) {
                    if (isArray(seriesData[index][1])) {
                        points.push([xScale(d) + offset, yScale(seriesData[index][1][valueIndex])]);
                    } else {
                        points.push([xScale(d) + offset, yScale(seriesData[index][1])]);
                    }
                });
            }

            if (label.show) {
                let xAxisDataLen = xAxisData.length;
                this.xstart = xAxisData[Math.round((xAxisDataLen - 1) * start)];
                this.xend = xAxisData[Math.round((xAxisDataLen - 1) * end)];
            }
        }

    }
}
