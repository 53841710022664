import { Path } from '../util/graphic';

export default Path.extend({

    type: 'lineCircle',

    shape: {
        cx: 0,
        cy: 0,
        r: 0,
        angle: -45,
        step: 3
    },
    style: {
        stroke: '#000',

        fill: null
    },

    buildPath: function (ctx, shape) {
        let { cx, cy, r, angle, step } = shape;

        angle = angle / 180 * Math.PI;

        let k = Math.tan(angle);
        let stepB = step / Math.cos(angle + Math.PI / 2);

        function drawLines(stepB) {
            let cur = 0;
            let curB = 0;

            while (cur < r) {
                let m = 1 + Math.pow(k, 2);
                let n = 2 * k * curB;
                let p = Math.pow(r, 2) - Math.pow(curB, 2);
                let q = Math.pow(n, 2) / 4 / Math.pow(m, 2) + p / m;
                let x1 = Math.sqrt(q) - n / 2 / m;
                let x2 = -Math.sqrt(q) - n / 2 / m;
                let y1 = k * x1 + curB;
                let y2 = k * x2 + curB;
    
                ctx.moveTo(x1, y1);
                ctx.lineTo(x2, y2);
    
                curB += stepB;
                cur += step;
            }
        }

        ctx.arc(cx, cy, r, 0, Math.PI * 2, true);
        drawLines(stepB);
        drawLines(-stepB);
    }
});
