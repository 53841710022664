import Path from 'zrender/src/graphic/Path';
import { each } from '../util';

export default Path.extend({

    type: 'largeRects',

    shape: {
        points: [],
        y0: 0,
        rectWidth: 1
    },

    style: {
        fill: 'black',
        stroke: null
    },

    buildPath: function (ctx, shape) {
        let { points, y0, rectWidth } = shape;

        each(points, function (point) {
            ctx.rect(point[0], point[1], rectWidth, y0 - point[1]);
        });
    }
});