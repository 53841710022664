/**
 * 日历
 */
import ComponentModel from '../../model/Component';
import { parsePercent, isArray, map, each, find } from '../../util';
import { timeDays, timeMonths, timeWeek } from 'd3-time';

export const TYPE = 'calendar';

export default class CalendarModel extends ComponentModel {

    static type = TYPE;

    type = TYPE;

    static defaultOption = {

        show: true, // {boolean} 是否显示
        // 显示位置
        left: 50, // {string | number} 左
        top: 50, // {string | number} 上

        cellSize: 20, // {number} 日历每格框的大小
        orient: 'horizontal', // {string} 日历坐标的布局朝向。
        range: new Date().getFullYear(), // {string|Array} 必填，日历坐标的范围 支持多种格式 2017 | '2017-02' | ['2017-01-02', '2017-02-23'] | ['2017-01', '2017-02']

        // 设置日历格的样式
        itemStyle: {
            stroke: '#ccc',
            fill: '#fff',
            lineWidth: 1
        },

        // 设置日历坐标分隔线的样式。
        splitLine: {
            show: true, // {boolean} 是否显示
            style: { // {Object} 样式
                stroke: '#000',
                lineWidth: 1,
                fill: null
            }
        },

        // 设置日历坐标中 星期轴的样式
        dayLabel: {
            show: true, // {boolean} 是否显示
            firstDay: 0, // {number} 一周从周几开始，默认从周日开始
            margin: 10, // {number} 星期标签与轴线之间的距离
            position: 'start', // {string} 星期的位置 在星期轴的开头还是结尾。
            nameMap: 'cn', // {string|Array} 星期显示的效果，默认为'cn' 可设置中英文以及自定义 下标0为对应星期天的文字显示 cn | en | 自定义 [1, 2]
            style: { // {Object} 样式
                fill: '#000'
            }
        },

        // 设置日历坐标中 月份轴的样式
        monthLabel: {
            show: true, // {boolean} 是否显示
            margin: 10, // {number} 月份标签与轴线之间的距离
            position: 'start', // {string} 月份的位置 在开头还是结尾。
            nameMap: 'cn', // {string|Array} 月份显示的效果，默认为'en' 可设置中英文以及自定义 下标0为对应一月的文字显示
            style: { // {Object} 样式
                fill: '#000'
            }
        },

        // 设置日历坐标中 年的样式
        yearLabel: {
            show: true, // {boolean} 是否显示
            margin: 40, // {number} 年份与轴线之间的距离
            style: { // {Object} 样式
                fill: '#ccc',
                fontSize: 20
            }
        },

        zlevel: 0, // {number} 所有图形的 zlevel 值
        z: 0 // {number} 组件的所有图形的z值。控制图形的前后顺序。z值小的图形会被z值大的图形覆盖。
    };

    update() {
        let range = this.get('range');
        let dayLabel = this.get('dayLabel');
        let orient = this.get('orient');
        let firstDay = dayLabel.firstDay % 7;
        let start, end;
        let days = [], months = {};

        if (isArray(range)) {
            let range0 = new Date(range[0]);

            start = new Date(range0.getFullYear(), range0.getMonth(), range0.getDate());
            end = new Date(range[1]);
        } else {
            let _range = range.toString().split('-');

            if (_range.length === 1) {
                start = new Date(range, 0, 1);
                end = new Date(+range + 1, 0, 1);
            } else {
                start = new Date(_range[0], +_range[1] - 1, 1);
                end = new Date(_range[0], +_range[1], 1);
            }
        }

        let _days = timeDays(start, end);
        let lastMonth;

        // 转换
        for (let i = 0; i < _days.length; i++) {
            let d = _days[i];
            let yi = d.getDay() - firstDay;
            let xi = timeWeek.count(_days[0], d);
            let m = '' + d.getFullYear() + '-' + d.getMonth();

            !months[m] && (months[m] = []);

            yi < 0 && (yi += 7);
            d.getDay() < firstDay && (xi--);

            if (orient !== 'horizontal') {
                [xi, yi] = [yi, xi];
            }

            let re = {
                d,
                yi,
                xi
            };

            if (i === 0) {
                months[m][0] = re;
                lastMonth = m;
            } else if (m !== lastMonth) {
                months[m][0] = re;
                months[lastMonth][1] = days[i - 1];
                lastMonth = m;
            }

            days.push(re);
        }

        months[lastMonth][1] = days[days.length - 1];

        // 修正
        if (days[0].xi < 0) {
            each(days, function (d) {
                d.xi++;
            });
        }

        this.days = days;
        this.months = months;
    }

    calcPoint(date) {
        let { days } = this;
        let cellSize = this.get('cellSize');
        let left = this.get('left');
        let top = this.get('top');
        let finded;

        date = new Date(date);
        finded = find(days, function(day) {
            let d = day.d;
            return d.getFullYear() === date.getFullYear() && d.getMonth() === date.getMonth() && d.getDate() == date.getDate();
        });

        if (finded) {
            finded.x = left + cellSize * (finded.xi + 0.5);
            finded.y = top + cellSize * (finded.yi + 0.5);

            return finded;
        }
    }
}