/**
 *  关系布局图 by zp
 *  @todo 待和DagrexModel合并
 *  关系图
 */
import SeriesModel from '../../model/Series';
import { each, map } from '../../util';

export const TYPE = 'dagrex';

export default class DagrexModel extends SeriesModel {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    static defaultOption = {
        zlevel: 0, // canvas层级
        z: 1, // 同一层canvas内层级
        isTop: false,
        limited: true,
        node: { // 人物事件节点 
            rankStep: 80,
            entireLabel: false,
            shape: {
                width: 80, // 节点形状弧度
                height: 30,
                r: 15
            },
            style: {
                normal: {
                    colors: {origin: 'rgb(47, 151, 255)', process: 'rgba(169, 210, 251)', end: 'rgba(43, 87, 194, 1)'},
                    fontSize: 14, // label字体颜色与节点颜色一致
                    textFill: 'rgb(255, 255 ,255)',
                    stroke: 'rgb(47, 151, 255)',
                    lineWidth: 1
                }
            }
        },
        link: { // 人物事件节点
            arrowSize: [3, 6], // 箭头大小范围，数组元素一表示箭头最小值，数组元素二表示箭头最大值
            lineWidth: [1, 3], // 连线宽度范围，数组元素一表示最小宽度，数组元素二表示最大宽度
            label: {
                type: 'percent',
                fontSize: 10, // 连线上文字大小
                textFill: 'rgb(47, 151, 255)' // 连线上文字颜色
            },
            style: {
                normal: {
                    stroke: 'rgb(47, 151, 255)', // 连线颜色
                    opacity: 0.4 // 连线透明度
                }
            }
        }
    };
}
