import { Circle, Line, Text, Polygon, Polyline } from '../../util/graphic';
import ChartView from '../../view/Chart';
import { each } from '../../util';
import { TYPE } from './PieRelationModel'; // 使用Model中定义的TYPE，与model的type值保持一致
import dagre from '../../util/dagre';
import { Symbol } from '../../util/symbol';
import { max } from 'd3-array';
import { point } from 'd3-scale/src/band';
import { forceActions } from '../../action/event';
import Arc from '../../shape/D3Arc';

export default class PieRelationView extends ChartView {

    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    render(model, globalModel, global) {
        let data = model.getData();
        let nodeOpt = model.get('node');
        let clusterOpt = model.get('cluster');

        this.w = global.getWidth();
        this.h = global.getHeight();

        if (!this.isRender) {
            let scaleParam = (1 / model.finalScale - 1) / 0.1;
            let offsetX = this.w / 2;
            let offsetY = this.h / 2;
            this.scale(offsetX, offsetY, scaleParam);
        }
        this.isRender = true;

        let offsetWidth = this.w - model.g.graph().width;
        let offsetHeight = this.h - model.g.graph().height;
        let attrs = [];
        let attrs2 = [];
        let attrs3 = [];

        renderLinks.call(this, model, global, offsetWidth, offsetHeight, data.edges);
        let changeStroke = (shape, stroke, lineWidth) => {
            shape.setStyle('stroke', stroke);
            shape.setStyle('lineWidth', lineWidth);
        }
        let getChildShape = (child, stroke, lineWidth) => {
            if (model.g.node(child).style) {
                let childShape = this.getShape(child);
                changeStroke(childShape, stroke, lineWidth);
            }
        }

        each(model.g.nodes(), (v, i) => {
            let nodeW = 20;
            let nodeH = 20;
            let node = model.g.node(v);
            let currentX = node.x + offsetWidth / 2;
            let currentY = node.y + offsetHeight / 2;
            this.setShape(node.label, Circle, {
                shape: {
                    cx: currentX,
                    cy: currentY,
                    r: nodeOpt.shape.r
                },
                style: {
                    ...nodeOpt.style.normal,
                    fill: nodeOpt.colors[node.group]
                },
                // position: [-nodeOpt.shape.r / 2, -nodeOpt.shape.r / 2],
                z: model.get('z'),
                z2: 998,
                silent: true,
                group: model.g.node(v).label,
                key: v
            });
            this.setShape(node.label + 'label', Text, {
                style: {
                    text: model.splitNameArr[i],
                    fontSize: nodeOpt.style.normal.fontSize,
                    textFill: nodeOpt.colors[node.group],
                    textAlign: 'left'
                },
                position: [currentX + nodeOpt.shape.r, currentY - nodeOpt.style.normal.fontSize / 2],
                z: model.get('z'),
                z2: 999,
                silent: true,
                nodeName: v,
                key: v
            })
            let proportion = node.proportion;
            let proportionArr = [];
            let endAngle = 0;
            for (let p in proportion) {
                endAngle += proportion[p];
                proportionArr.push(endAngle)
            }
            let startAngle = [0];
            for (let i = 0; i < proportionArr.length ; i += 1) {
                if (i < proportionArr.length - 1) {
                    startAngle.push(proportionArr[i])
                }
            }
            each(startAngle, (start, i) => {
                this.setShape(node.label + 'pie' + i, Arc, {
                    shape: {
                        outerRadius: nodeOpt.shape.r - nodeOpt.shape.piePadding,
                        innerRadius: 0,
                        startAngle: start * Math.PI * 2,
                        endAngle: proportionArr[i] * Math.PI * 2
                    },
                    position: [currentX, currentY],
                    style: {
                        fill: nodeOpt.pieColors[i]
                    },
                    z: model.get('z'),
                    z2: 998,
                    silent: true
                })
            })
        })
    }
}

function getScale(model) {
    let fixedParam = 1;
    let scaleW = model.g.graph().width / this.w;
    let scaleH = model.g.graph().height / this.h;
    let finalScale = Math.max(scaleW, scaleH) * fixedParam;
    return finalScale;
}

function renderLinks(model, global, offsetWidth, offsetHeight) {
    let linkOpt = model.get('link');
    let roamOpt = model.get('roam');
    let attrs = [];
    let attrs2 = [];

    each(model.g.edges(), (e, i) => {
        const lineWidth = linkOpt.lineWidth;
        let points = model.g.edge(e).points;
        let point1 = [points[points.length - 2].x + offsetWidth / 2, points[points.length - 2].y + offsetHeight / 2];
        let point2 = [points[points.length - 1].x + offsetWidth / 2, points[points.length - 1].y + offsetHeight / 2];
        const arrowSize = linkOpt.arrowSize;
        const lineLength = Math.sqrt(Math.pow((point2[0] - point1[0]), 2) + Math.pow((point2[1] - point1[1]), 2))
        const percent = (lineLength - arrowSize) / lineLength;

        let pointArr = points.map((p, i) => {
            if (i < points.length - 1) {
                return [p.x + offsetWidth / 2, p.y + offsetHeight / 2];
            } else {
                return [point1[0] + (point2[0] - point1[0]) * percent, point1[1] + (point2[1] - point1[1]) * percent];
            }
        });

        this.setShape(e.v + e.w, Polyline, {
            shape: {
                points: pointArr
            },
            style: {
                ...linkOpt.style.normal
            },
            z: model.get('z'),
            z2: 997,
            key: e.v + e.w,
            silent: true
        });

        let arrowAngle;
        if ((point2[1] - point1[1]) >= 0) {
            arrowAngle = Math.asin((point2[0] - point1[0]) / lineLength);
        } else if ((point2[1] - point1[1]) < 0 && (point2[0] - point1[0]) < 0) {
            arrowAngle = - Math.PI / 2 + Math.asin((point2[1] - point1[1]) / lineLength);
        } else if ((point2[1] - point1[1]) < 0 && (point2[0] - point1[0]) >= 0) {
            arrowAngle = Math.PI / 2 + Math.asin((point1[1] - point2[1]) / lineLength);
        }

        this.setShape(e.w + e.v, Polygon, {
            shape: {
                points: [point2, [point2[0] + arrowSize, point2[1] - arrowSize], [point2[0] - arrowSize, point2[1] - arrowSize]]
            },
            style: {
                fill: linkOpt.style.normal.stroke
            },
            z: model.get('z'),
            z2: 997,
            rotation: arrowAngle,
            origin: point2,
            key: e.w + e.v,
            silent: true
        });
    })
}

