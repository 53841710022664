import Indicator from '../Indicator';
import { IndicatorFormula } from 'hxc3-indicator-formula';

const Formula = IndicatorFormula.getClass('wr')
let formula = new Formula();

export default class WR extends Indicator{
    static type = 'wr';

    static defaultOption = {
        WR1: {
            type: 'line',
            normal:{
                show: true, 
                style:{
                    stroke: '#C00C00', 
                    lineWidth: 1
                }
            },
            emphasis: undefined
        },
        WR2: {
            type: 'line',
            normal:{
                show: true, 
                style:{
                    stroke: '#C0BA4D', 
                    lineWidth: 1
                }
            },
            emphasis: undefined
        },
        A: {
            type: 'line',
            normal:{
                show: true, 
                style:{
                    lineDash: [2, 4],
                    stroke: 'rgba(204,204,204,0.8)', 
                    lineWidth: 1
                }
            },
            emphasis: undefined
        },
        D: {
            type: 'line',
            normal:{
                show: true, 
                style:{
                    lineDash: [2, 4],
                    stroke: 'rgba(204,204,204,0.8)', 
                    lineWidth: 1
                }
            },
            emphasis: undefined
        }
    }

    static calculate(data){return formula.calculate(data)}

    static formatData(d, i) {
        return [i, [d.WR1, d.WR2, d.A, d.D], d];
    }

}