import GroupShape from '../../shape/GroupShape';
import { each } from '../../util';
import { Symbol, createSymbol } from '../../util/symbol';
import { Group } from '../../util/graphic';

export default class EffectLine extends GroupShape {

    constructor(attrMap) {
        super(...arguments);

        let symbol = this.setShape('symbol', Symbol, attrMap);

        let rippleGroup = new Group();
        let model = attrMap.model;
        let rippleEffect = model.get('rippleEffect');
        let showEffectOn = model.get('showEffectOn');
        let rippleNumber = rippleEffect.number;
        let ripplePeriod = rippleEffect.period;
        let rippleScale = rippleEffect.scale;
        let rippleBrushType = rippleEffect.brushType;

        for (let i = 0; i < rippleNumber; i++) {
            let ripplePath = new Symbol({
                ...attrMap,
                z2: 99,
                silent: true,
                scale: [0.8, 0.8]
            });

            if (rippleBrushType === 'fill') {
                let color = attrMap.style.fill || attrMap.style.stroke;
                
                ripplePath.setStyle({
                    fill: color,
                    stroke: null
                });
            } else {
                let color = attrMap.style.stroke || attrMap.style.fill;
                
                ripplePath.setStyle({
                    fill: null,
                    stroke: color
                });
            }

            let delay = -i / rippleNumber * ripplePeriod;

            ripplePath.animateScale = ripplePath.animate('', true)
                .when(ripplePeriod, {
                    scale: [rippleScale, rippleScale]
                })
                .delay(delay);
            ripplePath.animateOpacity = ripplePath.animateStyle(true)
                .when(ripplePeriod, {
                    opacity: 0
                })
                .delay(delay);

            if (showEffectOn === 'emphasis') {
                symbol.on('mouseover', function () {
                    ripplePath.show();
                    ripplePath.animateScale.start();
                    ripplePath.animateOpacity.start();
                });
                symbol.on('mouseout', function () {
                    ripplePath.animateScale.stop();
                    ripplePath.animateOpacity.stop();
                    ripplePath.hide();
                });
            } else {
                ripplePath.animateScale.start();
                ripplePath.animateOpacity.start();
            }

            rippleGroup.add(ripplePath);
        }

        this.set('rippleGroup', rippleGroup)
    }
}
