import ComponentView from '../../view/Component';
import { setHoverStyle, Rect, subPixelOptimizeRect } from '../../util/graphic';
import { TYPE } from './MarkAreaModel';
import { each, merge, parseSize, isArray } from '../../util';
import { getMarkData } from '../markLine/MarkLineView';

export default class MarkAreaModel extends ComponentView {
    static type = TYPE;

    type = TYPE;

    render(model, globalModel) {
        if (model.get('show')) {
            let data = model.getData();
            let areaStyle = model.get('areaStyle');
            let labelOpt = model.get('label').normal;
            let animation = model.get('animation');
            let attrs = [];

            each(data, function (item, i) {
                let _areaStyle = merge(
                    (item.areaStyle || {}).normal || {},
                    areaStyle.normal
                );
                let _labelOpt = merge(
                    (item.label && item.label.normal) || {},
                    labelOpt
                );
                let _labelStyle = {};

                if (_labelOpt.show) {
                    let text = _labelOpt.formatter ? _labelOpt.formatter(item) : item.value;

                    _labelStyle = globalModel.getFormattedText({
                        text,
                        ..._labelOpt.style
                    });
                }

                if (item.points) {
                    let xAxisModel = model.getAxisModel('x');
                    let yAxisModel = model.getAxisModel('y');
                    let xRange = xAxisModel.range;
                    let yRange = yAxisModel.range;
                    let points = [];
                    let inRange = false;


                  let isOverflowMax = item.points.every(d => d.xValue > xAxisModel.domain[xAxisModel.domain.length - 1])
                  let isOverflowMin = item.points.every(d => d.xValue < xAxisModel.domain[0])

                  //console.log(!(isOverflowMax || isOverflowMin))
                  if (!(isOverflowMax || isOverflowMin)) {
                    each(item.points, function (point) {
                        let { position } = getMarkData(
                            point,
                            model,
                            globalModel
                        ) || {};

                        if (position) {
                            points.push(position);
                        }
                    });
                  }

                    if (points.length >= 2) {
                        let x = 0, y = 1;

                        if (['left', 'right'].indexOf(xAxisModel.get('position')) > -1) {
                            x = 1;
                            y = 0;
                        }

                        if ((points[0][0] <= xRange[y] || points[1][0] <= xRange[y]) &&
                            (points[0][0] >= xRange[x] || points[1][0] >= xRange[x]) &&
                            (points[0][1] <= yRange[x] || points[1][1] <= yRange[x]) &&
                            (points[0][1] >= yRange[y] || points[1][1] >= yRange[y])
                        ) {
                            inRange = true
                        }

                        if (!inRange) return;

                        points[0][0] = Math.min(Math.max(xRange[x], points[0][0]), xRange[y]);
                        points[0][1] = Math.min(Math.max(yRange[y], points[0][1]), yRange[x]);

                        points[1][0] = Math.min(Math.max(xRange[x], points[1][0]), xRange[y]);
                        points[1][1] = Math.min(Math.max(yRange[y], points[1][1]), yRange[x]);


                        if (['left', 'right'].indexOf(xAxisModel.get('position')) > -1) {
                            points[0] = [points[0][1], points[0][0]];
                            points[1] = [points[1][1], points[1][0]];
                        }

                        attrs.push(
                            subPixelOptimizeRect({
                                cursor: model.get('cursor'),
                                shape: {
                                    x: points[0][0],
                                    y: points[0][1],
                                    width: points[1][0] - points[0][0],
                                    height: points[1][1] - points[0][1]
                                },
                                style: {
                                    ..._areaStyle,
                                    ..._labelStyle
                                },
                                key: i
                            })
                        );
                    }
                } else if (item.type) {
                    let seriesModel = globalModel.getComponentByIndex(
                        'series',
                        model.get('$seriesIndex')
                    );

                    if (seriesModel && seriesModel.get('selected') !== false) {
                        let xAxisModel = (seriesModel || model).getAxisModel(
                            'x'
                        );
                        let yAxisModel = model.getAxisModel('y');
                        let extData = seriesModel.getDataExtent(item.type);
                        let shape;

                        if (item.type === 'average') {
                            shape = {
                                x: xAxisModel.range[0],
                                y: yAxisModel.scale(extData),
                                width: xAxisModel.range[1] - xAxisModel.range[0],
                                height: yAxisModel.scale(extData) - yAxisModel.scale(extData)
                            };
                        } else {
                            shape = {
                                x1: xAxisModel.range[0],
                                y1: yAxisModel.scale(extData[0][1]),
                                x2: xAxisModel.range[1],
                                y2: yAxisModel.scale(extData[0][1])
                            };
                        }

                        attrs.push(
                            subPixelOptimizeRect({
                                cursor: model.get('cursor'),
                                shape: shape,
                                style: {
                                    ..._areaStyle,
                                    ..._labelStyle
                                },
                                key: i
                            })
                        );
                    }
                }
            });

            this.setShapeGroup('markAreas', Rect, attrs, {
                animation,
                animateList: {
                    shape: {
                        x: 0,
                        y: 0,
                        width: 0,
                        height: 0
                    }
                }
            });
        } else {
            this.removeShape('markAreas');
        }
    }

}
