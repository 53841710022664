/**
 * 自定义Model模板
 * by wjw
 */
import SeriesModel from '../../model/Series';
import { each } from '../../util';

export const TYPE = 'custom';

export default class CustomModel extends SeriesModel {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    static defaultOption = {
        zlevel: 0, // canvas层级
        z: 1, // 同一层canvas内层级

        view: {
        },

        model: {
        }
    }

    constructor() {
        super(...arguments);

        let modelFunc = this.get('model');

        each(modelFunc, (func, name) => {
            this[name] = func;
        });
    }

}
