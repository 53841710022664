import { parsePercent } from '../../util';

export default function setModelPosition(model) {
    let { _option: { left, right, top, bottom, width, height }, global } = model;
    let globalWidth = global.getWidth();
    let globalHeight = global.getHeight();
    let position = {};

    if (typeof width !== 'undefined') {
        position.width = parsePercent(width, globalWidth);

        if (typeof left !== 'undefined') {
            position.left = parsePercent(left, globalWidth);
            position.right = position.left + position.width;
        } else {
            position.right = globalWidth - parsePercent(right, globalWidth);
            position.left = position.right - position.width;
        }
    } else {
        position.left = parsePercent(left, globalWidth);
        position.right = globalWidth - parsePercent(right, globalWidth);
        position.width = position.right - position.left;
    }

    if (typeof height !== 'undefined') {
        position.height = parsePercent(height, globalHeight);

        if (typeof bottom !== 'undefined') {
            position.bottom = globalHeight - parsePercent(bottom, globalHeight);
            position.top = position.bottom - position.height;
        } else {
            position.top = parsePercent(top, globalHeight);
            position.bottom = position.top + position.height;
        }
    } else {
        position.top = parsePercent(top, globalHeight);
        if (model.get('topPlaceHolder').show) position.top += model.get('topPlaceHolder').height;
        position.bottom = globalHeight - parsePercent(bottom, globalHeight);
        if (model.get('bottomPlaceHolder').show) position.bottom -= model.get('bottomPlaceHolder').height;
        position.height = position.bottom - position.top;
    }

    return model.position = position;
}