import ComponentView from '../../view/Component';
import { TYPE } from './RadarAxisModel';
import Line from 'zrender/src/graphic/shape/Line';
import Circle from 'zrender/src/graphic/shape/Circle';
import Polygon from 'zrender/src/graphic/shape/Polygon';
import Text from 'zrender/src/graphic/Text';

import { radarAxisActions } from '../../action/event';


export default class RadarAxisView extends ComponentView {

    static type = TYPE;

    type = TYPE;

    constructor(model, globalModel, global) {
        super(...arguments);

        this._initEvent(model, globalModel, global);
    }

    _initEvent(model, globalModel, global) {

        let axisType = model.getAxisType();
        let lastIndex,
            lastSeries = [],
            isMouseout = true;

        // let axisIndex = model.get('$radarAxisIndex');
        // let axisModel = globalModel.getComponentByIndex('radarAxis', 0);

        function onOut() {
            if (isMouseout) return;

            if (axisType === 'polar') {
                global.dispatchAction(model, radarAxisActions.out, {});
            }

            isMouseout = true;
        }

        // global.registerAction(axisModel, 'LABEL_CLICK', (e) => {
        //     console.log('e', e)
        //     let { offsetX, offsetY } = e;
        //     if (axisType === 'polar') {

        //         var angle = model.angleScaleInvert(offsetX, offsetY)
        //         var pos = Math.round(model.angleScale.invert(angle));
        //         pos = model.get('indicator').length - pos;
        //         if(pos >= model.get('indicator').length) pos = 0

        //         global.dispatchAction(model, radarAxisActions.move, {
        //             e,
        //             axisIndex: pos,
        //             indicator: model.get('indicator')[pos],
        //             axis: {
        //                 data: model.get('indicator')[pos].name,
        //                 axisIndex: pos
        //             },
        //             series: model.getDataByIndex(
        //                 pos
        //             ),
        //             allSeries: model.getDataByIndex(
        //                 pos,
        //                 false
        //             )
        //         });
        //     }
        // })

        global.on('mousemove', function (e) {
            if (global.isEnableEdit || global.isEnableSketch) return;

            let { offsetX, offsetY } = e;
            let inGrid = false;

            if (model.isPointIn(offsetX, offsetY)) {
                inGrid = true;
            }

            if (inGrid) {
                if (axisType === 'polar') {

                    var angle = model.angleScaleInvert(offsetX, offsetY)
                    var pos = Math.round(model.angleScale.invert(angle));
                    pos = model.get('indicator').length - pos;
                    if(pos >= model.get('indicator').length) pos = 0

                    global.dispatchAction(model, radarAxisActions.move, {
                        e,
                        axisIndex: pos,
                        indicator: model.get('indicator')[pos],
                        axis: {
                            data: model.get('indicator')[pos].name,
                            axisIndex: pos
                        },
                        series: model.getDataByIndex(
                            pos
                        ),
                        allSeries: model.getDataByIndex(
                            pos,
                            false
                        )
                    });
                }

                isMouseout = false;
            } else if (!isMouseout) {
                onOut();
            }
        });

        global.on('globalout', onOut);
        global.on('mouseup', onOut);
        // global.on('mouseout', onOut);

    }

    render(model, globalModel, global) {
        this.remove();

        const shape = model.get('shape');
        let radius = model.radius;
        let cx = model.center[0];
        let cy = model.center[1];
        const indicator = model.get('indicator');
        const splitNumber = model.get('splitNumber');
        const splitLineStyle = model.get('splitLine').lineStyle;
        const splitAreaStyle = model.get('splitArea').areaStyle;

        const angleScale = model.angleScale;
        const axisLineStyle = model.get('axisLine').lineStyle;
        const nameTextStyle = model.get('name').textStyle;
        const nameFormatter = model.get('name').formatter;
        const positionOffset = model.get('name').positionOffset;

        const cos = Math.cos;
        const sin = Math.sin;


        let indicatorNum = indicator.length;

        var r = radius/splitNumber;

        for (var j = splitNumber - 1; j >= 0; j--) {
            var pointArr = [];
            for (var i = 0; i < indicatorNum; i++) {
                var x = r*(j+1) * cos(angleScale(i));
                var y = r*(j+1) * sin(angleScale(i));

                pointArr.push([x, y]);
                if (j == (splitNumber - 1)) {
                    let textAlign = x.toFixed(6) == 0 ? 'center' : (x.toFixed(6)< 0 ? 'right': 'left');
                    let text = typeof nameFormatter === 'function' ? nameFormatter(indicator[i], i): indicator[i].name;

                    // 多边形文字
                    let itemText = new Text({
                        z2: 2,
                        cursor: 'default',
                        style: {
                          text,
                          x: x*positionOffset,
                          y: y*positionOffset,
                          ...nameTextStyle,
                          textAlign: textAlign
                        },
                        data: text,
                        position: [cx, cy],
                        axisIndex: i
                    });

                    itemText.on('click', function(e) {
                        global.dispatchAction(model, 'LABEL_CLICK', {
                            shape: e.target,
                            data: e.target.data,
                            axisIndex: this.axisIndex
                        });
                    });

                    // itemText.on('mousemove', function(e) {
                    //     global.dispatchAction(model, 'LABEL_MOVE', {
                    //         shape: e.target,
                    //         data: e.target.data
                    //     });
                    // });

                    this.group.add(itemText);
                    // 坐标轴线
                    let line = new Line({
                        z2: 2,
                        shape: {
                            x1: 0,
                            y1: 0,
                            x2: x,
                            y2: y
                        },
                        position: [cx, cy],
                        style: {
                            stroke: axisLineStyle.color,
                            fill: null
                        }
                    })

                    this.group.add(line);
                }

            }

            // 多边形
            if (shape == 'polygon') {
                // 层叠背景多边形
                let outerPolygon = new Polygon({
                    z2: 1,
                    cursor: 'default',
                    shape: {
                        points: pointArr
                    },
                    position: [cx, cy],
                    style:{
                        stroke: splitLineStyle.color[j%2],
                        fill: splitAreaStyle.color[j%2],
                        lineDash: (j === splitNumber - 1) ? [0, 0] : splitLineStyle.lineDash,
                    }
                });
                this.group.add(outerPolygon);
            // 层叠背景 圆形
            }else if(shape == 'circle'){
                let outerPie = new Circle({
                    cursor: 'default',
                    z2: 1,
                    shape: {
                        r: r*(j+1)
                    },
                    position: [cx, cy],
                    style:{
                        stroke: splitLineStyle.color[j%2],
                        fill: splitAreaStyle.color[j%2]
                    }
                });
                this.group.add(outerPie);
            }
        }

    }

}
