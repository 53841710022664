import {
    default as DataProvider,
    getLastestTime
} from '../DataProvider';
import { getStockType } from '../../util/hqHelper'
import { each, isArray } from 'zrender/src/core/util'

function checkHqData(data){
    return data.dataArray && data.dataArray.length && data.dataArray[0].nowp && data.preClosePrice
}
// var test = {
//     sj_mAUTD: {
//         name: "mAu(T+D)",
//         open: 1,
//         stop: 0,
//         rt: "2000-0230,0900-1130,1330-1530",
//         tradeTime: [
//             "2000-0230",
//             "0900-1130",
//             "1330-1530"
//         ],
//         pre: "273.17",
//         date: "20181127",
//         data: "",
//         dotsCount: 663,
//         dates: [
//             "20181127",
//             "20181128"
//         ]
//     }
// }

/**
 * 数据处理-当天分时
 * 包含以下行情逻辑
 * 1. 先请求全量数据，再请求增量数据
 * 2. 可开启或关闭实时更新
 * 继承 DataProvider
 * 需要把整理的数据用 this.fetchSuccess(data) 通知出去
 * !!不可以直接对this._data 进行赋值
 *
 * @extends DataProvider
 */
export default class FsToday extends DataProvider{

    static type = 'fsToday'

    /**
     * 默认配置
     *
     * ```
     code:'hs_300033',
     // 获取方式
     fetchType: 'jqXhrWithoutCallback',
     // 接口版本
     version: 'v6',
     // 类型再细分
     dType: undefined,
     // 是否持续更新
     isKeepingGet: true,
     urlFormatter: function({version, protocol, code}) {
         return protocol+'//d.10jqka.com.cn/'+version+'/time/'+code+'/last.js';
     },
     keepGetUrlFormatter: function({version, protocol, code, todayStart}) {
         return protocol+'//d.10jqka.com.cn/'+version+'/time/'+code+'/'+todayStart+'.js';
     },
     intervalTime: 60000
     * ```
     * @defalut
     */
    static defaultOption = {
        code:'hs_300033',
        // 全量地址
        urlFormatter: function({version, protocol, code}) {
            return protocol+'//d.10jqka.com.cn/'+version+'/time/'+code+'/last.js';
        },
        // 增量地址
        keepGetUrlFormatter: function({version, protocol, code, todayStart}) {
            return protocol+'//d.10jqka.com.cn/'+version+'/time/'+code+'/'+todayStart+'.js';
        }
    }

    constructor(){
        super(...arguments);

        // 当前是否实时更新
        // 根据行情数据返回的情况，程序会自行调整是否需要实时更新,
        this.keepingGetHandle;
        // 数据的值异常
        this.dataError = false
    }

    // 子类实现
    // 全量数据整理
    arrangeData (originalD, historyD) {
        var r = {};
        var codeArr = this.get('code').split(',');
        each(codeArr, (code)=>{
            r[code] = {};
            var hasHistoryD = !!(historyD && historyD[code]);
            let historyArr = hasHistoryD ? historyD[code].dataArray : '';
            let preClosePrice = hasHistoryD ? historyD[code].preClosePrice : undefined;
            let historySingleD = hasHistoryD && historyD[code];
            let d = originalD[code];
            let stockType = getStockType(code);
            stockType.marketType = d.marketType
            let { dates: formatterDate } = d;
            // 如果全量数据有pre字段，就用全量字段里的
            if (!preClosePrice) preClosePrice = d.pre;

            let datesLength = formatterDate.length;
            let data = d.data.split(';');
            let dataArray = historyArr ? historyArr.slice(0):[];
            let tempArr = [], temp, todayStart = hasHistoryD ? historySingleD.todayStart : '';

            // 0930,65.02,12619793,65.354,193100
            var formatData = function(){
                var dates;
                return function(d1, beforedate, afterdate) {
                    if (!dates) { dates = beforedate; }
                    if (d1[0] == '0000') {
                        dates = afterdate;
                    }

                    let nowp = parseFloat(d1[1]?d1[1]:0);
                    let av = parseFloat(d1[3]?d1[3]:d1[1]);
                    let nowpPct = (nowp - preClosePrice) / preClosePrice;
                    let avPct = (av - preClosePrice) / preClosePrice;

                    return {
                        s: undefined,
                        't': dates + ' ' + d1[0],
                        // 现价
                        nowp,
                        // 成交额
                        'np': parseInt(d1[2]?d1[2]:0),
                        // 均价
                        av,
                        // 成交量
                        'n': parseInt(d1[4]?d1[4]:0),
                        // 现价百分比
                        nowpPct,
                        // 均价百分比
                        avPct
                    };
                }
            }();

            // 如果昨收价格和 分时第一个点的均价都不存在，判断为未上市的股票
            // if (!preClosePrice && (!data[0].split(',')[2] || (data[0].split(',')[2] == 0)) ) {
            // eslint-disable-next-line no-empty
            if ((!preClosePrice && (!data[0].split(',')[2] || (data[0].split(',')[2] == 0)))|| !data[0].split(',')[1] || data[0].split(',')[1]==0 ) {

            }else{

                var nowpMax = hasHistoryD ? historySingleD.max.nowp : -Infinity;
                var nowpMin = hasHistoryD ? historySingleD.min.nowp : Infinity;
                var avMax = hasHistoryD ? historySingleD.max.av : -Infinity;
                var avMin = hasHistoryD ? historySingleD.min.av : Infinity;
                var nMax = hasHistoryD ? historySingleD.max.n : -Infinity;
                var nMin = hasHistoryD ? historySingleD.min.n : Infinity;

                if (data.length && data[0]) {
                    for (var i = 0; i < data.length; i++) {
                        tempArr = data[i].split(',');
                        temp = formatData(tempArr, formatterDate[0], formatterDate[datesLength-1]);

                        // 计算每个点对应 成交量的颜色
                        let lastObj = dataArray[dataArray.length-1];
                        if (i == 0) {
                            temp.s = (temp.nowp - preClosePrice) > 0 ? 'ab' : ((temp.nowp - preClosePrice) < 0 ? 'be' : 'eq');
                        } else {
                            temp.s = (temp.nowp - lastObj.nowp) > 0 ? 'ab' : ((temp.nowp - lastObj.nowp) < 0 ? 'be' : 'eq');
                        }

                        nowpMax = Math.max(temp.nowp, nowpMax);
                        nowpMin = Math.min(temp.nowp, nowpMin);

                        avMax = Math.max(temp.av, avMax);
                        avMin = Math.min(temp.av, avMin);

                        nMax = Math.max(temp.n, nMax);
                        nMin = Math.min(temp.n, nMin);

                        if (tempArr[1] && dataArray.length && dataArray[dataArray.length-1].t && tempArr[0] == todayStart) {
                            dataArray.pop();
                        }

                        dataArray.push(temp);

                    }

                    formatData = null;

                    todayStart = dataArray[dataArray.length-1].t.split(' ')[1];
                }
            }

            r[code] = {
                stockType,
                afterTradeTime: d.afterTradeTime,
                // 交易时间 : ["0930-1130", "1300-1500"]
                tradeTime: d.tradeTime,
                isTrading: d.isTrading,
                // 股票代码
                code,
                // 各地时间换算成北京时间，跨天展示问题
                formatterDate,
                // 昨收价格
                preClosePrice,
                // 分时一天交易结束总共点数
                totalTimeNum: d.dotsCount,
                // 股票名称
                name:d.name,
                // 通过最大值最小值的分析，由此可得出 数据状态
                max: {
                    'nowp': nowpMax,
                    'av': avMax,
                    'n': nMax
                },
                min: {
                    'nowp': nowpMin,
                    'av': avMin,
                    'n': nMin
                },
                // 是否停盘
                stop: d.stop,
                // 当天是否开市日(双休日不开市)
                open: d.open,
                // 更新到的最后时刻
                todayStart,
                // 整理后的数据
                dataArray: dataArray.length ? dataArray : []
            };

            if(!checkHqData(r[code])){ this.dataError = true; }
        })

        return r;
    }

    /**
     * 初始化
     */
    init(){
        var that = this;
        // let fetchType = this.get('fetchType');
        let req1Url = this.get('urlFormatter')({
            ...this.option
        });
        let { url, callbackName } = this.getHqCallbackNameAndUrl(req1Url);

        this.fetchRemote(url, {
                jsonpCallbackFunction: callbackName
            })
            .then(function(d) {
                // d = test;
                var r = that.arrangeData(d);
                if (that.dataError) {
                    that.fetchFail({message:'数据正常返回，内容异常'}, r)
                    // 不再执行下面的更新操作
                    return false;
                }else{
                    that.fetchSuccess(r);
                }

                // 是否需要实时更新 未上市:不需要实时更新
                if(!that.checkNeedGetting()){
                    that.keepingGetHandle && clearTimeout(that.keepingGetHandle);
                    // 不再执行下面的更新操作
                    return false;
                }

                // 持续获取
                that.keepingGetHandle = setTimeout(function repeatFn(){
                    that.keepGettingData();
                    that.keepingGetHandle = setTimeout(repeatFn, that.get('intervalTime'));
                }, that.get('intervalTime'));
            }).catch(function(ex) {
                that.fetchFail(ex);
            })
    }

    /**
     * 校验是否在交易时段
     * 需要在获取data以后调用才有效
     * @return {boolean}
     */
    checkIsTradingTime(checkTime){
        let code = this.get('code').split(',');
        let d = this.getData();
        try {
            // 如果是多个code, 只取第一个code 作是否需要更新的计算
            if(isArray(code)) code = code[0];
            let data = d[code];

            if (!data) { return false }

            return !!((data.isTrading && data.open));
        } catch (e) {
            return false;
        }

    }

    // 子类实现
    // 校验是否实时更新
    checkNeedGetting() {
        if (this.dataError) { return false; }

        let code = this.get('code').split(',');
        let d = this.getData();
        // 如果是多个code, 只取第一个code 作是否需要更新的计算
        if(isArray(code)) code = code[0];
        let data = d[code];

        let isKeepingGet = this.get('isKeepingGet');

        let lastestTime = getLastestTime(data);

        let start1, end1, start2, end2, r = false;

        for (let i = 0; i < data.tradeTime.length; i++) {
            start1 = data.tradeTime[i].split('-')[0];
            end1 = data.tradeTime[i].split('-')[1];
            if (end1 < start1) {
                end1 = '2400';
                start2 = '0000';
                end2 = data.tradeTime[i].split('-')[1];
            }

            if (!start2 && lastestTime >= start1 && lastestTime < end1 ) {
                r = isKeepingGet;
            }else if(start2
                && ((lastestTime >= start1 && lastestTime < end1)
                || (lastestTime >= start2 && lastestTime < end2))
                ){
                r = isKeepingGet;
            }
        }

        return r;
    }

    // 更新增量数据
    keepGettingData (){
        let that = this;
        // let fetchType = this.get('fetchType');

        let code = this.get('code').split(',');
        let d = this.getData();
        // 如果是多个code, 只取第一个code 作是否需要更新的计算
        if(isArray(code)) code = code[0];
        let data = d[code];

        let urlFormatterCbk = this.get('keepGetUrlFormatter');
        // todayStart 用于拼接增量数据的url
        let keepGettingUrl = urlFormatterCbk({
                                todayStart: data.todayStart,
                                ...this.option
                            });

        let { url, callbackName } = this.getHqCallbackNameAndUrl(keepGettingUrl);

        this.fetchRemote(url, {
                jsonpCallbackFunction: callbackName
            })
            .then(function(dd) {
                let res = that.arrangeData(dd, that.getData());
                that.fetchSuccess(res);
            }).catch(function(ex) {
                that.fetchFail(ex);
            });
    }
}
