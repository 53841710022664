import Arc from '../../shape/D3Arc';
import Pie from 'zrender/src/graphic/shape/Circle';
import Text from 'zrender/src/graphic/Text';
import ChartView from '../../view/Chart';
import extent from 'd3-array/src/extent';
import d3color from 'd3-color/src/color';

const TYPE = 'arcbar';

export default class ArcView extends ChartView {

    static type = TYPE;

    type = TYPE;

    render(model, globalModel, global) {
        this.remove();
        let PI = Math.PI;
        let PI2 = PI * 2;
        let group = this.group;
        let data = model.getData();
        let z = model.get('z');
        let zlevel = model.get('zlevel');
        let arcStyle = model.get('itemStyle');
        let circleStyle = model.get('circleStyle');
        let hoverStyle = model.get('hoverStyle');
        let colors = model.get('colors');

        let arcCenter = model.get('center');
        let arcRadius = model.get('radius');

        let canvasW = global.getWidth();
        let canvasH = global.getHeight();

        let minDiff = Math.min(canvasW, canvasH);

        let cx = typeof arcCenter[0] === 'string' ? parseInt(parseFloat(arcCenter[0].split('%')[0])/100*canvasW) : parseInt(arcCenter[0]);
        let cy = typeof arcCenter[1] === 'string' ? parseInt(parseFloat(arcCenter[1].split('%')[0])/100*canvasH) : parseInt(arcCenter[1]);

        let itemRadius = arcStyle.itemRadius || 6;
        let outerRadius = typeof arcRadius === 'string' ? parseInt(parseFloat(arcRadius.split('%')[0])/100*minDiff) / 2 : parseInt(arcRadius);
        let innerRadius = outerRadius - itemRadius + 1;

        let edgePadding = circleStyle.edgePadding || 2;

        let itemFormatter = hoverStyle.formatter;

        let textPan;

        let arcList = [];

        let values = data.values;

        let total = 0;


        function angleGradient(angle, r) {
            let x, y, x2, y2;
            if (angle < PI / 2 && angle >= 0) {
                x = y = 0;
                x2 = y2 = 1;
            } else if (angle >= PI / 2 && angle < PI) {
                x = y = 0;
                x2 = Math.cos(PI + angle) / 2;
                y2 = 1;
            } else if (angle >= PI && angle < PI * 1.5) {
                x = Math.sin(angle - PI) / (1 + Math.sin(angle - PI));
                y = 0;
                x2 = 0;
                y2 = (Math.cos(angle - PI) + 1) / 2;
            } else if (angle >= PI * 1.5 && angle < PI2){
                x = .5;
                y = 0;
                x2 = (1 - Math.sin(PI2 - angle)) / 2;
                y2 = (1 - Math.cos(PI2 - angle)) / 2;
            }
            return {x, y, x2, y2}

        }

        function drawBG() {
            let circleStroke = circleStyle.borderColor;
            let midRadius = (outerRadius + innerRadius) / 2

            let arc = new Arc({
                shape: {
                    outerRadius: midRadius + .5,
                    innerRadius: midRadius - .5,
                    endAngle: 0,
                    startAngle: PI2,
                    cornerRadius: 4
                },
                position: [cx, cy],
                style: {
                    fill: circleStroke
                }

            })
            this.group.add(arc);
        }
        drawBG.call(this);

        function drawShadow(item) {

            let {endAngle} = item;
            let arc = new Arc({
                shape: {
                    outerRadius: outerRadius,
                    innerRadius: 0,
                    endAngle: 0,
                    startAngle: 0,
                    cornerRadius: 0
                },
                position: [cx, cy],
                style: {
                    fill: circleStyle.shadowColor
                }
            })

            arc.animateTo({
                shape: {
                    endAngle: endAngle
                }
            }, 500, 'linear')
            this.group.add(arc);
        }

        function drawItem(item, idx) {

            let {endAngle, startAngle, strokeColor, itemValue, itemName} = item;
            let anglePos = angleGradient(endAngle, outerRadius - (outerRadius - innerRadius) / 2);
            let colorObj = values[idx].color || circleStyle.color || ['#2bbdb9', '#2bbdb9'];
            if (typeof colorObj !== 'object' || colorObj.length <= 1) {
                colorObj = [colorObj, d3color(colorObj).brighter()];
            }
            let arc = new Arc({
                shape: {
                    outerRadius: outerRadius,
                    innerRadius: innerRadius,
                    endAngle: 0,
                    startAngle: 0,
                    cornerRadius: 4
                },
                position: [cx, cy],
                style: {
                    fill: {
                        type: 'linear',
                        x: anglePos.x,
                        y: anglePos.y,
                        x2: anglePos.x2,
                        y2: anglePos.y2,
                        colorStops: [{
                            offset: 0, color:  colorObj[0] // 0% 处的颜色
                        }, {
                            offset: 1, color:  colorObj[1] // 100% 处的颜色
                        }],
                        globalCoord: false // 缺省为 false
                    },
                    stroke: null
                }
            })
            let timer;
            function onEmphasis() {
                clearTimeout(timer);
                timer = setTimeout(function() {
                    textPan && textPan.setStyle('text', itemFormatter(itemValue))
                    arc.stopAnimation(true);
                    arc.animateTo({
                        shape: {
                            r: outerRadius + 8
                        }
                    }, 300, 'elasticOut');
                }, 50)
            }

            function onNormal() {
                clearTimeout(timer);
                arc.stopAnimation(true);
                timer = setTimeout(function() {
                    arc.animateTo({
                        shape: {
                            r: outerRadius
                        }
                    }, 300, 'elasticOut');
                }, 50)

            }
            arc.on('mouseover', onEmphasis);
            arc.on('mouseout', onNormal)

            arc.animateTo({
                shape: {
                    endAngle: endAngle
                }
            }, 500, 'linear')
            this.group.add(arc);
            let tooltipFormatter = globalModel.get('tooltip') && globalModel.get('tooltip').formatter;
            if (!tooltipFormatter) {
                tooltipFormatter = function(text, value) {
                    return `${text}: ${(value * 100).toFixed(0)}%`;
                }
            }
            arc.data = {value: tooltipFormatter(itemName, itemValue)};
            return arc;
        }

        function drawText(itemValue) {
            if (hoverStyle.show === false) {
                return;
            }

            textPan = new Text({
                style: {
                    text: itemFormatter(itemValue),
                    x: cx,
                    y: cy * .97,
                    font: hoverStyle.textStyle.fontWeight +' '+hoverStyle.textStyle.fontSize+'px '+hoverStyle.textStyle.fontFamily,
                    textBaseline: 'middle',
                    textAlign: 'center',
                    textFill: hoverStyle.textStyle.color
                }
            });

            this.group.add(textPan);
            return textPan;
        }

        if (values.length > 1) {

            let l = values.length;
            let startAngle = 0;
            let firstItemValue;
            total = extent(values, function(d) {
                return d.value
            })[1]
            for (let i = 0, item; i < l; i++) {
                item = values[i];
                let itemValue = Math.abs(item.value / total);
                let endAngle = itemValue * circleStyle.maxAngle / 180 * PI || itemValue * 3 / 4 * (2*PI) || PI;
                let strokeColor = item.color;
                let itemName = item.name;
                let itemOpt = {itemValue, startAngle, endAngle, strokeColor, itemName};
                if (i == 0) {
                    firstItemValue = itemValue
                }

                drawItem.call(this, itemOpt, i);
                innerRadius -= itemRadius;
                outerRadius -= itemRadius;

                //startAngle += endAngle;
            }
            drawText.call(this, firstItemValue)
        } else {
            let itemValue = Math.abs(data.values[0].value / (data.max - data.min));
            let strokeColor = data.values[0].color;
            let itemName = data.values[0].name;
            let endAngle = itemValue * circleStyle.maxAngle / 180 * PI || itemValue * (2*Math.PI) || 0;
            if (circleStyle.shadowColor) {
                drawShadow.call(this, {endAngle});
            }
            drawItem.call(this, {endAngle, itemValue, strokeColor, startAngle: -PI / 2, itemName}, 0);
            drawText.call(this, itemValue)
        }

        for (let i = 0; i < arcList.length; i++) {


            this.group.add(arc);
        }
        /*arc.animateTo({
            shape: {
                endAngle: endAngle
            }

        }, 800, 'linear');*/


        if (data.title) {
            let titleConfigs = model.get('title');
            let titleOffsetX = typeof titleConfigs.offsetCenter[0] === 'string' ? parseFloat(titleConfigs.offsetCenter[0].split('%')[0])/100*canvasW : titleConfigs.offsetCenter[0];
            let titleOffsetY = typeof titleConfigs.offsetCenter[1] === 'string' ? parseFloat(titleConfigs.offsetCenter[1].split('%')[0])/100*canvasH : titleConfigs.offsetCenter[1];
            
            let nameText = new Text({
                style: {
                  text: data.title,
                  x: cx - titleOffsetX,
                  y: cy - titleOffsetY,
                  font: titleConfigs.textStyle.fontWeight+' '+titleConfigs.textStyle.fontSize + 'px ' + titleConfigs.textStyle.fontFamily,
                  textBaseline: 'middle',
                  textAlign: 'center',
                  textFill: titleConfigs.textStyle.color
                }
            });
            
            this.group.add(nameText);
        }
    }
}
