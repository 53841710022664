import View from './View';

const TYPE = 'component';

/**
 * 组件View类
 * 
 * @class ComponentView
 * @extends {View}
 */
class ComponentView extends View {

    static type = TYPE;
    
    constructor() {
        super(...arguments);
        this.type = this.constructor.type;
    }
}

export default ComponentView;