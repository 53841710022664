import { merge, isFunction, each } from '../../util';

function setLabel(style = {}, { style: labelStyle, show, formatter }, item, globalModel, model) {
    if (show) {
        let { data } = item;
        let value = data[1];
        let param = getParams(item, model);
        let _labelStyle = (isFunction(labelStyle) ? labelStyle(value, param) : labelStyle) || {};

        return merge(globalModel.getFormattedText(formatXXXStyle({
            ..._labelStyle,
            text: formatter ? formatter(value, param) : value
        }, value, param)), style);
    } else {
        return style;
    }
}

function getParams(item, model) {
    return {
        ...item,
        seriesIndex: model.index,
        seriesName: model.name
    }
}

function formatXXXStyle(style, value, item) {
    let _style = {};

    each(style, function (v, k) {
        if (isFunction(v)) {
            _style[k] = v(value, item);
        } else {
            _style[k] = v;
        }
    });

    return _style;
}

export {
    setLabel
}