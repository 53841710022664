import { isObject, isArray, each, enableClassManagement, merge } from '../util';
import ShapeStorage from '../util/ShapeStorage';
import { translate, scale } from 'zrender/src/core/matrix';
import { viewActions } from '../action/event.js';

const TYPE = 'view';

/**
 * View基类
 * 
 * @class View
 * @extends {ShapeStorage}
 */
class View extends ShapeStorage {

  static type = TYPE;

  type = TYPE;

  _lastUpdateTag = undefined; // 上次更新的tag，避免不必要的渲染

  constructor(model, globalModel, global) {
    super(...arguments);

    this.setRoam(model, globalModel, global);
  }

  zoomable(model, globalModel, global) {
    let roam = model.get('roam') || {};
    let isRoam = !roam.enable;

    model.set({
      roam: {
        enable: isRoam
      }
    });
  }

  /**
   * 设置roam
   * 
   * @param {Object} model 
   * @param {Object} globalModel
   * @param {Object} global 
   * @private
   */
  setRoam(model, globalModel, global) {
    let roam = model.get('roam') || {};
    let globalRoam = globalModel.get('roam');
    let preventDefaultMouseWheel = globalModel.get('preventDefaultMouseWheel')
    merge(roam, globalRoam);

    let gridModel;

    if (roam.inGrid) {
      let gridIndex = model.get('$gridIndex');

      gridModel = globalModel.getComponentByIndex('grid', gridIndex);
    }

    global.on('mousewheel', ({event, offsetX, offsetY, wheelDelta }) => {
      if (!roam.inGrid && (!gridModel || gridModel.isPointInGrid(offsetX, offsetY)) && roam.enable) {
        this.scale(offsetX, offsetY, wheelDelta, roam);
      }
      preventDefaultMouseWheel && event.preventDefault && event.preventDefault()
    });

    global.on('pinch', (e) => {
      let { offsetX, offsetY, pinchX, pinchY } = e;
      if ((!gridModel || gridModel.isPointInGrid(pinchX, pinchY)) && roam.enable) {
        this.scale(pinchX, pinchY, e.pinchScale - 1, roam);
      }
    });

    this._roaming = false;
    let startX, startY, startPosition;

    global.on('mousedown', ({ offsetX, offsetY, target }) => {
      if ((!gridModel || gridModel.isPointInGrid(offsetX, offsetY)) && roam.enable) {
        let group = this.group;

        if (!group || (!roam.onTarget && target)) return;

        this._roaming = true;
        startX = offsetX;
        startY = offsetY;
        startPosition = [group.position[0], group.position[1]];
      }
    });

    global.on('mousemove', ({ offsetX, offsetY }) => {
      if (this._roaming) {
        let group = this.group;
        let x = 0;
        let y = 0;
        if (roam.horiDrag) {
          x = offsetX - startX;
        }
        if (roam.vertiDrag) {
          y = offsetY - startY;
        }
        if (roam.inGrid) {
          let boundingRect = group.getBoundingRect()
          //let scale = this.group.getGlobalScale()
          //let bx = boundingRect.x * scale[0]
          //let by = boundingRect.y * scale[1]
          //let bh = boundingRect.height * scale[1]
          //let bw = boundingRect.width * scale[1]
          let entireWidth = global.getWidth()
          let entireHeight = global.getHeight()

          x = Math.max(-boundingRect.x, Math.min(entireWidth - boundingRect.width - boundingRect.x, startPosition[0] + x))
          y = Math.max(-boundingRect.y, Math.min(entireHeight - boundingRect.height - boundingRect.y, startPosition[1] + y))
          //console.log(group.position[1], y)
          //x = Math.max(-bx, Math.min(entireWidth - bw - bx, startPosition[0] + x))
          ////y = Math.max(-by, Math.min(entireHeight - boundingRect.height - by, startPosition[1] + y))
          //y += startPosition[1]
          //if (y > 0) {
            //y = 0
          //} else if (y < -(bh - entireHeight)) {
            //y = -(bh - entireHeight)
          //}
        } else {
          x += startPosition[0]
          y += startPosition[1]
        }
        group.attr({
          position: [x, y]
        });
      }
    });

    global.on('mouseup', (e) => {
      this._roaming = false;
    });

    this.roam = roam;
  }

  /**
   * 缩放view
   * 
   * @param {number} offsetX 缩放中线点X
   * @param {number} offsetY 缩放中心点Y
   * @param {number} wheelDelta 缩放比例
   */
  scale(offsetX, offsetY, wheelDelta, option = {}) {
    let group = this.group;
    if (!group) return;
    let scaleDelta = wheelDelta * 0.1 + 1;
    let transform = group.getLocalTransform();
    let scaleMax = option.scaleMax || Infinity;
    let scaleMin = option.scaleMin || -Infinity;

    if (transform[0] * scaleDelta <= scaleMax &&
      transform[3] * scaleDelta <= scaleMax &&
      transform[3] * scaleDelta >= scaleMin &&
      transform[3] * scaleDelta >= scaleMin) {
      translate(transform, transform, [-offsetX, -offsetY]);
      scale(transform, transform, [scaleDelta, scaleDelta]);
      translate(transform, transform, [offsetX, offsetY]);

      group.transform = transform;
      group.decomposeTransform();
      group.dirty();
    }
  }

  // 统一更新组件的z和zlevel
  updateZ(model) {
    var z = model.get('z');
    var zlevel = model.get('zlevel');

    this.group.traverse(function (el) {
      if (el.type !== 'group') {
        !isNaN(z) && (el.z = z);
        !isNaN(zlevel) && (el.zlevel = zlevel);
      }
    });
  }

  /**
   * 实际调用render方法，在子类的render方法进行一些包装
   * 
   * @param {Object} model 
   * @param {Object} globalModel
   * @param {Object} global 
   * @private
   */
  __render(model, globalModel, global) {


    // 与上次更新的tag比较 更新的model才需要更新对应的view
    if (this._lastUpdateTag !== model._updateTag) { 
      //console.log(`${model.type}_${model.index}视图更新了！`);

      // 创建了含有所有需要绘图(shape)的group
      this.render(model, globalModel, global);
      // 更新group里面 每个shape的z, zlevel
      this.updateZ(model);

      this._lastUpdateTag = model._updateTag;

      //  @todo delete enableEdit已废弃
      if (model.get('enableEdit')) {
        this.renderEdit(model, globalModel, global);
      } else {
        this.removeEdit();
      }

      global.dispatchAction(model, viewActions.didRender, { model: model, view: this });
    }
  }

  /**
   * 渲染函数
   * 
   * @param {Object} model 
   * @param {Object} globalModel
   * @param {Object} global 
   */
  render(model, globalModel, global) {
  }

  /**
   * 编辑状态渲染函数(canvas 可拖拽放大..)
   * 
   * @param {Object} model 
   * @param {Object} globalModel
   * @param {Object} global 
   * 
   */
  renderEdit(model, globalModel, global) {
  }

  /**
   * 移除edit状态相关图形
   * 
   * @param {Object} model 
   * @param {Object} globalModel
   * @param {Object} global 
   */
  removeEdit() {
    this.eachShape((shape, name) => {
      if (name.indexOf('_edit_') === 0) {
        shape.disableDrag && shape.disableDrag();
        this.removeShape(name);
      }
    });
  }

  /**
   * 移除view内所有图形
   */
  remove() {
    super.remove(...arguments);
  }
}

export default enableClassManagement(View);
