/**
 * 特效散点 by wjw
 */
import ScatterModel from '../scatter/ScatterModel';
import {
    each,
    map,
    isArray,
    parsePercent,
    setObjectKV,
    getObjectKV
} from '../../util';

export const TYPE = 'effectScatter';

export default class EffectScatterModel extends ScatterModel {
    static type = TYPE;

    type = TYPE;

    static defaultOption = {
        //data: undefined, // {Array} 系列中的数据内容数组。数组项通常为具体的数据项。数据项格式同echarts类似，http://echarts.baidu.com/option.html#series-scatter.data
        $dataIndex: undefined, // {number} 依赖的$data的index，格式同上，优先级低于data，取得的数据需符合data的格式
        dataKey: undefined, // {string|number} 搭配$dataIndex使用，方便从依赖的$data中取数据，等同于 data.map((d) => d[dataKey])，最终取得的数据需符合data的格式
        $axisIndex: [0, 1], // {Array} 所依赖axis的index，需包括一个x轴和y轴
        name: undefined, // {string} 系列名称，用于tooltip的显示，legend 的图例筛选

        effectType: 'ripple', // {string} 特效类型，目前只支持涟漪特效'ripple'。
        showEffectOn: 'render', // {string} 配置何时显示特效。'render' 绘制完成后显示特效 || 'emphasis' 高亮（hover）的时候显示特效
        rippleEffect: {
            period: 4000, // {number} 动画的周期，毫秒数。
            scale: 3, // {number} 动画中波纹的最大缩放比例。
            brushType: 'stroke', //{string} 波纹的绘制方式，可选 'stroke' 和 'fill'。
            number: 3 // {number} 波纹数量
        },

        // 拐点标记
        symbol: {
            // 普通状态
            normal: {
                show: true, // {boolean|string} 是否显示拐点标记， true 显示坐标轴对应点 | false 不显示 | 'all' 显示全部
                type: 'circle', // {string} symbol类型 'line' | 'rect' | 'roundRect' | 'square' | 'circle' | 'diamond' | 'pin' | 'arrow' | 'triangle'
                offset: [0, 0], // {Array} [x, y]，偏移位置
                size: 8, // {number|Function} symbol大小
                style: { // {Object|Function} 普通样式
                    fill: '#058499',
                    lineWidth: 0.5
                }
            },
            // 高亮状态
            emphasis: {
                show: false, // {boolean} 是否显示高亮显示
                style: {} // {Object|Function} 高亮样式
            }
        },

        // 拐点上方的文本
        label: {
            // 普通状态
            normal: {
                show: false, // {boolean|string} 是否显示拐点上方值， true 显示坐标轴对应点 | false 不显示 | 'all' 显示全部
                style: { // {Object|Function} 普通样式
                    position: 'top'
                },
                formatter: undefined // {Function} 文本格式化函数
            },
            emphasis: {
                show: false, // {boolean} 是否高亮显示
                style: {} // {Object|Function} 高亮样式
            }
        },

        cursor: 'default', // {string} 鼠标悬浮时在图形元素上时鼠标的样式是什么。同 CSS 的 cursor。
        zlevel: 0, // {number} 所有图形的 zlevel 值
        z: 4 // {number} 组件的所有图形的z值。控制图形的前后顺序。z值小的图形会被z值大的图形覆盖。
    };
}
