
import { BezierCurve } from '../../util/graphic';
import { each, merge, isFunction } from '../../util';
import ChartView from '../../view/Chart';
import { TYPE } from './LinesModel';
import EffectLine from './EffectLine';

export default class LinesView extends ChartView {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    render(model, globalModel, global) {
        this.remove();

        let zr = global.getZr();
        let isSvg = zr.painter.getType() === 'svg';
        let zlevel = model.get('zlevel');
        let effectOpt = model.get('effectSymbol');
        let endOpt = model.get('endSymbol');
        let { show, trailLength } = effectOpt;
        let lineOpt = model.get('line');
        let geoModel = global.getModel('geo', 0);
        let data = model.getData();
        let attrs = [];

        // 清除zlevel 
        if (!isSvg) {
            zr.painter.getLayer(zlevel).clear(true);
        }

        if (show && trailLength) {
            if (!isSvg) {
                // zlevel层canvas 模糊
                zr.configLayer(zlevel, {
                    motionBlur: true,
                    lastFrameAlpha: Math.max(Math.min(trailLength / 10 + 0.9, 1), 0)
                });
            }
        }

        // TODO: 暂时只支持geo
        each(data, (d, i) => {
            let coords = d.coords;
            let coord0 = geoModel.projection(coords[0]);
            let coord1 = geoModel.projection(coords[1]);

            attrs.push({
                coords: [coord0, coord1],
                line: lineOpt,
                effectSymbol: effectOpt,
                endSymbol: endOpt,
                model,
                key: i,
                index: i,
                data: d
            });
        });

        this.setShapeGroup('flylines', EffectLine, attrs);
    }
}