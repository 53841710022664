import { each, isFunction } from '../../../util';
import { subPixelOptimizeRect, Rect } from '../../../util/graphic';

export default function getBoxplotLayout({
    rectWidth, position, y0, points, data, model
}) {
    let itemStyle = model.get('itemStyle').normal;
    let attrs = [];
    let animateFroms = [];
    let attrsDroplet = [];

    let animateList = {
        shape: {
            x: 0,
            y: 0,
            width: 0,
            height: 0
        }
    }

    if (position === 'top' || position === 'bottom') {
        each(points, ([x, [y1, y2], d, i]) => {
            attrs.push(subPixelOptimizeRect({
                shape: {
                    x: x,
                    y: y2,
                    width: rectWidth,
                    height: y1 - y2
                },
                style: isFunction(itemStyle) ? itemStyle(data[i]) : itemStyle
            }));

            animateFroms.push({
                shape: {
                    height: 0
                }
            });
        });
    // eslint-disable-next-line no-empty
    } else {

    }

    return {
        attrs,
        animateFroms,
        animateList,
        Shape: Rect
    }
}
