/** 
 * 行情数据处理相关函数
 * 
 * @module util/hqHelper 
 */
import { isString, merge, each } from 'zrender/src/core/util'

import StockType from '../datapool/StockType';
export { getStockstatus } from '../datapool/stockstatus';

export function getStockType(code){
    return new StockType({ code:code }).init();
}

export function getDefaultColor (){
    return {
        abAxis: '#de422e',
        beAxis: '#3e9663',
        eqAxis: '#444',
        ma: '#444',
        axisLine: '#ccc',
        ma5: '#1F6195',
        ma10: '#E0AC58',
        ma30: '#9C73AF',
        // 悬停十字线
        hoverLine: '#A0A0A0',
        hideColor: 'rgba(255,255,255,0)'
    };
} 

var color = getDefaultColor();

// 行情图 根据市场 配置限制
export function getLimitByMarket(stockType){
    var market = stockType.msg;
    var f = {
        'fs': {
            av: true,
            vol: true,
            moneyflow: true
        },
        'kline':{
            vol: true
        }
    }

    // 分时均价线不显示
    if(/^(HK_index|Others_wh|Others_gzs|HS_block_hangye|HS_block_gainian|USA_index|Others_pmetal|Others_wh|Others_gzqh|Others_gqh|Others_qh|Others_zq|Others_sj|Others_ggqq)/.test(market)){
        f.fs.av = false;
    }

    // 分时资金流向 自动去除
    if(market !== 'HS_stock'){
        f.fs.moneyflow = false;
    }

    // 分时成交量面板自动去除
    if(/^(Others_wh|Others_gzs|Others_pmetal)/.test(market)){
        f.fs.vol = false;
    }

    // kline成交量面板自动去除
    if(/^(Others_wh|Others_zq|Others_pmetal|Others_gqh)/.test(market)){
        f.kline.vol = false;
    }

    return f;

}

export function getXData(stockType, totalTimeNum, tradeTime, ticks=false){
    var market = stockType.msg || 'HS_stock';
    var fsXaxis = stockType.fsXaxisShow;
    var startX = tradeTime[0].split('-')[0];
    var endX = tradeTime[tradeTime.length-1].split('-')[1];

    if (!fsXaxis) {
        if(/^(HS)/.test(market)) {
            fsXaxis = {
                '0930': 0,
                '1030': 60,
                '1130': 120,
                '1400': 180,
                '1500': 240
            }
        }else{
            fsXaxis = {};
            fsXaxis[startX] = 0;
            fsXaxis[endX] = totalTimeNum-1;
        }
    }

    if (!ticks) {
        ticks = [];
        if (/^(HS|HK)/.test(market)) {
            each(fsXaxis, function(item, i){ ticks.push(i) });
            ticks.sort();
        }else{
            ticks = [startX, endX];
        }
    }
    
    var xData = [];
    for (let i = 0; i < totalTimeNum; i++) {
        xData.push('___' + i);
    }
    var posArr = [];
    for (let i = 0; i < ticks.length; i++) {
        typeof fsXaxis[ticks[i]] !== 'undefined' && posArr.push(fsXaxis[ticks[i]])
    }
    for (let i = 0; i < posArr.length; i++) {
        xData[posArr[i]] = ticks[i];
    }
    return {
        xData: xData,
        xTick: ticks
    }
}

// 把常见的行情数据，转换为 易显示的内容
// 只可以用来展示，不可以用于再计算
export function convertToShowData(res, keepLen, needConvertoShou, filterNumberDiv = 100) {
// o : r['7'],
// a: r['8'],
// i : r['9'],
// c : r['10'],
// h : r['1968584'],
// n : r['13'],
// np : r['19'],
// yc : yesterdayP,
// preRateP: preRateP,
// preRate: preRate
    
    var openP, openPStatus, maxP, maxPStatus, minP, minPStatus, curP, curPStatus, n, np, turnRate, yesterdayP, rateP, rate;
    if (typeof res.o !== 'undefined') {
        openP = decimalRound(res.o, keepLen);
        openPStatus = filterStatus(res.o, res.yc);
    }

    if(typeof res.a !== 'undefined'){
        maxP = decimalRound(res.a, keepLen);
        maxPStatus = filterStatus(res.a, res.yc);
    }

    if(typeof res.i !== 'undefined'){
        minP = decimalRound(res.i, keepLen);
        minPStatus = filterStatus(res.i, res.yc);
    }

    if(typeof res.c !== 'undefined'){
        curP = decimalRound(res.c, keepLen);
        curPStatus = filterStatus(res.c, res.yc);
    }

    if(typeof res.n !== 'undefined') n = filterNum(res.n, needConvertoShou, filterNumberDiv);
    if(typeof res.np !== 'undefined') np = filterNum(res.np);
    if(typeof res.h !== 'undefined') turnRate = parseFloat(res.h).toFixed(2);
    yesterdayP = res.yc
    // 涨跌额
    if(typeof res.preRateP !== 'undefined') rateP = decimalRound(res.preRateP, keepLen);
    // 涨跌幅 展示时后面需要带％
    if(typeof res.preRate !== 'undefined') rate = decimalRound(res.preRate, 2);

    return {
        openP, openPStatus, maxP, maxPStatus, minP, minPStatus, curP, curPStatus, n, np, turnRate, yesterdayP, rateP, rate
    }

}

/**
 * 保留几位小数
 */
export function decimalRound(num,v) {
    if(!num){
        num = 0;
    }else if(isString(num)){
        num = parseFloat(num);
    }else if(isNaN(parseFloat(num))){
        var a = 0;
        return a.toFixed(v);
    }
    return num.toFixed(v);
}

/*
* 只过虑 成交额 和 成交量 字段
* 如果是成交量 先 股转为 手，然后去除小数点
 */
export function filterNum(value, needConvertoShou=false, div=100){
    if (!value && value != 0) {return ''}
    if(needConvertoShou) value = value / div;
    // 不进行向下取整
    // value = needConvertoShou ? Math.floor(value/100): Math.floor(value);
    let tempValue = Math.abs(value);
    if( tempValue > 1e12){
        value = decimalRound(value/1e12, 1)+'万亿';
    }else if( tempValue > 1e10){
        value = decimalRound(value/1e8, 0)+'亿';
    }else if( tempValue > 1e8){
        value = decimalRound(value/1e8, 1)+'亿';
    }else if( tempValue > 1e7){
        value = decimalRound(value/10000, 0)+'万';
    }else if( tempValue > 10000){
        value = decimalRound(value/10000, 1)+'万';
    }else{
        value = decimalRound(value, 0)
    }
    return value;
}

/**
 * 判断颜色
 * 使用 'ab' 'eq' 'be'表示 上涨 持平 下跌. 
 * ab: above  
 * be: below  
 * eq: equal
 * @param  {string|number} curPrise       当前价格
 * @param  {string|number} yesterdayPrice 昨收价格
 * @return {string}               颜色
 */
export function filterStatus(curPrise, yesterdayPrice) {
    var cur = parseFloat(curPrise);
    var last = parseFloat(yesterdayPrice);
    var str = '';
    if (cur - last > 0) {
        str = 'ab';
    }else if( cur - last < 0){
        str = 'be';
    }else{
        str = 'eq';
    }
    return str;
}

//红绿柱的状态识别符 
//如果 收盘价等于开盘价，则和前一天收盘价比(涨停或者跌停的情况)
//请使用 'ab' 'eq' 'be'表示 上涨 持平 下跌. 
//ab: above  
//be: below  
//eq: equal
export function getKLineStatus (cur, last) {
    var openingPrice = (cur.c === cur.o && last) ? last.c : cur.o;
    var closingPrice = cur.c;

    return filterStatus(closingPrice, openingPrice);
}

/**
 * 数字过滤
 * @param  {any} num  description
 * @param  {any} fix  description
 * @param  {any} fix2 已经用'万'等单位过滤后的精确度
 * @return {any}      description
 */
export function numFormatter(num, fix = 0, fix2 = 0) {

    let tempValue = Math.abs(num);
    if( tempValue > 1e12){
        return (num/1e12).toFixed(fix2) +'万亿';
    }else if (tempValue > 1e8 || tempValue < -1e8) {
        return (num / 1e8).toFixed(fix2) + '亿';
    } else if (tempValue > 1e4 || tempValue < -1e4) {
        return (num / 1e4).toFixed(fix2) + '万';
    }

    return num.toFixed(fix);
}

function object(o){
    function TF(){}
    TF.prototype = o;
    return new TF();
}

export function inheritFrom(subType, parentType){
    var prototypeObj = object(parentType.prototype);
    prototypeObj.constructor = subType;
    subType.prototype = prototypeObj;

    //ClassB继承ClassA的静态属性和方法
    for (var p in parentType) {
        if (parentType.hasOwnProperty(p)) {
            subType[p] = parentType[p];
        }
    }
}

function fsPrice_tooltip(data, {pre, keepLen, needConvertoShou, hqMarket}, callback, div=100){
    var lastPrice = parseFloat(pre);
    
    var result = merge({
        // t 当前时刻: '2017-06-15 11:34'
        t:'',
        // preClosePrice 昨收价: 14.6
        preClosePrice: '',
        // nowp 现价: '14.500'
        nowp:'',
        // np 成交金额: '40.6万'
        np:'',
        // av 均价: '14.521'
        av:'',
        // n 成交量: '2.8万'
        n: '',
        // 红绿色 up down eq
        status:'',
        // rate 涨跌幅: '-0.69%'
        rate:'',
        // ratep 涨跌额: '-0.100'
        ratep:''
    }, data, true);

    var curPrise = '';

    if (data['nowp'] != '') {
        curPrise = data['nowp'];
        result.status = filterStatus(curPrise, lastPrice);
    }else{
        curPrise = '';
        result.status = 'eq';
    }
    
    result.preClosePrice = lastPrice;
    result.nowp = decimalRound(curPrise, keepLen);
    result.av = decimalRound(data.av, keepLen);
    result.avStatus = filterStatus(data.av, lastPrice);
    // 修正分时图成交量不一致的问题
    if(needConvertoShou) result.n = data.n / div;
    result.n = numFormatter(result.n, 0, 1) || 0;
    result.np = numFormatter(data.np, 0, 1) || 0;

    var floatPrice = decimalRound(-(lastPrice - curPrise), keepLen); 
    var floatRate = decimalRound(floatPrice/lastPrice*100, 2);
    result.rate = floatRate+'%';
    result.ratep = floatPrice;

    var tpl;
    if(/^(Others_wh|Others_gzs|HS_block_hangye|HS_block_gainian|USA_index|Others_pmetal|Others_wh|Others_gzqh|Others_gqh|Others_qh|Others_zq|Others_sj|Others_ggqq)/.test(hqMarket)){
        tpl = '<%t%> 现价:<%nowp%> 涨跌:<%ratep%> 涨幅:<%rate%> 量:<%n%> 额:<%np%>';
    }else{
        tpl = '<%t%> 现价:<%nowp%> 均价:<%av%> 涨跌:<%ratep%> 涨幅:<%rate%> 量:<%n%> 额:<%np%>';
    }
    
    var domStr = typeof callback === 'function' ? callback({tpl, result}) : tpl.replace(/<%([^%>]+)?%>/g, function(s0, s1){
        if (s1 === 't') {
            return result[s1].substr(0, 2) + ':' + result[s1].substr(2, 2);
        }else{
            return '<span style="color:'+ color[result.status+'Axis'] +';">'+result[s1]+'</span>';
        }
    });

    return domStr;
}

function klinePrice_tooltip(data, {keepLen, needConvertoShou}, callback, div=100){
    // var stockTypeObj = hqObj.stockTypeObj;
    // var keepLen = stockTypeObj.keepLen;
    // var needConvertoShou = stockTypeObj.needConvertoShou;

    // result ////////////////////
    // closeP : '103.100'
    // maxP : '104.440'
    // maxPStatus : 'up'
    // minP : '102.620'
    // minPStatus : 'up'
    // mpos : Object
    // t : '20170213'
    // n : '972.4万'
    // openP : '102.820'
    // openPStatus : 'up'
    // originD : Object
    // rate : '0.72%'
    // ratep : '0.740'
    // status : 'up'
    var result = {};
    var lastPrice = data.yc;
  var lastPrice4Rate = data.yj || lastPrice

    var curPrise='';
    if (data.c!=='') {
        curPrise = data.c;
    }else{
        curPrise = '';
    }
    
    result.t = data.t;
    result.ratep = decimalRound(curPrise - lastPrice4Rate, keepLen);
    result.maxP = decimalRound(data.a, keepLen);
    result.minP = decimalRound(data.i, keepLen);
    result.openP = decimalRound(data.o, keepLen);
    result.closeP = decimalRound(curPrise, keepLen);

    result.n = data.n;
    if(needConvertoShou) result.n = data.n / div;
    result.n = numFormatter(result.n, 0, 1) || 0;
    
    result.maxPStatus = filterStatus(data.a, lastPrice);
    result.minPStatus = filterStatus(data.i, lastPrice);
    result.openPStatus = filterStatus(data.o, lastPrice);
    
    result.rate = decimalRound(((curPrise - lastPrice4Rate)/lastPrice4Rate*100), 2)+'%';
    result.status = (curPrise - lastPrice4Rate > 0 ) ? 'ab' : ((curPrise - lastPrice4Rate == 0) ? 'eq':'be');
    
    var tpl = '<%t%> 开：<span style="color: <%openPStatus%>;"><%openP%></span> 高：<span style="color: <%maxPStatus%>"><%maxP%></span> 低：<span style="color: <%minPStatus%>"><%minP%></span> 收：<span style="color: <%status%>"><%closeP%></span> 涨跌：<span style="color: <%status%>"><%ratep%></span> 涨幅：<span style="color: <%status%>"><%rate%></span><br/>';

    var maTpl = '';
    for(let i in data) { 
        if(/^(ma[{0-9}]+)/.test(i) && data[i]){
            maTpl += '<span style="color:<%'+ i +'Status%>">' + i.toUpperCase() + ':<%'+ i +'%> </span>';
        } 
    }
    
    var totalTpl = tpl + maTpl;

    var domStr = typeof callback === 'function' ? callback({tpl, maTpl, result, data}):totalTpl.replace(/<%([^%>]+)?%>/g, function(s0, s1){
        if (s1 === 'status' 
                || s1 ==='openPStatus' 
                || s1 === 'maxPStatus' 
                || s1 === 'minPStatus') {
            return color[result[s1]+'Axis'];
        }else if(/^(ma[{0-9}]+Status)$/.test(s1)){
            return color['ma'];
        }else if(/^(ma[{0-9}]+)$/.test(s1)){
            return parseFloat(data[s1]).toFixed(keepLen);
        }else{
            return result[s1];
        }
    });
    
    return domStr;
}

export {
    fsPrice_tooltip,
    klinePrice_tooltip
}
