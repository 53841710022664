/**
 * 视觉映射 by zzy
 * demo: 
 * http://datav.iwencai.com/platform/chartconfig.html#chartId=249
 * http://datav.iwencai.com/platform/chartconfig.html#chartId=248
 */
import SeriesModel from '../../model/Series';
import { each, map } from '../../util';
import { interpolateRgb } from 'd3-interpolate';

export const TYPE = 'visualMap';

export default class VisualMapModel extends SeriesModel {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    static defaultOption = {
        zlevel: 0, // canvas层级
        z: 3, // 同一层canvas内层级
        min: 0, // {number} 最小值 required
        max: 10, // {number} 最大值 required
        left: 20, // {number} 左偏移
        top: 100, // {number} 上偏移
        itemWidth: 20, // {number} 柱宽度
        itemHeight: 200, // {number} 柱高度
        range: undefined, // {array[min, max]} 默认的范围, range[min,max] 需要在 最小值(min)，最大值(max)的范围内
        text: ['max', 'min'], // {array} 显示文字
        precision: 0, // {number} 文字保留小数位数
        seriesColorReplace: true, // {boolean} 自动替换原series里面图形颜色, 默认true
        textStyle: {
            fontSize: 12, // {number} 文字大小
            textFill: '#000' // {sring} 文字填充
        },
        arrowDown: {
            labelFormatter: undefined, //{function} 说明文字过滤
            style: {}
        },
        arrowUp: {
            labelFormatter: undefined, //{function} 说明文字过滤
            style: {}
        },
        // throttleTime: undefined, // {number}拖拽时事件触发保护时间，当页面大数据运算是可设置30
        textGap: 10, // {number} 文字上下偏移
        arrowSize: 15, // {number} 箭头大小
        backgroundColor: 'grey', // {sring} 背景颜色
        inRange: {
            color: [
                [0, 'rgb(255, 204, 102)'], // {array} 渐变开始颜色
                [1, 'rgb(204, 51, 0)'] // {array} 渐变结束颜色
            ]
        },
        eventKey: 'VISUALMAP' // {sring} 交互事件
    }

    update() {
        let max = this.get('max');
        let min = this.get('min');
        let rangeOpt = this.get('range');
        let range = [];
        if (!rangeOpt) {
            range = [min, max];
        } else {
            range[0] = rangeOpt[0] < min ? min : rangeOpt[0];
            range[1] = rangeOpt[1] > max ? max : rangeOpt[1];
        }

        this.range = range;
        this.colorStopArr = [];
        const colorRange = this.get('inRange').color;
        for (let i = 0; i < colorRange.length - 1; i += 1) {
            this.colorStopArr.push({ down: colorRange[i][0], up: colorRange[i + 1][0], downColor: colorRange[i][1], upColor: colorRange[i + 1][1] });
        }
    }

    getUpRectHeight(value, min, max, height){
       return (max - value) / (max - min) * height
    }

    getDownRectHeight(value, min, max, height){
        return Math.abs(value - min) / (max - min) * height
    }

    isInRange(value) {
        let rangeOpt = this.get('range');

        if (rangeOpt) {
            return value >= rangeOpt[0] && value <= rangeOpt[1];
        } else {
            return true;
        }
    }

    getColor(value) {
        let max = this.get('max');
        let min = this.get('min');
        let colorStop = this.get('inRange').color;
        let countColor = interpolateRgb(colorStop[0][1], colorStop[1][1]);
        let result = (value - min) / (max - min);
        return countColor(result);
    }

    getColorStop(inter, seriesColor) {
        let max = this.get('max');
        let min = this.get('min');

        inter = inter / (max - min);

        if (seriesColor && !this.get('seriesColorReplace')) { return seriesColor; }

        for (let i = 0; i < this.colorStopArr.length; i += 1) {
            if (inter >= this.colorStopArr[i].down && inter < this.colorStopArr[i].up) {
                let computeColor = interpolateRgb(this.colorStopArr[i].downColor, this.colorStopArr[i].upColor);
                let finalColor = computeColor((inter - this.colorStopArr[i].down) / (this.colorStopArr[i].up - this.colorStopArr[i].down));
                return finalColor;
            }
            if (inter >= 1 && this.colorStopArr[i].up === 1) {
                return this.colorStopArr[i].upColor;
            }
        }
    }
}