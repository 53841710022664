// kline - 基础类
// 流程:
// 1. 获取历史数据H，整理(子类可选实现)
// 2. 获取实时数据R，整理(子类可选实现)
// 3. 历史数据H 和 实时数据R 拼接(子类可选实现), 计算Ma均线(父类实现), 存储为A, 如果不满足自动更新条件，到此处结束
// 4. 自动更新后新的实时数据R2, 整理(子类可选实现)
// 5. A数据和 更新的实时数据R2 拼接, 计算Ma均线, 存储为A  重复 3-5  (父类实现)
// 
// kline 与分时行情数据不同点在于， 分时，只请求一次历史数据，就包含了实时的数据。

// 为了较好的载入体验，均线ma设定值:  pc展示推荐<=60 ,手机展示推荐<=30, 
// ma配置为空 [], 计算较快
// 4000个k线，ma 如设置为 [5, 10, 30, 60] ，计算ma需要60ms+
import { 
    default as DataProvider,
    klineTypeConvertToNum,
    mergeLargeArr
} from '../DataProvider';
import { each, isArray, merge } from 'zrender/src/core/util'
import { getKLineStatus } from '../../util/hqHelper'

/**
 * 实时数据 格式化处理
 * 必须使用 'ab' 'eq' 'be'表示 上涨 持平 下跌
 * @param  {any} obj 
 * @param  {any} lastData 
 * @return {object}      
 * 1 日期
 * 7 开盘
 * 8 最高
 * 9 最低
 * 11 收盘
 * 13 成交量
 * 19 成交额
 * 1968584 换手率
 */
function getStandardCurrent(obj) {
    let result = {
        // time
        't': obj['1'],
        // openPrice
        'o': parseFloat(obj['7']),
        // maxPrice
        'a': parseFloat(obj['8']),
        // minPrice
        'i': parseFloat(obj['9']),
        // closePrice
        'c': parseFloat(obj['11']),
        // column 成交量
        'n': parseFloat(obj['13']),
        // money 成交金额
        'np': parseFloat(obj['19']),
        // 盘后成交量 目前只有科创板有该值
        'an': parseFloat(obj['74']),
        // // 盘后成交笔数 目前只有科创板有该值
        // 'anp': parseFloat(obj['75']),

        // 换手率
        'h': parseFloat(obj['1968584']),
        // 昨结价
        // 只用于sj 期货，gzqh三个市场
        'yj': parseFloat(obj['66']),
        // 昨收 
        // 父类 和历史数据merge的时候(mergeHistoryWithCurrent)，会补全
        'yc': '',
        // 上涨 下跌 持平
        // 获取 k线颜色 
        // 父类 和历史数据merge的时候(mergeHistoryWithCurrent)，会补全
        's': ''
    }

    return result;
}

function strToDate(dateStr) {
    dateStr = String(dateStr); 
    return new Date(dateStr.slice(0, 4), (dateStr.slice(4, 6)-1), dateStr.slice(6, 8));
}

function getSeason(num) {
    num = parseInt(num);
    if(num%3 === 0) {
        return num/3
    }else{ 
        return Math.ceil(num/3)
    }
}
/**
 * 默认 一周第一天是 周日
 * @return {boolen} 根据 stock类别 来判断
 */
function needMergeDate(a, b, time) {
    if (time === 'day') {
        return (a === b);
    }else if (time === 'week') {
        let minDate = strToDate(Math.min(a, b)),
            maxDate = strToDate(Math.max(a, b));
        return (minDate.getDay()+(maxDate.getTime()-minDate.getTime())/86400000) <= 6;
    }else if(time === 'month'){
        return a.slice(4, 6) === b.slice(4, 6);
    }else if(time === 'season'){
        return a.slice(0, 4) == b.slice(0,4) 
            && (getSeason(a.slice(4,6)) === getSeason(b.slice(4,6)))
    }else{
        return (a === b);
    }
}

/**
 * 包含以下行情逻辑 
 * 1. 港股 应港交所约定，不可以实时更新
 * @log 20180807 注册用户可以实时更新
 * 非注册用户需要延迟 15分钟
 * 以第三方提供出去的，可以实时更新
 * 
 * 2. 先请求全量数据，再请求增量数据
 * 3. 可开启或关闭实时更新
 * 继承 DataProvider
 * 需要把整理的数据用 this.fetchSuccess(data) 通知出去
 * !!不可以直接对this._data 进行赋值
 * 
 * @extends DataProvider
 */
 export default class KlineBase extends DataProvider{

    static type = 'klineBase';

    static defaultOption = {
        code:'hs_300033',

        // 类型再细分
        // 'qfq'   前复权
        // 'hfq'   后复权
        // 'bfq'   不复权
        // 'day'   日k 
        // 'week'  周k
        // 'month' 月k
        // 'season' 季k
        // '5Min'  5分钟k
        // '30Min' 30分钟k
        // '60Min' 60分钟k
        // '1Min'  1分钟k
        // '4Hour' 4小时k
        dType: 'qfqDay',

        // 必须从小到大排列, 数字
        // 成交量均线也是用这个
        ma:[5, 10, 30, 60],
        // 成交量 均线
        // nMa:[5, 10, 30, 60],

        urlFormatter: function({version, protocol, code, typecode}) {
            return `${protocol}//d.10jqka.com.cn/${version}/line/${code}/${typecode}/all.js`;
        },

        // 增量地址
        // 默认都是使用 全量+增量的 数据整合方式, 
        // 可以设置null, 持续更新也将关闭
        // keepGetUrlFormatter: null,
        keepGetUrlFormatter: function({version, protocol, code, typecode}) {
            return `${protocol}//d.10jqka.com.cn/${version}/line/${code}/${typecode}/today.js`;
        },

        // 是否持续更新
        isKeepingGet: false,

        // 更新间隔
        intervalTime: 60000
    }

    constructor(){
        super(...arguments);
        this.option = merge(this.option, KlineBase.defaultOption);
        // 当前是否实时更新
        // 根据行情数据返回的情况，程序会自行调整是否需要实时更新,
        this.keepingGetHandle;
    }

    // 子类 可覆盖实现
    arrangeHistory (d){
        return d;
    }

    // 子类 可覆盖实现
    // 1: "20160330"
    // 7: "19.48"
    // 8: "20.20"
    // 9: "19.26"
    // 11: "20.05"
    // 13: 19914098
    // 19: "391560870.00"
    // 1968584: "3.443"
    // dt: "1856"
    // name: "金龙机电"
    // open: 1
    /**
     * 整理实时数据
     * @param  {any} r  获取的原始数据
     * 实时数据部分, 放到 dataArray字段
     * 其他属性字段解析,统一放到 current 属性下面
     */
    arrangeCurrent(r){
        let code = this.get('code');
        let d = r[code];
        let result = {
            current:{
                open: d.open,
                dt: d.dt
            },
            dataArray: d['11'] ? [getStandardCurrent(d)] : []
        };
        return result;
    }


    // 子类 可覆盖实现
    // 更新增量数据
    // _protected_keepGetting (callback) {
    //     var that = this;
    //     var urlFormatterCbk = this.get('keepGetUrlFormatter');
    //     var code = this.get('code');
    //     var xhrType = this.get('xhrType');
    //     var data = this.getData();

    //     // todayStart [用于拼接增量数据的url]
    //     var url = urlFormatter(urlFormatterCbk, code, data.todayStart);

    //     var host = '';
    //     url.replace(/\/{2}([\w.:]+)\//, function(s0, s1){ 
    //         host = s1; 
    //     });
    //     var callbackName = hqArrange.getCallbackName(url, host+'/');

    //     Xhr[xhrType](url, function(d){
    //         var resultData = that.arrangeData(d, data);
    //         that.getStatus(resultData);
    //         callback(resultData);
    //     }, callbackName);
    // }

    // 子类 可覆盖实现
    // 校验是否实时更新
    checkNeedGetting (callback) {
        let data = this.getData();
        let code = this.get('code'); 
        let isKeepingGet = this.get('isKeepingGet'); 
        
        try {
            return isKeepingGet && data.current.current.open
            // let lastestTime = data.current.current.dt;
            // let start, end, r = false;

            // for (let i = 0; i < data.tradeTime.length; i++) {
            //     start = data.tradeTime[i].split('-')[0];
            //     end = data.tradeTime[i].split('-')[1];
            //     if (lastestTime >= start && lastestTime < end) {
            //         r = isKeepingGet;
            //     }
            // }
            // return r;

        } catch (e) {
            return false;
        }
        
    }

    // 历史数据 和 实时数据 合并
    // history: [a, b, c1], current: [c2, d, e]
    // 输出: [a, b, c2, d, e]    c1, c2 表示同一个时间点
    mergeHistoryWithCurrent (hisDataArr, curDataArr, issuePrice) {
        let { time } = this.getDType();

        let historyData = hisDataArr.slice(0);
        
        if(!historyData.length){
            historyData[0] = {t: curDataArr[0].t} 
        }

        let needMerge = needMergeDate(curDataArr[0].t, historyData[historyData.length-1].t, time);

        if (needMerge) {
            let popd = historyData.pop();
        }
        let cc = curDataArr.slice(0);
        if (historyData.length) {
            let historyDataLast = historyData[historyData.length-1];
            cc[0].yc = historyDataLast.c;
            cc[0].s = getKLineStatus(cc[0], historyDataLast);         
        }else{
            cc[0].yc = issuePrice ? issuePrice : cc[0].o;
            cc[0].s = getKLineStatus(cc[0]);
        }
               
        let r = mergeLargeArr(historyData, curDataArr.slice(0));

        return r;
    }

    // 计算 dataCollection 尾部数据的ma均价
    // 持续更新的时候，只有尾部的数据被替换过，只计算那段ma
    calculateMaOnEndData (dataCollection) {

        let that = this, 
            n = dataCollection.length;
        let maArr = that.get('ma');

        let maArrLength = maArr.length, str = '', currentMa, resultInt = 0, total = 0, nStr = '', nTotal = 0, nResult = 0, temp1, k = 0, j = 0;

        function processItem(pos, d) {

            for (k = 0; k < maArrLength; k++) {
                currentMa = maArr[k];
                str = 'ma' + maArr[k],
                resultInt = 0,
                total = 0,
                nStr = 'nMa' + maArr[k],
                nTotal = 0,
                nResult = 0;

                if(pos + 1 < currentMa)
                {
                    currentMa = pos + 1;
                    // d[str] = null;
                    // for(let kk = maArrLength-1; kk > k; kk--){ 
                    //     d['ma'+maArr[kk]] = null;
                    // } 
                    // break;
                }

                for (j = 0; j < currentMa; j++) {
                    temp1 = dataCollection[pos-j];
                    total += temp1.c;
                    nTotal += temp1.n;
                }
                resultInt = total/currentMa;
                // resultInt = decimalRound(total/currentMa, 4);
                // nResult = decimalRound(nTotal/currentMa, 0);
                d[str] = resultInt;
                // d[nStr] = nResult;
            }
            return d;
        }
        let tempMaStr = 'ma' + maArr[0];
        for (let i = n - 1; i >= 0; i--) {
            if (!(tempMaStr in dataCollection[i])) {
                processItem(i, dataCollection[i]);
            }else{
                break;
            }
        }
        return dataCollection;
    }

    // 计算 dataCollection 整个数据的ma均价
    // @todo processItem 两处调用，但是没有独立, 虽代码冗余，但是效率高
    calculateMa (dataCollection) {

        let n = dataCollection.length;
        let maArr = this.get('ma');

        let maArrLength = maArr.length, str = '', currentMa, resultInt = 0, total = 0, nStr = '', nTotal = 0, nResult = 0, temp1, k = 0, j = 0;

        function processItem(pos, d) {

            for (k = 0; k < maArrLength; k++) {
                currentMa = maArr[k];
                str = 'ma' + maArr[k],
                resultInt = 0,
                total = 0,
                nStr = 'nMa' + maArr[k],
                nTotal = 0,
                nResult = 0;

                if(pos + 1 < currentMa)
                {
                    currentMa = pos + 1;
                    d[str] = NaN;
                    for(let kk = maArrLength-1; kk > k; kk--){ 
                        d['ma'+maArr[kk]] = NaN;
                    } 
                    break;
                }

                for (j = 0; j < currentMa; j++) {
                    temp1 = dataCollection[pos-j];
                    total += temp1.c;
                    nTotal += temp1.n;
                }
                resultInt = total/currentMa;
                // resultInt = decimalRound(total/currentMa, 4);
                // nResult = decimalRound(nTotal/currentMa, 0);
                d[str] = resultInt;
                // d[nStr] = nResult;
            }
            return d;
        }

        // 小于8个k线
        let iteration = Math.floor(n/8);
        let leftover = n%8;
        let i = 0;

        if (n>=8) {
            if(leftover>0){
                do{
                    processItem(i, dataCollection[i++]);
                }while(--leftover > 0);
            }
            do{
                processItem(i, dataCollection[i++]);
                processItem(i, dataCollection[i++]);
                processItem(i, dataCollection[i++]);
                processItem(i, dataCollection[i++]);
                processItem(i, dataCollection[i++]);
                processItem(i, dataCollection[i++]);
                processItem(i, dataCollection[i++]);
                processItem(i, dataCollection[i++]);
            }while(--iteration > 0);
        }else{
            do{
                processItem(i, dataCollection[i++]);
            }while(--leftover > 0);
        }

        return dataCollection;
    }

    // 是否配置 ma (均价)
    isSettingMa () {
        return this.get('ma') && this.get('ma').length;
    }

    getDType(){
        let a = this.get('dType');
        return {
            fuquan: a.substr(0, 3).toLowerCase(),
            time: a.slice(3).toLowerCase()
        }
    }

    init () {
        let that = this;

        let req1Url = this.get('urlFormatter')({
            typecode: klineTypeConvertToNum(this.getDType()),
            ...this.option
        });
        let {url, callbackName} = this.getHqCallbackNameAndUrl(req1Url);

        // 只请求一次全量数据, 不实时更新
        if (!this.get('keepGetUrlFormatter')) {
            this.fetchRemote(url, {
                    jsonpCallbackFunction: callbackName
                })
                .then(function(d) {
                    let result = that.arrangeHistory(d);
                    if (result.dataArray.length && that.isSettingMa()) {
                        result.dataArray = that.calculateMa(result.dataArray);
                    }

                    that.fetchSuccess(result)

                }).catch(function(ex) {
                    that.fetchFail(ex);
                });
        }else{
            let req2Url = this.get('keepGetUrlFormatter')({
                typecode: klineTypeConvertToNum(this.getDType()),
                ...this.option
            });
            let { url: url2, callbackName: callbackName2 } = this.getHqCallbackNameAndUrl(req2Url);

            Promise.all([
                    this.fetchRemote(url, {
                        jsonpCallbackFunction: callbackName
                    }),
                    this.fetchRemote(url2, {
                        jsonpCallbackFunction: callbackName2
                    })
            ]).then(function (response) {
                let hisData = that.arrangeHistory(response[0]);
                let curData = that.arrangeCurrent(response[1]);
                
                let result = hisData;
                result.current = curData;
                let d = [];
                if (hisData.dataArray.length || curData.dataArray.length) {
                    d = that.mergeHistoryWithCurrent(
                        hisData.dataArray, 
                        curData.dataArray, 
                        hisData.issuePrice
                    );
                }
                if (d.length && that.isSettingMa()) {
                    d = that.calculateMa(d);
                }

                result.dataArray = d;
                that.fetchSuccess(result)
                
                // 持续获取
                // that.keepingGetHandle = setTimeout(function repeatKline() {
                //     // 是否需要实时更新
                //     if(!that.checkNeedGetting()){
                //         that.keepingGetHandle && clearTimeout(that.keepingGetHandle);
                //         return false;
                //     }
                //     // debugger;

                //     that.fetchRemote(url2, {
                //             jsonpCallbackFunction: callbackName2
                //         })
                //         .then(function(d) {

                //             let result = that.getData();
                           

                //             that.fetchSuccess(result)

                //         }).catch(function(ex) {
                //             that.fetchFail(ex);
                //         });


                //     // getCurrent.call(that, that.configs, hisData, function(cDD){
                //     //     that.r.current = cDD.current;
                //     //     subArrangeData = mergeHistoryWithCurrent(that.r.dataArray, cDD.dataArray, that.configs.dType.substr(12));
                //     //     that.r.dataArray = calculateMaOnEndData.call(that, subArrangeData);
                //     //     that.trigger('getDataHXC3', that.r);
                //     // });

                //    that.keepingGetHandle = setTimeout(repeatKline, that.get('intervalTime'));
                // }, that.get('intervalTime'));

            }).catch(function(ex) {
                that.fetchFail(ex);
            });
        }
    }
}
