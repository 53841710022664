import { Group } from '../util/graphic';
import { isString, isFunction, each } from '../util';
import ShapeStorage from '../util/ShapeStorage';

export default class GroupShape extends ShapeStorage {

    constructor(attrMap) {
        super(...arguments);

        this.name = attrMap.name;
        this.group.name = attrMap.name;
        this.dataIndex = attrMap.dataIndex;
        this.group.dataIndex = attrMap.dataIndex;
        this.seriesIndex = attrMap.seriesIndex;
        this.group.seriesIndex = attrMap.seriesIndex;

        delete attrMap.name;
        delete attrMap.dataIndex;
        delete attrMap.seriesIndex;
    }

    attr(attrMap) {
        let shapeMap = this._shapeMap;

        each(attrMap, (attr, key) => shapeMap[key] && shapeMap[key].attr(attr));
    }

    animateTo(targetMap, time, delay, easing, callback) {
        // animateTo(targetMap, time, easing, callback);
        if (isString(delay)) {
            callback = easing;
            easing = delay;
            delay = 0;
        }
        // animateTo(targetMap, time, delay, callback);
        else if (isFunction(easing)) {
            callback = easing;
            easing = 'linear';
            delay = 0;
        }
        // animateTo(targetMap, time, callback);
        else if (isFunction(delay)) {
            callback = delay;
            delay = 0;
        }
        // animateTo(targetMap, callback)
        else if (isFunction(time)) {
            callback = time;
            time = 500;
        }
        // animateTo(targetMap)
        else if (!time) {
            time = 500;
        }

        let shapeMap = this._shapeMap;

        each(targetMap, (target, key) => shapeMap[key] && shapeMap[key].animateTo(target, time, delay, easing, callback));
    }

    setStyle(styleMap) {
        let shapeMap = this._shapeMap;

        each(styleMap, (style, key) => shapeMap[key] && shapeMap[key].setStyle(style));
    }

    useStyle(styleMap) {
        let shapeMap = this._shapeMap;

        each(styleMap, (style, key) => shapeMap[key] && shapeMap[key].useStyle(style));
    }

    on() {
        this.group.on(...arguments);
    }

    off() {
        this.group.off(...arguments);
    }

    one() {
        this.group.one(...arguments);
    }

    trigger() {
        this.group.trigger(...arguments);
    }
}