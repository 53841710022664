/**
 * 面积线 by wjw
 */
import ScatterModel from '../scatter/ScatterModel';
import { each, map, isArray, setObjectKV, getObjectKV } from '../../util';

export const TYPE = 'area';

export default class AreaModel extends ScatterModel {
    static type = TYPE;

    static defaultOption = {
        //data: undefined, // {Array} 系列中的数据内容数组。数组项通常为具体的数据项。数据项格式同echarts类似，http://echarts.baidu.com/option.html#series-line.data
        $dataIndex: undefined, // {number} 依赖的$data的index，格式同上，优先级低于data，取得的数据需符合data的格式
        dataKey: undefined, // {string|number} 搭配$dataIndex使用，方便从依赖的$data中取数据，等同于 data.map((d) => d[dataKey])，最终取得的数据需符合data的格式
        $axisIndex: [0, 1], // {Array} 所依赖axis的index，需包括一个x轴和y轴
        name: undefined, // {string} 系列名称，用于tooltip的显示，legend 的图例筛选

        // 区域样式
        area: {
            normal: { // {Object} 普通
                show: true,
                style: {
                    fill: '#ccc'
                }
            }
        },

        // 平滑处理
        curve: {
            type: 'linear', // {String} curve类型，参考d3文档 https://github.com/xswei/d3js_doc/tree/master/API/d3-shape-master#curves
            param: undefined // 根据type不同，param代表的参数也不同，参考d3文档
        },
        connectNulls: false, // {Boolean} 是否连接空数据。
        legendHoverLink: true, // {Boolean} 是否与图例进行高亮联动

        cursor: 'default', // {string} 鼠标悬浮时在图形元素上时鼠标的样式是什么。同 CSS 的 cursor。
        zlevel: 0, // {number} 所有图形的 zlevel 值
        z: 3 // {number} 组件的所有图形的z值。控制图形的前后顺序。z值小的图形会被z值大的图形覆盖。
    };

    formatOption(option) {
        super.formatOption(...arguments);

        if (option.color) {
            if (!getObjectKV(option, 'area.normal.style.fill')) {
                setObjectKV(option, 'area.normal.style.fill', option.color);
            }
        }
    }

    formatData(data) {
        return map(data, (d, i) => {
            return [i, d];
        });
    }

    update() {
        super.update(...arguments);

        let yAxis = this.getAxisModel('y');
        let yScale = yAxis.scale;
        let yDomain = yScale.domain();
        
        this.y0 = yScale(
            yDomain[1] < 0 ? yDomain[1] : yDomain[0] > 0 ? yDomain[0] : 0
        );
    }
}
