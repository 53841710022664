import { each, clone, isArray } from '../../../util';
import { Matrix4, Vector3 } from '../../../util/cuon-matrix';
import MultPolygon from '../../../shape/MultPolygon';

export default function getCuboidLayout({
    rectWidth, position, y0, points, model
}) {
    let itemStyle = model.get('itemStyle').normal;
    let xLookAngle = model.get('xLookAngle') / 180 * Math.PI;
    let yLookAngle = model.get('yLookAngle') / 180 * Math.PI;
    let isStack = !!model.get('stack');
    let attrs = [];
    let animateFroms = [];

    if (position === 'top' || position === 'bottom') {
        let style1 = getItemStyle(itemStyle, 'front'), style2 = getItemStyle(itemStyle, 'left'), style3 = getItemStyle(itemStyle, 'top');

        let viewMatrix = new Matrix4();
        viewMatrix.setLookAt(Math.tan(xLookAngle), Math.tan(yLookAngle), 1, 0, 0, 0, 0, 1, 0);

        let x = [-rectWidth / 4, rectWidth / 4 * 3];
        let z;

        if (Math.tan(xLookAngle) > 0) {
            z = [0, -rectWidth]
        } else {
            z = [rectWidth, 0]
        }

        let topFace = [
            (new Matrix4(viewMatrix)).multiplyVector3(new Vector3([x[0], 0, z[1]])).elements.slice(0, 2),
            (new Matrix4(viewMatrix)).multiplyVector3(new Vector3([x[1], 0, z[1]])).elements.slice(0, 2),
            (new Matrix4(viewMatrix)).multiplyVector3(new Vector3([x[0], 0, z[0]])).elements.slice(0, 2),
            (new Matrix4(viewMatrix)).multiplyVector3(new Vector3([x[1], 0, z[0]])).elements.slice(0, 2)
        ];

        each(points, ([xi, yi], index) => {
            let attr = {};
            let animateFrom = {};
            let points = [];
            let startPoints = [];
            let _y0 = isStack ? yi[0] : y0;
            let _yi = isStack ? yi[1] : yi;

            for (let i = 0; i < topFace.length; i++) {
                let x = topFace[i][0] + xi;

                points.push([x, topFace[i][1] + _y0]);
                points.push([x, topFace[i][1] + _yi]);
                startPoints.push([x, topFace[i][1] + _y0]);
            }

            // 正面矩形
            attr.front = {
                shape: {
                    points: clone([
                        points[0],
                        points[2],
                        points[3],
                        points[1]
                    ])
                },
                style: style1
            };

            animateFrom.front = {
                shape: {
                    points: clone([
                        startPoints[0],
                        startPoints[1],
                        startPoints[1],
                        startPoints[0]
                    ])
                }
            };


            // 侧面矩形
            let points2 = [];

            if (Math.tan(xLookAngle) > 0) {
                points2 = [
                    points[0],
                    points[4],
                    points[5],
                    points[1]
                ];

                animateFrom.left = {
                    shape: {
                        points: clone([
                            startPoints[0],
                            startPoints[2],
                            startPoints[2],
                            startPoints[0]
                        ])
                    }
                };
            } else {
                points2 = [
                    points[2],
                    points[6],
                    points[7],
                    points[3]
                ];

                animateFrom.left = {
                    shape: {
                        points: clone([
                            startPoints[1],
                            startPoints[3],
                            startPoints[3],
                            startPoints[1]
                        ])
                    }
                };
            }

            attr.left = {
                shape: {
                    points: clone(points2)
                },
                style: style2
            };


            // 上下矩形
            let points3 = [];

            if ((Math.tan(yLookAngle) > 0 && _yi < _y0) || (Math.tan(yLookAngle) < 0 && _yi > _y0)) {
                points3 = [
                    points[1],
                    points[3],
                    points[7],
                    points[5]
                ];
            } else {
                points3 = [
                    points[0],
                    points[2],
                    points[6],
                    points[4]
                ];
            }

            attr.top = {
                shape: {
                    points: clone(points3)
                },
                style: style3
            };

            animateFrom.top = {
                shape: {
                    points: clone([
                        startPoints[0],
                        startPoints[1],
                        startPoints[3],
                        startPoints[2]
                    ])
                }
            };

            attrs.push(attr);
            animateFroms.push(animateFrom);
        });
    // eslint-disable-next-line no-empty
    } else {

    }

    return {
        attrs,
        animateFroms,
        Shape: MultPolygon
    }
}

function getItemStyle(itemStyle, index) {
    return itemStyle[index];
}
