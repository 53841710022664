/**
 * 仪表盘 by wjw
 */
import SeriesModel from '../../model/Series';
import { parsePercent, isObject, isArray } from '../../util';

export const TYPE = 'gauge';

export default class GaugeModel extends SeriesModel {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    static defaultOption = {

        data: undefined, // {number} 当前值
        center: ['50%', '50%'], // {Array} 中心位置
        radius: '40%', // {string|number} 半径大小，若为百分比则取宽高较小的值取百分比, arc宽度可以在axisLine.style.lineWidth 设置
        startAngle: -210, // {number} 仪表盘起始角度。圆心 正右手侧为-180度，正上方为-90度，正左手侧为0度。
        endAngle: 30, // {number} 仪表盘结束角度。
        min: 0, // {number} 最小的数据值，映射到 minAngle。
        max: 100, // {number} 最大的数据值，映射到 maxAngle。
        splitNumber: 10, // {number} 仪表盘刻度的分割段数。
        tickOffset: 40, // {number} tick偏移距离
        color: [[1, 'red']], // {Array|string} 仪表盘可以被分成不同颜色的多段。每段的结束位置和颜色可以通过一个数组来表示。 数据在 0-1之间
        gradient: false, // {boolean} 是否渐变
        arcNum: 50,
        axisLine: { // 仪表盘轴线相关配置。
            show: true, // {boolean} 是否显示
            style: { // {Object} 样式
                lineWidth: 30 // {number} arc 宽度
            }
        },
        arcOutline:{ // 圆弧完整背景
            show: false,
            style:{
                fill: '#0E122D',
                stroke: '#26284F'
            }
        },
        endArc: { // 圆弧结束位置高亮
            show: false,
            angleCross: 1, // {number} 度 360度为分母
            style: {
                fill: '#EF136D',
                stroke: '#EF136D'
            }
        },
        splitLine: { // 分隔线样式。
            show: true, // {boolean} 是否显示
            type: 'tick', // tick || circle
            length: 15, // {string|number} 分隔线线长。支持相对半径的百分比。
            gradient: false, // false：读取style，true：读取color
            color: [[0, '#202126'], [0.5, '#0096FE'], [1, '#202126']],
            style: { // {Object} 样式
                fill: '#ED1671',
                stroke: '#acacac',
                lineWidth: 2
            }
        },
        axisTick: { // 刻度样式。
            show: true, // {boolean} 是否显示
            type: 'tick', // {string} tick || circle
            splitNumber: 5, // {number} 分隔线之间分割的刻度数。
            length: 8, // {number} 刻度线长。支持相对半径的百分比。如果type是circle，则为半径
            gradient: false, // false：读取style，true：读取color
            color: [[0, '#202126'], [0.5, '#0096FE'], [1, '#202126']], // {Array|string} [[1, 'red']], 仪表盘可以被分成不同颜色的多段。每段的结束位置和颜色可以通过一个数组来表示
            style: { // {Object} 样式
                fill: '#ED1671',
                stroke: '#acacac',
                lineWidth: 1
            }
        },
        axisLabel: { // 刻度标签。
            show: true, // {boolean} 是否显示
            distance: 15, // {number} 标签与刻度线的距离。
            formatter: undefined, // {Function} 格式化函数
            style: { // {Object} 样式
                fill: '#acacac',
                textAlign: 'center',
                textVerticalAlign: 'center'
            }
        },
        title: { // 仪表盘标题。
            show: true, // {boolean} 是否显示
            text: undefined,
            offsetCenter: [0, '80%'], // {Array} 相对于仪表盘中心的偏移位置，数组第一项是水平方向的偏移，第二项是垂直方向的偏移。可以是绝对的数值，也可以是相对于仪表盘半径的百分比。
            style: { // {Object} 样式
                fill: '#acacac',
                fontSize: 20,
                textAlign: 'center',
                textVerticalAlign: 'center'
            }
        },
        detail: { // 仪表盘详情，用于显示数据。
            show: true, // {boolean} 是否显示
            offsetCenter: [0, 0], // {Array} 相对于仪表盘中心的偏移位置，数组第一项是水平方向的偏移，第二项是垂直方向的偏移。可以是绝对的数值，也可以是相对于仪表盘半径的百分比。
            formatter: undefined, // {Function} 格式化函数
            style: { // {Object} 样式
                fontSize: 50,
                textAlign: 'center',
                textVerticalAlign: 'center',
                // fill: '#333',
                // rich: {
                //     a: {
                //         fill: 'rgb(161, 161, 161)'
                //     },
                //     b: {
                //         fill: 'white'
                //     }
                // }
            }
        },

        zlevel: 0, // canvas层级
        z: 1 // 同一层canvas内层级
    };

    update() {
        let data = this.getData();
        let min = this.get('min');
        let max = this.get('max');
        let title = this.get('title').text;
        let startAngle = this.get('startAngle');
        let endAngle = this.get('endAngle');
        let global = this.global;
        let globalWidth = global.getWidth();
        let globalHeight = global.getHeight();
        let baseLen = Math.min(globalHeight, globalWidth);
        let center = this.get('center');
        let radius = this.get('radius');
        let color = this.get('color');
        let gradient = this.get('gradient');
        let value;

        if (isObject(data)) {
            data.min !== undefined && (min = data.min);
            data.max !== undefined && (max = data.max);
            value = data.value;
            data.title !== undefined && (title = data.title);
        } else {
            value = data;
        }

        value = Math.min(Math.max(min, value), max);

        if (color) {
            if (isArray(color)) {
                color = color.sort((a, b) => a[0] - b[0]);
            } else {
                color = [[1, color]];
            }

            if (gradient && color[0][0] !== 0) {
                color.unshift([0, color[0][1]])
            }
        }

        this.color = color;
        this.title = title;
        this.percent = (value - min) / (max - min);
        this.startAngle = startAngle / 180 * Math.PI;
        this.endAngle = endAngle / 180 * Math.PI;
        this.value = value;
        this.radius = parsePercent(radius, baseLen);
        this.center = [parsePercent(center[0], globalWidth), parsePercent(center[1], globalHeight)];
        this.min = min;
        this.max = max;
    }
}
