/**
 * 雷达图 by zzy
 */
import SeriesModel from '../../model/Series';

export const TYPE = 'radar';

export default class RadarModel extends SeriesModel {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    static defaultOption = {

        $radarAxisIndex: 0, // {number} 依赖极坐标index值
        name: undefined, // {string} series的name，与legend相关联,
        animation: true, // {boolean} 是否开启动画
        duration: 500, // 动画持续时间
        easing: 'cubicOut', // 动画缓动函数
        symbol: 'circle', // {string} 标记的图形
        symbolSize: 3, // {number} 标记的图形的尺寸
        label: {
            normal: {
                show: false,
                distanceOffset: 1, // {number} 由中心发散出去到点的距离的倍数
                offset: [0, 15], // {array<number>} 偏移
                formatter: undefined, // {function} 文字过滤
                textBaseline: 'middle',
                textAlign: 'center'
                // textFill  // 文字颜色, 不配置的话将会使用color颜色
            },
            emphasis: {
                show: false
            }
        },
        itemStyle: {
            normal: {
                lineWidth: 1,
                borderType: 'solid',
                fill: 'auto',// 不设置的话，自动根据lineStyle颜色计算，'auto', 'hidden', '#fffede', 'rgba(255,255,255,0.8)'
                opacity: 0.6 // 只针对 fill的颜色调整透明度
            },
            emphasis: {
                lineWidth: 1,
                borderType: 'solid',
                fill: 'auto', // 不设置的话，自动根据lineStyle颜色计算，'auto', 'hidden', '#fffede', 'rgba(255,255,255,0.8)'
                opacity: 0.8 // 只针对 fill的颜色调整透明度
            }
        },
        tooltip: { // 悬停时，展示
            formatter: function (params) { // {function} 悬停时展示的内容过滤
               var finalStr = params.data.name+':<br />'
               for (var i = 0; i < params.indicator.length; i++) {
                   finalStr += params.indicator[i].name+': '+params.data.value[i]+'<br />';
               }
               return finalStr;
            }
        },

        // cursor: 'default', // {string} 鼠标悬浮时在图形元素上时鼠标的样式是什么。同 CSS 的 cursor。
        zlevel: 0, // {number} 所有图形的 zlevel 值
        z: 2 // {number} 组件的所有图形的z值。控制图形的前后顺序。z值小的图形会被z值大的图形覆盖。

    };


}


