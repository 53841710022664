// kline 全历史数据处理 实现类(子类)
// 请勿直接对 this._xxx 的私有成员变量 赋值或修改，
// 可调用提供的公共方法来修改
import KlineBase from './KlineBase'
import { getKLineStatus, getStockType } from '../../util/hqHelper'

export default class KlineCommon extends KlineBase{

    static type = 'klineCommon'

    static defaultOption = {
        urlFormatter: function({version, protocol, code, typecode}) {
            return `${protocol}//d.10jqka.com.cn/${version}/line/${code}/${typecode}/all.js`;
        }
    }
    
    constructor(){
        super(...arguments);
    }

    arrangeHistory (d) {
        let stockType = getStockType(this.get('code'));

        let { 
            sortYear, 
            priceFactor,
            issuePrice,
            name,
            total: totalKlineNum,
            start: firstDate 
        } = d;
        let vol = d.volumn.split(',');
        let price = d.price.split(',');
        let dates = d.dates.split(',');
      let yj = d.afterVolumn.split(',')
        let dataLength = dates.length;
        let isGetTotalFirstData = true;

        let rr = {
            stockType,
            sortYear, 
            // 数据精度
            priceFactor,
            // 初上市定价
            issuePrice,
            name,
            totalKlineNum,
            firstDate,
            // 获取所有历史数据
            isGetTotalFirstData,
            dataArray: []
        };

        if (totalKlineNum == 0) return rr; 

        let result = [];
        let priceName = ['i', 'o', 'a', 'c'];
        let j = 0, yearStr = '';
        let yearn = 0, tempYearArr = [];

        for (let l = 0; l < sortYear.length; l++) {
            tempYearArr.push([sortYear[l][0], yearn]);
            yearn += sortYear[l][1];
        }
        tempYearArr.push(['', Infinity]);

        // 低开高收
        for (let i = 0; i < dataLength; i++) {
            result[i] = {};
            if (i === 0) {
                yearStr = tempYearArr[j][0];
                result[i].t = yearStr + dates[i];
            }else if (i < tempYearArr[j+1][1]) {
                result[i].t = yearStr + dates[i];
            }else if(i >= tempYearArr[j+1][1]){
                ++j;
                yearStr = tempYearArr[j][0];
                result[i].t = yearStr + dates[i];
            }
            result[i].n = parseInt(vol[i]);
            for (var k = 0; k < 4; k++) {
                if (result[i].i || result[i].i == 0) {
                    result[i][priceName[k]]= price[4*i+k]/priceFactor + result[i].i;
                }else{
                    result[i][priceName[k]]= price[4*i+k]/priceFactor;
                }
            }
            result[i].s = getKLineStatus(result[i], result[i - 1]);
            if(i === 0){
                result[i].yc = result[i].o;
            }else{
                result[i].yc = result[i-1].c;
            }
              
          result[i].yj = yj[i]
        }
        if(isGetTotalFirstData){
            result[0].yc = parseFloat(rr.issuePrice);
        }

        rr.dataArray = result;

        return rr;
    }
}
