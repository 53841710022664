// import basisClosed from "d3-shape/src/curve/basisClosed";
// import basisOpen from "d3-shape/src/curve/basisOpen";
// import basis from "d3-shape/src/curve/basis";
// import bundle from "d3-shape/src/curve/bundle";
// import cardinalClosed from "d3-shape/src/curve/cardinalClosed";
// import cardinalOpen from "d3-shape/src/curve/cardinalOpen";
// import cardinal from "d3-shape/src/curve/cardinal";
// import catmullRomClosed from "d3-shape/src/curve/catmullRomClosed";
// import catmullRomOpen from "d3-shape/src/curve/catmullRomOpen";
// import catmullRom from "d3-shape/src/curve/catmullRom";
// import linearClosed from "d3-shape/src/curve/linearClosed";
import linear from 'd3-shape/src/curve/linear';
import { monotoneX, monotoneY } from 'd3-shape/src/curve/monotone';
import natural from 'd3-shape/src/curve/natural';
import { default as step, stepAfter, stepBefore } from 'd3-shape/src/curve/step';


const curve = {
    // basisClosed,
    // basisOpen,
    // basis,
    // bundle,
    // cardinalClosed,
    // cardinalOpen,
    // cardinal,
    // catmullRomClosed,
    // catmullRomOpen,
    // catmullRom,
    linear,
    // linearClosed,
    monotoneX,
    monotoneY,
    natural,
    step,
    stepAfter,
    stepBefore
};

export function getCurve(option) {
    let type = option.type;
    let param;

    switch (type) {
        case 'bundle':
            param = 'beta';
            break;
        case 'cardinal':
        case 'cardinalOpen':
        case 'cardinalClosed':
            param = 'tension';
            break;
        case 'catmullRom':
        case 'catmullRomOpen':
        case 'catmullRomClosed':
            param = 'alpha';
            break;
        default:
            break;
    }

    return param ? curve[type][param](option.param) : curve[type];
}