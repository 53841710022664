import { Rect, Line, Text, Polygon, Polyline, Circle } from '../../util/graphic';
import ChartView from '../../view/Chart';
import { each } from '../../util';
import { TYPE } from './DagreModel'; // 使用Model中定义的TYPE，与model的type值保持一致
import dagre from '../../util/dagre';
import { max, min } from 'd3-array';
import { point } from 'd3-scale/src/band';
import { forceActions } from '../../action/event';

let w, h;
let splitNum = 7;
export default class DagreView extends ChartView {

  static type = TYPE; // 静态变量

  type = TYPE; // 实例变量

  render(model, globalModel, global) {
    let data = model.getData();
    let nodeOpt = model.get('node');
    let linkOpt = model.get('link');
    let g = new dagre.graphlib.Graph({ multigraph: true, directed: true, compound: true });
    let draggable = model.get('draggable')
    g.setGraph({
      // rankdir: "LR",
      // nodesep: 30,
      ranksep: nodeOpt.rankStep
    });
    g.setDefaultEdgeLabel(function () { return {}; });

    let realNodes = data.nodes;
    // 匹配节点，并计算节点初始位置
    if (nodeOpt.entireLabel) {
      each(realNodes, (node) => {
        if (node.data && strlen(node.data.name) / 2 > splitNum && node.data.category === 1) {
          let newName = node.data.name.slice(0, splitNum) + '...';
          g.setNode(node.data.id, { label: node.data.category, width: nodeOpt.shape.width, height: nodeOpt.shape.height, newName: newName, isClick: true });
        } else {
          let isEnglish = escape(node.data.name).indexOf('%u');
          // console.log(isEnglish)
          let tl = strlen(node.data.name) / 2;
          if (tl > splitNum) {
            if (isEnglish === -1) {
              let fontWidth = Math.ceil(tl / 2) * nodeOpt.style.normal.fontSize;
              g.setNode(node.data.id, { label: node.data.category, width: fontWidth, height: nodeOpt.shape.height, newName: node.data.name, isClick: false });
            } else {
              let newTl = Math.ceil(tl / 2);
              let split = new RegExp(`.{${newTl * 2}}\x01?`, 'g');
              // eslint-disable-next-line no-control-regex
              let newName = node.data.name.replace(/[^\x00-\xff]/g, '$&\x01').replace(split, '$&\n').replace(/\x01/g, '');
              g.setNode(node.data.id, { label: node.data.category, width: nodeOpt.shape.width, height: nodeOpt.shape.height, newName: newName, isClick: false });
            }
          } else {
            let fontWidth = tl * nodeOpt.style.normal.fontSize + 50;
            g.setNode(node.data.id, { label: node.data.category, width: fontWidth, height: nodeOpt.shape.height, newName: node.data.name, isClick: false });
          }
        }
      });
    } else {
      each(realNodes, (node) => {
        if (node.data && node.data.name.length > 4) {
          let newName = node.data.name.slice(0, 4) + '...';
          g.setNode(node.data.id, { label: node.data.category, width: nodeOpt.shape.width, height: nodeOpt.shape.height, newName: newName, isClick: true });
        } else {
          g.setNode(node.data.id, { label: node.data.category, width: nodeOpt.shape.width, height: nodeOpt.shape.height, newName: node.data.name, isClick: true });
        }
      });
    }

    // 数据处理
    this.maxCategory = max(realNodes, (n) => n.data.category);
    this.minCategory = min(realNodes, (n) => n.data.category);
    this.categoryArr = realNodes.map((n) => n.data.category);

    let realEdges = data.edges;
    let maxLink = max(realEdges, (e) => e.data.weight);

    // 设置连线默认位置
    each(realEdges, (edge) => {
      let lw;
      if (edge.data) {
        if (linkOpt.label.type === 'text') {
          lw = linkOpt.lineWidth[0];
        } else {
          lw = edge.data.weight ? edge.data.weight / maxLink : linkOpt.lineWidth[0];
        }
      } else {
        lw = 0.5;
      }
      g.setEdge(edge.data.source, edge.data.target, { lineWidth: lw, label: edge.data.weight });
    });

    // 布局默认位置
    dagre.layout(g);
    w = global.getWidth();
    h = global.getHeight();
    this.offsetWidth = w - g.graph().width;
    this.offsetHeight = h - g.graph().height;
    let attrs = [];

    // 颜色函数
    let fillColor = (node) => {
      if (node.label === this.maxCategory) {
        return nodeOpt.style.normal.colors.end;
      } else if (node.label === this.minCategory) {
        return nodeOpt.style.normal.colors.origin;
      } else {
        return nodeOpt.style.normal.colors.process;
      }
    }

    // 去除数组内重复元素
    let uniqueArray = (arr) => {
      let res = [];
      let json = {};
      each(arr, (a) => {
        if (!json[a]) {
          res.push(a);
          json[a] = 1;
        }
      })
      return res;
    }

    // 获取节点宽度函数
    const getNodeWidth = (w, n) => {
      if (nodeOpt.entireLabel) {
        if (n.data.category === 1) {
          return w;
        } else {
          let newWidth;
          if (n.data.name.length > splitNum) {
            let halfNum = Math.ceil(n.data.name.length / 2);
            newWidth = halfNum * nodeOpt.style.normal.fontSize + 50;
          } else {
            newWidth = n.data.name.length * nodeOpt.style.normal.fontSize + 50;
          }
          // newWidth = newWidth > w ? newWidth : w;
          return newWidth;
        }
      } else {
        return w;
      }
    }

    // 获取节点高度函数
    const getNodeHeight = (h, n) => {
      if (nodeOpt.entireLabel) {
        let isEnglish = escape(n.data.name).indexOf('%u');
        if (n.data.category === 1) {
          return h;
        } else {
          let newH;
          if (isEnglish === -1) {
            newH = h;
          } else {
            newH = n.data.name.length > splitNum ? h + nodeOpt.style.normal.fontSize : h;
          }
          return newH;
        }
      } else {
        return h;
      }
    }

    // 获取节点间距函数
    const getNodeR = (r, n) => {
      if (nodeOpt.entireLabel) {
        let isEnglish = escape(n.data.name).indexOf('%u');
        if (n.data.category === 1) {
          return r;
        } else {
          let newR;
          if (isEnglish === -1) {
            newR = n.data.name.length > splitNum * 2 ? r + nodeOpt.style.normal.fontSize / 2 : r;
          } else {
            newR = n.data.name.length > splitNum ? r + nodeOpt.style.normal.fontSize / 2 : r;
          }
          return newR;
        }
      } else {
        return r;
      }
    }

    // 渲染节点
    each(g.nodes(), (v, i) => {
      let node = g.node(v);
      let currentX = node.x + this.offsetWidth / 2;
      let currentY = node.y + this.offsetHeight / 2;
      attrs.push({
        shape: {
          x: currentX,
          y: currentY,
          width: getNodeWidth(nodeOpt.shape.width, realNodes[i]),
          height: getNodeHeight(nodeOpt.shape.height, realNodes[i]),
          r: getNodeR(nodeOpt.shape.r, realNodes[i])
        },
        style: {
          ...nodeOpt.style.normal,
          fill: fillColor(node)
        },
        position: [-getNodeWidth(nodeOpt.shape.width, realNodes[i]) / 2, -nodeOpt.shape.height / 2],
        z: model.get('z'),
        z2: 900,
        nodeKey: v,
        nodeName: realNodes[i].data.name,
        nodeData: realNodes[i],
        nodeLabel: node.newName,
        isClick: node.isClick
      });
      this.setShape(v, Text, {
        style: {
          // text: getText(node.newName, realNodes[i]),
          text: node.newName,
          fontSize: nodeOpt.style.normal.fontSize,
          textFill: nodeOpt.style.normal.textFill,
          textAlign: 'center'
        },
        position: [currentX, currentY - nodeOpt.style.normal.fontSize / 2],
        z: model.get('z'),
        z2: 901,
        silent: true
      });
    })
    let limit = model.get('limited');
    initScale.call(this, model, limit);

    // must be here before rendering nodes
    this.edgeOffsetX = -(w - g.graph().width) / 2;
    this.edgeOffsetY = -(h - g.graph().height) / 2;

    // 设置节点交互
    this.setShapeGroup('nodes', Rect, attrs, undefined, undefined, (n, i) => {
      let drag = false;
      let circleSize = n.shape.height * 0.8;
      let labelShape;
      let isLabel = false;
      n.on('mousedown', ({ offsetX, offsetY }) => {
        drag = true;
        isLabel = !isLabel;
        labelShape = this.getShape(n.nodeKey);
        global.dispatchAction(model, forceActions.nodeClick, n.nodeData);
        let textLength = Math.ceil(strlen(n.nodeName) / 2) + .5
        if (textLength > splitNum && n.isClick) {
          if (isLabel) {
            labelShape.setStyle('text', n.nodeName);
            labelShape.attr('z2', 1501);
            let width = (textLength + 1.5) * nodeOpt.style.normal.fontSize
            n.setShape('width', width);
            let offset = (textLength - splitNum) * nodeOpt.style.normal.fontSize / 2;
            n.attr('position', [-width / 2, -nodeOpt.shape.height / 2]);
            let isEnglish = escape(n.nodeName).indexOf('%u');
            if (isEnglish) {
              let newW = (textLength / 2 + 2) * nodeOpt.style.normal.fontSize;
              n.setShape('width', newW);
              let offset = (textLength / 2 - splitNum) * nodeOpt.style.normal.fontSize / 2;
              n.attr('position', [-nodeOpt.shape.width / 2 - offset, -nodeOpt.shape.height / 2]);
            }
            n.attr('z2', 1500);
          } else {
            labelShape.setStyle('text', n.nodeLabel);
            labelShape.attr('z2', 901);
            n.setShape('width', nodeOpt.shape.width);
            n.attr('position', [-nodeOpt.shape.width / 2, -nodeOpt.shape.height / 2]);
            n.attr('z2', 900);
          }
        }
      });

      global.on('mousemove', ({ offsetX, offsetY }) => {
        if (drag === true && draggable) {
          const newPos = this.group.transformCoordToLocal(offsetX, offsetY); //Important!
          n.setShape('x', newPos[0]);
          n.setShape('y', newPos[1]);
          labelShape.attr('position', [newPos[0], newPos[1] - nodeOpt.style.normal.fontSize / 2]);
          const nodeEdges = g.nodeEdges(n.nodeKey);
          each(nodeEdges, (ne) => {
            let points = g.edge(ne).points;
            if (n.nodeKey === ne.w) {
              let newPoint = [newPos[0] + this.edgeOffsetX, newPos[1] + this.edgeOffsetY];
              let origPoint = points[points.length - 2];
              let length = Math.sqrt(Math.pow((newPoint[0] - origPoint.x), 2) + Math.pow((newPoint[1] - origPoint.y), 2));
              let percent = (length - circleSize) / length;
              let newX = origPoint.x + (newPoint[0] - origPoint.x) * percent;
              let newY = origPoint.y + (newPoint[1] - origPoint.y) * percent;
              g.setEdge(ne.v, ne.w, {
                lineWidth: g.edge(ne).lineWidth, label: g.edge(ne).label, points: points.map((o, i) => {
                  if (i > points.length - 2) {
                    return { x: newX, y: newY };
                  }
                  return o;
                })
              });
            } else if (n.nodeKey === ne.v) {
              g.setEdge(ne.v, ne.w, {
                lineWidth: g.edge(ne).lineWidth, label: g.edge(ne).label, points: points.map((o, i) => {
                  if (i < 1) {
                    return { x: newPos[0] + this.edgeOffsetX, y: newPos[1] + this.edgeOffsetY };
                  }
                  return o;
                })
              });
            }
          });
          renderLinks.call(this, model, global, g, this.offsetWidth, this.offsetHeight, data.edges);
        }
      });
      global.on('mouseup', () => {
        drag = false;
      });
    });

    // 渲染连线
    each(g.edges(), (e, i) => {
      let originPoints = g.edge(e).points;
      let numberCategory = uniqueArray(this.categoryArr).length;
      if (numberCategory < 3) {
        if (originPoints.length === 3) {
          originPoints.splice(1, 1);
        }
      } else {
        // eslint-disable-next-line no-inner-declarations
        function shortLength() {
          if (originPoints.length > 2) {
            for (let i = 0; i < originPoints.length - 1; i += 1) {
              if (i < originPoints.length - 2) {
                if (originPoints[i].x === originPoints[i + 1].x && originPoints[i + 1].x === originPoints[i + 2].x) {
                  originPoints.splice(i + 1, 1);
                  shortLength();
                }
              }
            }
          }
        }
        shortLength();
      }
    });
    renderLinks.call(this, model, global, g, this.offsetWidth, this.offsetHeight, data.edges);
  }
}

function strlen(str){
  var len = 0;
  for (var i=0; i<str.length; i++) {
    var c = str.charCodeAt(i);
    //单字节加1
    if ((c >= 0x0001 && c <= 0x007e) || (0xff60<=c && c<=0xff9f)) {
      len++;
    }else {
      len+=2;
    }
  }
  return len;
}

// 缩放功能
function initScale(model, limit) {
    this.group.scale = [1, 1];
    this.group.position = [0, 0];
    const rect = this.group.getBoundingRect();
    let scaleW = rect.width / w;
    let scaleH = rect.height / h;
    let finalScale = Math.max(scaleW, scaleH) * 1.1;
    let originParam = (1 / finalScale - 1) / 0.1;
    let scaleParam;
    if (limit) {
        scaleParam = Math.min(originParam, -1);
    } else {
        scaleParam = originParam;
    }
    let offsetX = w / 2;
    let offsetY = h / 2;
    if (model.option.isTop) {
        offsetY = 0;
        this.group.position[1] = -rect.y + 10;
    }
    this.scale(offsetX, offsetY, scaleParam);
}

// 连线计算重绘
function renderLinks(model, global, g, offsetWidth, offsetHeight, data) {
    let linkOpt = model.get('link');
    let roamOpt = model.get('roam');
    let attrs = [];
    let attrs2 = [];
    let attrs3 = [];

    function calcuTextPos(pointArray) {
        if (pointArray.length > 2) {
            let midX, midY;
            for (let i = 0; i < pointArray.length; i += 1) {
                midX = (pointArray[pointArray.length - 3][0] + pointArray[pointArray.length - 2][0]) / 2;
                midY = (pointArray[pointArray.length - 3][1] + pointArray[pointArray.length - 2][1]) / 2;
            }
            return [midX, midY];
        } else {
            let midX = (pointArray[0][0] + pointArray[1][0]) / 2;
            let midY = (pointArray[0][1] + pointArray[1][1]) / 2;
            return [midX, midY];
        }
    }

    function getEdageText(data, label) {
        if (linkOpt.label.type === 'text') {
            return label;
        } else {
            if (data.weight) {
                return data.weight + '%';
            } else if (data.label) {
                return data.label;
            } else {
                return '';
            }
        }
    }

    function getEdgeDash(label) {
        if (label) {
            return [5, 5];
        } else {
            return [0, 0];
        }
    }

    function fillArrow(label) {
        if (label) {
            return null;
        } else {
            return linkOpt.style.normal.stroke;
        }
    }

    function getLabelBg(weight) {
        if (weight) {
            return linkOpt.label.fontSize;
        } else {
            return 0;
        }
    }

    function getLineColor(data) {
        if (data.label) {
            return linkOpt.style.normal.dashFill;
        } else {
            return linkOpt.style.normal.stroke;
        }
    }

    each(g.edges(), (e, i) => {
        const lineWidth = Math.max(linkOpt.lineWidth[0], g.edge(e).lineWidth * linkOpt.lineWidth[1]);
        let points = g.edge(e).points;
        let point1 = [points[points.length - 2].x + this.offsetWidth / 2, points[points.length - 2].y + this.offsetHeight / 2];
        let point2 = [points[points.length - 1].x + this.offsetWidth / 2, points[points.length - 1].y + this.offsetHeight / 2];
        const arrowSize = Math.max(linkOpt.arrowSize[0], g.edge(e).lineWidth * linkOpt.arrowSize[1]);
        const lineLength = Math.sqrt(Math.pow((point2[0] - point1[0]), 2) + Math.pow((point2[1] - point1[1]), 2))
        const percent = (lineLength - arrowSize) / lineLength;

        let pointArr = points.map((p, i) => {
            if (i < points.length - 1) {
                return [p.x + this.offsetWidth / 2, p.y + this.offsetHeight / 2];
            } else {
                return [point1[0] + (point2[0] - point1[0]) * percent, point1[1] + (point2[1] - point1[1]) * percent];
            }
        });
        attrs.push({
            shape: {
                points: pointArr
            },
            style: {
                ...linkOpt.style.normal,
                stroke: getLineColor(data[i].data),
                lineWidth: lineWidth,
                lineDash: getEdgeDash(data[i].data.label)
            },
            edgeData: data[i],
            z: model.get('z'),
            z2: 880
        })
        let arrowAngle;
        if ((point2[1] - point1[1]) >= 0) {
            arrowAngle = Math.asin((point2[0] - point1[0]) / lineLength);
        } else if ((point2[1] - point1[1]) < 0 && (point2[0] - point1[0]) < 0) {
            arrowAngle = - Math.PI / 2 + Math.asin((point2[1] - point1[1]) / lineLength);
        } else if ((point2[1] - point1[1]) < 0 && (point2[0] - point1[0]) >= 0) {
            arrowAngle = Math.PI / 2 + Math.asin((point1[1] - point2[1]) / lineLength);
        }
        attrs2.push({
            shape: {
                points: [point2, [point2[0] + arrowSize, point2[1] - arrowSize], [point2[0] - arrowSize, point2[1] - arrowSize]]
            },
            style: {
                fill: fillArrow(data[i].data.label)
            },
            z: model.get('z'),
            z2: 880,
            rotation: arrowAngle,
            origin: point2
        });
        attrs3.push({
            style: {
                text: getEdageText(data[i].data, g.edge(e).label),
                fontSize: linkOpt.label.fontSize,
                textFill: linkOpt.label.textFill,
                textAlign: 'center'
            },
            position: calcuTextPos(pointArr),
            z: model.get('z'),
            z2: 882,
            silent: true
        });
    });
    this.setShapeGroup('edges', Polyline, attrs, undefined, undefined, (e) => {
        if (!roamOpt.enable) {
            e.cursor = 'default';
        }
        e.on('mousedown', () => {
            global.dispatchAction(model, forceActions.linkClick, e.edgeData);
        });
    });
    this.setShapeGroup('arrows', Polygon, attrs2);
    this.setShapeGroup('edgeLabel', Text, attrs3);
}

