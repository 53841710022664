import { Text, Rect, Polygon } from '../../util/graphic';
import { each, merge, isFunction } from '../../util';
import { interpolateRgb } from 'd3-interpolate';
import { scaleLinear } from 'd3-scale';
import ChartView from '../../view/Chart';
import { TYPE } from './VisualMapModel'; // 使用Model中定义的TYPE，与model的type值保持一致

const throttle = (func, limit) => {
  let inThrottle
  return function() {
    const args = arguments
    const context = this
    if (!inThrottle) {
      func.apply(context, args)
      inThrottle = true
      setTimeout(() => inThrottle = false, limit)
    }
  }
}

export default class VisualMapView extends ChartView {

    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    render(model, globalModel, global) {
        let arrowSize = model.get('arrowSize');
        let min = model.get('min');
        let max = model.get('max');
        let backgroundColor = model.get('backgroundColor');
        let arrowUp = model.get('arrowUp');
        let arrowDown = model.get('arrowDown');
        let x = model.get('left'), 
            y = model.get('top'), 
            width = model.get('itemWidth'), 
            height = model.get('itemHeight'),
            throttleTime = model.get('throttleTime');
        let getFill = (colors) => {
            let colorArr = colors.map((e) => {
                return { offset: e[0], color: e[1] };
            });
            return {
                type: 'linear',
                x: 0,
                y: 1,
                x2: 0,
                y2: 0,
                colorStops: colorArr
            };
        };

        // 高亮的矩形块, 上下空出的矩形，其实都是覆盖在该矩形上的
        this.setShape('VisualMapBar', Rect, {
            shape: { x, y, width, height },
            style: {
                fill: getFill(model.get('inRange').color)
            }
        } ,undefined,(point) => {
            point.on('mousedown',function({offsetY}){
                console.log(offsetY);
                let range = max - min - (offsetY - y) / height * (max - min);
                if(model.range[1] - range >= range - model.range[0]){
                    model.range[0] = range;
                }else{
                    model.range[1] = range;
                }
                model.set('range', model.range);
            });
        });
//---
        let upRectHeight = model.getUpRectHeight(model.range[1], min, max, height);
        this.setShape('upRect', Rect, {
            shape: {
                x: x,
                y: y,
                width: width,
                height: upRectHeight
            },
            style: {
                fill: backgroundColor
            },
            z: model.get('z'),
            z2: 1000
        } ,undefined,(point) => {
            point.on('mousedown',function({offsetY}){
                
                let range = max - min - (offsetY - y) / height * (max - min);
                model.range[1] = range;
                model.set('range', model.range);
            });
        });

        let downRectHeight = model.getDownRectHeight(model.range[0], min, max, height);
        this.setShape('downRect', Rect, {
            shape: {
                x: x,
                y: y + height - downRectHeight,
                // y: y + height - model.range[0] / (max - min) * height,
                width: width,
                height: downRectHeight
                // height: model.range[0] / (max - min) * height
            },
            style: {
                fill: backgroundColor
            },
            z: model.get('z'),
            z2: 1000
        },undefined,(point) => {
            point.on('mousedown',function({offsetY}){
                let range = max - min - (offsetY - y) / height * (max - min);
                model.range[0] = range;
                model.set('range', model.range);
            });
        });

        let textGroup = model.get('text');
        let textStyle = model.get('textStyle');
        let textGap = model.get('textGap');
        let posArr = [[x + width / 2, y], [x + width / 2, y + height]];
        const getTextPos = (i) => {
            return i < 1 ? [posArr[i][0], posArr[i][1] - textGap - textStyle.fontSize] : [posArr[i][0], posArr[i][1] + textGap];
        };
        for (let i = 0; i < textGroup.length; i += 1) {
            this.setShape('VisualMapText' + i, Text, {
                style: {
                    text: textGroup[i],
                    textFill: textStyle.textFill,
                    fontSize: textStyle.fontSize,
                    textAlign: 'middle',
                    ...textStyle
                },
                position: getTextPos(i),
                z: model.get('z'),
                z2: 999
            });
        }

        

        this.setShape('VisualMapArrowUp', Polygon, {
            shape: {
                points: [[0, arrowSize], [arrowSize, 0], [arrowSize, arrowSize]]
            },
            style: {
                fill: model.getColorStop(model.range[1]),
                text: typeof arrowUp.labelFormatter === 'function' ? arrowUp.labelFormatter(model.range[1], model.get('precision')):model.range[1].toFixed(model.get('precision')),
                textPosition: 'right',
                // currentPos: y - arrowSize + height - model.range[1],
                ... arrowUp.style
            },
            position: [x + width, y+upRectHeight-arrowSize],
            // position: [x + width, y - arrowSize + height - model.range[1] / (max - min) * height],
            z: model.get('z'),
            z2: 998
        }, undefined, (point) => {
            let bool = false;
            point.on('mousedown', () => {
                bool = true;
            });

            function dragUp({ offsetY }){
                if (bool) {
                    model.range[1] = max - min - (offsetY - y + arrowSize) / height * (max - min);
                    if (model.range[1] >= max) {
                        model.range[1] = max;
                    }
                    if (model.range[1] <= model.range[0]) {
                        model.range[1] = model.range[0];
                    }

                    model.set('range', model.range);
                    global.dispatchAction(model, model.get('eventKey'), { up: model.range[1], down: model.range[0] });
                }
            }

            // if (throttleTime) {
            //     global.on('mousemove', throttle(dragUp, throttleTime));
            // }else{
            global.on('mousemove', dragUp);
            // }

            global.on('mouseup', () => {
                bool = false;
            });
        });

        this.setShape('VisualMapArrowDown', Polygon, {
            shape: {
                points: [[0, 0], [arrowSize, 0], [arrowSize, arrowSize]]
            },
            style: {
                fill: model.getColorStop(model.range[0]),
                text: typeof arrowDown.labelFormatter === 'function' ? arrowDown.labelFormatter(model.range[0], model.get('precision')): model.range[0].toFixed(model.get('precision')),
                textPosition: 'right',
                // currentPos: y + height - model.range[0],
                ... arrowDown.style
            },
            position: [x + width, y + height - downRectHeight],
            z: model.get('z'),
            z2: 998
        }, undefined, (point) => {
            let bool = false;
            point.on('mousedown', () => {
                bool = true;
            });
            function dragDown({ offsetY }) {
                if (bool) {
                    model.range[0] = (height - (offsetY - y)) / height * (max - min);
                    if (model.range[0] <= 0) {
                        model.range[0] = min;
                    } else if (model.range[0] >= model.range[1]) {
                        model.range[0] = model.range[1];
                    }
                    model.set('range', model.range);
                    global.dispatchAction(model, model.get('eventKey'), { up: model.range[1], down: model.range[0] });
                }
            }

            // if (throttleTime) {
            //     global.on('mousemove', throttle(dragDown, throttleTime));
            // }else{
                global.on('mousemove', dragDown);
            // }
            global.on('mouseup', () => {
                bool = false;
            });
        });
    }
}
