/**
 * 发散的折线填充的圆弧
 */
import { Path } from '../util/graphic';

var PI = Math.PI;
var cos = Math.cos;
var sin = Math.sin;

export default Path.extend({

    type: 'arcline',

    shape: {
        cx: 0,
        cy: 0,
        r: 0,
        r1: 0,
        endAngle: -PI / 2,
        startAngle: -PI / 2,
        edgePadding: 1
    },
    style: {
        stroke: '#000',

        fill: null
    },

    buildPath: function (ctx, shape) {

        var cx0 = shape.cx
        var cy0 = shape.cy
        var earc = shape.endAngle
        var sarc = shape.startAngle
        var edgePadding = shape.edgePadding
        var cx1 = cx0
        var cy1 = cy0 * 0.97 >> 0
        var r0 = shape.r
        var r1 = shape.r1 || r0 * 0.8 >> 0
        var n = earc / 4 / PI * 360 / edgePadding

        function getPoint(c1, c2, radius, angle) {
            return [c1 + cos(angle) * radius, c2 + sin(angle) * radius]
        }

        for (var i = 0; i < n; i++) {
            var ii0 = getPoint(cx0, cy0, r0, earc / n * i + sarc)
            var ii1 = getPoint(cx1, cy1, r1, earc / n * i + sarc)

            ctx.moveTo.apply(ctx, ii0)
            ctx.lineTo.apply(ctx, ii1)
        }

    }
});
