// import { map } from '../../util';

export function reversePointPos(argument) {

}
export function getPointPos(data, name, scale, seriesData){
    let result, temp;
    let val = data[name];
    let bandwidth = typeof scale.bandwidth === 'function' ? scale.bandwidth() : 0;

    switch(data[name+'Type']){
        case 'coordMiddle':
            result = Math.min(scale.range()[0], scale.range()[1]) + Math.abs(scale.range()[0] - scale.range()[1])/2;
            break;
        case 'coordStart':
            result = Math.min(scale.range()[0], scale.range()[1]);
            break;
        case 'coordEnd':
            result = Math.max(scale.range()[0], scale.range()[1]);
            break;
        case 'coord':
            result = val;
            break;
        case 'value':
            result = scale(val);
            break;
        case 'bandValue':
            result = scale(val) + bandwidth/2;
            break;
        // 第几个, 只对非线性坐标轴有效, 一般: x轴可以配，y轴不能配
        case 'number':
            // eslint-disable-next-line no-case-declarations
            let d = scale.domain()[val];
            result = scale(d);
            break;
        // type 和index 处理一样
        // 也需要定义 fx fy 再定位
        case 'type':
        case 'index':
            if (seriesData) {
                temp = data['f'+name](seriesData);
                result = scale(temp);
            }
            break;
        default:
            break;

    }

    if (typeof data[name+'Formatter'] === 'function') {
        return data[name+'Formatter'](result, {
            bandwidth: bandwidth
        });
    } else {
        return result;
    }
}

// export function transformToAxis(points, xAxis, yAxis) {
//     return map(points, point => transformToAxis1(point, xAxis, yAxis))
// }

// export function transformToPX(points, xAxis, yAxis, inCenter) {
//     return map(points, point => transformToPX1(point, xAxis, yAxis, inCenter))
// }

// export function transformToAxis1(point, xAxis, yAxis) {
//     return [xxx(xAxis, point), xxx(yAxis, point)];
// }

// export function transformToPX1(point, xAxis, yAxis, inCenter) {
//     return [yyy(xAxis, point, inCenter), yyy(yAxis, point, inCenter)];
// }

// function xxx(axis, point) {
//     if (axis.getAxisType() === 'ordinal') {
//         return axis.getIndexByOffset(point[0], point[1]) + axis.startIndex;
//     } else {
//         return axis.scale.invert(point[['bottom', 'top'].indexOf(axis.get('position')) > -1 ? 0 : 1]);
//     }
// }

// function yyy(axis, point, inCenter) {
//     let bottomOrTop = ['bottom', 'top'].indexOf(axis.get('position')) > -1;

//     if (axis.getAxisType() === 'ordinal') {
//         let p = axis.scale(axis.scale.domain()[point[bottomOrTop ? 0 : 1] - axis.startIndex]);

//         if (inCenter && axis.get('type') === 'band') {
//             p += axis.scale.bandwidth() / 2;
//         }

//         return p;
//     } else {
//         return axis.scale(point[bottomOrTop ? 0 : 1]);
//     }
// }
