/**
 * 特效线(飞线) by wjw
 */
import { colorTool } from '../../util/graphic';
import SeriesModel from '../../model/Series';
import { each, isString, isArray } from '../../util';

export const TYPE = 'lines';


export default class LinesModel extends SeriesModel {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    static defaultOption = {
        //data: undefined, // {Array} 系列中的数据内容数组。数组项通常为具体的数据项。数据项格式同echarts类似，http://echarts.baidu.com/option.html#series-lines.data
        $dataIndex: undefined, // {number} 依赖的$data的index，格式同上，优先级低于data，取得的数据需符合data的格式
        dataKey: undefined, // {string|number} 搭配$dataIndex使用，方便从依赖的$data中取数据，等同于 data.map((d) => d[dataKey])，最终取得的数据需符合data的格式
        $geoIndex: 0, // {Array} 所依赖grid的index，
        name: undefined, // {string} 系列名称，用于tooltip的显示，legend 的图例筛选

        // 线特效标记的配置
        effectSymbol: {
            show: false, // {boolean} 是否显示
            type: 'circle', // {string} 特效图形的标记。
            size: 3, // {number} 特效标记的大小
            style: { // {Object} 样式

            },
            period: 4000, // {number} 特效动画的时间，单位为 ms。
            delay: 0, // {number} 特效动画的延时，支持设置成数字或者回调。单位ms
            trailLength: 0.2, // {number} 特效尾迹的长度。取从 0 到 1 的值，数值越大尾迹越长。
            loop: true // {boolean} 是否循环显示特效。
        },

        // 线配置
        line: {
            show: true, // {boolean} 是否显示
            curveness: 0, // {number} 边的曲度，支持从 0 到 1 的值，值越大曲度越大。
            style: { // {Object} 样式

            }
        },

        // 线末尾标记配置
        endSymbol: {
            show: false, // {boolean} 是否显示
            type: 'arrow', // {string} symbol类型
            size: 10, // {number} symbol大小
            style: { // {Object} 样式

            }
        },
        
        zlevel: 0, // canvas层级
        z: 9 // 同一层canvas内层级
    };
}