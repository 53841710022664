import ComponentView from '../../view/Component';
import { TYPE } from './TitleModel';
import { Text, setHoverStyle } from '../../util/graphic';
import { merge } from '../../util';
import { titleActions } from '../../action/event';

export default class TitleView extends ComponentView {

    static type = TYPE;

    type = TYPE;

    render(model, globalModel, global) {
        let { left, top, show } = model;

        if (show) {
            let data = model.getData();
            let text = model.get('text') || (data && data.text || data);
            let link = model.get('link');
            let target = model.get('target');
            let style = model.get('style');
            let rotation = model.get('rotation');
            let cursor = model.get('cursor');

            this.setShape('title', Text, globalModel.getFormattedText({
                position: [left, top],
                style: {
                    text,
                    ...style.normal
                },
                rechHover: true,
                rotation,
                cursor
            }), undefined, function (shape) {
                setHoverStyle(shape, globalModel.getFormattedText(merge(style.emphasis, style.normal)));
                shape.on('click', function (event) {
                    global.dispatchAction(model, titleActions.titleClick, { e:event });

                    if (link) {
                        window.open(link, '_' + model.get('target'));
                    }
                });

            });
        }
    }
}