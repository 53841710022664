import { merge, clone } from 'zrender/src/core/util';
import { enableClassManagement } from '../util/enableClassManagement'

import { isInWXApp } from '../util/track'

import { functionNameValidate, getHqCallbackName, getLastestTime } from './helper'
import { dataProviderActions } from '../action/event'
// 如果开启chrome debugger，抓取jsonp的请求会变得很耗时
// 关闭chrome debugger 即可恢复正常
import fetchJsonp from 'fetch-jsonp'

import { dataVersion, dataProtocol } from './configs'

export * from './helper';
export * from './configs'

// 获取唯一id
// 但在ie10以下使用静态函数并且继承有问题
export function getDataId(opt, type){
    return type+'__'+(opt.dType || 'common')+'__'+functionNameValidate(opt.code);
}

// 自定义行情接口数据处理
export function registerDataProvider(DataProvider_) {
    DataProvider.registerClass(DataProvider_, DataProvider_.type);
}

/**
 * 数据处理的基础类, 所有数据处理类需要继承该类
 *
 * 子类中需要注意的:
 * 需要把整理后的数据用 this.fetchSuccess(data) 通知出去
 * !!子类中不可以直接对this._data 进行赋值
 * 举例：自己实现一个数据整理类
 * class FsHistory extends D3Charts.DataProvider{
 *      // 启动的方法名
 *      init(){
 *          setTimeout(()=> {
 *              // 获取数据后，用fetchSuccess传递结果，失败可以用fetchFail
 *              this.fetchSuccess({'data':'123'})
 *          },1000)
 *      }
 *  }
 *  // 数据整理类的唯一标识
 *  FsHistory.type = 'fsHistory';
 *
 * @class DataProvider
 * @mixes enableClassManagement
 * @param {Object} option
 * @param {Object} dataPool 数据池管理实例
 */
class DataProvider{
    static type = 'dataProvider'

    static defaultOption = {
        code:'hs_300033',
        // 获取方式
        // fetchType: 'jqXhrWithoutCallback',
        // 接口版本
        version: dataVersion,
        // 类型再细分
        dType: 'common',
        protocol: dataProtocol,
        // 是否持续更新
        isKeepingGet: false,
        // 更新频率: 重复获取data的间隔时间(毫秒)
        // 行情服务器更新频率是1min :20160405
        intervalTime: 60000
    }

    constructor(option, dataPool){
        this.dataPool = dataPool;
        var defaultOption = merge(clone(this.constructor.defaultOption), DataProvider.defaultOption);
        this.option = merge(option, defaultOption);

        // dataId 在dataPool中是唯一标识
        // {string} 'klineLast__bfqDay__hs_300033';
        this.dataId = getDataId(this.option, this.constructor.type);

        // fetch请求状态 !!请使用fetchStatus.code判断状态，不要用 fetchStatus.msg
        // 000 为正常运行
        // 001 isPending
        // 002 error
        this.fetchStatus;

        this._data;
    }

    // 集中处理回调函数名，港股需要替换成lastdefer.js 这样的
    getHqCallbackNameAndUrl(url, definedName){
        var filterUrl = this.dataPool.getFilterUrl(url);
        var callbackName = getHqCallbackName(filterUrl, definedName);
        return {
            url: filterUrl,
            callbackName: callbackName
        }
    }

    fetchRemote(url, opt, type='fetchJsonpNoCallback'){
        let optMerge = merge(opt, {
            cache: true,
            jsonpCallback: null,
            jsonpCallbackFunction: undefined,
            timeout: 9000
        });

        if(isInWXApp){
            let cbName = optMerge.jsonpCallbackFunction;
            return wx.pro.request({
                url: url,
                dataType: 'json'
            }).then(d=>{
                    if (d.data) {

                        let str = d.data.split(cbName+'(')[1].split(')')[0]
                        var data = JSON.parse(str);
                        console.log(data);
                        return data;
                    }
                })
        }
        return fetchJsonp(url, optMerge).then(response=>response.json())
    }

    getFetchStatus(name, failMsg){
        let r = {};
        switch(name){
            case 'success':
                r = { code: '000', msg: name }
                break;
            case 'isPending':
                r = { code: '001', msg: name }
                break;
            case 'fail':
                r = { code: '002', msg: failMsg || name }
                break;
            default:
                r = { code: '000', msg: 'success' }
                break;
        }
        r.tip = '提示:请勿覆盖data下数据任意字段!!';
        r.dataId = this.dataId;
        return r;
    }

    /**
     * 初始化 子类中实现
     * @abstract
     * @returns {boolean}
     */
    init() {
        console.warn('Must be implemented by subclass!');
    }

    update(){
        this.fetchPending();
        var that = this;

        if(this.dataPool._status.lazyInit){
            // 为了等待 dataPool setStatus配置完成
            setTimeout(function(){
                that.init();
            });
        }else{
            this.init();
        }
    }

    /**
     * 获取option 避免在外面直接赋值修改
     * @param {string}  option的属性名, 不传则返回整个
     * @returns {Object}
     */
    get(key) {
        if (key) {
            return this.option[key];
        }
        return this.option;
    }

    /**
     * 获取 DataProvider 所有事件
     * @return {Object} 键值对
     */
    getAllActions() {
        return dataProviderActions;
    }

    /**
     * 通知数据池 异步获取成功
     * 由dataPool把这个dataId和处理后的值通知出去
     * @protected
     */
    fetchSuccess(data) {
        this._data = data;
        var dataPool = this.dataPool;
        this.fetchStatus = this.getFetchStatus('success');
        dataPool.triggerAction(this, dataProviderActions.update, {
            data: this._data,
            fetchStatus: this.fetchStatus
        });
    }

    /**
     * 通知数据池 异步获取失败
     * 由dataPool把这个dataId和处理后的值通知出去
     * @protected
     */
    fetchFail(errorInfo, data) {
        this._data = data;
        var dataPool = this.dataPool;
        var msg = errorInfo.message || 'error'
        this.fetchStatus = this.getFetchStatus('fail', msg);
        dataPool.triggerAction(this, dataProviderActions.update, {
            data: this._data,
            fetchStatus: this.fetchStatus
        });
    }

    /**
     * 通知数据池 异步获取等待中
     * 由dataPool把这个dataId和处理后的值通知出去
     * @protected
     */
    fetchPending() {
        var dataPool = this.dataPool;
        this.fetchStatus = this.getFetchStatus('isPending');
        dataPool.triggerAction(this, dataProviderActions.update, {
            data: undefined,
            fetchStatus: this.fetchStatus
        });
    }

    // 清除实时更新的定时器
    // 子类如果有实时更新功能，需要在子类做实时更新的函数的返回值(句柄)传给 this.keepingGetHandle
    stopGetData () {
        this.keepingGetHandle && clearTimeout(this.keepingGetHandle);
    }

    /**
     * 获取整理好的data
     * @returns {object} 处理后的data
     */
    getData() {
        return this._data;
    }
}

export default enableClassManagement(DataProvider);
