import ComponentView from '../../view/Component';
import { each, isArray } from '../../util';
import { TYPE } from './AxisPointerModel';
import { axisActions, axisPointerActions } from '../../action/event';
import { Line, subPixelOptimizeRect, subPixelOptimizeLine, Text, Rect } from '../../util/graphic';
import { bisector } from 'd3-array';

let bisectX = function (arr, value) {
    let i;

    for (i = 0; i < arr.length; i++) {
        if (arr[i] === null) {
            continue;
        } else {
            if (value < arr[i][0]) {
                break;
            }
        }
    }

    return i;
};
let bisectY = bisector(function (d) {
    return d && -d[1];
}).right;

export default class AxisPointerView extends ComponentView {
    static type = TYPE;

    type = TYPE;

    constructor(model, globalModel, global) {
        super(...arguments);

        this._initEvent(model, globalModel, global);
    }

    _initEvent(model, globalModel, global) {
        let axisIndex = model.get('$axisIndex');
        let axisPointerIndex = model.get('$axisPointerIndex');
        let axisPointerModel = globalModel.getComponentByIndex('axisPointer', axisPointerIndex);
        let axisModels = [];

        if (!isArray(axisIndex)) {
            axisIndex = [axisIndex];
        }

        each(axisIndex, function (index) {
            axisModels.push(globalModel.getComponentByIndex('axis', index));
        });

        const hideShape = () => {
            this.eachShape(function (shape) {
                shape.hide();
            });
        }

        if (axisPointerModel) {
            global.registerAction(axisPointerModel, axisPointerActions.move, ({ offsetX, offsetY, dataIndex, axisDataIndex, axisData, pos }) => {
                let enable = model.get('enable');

                if (enable) {
                    let seriesModel = globalModel.getComponentByIndex('series', model.get('$seriesIndex'));
                    let data = seriesModel.getRealData(dataIndex);

                    if (data) {
                        let value = data[1];
                        let valueIndex = model.get('valueIndex');

                        each(axisModels, (axisModel) => {
                            let yValue = getAxisValue(axisModel, value, valueIndex);
                            if(axisModel.scale){
                                let y = axisModel.scale(yValue);
                                this.movePointer(model, axisModel, globalModel, { offsetY: y, axisDataIndex, axisData: value });
                            }
                        });
                    }
                } else {
                    hideShape();
                }
            });

            global.registerAction(axisPointerModel, axisPointerActions.out, () => {
                hideShape();
            });
        } else {
            each(axisModels, axisModel => {
                global.registerAction(
                    axisModel,
                    axisActions.move,
                    ({
                        e: { offsetX, offsetY } = {},
                        index: axisDataIndex,
                        dataIndex: dataIndex,
                        axisData,
                        pos
                    }) => {
                        let enable = model.get('enable');

                        if (enable) {
                            global.dispatchAction(model, axisPointerActions.before, { offsetX, offsetY, axisDataIndex, axisData, pos, dataIndex });
                            this.movePointer(model, axisModel, globalModel, { offsetX, offsetY, axisDataIndex, axisData, pos });
                            global.dispatchAction(model, axisPointerActions.move, { offsetX, offsetY, axisDataIndex, axisData, pos, dataIndex });
                        } else {
                            hideShape();
                        }
                    }
                );

                global.registerAction(axisModel, axisActions.out, (e) => {
                  global.dispatchAction(model, axisPointerActions.after, e);
                    hideShape();
                    global.dispatchAction(model, axisPointerActions.out);
                });
            });
        }
    }

    /**
     * 更新位置
     */
    movePointer(model, axisModel, globalModel, { offsetX, offsetY, axisDataIndex, axisData, pos }) {
        let gridModels = axisModel.getGridModels();
        let position = axisModel.get('position');
        let isHorizontal =
            position === 'bottom' || position === 'top';
        let lineOption = model.get('line');
        let shadowOption = model.get('shadow');
        let labelOption = model.get('label');
        let seriesLabelOpt = model.get('seriesLabel');
        let seriesLabFormatter = seriesLabelOpt.formatter;
        let seriesIndex = model.seriesIndex;
        let z = model.get('z');
        let zlevel = model.get('zlevel');
        let seriesLabelAttr = [];

      const gridModel = gridModels[0]

      if (offsetX && 'ontouchstart' in window && gridModel.get('reverse')) {
        let temp = offsetX
        let gridPosition = gridModel.position
        offsetX = offsetY
        offsetY = gridPosition.bottom + gridPosition.top - temp
      }

        if (seriesLabelOpt.show && axisModel.get('xOrY') === 'x') {
            each(seriesIndex, function (index_) {
                let seriesModel = globalModel.getComponentByIndex(
                    'series',
                    index_
                );

                if (
                    seriesModel &&
                    seriesModel.get('selected') !== false
                ) {
                    let points = seriesModel.points;

                    if (!points.length) return;

                    let index = isHorizontal
                        ? bisectX(points, offsetX)
                        : bisectY(points, -offsetY);

                    index--;

                    if (points[index]) {
                        let position;

                        if (isHorizontal) {
                            position = [
                                offsetX + seriesLabelOpt.gap,
                                points[index][1]
                            ];
                        } else {
                            position = [
                                points[index][0] +
                                seriesLabelOpt.gap,
                                offsetY
                            ];
                        }

                        seriesLabelAttr.push(
                            globalModel.getFormattedText({
                                style: {
                                    text: seriesLabFormatter(
                                        points[index][2][1]
                                    ),
                                    ...seriesLabelOpt.style
                                },
                                position: position,
                                key: index_,
                                zlevel,
                                z,
                                silent: true
                            })
                        );
                    }
                }
            });

            this.setShapeGroup(
                'seriesLabel',
                Text,
                seriesLabelAttr
            ).show();
        }

        if (axisModel.get('xOrY') === 'y') {
            axisData = getAxisValue(axisModel, axisData, model.get('valueIndex'));
        }

        each(gridModels, (gridModel, index) => {
            let gridPosition = gridModel.position;
            let { top, bottom, left, right } = gridPosition;

            // line计算
            if (lineOption.show) {
                let lineShape = {};

                if (typeof pos === 'undefined') {
                    if (isHorizontal) {
                        lineShape = {
                            y1: top - lineOption.topExtend,
                            y2: bottom,
                            x1: offsetX,
                            x2: offsetX
                        };
                    } else {
                        lineShape = {
                            x1: left,
                            x2: right,
                            y1: offsetY,
                            y2: offsetY
                        };
                    }
                } else {
                    if (isHorizontal) {
                        lineShape = {
                            y1: top - lineOption.topExtend,
                            y2: bottom,
                            x1: pos,
                            x2: pos
                        };
                    } else {
                        lineShape = {
                            x1: left,
                            x2: right,
                            y1: pos,
                            y2: pos
                        };
                    }
                }

                this.setShape(
                    `line_${axisModel.index}_${gridModel.index}`,
                    Line,
                    subPixelOptimizeLine({
                        shape: lineShape,
                        style: lineOption.style,
                        zlevel,
                        z,
                        silent: true
                    })
                ).show();
            }

            // shadow计算
            if (
                shadowOption.show &&
                axisModel.get('type') === 'band'
            ) {
                let shadowShape = {};
                let step = axisModel.scale.step();
                let halfInnerWidth =
                    axisModel.scale.paddingInner() * step / 2;
                let outerWidth =
                    axisModel.scale.paddingOuter() * step;

                if (isHorizontal) {
                    shadowShape = {
                        x: pos - step / 2,
                        y: top,
                        width: step,
                        height: bottom - top
                    };

                    if (axisDataIndex === 0) {
                        if (halfInnerWidth > outerWidth) {
                            shadowShape.x +=
                                halfInnerWidth - outerWidth;
                            shadowShape.width -=
                                halfInnerWidth - outerWidth;
                        }
                    } else if (
                        axisDataIndex ===
                        axisModel.getData().length - 1
                    ) {
                        if (halfInnerWidth > outerWidth) {
                            shadowShape.width -=
                                halfInnerWidth - outerWidth;
                        }
                    }
                } else {
                    shadowShape = {
                        x: left,
                        y: pos - step / 2,
                        width: right - left,
                        height: step
                    };

                    if (axisDataIndex === 0) {
                        if (halfInnerWidth > outerWidth) {
                            shadowShape.height -=
                                halfInnerWidth - outerWidth;
                        }
                    } else if (
                        axisDataIndex ===
                        axisModel.getData().length - 1
                    ) {
                        if (halfInnerWidth > outerWidth) {
                            shadowShape.y +=
                                halfInnerWidth - outerWidth;
                            shadowShape.height -=
                                halfInnerWidth - outerWidth;
                        }
                    }
                }

                this.setShape(
                    `shadow_${axisModel.index}_${gridModel.index}`,
                    Rect,
                    subPixelOptimizeRect({
                        shape: shadowShape,
                        style: shadowOption.style,
                        zlevel,
                        z,
                        silent: true
                    })
                ).show();
            }

            // label计算
            if (
                labelOption.show === 'all' ||
                (labelOption.show && index === 0)
            ) {
                let axisLabelOption = axisModel.get('label');
                let labelInside = labelOption.inside;
                let labelStyle = typeof labelOption.style === 'function' ? labelOption.style(axisData) : labelOption.style;
                let labelGap = labelOption.gap === undefined ? axisLabelOption.padding : labelOption.gap;
                let labelFormatter =
                    labelOption.formatter ||
                    axisModel.get('label').formatter ||
                    function (d) {
                        if (d && d.toFixed) {
                            return d.toFixed(2);
                        } else {
                            return d;
                        }
                    };
                let _labelStyle = {};
                let _position = [];

                switch (position) {
                    case 'bottom':
                        _position[1] = axisModel.pos + (labelInside ? -labelGap : labelGap);
                        _labelStyle = {
                            textVerticalAlign: labelInside ? 'bottom' : 'top',
                            textAlign: 'center'
                        };
                        break;
                    case 'top':
                        _position[1] = axisModel.pos - (labelInside ? -labelGap : labelGap);
                        _labelStyle = {
                            textVerticalAlign: labelInside ? 'top' : 'bottom',
                            textAlign: 'center'
                        };
                        break;
                    case 'right':
                        _position[0] = axisModel.pos + (labelInside ? -labelGap : labelGap);
                        _labelStyle = {
                            textVerticalAlign: 'middle',
                            textAlign: labelInside ? 'right' : 'left'
                        };
                        break;
                    case 'left':
                        _position[0] = axisModel.pos - (labelInside ? -labelGap : labelGap);
                        _labelStyle = {
                            textVerticalAlign: 'middle',
                            textAlign: labelInside ? 'left' : 'right'
                        };
                        break;
                    default:
                        break;
                }

                if (typeof pos === 'undefined') {
                    if (isHorizontal) {
                        pos = offsetX;
                    } else {
                        pos = offsetY;
                    }
                }

                if (isHorizontal) {
                    _position[0] = pos;
                } else {
                    _position[1] = pos;
                }

                let other = {};

                axisModel.get('color') &&
                    (other.textBackgroundColor = axisModel.get(
                        'color'
                    ));

                let label = this.setShape(
                    `label_${axisModel.index}_${gridModel.index}`,
                    Text,
                    globalModel.getFormattedText({
                        style: {
                            text: labelFormatter
                                ? labelFormatter(axisData)
                                : axisData,
                            ..._labelStyle,
                            ...axisLabelOption.style,
                            ...labelStyle,
                            ...other
                        },
                        position: _position,
                        rotation:
                            (labelOption.rotate === undefined ? axisLabelOption.rotate : labelOption.rotate) / 180 * Math.PI,
                        zlevel,
                        z,
                        z2: 99,
                        silent: true
                    })
                );

                label.show();

                if (labelOption.inRange === undefined ? axisLabelOption.inRange : labelOption.inRange) {
                    let labelRect = label.getBoundingRect();
                    if(axisModel.range){
                        let range = axisModel.range;
                    if (isHorizontal) {
                        let left = range[0] - labelRect.x;
                        let right = range[1] + labelRect.x;

                        _position[0] = Math.max(Math.min(right, pos), left);
                    } else {
                        let top = range[1] - labelRect.y;
                        let bottom = range[0] + labelRect.y;

                        _position[1] = Math.max(Math.min(bottom, pos), top);
                    }
                    label.attr('position', _position);
                }

                }
            }
        });
    }
}

function getAxisValue(axisModel, value, valueIndex = 0) {
    let axisType = axisModel.getAxisType();

    if (axisType === 'ordinal') {
        return axisModel.scale.domain()[value - axisModel.startIndex];
    } else {
        if (isArray(value)) {
            return value[valueIndex];
        } else {
            return value;
        }
    }
}
