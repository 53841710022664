export function functionNameValidate(str) {
    return str.replace(/([\W])/g, function(s0, s1){
        if (s1 === '/' || s1 === ',') {
            return '_';
        }
        return '__';
    });
}

export function getCallbackName(url, host) {
    var key = 'quotebridge';
    var temp = url.split(host);
    var a = temp[1].replace(/(\/)/g,function () {
        return '_';
    });
    a = a.replace(/(.js)/g,function () {
        return '';
    });
    a = functionNameValidate(a);
    return key+'_'+a;
}

// 获取分时最新的更新时间 t
export function getLastestTime(data) {
    let t = data.dataArray[data.dataArray.length-1].t;
    if(t.split(' ').length === 2) {
        t = t.split(' ')[1];
    }
    return t;
}

// 如果有指定的回调函数名，直接返回
export function getHqCallbackName(url, definedName = false) {
    if (definedName) { return definedName}
    var host = '';
    // url = url.split('?')[0]
    // url = url.split('#')[0]
    url.replace(/\/{2}([\w.:]+)\//, function(s0, s1){
        host = s1;
    });
    return getCallbackName(url, host+'/');
}

// bfq day
// 类型未找到, 默认前复权
export function klineTypeConvertToNum({time, fuquan}){
    // days 不复权 00 ，前复权 01 ，后复权 02
    // week 不复权 10 ，前复权 11 ，后复权 12
    // timeStatusCode + fuquanStatusCode 
    // 参照 http://172.20.1.247/wiki/index.php/WEBHQ:k%E7%BA%BF%E6%8E%A5%E5%8F%A3
    // 0=> 日线 
    // 1=> 周线 
    // 2=> 月线 
    // 3=> 五分钟K线 
    // 4=> 三十分钟K线 
    // 5=> 六十分钟K线 
    // 6=> 一分钟K线 
    // 7=> 4小时k线 
    // 8=> 年线 
    // 9=> 季线
    var timeStatus = {
        'day': '0',
        'week': '1',
        'month': '2',
        '5min': '3',
        '30min': '4',
        '60min': '5',
        '1min': '6',
        '4hour': '7',
        'year': '8',
        'season':'9'
    }

    // 表示 不复权 前复权 后复权
    var fuquanStatus = {
        'bfq':'0',
        'qfq':'1',
        'hfq':'2'
    }
    return (timeStatus[time] ? timeStatus[time]: '0') + (fuquanStatus[fuquan] ? fuquanStatus[fuquan]: '1');
}


/**
 * mergeLargeArr
 * For example:
 * destinationArr = ['a', 'b',...,'z']
 * tempArr = [5, 6]
 * return ['a', 'b',...,'z', 5, 6]
 */
export function mergeLargeArr(destinationArr, tempArr) {
    var dn = destinationArr.length;
    var tn = tempArr.length;
    var j = dn;
    if (dn >= tn) {
        for(var i = 0, n = tempArr.length; i < n; i++) destinationArr.push(tempArr[i]);
        return destinationArr;
    }else{
        while(--j >= 0)
        {
            tempArr.unshift(destinationArr[j]);
        }

        return tempArr;
    }

}
