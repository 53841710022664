import { enableClassManagement } from '../../util';

/**
 * Indicator类
 * 
 * @class Indicator
 */
class Indicator{

    /**
     * {string} 组件名type
     */
    static type = 'indicatorBase';

    constructor() {
        
    }
    // 除了 hqbar，line外的自定义图形，需要继承 Path
    // 子类中实现
    getShape(name){
        console.warn(`指标绘图${name}的Shape类未定义,请在指标子类中返回该类`);
    }

    // 自定义图形 的配置
    // 子类中实现
    // 返回值 须包括 shape字段和style字段
    // {
    //     shape:{
    //         x: function (d) { return d[0]; },
    //         y: function (d) { return d[1]; },
    //         data: points
    //     },
    //     style: item.normal.style
    // }
    getDrawData({name, xScale, yScale, data, model}){
        console.warn(`指标绘图${name}的详细配置未定义,请在指标子类中实现`);
    }

}

export default enableClassManagement(Indicator);
