/**
 * 圆环圆饼图 by zp
 */
import SeriesModel from '../../model/Series';
import { filter } from '../../util';

import { color20 } from '../../model/globalDefault';

const TYPE = 'pie';

export default class ArcModel extends SeriesModel {
  static type = TYPE;

  type = TYPE;

  static defaultOption = {
    theme: color20,/*默认颜色配置*/
    center: ['50%', '50%'], /*饼图中心点坐标，支持百分比，一般设置成 ['50%', '50%'] 居中*/
    radius: [50, '30%'], /*饼图半径，支持百分比，依次对应内径、外径、悬停半径值*/
    hoverAnimation: true, /*是否开启悬停动画过渡*/
    avoidLabelOverlap: true, /*是否开启禁止文本覆盖*/
    avoidLabelGap: 6,
    likeAlipay: false, /*是否显示和支付宝一样的风格*/
    itemStyle: {
      shadowRadius: 0,
      shadowColor: '#e4e4e4',
      order: 'nice'
    },
    item: {
      //扇形样式
      normal: {
        style: {

        }
      },
      //扇形悬停样式
      emphasis: {
        style: {
        }
      }
    },
    label: {
      nameFormatter: function() {},
      lineColor: '', // 设置连线颜色，不设置则与文本相同
      position: 'inner', // label 显示位置，可取outer, inner, center，默认为inner
    //   hideList: [0, 2], // 隐藏label的数组，相对于originData的数组下标，position为center时无效
      outerOffset: 0, /* 当 label 的 position 设置为 outer 时生效，为连线距离边缘的像素值*/
      textStyle: {
        // 设置文本颜色，不设置或 auto 则默认跟随扇形颜色，position 为 inner，color 必须设置，不然文本将不可见
        color: ''
      },
      subTextStyle: {
        fontSize: 14,
        textLineHeight: 30
      },
      symbol: {
        show: false,
        size: 5,
        type: 'circle',
        style: {
        }
      }
    },
    // 标题
    title : {
        // x, y，单位px
        offsetCenter: [0, 0],
        textStyle: { // 其余属性默认使用全局文本样式，详见TEXTSTYLE
            color: '#333',
            fontFamily: 'sans-serif',
            fontWeight: 'normal',
            fontSize: 18
        }
    },
  }

  update() {
    let {globalModel} = this;
    let originData = this.getData();

    let values = originData.values

    if (values[0] && values[0][0]) {
      values.forEach(v => {
        v.name = v[0]
        v.value = v[1]
        v.color = v[2]
      })
    }

    let selected = this.get('selected');
    let drawData = filter(originData.values, (v, k) => {
      v.dataIndex = k;
      return !(selected && selected[v.name] === false)
    });
    if (drawData.length >= 0) {
      if (this.get('itemStyle').order == 'nice') {
        drawData.sort(function(a, b) {
          return a.value - b.value
        })
        let maxData = drawData.pop();
        maxData && drawData.splice(0, 0, maxData);
      }
      let {max, min, title} = originData;
      this.drawData = {max, min, title, values: drawData};
    }
  }

  getSeriesColor(name) {

    let theme = this.get('theme')
    let data = this.getData().values
    let dataIndex = data.findIndex(d => d.name == name)

    let itemStyle, color;

    if (arguments.length && this.get('name') !== name) {
      let finded = this.findDataNamed(name);

      if (finded) {
        itemStyle = finded.itemStyle;
        color = finded.color;
      }
    } else {
      itemStyle = this.get('itemStyle');
      color = this.get('color');
    }

    if (color) {
      return color;
    } else if (itemStyle && itemStyle.normal) {
      return itemStyle.normal.stroke || itemStyle.normal.fill;
    } else {
      return theme[dataIndex % theme.length]
    }
  }
}
