import { Text, Circle, formatTextStyle, Line } from '../../util/graphic';
import { each, parseSize, isFunction } from '../../util';
import { Symbol } from '../../util/symbol';
import ChartView from '../../view/Chart';
import D3Link from '../../shape/D3Link';
import * as d3Hierarchy_ from 'd3-hierarchy';
import { TYPE } from './RadialModel'; // 使用Model中定义的TYPE，与model的type值保持一致

import * as d3array from 'd3-array';

export default class RadialView extends ChartView {
    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    render(model, globalModel, global) {
        let width = global.getWidth();
        let height = global.getHeight();
        let that = this;

        let nodeOpt = model.get('node').normal;
        let nodeStyle = nodeOpt.style;
        let nodeSize = nodeOpt.size;
        let nodeType = nodeOpt.type;
        let nodes = model.getData();

        let nodeAttrs = [];
        let edgeAttrs = [];
        let textAttrs = [];
        function radialPoint(x, y) {
            return [(y = +y) * Math.cos(x -= Math.PI / 2), y * Math.sin(x)];
        }

        var tree = d3Hierarchy_.tree()
            .size([2 * Math.PI, Math.min(width, height) / 2.1])
            .separation(function(a, b) { return (a.parent == b.parent ? 1 : 2) / a.depth; });
        var data = d3Hierarchy_.hierarchy(nodes)
        var root;
        let extraLinks = [], loop = 50;
        if (globalModel.root) {
            root = globalModel.root;
        } else {
            root = tree(data);
            let descendants = root.descendants();
            let links = root.links();
            let circleLeaves = root.descendants().filter(function(d) {return d.data.type == 'circle'});
            let depthSize = 0;
            for (let i = 0; i < descendants.length; i++) {
                let item = descendants[i];
                if (item.depth == 1) {
                    depthSize = item.y;
                    break;
                }
            }
            circleLeaves.forEach(function(d) {
                d.children
                    .forEach(function(d) {
                        d.y -= depthSize / 2;
                        d.depth -= .5;
                    })

            })


            for (let i = 0; i < loop; i++) {
                let sourceIndex = descendants.length * Math.random() >> 0;
                let targetIndex = descendants.length * Math.random() >> 0;
                extraLinks.push({
                    type: 'extra',
                    source: descendants[sourceIndex],
                    target: descendants[targetIndex]
                })
            }
            root.extraLinks = extraLinks;

        }
        globalModel.root = root;
        let descendants = root.descendants();
        let links = root.links();

        let realLinks = root.extraLinks.filter(function(d) {
            return descendants.indexOf(d.source) >= 0 && descendants.indexOf(d.target) >= 0;
        })

        descendants.forEach(function(d, i) {

            let _nodeType = isFunction(nodeType) ? nodeType(d, i) : nodeType;
            let _nodeStyle = isFunction(nodeStyle)
                ? nodeStyle(d, i)
                : nodeStyle;
            let pos = radialPoint(d.x, d.y);
            let x = pos[0];
            let y = pos[1];
            let ang = (d.x / Math.PI * 180 + 360) % 360;
            console.log(d);
            let rotate = (d.x + (ang < 180 && ang > 0 ? -0.5 : 0.5)) * Math.PI;

            nodeAttrs.push({
                position: pos,
                shape: {
                    symbolType: _nodeType,
                    width: 6,
                    height: 6,
                    x: -3 + width / 2,
                    y: -3 + height / 2
                },
                style: _nodeStyle,
                node: d,
                data: d.data
            });

            textAttrs.push({
                style: {
                    text: 11111111 || d.data.name
                },
                position: [x + width / 2, y + height / 2],
                rotation: rotate || ang < 180 ? (Math.PI / 2 - d.x) : (-Math.PI / 2 - d.x)
            })

        })

        links.concat(realLinks).forEach(function(d) {
            var source = radialPoint(d.source.x, d.source.y);
            var target = radialPoint(d.target.x, d.target.y);
            var strokeColor;
            if (d.type == 'extra') {
                strokeColor = 'rgba(200, 200, 200, .15)'
            } else if (d.highlight) {
                strokeColor = 'red'
            } else {
                strokeColor = 'rgba(0, 0, 0, .1)'
            }
            edgeAttrs.push({
                style: {
                    stroke: strokeColor,
                    lineWidth: 1
                },
                shape: {
                    x1: source[0] + width / 2,
                    y1: source[1] + height / 2,
                    x2: target[0] + width / 2,
                    y2: target[1] + height / 2
                }
            })
        })

        that.setShapeGroup(
            'edges',
            Line,
            edgeAttrs
        )

        that.setShapeGroup(
            'nodes',
            Symbol,
            nodeAttrs,
            {},
            function(nodeGroup) {
                nodeGroup.on('click', function(e) {
                    let expandAndCollapse = model.get('expandAndCollapse');
                    let { target: { node } } = e;

                    if (expandAndCollapse) {
                        if (node.children) {
                            node._children = node.children;
                            node.children = null;
                            model.dirty();
                        } else if (node._children) {
                            node.children = node._children;
                            model.dirty();
                        }
                    }
                });
                nodeGroup.on('mouseover', function(e) {
                    let { target: { node } } = e;
                    node.links().forEach(function(link) {
                        link.highlight = true;
                    })
                        model.dirty();
                })
            }
        )

        /*that.setShapeGroup(
            'texts',
            Text,
            textAttrs
        )*/
    }
}
