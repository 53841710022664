/**
 * 悬浮窗
 */
import ComponentModel from '../../model/Component';
import { each } from '../../util';

export const TYPE = 'tooltip';

/*

提示框组件。trigger为item时依赖于axis。

组件详细配置项如下，每个配置项都有一个默认值：

*/

export default class TooltipModel extends ComponentModel {
    static type = TYPE;

    type = TYPE;

    // http://echarts.baidu.com/option.html#tooltip
    static defaultOption = {
        $axisIndex: undefined, // {number} 当触发类型为axis时，依赖的axis, 只接受x轴的axisIndex
        trigger: 'item', // {string} 触发类型 'item' | 'axis'
        alwaysShowContent: false, // {boolean} 是否永远显示提示框内容，默认情况下在移出可触发提示框区域后 一定时间 后隐藏，设置为 true 可以保证一直显示提示框内容
        offset: [12, 15], // {Array<number>} 偏移大小 [x, y]
        position: undefined, // {Array|Function} 提示框浮层的位置，默认不设置时位置会跟随鼠标的位置。
        confine: true, // {boolean} 是否将 tooltip 框限制在图表的区域内。
        formatter: undefined, // {Function} 提示框浮层内容格式化函数
        toFixedNumber: 2, // {number} 保留位数，设置formatter后无效
        dataFormatter: undefined, // {Function}  单项data数据格式化，设置formatter后无效, 返回false将不显示
        axisFormatter: d => d, // {Function} function 坐标轴数据格式化
        enterable: false, // {boolean} 鼠标是否可进入提示框浮层中
        showDelay: 0, // {number} 浮层显示的延迟，单位为ms
        hideDelay: 100, // {number} 浮层隐藏的延迟，单位为 ms，在 alwaysShowContent 为 true 的时候无效。
        triggerOn: 'mousemove', // {string} 提示框触发的条件 'mousemove' | 'click'

        // 浮窗的css样式
        style: {
            position: 'absolute',
            // whiteSpace: 'nowrap',
            zIndex: 99999,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            borderColor: '#333',
            borderStyle: 'solid',
            borderWidth: 0,
            borderRadius: '5px',
            padding: '5px',
            color: 'white',
            display: 'none'
            // transition: 'all 0.2s cubic-bezier(0.23, 1, 0.32, 1)'
        }
    };
}
