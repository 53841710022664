import Indicator from '../Indicator';
import { IndicatorFormula } from 'hxc3-indicator-formula';

const Formula = IndicatorFormula.getClass('vr')
let formula = new Formula();

export default class VR extends Indicator{
    static type = 'vr';

    static defaultOption = {
        VR: {
            type: 'line',
            normal:{
                show: true, 
                style:{
                    stroke: '#C00C00', 
                    lineWidth: 1
                }
            },
            emphasis: undefined
        },
        A: {
            type: 'line',
            normal:{
                show: true, 
                style:{
                    lineDash: [2, 4],
                    stroke: 'rgba(204,204,204,0.8)', 
                    lineWidth: 1
                }
            },
            emphasis: undefined
        },
        B: {
            type: 'line',
            normal:{
                show: true, 
                style:{
                    lineDash: [2, 4],
                    lineWidth: 1,
                    stroke: 'rgba(204,204,204,0.8)'
                }
            },
            emphasis: undefined
        }
    }

    static calculate(data){return formula.calculate(data)}

    static formatData(d, i) {
        return [i, [d.VR, d.A, d.B], d];
    }

}