import ComponentView from '../../view/Component';
import { each, isArray } from '../../util';
import { TYPE } from './ZoomBrushModel';
import { Rect, subPixelOptimizeRect, setHoverStyle } from '../../util/graphic';
import { Symbol } from '../../util/symbol';
import D3Line from '../../shape/D3Line';

export default class ZoomBrushView extends ComponentView {

    static type = TYPE;

    type = TYPE;

    constructor(model, globalModel, global) {
        super(...arguments);

        this._initEvent(model, globalModel, global);
    }

    _initEvent(model, globalModel, global) {
        let verticalAxis = [], horizontalAxis = [];
        let gridModel;
        let startX, startY;
        let brushing;
        let startTime;

        global.on('mousedown', (e) => {
            if (!model.get('enable') || global.isEnableEdit || global.isEnableSketch) return;

            let { offsetX, offsetY } = e;
            let axisIndexes = model.get('axisIndex');

            gridModel = globalModel.getComponent('grid', function (gridModel) {
                return gridModel.isPointInGrid(offsetX, offsetY);
            })[0];

            if (gridModel && !brushing) {
                let gridIndex = gridModel.index;

                startTime = new Date();
                verticalAxis = [], horizontalAxis = [];
                brushing = true;
                startX = offsetX;
                startY = offsetY;

                each(axisIndexes, (index) => {
                    let axisModel = globalModel.getComponentByIndex('axis', index);
                    let gridIndex = axisModel.get('$gridIndex');

                    if (~['bottom', 'top'].indexOf(axisModel.get('position'))) {
                        horizontalAxis.push(axisModel);
                    } else {
                        verticalAxis.push(axisModel);
                    }
                });
            }
        });

        global.on('mousemove', (e) => {
            if (!brushing) return;

            let { offsetX, offsetY } = e;
            let style = model.get('style');
            let { left, right, top, bottom, width, height } = gridModel.position;
            let _offsetX = getFixedOffset(offsetX, left, right);
            let _offsetY = getFixedOffset(offsetY, top, bottom);
            let shape;

            if (verticalAxis.length && horizontalAxis.length) {
                shape = {
                    x: startX,
                    y: startY,
                    width: _offsetX - startX,
                    height: _offsetY - startY
                };
            } else if (verticalAxis.length) {
                shape = {
                    x: left,
                    y: startY,
                    width,
                    height: _offsetY - startY
                };
            } else if (horizontalAxis.length) {
                shape = {
                    x: startX,
                    y: top,
                    width: _offsetX - startX,
                    height
                };
            }

            if (shape) {
                this.setShape('brushRect', Rect, {
                    shape,
                    style,
                    z: 999
                });
            }
        });

        global.on('mouseup', (e) => {
            if (brushing) {
                let { offsetX, offsetY } = e;
                let history = [];

                if (new Date() - startTime > 200 && (startX != offsetX || startY != offsetY)) {
                    if (verticalAxis.length) {
                        history = history.concat(zoomAxis('vertical', verticalAxis, gridModel, globalModel, global, e, startX, startY));
                    }
                    if (horizontalAxis.length) {
                        history = history.concat(zoomAxis('horizontal', horizontalAxis, gridModel, globalModel, global, e, startX, startY));
                    }

                    if (history.length) {
                        model.pushHistory(history);
                    }
                }

                this.removeShape('brushRect');

                startTime = undefined;
                brushing = false;
                verticalAxis = [];
                horizontalAxis = [];
                gridModel = undefined;
            }
        });
    }

    render(model, globalModel, global) {
        if (!model.get('show')) return;

        let { left, top } = model;
        let icons = model.get('icon');
        let iconNormalStyle = model.get('iconStyle').normal;
        let iconHoverStyle = model.get('iconStyle').emphasis;
        let enable = model.get('enable');
        let history = model.get('history');

        this.setShape('zoom', Symbol, {
            shape: {
                symbolType: icons['zoom'],
                x: 0,
                y: 0,
                width: 20,
                height: 20
            },
            position: [left, top],
            style: enable ? iconHoverStyle : iconNormalStyle,
            rectHover: true
        }, undefined, shape => {
            shape.on('click', function (e) {
                model.toggleEnable();
                this.setStyle(model.get('enable') ? iconHoverStyle : iconNormalStyle);
            });
        });

        this.setShape('back', Symbol, {
            shape: {
                symbolType: icons['back'],
                x: 35,
                y: 0,
                width: 16,
                height: 20
            },
            position: [left, top],
            style: history.length ? iconHoverStyle : iconNormalStyle,
            rectHover: true
        }, undefined, shape => {
            shape.on('click', function (e) {
                model.popHistory();
            });
        });
    }
}

function zoomAxis(orient, axesModel, gridModel, globalModel, global, e, startX, startY) {
    let { left, bottom, width: gridWidth, height: gridHeight } = gridModel.position;
    let { offsetX, offsetY } = e;
    let history = [];

    each(axesModel, function (axisModel) {
        let dataZoomModel = globalModel.getComponentByIndex('dataZoom', axisModel.get('$dataZoomIndex'));
        let { start, end } = dataZoomModel || axisModel;
        let delta = end - start;
        let _start, _end;

        if (orient === 'vertical') {
            _start = start + (bottom - startY) / gridHeight * delta;
            _end = start + (bottom - offsetY) / gridHeight * delta;
        } else {
            _start = start + (startX - left) / gridWidth * delta;
            _end = start + (offsetX - left) / gridWidth * delta;
        }

        let zoom = fixZoom({
            start: _start,
            end: _end
        });

        axisModel.setZoom(zoom);

        history.push([axisModel.index, {
            start,
            end
        }]);
    });

    return history;
}

function preventDefault(e) {
    var rawE = e.event;
    rawE.preventDefault && rawE.preventDefault();
}

function fixZoom(zoom) {
    return {
        start: Math.min(zoom.start, zoom.end),
        end: Math.max(zoom.start, zoom.end)
    }
}

function getFixedOffset(offset, min, max) {

    offset < min && (offset = min);
    offset > max && (offset = max);

    return offset
}