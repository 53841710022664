// import { Text, formatTextStyle } from '../../util/graphic';
// import RectArc from "../../shape/RectArc";
import ChartView from '../../view/Chart';
import { TYPE } from './RadarModel'; // 使用Model中定义的TYPE，与model的type值保持一致
import Polygon from 'zrender/src/graphic/shape/Polygon';
import Circle from 'zrender/src/graphic/shape/Circle';
import Text from 'zrender/src/graphic/Text';
import { setHoverStyle } from '../../util/graphic';
import * as d3Color_ from 'd3-color';
import { isObject } from '../../util';
import { seriesActions } from '../../action/event';

const d3Color = d3Color_;

export default class RadarView extends ChartView {

    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    getBrighterColor(color, fill) {
        return fill == 'auto' ? color.toString(): (fill == 'hidden' ? undefined : fill)
    }

    render(model, globalModel, global) {

        const cos = Math.cos;
        const sin = Math.sin;

        const defaultColor = ['#c23531','#2f4554', '#61a0a8', '#d48265', '#91c7ae','#749f83', '#ca8622', '#bda29a','#6e7074', '#546570', '#c4ccd3'];

        let d = model.getData();

        let radarAxisIndex = model.get('$radarAxisIndex');
        const radarAxisModel = globalModel.getComponentByIndex('radarAxis', radarAxisIndex);
        const radiusScaleArr = radarAxisModel.radiusScaleArr ;
        const angleScale = radarAxisModel.angleScale ;
        // const symbolSize = model.get('symbolSize');
        const itemStyle = model.get('itemStyle');
        const labelStyle = model.get('label');
        const distanceOffset = model.get('label').normal.distanceOffset;
        const duration = model.get('duration');
        const easing = model.get('easing');
        const symbol = model.get('symbol')
        const animation = model.get('animation')

        let legendIndex = model.get('$legendIndex') || 0;
        let selected = model.get('selected');

        let canvasW = global.getWidth();
        let canvasH = global.getHeight();

        const radarCenter = radarAxisModel.get('center');
        let cx = typeof radarCenter[0] === 'string' ? parseInt(parseFloat(radarCenter[0].split('%')[0])/100*canvasW) : parseInt(radarCenter[0]);
        let cy = typeof radarCenter[1] === 'string' ? parseInt(parseFloat(radarCenter[1].split('%')[0])/100*canvasH) : parseInt(radarCenter[1]);

        let offsetpx = 10;
        var attrArr = [], emptyArr = [], polygonGroup, labelGroup;
        for (var k = 0; k < d.length; k++) {
            let dataObj = d[k];
            let data = dataObj.value;
            let color
            let symbolSize
            let circleFill
            if (isObject(symbol)) {
                color = symbol.normal.style.stroke ? symbol.normal.style.stroke : defaultColor[k];
                symbolSize = symbol.normal.size;
                circleFill = symbol.normal.style.fill ? symbol.normal.style.fill : '#fff';
            } else {
                color = model.get('color') ? model.get('color') : defaultColor[k];
                symbolSize = model.get('symbolSize');
                circleFill = '#fff'
            }
            color = d3Color.color(color);
            let colorStr = color.toString();
            color.opacity = itemStyle.normal.opacity;
            let colorBrighter = this.getBrighterColor(color, itemStyle.normal.fill);

            let pointArr = [];
            emptyArr = [];
            for (let i = 0; i < data.length; i++) {
                var r = radiusScaleArr[i](data[i]);

                var x = r * cos(angleScale(i));
                var y = r * sin(angleScale(i));
                var textOffsetX = (r+offsetpx*distanceOffset) * cos(angleScale(i))
                var textOffsetY = (r+offsetpx*distanceOffset) * sin(angleScale(i))
                pointArr.push([x, y, textOffsetX, textOffsetY]);
                emptyArr.push([0, 0]);
            }
            if (!selected || selected[dataObj.name] !== false ) {
                // 画多边线填充or线框
                attrArr.push({
                    shape: {
                        points: pointArr
                    },
                    position: [cx, cy],
                    // 建议始终添加key
                    key: dataObj.name,
                    style:{
                        stroke: colorStr,
                        ...itemStyle.normal,
                        fill: colorBrighter
                    },
                    data: {
                        // color: colorStr,
                        value: model.get('tooltip').formatter({
                            seriesIndex: k,
                            color: colorStr,
                            data: dataObj,
                            indicator: radarAxisModel.get('indicator')
                        })
                    }
                });
            }

            let pointAttrArr = [], textAttrArr = [];
            //小圆点 symbol
            for (let i = 0; i < pointArr.length; i++) {
                pointAttrArr.push({
                    z2: 4,
                    shape: {
                        cx: pointArr[i][0],
                        cy: pointArr[i][1],
                        r: symbolSize
                    },
                    // 建议始终添加key
                    key: 'symbol_'+k+'_'+i,
                    position: [cx, cy],
                    style:{
                        stroke: colorStr,
                        fill: circleFill
                    },
                    data: {
                        value: model.get('tooltip').formatter({
                            seriesIndex: k,
                            color: colorStr,
                            data: dataObj,
                            indicator: radarAxisModel.get('indicator')
                        })
                    }
                });


                let textX = pointArr[i][0];
                let textY = pointArr[i][1];
                let textAlign = textX.toFixed(6) == 0 ? 'center' : (textX.toFixed(6)< 0 ? 'right': 'left');
                let textVerticalAlign = textX.toFixed(6) == 0 ? (textY < 0 ? 'bottom': 'top') : undefined;

                // 每块的数字显示
                textAttrArr.push({
                    z2: 5,
                    key: 'label_'+k+'_'+i,
                    style: {
                        text: typeof labelStyle.normal.formatter === 'function' ? labelStyle.normal.formatter(data[i]) : data[i],
                        x: pointArr[i][2] - labelStyle.normal.offset[0],
                        y: pointArr[i][3] - labelStyle.normal.offset[1],
                        textFill: colorStr,
                        ...labelStyle.normal,
                        textAlign: textAlign,
                        textVerticalAlign
                    },
                    position: [cx, cy],
                    data: {
                        value: model.get('tooltip').formatter({
                            seriesIndex: k,
                            color: colorStr,
                            data: dataObj,
                            indicator: radarAxisModel.get('indicator')
                        })
                    }
                });

            }

            this.setShapeGroup('symbolGroup'+k, Circle, pointAttrArr, {
                animation: animation,
                animateFrom: {
                    shape: {
                        r: 0
                    }
                },
                duration: duration,
                easing: easing,
            }, undefined,
            function (shape) {
                shape.on('mouseover', function ({ target }) {
                    polygonGroup._children[target.name.split('_')[1]].trigger('mouseover');
                });

                shape.on('mouseout', function ({ target }) {
                    polygonGroup._children[target.name.split('_')[1]].trigger('mouseout');
                });
            });

            labelGroup = this.setShapeGroup('labelGroup'+k, Text, textAttrArr);
            if (!labelStyle.normal.show) {
                labelGroup.hide();
            }else{
                labelGroup.show();
            }

        }

        var that = this;
        polygonGroup = this.setShapeGroup('polygon', Polygon, attrArr,
            {
                animation: animation,
                animateFrom: {
                    shape: {
                        points: emptyArr
                    }
                },
                duration: duration,
                easing: easing,
            }, undefined, function (shape) {
                // setHoverStyle(shape, {
                //     ...itemStyle.emphasis
                // });

                shape.on('mouseover', function () {
                    let target = this;

                    if (labelStyle.emphasis.show) {
                        labelGroup.eachChild(function (labelShape) {
                            let textAlign = labelShape.style.textAlign;
                            labelShape.setStyle({
                                ...labelStyle.emphasis,
                                textAlign: textAlign
                            });
                            labelShape.z2 = 3;
                        });
                        labelGroup.show();
                    }else{
                        labelGroup.hide();
                    }

                    let color = d3Color.color(target.style.stroke);
                    color.opacity = itemStyle.emphasis.opacity;
                    target.setStyle({
                        ...itemStyle.emphasis,
                        'fill': that.getBrighterColor(color, itemStyle.emphasis.fill)
                    });
                    target.z2 = 3;
                    global.dispatchAction(model, seriesActions.mouseover, target);
                });

                shape.on('mouseout', function () {
                    let target = this;

                    if (labelStyle.normal.show) {
                        labelGroup.eachChild(function (labelShape) {
                            let textAlign = labelShape.style.textAlign;
                            labelShape.setStyle({
                                ...labelStyle.normal,
                                textAlign: textAlign
                            });
                            labelShape.z2 = 0;
                        });
                        labelGroup.show();
                    }else{
                        labelGroup.hide();
                    }

                    let color = d3Color.color(target.style.stroke);
                    color.opacity = itemStyle.normal.opacity;
                    target.setStyle({
                        ...itemStyle.normal,
                        'fill': that.getBrighterColor(color, itemStyle.normal.fill)
                    });
                    target.z2 = 0;
                    global.dispatchAction(model, seriesActions.mouseout, target);
                });

                shape.on('click', function({ target }) {
                    global.dispatchAction(model, seriesActions.itemClick, target);
                })
            }
        );
    }


}
