// import { select } from 'd3-selection';
import { geoTransverseMercator, geoPath } from 'd3-geo';
import { Rect, Polygon, Line, BezierCurve, Text } from '../../util/graphic';
import ChartView from '../../view/Chart';
import { each } from '../../util';
import { TYPE } from './GeoModel'; // 使用Model中定义的TYPE，与model的type值保持一致
import SVGPath from '../../shape/SVGPath';
import { Symbol } from '../../util/symbol';
import { max, min } from 'd3-array';
import { interpolateRgb } from 'd3-interpolate';
import { scaleLinear } from 'd3-scale';
import { geoActions } from '../../action/event';

let bool, width, maxValue, minValue;

const getLineWidth = (wValue, opt, max) => {
    let w = opt.style.lineWidth;
    let n = wValue / max * w;
    let newWidth = n < 2 ? 2 : n;
    return newWidth;
}

const leftGradient = (src, tar) => {
    const dir = src[0] < tar[0] ? 1 : 0;
    return dir;
};
const rightGradient = (src, tar) => {
    const dir = src[0] > tar[0] ? 1 : 0;
    return dir;
};

const textPosition = (pos, name, padding) => {
    if (name === '香港' || name === '天津') {
        return [pos[0] + 25, pos[1] - 10];
    } else if (name === '广州') {
        return [pos[0] - 20, pos[1] - 10];
    }
    return [pos[0] + padding[0], pos[1] + 5 + padding[1]];
};

export default class GeoView extends ChartView {

    static type = TYPE; // 静态变量

    type = TYPE; // 实例变量

    render(model, globalModel, global) {
        this.newGroup = [];
        let markerData = model.getData().markerData || [];
        let data = window.chinaData;
        for (let j = 0; j < markerData.length; j += 1) {
            if (markerData[j].position) {
                this.newGroup.push(markerData[j]);
            } else {
                for (let i = 0; i < data.features.length; i += 1) {
                    if (data.features[i].properties.name === markerData[j].name) {
                        let newMarker = markerData[j];
                        newMarker.position = data.features[i].properties.cp;
                        this.newGroup.push(newMarker);
                    }
                }
            }
        }
        maxValue = max(markerData, (d) => d.value);
        minValue = min(markerData, (d) => d.value);
        renderCanvas.call(this, model, global);
        renderMarkders.call(this, model, global);
        renderFlyLine.call(this, model);
        renderPolygon.call(this, model);
        drawBars.call(this, model);
        drawSouthSea.call(this, model);
        renderRoute.call(this, model);
    }
}

function renderCanvas(model, global) {
    let { outerPath, innerPath } = model.get('map');
    let gridModel = model.globalModel.getComponentByIndex('grid', 0);
    let { left, right, top, bottom } = gridModel.position;
    let visualMapModel = model.globalModel.getComponentByIndex('visualMap', 0);

    const getPath = () => {
        const path = geoPath()
            .projection(model.projection);
        return path;
    }

    if (outerPath.show) {
        this.border = model.getData().borderData;
        let path = getPath();
        this.setShape('border', SVGPath, {
            shape: {
                path: path(window.chinaBorderData)
            },
            style: {
                stroke: outerPath.style.stroke,
                lineWidth: outerPath.style.lineWidth,
                fill: null
            }
        });
    }

    if (innerPath.show) {
        let path = getPath();
        let linear = scaleLinear().domain([minValue, maxValue]).range([0, 1]);
        const getCurrentData = (name) => {
            for (let j = 0; j < this.newGroup.length; j += 1) {
                if (name === this.newGroup[j].name) {
                    return this.newGroup[j];
                }
            }
        }
        let drawInnerPath = (arr) => {
            let attrs = [];
            each(window.chinaData.features, (d, i) => {
                let fillType;
                let currentValue;
                let currentData;
                if (innerPath.style.fill instanceof Array) {
                    const a = innerPath.style.fill[0];
                    const b = innerPath.style.fill[1];
                    const computeColor = interpolateRgb(a, b);
                    for (let j = 0; j < this.newGroup.length; j += 1) {
                        if (d.properties.name === this.newGroup[j].name) {
                            currentValue = this.newGroup[j].value;
                            if (currentValue >= arr.down && currentValue <= arr.up) {
                                const t = linear(currentValue);
                                fillType = computeColor(t);
                            } else {
                                fillType = '#fff';
                            }
                        }
                    }
                } else if (innerPath.style.randomFill.randomFill) {
                    let colors = innerPath.style.randomFill.colors;
                    let random = parseInt(Math.random() * colors.length);
                    fillType = colors[random];
                } else {
                    fillType = typeof innerPath.style.fill === 'function' ? innerPath.style.fill(d) : innerPath.style.fill;
                }
                attrs.push({
                    shape: {
                        path: path(d),
                        currentValue: currentValue,
                        fillType: fillType
                    },
                    style: {
                        stroke: innerPath.style.stroke,
                        fill: fillType,
                        lineWidth: innerPath.style.lineWidth,
                        lineDash: innerPath.style.lineDash
                    },
                    data: {
                        name: d.properties.name,
                        value: currentValue,
                        data: getCurrentData(d.properties.name)
                    },
                    fill: fillType
                });
            });
            this.setShapeGroup('geomap', SVGPath, attrs, undefined, undefined, function (path) {
                path.on('mouseover', (e) => {
                    if (innerPath.style.hover.hover) {
                        let hoverFill = innerPath.style.hover.hoverFill
                        if(typeof hoverFill === 'function') {
                          path.setStyle({fill: hoverFill(e.target.data.data)})
                        }
                        else {
                          path.setStyle({ fill: hoverFill });
                        }
                    }
                    if (innerPath.events) {
                        global.dispatchAction(model, innerPath.events.registion, path.data.data);
                        global.dispatchAction(model, geoActions.mouseover, path.data.data);
                    }
                });
                path.on('click', () => {
                    global.dispatchAction(model, geoActions.itemClick, path.data.data);
                });

                path.on('mouseout', () => {
                    path.setStyle({ fill: path.shape.fillType });
                    global.dispatchAction(model, geoActions.mouseout, path.data.data);
                });
                if (innerPath.events) {
                    global.registerAction(model, innerPath.events.dispatch.mouseover, function (n) {
                        if (path.data.name === n.name) {
                            let eventFill = n.fill ? n.fill : 'white';
                            path.setStyle('fill', eventFill);
                        }
                    });
                    global.registerAction(model, innerPath.events.dispatch.mouseout, function (n) {
                        if (path.data.name === n.name) {
                            path.setStyle('fill', path.fill);
                        }
                    });
                }
            });
        }
        let pathObj;
        if (innerPath.style.fill instanceof Array) {
            pathObj = { up: visualMapModel.get('max'), down: visualMapModel.get('min') };
            global.registerAction(visualMapModel, visualMapModel.get('eventKey'), (arr) => {
                drawInnerPath(arr);
            });
        } else {
            pathObj = {};
        }
        drawInnerPath(pathObj);
    }
}

function drawBars(model) {
    let barOpt = model.get('bars');
    if (barOpt.show) {
        let data = window.chinaData;
        for (let i = 0; i < data.features.length; i += 1) {
            this.removeShape(`geobarTop${i}`);
            this.removeShape(`geobar${i}`);
            const properties = data.features[i].properties;
            const pos = model.projection(properties.cp);
            const height = 20 + Math.random() * 40;

            const posList = [
                [pos[0], pos[1]], [pos[0] - barOpt.size * 2, pos[1] - barOpt.size * 0.4],
                [pos[0] + barOpt.size, pos[1] - barOpt.size], [pos[0] - barOpt.size * 0.8, pos[1] - barOpt.size * 1.4]
            ];
            const newPosList = posList.map((o) => [o[0], (o[1] - height)]);
            this.setShape(`geobarTop${i}`, Polygon,
                {
                    shape: {
                        points: [newPosList[0], newPosList[1], newPosList[3], newPosList[2]]
                    },
                    style: {
                        fill: barOpt.colors[0]
                    },
                    z: model.get('z'),
                    z2: 998,
                    position: [0, 0]
                },
                {
                    animation: true,
                    animateFrom: {
                        position: [0, height]
                    },
                    easing: 'cubicOut',
                    duration: 1000
                });

            const barNamePos = (name) => {
                let textPos = [0, 0];
                if (name === '广东') {
                    textPos = [-20, -10];
                } else if (name === '澳门') {
                    textPos = [30, -15];
                } else if (name === '河南') {
                    textPos = [10, 0];
                }
                return textPos;
            }

            this.setShapeGroup(`geobar${i}`, Polygon, [{
                shape: {
                    points: [posList[0], posList[1], [posList[1][0], posList[1][1] - 1], [posList[0][0], posList[0][1] - 1]]
                },
                style: {
                    fill: barOpt.colors[1],
                    text: properties.name,
                    textFill: barOpt.style.textFill,
                    textPosition: barOpt.style.textPosition,
                    textOffset: barNamePos(properties.name),
                    fontSize: barOpt.style.fontSize
                },
                z: model.get('z'),
                z2: 999
            }, {
                shape: {
                    points: [posList[0], posList[2], [posList[2][0], posList[2][1] - 1], [posList[0][0], posList[0][1] - 1]]
                },
                style: {
                    fill: barOpt.colors[2]
                },
                z: model.get('z'),
                z2: 999
            }], undefined, undefined, (bar) => {
                const points = bar.shape.points;
                bar.animateShape(false)
                    .when(1000, {
                        points: [points[0], points[1], [points[2][0], points[2][1] - height], [points[3][0], points[3][1] - height]]
                    })
                    .start('cubicOut');
            });
        }
    }
}

function renderMarkders(model, global) {
    let markerOpt = model.get('markers');
    let markerGroup = markerOpt.group;
    let overLabelOpt = markerOpt.overLabel;
    let clickLabelOpt = markerOpt.clickLabel;

    for (let i = 0; i < markerGroup.length; i += 1) {
        let attrs = [];
        let attrs2 = [];
        if (markerGroup[i].show) {
            let animationOpt;
            if (markerGroup[i].animation) {
                animationOpt = markerGroup[i].animation;
            }
            each(this.newGroup, (point, j) => {
                const pos = model.projection(point.position);
                const pecent = point.value / maxValue;
                const oriValue = markerGroup[i].shape.size[1] * pecent;
                let value;
                let opacity = markerGroup[i].style.opacity;
                if (markerGroup[i].sameSize) {
                    value = markerGroup[i].shape.size[0];
                } else {
                    value = oriValue < markerGroup[i].shape.size[0] ? markerGroup[i].shape.size[0] : oriValue;
                }
                if (animationOpt && animationOpt.animation) {
                    value = value * animationOpt.animateSize;
                    opacity = 0;
                }
                let offset = markerGroup[i].offSet ? markerGroup[i].offSet : [0, 0];
                attrs.push({
                    shape: {
                        symbolType: markerGroup[i].shape.symbolType,
                        x: pos[0] - value / 2 + offset[0],
                        y: pos[1] - value / 2 + offset[1],
                        width: value,
                        height: value,
                        r: 0
                    },
                    style: {
                        ...markerGroup[i].style,
                        opacity: opacity
                    },
                    z: model.get('z'),
                    z2: 998,
                    province: point.name,
                    rank: point.rank,
                    key: point.name + i,
                    data: point
                });
                let labelOpt = markerGroup[i].label;
                if (labelOpt.show) {
                    attrs2.push({
                        style: {
                            text: point.name,
                            textFill: labelOpt.style.textFill,
                            textAlign: 'middle',
                            fontSize: labelOpt.style.fontSize
                        },
                        position: textPosition(pos, point.name, labelOpt.padding),
                        z: model.get('z'),
                        z2: 998,
                        silent: true
                    })
                }
            });

            this.setShapeGroup('geomarker' + i, Symbol, attrs, undefined, (group) => {
                const markers = group.children();
                if (overLabelOpt.show) {
                    this.newGroup.sort((a, b) => a.rank - b.rank);
                    markers.sort((a, b) => a.rank - b.rank);
                    let count = 0;
                    let total = attrs.length;
                    const changeColor = (fill, d) => {
                        if (fill.length > 1) {
                            return d < 0 ? fill[0] : fill[1];
                        } else {
                            return fill;
                        }
                    }
                    let drawOverLabel = (x, y, w, point) => {
                        let currentX = x + w / 2;
                        let currentY = y + w / 2;
                        let { startX, startY, endX } = overLabelOpt.labelLine.position;
                        let newX = currentX + startX;
                        let newY = currentY - startY;
                        let end = currentX + endX;
                        if (overLabelOpt.labelLine.show) {
                            this.setShape('overLine', SVGPath, {
                                shape: {
                                    path: 'M' + currentX + ' ' + currentY + 'L' + newX + ' ' + newY + 'L' + end + ' ' + newY
                                },
                                style: {
                                    stroke: overLabelOpt.style.fill,
                                    fill: 'none',
                                    lineWidth: overLabelOpt.style.lineWidth
                                },
                                z: model.get('z'),
                                z2: 1000
                            });
                        }
                        let labelGroup = overLabelOpt.labelGroup;

                        for (let i = 0; i < labelGroup.length; i += 1) {
                            let { textFill, x, y, fontSize, textAlign, fontFamily } = labelGroup[i];
                            let labelContent;
                            if (labelGroup[i].formatter) {
                                let labelFormatter = labelGroup[i].formatter;
                                labelContent = labelFormatter(point);
                            } else {
                                labelContent = point;
                            }

                            this.setShape('overLineText' + i, Text, {
                                style: {
                                    text: labelContent,
                                    textFill: changeColor(textFill, point.diff),
                                    fontSize: fontSize,
                                    textAlign: textAlign,
                                    fontFamily: fontFamily
                                },
                                z: model.get('z'),
                                z2: 1000,
                                position: [newX + x, newY + y]
                            });
                        }
                    };
                    drawOverLabel(markers[0].shape.x, markers[0].shape.y, markers[0].shape.width, this.newGroup[0]);
                    setInterval(() => {
                        count += 1;
                        let i = count % total;
                        // 不加这个条件，循环一轮之后，i超出markers.length，会报错
                        if (i < markers.length) {
                            let { x, y, width } = markers[i].shape;
                            drawOverLabel(x, y, width, this.newGroup[i]);
                        }
                    }, overLabelOpt.speed)
                }
                if (clickLabelOpt.show === true) {
                    global.registerAction(model, geoActions.showLabel, (name) => {
                        for (let i = 0; i < markers.length; i += 1) {
                            for (let j = 0; j < markerGroup.length; j += 1) {
                                markers[i].setColor(markerGroup[j].style.fill);
                                if (markers[i].province === name) {
                                    model.isLight = markers[i];
                                    createClickLabel(markers[i]);
                                }
                            }
                        }
                    });
                }
            }, (marker) => {
                if (animationOpt && animationOpt.animation) {
                    const _width = marker.shape.width / animationOpt.animateSize;
                    const _height = marker.shape.height / animationOpt.animateSize;
                    const _x = marker.shape.x - (_width - marker.shape.width) / 2;
                    const _y = marker.shape.y - (_height - marker.shape.height) / 2;
                    const speed = animationOpt.speed;
                    marker.animateShape(true) // animate在update时不执行更新
                        .delay(animationOpt.delay + animationOpt.randomDelay * marker.rank)
                        .when(speed, {
                            width: _width,
                            height: _height,
                            x: _x,
                            y: _y
                        })
                        .start();

                    marker.animateStyle(true)
                        .when(animationOpt.randomDelay * marker.rank, {
                            opacity: 1
                        })
                        .delay(animationOpt.delay + animationOpt.randomDelay * marker.rank)
                        .when(speed, {
                            opacity: 0
                        })
                        .start();
                }
                marker.on('mousedown', () => {
                    global.dispatchAction(model, geoActions.markerClick, marker.province);
                });
                if (markerOpt.hover.hover) {
                    marker.on('mouseover', (e) => {
                        if (markerOpt.hover.pannal.show) {
                            this.setShape('hoverLabel', Rect, {
                                shape: {
                                    x: e.offsetX,
                                    y: e.offsetY - 150,
                                    width: markerOpt.hover.pannal.width,
                                    height: markerOpt.hover.pannal.height
                                },
                                style: {
                                    ...markerOpt.hover.pannal.style
                                },
                                z: model.get('z'),
                                z2: 1040,
                                silent: true
                            });
                        }
                        let attrs = [];
                        let hoverLabelGroup = markerOpt.hover.labelGroup;
                        each(hoverLabelGroup, (h) => {
                            let hoverTarget = e.target.data;
                            let hoverText = h.formatter(hoverTarget);
                            attrs.push({
                                style: {
                                    text: hoverText + h.unit,
                                    textFill: h.textFill,
                                    textAlign: h.textAlign,
                                    fontSize: h.fontSize
                                },
                                position: [e.offsetX + h.x, e.offsetY - 150 + h.y],
                                z: model.get('z'),
                                z2: 1050,
                                silent: true
                            })
                        })
                        this.setShapeGroup('hoverLabelText', Text, attrs);
                    })
                    marker.on('mouseout', () => {
                        let hoverLabel = this.getShape('hoverLabel');
                        if (hoverLabel) {
                            this.removeShape('hoverLabel');
                            this.removeShape('hoverLabelText');
                        }
                    })
                }
            });
        }
        this.setShapeGroup('markerLabel', Text, attrs2);
        const createClickLabel = (marker) => {
            marker.setColor('#57FFD9');
            let labelX = marker.shape.x + marker.shape.width / 2;
            let labelY = marker.shape.y;
            this.setShape('clickLabel', Text, {
                style: {
                    text: marker.province,
                    textFill: clickLabelOpt.style.textFill,
                    fontSize: clickLabelOpt.style.fontSize,
                    textAlign: clickLabelOpt.style.textAlign,
                    fontFamily: clickLabelOpt.style.fontFamily
                },
                z: model.get('z'),
                z2: 1100,
                position: [labelX, labelY - clickLabelOpt.top - clickLabelOpt.style.fontSize * 1.1]
            });
            let fontW = marker.province.length * clickLabelOpt.style.fontSize * 0.8;
            let labelX2 = labelX - fontW;
            let labelX3 = labelX + fontW;
            let labelY2 = labelY - clickLabelOpt.top - clickLabelOpt.style.fontSize * 1.2;
            let labelY3 = labelY - clickLabelOpt.top;
            this.setShape('clickPolygon', Polygon, {
                shape: {
                    points: [[labelX2, labelY2], [labelX3, labelY2], [labelX3, labelY3], [labelX + clickLabelOpt.top, labelY3], [labelX, labelY3 + clickLabelOpt.top], [labelX - clickLabelOpt.top, labelY3], [labelX2, labelY3]]
                },
                style: {
                    fill: 'white',
                    shadowColor: 'rgba(0, 0, 0, 0.2)',
                    shadowBlur: 10,
                    shadowOffsetX: 2,
                    shadowOffsetY: 2
                },
                z: model.get('z'),
                z2: 1010
            });
        }

        if (model.isLight) { createClickLabel(model.isLight); }
    }
}

function renderFlyLine(model) {
    let attrs = [];
    let flylineOpt = model.get('flylines');
    if (flylineOpt.show) {
        let trajactoryData = model.getData().trajectoryData;
        let maxFlyline = max(trajactoryData, (d) => d.value);
        const src = model.projection(this.newGroup[0].position);
        trajactoryData.map((d) => {
            for (let i = 0; i < this.newGroup.length; i += 1) {
                if (d.src === this.newGroup[i].name) {
                    d.srcPos = this.newGroup[i].position;
                }
                if (d.target === this.newGroup[i].name) {
                    d.tarPos = this.newGroup[i].position;
                }
            }
        });

        for (let i = 0; i < trajactoryData.length; i += 1) {
            const srcPos = model.projection(trajactoryData[i].srcPos);
            const tarPos = model.projection(trajactoryData[i].tarPos);
            const attribution = {
                shape: {
                    x1: srcPos[0],
                    y1: srcPos[1],
                    x2: tarPos[0],
                    y2: tarPos[1],
                    cpx1: (srcPos[0] + tarPos[0]) / 2 - (srcPos[1] - tarPos[1]) * 0.2,
                    cpy1: (srcPos[1] + tarPos[1]) / 2 - (tarPos[0] - srcPos[0]) * 0.2,
                    percent: 1
                },
                style: {
                    ...flylineOpt.style,
                    stroke: {
                        x1: 0,
                        x2: 1,
                        colorStops: [
                            {
                                offset: leftGradient(srcPos, tarPos),
                                color: flylineOpt.colors[0]
                            },
                            {
                                offset: rightGradient(srcPos, tarPos),
                                color: flylineOpt.colors[1]
                            }
                        ]
                    },
                    lineWidth: getLineWidth(trajactoryData[i].value, flylineOpt, maxFlyline)
                },
                z: model.get('z'),
                z2: 997
            };
            attrs.push(attribution);
            if (flylineOpt.background.show) {
                this.setShape('flyBG' + i, BezierCurve, attribution, undefined, (l) => {
                    l.setStyle('stroke', flylineOpt.background.style.stroke);
                    l.setStyle('shadowColor', 'rgba(0, 0, 0, 0)');
                    l.setStyle('lineWidth', flylineOpt.background.style.lineWidth);
                });
            }
        }
        this.setShapeGroup('flylines', BezierCurve, attrs, undefined, undefined, (line) => {
            const xDis = (line.shape.x1 - line.shape.x2) * 2;
            const yDis = (line.shape.y1 - line.shape.y2) * 2;
            const length = (Math.sqrt(Math.pow(xDis, 2) + Math.pow(yDis, 2)));
            line.style.lineDash = [length, length];
            const start = length;
            const end = start - 2 * length;
            line.style.lineDashOffset = start;
            let r = Math.random() + 1;
            if (flylineOpt.animation) {
                line.animate('style', true)
                    .delay(3000 * Math.random())
                    .when(3000 * r, {
                        lineDashOffset: end
                    })
                    .start();
            }
        });
    }
}

function renderRoute(model) {
    let routeOpt = model.get('routes');
    let attrs = [];
    if (routeOpt.show) {
        let routeData = model.getData().trajectoryData;
        let maxFlyline = max(routeData, (d) => d.value);
        const src = model.projection(this.newGroup[0].position);
        routeData.map((d) => {
            for (let i = 0; i < this.newGroup.length; i += 1) {
                if (d.src === this.newGroup[i].name) {
                    d.srcPos = this.newGroup[i].position;
                }
                if (d.target === this.newGroup[i].name) {
                    d.tarPos = this.newGroup[i].position;
                }
            }
        })
        const threshold = (value, oldColor) => {
            if (routeOpt.threshold.threshold) {
                let colors;
                if (value > routeOpt.threshold.value) {
                    colors = routeOpt.threshold.colors[0];
                } else {
                    colors = oldColor;
                }
                return colors;
            } else {
                return oldColor;
            }
        }
        each(routeData, (t) => {
            const srcPos = model.projection(t.srcPos);
            const tarPos = model.projection(t.tarPos);
            attrs.push({
                shape: {
                    x1: srcPos[0],
                    y1: srcPos[1],
                    x2: tarPos[0],
                    y2: tarPos[1]
                },
                style: {
                    stroke: {
                        x1: 0,
                        x2: 1,
                        colorStops: [
                            {
                                offset: leftGradient(srcPos, tarPos),
                                color: threshold(t.value, routeOpt.colors[0])
                            },
                            {
                                offset: rightGradient(srcPos, tarPos),
                                color: threshold(t.value, routeOpt.colors[1])
                            }
                        ]
                    },
                    lineWidth: getLineWidth(t.value, routeOpt, maxFlyline),
                    shadowBlur: routeOpt.style.shadowBlur,
                    shadowColor: routeOpt.style.shadowColor,
                    opacity: routeOpt.style.opacity,
                    text: routeOpt.style.text.formatter(t),
                    textFill: routeOpt.style.textFill,
                    fontSize: routeOpt.style.fontSize
                },
                z: model.get('z'),
                z2: 997,
                value: t.value,
                silent: true
            });
        })
        this.setShapeGroup('routes', Line, attrs);

        let strokeColor = (fill, thresholdFill, value, srcPos, tarPos) => {
            if (fill instanceof Array) {
                let finalFill;
                if (routeOpt.threshold.threshold && value > routeOpt.threshold.value) {
                    finalFill = thresholdFill;
                } else {
                    finalFill = fill;
                }
                return {
                    x1: 0,
                    x2: 1,
                    colorStops: [
                        {
                            offset: leftGradient(srcPos, tarPos),
                            color: finalFill[0]
                        },
                        {
                            offset: rightGradient(srcPos, tarPos),
                            color: finalFill[1]
                        }
                    ]
                }
            } else {
                return fill;
            }
        }
        if (routeOpt.animation.animation) {
            let i = -1;
            this.setShapeGroup('routesAnimation', Line, attrs, undefined, undefined, (line) => {
                const xDis = (line.shape.x1 - line.shape.x2) * 2;
                const yDis = (line.shape.y1 - line.shape.y2) * 2;
                const length = Math.sqrt(Math.pow(xDis, 2) + Math.pow(yDis, 2));
                const routeLength = routeOpt.animation.style.lengthPercent * length;
                const wait = routeOpt.animation.style.delay / routeOpt.animation.style.speed;
                line.setStyle('lineDash', [routeLength, length * wait - routeLength]);
                line.setStyle('lineDashOffset', routeLength);
                line.setStyle('stroke', strokeColor(routeOpt.animation.style.fill, routeOpt.animation.style.thresholdFill, line.value, [line.shape.x1, line.shape.y1], [line.shape.x2, line.shape.y2]));
                line.setStyle('lineWidth', routeOpt.animation.style.lineWidth);
                line.attr('z2', 998);
                i += 1;
                line.animate('style', true)
                    .delay(routeOpt.animation.style.delay * i / attrs.length)
                    .when(routeOpt.animation.style.speed, {
                        lineDashOffset: -length * wait + routeLength
                    })
                    .start();
            })
        }
    }
}


function renderPolygon(model) {
    let polygonOpt = model.get('polygons');
    if (polygonOpt.show) {
        let attrs = [];
        let data = model.getData().polygonData || { points: [] };
        let posArr = data.points.map((point) => model.projection(point.position));
        attrs.push({
            shape: {
                points: posArr
            },
            style: {
                fill: polygonOpt.style.fill,
                stroke: polygonOpt.style.stroke,
                lineWidth: polygonOpt.style.lineWidth
            },
            data,
            z: model.get('z'),
            z2: 996
        });
        this.setShapeGroup('polygons', Polygon, attrs);
    }
}

// SouthSea Path
const paths = ['m6,31.5l9,7.5l15,9l15,4l18,0l17,-14l21,-31L20,7L6,24z', 'm113,7l10,25l11,-25z', 'm46.5,66.5l14.5,-6.5l-1,13l-7,7l-15,4l8.5,-17.5z']
const lines = [
    { y2: 7, x2: 145, y1: 7, x1: 20 },
    { y2: 24, x2: 6, y1: 7, x1: 20 },
    { y2: 195, x2: 145, y1: 7, x1: 145 },
    { y2: 195, x2: 6, y1: 24, x1: 6 },
    { y2: 195, x2: 145, y1: 195, x1: 6 },
    { y2: 46.5, x2: 132.5, y1: 31.5, x1: 141.5 },
    { y2: 76.5, x2: 115.5, y1: 61.5, x1: 121.5 },
    { y2: 111.5, x2: 110.5, y1: 92.5, x1: 110.5 },
    { y2: 147.5, x2: 101.5, y1: 127.5, x1: 108.5 },
    { y2: 177.5, x2: 78.5, y1: 163.5, x1: 91.5 },
    { y2: 188.5, x2: 39.5, y1: 184.5, x1: 54.5 },
    { y2: 158.5, x2: 11.5, y1: 172.5, x1: 17.5 },
    { y2: 132.5, x2: 39.5, y1: 142.5, x1: 24.5 },
    { y2: 98.5, x2: 37.5, y1: 113.5, x1: 40.5 }
];

function drawSouthSea(model) {
    const attrs = [];
    const attrs2 = [];
    let southSeaOpt = model.get('southSea');
    let markerOpt = model.get('markers');
    let gridModel = model.globalModel.getComponentByIndex('grid', 0);
    let { right, bottom } = gridModel.position;
    let { outerPath } = model.get('map');
    function toPoint(percent) {
        let str = percent.replace('%', '');
        str = str / 100;
        return str;
    }

    if (southSeaOpt.show) {
        each(paths, function (p) {
            attrs.push({
                shape: {
                    path: p
                },
                style: {
                    stroke: outerPath.style.stroke,
                    fill: outerPath.style.fill,
                    lineWidth: 1
                },
                scale: southSeaOpt.scale,
                position: [right * toPoint(southSeaOpt.offset[0]), bottom * toPoint(southSeaOpt.offset[1])]
            });
        });
        each(lines, function (l) {
            attrs2.push({
                shape: {
                    x1: l.x1,
                    y1: l.y1,
                    x2: l.x2,
                    y2: l.y2
                },
                style: {
                    stroke: outerPath.style.stroke,
                    fill: outerPath.style.fill,
                    lineWidth: southSeaOpt.lineWidth
                },
                scale: southSeaOpt.scale,
                position: [right * toPoint(southSeaOpt.offset[0]), bottom * toPoint(southSeaOpt.offset[1])]
            })
        })

        this.setShapeGroup('southSea', SVGPath, attrs);
        this.setShapeGroup('southSeaLine', Line, attrs2);
        this.setShape('southSeaLabel', Text, {
            style: {
                text: '中国南海诸岛',
                textFill: southSeaOpt.style.textFill,
                textAlign: 'middle',
                fontSize: southSeaOpt.style.fontSize
            },
            position: [right * toPoint(southSeaOpt.offset[0]) + (75 + southSeaOpt.style.textOffset[0]) * southSeaOpt.scale[0], bottom * toPoint(southSeaOpt.offset[1]) + (190 - southSeaOpt.style.fontSize * 2 + southSeaOpt.style.textOffset[1]) * southSeaOpt.scale[1]],
            z: model.get('z'),
            scale: southSeaOpt.scale,
            z2: 999
        });
    }
}
